import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 301,
        height: 400,
        x: 6,
        y: 16,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        title: '',
        uri: '9ab6d0ae598a42a3b59d7560e78054af.jpg',
        description: '',
        width: 5760,
        height: 3840,
        alt: 'Smiling Young Woman',
        crop: {
          x: 1509,
          y: 19,
          width: 2875,
          height: 3821,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Smiling Young Woman',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'rifm1',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
    },
    {
      type: 'Container',
      components: [],
      skin: 'wysiwyg.viewer.skins.area.RectangleArea',
      layout: {
        width: 249,
        height: 311,
        x: 33,
        y: 59,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      scopedTransformations: {
        'variants-kgujmnm3': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'rifm1',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        style: {
          properties: {
            bg: 'rgba(255,222,95,1)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            bg: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.RectangleArea',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 229,
        height: 65,
        x: 42,
        y: 82,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        text: `<h5 class="font_5" style="font-size:24px; text-align:center"><span style="font-size:24px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#000000"><span style="font-family:baskervillemtw01-smbdit,serif"><span style="font-weight:bold">${translate(
          'interactions_preset_profile_exec_name_1',
        )}</span></span></span></span></span></span></span></h5>\n\n<h5 class="font_5" style="font-size:24px; text-align:center"><span style="font-size:24px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#000000"><span style="font-family:baskervillemtw01-smbdit,serif"><span style="font-weight:bold">${translate(
          'interactions_preset_profile_exec_name_2',
        )}</span></span></span></span></span></span></span></h5>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'rifm1',
        },
        brightness: 1,
        packed: false,
        minHeight: 65,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      scopedTransformations: {
        'variants-kgujmnm3': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'rifm1',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.line.SolidLine',
      layout: {
        width: 35,
        height: 5,
        x: 143,
        y: 202,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.FiveGridLine',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      props: {
        type: 'FiveGridLineProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'rifm1',
        },
        fullScreenModeOn: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        hidden: true,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      scopedTransformations: {
        'variants-kgujmnm3': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'rifm1',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        style: {
          properties: {
            'alpha-brd': '1',
            brd: '#000000',
            lnw: '3',
          },
          propertiesSource: {
            'alpha-brd': 'value',
            brd: 'value',
            lnw: 'value',
          },
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
      layout: {
        width: 66,
        height: 24,
        x: 125,
        y: 317,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.LinkBar',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      data: {
        type: 'ImageList',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        items: [
          {
            type: 'Image',
            metaData: {
              isPreset: false,
              schemaVersion: '2.0',
              isHidden: false,
              pageId: 'rifm1',
            },
            title: 'Facebook',
            uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
            description: '',
            width: 200,
            height: 200,
            alt: '',
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'rifm1',
              },
              url: 'https://www.facebook.com/wix',
              target: '_blank',
            },
          },
          {
            type: 'Image',
            metaData: {
              isPreset: false,
              schemaVersion: '2.0',
              isHidden: false,
              pageId: 'rifm1',
            },
            title: 'Twitter',
            uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
            description: '',
            width: 200,
            height: 200,
            alt: '',
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'rifm1',
              },
              url: 'https://www.twitter.com/wix',
              target: '_blank',
            },
          },
        ],
      },
      props: {
        type: 'LinkBarProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'rifm1',
        },
        gallery: 'social_icons',
        iconSize: 24,
        spacing: 18,
        bgScale: 1,
        orientation: 'HORIZ',
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        hidden: true,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      scopedTransformations: {
        'variants-kgujmnm3': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'rifm1',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {
            displayer: {},
          },
        },
        componentClassName: 'wysiwyg.viewer.components.LinkBar',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 183,
        height: 30,
        x: 67,
        y: 157,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        text: `<p class="font_7" style="font-size: 14px; line-height: 2em; text-align: center;"><span style="font-size:14px;"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#000000"><span style="font-family:lato-light,lato,sans-serif">${translate(
          'interactions_preset_profile_exec_position',
        )}</span></span></span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'rifm1',
        },
        brightness: 1,
        packed: false,
        minHeight: 30,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      scopedTransformations: {
        'variants-kgujmnm3': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'rifm1',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 219,
        height: 76,
        x: 48,
        y: 226,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'rifm1',
      },
      parent: 'comp-kgujmnls',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        text: `<p class="font_8" style="font-size: 12px; line-height: 1.8em; text-align: center;"><span style="font-size:12px;"><span style="color:#000000"><span style="font-family:proxima-n-w01-reg,sans-serif"><span style="letter-spacing:0.03em">${translate(
          'interactions_preset_profile_exec_description',
        )}</span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'rifm1',
        },
        brightness: 1,
        packed: false,
        minHeight: 76,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
        hidden: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'rifm1',
        },
      },
      scopedTransformations: {
        'variants-kgujmnm3': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'rifm1',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 312,
    height: 427,
    x: 332,
    y: 36,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'rifm1',
  },
  parent: 'rifm1',
  variants: {
    'variants-kgujmnm3': {
      componentId: 'comp-kgujmnls',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'rifm1',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujmnm3': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'rifm1',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'rifm1',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};
