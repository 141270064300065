import React, {
  useCallback,
  type FunctionComponent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash';
import { Slider } from '@wix/wix-base-ui';
import {
  previewWidth as getPreviewWidthBiEvent,
  sliderValueOnPreviewChange as getSliderValueOnPreviewChangeBiEvent,
} from '@wix/bi-logger-editor/v2';
import type {
  previewWidthParams,
  sliderValueOnPreviewChangeParams,
} from '@wix/bi-logger-editor/v2/types';
import styles from './deviceSwitchWithResize.scss';
import * as util from '@/util';

import Button from '../topBarButton/topBarButtonWithTooltip';
import TooltipContent from '../tooltipContent';
import connect, {
  type DeviceSwitchWithResizeStateProps,
  type DeviceSwitchWithResizeDispatchProps,
  mapPreviewTypeToBiPreviewMode,
} from './deviceSwitchWithResizeMappers';
import {
  BREAKPOINT_DELTA,
  TABLET_LAPTOP_WIDTH,
  TABLET_WIDTH,
  DESKTOP_WIDTH,
  WIDE_DESKTOP_WIDTH,
  PREVIEW_HORIZONTAL_MARGIN,
  TABLET_MIN_WIDTH,
} from '@/preview';
import { hooks } from '@/util';

const calcPreviewWidth = (
  currentWidth: number,
  maxWidth: number,
  baselineWidth: number,
) => {
  if (currentWidth > maxWidth) {
    return maxWidth;
  }

  const BREAKPOINTS = [
    TABLET_WIDTH,
    baselineWidth,
    TABLET_LAPTOP_WIDTH,
    DESKTOP_WIDTH,
    WIDE_DESKTOP_WIDTH,
  ];
  const matchedBreakPoint = BREAKPOINTS.find(
    (bpWidth) => Math.abs(currentWidth - bpWidth) < BREAKPOINT_DELTA,
  );

  return matchedBreakPoint ?? currentWidth;
};

interface DeviceSwitchWithResizeProps
  extends DeviceSwitchWithResizeStateProps,
    DeviceSwitchWithResizeDispatchProps {}

const DeviceSwitchWithResize: FunctionComponent<
  DeviceSwitchWithResizeProps
> = ({
  isMobileEditor,
  previewWidth,
  baselineWidth,
  windowWidth,
  previewType,
  setPreviewWidth,
  initPreview,
  switchToMobile,
  switchToTablet,
  switchToDesktop,
  switchToFullScreen,
  disablePreviewResizeAnimation,
}) => {
  const isTablet =
    previewType === 'desktop' && previewWidth < TABLET_LAPTOP_WIDTH;
  const isDesktop =
    previewType === 'desktop' && previewWidth >= TABLET_LAPTOP_WIDTH;
  const shouldShowSlider = isTablet || isDesktop;
  const sliderStartValue = useRef(shouldShowSlider ? previewWidth : null);

  hooks.useIsMounted(() => {
    initPreview(windowWidth);
  });

  const maxWidth = useMemo(
    () => Math.max(windowWidth - PREVIEW_HORIZONTAL_MARGIN * 2, baselineWidth),
    [windowWidth, baselineWidth],
  );

  const sendPreviewWidthBi = useCallback((params: previewWidthParams) => {
    util.biLogger.report(getPreviewWidthBiEvent(params));
  }, []);

  const debouncedSendPreviewWidthBi = useMemo(
    () => _.debounce(sendPreviewWidthBi, 500),
    [sendPreviewWidthBi],
  );

  const sendSliderValueOnPreviewChangeBi = useCallback(
    (params: sliderValueOnPreviewChangeParams) => {
      util.biLogger.report(getSliderValueOnPreviewChangeBiEvent(params));
    },
    [],
  );

  const debouncedSendSliderValueOnPreviewChangeBi = useMemo(
    () =>
      _.debounce((currentWidth: number) => {
        if (sliderStartValue.current !== currentWidth) {
          const currentPreviewMode =
            currentWidth < TABLET_LAPTOP_WIDTH ? 'tablet' : 'desktop';
          const prevPreviewMode =
            sliderStartValue.current < TABLET_LAPTOP_WIDTH
              ? 'tablet'
              : 'desktop';
          const isPreviewModeChanged = currentPreviewMode !== prevPreviewMode;
          sendSliderValueOnPreviewChangeBi({
            selected_value: String(currentWidth),
            value_change_origin: 'slider',
            previous_value: String(sliderStartValue.current),
            is_preview_mode_changed: isPreviewModeChanged,
            preview_mode: currentPreviewMode,
          });
          sliderStartValue.current = currentWidth;
        }
      }, 500),
    [sendSliderValueOnPreviewChangeBi],
  );

  useEffect(() => {
    function handleWindowResize() {
      debouncedSendPreviewWidthBi({
        preview_mode: mapPreviewTypeToBiPreviewMode(previewType, previewWidth),
        preview_width: previewWidth,
        origin: 'browser_change',
        browser_width: windowWidth,
      });
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [previewType, previewWidth, windowWidth, debouncedSendPreviewWidthBi]);

  useEffect(() => {
    if (previewWidth > maxWidth) {
      setPreviewWidth(maxWidth);
    }
  }, [previewWidth, maxWidth, setPreviewWidth]);

  const handleChange = (value: number) => {
    if (value) {
      const newPreviewWidth = calcPreviewWidth(value, maxWidth, baselineWidth);
      setPreviewWidth(newPreviewWidth);

      debouncedSendPreviewWidthBi({
        preview_mode: mapPreviewTypeToBiPreviewMode(previewType, previewWidth),
        preview_width: previewWidth,
        origin: 'slider',
        browser_width: windowWidth,
      });
      debouncedSendSliderValueOnPreviewChangeBi(value);
    }
  };

  const switchingToMobile = !isMobileEditor && previewType === 'mobile';
  const switchingFromMobile = isMobileEditor && previewType !== 'mobile';
  const isTogglingMobile = switchingToMobile || switchingFromMobile;

  const mobileBtnClickHandler = () => {
    if (isTogglingMobile) return;

    switchToMobile(windowWidth);
  };

  const tabletBtnClickHandler = () => {
    if (isTogglingMobile) return;

    switchToTablet(previewWidth, windowWidth);
    sliderStartValue.current = TABLET_MIN_WIDTH;
  };

  const desktopBtnClickHandler = () => {
    if (isTogglingMobile) return;

    switchToDesktop(previewWidth, maxWidth, windowWidth);
    sliderStartValue.current = maxWidth;
  };

  const fullSizeBtnClickHandler = () => {
    if (isTogglingMobile) return;

    switchToFullScreen(windowWidth);
  };

  return (
    <div
      className={util.cx(
        styles.topBarDeviceSwitchWithResize,
        'with-separators',
      )}
      data-aid="top-bar-device-switch-with-resize-section"
    >
      <Button
        automationId="top-bar-button-switch-mode-mobile"
        toggled={previewType === 'mobile'}
        symbolName="topBarMobile_NewWorkspace"
        className={styles.topBarBtnDevice}
        onClick={mobileBtnClickHandler}
        tooltipOpenDelay={600}
        tooltip={TooltipContent({
          tooltipData: {
            title: 'Topbar_Preview_Mode_Mobile_Tooltip_Title',
            text: 'Topbar_Preview_Mode_Mobile_Tooltip_Body',
          },
        })}
      />
      <div className={styles.fluidPreviewControls}>
        <Button
          automationId="top-bar-button-switch-mode-tablet"
          toggled={isTablet}
          symbolName="topBarTabletIcon"
          className={styles.topBarBtnDevice}
          onClick={tabletBtnClickHandler}
          tooltipOpenDelay={600}
          tooltip={TooltipContent({
            tooltipData: {
              title: 'Topbar_Preview_Mode_Tablet_Tooltip_Title',
              text: 'Topbar_Preview_Mode_Tablet_Tooltip_Body',
            },
          })}
        />
        <div
          className={util.cx(styles.deviceSwitchSlider, {
            [styles.hidden]: !shouldShowSlider,
          })}
        >
          <Slider
            hideNumericInput
            value={previewWidth}
            onChange={handleChange}
            onSlideStart={disablePreviewResizeAnimation}
            min={TABLET_MIN_WIDTH}
            max={maxWidth}
          />
        </div>
        <Button
          automationId="top-bar-button-switch-mode-desktop"
          toggled={isDesktop}
          symbolName="topBarDesktop_NewWorkspace"
          className={styles.topBarBtnDevice}
          onClick={desktopBtnClickHandler}
          tooltipOpenDelay={600}
          tooltip={TooltipContent({
            tooltipData: {
              title: 'Topbar_Preview_Mode_Desktop_Tooltip_Title',
              text: 'Topbar_Preview_Mode_Desktop_Tooltip_Body',
            },
          })}
        />
      </div>
      <Button
        automationId="top-bar-button-switch-mode-full-screen"
        toggled={previewType === 'fullScreen'}
        symbolName="topBarMaximizeIcon"
        className={styles.topBarBtnDevice}
        onClick={fullSizeBtnClickHandler}
        tooltipOpenDelay={600}
        tooltip={TooltipContent({
          tooltipData: {
            text: 'Topbar_Preview_Mode_Fit_Tooltip',
          },
        })}
      />
    </div>
  );
};

export default connect(DeviceSwitchWithResize);
