import React from 'react';
import { TextLabel, Button } from '@wix/wix-base-ui';
import styles from './editorSearch.scss';
import Block from './block';
import { AIDS } from '../constants';
import { cx } from '@/util';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import { HelpBlock } from '../types';
import type { Props } from '../types';

const EditorSearch: React.FC<Props> = ({ openSearch }) => (
  <Block type={HelpBlock.EDITOR_SEARCH} className={styles.editorSearch} raw>
    <TextLabel
      type="T02"
      value="Editor_Help_Panel_Search_Title"
      className={styles.label}
      enableEllipsis={false}
    />
    <Button
      className={cx('btn-md', 'btn-inverted', styles.actionButton)}
      automationId={AIDS.EDITOR_SEARCH}
      onClick={openSearch}
    >
      <Symbol name="editorSearchButton" />
      <TextLabel value="Editor_Help_Panel_Search_CTA_Button" />
    </Button>
  </Block>
);

export default EditorSearch;
