._noteContainer_1xzr0_1 {
  padding: 12px 24px; }

._optionText_1xzr0_4 {
  margin-top: 2px; }

._radioButtonsContainer_1xzr0_7 {
  padding: 36px 24px 0; }

._mobileFooterNote_1xzr0_10 {
  position: absolute;
  bottom: 0;
  padding: 18px 24px; }
