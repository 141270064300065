import _ from 'lodash';
import React from 'react';

import { Button, TextLabel, Tooltip } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { appStudioUtils } from '@/util';

type Callback = () => void;

interface ListItemProps {
  automationId?: string;
  canImport?: boolean;
  isDraft?: boolean;
  isAlpha?: boolean;
  href?: string;
  label: string;
  onImport: Callback;
  onEdit: Callback;
  target?: string;
  version?: string;
}

class ListItem extends React.Component<ListItemProps> {
  static defaultProps = {
    isDraft: false,
    canImport: true,
    isAlpha: false,
  };
  importButton: any;

  getLabelClassName() {
    return `label ${this.props.isDraft ? 'import-disabled' : ''}`;
  }

  registerImportButton(ref: AnyFixMe) {
    if (!_.isEqual(ref, this.importButton)) {
      this.importButton = ref;
    }
  }

  getSuffix() {
    if (this.props.isDraft) {
      return translate('AppStudio_ImportDialog_Draft_Label');
    }
    if (this.props.isAlpha) {
      return translate('AppStudio_ImportDialog_Alpha_Label');
    }
    return appStudioUtils.formatVersion(this.props.version);
  }

  getDisabledImportTooltipContent() {
    if (this.props.isDraft) {
      return 'AppStudio_ImportDialog_Button_Disabled_Tooltip';
    }

    return 'AppStudio_Import_Edit_Apps_Modal_Import_Button_Disabled_Tooltip';
  }

  isImportDisabled() {
    return this.props.isDraft || !this.props.canImport;
  }

  template() {
    const suffix = this.getSuffix();

    return (
      <div data-aid={this.props.automationId} className="item-list">
        <div className="label-container">
          <TextLabel
            value={this.props.label}
            shouldTranslate={false}
            className={this.getLabelClassName()}
          />
          {suffix ? (
            <div className="suffix" data-aid="item-suffix">
              ({suffix})
            </div>
          ) : null}
        </div>
        <Tooltip
          content="AppStudio_ImportDialog_Edit_Tooltip"
          className="edit-tooltip"
        >
          <Button
            onClick={() => {
              this.props.onEdit();
            }}
            automationId="edit-button"
            className="btn-sm primary"
          >
            {this.props.href ? (
              <a
                key="edit-link"
                href={this.props.href}
                target={this.props.target}
              >
                <symbols.symbol name="edit_icon_small" key="edit-symbol" />
              </a>
            ) : null}
            {!this.props.href ? (
              <symbols.symbol name="edit_icon_small" key="edit-symbol" />
            ) : null}
          </Button>
        </Tooltip>
        <div>
          <Tooltip
            content={this.getDisabledImportTooltipContent()}
            disabled={!this.isImportDisabled()}
            customTrigger={this.importButton}
            className="import-tooltip"
          />
          <div ref={(ref) => this.registerImportButton(ref)}>
            <Button
              automationId="import-button"
              onClick={() => {
                this.props.onImport();
              }}
              disabled={this.isImportDisabled()}
              className="btn-sm primary"
            >
              <TextLabel value="AppStudio_ImportDialog_Button" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.template();
  }
}

export default ListItem;
