._container_capdn_1 {
  padding-top: 24px; }

._illustration_capdn_4,
._learnMoreBtn_capdn_5 {
  display: block;
  margin: 0 auto; }

._textContentWrapper_capdn_9 {
  margin-top: 18px;
  text-align: center; }

._textWrapper_capdn_13 {
  padding: 6px 0; }
