{
  "addPanelSectionsTR.js": "autoaddPanelSectionsTR.js",
  "addPanelSectionsSV.js": "autoaddPanelSectionsSV.js",
  "addPanelSectionsRU.js": "autoaddPanelSectionsRU.js",
  "addPanelSectionsPT.js": "autoaddPanelSectionsPT.js",
  "addPanelSectionsPL.js": "autoaddPanelSectionsPL.js",
  "addPanelSectionsNO.js": "autoaddPanelSectionsNO.js",
  "addPanelSectionsNL.js": "autoaddPanelSectionsNL.js",
  "addPanelSectionsKO.js": "autoaddPanelSectionsKO.js",
  "addPanelSectionsJA.js": "autoaddPanelSectionsJA.js",
  "addPanelSectionsIT.js": "autoaddPanelSectionsIT.js",
  "addPanelSectionsHI.js": "autoaddPanelSectionsHI.js",
  "addPanelSectionsFR.js": "autoaddPanelSectionsFR.js",
  "addPanelSectionsES.js": "autoaddPanelSectionsES.js",
  "addPanelSectionsDE.js": "autoaddPanelSectionsDE.js",
  "addPanelSectionsDA.js": "autoaddPanelSectionsDA.js",
  "addPanelSectionsCS.js": "autoaddPanelSectionsCS.js"
}