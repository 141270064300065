// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import { SafeInjectHtml } from '@/util';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';

export interface GeneralContentProps {
  className?: string;
  symbolName?: string;
  imageSrc?: string;
  imageStyle?: React.CSSProperties;
  mainTitle: string;
  title: string;
  subtitle?: string;
  actionLabel: string;
  actionCallback: () => void;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<GeneralContentProps>({
  displayName: 'generalContent',
  mixins: [util.translationMixin],
  propTypes: {
    symbolName: PropTypes.string,
    imageSrc: PropTypes.string,
    imageStyle: PropTypes.object,
    mainTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    actionCallback: PropTypes.func.isRequired,
    actionLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
  },
  getClassName() {
    return `general-content${
      this.props.className ? ` ${this.props.className}` : ''
    }`;
  },
  render() {
    return (
      <div className={this.getClassName()}>
        <div className="main-title-wrapper">
          <Divider long={true} />
          <div className="main-title">
            {this.translateIfNeeded(this.props.mainTitle)}
          </div>
          <Divider long={true} />
        </div>
        <div className="middle-spacer" />
        <div className="bottom-section">
          <div className="left-side">
            {this.props.symbolName ? (
              <baseUI.symbol name={this.props.symbolName} key="symbol" />
            ) : null}
            {this.props.imageSrc ? (
              <img
                key="image"
                src={this.props.imageSrc}
                style={this.props.imageStyle || {}}
              />
            ) : null}
          </div>
          <div className="right-side">
            <div className="title">
              {this.translateIfNeeded(this.props.title)}
            </div>
            {this.props.subtitle ? (
              <SafeInjectHtml
                tag="div"
                key="subtitle"
                html={this.translateIfNeeded(this.props.subtitle)}
                className="subtitle"
              />
            ) : null}

            <a
              onClick={this.props.actionCallback}
              className="general-content-action"
            >
              {this.translateIfNeeded(this.props.actionLabel)}
            </a>
          </div>
        </div>
      </div>
    );
  },
});
