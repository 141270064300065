:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._tab-title_1qz3n_7 {
  position: relative; }

._selected-tab_1qz3n_10 {
  color: #116dff !important; }
