'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_wixstaff",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/wixStaffSection_en/wixStaffSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Other_Wix_Staff_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.wixhomepage.HomePageLoginSkin",
                "layout": {
                    "width": 135,
                    "height": 38,
                    "x": 25,
                    "y": 15,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.wixhomepage.HomePageLogin",
                "data": {
                    "type": "HomePageLogin",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "postLoginUrl": "http://www.wix.com",
                    "postSignUpUrl": "http://www.wix.com",
                    "startWith": "login",
                    "sendMail": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "wix_login1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 14px/1.4em helvetica-w01-light {color_15}",
                            "txt": "#3D3D3D",
                            "txth": "#0092CC"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.wixhomepage.HomePageLoginSkin"
                },
                "id": "ihnep93h"
            },
            "preset": {
                "rect": {"width": 324, "height": 73, "x": 0, "y": 0},
                "label": "add_preset_Other_Wix_Staff_1",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 57,
                    "y": 83,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "numberOfUsers"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihneng3v"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 73},
                "label": "add_preset_Other_Wix_Staff_2",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 70,
                    "y": 161,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "roundedNumOfUsers"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihnenkfl"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 143},
                "label": "add_preset_Other_Wix_Staff_3",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 20,
                    "y": 222,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "dailyApps"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihnenqci"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 212},
                "label": "add_preset_Other_Wix_Staff_4",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 60,
                    "y": 303,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "numOfCountries"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihnenoi6"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 282},
                "label": "add_preset_Other_Wix_Staff_5",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 60,
                    "y": 364,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "numOfTemplates"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihnenskj"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 352},
                "label": "add_preset_Other_Wix_Staff_6",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 40,
                    "y": 435,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "dailySignUps"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihnenpih"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 422},
                "label": "add_preset_Other_Wix_Staff_7",
                "tags": null
            }
        }, {
            "id": "Other_Wix_Staff_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin",
                "layout": {
                    "width": 200,
                    "height": 50,
                    "x": 30,
                    "y": 506,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.backofficetext.viewer.BackOfficeText",
                "data": {
                    "type": "BackOfficeText",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "key": "numOfEmp"
                },
                "props": {
                    "type": "BackOfficeTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 24px/1.4em helvetica-w01-light {color_14}",
                            "txt": "#383838",
                            "txth": "#383838"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.backofficetext.viewer.skins.BackOfficeTextSkin"
                },
                "id": "ihnenth5"
            },
            "preset": {
                "rect": {"width": 324, "height": 70, "x": 0, "y": 492},
                "label": "add_preset_Other_Wix_Staff_8",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.wixhomepage.HomePageLogin", "wysiwyg.common.components.backofficetext.viewer.BackOfficeText"]
    }
}
