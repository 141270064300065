import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import type { Scope } from './scope';
import type { HighlightBox } from './types';

interface HighlightBoxesStateProps {
  highlightedBoxes: HighlightBox[];
}
interface HighlightBoxesDispatchProps {}

export interface HighlightBoxesProps
  extends HighlightBoxesStateProps,
    HighlightBoxesDispatchProps {}

const getBoundingBoxStyles = ({
  x,
  y,
  width,
  height,
  rotationInDegrees,
}: Rect & { rotationInDegrees: number }) => {
  const borderWidth = 1;
  const transform = `rotate(${rotationInDegrees}deg)`;
  return {
    top: y,
    left: x,
    borderWidth,
    width: width - borderWidth,
    height: height - borderWidth,
    transform,
    WebkitTransform: transform,
  };
};

const createHighlightedBox = (
  id: string,
  boundingBox: Rect,
  rotationInDegrees: number,
): HighlightBox => {
  return {
    id,
    style: getBoundingBoxStyles({
      ...boundingBox,
      rotationInDegrees,
    }),
  };
};

const getHighlightedBoxes = (
  editorAPI: EditorAPI,
  highlightedCompsRefs: CompRef[],
): HighlightBox[] => {
  return highlightedCompsRefs.map((compRef: CompRef) => {
    const rotationInDegrees =
      editorAPI.components.layout.get_rotationInDegrees(compRef);
    const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();

    if (!isStageZoomMode) {
      const rect =
        editorAPI.components.layout.getRelativeToScreen_rect(compRef);
      return createHighlightedBox(compRef.id, rect, rotationInDegrees);
    }

    const compBoundingBox =
      editorAPI.components.layout.measure.getBoundingClientRect(compRef);
    const adjustedBoundingBox = {
      ...compBoundingBox,
      x: compBoundingBox.absoluteLeft,
      y: compBoundingBox.absoluteTop,
    };
    return createHighlightedBox(
      compRef.id,
      adjustedBoundingBox,
      rotationInDegrees,
    );
  });
};

export const mapStateToProps = (scope: Scope) => {
  const { store, editorAPI } = scope;

  const highlightedCompsRefs = store.getHighlightedComponents();

  const highlightedBoxes = getHighlightedBoxes(editorAPI, highlightedCompsRefs);

  return {
    highlightedBoxes,
  };
};

export const mapDispatchToProps = () => ({});
