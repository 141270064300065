import _ from 'lodash';
import { EditorAPIKey, PagesApiKey } from '@/apis';
import * as platformEvents from 'platformEvents';

import * as util from '@/util';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';

import type { CompRef, DSRead } from 'types/documentServices';
import type { Shell } from '@/apilib';
import { ComponentsApiKey } from './publicApiKey';
import { groupComponentsByContainer } from '@/documentServices';
import type { CompCommonBiParams } from '@/bi';

const PAGE_COMP_TYPE = 'mobile.core.components.Page';
const POPUP_COMP_TYPE = 'wysiwyg.viewer.components.PopupContainer';

const recursivelyMoveNonMobileOnlyCompsToParent = (
  editorAPI: EditorAPI,
  compsToMove: CompRef[],
  parent: CompRef,
  biParams: AnyFixMe,
  origin?: string,
): void => {
  compsToMove.forEach((compToMove) => {
    if (
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        compToMove,
      )
    ) {
      const children = editorAPI.components.getChildren(compToMove);
      recursivelyMoveNonMobileOnlyCompsToParent(
        editorAPI,
        children,
        parent,
        biParams,
        origin,
      );
    } else {
      editorAPI.components.setContainer(compToMove, parent);
      handleRemoveOrHideMobileComponent(
        editorAPI,
        editorAPI.dsRead,
        editorAPI.dsActions,
        [compToMove],
        biParams,
        origin,
      );
    }
  });
};

const removeMobileOnlyComponent = function (
  editorAPI: EditorAPI,
  compRef: CompRef,
  biParams: AnyFixMe,
): void {
  const parent = editorAPI.components.getContainer(compRef);
  const compChildren = editorAPI.components.getChildren(compRef);
  recursivelyMoveNonMobileOnlyCompsToParent(
    editorAPI,
    compChildren,
    parent,
    biParams,
  );
  editorAPI.dsActions.components.remove(compRef);
  editorAPI.bi.reportBI(util.bi.events.COMPONENT_REMOVED, biParams);
};

const cutComponent = function (
  dsRead: AnyFixMe,
  dsActions: AnyFixMe,
  compRef: AnyFixMe,
  cutPlugins: AnyFixMe,
) {
  const compType = dsRead.components.getType(compRef);
  if (cutPlugins[compType]) {
    cutPlugins[compType](compRef);
  } else {
    dsActions.components.remove(compRef);
  }
};

const handleRemoveOrHideMobileComponent = function (
  editorAPI: AnyFixMe,
  dsRead: AnyFixMe,
  dsActions: AnyFixMe,
  componentPointers: AnyFixMe,
  biParams = {},
  origin?: string,
) {
  const { hidePlugins } = editorAPI;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(componentPointers, function (componentPointer) {
    if (
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        componentPointer,
      )
    ) {
      removeMobileOnlyComponent(editorAPI, componentPointer, biParams);
      return;
    }
    const { variants } = editorAPI;
    if (variants) {
      const mobileVariant = editorAPI.variants.mobile.get();
      if (mobileVariant) {
        const compMobileVariantPointer =
          editorAPI.components.variants.getPointer(
            componentPointer,
            mobileVariant,
          );
        const compMobileTransformations =
          editorAPI.components.transformations.get(compMobileVariantPointer);
        // Must check that it's really "false" because undefined is also not hidden.
        if (compMobileTransformations?.hidden === false) {
          editorAPI.components.transformations.update(
            compMobileVariantPointer,
            {
              hidden: true,
            },
          );
          editorAPI.panelManager.openPanel(
            constants.ROOT_COMPS.LEFTBAR.HIDDEN_ELEMENTS_PANEL_NAME,
          );
          return;
        }
      }
    }

    const compType = dsRead.components.getType(componentPointer);
    if (hidePlugins[compType]) {
      hidePlugins[compType](componentPointer);
    } else {
      dsActions.mobile.hiddenComponents.hide(componentPointer);
    }

    if (
      !editorAPI.zoomMode.isInZoomMode() &&
      editorAPI.components.is.hiddenable(componentPointer) &&
      !editorAPI.panelManager.isPanelOpened(
        constants.ROOT_COMPS.LEFTBAR.HIDDEN_ELEMENTS_PANEL_NAME,
      )
    ) {
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.HIDDEN_ELEMENTS_PANEL_NAME,
      );
    }

    const params = getHideMobileComponentBIParams(
      editorAPI,
      dsRead,
      componentPointer,
      origin,
    );
    editorAPI.bi.event(
      coreBi.events.mobileEditor.hiddenElements.HIDDEN_ELEMENTS_HIDE,
      params,
    );
    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.hideMobileElement({ compRef: componentPointer }),
    );
  });
};

function getComponentSegment(editorAPI: AnyFixMe, componentPointer: AnyFixMe) {
  const footer = editorAPI.siteSegments.getFooter();
  const header = editorAPI.siteSegments.getHeader();

  if (editorAPI.components.isDescendantOfComp(componentPointer, header)) {
    return header.id;
  }

  if (editorAPI.components.isDescendantOfComp(componentPointer, footer)) {
    return footer.id;
  }

  return '';
}

function getHideMobileComponentBIParams(
  editorAPI: EditorAPI,
  dsRead: AnyFixMe,
  componentPointer: AnyFixMe,
  hideOrigin?: string,
) {
  const componentType = dsRead.components.getType(componentPointer);
  const componentId = componentPointer.id;

  let params = {
    component_id: componentId,
    component_type: componentType,
    current_page_name: dsRead.pages.getFocusedPageId(),
    site_segment: getComponentSegment(editorAPI, componentPointer),
    origin: hideOrigin || '',
    hide_method: '',
  };
  if (editorAPI.isTpa(componentType)) {
    const compBIParams = _.head(
      editorAPI.bi.getComponentsBIParams(componentPointer),
    );
    const pickedParams = _.omit(compBIParams, ['is_last_tpa', 'widget_id']);
    params = { ...params, ...pickedParams };
  }
  return params;
}

function getAllDeletedComponents(dsRead: DSRead, componentPointers: CompRef[]) {
  return _(componentPointers)
    .flatMap(function (compPointer) {
      return dsRead.components.is.container(compPointer)
        ? [compPointer].concat(dsRead.components.get.byAncestor(compPointer))
        : compPointer;
    })
    .uniqBy('id')
    .value();
}

const removeComponent = function (
  shell: Shell,
  componentPointers: CompRef[],
  removeArgs?: AnyFixMe,
  origin?: string,
) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const { dsRead, dsActions } = editorAPI;
  const compType = editorAPI.components.getType(componentPointers);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  const isContainer = _.some(componentPointers, dsRead.components.is.container);
  const isMultiSelect = componentPointers.length > 1;
  const biParams = getBiParamsForNestedTpas(
    editorAPI,
    dsRead,
    componentPointers,
  );

  return new Promise<void>((resolve) => {
    if (dsRead.viewMode.get() === dsRead.viewMode.VIEW_MODES.MOBILE) {
      if (!util.appStudioUtils.isAppStudio()) {
        // TODO: Remove `if` when mobile hide is supported in app studio
        handleRemoveOrHideMobileComponent(
          editorAPI,
          dsRead,
          dsActions,
          componentPointers,
          biParams,
          origin,
        );
      }
      resolve();
    } else {
      const premiumTpaChildren =
        // @ts-expect-error
        dsRead.tpa.getPremiumTpaChildren(componentPointers);
      const premiumAppsThatShouldShowDeleteDialog = _.reject(
        premiumTpaChildren,
        dsRead.tpa.app.hasSections,
      );

      // Notice: If premium TPAs are in the removed selection, we give TPA EXCLUSIVE rights
      // in showing remove message (no other messages should be shown)
      if (
        (isContainer || isMultiSelect) &&
        premiumAppsThatShouldShowDeleteDialog.length > 0
      ) {
        deleteContainerWithPremiumApps(
          editorAPI,
          dsRead,
          dsActions,
          editorAPI.removePlugins,
          premiumTpaChildren,
          componentPointers,
          biParams,
          removeArgs,
        ).then(resolve);
        return;
      }

      const actualDelete = function () {
        const isSomeComponentsOutOfGridlines = componentPointers.some(
          (compRef: CompRef) =>
            editorAPI.components.layout.shouldShowOutOfGridlinesIndication(
              compRef,
            ),
        );
        const reportBi = () => {
          util.editorWixRecorder.addLabel(
            `${biParams[0]?.component_type} component removed`,
          );
          const actualDeleteBiParams = biParams.map((compBiParams) => ({
            ...compBiParams,
            removal_method: origin,
            out_of_grid_previously: isSomeComponentsOutOfGridlines,
            origin,
            parent_component:
              editorAPI.componentFocusMode.getMenuType() ??
              compBiParams.parent_component_type,
          }));
          editorAPI.bi.reportBI(
            util.bi.events.COMPONENT_REMOVED,
            actualDeleteBiParams,
          );
        };

        const doRemove = async () => {
          await Promise.all(
            removeAllSelectedComponets(
              dsRead,
              dsActions,
              editorAPI.removePlugins,
              componentPointers,
              true,
              removeArgs,
            ),
          );
        };

        const meshLayoutApi = editorAPI.components.layout.__mesh;
        return (
          !meshLayoutApi.isEnabled()
            ? doRemove()
            : dsActions.transactions.run(async () => {
                const componentsByContainer = groupComponentsByContainer(
                  dsRead,
                  componentPointers,
                );

                await doRemove();

                componentsByContainer.forEach(({ containerRef }) => {
                  meshLayoutApi.updateContainerGrid(containerRef);
                });
              })
        )
          .then(reportBi)
          .then(resolve)
          .then(() => {
            shell.getAPI(ComponentsApiKey).hooks.componentDeleted.fire({
              compType,
              compRefs: componentPointers,
            });
          });
      };

      const wasRemoveHandledByPlugin = runComponentRemoveHandlerPlugins(
        editorAPI,
        dsRead,
        componentPointers,
        actualDelete,
        origin,
      );

      if (!wasRemoveHandledByPlugin) {
        actualDelete();
      }
    }
  });
};

function runComponentRemoveHandlerPlugins(
  editorAPI: EditorAPI,
  dsRead: DSRead,
  deletedComponentsPointers: CompRef[],
  actualDelete: () => Promise<void>,
  origin: string,
) {
  const allDeletedComponents = getAllDeletedComponents(
    dsRead,
    deletedComponentsPointers,
  );

  let wasRemoveHandled = false;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(
    editorAPI.componentsRemoveHandlerPlugins,
    function (removeHandlerPlugin) {
      if (
        removeHandlerPlugin(
          editorAPI,
          dsRead,
          deletedComponentsPointers,
          allDeletedComponents,
          actualDelete,
          origin,
        )
      ) {
        wasRemoveHandled = true;

        return false;
      }
    },
  );

  return wasRemoveHandled;
}

const getBiParamsForNestedTpas = function (
  editorAPI: AnyFixMe,
  dsRead: AnyFixMe,
  componentPointers: AnyFixMe,
) {
  const tpaComponentPointers =
    dsRead.tpa.getTpaPointersRecursive(componentPointers);
  let componentPointersForBi = componentPointers.concat(tpaComponentPointers);
  componentPointersForBi = _.uniqBy(componentPointersForBi, 'id');
  const biParams = editorAPI.bi.getComponentsBIParams(
    componentPointersForBi,
  ) as CompCommonBiParams[];
  return biParams;
};

const removeAllSelectedComponets = function (
  dsRead: AnyFixMe,
  dsActions: AnyFixMe,
  removePlugins: AnyFixMe,
  componentPointers: AnyFixMe,
  allowRemoveMessage: AnyFixMe,
  removeArgs: AnyFixMe,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(componentPointers, function (componentPointer) {
    const compType = dsRead.components.getType(componentPointer);
    const originRemove = function () {
      return new Promise((resolve) => {
        dsActions.components.remove(componentPointer, resolve, removeArgs);
      });
    };

    if (removePlugins[compType]) {
      return Promise.resolve(
        removePlugins[compType](
          componentPointer,
          allowRemoveMessage,
          originRemove,
        ),
      );
    }

    return originRemove();
  });
};

function deleteContainerWithPremiumApps(
  editorAPI: AnyFixMe,
  dsRead: AnyFixMe,
  dsActions: AnyFixMe,
  removePlugins: AnyFixMe,
  premiumTpaChildren: AnyFixMe,
  componentPointers: AnyFixMe,
  biParams: AnyFixMe,
  removeArgs: AnyFixMe,
) {
  const firstPremiumTpaChild = premiumTpaChildren[0];

  return new Promise<void>((resolve) => {
    editorAPI.panelManager.openPanel(
      'tpaPanels.confirmation.deleteContainerContainsPremiumTpa',
      {
        appName: firstPremiumTpaChild.appDefinitionName,
        appDefId: firstPremiumTpaChild.appDefinitionId,
        appCount: premiumTpaChildren.length,
        onConfirm() {
          Promise.all(
            removeAllSelectedComponets(
              dsRead,
              dsActions,
              removePlugins,
              componentPointers,
              false,
              removeArgs,
            ),
          ).then(resolve as () => void);

          util.editorWixRecorder.addLabel(
            `${biParams[0]?.component_type} component removed`,
          );
          editorAPI.bi.reportBI(util.bi.events.COMPONENT_REMOVED, biParams);
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
          _.forEach(biParams, function (params) {
            editorAPI.bi.event(
              util.bi.events.REMOVE_COMPONENT_SECOND_STEP_CLICK,
              params,
            );
          });
        },
      },
      true,
    );
  });
}

function removePopupComponent(
  editorAPI: EditorAPI,
  popupRef: AnyFixMe,
  removeOrigin?: AnyFixMe,
) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.DELETE_LIGHTBOX_FROM_EDITOR,
  );
  const pageOfLightBox = editorAPI.components.getPage(popupRef);
  util.editorWixRecorder.addLabel(`${POPUP_COMP_TYPE} component removed`);
  editorAPI.bi.event(util.bi.events.COMPONENT_REMOVED, {
    component_id: popupRef.id,
    removal_method: removeOrigin,
    component_type: POPUP_COMP_TYPE,
  });
  const onRemove = () => {
    editorAPI.history.add('LightBox removed');
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.DELETE_LIGHTBOX_FROM_EDITOR,
    );
  };
  editorAPI.host
    .getAPI(PagesApiKey)
    .removeInternal(pageOfLightBox.id, onRemove, _.noop, {
      shouldShowEditorRemovePanel: false,
    });
}

function removePopupWithMessagePanel(
  editorAPI: AnyFixMe,
  popupRef: AnyFixMe,
  removeOrigin: AnyFixMe,
) {
  const onConfirm = _.partial(
    removePopupComponent,
    editorAPI,
    popupRef,
    removeOrigin,
  );
  const pageOfPopupRef =
    editorAPI.components.getType(popupRef) === PAGE_COMP_TYPE
      ? popupRef
      : editorAPI.components.getPage(popupRef);
  const pageTitle = editorAPI.pages.getPageTitle(pageOfPopupRef.id);
  editorAPI.panelManager.openPanel('panels.messagePanels.deletePageMessage', {
    onConfirm,
    pageTitle,
    isPopup: true,
  });
}

export {
  removeComponent,
  removePopupComponent,
  removePopupWithMessagePanel,
  cutComponent,
};
