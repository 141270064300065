import type { ComponentType } from 'react';
import { HelpBlock } from '../types';
import type { Props } from '../types';

import PopularArticles from './popularArticles';
import WelcomeTour from './welcomeTour';
import HirePro from './hirePro';
import EditorSearch from './editorSearch';
import MediaCarousel from './mediaCarousel';

export const blocksMap: Partial<Record<HelpBlock, ComponentType<Props>>> = {
  [HelpBlock.ARTICLES]: PopularArticles,
  [HelpBlock.WELCOME_TOUR]: WelcomeTour,
  [HelpBlock.HIRE_PRO]: HirePro,
  [HelpBlock.EDITOR_SEARCH]: EditorSearch,
  [HelpBlock.MEDIA_CAROUSEL]: MediaCarousel,
};
