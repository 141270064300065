:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._headerContainer_1aak5_7 {
  margin-left: 30px;
  padding-bottom: 12px; }
  ._headerContainer_1aak5_7 ._title_1aak5_10 {
    color: #000624; }
  ._headerContainer_1aak5_7 ._subtitleContainer_1aak5_12 {
    margin-top: 5px; }
    ._headerContainer_1aak5_7 ._subtitleContainer_1aak5_12 ._subtitle_1aak5_12 {
      color: #3b4057; }

._smallHeaderContainer_1aak5_17 {
  max-width: 594px;
  margin-left: 30px;
  padding-bottom: 12px; }
  ._smallHeaderContainer_1aak5_17 ._title_1aak5_10 {
    color: #000624; }
  ._smallHeaderContainer_1aak5_17 ._subtitleContainer_1aak5_12 {
    margin-top: 5px; }
    ._smallHeaderContainer_1aak5_17 ._subtitleContainer_1aak5_12 ._subtitle_1aak5_12 {
      color: #3b4057; }
