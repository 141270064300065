import React from 'react';
import { TextInput, Preloader } from '@wix/wix-base-ui';
import { Icon, IconLayouts, IconColors } from '@wix/editor-search-ui';
import * as symbols from '@wix/santa-editor-symbols';

import { AUTOMATION_IDS } from '../../automationIds';

import styles from './SearchInput.scss';

export interface ISearchInputProps {
  dataHook?: string;
  value?: string;
  placeholder?: string;
  onChange?(value: string): void;
  onClear?(): void;
  shouldFocus?: boolean;
  isLoading?: boolean;
}

export const SearchInput: React.FunctionComponent<ISearchInputProps> = ({
  placeholder,
  dataHook = AUTOMATION_IDS.SEARCH_INPUT.CONTAINER,
  isLoading,
  value = '',
  shouldFocus = false,
  onClear,
  onChange,
}) => (
  <div className={styles.container} data-hook={dataHook}>
    <TextInput
      value={value}
      shouldTranslate={false}
      dataHook={AUTOMATION_IDS.SEARCH_INPUT.INPUT}
      placeholder={placeholder}
      autoSelect={shouldFocus}
      maxLength={100}
      onChange={onChange}
      preventBlur
    />
    {value && !isLoading && (
      <button
        tabIndex={0}
        data-hook={AUTOMATION_IDS.SEARCH_INPUT.CLEAR_BUTTON}
        className={styles.clearButton}
        onClick={onClear}
      >
        <Icon
          size={18}
          layout={IconLayouts.Circle}
          colors={IconColors.Primary}
          bordered={false}
        >
          <symbols.symbol name="x" />
        </Icon>
      </button>
    )}
    {isLoading && (
      <div className={styles.loader}>
        <Preloader className="tiny" />
      </div>
    )}
  </div>
);
