'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const mapDragging = true
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "",
    "subNavigationHide": true,
    "automationId": "add-panel-section-googleMapsGenericSection",
    "showSectionHeader": false,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/googleMapsGenericSection_en/googleMapsGenericSection_en.png",
        "imageHover": null,
        "items": [
            {
                "id": "Contact_Google_Maps_Generic_1",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 40,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inojbdl6"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 0
                    },
                    "label": "add_preset_Maps_Default_1",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Generic_2",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 40,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "TERRAIN",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inojcfnw"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 0
                    },
                    "label": "add_preset_Maps_Default_2",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Generic_3",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 21,
                        "y": 404,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "SATELLITE",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inojdyw3"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 182
                    },
                    "label": "add_preset_Maps_Default_3",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Generic_4",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 343,
                        "y": 404,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "HYBRID",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inojdyvn"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 182
                    },
                    "label": "add_preset_Maps_Default_4",
                    "tags": null
                }
            }
        ],
        "compTypes": ["wysiwyg.viewer.components.GoogleMap"]
    },
    "help": {"hide": false, "text": "add_section_info_text_googlemap"}
}
