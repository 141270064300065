// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '@/addPanelDataConsts';

const title = 'add_section_label_registrationsforms';
const presetTitle = 'add_section_label_registrationsforms_preset';
const tooltipTitle = 'add_section_label_registrationsforms_tooltip_title';
const subNavigationTitle = 'add_section_registrations_sidebar';
const helpText = 'add_section_label_registrationsforms_tooltip_description';

const getRegistrationsSection = (editorAPI) => {
  return {
    registrationsSection: {
      type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
      hide: false,
      title,
      topTitle: title,
      tooltipTitle,
      sectionName: 'registrationsSection',
      appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
      automationId: 'add-panel-section-contestsSection',
      showSectionHeader: true,
      subNavigationHide: false,
      presetTitle,
      subNavigationTitle,
      additionalBehaviours: {
        labelMode: 'none',
        infoIcon: 'none',
        hoverImageAction: 'scale',
        iconEnabledForComps: {},
      },
      props: {
        image:
          'addPanelData/sections/formContestsSection_en/formsContestsSection_en.v7.png',
        imageStyle: wixFormsConstants.imageStyle,
        imageHover: null,
        items: [
          {
            id: 'Contests_Forms_1',
            onDrop: wixFormsUtils.onDrop(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY03,
            ),
            onClick: wixFormsUtils.onClick(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY03,
            ),
            structure: wixFormsUtils.getFormWidget({
              layout: {
                width: 610,
                height: 522,
                x: 185,
                y: 15,
                scale: 1.0,
                rotationInDegrees: 0.0,
                fixedPosition: false,
              },
            }),
            preset: {
              rect: { width: 162, height: 186, x: 0, y: 0 },
              tags: null,
            },
          },
          {
            id: 'Contests_Forms_2',
            onDrop: wixFormsUtils.onDrop(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY04,
            ),
            onClick: wixFormsUtils.onClick(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY04,
            ),
            structure: wixFormsUtils.getFormWidget({
              layout: {
                width: 580,
                height: 663,
                x: 185,
                y: 15,
                scale: 1.0,
                rotationInDegrees: 0.0,
                fixedPosition: false,
              },
            }),
            preset: {
              rect: { width: 162, height: 186, x: 162, y: 0 },
              tags: null,
            },
          },
          {
            id: 'Contests_Forms_3',
            onDrop: wixFormsUtils.onDrop(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY05,
            ),
            onClick: wixFormsUtils.onClick(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY05,
            ),
            structure: wixFormsUtils.getFormWidget({
              layout: {
                width: 630,
                height: 616,
                x: 185,
                y: 15,
                scale: 1.0,
                rotationInDegrees: 0.0,
                fixedPosition: false,
              },
            }),
            preset: {
              rect: { width: 162, height: 176, x: 0, y: 186 },
              tags: null,
            },
          },
          {
            id: 'Contests_Forms_4',
            onDrop: wixFormsUtils.onDrop(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY06,
            ),
            onClick: wixFormsUtils.onClick(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY06,
            ),
            structure: wixFormsUtils.getFormWidget({
              layout: {
                width: 366,
                height: 757,
                x: 185,
                y: 15,
                scale: 1.0,
                rotationInDegrees: 0.0,
                fixedPosition: false,
              },
              controllerType:
                wixFormsConstants.CONTROLLER_TYPES.MULTI_STEP_FORM,
            }),
            preset: {
              rect: { width: 162, height: 176, x: 162, y: 186 },
              tags: null,
            },
          },
          {
            id: 'Contests_Forms_5',
            onDrop: wixFormsUtils.onDrop(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY07,
            ),
            onClick: wixFormsUtils.onClick(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY07,
            ),
            structure: wixFormsUtils.getFormWidget({
              layout: {
                width: 900,
                height: 274,
                x: 185,
                y: 15,
                scale: 1.0,
                rotationInDegrees: 0.0,
                fixedPosition: false,
              },
            }),
            preset: {
              rect: { width: 324, height: 89, x: 0, y: 362 },
              tags: null,
            },
          },
          {
            id: 'Contests_Forms_6',
            onDrop: wixFormsUtils.onDrop(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY08,
            ),
            onClick: wixFormsUtils.onClick(
              editorAPI,
              wixFormsConstants.FormPreset.CONTEST_ENTRY08,
            ),
            structure: wixFormsUtils.getFormWidget({
              layout: {
                width: 931,
                height: 226,
                x: 185,
                y: 15,
                scale: 1.0,
                rotationInDegrees: 0.0,
                fixedPosition: false,
              },
            }),
            preset: {
              rect: { width: 324, height: 116, x: 0, y: 451 },
              tags: null,
            },
          },
        ],
        compTypes: [wixFormsConstants.componentType],
      },
      help: {
        hide: false,
        text: helpText,
      },
    },
  };
};

export default (editorAPI) => getRegistrationsSection(editorAPI);
