import type { EditorAPI } from '@/editorAPI';
import { createMyWidgetsSectionsAddPanelV2 as createMyWidgetsSections } from '@/platform';
import {
  appsStore,
  components,
  type EditorState,
  interactions,
  platform,
  savedComponents,
  schoolMode,
} from '@/stateManagement';
import { tpaUtils } from '@/util';
import { isDataModeOn } from '@/wixData';
import { wixStoreDataProvider } from '@/wixStore';
import type { ConditionHelpers } from '@wix/add-panel-component';
import {
  DATA_BINDING,
  ADMIN_PAGES,
  MEMBERS_AREA,
} from '@wix/app-definition-ids';
import _ from 'lodash';

interface CreateConditionHelpersArgs {
  editorAPI: EditorAPI;
}

export const getSavedComponents = (state: EditorState) =>
  savedComponents.selectors.getSavedComponents(state);

export const createNewAddPanelConditionHelpers = ({
  editorAPI,
}: CreateConditionHelpersArgs): ConditionHelpers => {
  const getEditorState = () => editorAPI.store.getState();

  return {
    isSectionAvailable: (section) => {
      const addPanelOverriding =
        editorAPI.componentFocusMode.getAddPanelOverriding();
      if (!addPanelOverriding) {
        return true;
      }
      return addPanelOverriding.includes(section.sectionName);
    },
    isRepeaterItem: () => {
      const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
      return components.selectors.isRepeaterItem(
        editorAPI.columns.isStrip(selectedComponent)
          ? editorAPI.columns.getColumnIfStripIsSingleColumn(selectedComponent)
          : selectedComponent,
        editorAPI.dsRead,
      );
    },
    isMultilingualModeOn: () =>
      editorAPI.dsRead.language.multilingual.isEnabled(),
    isSuperAppInstalled: (superAppId) =>
      editorAPI.dsRead.platform.isAppActive(superAppId),
    isMembersAppInstalled: () => editorAPI.tpa.app.isInstalled(MEMBERS_AREA),
    isRefComponentSupported: () =>
      platform.selectors.isRefComponentSupported(editorAPI),
    isTPAInstalled: (tpaId) => {
      if (tpaId === tpaUtils.getStoresAppDefId()) {
        return wixStoreDataProvider.isAppInstalled(editorAPI);
      }
      return editorAPI.tpa.app.isInstalled(tpaId);
    },
    isEducationModeOn: () => schoolMode.selectors.isEnabled(getEditorState()),
    isLightboxModeOn: () => editorAPI.dsRead.pages.popupPages.isPopupOpened(),
    isFullWidthLightboxModeOn: () =>
      editorAPI.pages.popupPages.isFullWidthPopupOpened(),
    isMobileEditorOn: () => editorAPI.isMobileEditor(),
    isInteractionModeOn: () =>
      interactions.selectors.isInInteractionMode(getEditorState()),
    isDataModeOn: () => isDataModeOn(editorAPI),
    isDevModeOn: () => editorAPI.developerMode.isEnabled(),
    isAdminPage: () => {
      const currentPage = editorAPI.components.data.get(
        editorAPI.pages.getFocusedPage(),
      );
      return currentPage.managingAppDefId === ADMIN_PAGES;
    },
    isProvisionedOn: () => editorAPI.documentServices.wixCode.isProvisioned(),
    hasSavedComponents: () => {
      const savedComponents = getSavedComponents(getEditorState());
      return Boolean(savedComponents?.length);
    },
    hasMyWidgets: () => {
      const myWidgetsSections = createMyWidgetsSections(editorAPI);
      return Boolean(myWidgetsSections?.length);
    },
    isStaticPagesOverLimit: () => {
      const staticPagesCount = editorAPI.pages.getStaticPagesCount();

      return !editorAPI.pages.canAddStaticPage(staticPagesCount);
    },
    isConnectablePage: () => {
      const schemas = appsStore.selectors.getAppStore(
        getEditorState(),
        DATA_BINDING,
      )?.schemas;

      return schemas?.length > 0;
    },
    isCustomMenuEnabled: () => editorAPI.menus.__UNSAFE__isCustomMenusEnabled(),
    hasCustomTheme: (compType) => {
      const compDef = editorAPI.components.getDefinition(compType);

      if (!compDef) {
        return false;
      }
      const themeMap = editorAPI.theme.styles.getAll();
      return Object.keys(compDef.styles).some((key) => {
        if (!themeMap[key]) {
          return false;
        }

        const defaultStyle = editorAPI.theme.styles.getDefaultThemeStyle(
          compType,
          key,
        );

        const currentStyle = themeMap[key];
        const isStylableComponent =
          currentStyle.style.properties.hasOwnProperty('$st-css');

        if (isStylableComponent) {
          return !_.isEqual(defaultStyle.style, currentStyle.style);
        }

        return !!currentStyle.metaData?.sig;
      });
    },
  };
};
