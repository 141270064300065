import { pages } from '@/stateManagement';
import { biLogger, sections } from '@/util';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';
import type {
  MapStateToProps,
  MapDispatchToProps,
  StateMapperArgs,
} from 'types/redux';
import type { LandingPageIndicationOwnProps } from './LandingPageIndication';
import { clickOnGoToLayoutSettingsForHideHeaderAndFooterFeature } from '@wix/bi-logger-editor/v2';

const TOP_LABEL_MIN_FIRST_SECTIONS_HEIGHT = 82;

export interface LandingPageIndicationStateProps {
  shouldHide: boolean;
  tooltipDisabled: boolean;
}

export interface LandingPageIndicationDispatchProps {
  openPagesLandingTab: () => void;
  logLandingPageLinkClicked: () => void;
}

export const mapStateToProps: MapStateToProps<
  LandingPageIndicationStateProps,
  LandingPageIndicationOwnProps
> = ({ editorAPI }, ownProps) => {
  const firstSectionHeight = editorAPI.components.layout.get_size(
    editorAPI.sections.getPageSections(editorAPI.pages.getCurrentPage())[0],
  )?.height;

  const pagesData = editorAPI.pages.getPagesData();
  const currentPageId = editorAPI.pages.getCurrentPageId();
  const { tpaApplicationId, managingAppDefId } = pagesData.find(
    ({ id: pageId }) => pageId === currentPageId,
  );
  const isTpaPage = Boolean(tpaApplicationId || managingAppDefId);

  const shouldHide =
    ownProps.type === 'topPage' &&
    firstSectionHeight < TOP_LABEL_MIN_FIRST_SECTIONS_HEIGHT;

  return {
    shouldHide,
    tooltipDisabled: isTpaPage,
  };
};

const getEditorAPI = (
  dispatch: any,
  getState: () => any,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  LandingPageIndicationDispatchProps,
  LandingPageIndicationOwnProps
> = (dispatch, ownProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openPagesLandingTab: () => {
      editorAPI.panelHelpers.openPagesPanel({
        initialSettingsTabType: constants.PAGE_SETTINGS_TABS.LAYOUT,
      });
    },
    logLandingPageLinkClicked: () => {
      const closestSiteSegment =
        ownProps.type === 'topPage'
          ? editorAPI.siteSegments.getHeader()
          : editorAPI.siteSegments.getFooter();

      const [params] = editorAPI.bi.getComponentsBIParams([closestSiteSegment]);

      biLogger.report(
        clickOnGoToLayoutSettingsForHideHeaderAndFooterFeature({
          ...params,
          origin: 'no header/footer',
        }),
      );
    },
  };
};

export const shouldShowLandingPageIndication = (
  stateMapperArgs: StateMapperArgs,
) => {
  if (!sections.isSectionsEnabled()) {
    return false;
  }

  const { editorAPI, state } = stateMapperArgs;
  const isMobile = editorAPI.isMobileEditor();

  const pageId = pages.selectors.getFocusedPageId(state);
  const isLandingPage = editorAPI.pages.isLandingPage(pageId);

  return isMobile && isLandingPage;
};
