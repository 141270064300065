import {
  type GetDefaultPrivacyStatusResponse,
  type SetDefaultPrivacyStatusRequest,
  type SetDefaultPrivacyStatusResponse,
  Privacy,
} from '@wix/ambassador-members-v1-default-privacy/types';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
import * as util from '@/util';

const BASE_URL = `${util.serviceTopology.editorRootUrl}_api/members/v1/default-privacy-status`;

const defaultPrivacyStatusUrl = {
  getDefaultPrivacyStatus: `${BASE_URL}`,
  setDefaultPrivacyStatus: `${BASE_URL}`,
};

const patchToEndpoint = (
  url: string,
  metaSiteInstance: string,
  requestBody: any,
) =>
  util.http.fetchJson(url, {
    credentials: 'same-origin',
    method: 'PATCH',
    body: JSON.stringify(requestBody),
    headers: new Headers({ Authorization: metaSiteInstance }),
  });

const fetchFromEndpoint = (url: string, metaSiteInstance: string) =>
  util.http.fetchJson(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: new Headers({ Authorization: metaSiteInstance }),
  });

const getDefaultPrivacyStatus = (metaSiteInstance: string) =>
  fetchFromEndpoint(
    defaultPrivacyStatusUrl.getDefaultPrivacyStatus,
    metaSiteInstance,
  );

const setDefaultPrivacyStatus = (
  metaSiteInstance: string,
  defaultPrivacy: Privacy,
  revision?: string,
): Promise<SetDefaultPrivacyStatusResponse> => {
  const request: SetDefaultPrivacyStatusRequest = {
    defaultPrivacy: { defaultPrivacy, revision },
  };
  return patchToEndpoint(
    defaultPrivacyStatusUrl.setDefaultPrivacyStatus,
    metaSiteInstance,
    request,
  );
};

// The revision value is used for checking the optimistic lock condition. Changes on every write.
export interface JoinCommunityStatus {
  joinCommunity: boolean;
  revision?: string;
}

export const createJoinCommunityStatusApi = (editorAPI: EditorAPI) => {
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  const getJoinCommunityStatus = async (): Promise<JoinCommunityStatus> => {
    const response: GetDefaultPrivacyStatusResponse =
      await getDefaultPrivacyStatus(metaSiteInstance);
    const joinCommunity =
      response.defaultPrivacy?.defaultPrivacy === Privacy.PUBLIC;
    return { joinCommunity, revision: response.defaultPrivacy?.revision };
  };

  const setJoinCommunityStatus = async (
    joinCommunity: boolean,
    revision?: string,
  ): Promise<JoinCommunityStatus> => {
    const defaultPrivacy = joinCommunity ? Privacy.PUBLIC : Privacy.PRIVATE;
    const response: SetDefaultPrivacyStatusResponse =
      await setDefaultPrivacyStatus(metaSiteInstance, defaultPrivacy, revision);
    editorAPI.siteMembers.setJoinCommunityStatus(joinCommunity);
    return { joinCommunity, revision: response.defaultPrivacy?.revision };
  };

  return {
    getJoinCommunityStatus,
    setJoinCommunityStatus,
  };
};
