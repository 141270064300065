import React, { type ReactNode } from 'react';

import * as _ from 'lodash';
import { Button } from '@wix/wix-base-ui';
import styles from './dashboardShortcut.scss';
import withDropDownOnHover from '../hocs/withDropDownOnHover';
import withOpenDashboard, {
  type WithOpenDashboardProps,
} from '../hocs/withOpenDashboard/withOpenDashboard';

export interface DashboardShortcutOwnProps extends WithOpenDashboardProps {
  children: ReactNode;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}
export interface DashboardShortcutProps
  extends DashboardShortcutOwnProps,
    WithOpenDashboardProps {}

const DashboardShortcut = (props: DashboardShortcutProps) => {
  const btnClassName = `btn-text ${styles.dashboardShortcutWrapper}`;

  const handleClick = () => props.openDashboard();

  return (
    <div onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <Button className={btnClassName} onClick={handleClick}>
        {props.children}
      </Button>
    </div>
  );
};

export default _.flow(
  withOpenDashboard,
  withDropDownOnHover,
)(DashboardShortcut);
