import * as util from '@/util';
import * as stateManagement from '@/stateManagement';

const { bi, panels, mobile } = stateManagement;

const mapStateToProps = ({ dsRead }: AnyFixMe) => ({
  isMobileOptimizedSite: dsRead.mobile.isOptimized(),
});

const mapDispatchToProps = {
  sendBi: bi.actions.event,
  closePanelByName: panels.actions.closePanelByName,
  openHelpCenter: panels.actions.openHelpCenter,
  setMobileOptimizedSite: mobile.actions.setMobileOptimizedSite,
};

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  mapStateToProps,
  mapDispatchToProps,
);
