import React, { type FC, useEffect, useState } from 'react';
import _ from 'lodash';
import experiment from 'experiment';
import { translate } from '@/i18n';
import * as util from '@/util';

import withSendBi from '../hocs/withSendBi';
import withMenuItems from '../hocs/withMenuItems/withMenuItems';

import withDelayedOpenDropDownBehavior from './behaviors/withDelayedOpenDropDownBehavior';
import withActiveItemBehavior from './behaviors/withActiveItemBehavior';
import withKeyboardBehavior from './behaviors/withKeyboardBehavior';

import IllustratedListPanel from '../dropPanel/illustratedListPanel';
import MenuBarDropDownItem from './menuBarDropDownItem';
import SaleIndicatorA from '../menuBar/saleIndicator/saleIndicatorA';
import SaleIndicatorB from '../menuBar/saleIndicator/saleIndicatorB';
import { delay } from './menuBar.utils';
import { mapStateToProps, mapDispatchToProps } from './menuBar.mapper';

import type { MenuBarProps } from './menuBar.types';

const _delay = delay();
const TIME_IN_WHAT_WE_DECLARE_THAT_USER_SAW_SALE_TOOLTIP = 3000;

export const MenuBar: FC<MenuBarProps> = (props) => {
  const currentSaleId = props.saleEndDate ? props.saleEndDate.toString() : null;
  const [isEndOfSaleInCurrentSession, setIsEndOfSaleInCurrentSession] =
    useState(false);
  const [lastActiveShownSaleSession, setLastActiveShownSaleSession] = useState<
    string | undefined
  >(undefined);
  const [
    couldChangeLocallyLastActiveShownSaleSession,
    setCouldChangeLocallyLastActiveShownSaleSession,
  ] = useState(true);

  useEffect(() => {
    if (
      !props.sitePreferencesFetchDone ||
      !props.saleEndDate ||
      !experiment.isOpen('se_saleIndicationOnUpgradeButton')
    )
      return;

    const timeUntilTheEndOfTheSale = props.saleEndDate - Date.now();
    const timeoutId = setTimeout(() => {
      setIsEndOfSaleInCurrentSession(true);
    }, timeUntilTheEndOfTheSale);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.saleEndDate, props.sitePreferencesFetchDone]);

  useEffect(() => {
    if (couldChangeLocallyLastActiveShownSaleSession) {
      setLastActiveShownSaleSession(props.lastActiveShownSaleSession);
    }
  }, [
    props.lastActiveShownSaleSession,
    couldChangeLocallyLastActiveShownSaleSession,
  ]);

  const shouldShowSaleIndicator =
    props.sitePreferencesFetchDone &&
    props.isSalePeriod &&
    currentSaleId &&
    currentSaleId !== lastActiveShownSaleSession &&
    !isEndOfSaleInCurrentSession &&
    experiment.isOpen('se_saleIndicationOnUpgradeButton');

  const renderPanelContent = (item: AnyFixMe) => {
    const subItems = item.getListItems && item.getListItems();
    if (subItems) {
      return (
        <IllustratedListPanel
          menuKey={item.key}
          sendBi={props.sendBi}
          automationId={item.dropPanelAutomationId}
          isChecklist={item.isChecklist}
          closePanel={() => props.closeDropPanel(true)}
          items={subItems}
          initialSelectedItem={props.initialSelectedSubItem}
          hasDesignPermissions={props.hasDesignPermissions}
        />
      );
    }

    if (item.DropPanel) {
      return <item.DropPanel menuKey={item.key} sendBi={props.sendBi} />;
    }

    return null;
  };

  const setUserSawSaleTooltipInCurrentSaleWithDelay = () => {
    _delay.set(() => {
      if (currentSaleId) {
        setCouldChangeLocallyLastActiveShownSaleSession(false);
        props.setLastActiveShownSaleSession(currentSaleId);
      }
    }, TIME_IN_WHAT_WE_DECLARE_THAT_USER_SAW_SALE_TOOLTIP);
  };

  const abortSetUserSawSaleTooltipInCurrentSale = () => {
    _delay.abort();
  };

  const getSaleIndicatorComponent = (isActive: boolean) => {
    switch (experiment.getValue('se_saleIndicationOnUpgradeButton')) {
      case 'A':
        return <SaleIndicatorA isActive={isActive} />;

      case 'B':
        return <SaleIndicatorB isActive={isActive} />;

      default:
        return null;
    }
  };

  const { type, menuItems, getActiveItemIndex } = props;
  return (
    <div
      data-aid="top-bar-menu-bar"
      className={util.cx('top-bar-menu-bar-wrapper', {
        'with-separator': type === 'right' && !!menuItems.length,
      })}
    >
      {menuItems.map((item, itemIndex) => {
        const isActive = getActiveItemIndex() === itemIndex;
        const prefix =
          item.key === 'UPGRADE' &&
          shouldShowSaleIndicator &&
          experiment.isOpen('se_saleIndicationOnUpgradeButton')
            ? getSaleIndicatorComponent(isActive)
            : null;

        return (
          <MenuBarDropDownItem
            key={item.key}
            handlerKey={item.key}
            title={translate(item.title)}
            prefix={prefix}
            className={item.className}
            automationId={item.automationId}
            getCounter={item.getCounter}
            hasNotification={item.hasNotification}
            isActive={isActive}
            onMouseEnter={() => {
              if (item.onMouseEnter) {
                item.onMouseEnter(!!prefix);
              }
            }}
            onMouseLeave={item.onMouseLeave}
            openedDropPanel={props.openedDropPanel}
            openDropPanel={(key: string) => {
              props.openDropPanel(key);
              if (experiment.isOpen('se_saleIndicationOnUpgradeButton'))
                setUserSawSaleTooltipInCurrentSaleWithDelay();
            }}
            closeDropPanel={(force: boolean) => {
              props.closeDropPanel(force);
              if (experiment.isOpen('se_saleIndicationOnUpgradeButton'))
                abortSetUserSawSaleTooltipInCurrentSale();
            }}
            delayedFadeOut={props.delayedFadeOut}
            dropPanelKey={item.key}
            dropPanelContent={renderPanelContent(item)}
            panelClassName={item.panelClassName}
            onClick={item.onClick}
          />
        );
      })}
    </div>
  );
};

const MenuBarWithBehaviors = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  withKeyboardBehavior,
  withActiveItemBehavior,
  withDelayedOpenDropDownBehavior,
  withSendBi,
  withMenuItems,
)(MenuBar);

export default MenuBarWithBehaviors;
