// @ts-nocheck
const setValue = function (editorAPI, msg, callback) {
  if (!msg.compId) {
    // install pricing plans -> open settings -> go to preview
    // while going to preview, component is deselected, so compId of tpaSettings gets replaced to undefined
    // given no compId means no compRef, and a failure in DS
    console.warn('an attempt to set tpa data without compId: ', msg);
    return;
  }

  const compPointer = editorAPI.components.get.byId(msg.compId);
  editorAPI.dsActions.tpa.data.set(
    compPointer,
    msg.data.key,
    msg.data.value,
    msg.data.scope,
    callback,
  );
};

const getValue = function (editorAPI, msg, callback) {
  if (msg.data.scope === editorAPI.dsRead.tpa.data.SCOPE.APP) {
    const origCompData = editorAPI.selection.getSelectedComponentData() || {};
    editorAPI.dsRead.tpa.data.app.get(
      origCompData.applicationId,
      msg.data.key,
      callback,
    );
  } else {
    const compPointer = editorAPI.components.get.byId(msg.compId);
    editorAPI.dsRead.tpa.data.comp.get(compPointer, msg.data.key, callback);
  }
};

const removeValue = function (editorAPI, msg, callback) {
  const compPointer = editorAPI.components.get.byId(msg.compId);
  editorAPI.dsActions.tpa.data.remove(
    compPointer,
    msg.data.key,
    msg.data.scope,
    callback,
  );
};

const getValues = function (editorAPI, msg, callback) {
  if (msg.data.scope === editorAPI.dsRead.tpa.data.SCOPE.APP) {
    const origCompData = editorAPI.selection.getSelectedComponentData();
    editorAPI.dsRead.tpa.data.app.getMulti(
      origCompData.applicationId,
      msg.data.keys,
      callback,
    );
  } else {
    const compPointer = editorAPI.components.get.byId(msg.compId);
    editorAPI.dsRead.tpa.data.comp.getMulti(
      compPointer,
      msg.data.keys,
      callback,
    );
  }
};

export { setValue, removeValue, getValue, getValues };
