import { createNonThemedAction } from '../utils/nonThemedAction';
import type { NonThemedActionCreationData } from '../utils/textAction';

export const TextDecoration = {
  Underline: 'underline',
  Strike: 'line-through',
  None: 'none',
} as const;

export type TextDecoration =
  (typeof TextDecoration)[keyof typeof TextDecoration];

const underlineActionCreationData: Readonly<
  NonThemedActionCreationData<string>
> = {
  execCommand: (textManager, _actionInput) => {
    textManager.commands.underline();
  },
  getWixRichTextCommandState: (textManager) =>
    textManager.getCommandsStates().underline
      ? TextDecoration.Underline
      : undefined,
  execRevertCommand: (textManager) => {
    if (textManager.getCommandsStates().underline) {
      textManager.commands.underline();
    } else {
      // We do it twice to remove underline from the middle of the text
      // first underline makes all text underlined second removes all underline
      textManager.commands.underline();
      textManager.commands.underline();
    }
  },
  createUpdatedStyleProperties: (actionInput) => ({
    textDecoration:
      actionInput === TextDecoration.Underline
        ? TextDecoration.Underline
        : TextDecoration.None,
  }),
  getPropertyValue: (styleProperties) => styleProperties.textDecoration,
  getDefaultValue: () => TextDecoration.None,
};

export const underlineAction = createNonThemedAction(
  underlineActionCreationData,
);
