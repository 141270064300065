import React from 'react';
import { Button, TextButton } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import styles from './actionButtons.scss';

interface ActionButtonsProps {
  onMainAction: () => void;
  onSecondaryAction: () => void;
}

export const ActionButtons = ({
  onMainAction,
  onSecondaryAction,
}: ActionButtonsProps) => {
  return (
    <div className={styles.buttonContainer}>
      <Button onClick={onMainAction} dataHook="add-new-collection-button">
        {translate('SiteApp_WixData_DataManager_Tab_NewCollection_Button')}
      </Button>
      <TextButton
        onClick={onSecondaryAction}
        dataHook="add-a-preset-button"
        size="small"
      >
        {'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_PRESET'}
      </TextButton>
    </div>
  );
};
