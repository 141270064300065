import constants from '@/constants';
import addPanelDataConsts from '@/addPanelDataConsts';
import { panels, bi } from '@/stateManagement';
import { addPanel, panelUtils, scroll } from '@/util';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';

import { convertPageIdToLinkPageId } from '../../utils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { ThunkAction, StateMapperArgs, Dispatch } from 'types/redux';
import type { PopupLinkComponentOwnProps } from './PopupLink';
import type { TPageLink } from '../../types';

const LIGHTBOX_COMPONENT_TYPE = 'wysiwyg.viewer.components.PopupContainer';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

interface OpenAddPanelPopupSectionProps {
  onComponentAddedToStage(compRef: CompRef): void;
}

const openAddPanelPopupSection = (props: OpenAddPanelPopupSectionProps) =>
  panels.actions.updateOrOpenPanel(
    constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
    {
      category: addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW,
      origin: 'linkPanelPopupLink',
      [addPanel.isNewPanelEnabled() ? 'sectionId' : 'section']:
        'popupWelcomeSection',
      ...props,
    },
  );

interface ShowAddLightboxSuccessNotificationProps {
  onNotificationLinkClick(): void;
}

const showAddLightboxSuccessNotification = (
  props: ShowAddLightboxSuccessNotificationProps,
) =>
  stateManagement.notifications.actions.showUserActionNotification({
    message: 'LINK_PANEL_LIGHTBOX_ADDED_BANNER_TEXT',
    title: null,
    type: constants.NOTIFICATIONS.TYPES.SUCCESS,
    link: {
      caption: 'LINK_PANEL_COMMON_BANNER_LINK',
      onNotificationLinkClick: props.onNotificationLinkClick,
    },
  });

export const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  const popups = editorAPI.pages.popupPages.getDataListOrdered();
  return {
    popups,
    focusedPageId: editorAPI.dsRead.pages.getFocusedPageId(),
    isMobileEditor: editorAPI.isMobileEditor(),
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: PopupLinkComponentOwnProps,
) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const checkIsPagePopup = (pageId: string) =>
    editorAPI.dsRead.pages.popupPages.isPopup(pageId);

  const createDefaultData = (type: string = 'PageLink') =>
    editorAPI.dsRead.data.createItem(type);

  const createPopupLink = (popupPageId: string): TPageLink => {
    const link = createDefaultData();

    link.pageId = convertPageIdToLinkPageId(popupPageId);

    return link;
  };

  const getPopupPageIdByRef = (compRef: CompRef) => {
    return editorAPI.components.getType(compRef) === LIGHTBOX_COMPONENT_TYPE
      ? editorAPI.components.getPage(compRef).id
      : null;
  };

  let prevPageId: string;
  let selectedComponentRef: CompRef;

  const sendLightboxAddedBiEvent = (compRef: CompRef) =>
    bi.actions.event(
      coreBi.events.linkPanel.LINK_PANEL_LIGHTBOX_ADDED_TO_STAGE,
      {
        origin: 'link_panel',
        component_type: editorAPI.components.getType(compRef),
        component_id: compRef.id,
        page_id: editorAPI.pages.getFocusedPage()?.id,
        target_component: editorAPI.components.getType(
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef),
        ),
      },
    );

  const backToSelectedComponent = async () => {
    await new Promise((resolve) =>
      editorAPI.pages.navigateToAndScroll(
        prevPageId,
        selectedComponentRef?.id,
        resolve,
      ),
    );

    await scroll.waitForAnimationScrollEnd(() => editorAPI.site.getScroll());

    dispatch(
      stateManagement.selection.actions.selectComponents([
        selectedComponentRef,
      ]),
    );
  };

  const onNotificationLinkClick = async () => {
    await backToSelectedComponent();
  };

  const linkPopupComponent = (popupPageId: string) => {
    const popupLink = createPopupLink(popupPageId);
    ownProps.onPopupAdded(popupLink);
    dispatch(
      showAddLightboxSuccessNotification({
        onNotificationLinkClick,
      }),
    );
  };

  const onComponentAdded = (compRef: CompRef) => {
    const popupPageId = getPopupPageIdByRef(compRef);

    if (!popupPageId) {
      return;
    }

    dispatch(sendLightboxAddedBiEvent(compRef));

    if (panelUtils.isLinkingOfNewLightboxEnabled()) {
      linkPopupComponent(popupPageId);
    }
  };

  return {
    openAddPanelPopupSection() {
      prevPageId = editorAPI.dsRead.pages.getCurrentPageId();
      selectedComponentRef =
        stateManagement.selection.selectors.getSingleSelectedComponent(
          editorAPI.store.getState(),
        );

      dispatch(
        openAddPanelPopupSection({ onComponentAddedToStage: onComponentAdded }),
      );
    },
    openHelpCenter: (helpId: string, props?: object, biHelpParams?: any) => {
      dispatch(panels.actions.openHelpCenter(helpId, props, biHelpParams));
    },

    checkIsPagePopup,

    createDefaultData,
  };
};
