// @ts-nocheck
export default {
  'wysiwyg.viewer.components.MatrixGallery': {
    helpId: '8dbda4f4-4d21-4bb7-9c94-482a1e024b3d',
    mobileHelpId: '8a76793e-5fc9-4e09-b2f0-c271bcc5ce86',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'wysiwyg.viewer.components.PaginatedGridGallery': {
    helpId: 'c478a897-5ba6-450f-bc59-cf2c766f03a7',
    mobileHelpId: 'd3fa21ab-a13e-41ff-98f8-9a92fdaed040',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'wysiwyg.viewer.components.SliderGallery': {
    helpId: 'd25b1155-bba6-4b57-9a92-5aa02e7671b6',
    mobileHelpId: '95d96e84-f3e2-4d02-96ec-4bfb08471446',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'wysiwyg.viewer.components.SlideShowGallery': {
    helpId: 'cf6af37d-13d4-42fe-8e4d-7e56cc49d71f',
    mobileHelpId: '3cddf8a8-e561-4ecb-85b3-91e061bba74a',
    hasDesktopLayoutAction: false,
    hasMobileLayoutAction: false,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Masonry': {
    helpId: 'ee4907fa-738e-447b-9bcf-21738595c1f3',
    mobileHelpId: 'ae4db956-2cad-4acd-9bb5-d4bb0429f751',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Accordion': {
    helpId: '60c72193-e2e3-484a-b3f9-c16fe4e3f0a6',
    mobileHelpId: '1e9daab7-1625-4884-82ab-741299b0b48d',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: false,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Impress': {
    helpId: '99a36d1d-4120-4f2c-9c81-344202a8ded9',
    mobileHelpId: 'fa4faf8a-81ce-4059-af0f-0e1a0901e459',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: false,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Freestyle': {
    helpId: '5e601bbe-5660-4b8a-8faa-a8b0585cb452',
    mobileHelpId: '9c593ddb-00b0-4701-94dc-cfb6e54acc29',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Collage': {
    helpId: 'caa092d0-c704-45f9-9b8a-b82bc5b277ba',
    mobileHelpId: '82c5cd85-c0e4-41b4-acec-08c95f51604a',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Honeycomb': {
    helpId: '7a44c391-6abc-4b8c-a2da-9bfa2acbca19',
    mobileHelpId: '9c092a16-dcd1-4a3e-b87e-104ecae1986c',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.StripShowcase': {
    helpId: '2817d5cb-d5ac-4765-a47f-6f58a879eaf5',
    mobileHelpId: '816ffaec-a88a-4252-adcb-294650fe72a3',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: false,
  },
  'tpa.viewer.components.StripSlideshow': {
    helpId: 'e9a4ca17-a576-4d26-89ff-951f66600fad',
    mobileHelpId: '9993e40f-c3b1-4e26-8fcb-9e44ca45996a',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'tpa.viewer.components.Thumbnails': {
    helpId: 'e501d94c-006a-401d-8059-7f49cb58c53d',
    mobileHelpId: '3f1c8b58-f002-4fc2-a3be-67c5acc7e972',
    hasDesktopLayoutAction: true,
    hasMobileLayoutAction: true,
    hasCustomDesign: true,
  },
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': {
    helpId: '0855e6cf-ea34-4484-90bc-dfbf7773406d',
    mobileHelpId: '58931e60-7f68-4029-bab4-76b7d428043d',
    hasDesktopLayoutAction: false,
    hasMobileLayoutAction: false,
    hasCustomDesign: false,
  },
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': {
    helpId: '223e4033-1ac1-4ccf-85bc-7bfad99ad6ad',
    mobileHelpId: '6e773fd5-3ae9-4a5f-b65d-a5a92f7a0373',
    hasDesktopLayoutAction: false,
    hasMobileLayoutAction: false,
    hasCustomDesign: false,
  },
};
