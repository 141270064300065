:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._siteCreationNavigationEndScreen_tnzeo_7 {
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s 300ms, opacity 300ms linear;
  z-index: 1; }
  ._siteCreationNavigationEndScreen_tnzeo_7.hidden {
    opacity: 0;
    visibility: hidden; }
  ._siteCreationNavigationEndScreen_tnzeo_7 span {
    font-family: Madefor; }

._siteCreationBackToBoAction_tnzeo_25 {
  margin-top: 48px;
  margin-right: 16px; }

._siteCreationNavigationEndContentWrapper_tnzeo_29 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

._siteCreationGoToLive_tnzeo_36 {
  margin-top: 16px;
  margin-left: 6px;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center; }
  ._siteCreationGoToLive_tnzeo_36 span {
    color: #116dff; }
  ._siteCreationGoToLive_tnzeo_36 svg {
    margin-left: 4px; }
    ._siteCreationGoToLive_tnzeo_36 svg path {
      fill: #116dff; }
  ._siteCreationGoToLive_tnzeo_36:hover span {
    text-decoration: underline; }

._siteCreationNavigationEndTitle_tnzeo_52,
._siteCreationNavigationEndSubTitle_tnzeo_53 {
  font-size: 40px;
  color: #000000;
  font-weight: 700; }

._siteCreationNavigationEndMainActionsWrapper_tnzeo_58 {
  margin-bottom: 60px; }

._siteCreationNavigationEndActions_tnzeo_61 {
  text-align: left;
  margin-right: 8vw; }

._siteCreationNavigationEndThumbnailWrapper_tnzeo_65 {
  width: 40vw; }

._siteCreationNavigationEndLoadContentWrapper_tnzeo_68 {
  position: relative;
  height: calc(100% - 102px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-height: 900px) {
    ._siteCreationNavigationEndLoadContentWrapper_tnzeo_68 {
      height: calc(100% - 82px); } }
  ._siteCreationNavigationEndLoadContentWrapper_tnzeo_68 span {
    font-size: 32px;
    color: #000000;
    font-weight: 700;
    line-height: 1.5em; }
