import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';
import type { CompLayout, CompRef } from 'types/documentServices';

const { getPreviewPosition } = stateManagement.domMeasurements.selectors;

function getLayout(editorAPI: EditorAPI, layout: CompLayout, comp: CompRef) {
  const state = editorAPI.store.getState();

  layout = _.cloneDeep(layout);

  if (editorAPI.components.layout.isShowOnFixedPosition(comp)) {
    const previewPosition = getPreviewPosition(state);
    layout.y += previewPosition.top;
    layout.bounding.y += previewPosition.top;
    layout.x += previewPosition.left;
    layout.bounding.x += previewPosition.left;
  }

  return layout;
}

function getStyle(editorAPI: EditorAPI, layout: CompLayout, comp: CompRef) {
  layout = getLayout(editorAPI, layout, comp);

  const styles = {
    top: layout.y,
    left: layout.x,
    width: layout.width,
    height: layout.height,
  };

  if (layout.rotationInDegrees) {
    const transformValue = `rotate(${layout.rotationInDegrees}deg)`;

    Object.assign(styles, {
      transform: transformValue,
      WebkitTransform: transformValue,
    });
  }

  const position = editorAPI.components.layout.isShowOnFixedPosition(comp)
    ? 'fixed'
    : 'absolute';

  Object.assign(styles, { position });

  return styles;
}

export { getStyle };
