import _ from 'lodash';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { api as menuApi, utils } from '@/menu';
import type { IPageMenuItem, IMenuConfig, GetActionsOptions } from '@/menu';
import {
  isCustomMenusEnabled,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
  editorWixRecorder,
  link,
} from '@/util';
import { DesignerPanelsApiKey } from '@/apis';

import pagesAPI from '../../../api/api';
import {
  PAGE_CATEGORIES as pageCategories,
  sendNavigateToPageBiEvent,
} from '../../../utils';
import * as menuDataGetterUtil from '../utils/menuDataGetterUtil';
import * as menuViewUtils from './menuViewUtils';
import type { EditorAPI } from '@/editorAPI';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import { TriggerSources } from '@/pages';

const { LinkTypes } = link;

const { createMenuApi } = menuApi;

const { getLinkPanelPages, getAddMenuItemsActions } = utils;

const origin = 'pagesPanel';

interface ItemClickHandler {
  (
    editorAPI: AnyFixMe,
    biCategory: AnyFixMe,
    menuId: AnyFixMe,
    selectedMenuItemId: AnyFixMe,
    onItemAdded: AnyFixMe,
  ): () => void;
}

const sendBIEvent = (
  editorAPI: EditorAPI,
  biCategory: string,
  category: string,
  event: BiEventDefinition,
  biParams?: BiEventFields,
) => {
  editorAPI.bi.event(event, {
    ...biParams,
    origin,
    category,
    source: biCategory,
  });
};

const sendBIEventOnClick = (
  editorAPI: EditorAPI,
  biCategory: string,
  category: string,
  biParams?: BiEventFields,
) => {
  const event = coreBi.events.pages.pagesPanel.add_page_selection_click;

  sendBIEvent(editorAPI, biCategory, category, event, biParams);
};

const sendBIEventOnHover = (
  editorAPI: EditorAPI,
  biCategory: string,
  category: string,
  biParams?: BiEventFields,
) => {
  const event = coreBi.events.pages.pagesPanel.hover_on_item_in_context_menu;

  sendBIEvent(editorAPI, biCategory, category, event, biParams);
};

const getOnClickAddAnchor: ItemClickHandler =
  (editorAPI, biCategory, menuId, selectedMenuItemId, onItemAdded) => () => {
    const options = { defaultLinkType: LinkTypes.ANCHOR_LINK };
    editorAPI.menus.addLink(menuId, selectedMenuItemId, onItemAdded, options);
    sendBIEventOnClick(editorAPI, biCategory, 'anchor');
  };

const getOnClickAddSection: ItemClickHandler =
  (editorAPI, biCategory, menuId, selectedMenuItemId, onItemAdded) => () => {
    const options = { defaultLinkType: LinkTypes.ANCHOR_LINK };
    editorAPI.menus.addLink(menuId, selectedMenuItemId, onItemAdded, options);
    sendBIEventOnClick(editorAPI, biCategory, 'section');
  };

const getOnClickAddDynamic: ItemClickHandler =
  (editorAPI, biCategory, menuId, selectedMenuItemId, onItemAdded) => () => {
    const options = { defaultLinkType: LinkTypes.PAGE_LINK };
    editorAPI.menus.addLink(menuId, selectedMenuItemId, onItemAdded, options);
    sendBIEventOnClick(editorAPI, biCategory, 'dynamic');
  };

const getOnClickAddLink: ItemClickHandler =
  (editorAPI, biCategory, menuId, selectedMenuItemId, onItemAdded) => () => {
    const options = { defaultLinkType: LinkTypes.EXTERNAL_LINK };
    editorAPI.menus.addLink(menuId, selectedMenuItemId, onItemAdded, options);
    sendBIEventOnClick(editorAPI, biCategory, 'link');
  };

const getOnClickAddFolder: ItemClickHandler =
  (editorAPI, biCategory, menuId, selectedMenuItemId, onItemAdded) => () => {
    editorWixRecorder.addLabel('pages_panel_dnd_add_folder');
    editorAPI.menus.addFolder(menuId, selectedMenuItemId, onItemAdded);
    sendBIEventOnClick(editorAPI, biCategory, 'dropdown menu');
  };

const getOnClickAddPage =
  (editorAPI: AnyFixMe, origin: string, biCategory: string) =>
  (clickOrigin: AnyFixMe) => {
    pagesAPI.openAddPagePanel(editorAPI, {
      origin,
      biCategory,
      clickOrigin,
    });
    sendBIEventOnClick(editorAPI, biCategory, 'page', {
      page_id: null,
      button: clickOrigin,
    });
  };

const getPageId = (editorAPI: EditorAPI) => {
  return editorAPI.dsRead.pages.getFocusedPageId();
};

const navigateToPage = (
  editorAPI: AnyFixMe,
  pageId: AnyFixMe,
  biCategory: AnyFixMe,
  panelName: AnyFixMe,
) => {
  const newCurPageId = getPageId(editorAPI);

  if (newCurPageId !== pageId) {
    editorAPI.pages.navigateTo(pageId);
    sendNavigateToPageBiEvent(editorAPI, {
      pageId,
      biCategory,
      panelName,
    });
  }
};

const navigateToAnchor = (
  editorAPI: AnyFixMe,
  pageId: AnyFixMe,
  biCategory: AnyFixMe,
  panelName: AnyFixMe,
  anchorDataId: AnyFixMe,
) => {
  const newCurPageId = getPageId(editorAPI);

  if (newCurPageId !== pageId || anchorDataId) {
    editorAPI.pages.navigateToAndScroll(
      pageId,
      anchorDataId,
      editorAPI.scroll.applyPreviewScrollToEditorScroll,
    );
    sendNavigateToPageBiEvent(editorAPI, {
      pageId,
      biCategory,
      panelName,
    });
  }
};

const navigateToDynamicPage = (
  editorAPI: AnyFixMe,
  routerId: AnyFixMe,
  innerRoute: AnyFixMe,
  biCategory: AnyFixMe,
  panelName: AnyFixMe,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const dynamicPageByRouter = _.assign(
    editorAPI.dsRead.data.createItem('DynamicPageLink'),
    {
      innerRoute: innerRoute || '',
      routerId,
    },
  );
  editorAPI.pages.navigateTo(dynamicPageByRouter);
  sendNavigateToPageBiEvent(editorAPI, {
    biCategory,
    panelName,
  });
};

const getShouldShowAddFolderButton = (isInMultiLingualFlow: boolean) => {
  if (isInMultiLingualFlow || isNewPagesPanelEnabled()) {
    return false;
  }

  if (isCustomMenusEnabled()) {
    return isMainMenuFlowEnabled();
  }

  return true;
};

const mapStateToProps = (
  { editorAPI }: AnyFixMe,
  { isDesktop, menu, collapsionMap }: AnyFixMe,
) => {
  const membersAppDefId =
    editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;
  const renderedMenuItems = menuViewUtils.getMenuItemsWithInitialCollaptionData(
    menu.menuItems,
    collapsionMap,
  );
  const renderedMenuItemsWithSymbol =
    menuViewUtils.getMenuItemsWithsymbolIfApplicable(
      renderedMenuItems,
      menu.appDefId,
      membersAppDefId,
    );
  const biCategory = pageCategories.MENUS.getBiCategory(menu.id);
  const isInMultiLingualFlow =
    editorAPI.language.multilingual.isEnabled() &&
    !stateManagement.multilingual.services.utils.currentIsOriginal(editorAPI);

  const shouldShowAddFolderButton =
    getShouldShowAddFolderButton(isInMultiLingualFlow);

  const shouldShowAddLinkButton =
    !isCustomMenusEnabled() && !isInMultiLingualFlow;

  const showDynamicPages = getLinkPanelPages(editorAPI, false).length > 0;
  const loadingPageId = editorAPI.pages.loading.getPageId();
  const loadingTrigger = editorAPI.pages.loading.getTrigger();
  const setLoadingTrigger = (nonePageId?: string) => {
    editorAPI.pages.loading.setTrigger({
      source: TriggerSources.MENU_ITEM,
      nonePageId,
    });
  };

  return {
    staticPagesCount: editorAPI.pages.getStaticPagesCount(),
    title: menu.title,
    menuItems: renderedMenuItemsWithSymbol,
    isDraggable: isDesktop,
    renameEnabled: isDesktop,
    isMobile: !isDesktop,
    initialActions: !isDesktop && menu.appDefId ? null : ([] as AnyFixMe[]),
    showAddOptions:
      isDesktop &&
      menu.appDefId !==
        editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId,
    biCategory,
    titleAdd: pageCategories.MENUS.titleAdd,
    getTooManyPagesFrequencyCount:
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        constants.USER_PREFS.TOO_MANY_PAGES.FREQUENCY_COUNT,
      )(editorAPI.store.getState()) || 0,
    shouldShowAddFolderButton,
    shouldShowAddLinkButton,
    showDynamicPages,
    loadingPageId,
    setLoadingTrigger,
    loadingTrigger,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
  {
    onShowSettings,
    onItemAdded,
    onRename,
    isDesktop,
    onHelp,
    menu,
    selectedMenuItemId,
    biPanelName,
    setInPrefIscollapsedMenuItems,
  }: AnyFixMe,
) => {
  const editorAPI = dispatch(getEditorAPI);
  const state = editorAPI.store.getState();
  const useOriginalLanguage = editorAPI.menus.shouldUseOriginalLanguage();
  const rawMenuData = editorAPI.dsRead.menu.getById(
    menu.id,
    useOriginalLanguage,
  );
  const membersAppDefId =
    editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;
  const biCategory = pageCategories.MENUS.getBiCategory(menu.id);
  const contextMenuActions = stateManagement.contextMenu.actions;
  const isEditorLoadedWithMoreThanMaxStaticPages =
    !!stateManagement.pages.selectors.getIsEditorLoadedWithMoreThanMaxStaticPages(
      state,
    );
  const menuAPI = createMenuApi(editorAPI);
  const isTranslating =
    stateManagement.multilingual.selectors.isTranslating(state);

  function createOnClickHandler(callback: ItemClickHandler) {
    return callback(
      editorAPI,
      biCategory,
      menu.id,
      selectedMenuItemId,
      onItemAdded,
    );
  }

  const onAddLinkClick = createOnClickHandler(getOnClickAddLink);
  const onAddFolderClick = createOnClickHandler(getOnClickAddFolder);
  const onAddAnchorClick = createOnClickHandler(getOnClickAddAnchor);
  const onAddDynamicClick = createOnClickHandler(getOnClickAddDynamic);
  const onAddSectionClick = createOnClickHandler(getOnClickAddSection);

  const getAddItemsActions = (
    option: GetActionsOptions,
    config?: IMenuConfig,
  ) =>
    getAddMenuItemsActions(
      {
        ...option,
        onAddLinkClick,
        onAddAnchorClick,
        onAddDynamicClick,
        onAddSectionClick,
        onAddDropdownClick: onAddFolderClick,
      },
      config,
    );

  const onAddPagesSelectionClick = (category: string) => {
    sendBIEventOnClick(editorAPI, biCategory, category);
  };

  const onContextMenuItemsHover = (category: string, target: string) => {
    const pageId = getPageId(editorAPI);

    sendBIEventOnHover(editorAPI, biCategory, category, {
      target,
      page_id: pageId,
    });
  };

  return {
    onContextMenuItemsHover,
    onAddPagesSelectionClick,
    onComponentMount: () => {
      if (isCustomMenusEnabled()) {
        editorWixRecorder.addLabel('custom_menus');
        editorWixRecorder.addLabel('pages_panel_open');
        editorWixRecorder.addCustomAttribute(
          'custom_menus_number',
          String(menuAPI.getCustomMenus().length),
        );
      }
    },
    isEditorLoadedWithMoreThanMaxStaticPages,
    biEvent: editorAPI.bi.event,
    openPanel: editorAPI.panelManager.openPanel,
    onMoveMenuItem: (
      fromId: AnyFixMe,
      toId: AnyFixMe,
      position: AnyFixMe,
      callback: AnyFixMe,
    ) =>
      editorAPI.menus.moveMenuItem(
        rawMenuData,
        fromId,
        toId,
        position,
        true,
        callback,
      ),
    navigateCallback: (menuItem: AnyFixMe) => {
      if (menuItem.type.isPage) {
        navigateToPage(
          editorAPI,
          menuItem.pageData.id,
          biCategory,
          biPanelName,
        );
      } else if (menuItem.type.isLink && menuItem.type.raw === 'AnchorLink') {
        const anchorLink = menuItem.link;
        const pageId = anchorLink.pageId && anchorLink.pageId.replace('#', '');
        navigateToAnchor(
          editorAPI,
          pageId,
          biCategory,
          biPanelName,
          anchorLink.anchorDataId,
        );
      } else if (
        menuItem.type.isLink &&
        menuItem.type.raw === 'DynamicPageLink'
      ) {
        navigateToDynamicPage(
          editorAPI,
          menuItem.link.routerId,
          menuItem.link.innerRoute,
          biCategory,
          biPanelName,
        );
      }
    },
    findMenuItemById: editorAPI.menus.findMenuItemById,
    mainMenuPanelContext: editorAPI.mainMenu.panelContext,
    titleAdd: pageCategories.MENUS.titleAdd,
    titleAddMenuItem: pageCategories.MENUS.titleAddMenuItem,
    titleNewPage: pageCategories.MENUS.titleNewPage,
    titleDropdownPlaceholder: pageCategories.MENUS.titleDropdownPlaceholder,
    onHelpHeader: _.partial(
      onHelp,
      menuDataGetterUtil.getHelpIdForMenu(
        menu.id,
        menu.appDefId,
        membersAppDefId,
        isDesktop,
      ),
    ),
    onHelp,
    onClickAddPage: getOnClickAddPage(editorAPI, origin, biCategory),
    onAddLinkClick,
    onAddFolderClick,
    getAddItemsActions,
    openContextMenu: (config: AnyFixMe) =>
      editorAPI.store.dispatch(contextMenuActions.openContextMenu(config)),
    setInPrefIscollapsedMenuItems,
    notifyDataBindingAppTooManyPages:
      editorAPI.pages.notifyDataBindingAppTooManyPages,
    getMenuItemActions: (menuItem: IPageMenuItem) =>
      pagesAPI.menus.getActions(
        editorAPI,
        isDesktop,
        isTranslating,
        onShowSettings,
        onItemAdded,
        onRename,
        menuItem,
        menu,
      ),
    setTooManyPagesFrequencyCount: (frequencyCount: AnyFixMe) =>
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          constants.USER_PREFS.TOO_MANY_PAGES.FREQUENCY_COUNT,
          frequencyCount,
        ),
      ),
    showUserActionNotification: (...args: AnyFixMe[]) =>
      dispatch(
        stateManagement.notifications.actions.showUserActionNotification(
          //@ts-expect-error
          ...args,
        ),
      ),
    openSiteGeneratorPlayground: () =>
      editorAPI.host.getAPI(DesignerPanelsApiKey).openSiteGeneratorPlayground(),
    openUpdateSectionsPanel: () =>
      editorAPI.host.getAPI(DesignerPanelsApiKey).openUpdateSectionsPanel(),
  };
};

export { mapStateToProps, mapDispatchToProps };
