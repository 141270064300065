import React, { useState, useMemo, useEffect } from 'react';
import { hoc } from '@/util';
import { useTranslation } from 'react-i18next';
import { VerticalTabs, Divider, Preloader } from '@wix/wix-base-ui';
import { BusinessApp } from '../BusinessApp/businessApp';
import { RecommendedTools } from '../../components/RecommendedTools/RecommendedTools';
import { MoreApps } from '../../components/MoreApps/MoreApps';
import { PendingApp } from '../../components/PendingApp/PendingApp';
import { ErrorState } from '../../components/ErrorState/ErrorState';
import { TRANSLATIONS } from '../../translations';
import { HELP_ARTICLE_IDS, APP_MANAGER_ORIGIN } from '../../constants';
import {
  AUTOMATION_IDS,
  getBusinessAppSidebarTabDataHook,
} from '../../automationIds';
import { BI_EVENTS_IDS } from '../../biEvents';
import {
  mapStateToProps,
  mapDispatchToProps,
  type MyBusinessViewStateProps,
  type MyBusinessViewDispatchProps,
} from './myBusinessMapper';
import { useSiteSetup } from './useSiteSetup';
import { useRecommendedTools } from './useRecommendedTools';
import styles from './myBusiness.scss';

import type { RegularAppData } from '../../services/appMarket/appMarket.types';
import type { DealerAppPageData } from '../../services/dealer/dealer.types';
import type { DealerSegmentContent } from '../../services/dealer/dealer.segmentContent';

import type { BiEventFields } from 'types/bi';

export interface MyBusinessViewOwnProps {
  apps: RegularAppData[];
  moreApps: RegularAppData[];
  initialSelectedAppId?: string;
  segmentContent?: DealerSegmentContent;
  dealerData?: Record<string, DealerAppPageData>;
  openHelpCenter: (helpId: string, biHelpParams?: BiEventFields) => void;
}

interface MyBusinessViewProps
  extends MyBusinessViewOwnProps,
    MyBusinessViewStateProps,
    MyBusinessViewDispatchProps {}

const RECOMMENDED_APPS_TAB_ID = 'recommended_apps_tab_id';
const MORE_APPS_TAB_ID = 'more_apps_tab_id';

const VERTICAL_TABS_WIDTH = 186;

const MyBusinessComponent: React.FC<MyBusinessViewProps> = ({
  apps,
  moreApps,
  initialSelectedAppId,
  segmentContent,
  metaSiteInstance,
  dealerData,
  addApp,
  checkPermissions,
  openBusinessManager,
  sendBi,
  openHelpCenter,
  openAppMarket,
}) => {
  const [translate] = useTranslation();

  const [selectedTabId, setSelectedTabId] = useState(() => {
    if (initialSelectedAppId) {
      return initialSelectedAppId;
    }
    if (apps.length > 0) {
      return apps[0].id;
    }
    return MORE_APPS_TAB_ID;
  });

  const [siteSetup, { isLoading: isLoadingSetup, error: setupError }] =
    useSiteSetup(metaSiteInstance, checkPermissions, openBusinessManager);

  const [
    recommendedTools,
    { isLoading: isLoadingRecommendations, error: recommendationsError },
  ] = useRecommendedTools(metaSiteInstance, openBusinessManager);

  const isLoading = isLoadingSetup || isLoadingRecommendations;
  const error = setupError || recommendationsError;

  const translationKeys = useMemo(() => {
    return {
      recommendedAppsTab:
        segmentContent?.promotionalAppsTitle ||
        TRANSLATIONS.MY_BUSINESS.SIDEBAR.RECOMMENDED_APPS_TAB,
      moreAppsTab:
        segmentContent?.moreAppsTitle ||
        TRANSLATIONS.MY_BUSINESS.SIDEBAR.MORE_APPS_TAB,
    };
  }, [segmentContent]);

  const renderSidebar = () => {
    const isRecommendedToolsTabShown =
      !isLoading && !recommendedTools.isEmpty();
    const additionalTabs = [
      isRecommendedToolsTabShown
        ? {
            value: RECOMMENDED_APPS_TAB_ID,
            label: translate(translationKeys.recommendedAppsTab),
            dataHook: AUTOMATION_IDS.MY_BUSINESS.SIDEBAR_RECOMMENDED_TAB,
          }
        : null,
      {
        value: MORE_APPS_TAB_ID,
        label: translate(translationKeys.moreAppsTab),
        dataHook: AUTOMATION_IDS.MY_BUSINESS.SIDEBAR_MORE_APPS_TAB,
      },
    ].filter(Boolean);

    return (
      <div className={styles.sidebar}>
        {apps.length > 0 ? (
          <>
            <VerticalTabs
              maxWidth={VERTICAL_TABS_WIDTH}
              className={styles.sidebarTabs}
              value={selectedTabId}
              shouldTranslate={false}
              onChange={setSelectedTabId}
              options={apps.map((app) => ({
                value: app.id,
                label: app.name,
                showDotIndicator: app.isPending || app.isUpdateAvailable,
                dataHook: getBusinessAppSidebarTabDataHook(app.id),
              }))}
            />
            <div className={styles.sidebarDivider}>
              <Divider long={true} />
            </div>
          </>
        ) : null}

        <VerticalTabs
          maxWidth={VERTICAL_TABS_WIDTH}
          className={styles.sidebarTabs}
          value={selectedTabId}
          shouldTranslate={false}
          onChange={setSelectedTabId}
          options={additionalTabs}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (error) {
      return (
        <ErrorState
          error={error}
          segmentContent={segmentContent}
          onHelpClick={() =>
            openHelpCenter(
              segmentContent?.panelHelpId || HELP_ARTICLE_IDS.MY_BUSINESS,
              { learn_more: true },
            )
          }
        />
      );
    }

    if (isLoading) {
      return (
        <div className={styles.preloader}>
          <Preloader className="large" />
        </div>
      );
    }

    if (selectedTabId === RECOMMENDED_APPS_TAB_ID) {
      return (
        <RecommendedTools tools={recommendedTools.getAll()} sendBi={sendBi} />
      );
    }

    if (selectedTabId === MORE_APPS_TAB_ID) {
      return (
        <MoreApps
          apps={moreApps}
          onAddApp={(app) => {
            addApp(app);
            sendBi(BI_EVENTS_IDS.APP_LIST_ACTION_CLICKED, {
              app_id: app.id,
              action_id: 'add_application',
              app_type: 'not_installed',
            });
          }}
          onOpenAppMarket={() => {
            openAppMarket();
            sendBi(BI_EVENTS_IDS.APP_LIST_ACTION_CLICKED, {
              action_id: 'open_app_market',
            });
            sendBi(BI_EVENTS_IDS.OPEN_APP_MARKET, {
              referral_info: APP_MANAGER_ORIGIN,
            });
          }}
        />
      );
    }

    const selectedApp = apps.find((app) => app.id === selectedTabId);
    if (!selectedApp) {
      return null;
    }

    if (selectedApp.isInstalled) {
      return (
        <BusinessApp
          key={selectedApp.id}
          app={selectedApp}
          appSetup={siteSetup.getAppSetup(selectedApp.id)}
          recommendedTools={recommendedTools.getByApp(selectedApp.id)}
          dealerData={dealerData?.[selectedApp.id]}
        />
      );
    }

    if (selectedApp.isPending) {
      return (
        <PendingApp
          onAddApp={() => {
            addApp(selectedApp);
            sendBi(BI_EVENTS_IDS.APP_LIST_ACTION_CLICKED, {
              app_id: selectedApp.id,
              action_id: 'continue_installation',
            });
          }}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    sendBi(BI_EVENTS_IDS.TAB_CHANGED, {
      tab_name: selectedTabId,
    });
  }, [sendBi, selectedTabId]);

  return (
    <div
      className={styles.container}
      data-hook={AUTOMATION_IDS.MY_BUSINESS.CONTAINER}
    >
      {renderSidebar()}
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};

export const MyBusinessView = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  /* mergeProps */
  undefined,
  /* pure */
  true,
  /* opt-out of default error boundary to use the one defined in appManager/PanelFrame */
  false,
)(MyBusinessComponent);
