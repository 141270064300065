import React from 'react';
import { Composites, SectionDivider, TextLabel } from '@wix/wix-base-ui';

interface TopBarBannerProps {
  title: string;
}

const { Banner } = Composites;

const TopBarBanner = (props: TopBarBannerProps) => (
  <div className="top-bar-banner">
    <Banner>
      <SectionDivider>
        <TextLabel value={props.title} shouldTranslate={true} />
      </SectionDivider>
    </Banner>
  </div>
);

TopBarBanner.displayName = 'TopBarBanner';

export default TopBarBanner;
