import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import * as symbols from '@wix/santa-editor-symbols';
import { Composites, RichText, TextLabel } from '@wix/wix-base-ui';
import {
  appPostInstallationMap,
  appPostInstallationSecondaryActionMap,
  getImageUrl,
} from '../../addPagePanelUtil';

import type { AddPageCategory } from '@/addPreset';
import type {
  Dispatch,
  StateMapperArgs,
  MapDispatchToProps,
} from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { ImageTransformTarget } from '@/util';

const appMainImageTarget: ImageTransformTarget = {
  width: 451,
  height: 393,
  htmlTag: 'bg',
};

export interface AddPagePostInstallAppOwnProps {
  category: AddPageCategory;
  pageAddedCallback: (tpaType: string) => void;
  shouldDisableSecondaryAction: boolean;
}
export interface AddPagePostInstallAppDispatchProps {
  postInstallMainAction: () => void;
  postInstallSecondaryAction: () => void;
}
export interface AddPagePostInstallAppProps
  extends AddPagePostInstallAppDispatchProps,
    AddPagePostInstallAppOwnProps {}

const AddPagePostInstallApp = (props: AddPagePostInstallAppProps) => {
  const {
    appMainImage,
    postInstallTitle,
    postInstallButtonTitle,
    postInstallDescription,
    postInstallSecondaryButtonTitle,
  } = props.category.application;

  const { shouldDisableSecondaryAction } = props;

  return (
    <div className="add-page-post-install-app">
      <div className="add-page-post-install-app-content">
        <Composites.RichTextLabeled className="app-page-post-install-texts">
          <TextLabel
            className="app-page-post-install-title"
            type="T08"
            value={postInstallTitle}
          />
          <RichText
            className="app-page-post-install-description"
            type="T05"
            children={translate(postInstallDescription)}
          />
        </Composites.RichTextLabeled>
        {postInstallSecondaryButtonTitle && !shouldDisableSecondaryAction ? (
          <div className="post-install-action-container">
            <div
              onClick={props.postInstallSecondaryAction}
              className="post-install-action"
            >
              <TextLabel
                type="T05"
                className="post-install-action-text"
                value={postInstallSecondaryButtonTitle}
              />
            </div>
            <div className="post-install-action-divider">
              <symbols.symbol name="verticalLine" />
            </div>
            <div
              onClick={props.postInstallMainAction}
              className="post-install-action"
            >
              <TextLabel
                type="T05"
                className="post-install-action-text"
                value={postInstallButtonTitle}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={props.postInstallMainAction}
            className="post-install-action"
          >
            <TextLabel
              type="T05"
              className="post-install-action-text"
              value={postInstallButtonTitle}
            />
            <symbols.symbol name="sliderArrowRight" />
          </div>
        )}
      </div>
      {appMainImage ? (
        <div className="add-page-post-install-app-wrapper">
          <div className="add-page-post-install-app-bg" />
          <div
            className="add-page-post-install-app-image"
            style={{
              backgroundImage: `url(${getImageUrl(
                appMainImage,
                appMainImageTarget,
              )})`,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  AddPagePostInstallAppDispatchProps,
  AddPagePostInstallAppOwnProps
> = (dispatch: Dispatch, ownProps: AddPagePostInstallAppOwnProps) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    postInstallMainAction: () => {
      const { appDefinitionId, title } = ownProps.category.application;
      editorAPI.panelManager.closeAllPanels();
      editorAPI.bi.event(coreBi.events.pages.addPage.postInstallAppClick, {
        app_id: appDefinitionId,
        app_name: title,
      });
      appPostInstallationMap[ownProps.category.application.appDefinitionId](
        editorAPI,
      );
    },
    postInstallSecondaryAction: async () => {
      editorAPI.panelManager.closeAllPanels();

      const tpaType =
        await appPostInstallationSecondaryActionMap[
          ownProps.category.application.appDefinitionId
        ](editorAPI);

      ownProps.pageAddedCallback?.(tpaType);
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(AddPagePostInstallApp);
