import React, { type MouseEventHandler } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ToggleSwitch } from '@wix/wix-base-ui';
import * as utils from '@wix/santa-editor-utils';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as BaseUI from '@/baseUI';
import * as stateManagement from '@/stateManagement';
import * as higherOrderComponents from '@/higherOrderComponents';
import { panelOpened } from '@wix/bi-logger-editor/v2';
import { connectMouseOps } from '../../utils/connectMouseOps';
import ShowOnAllPagesToggle from '../showOnAllPagesToggle/showOnAllPagesToggle';
import { _relativeToStructureLayoutSel } from '../selectors/layoutSelectors';
import ToolbarTop from './toolbarTop';
import * as toolbarMapper from './toolBarMapper';
import type { BiEventFields, SendBiFunction } from 'types/bi';
import type { CompLayout } from 'types/documentServices';
import { shouldShowToolbar } from './toolBarSelectors';

const { DragLayoutConsumer } = stateManagement.interactions.context;

const bi = coreBi;

const getInitialRight = () => (util.sections.isSectionsEnabled() ? 95 : 42);
const INITIAL_TOP = 42;

const StepperRotation = connectMouseOps({
  value: _relativeToStructureLayoutSel.rotationInDegrees,
})(BaseUI.stepper) as AnyFixMe;
const StepperX = connectMouseOps({ value: _relativeToStructureLayoutSel.x })(
  BaseUI.stepper,
) as AnyFixMe;
const StepperY = connectMouseOps({ value: _relativeToStructureLayoutSel.y })(
  BaseUI.stepper,
) as AnyFixMe;
const StepperWidth = connectMouseOps({
  value: _relativeToStructureLayoutSel.width,
})(BaseUI.stepper) as AnyFixMe;
const StepperHeight = connectMouseOps({
  value: _relativeToStructureLayoutSel.height,
})(BaseUI.stepper) as AnyFixMe;

//TYPE WAS GENERATED, remove this line when reviewed
interface ToolbarProps {
  setComponentLayoutChange: (key: string, value: number) => void;
  isInteractionComponent: boolean;
  halfOpacityTools: boolean;
  hidden?: boolean;
  components?: Array<AnyFixMe>;
  toggleFlipHoriz: () => void;
  toggleFlipVert: () => void;
  isFlippedHoriz: boolean;
  isFlippedVert: boolean;
  toolsHidden?: boolean;
  isRightPanelOpen?: boolean;
  isDevModeOpened?: boolean;
  previewTopPosition?: number;
  componentsRestrictions?: AnyFixMe;
  addHistory?: FunctionFixMe;
  toggleShowOnAllPages?: () => void;
  canToggleShowOnAllPages?: boolean;
  shouldDisableShowOnAllPages?: boolean;
  isPage?: boolean;
  reportBI?: SendBiFunction;
  isComponentShowOnAllPages?: boolean;
  isPasteEnabled?: boolean;
  toggleToolbar?: FunctionFixMe;
  componentType?: string;
  isCurrentPageWidgetPage?: boolean;
  onHelp?: FunctionFixMe;
  toggleFixedPosition: (value: boolean) => void;
  startDrag: FunctionFixMe;
  isCustomPosition: boolean;
  registerDragCallbacks: FunctionFixMe;
  compDeleteTooltipText?: string;
  compBIParams?: BiEventFields;
  isCompControlledByParent?: boolean;
  copyPasteAPI?: {
    copy?: FunctionFixMe;
    paste?: FunctionFixMe;
    pastePage?: FunctionFixMe;
    duplicate?: FunctionFixMe;
    hasComponentToPaste?: FunctionFixMe;
    hasPageToPaste?: FunctionFixMe;
  };
  selectedMenuItem?: AnyFixMe;
  canDistributeAllDirections?: boolean;
  distribute?: FunctionFixMe;
  canMatchAllSizes?: boolean;
  matchSize?: FunctionFixMe;
  isMobileEditor?: boolean;
  canAlignAllDirections?: boolean;
  alignComp?: FunctionFixMe;
  removeComp?: FunctionFixMe;
  arrangementAPI?: {
    moveToFront?: FunctionFixMe;
    moveForward?: FunctionFixMe;
    moveToBack?: FunctionFixMe;
    moveBackward?: FunctionFixMe;
  };
  translateComponentTo: (axis: string, amountToMove: number) => void;
  showUserActionNotification?: FunctionFixMe;
  openLayersPanel: () => void;
  stageLayout?: AnyFixMe;
  topBarCloseToolsUsed?: boolean;
  isCrossSitePaste?: FunctionFixMe;
  reportComponentResized: () => void;
  onMouseMove: MouseEventHandler;
  onMouseEnter: MouseEventHandler;
  // TODO: move to some global types
  editorContentLayout?: {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
  };
  compLayout: CompLayout;
  isFixedStageEnabled: boolean;
}

interface ToolbarState {
  lastCustomXPosition?: number;
}

class Toolbar extends React.Component<ToolbarProps, ToolbarState> {
  static displayName = 'toolBar';
  dragLimits: AnyFixMe;
  static propTypes = {
    halfOpacityTools: PropTypes.bool.isRequired,
    hidden: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.object),
    toolsHidden: PropTypes.bool,
    isRightPanelOpen: PropTypes.bool,
    isDevModeOpened: PropTypes.bool,
    previewTopPosition: PropTypes.number,
    layout: PropTypes.shape({
      componentLayout: PropTypes.object,
      update: PropTypes.func,
      updateAndPreserveProportions: PropTypes.func,
    }),
    componentsRestrictions: PropTypes.object,
    addHistory: PropTypes.func,
    toggleShowOnAllPages: PropTypes.func,
    canToggleShowOnAllPages: PropTypes.bool,
    shouldDisableShowOnAllPages: PropTypes.bool,
    isPage: PropTypes.bool,
    reportBI: PropTypes.func,
    isComponentShowOnAllPages: PropTypes.bool,
    isPasteEnabled: PropTypes.bool,
    toggleToolbar: PropTypes.func,
    componentType: PropTypes.string,
    isCurrentPageWidgetPage: PropTypes.bool,
    onHelp: PropTypes.func,
    toggleFixedPosition: PropTypes.func,
    startDrag: PropTypes.func.isRequired,
    isCustomPosition: PropTypes.bool.isRequired,
    registerDragCallbacks: PropTypes.func.isRequired,
    compDeleteTooltipText: PropTypes.string,
    compBIParams: PropTypes.array,
    isCompControlledByParent: PropTypes.bool,
    copyPasteAPI: PropTypes.shape({
      copy: PropTypes.func,
      paste: PropTypes.func,
      pastePage: PropTypes.func,
      duplicate: PropTypes.func,
      hasComponentToPaste: PropTypes.func,
      hasPageToPaste: PropTypes.func,
    }),
    selectedMenuItem: PropTypes.object,
    canDistributeAllDirections: PropTypes.bool,
    distribute: PropTypes.func,
    canMatchAllSizes: PropTypes.bool,
    matchSize: PropTypes.func,
    isMobileEditor: PropTypes.bool,
    canAlignAllDirections: PropTypes.bool,
    alignComp: PropTypes.func,
    removeComp: PropTypes.func,
    arrangementAPI: PropTypes.shape({
      moveToFront: PropTypes.func,
      moveForward: PropTypes.func,
      moveToBack: PropTypes.func,
      moveBackward: PropTypes.func,
    }),
    showUserActionNotification: PropTypes.func,
    stageLayout: PropTypes.object,
    topBarCloseToolsUsed: PropTypes.bool,
    isCrossSitePaste: PropTypes.func,
    editorContentLayout: PropTypes.object,
    isFixedStageEnabled: PropTypes.bool,
  };

  constructor(props: ToolbarProps) {
    super(props);
    props.registerDragCallbacks({
      onDragEnd: this.onDragEnd,
    });
    this.state = {};
  }

  componentDidMount = (): void => {
    util.biLogger.report(panelOpened({ panel_name: 'toolbar_opened' }));
  };

  getInitialPosition = () => {
    return {
      right:
        getInitialRight() +
        (this.props.isRightPanelOpen ? constants.RIGHT_AREA.WIDTH : 0),
      top: INITIAL_TOP + this.props.previewTopPosition,
    };
  };

  getInfoTooltip = (title: string, text: string, link?: string) => {
    return React.createElement(
      BaseUI.popoverTemplates.titleBodyAndLinkTooltip,
      {
        title,
        text,
        link,
      },
    );
  };

  onDragEnd = (position: AnyFixMe) => {
    this.updateLastCustomXPosition(position.left);
  };

  getClasses = () => {
    return [
      'rootcomp-toolbar',
      this.props.halfOpacityTools ? 'half-opacity' : '',
      this.props.hidden ? 'hidden' : '',
    ].join(' ');
  };

  resizeComponent = (key: 'width' | 'height', value: number) => {
    this.props.reportComponentResized();
    this.setComponentLayoutChange(key, value);
  };

  moveComponent = (key: 'x' | 'y', value: number) => {
    this.setComponentLayoutChange(key, value);
  };

  getBIParams = () => {
    return {
      yCoordinate_old: this.props.compLayout?.y,
      xCoordinate_old: this.props.compLayout?.x,
      component_id: this.props.compBIParams?.[0]?.component_id,
      component_type: this.props.compBIParams?.[0]?.component_type,
      parent_component_id: this.props.compBIParams?.[0]?.parent_component_id,
      parent_component_type:
        this.props.compBIParams?.[0]?.parent_component_type,
    };
  };

  rotateComponent = (key: AnyFixMe, value: AnyFixMe) => {
    const valueAsNumber = parseInt(value, 10);
    if (valueAsNumber === 0) {
      this.props.reportBI(bi.events.editBox.CANCEL_ROTATE_COMPONENT);
    } else {
      const compType = this.getSelectedComponentsTypes()[0];
      this.props.reportBI(bi.events.editBox.ROTATE_COMPONENT, {
        origin: 'toolbar',
        value: valueAsNumber,
        component_type: compType,
      });
    }

    this.setComponentLayoutChange(key, value);
  };

  checkHorizontalResizable = () => {
    return this.props.componentType === 'Column'
      ? true
      : !this.props.componentsRestrictions.horizontallyResizable;
  };

  shouldDisableToolbarResize = () => {
    return (
      (this.props.componentType === 'MediaBox' &&
        this.props.isCurrentPageWidgetPage) ||
      this.props.isInteractionComponent
    );
  };

  setComponentLayoutChange = (
    key: 'x' | 'y' | 'width' | 'height',
    value: number,
  ) => {
    // TODO: stepper calls with number, why do we need parseInt?
    value = parseInt(value as any as string, 10);
    if (isNaN(value)) {
      this.forceUpdate();
      return;
    }

    this.props.setComponentLayoutChange(key, value);
  };

  onClose = () => {
    this.props.toggleToolbar();
    this.props.reportBI(bi.events.toolbar.TOOL_BAR_CLOSE_CLICK, {
      origin: 'X',
    });
    if (!this.props.topBarCloseToolsUsed) {
      const onNotificationLinkClick = () => {
        this.props.onHelp(helpIds.NOTIFICATIONS.TOOLBAR);
      };
      this.props.showUserActionNotification({
        message: 'Notifications_Hidden_Toolbar_Text',
        title: 'Notifications_Hidden_Toolbar_Text',
        type: 'info',
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick,
        },
      });
    }
  };

  onHelp = () => {
    const biHelpParams = {
      origin: constants.BI.HELP.ORIGIN.TOOLBAR,
      component: this.props.componentType,
      learn_more: false,
      panel_name: constants.BI.HELP.ORIGIN.TOOLBAR,
    };
    const helpId = '4ab49cd7-2ea4-4873-904e-f65d17d2f1dd';
    this.props.onHelp(helpId, biHelpParams);
  };

  handleShowOnAllPages = () => {
    if (this.props.componentsRestrictions.canToggleShowOnAllPages) {
      this.props.toggleShowOnAllPages();
      this.props.reportBI(bi.events.rightClickMenu.SHOW_ON_ALL_PAGES, {
        new_status: !this.props.isComponentShowOnAllPages,
        origin: constants.BI.HELP.ORIGIN.TOOLBAR,
      });
    }
  };

  shouldShowFreezePositionToggle = () => {
    return this.props.componentsRestrictions.showLegacyFixedPosition;
  };

  isShowOnAllPagesToggleDisabled = () => {
    return (
      !this.props.componentsRestrictions.canToggleShowOnAllPages ||
      this.props.shouldDisableShowOnAllPages
    );
  };

  getSelectedComponentsTypes = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(this.props.compBIParams, 'component_type');
  };

  updateLastCustomXPosition = (xValue: AnyFixMe) => {
    this.setState({
      lastCustomXPosition: xValue,
    });
  };

  renderWithPositioning = (renderCallback: AnyFixMe) => {
    return React.createElement(
      core.utils.toolbarPositioning,
      {
        isCustomPosition: this.props.isCustomPosition,
        lastCustomXPosition: this.state.lastCustomXPosition,
        isHidden: this.props.toolsHidden,
        initialPos: this.getInitialPosition(),
        dragRightLimit: getInitialRight(),
        className: this.getClasses(),
        stageLayout: this.props.stageLayout,
        editorContentLayout: this.props.editorContentLayout,
        isMobileEditor: this.props.isMobileEditor,
      },
      renderCallback,
    );
  };

  isWidthStepperDisabled = () =>
    this.checkHorizontalResizable() || this.shouldDisableToolbarResize();

  isWidthStepperTooltipDisabled = () =>
    !this.isWidthStepperDisabled() || !this.props.isFixedStageEnabled;

  renderWithDragLimits = (dragLimits: AnyFixMe) => {
    this.dragLimits = dragLimits;

    return (
      <div
        data-aid="toolbar-container"
        onMouseMove={this.props.onMouseMove}
        onMouseEnter={this.props.onMouseEnter}
        onContextMenu={util.preventContextMenu}
      >
        <div className="header">
          <div
            onMouseDown={(e) => {
              this.props.startDrag(e, this.dragLimits);
            }}
            className="drag-zone"
          >
            <span className="drag-handle">
              <BaseUI.symbol name="toolbarDragHandle" />
            </span>
          </div>
          <button onClick={this.onHelp} className="help">
            ?
          </button>
          <span
            onClick={this.onClose}
            data-aid="toolbar-close"
            className="close"
          >
            <BaseUI.symbol name="toolbarClose" />
          </span>
        </div>
        <div className="header-seperator" />
        <ToolbarTop
          restrictions={this.props.componentsRestrictions}
          components={this.props.components}
          pasteEnabled={this.props.isPasteEnabled}
          compDeleteTooltipText={this.props.compDeleteTooltipText}
          compBIParams={this.props.compBIParams}
          isCompControlledByParent={this.props.isCompControlledByParent}
          reportBI={this.props.reportBI}
          copyPasteAPI={this.props.copyPasteAPI}
          isCrossSitePaste={this.props.isCrossSitePaste}
          selectedMenuItem={this.props.selectedMenuItem}
          canDistributeAllDirections={this.props.canDistributeAllDirections}
          distribute={this.props.distribute}
          canMatchAllSizes={this.props.canMatchAllSizes}
          matchSize={this.props.matchSize}
          addHistory={this.props.addHistory}
          isMobileEditor={this.props.isMobileEditor}
          canAlignAllDirections={this.props.canAlignAllDirections}
          alignComp={this.props.alignComp}
          removeComp={this.props.removeComp}
          arrangementAPI={this.props.arrangementAPI}
        />
        <div className="seperator" />
        <div className="inner form">
          <label data-input-title="input_rotation" className="row">
            <BaseUI.tooltip
              value="toolbar_tooltip_rotation"
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                className={util.cx({
                  disabled: !this.props.componentsRestrictions.rotatable,
                })}
              >
                <BaseUI.symbol name="toolbarRotation" />
              </span>
            </BaseUI.tooltip>
            <StepperRotation
              disabled={!this.props.componentsRestrictions.rotatable}
              min={-Infinity}
              max={Infinity}
              units="°"
              onChange={(value: AnyFixMe) => {
                this.rotateComponent('rotationInDegrees', value);
              }}
              className="small degrees"
            />
          </label>
        </div>
        <div key="flip-buttons" className="inner button-grid">
          <BaseUI.tooltip
            value={this.getInfoTooltip(
              'Toolbar_tooltip_flip_horizontal',
              'Toolbar_tooltip_flip_horizontal_info',
            )}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.CONTENT_ONLY}
            delay="600"
          >
            <span
              onClick={this.props.toggleFlipHoriz}
              className={util.cx({
                button: true,
                disabled: !this.props.componentsRestrictions.flippable,
                selected: this.props.isFlippedHoriz,
              })}
            >
              <BaseUI.symbol name="symbol-flip-horizontal" />
            </span>
          </BaseUI.tooltip>
          <BaseUI.tooltip
            value={this.getInfoTooltip(
              'Toolbar_tooltip_flip_vertical',
              'Toolbar_tooltip_flip_vertical_info',
            )}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.CONTENT_ONLY}
            delay="600"
          >
            <span
              onClick={this.props.toggleFlipVert}
              className={util.cx({
                button: true,
                disabled: !this.props.componentsRestrictions.flippable,
                selected: this.props.isFlippedVert,
              })}
            >
              <BaseUI.symbol name="symbol-flip-vertical" />
            </span>
          </BaseUI.tooltip>
        </div>
        <div className="seperator" />
        <div className="inner form">
          <span className="layout-title">{translate('toolbar_size')}</span>
          <label className="row">
            <BaseUI.tooltip
              value="toolbar_tooltip_width"
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                style={{
                  paddingRight: '4px',
                }}
                className="label"
              >
                {`${translate('toolbar_width')}:`}
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getInfoTooltip(
                'toolbar_tooltip_editing_size',
                'toolbar_tooltip_editing_size_info',
                'toolbar_tooltip_editing_size_link',
              )}
              delay="600"
              interactive={true}
              disabled={this.isWidthStepperTooltipDisabled()}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.CONTENT_ONLY}
            >
              <StepperWidth
                target={'toolbar_width'}
                biParams={this.getBIParams()}
                disabled={this.isWidthStepperDisabled()}
                automationId="width-stepper"
                min={-Infinity}
                max={Infinity}
                onChange={(value: number) => {
                  this.resizeComponent('width', value);
                }}
                className="small"
              />
            </BaseUI.tooltip>
          </label>
          <label className="row">
            <BaseUI.tooltip
              value="toolbar_tooltip_height"
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span className="label">{`${translate('toolbar_height')}:`}</span>
            </BaseUI.tooltip>
            <StepperHeight
              target={'toolbar_height'}
              biParams={this.getBIParams()}
              disabled={
                !this.props.componentsRestrictions.verticallyResizable ||
                this.shouldDisableToolbarResize()
              }
              min={-Infinity}
              max={Infinity}
              automationId="height-stepper"
              onChange={(value: number) => {
                this.resizeComponent('height', value);
              }}
              className="small"
            />
          </label>
        </div>
        <div className="seperator" />
        <div className="inner form">
          <span className="layout-title">{translate('toolbar_position')}</span>
          <label data-input-title="input_x" className="row input_x">
            <BaseUI.tooltip
              value="toolbar_tooltip_x_position"
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span className="input_x_label label">
                {`${translate('toolbar_x')}:`}
              </span>
            </BaseUI.tooltip>
            {this.props.isInteractionComponent ? (
              <DragLayoutConsumer>
                {({ offsetX }) => (
                  <BaseUI.stepper
                    disabled={
                      !this.props.componentsRestrictions.horizontallyMovable
                    }
                    value={offsetX}
                    min={-Infinity}
                    max={Infinity}
                    onChange={(value: AnyFixMe) => {
                      this.props.translateComponentTo('x', value);
                    }}
                    biParams={this.getBIParams()}
                    className="small"
                  />
                )}
              </DragLayoutConsumer>
            ) : (
              <StepperX
                target={'toolbar_x'}
                disabled={
                  !this.props.componentsRestrictions.horizontallyMovable
                }
                min={-Infinity}
                max={Infinity}
                onChange={(value: number) => {
                  this.moveComponent('x', value);
                }}
                biParams={this.getBIParams()}
                className="small"
              />
            )}
          </label>
          <label data-input-title="input_y" className="row">
            <BaseUI.tooltip
              value="toolbar_tooltip_y_position"
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span className="label">{`${translate('toolbar_y')}:`}</span>
            </BaseUI.tooltip>
            {this.props.isInteractionComponent ? (
              <DragLayoutConsumer>
                {({ offsetY }) => (
                  <BaseUI.stepper
                    target={'toolbar_y'}
                    disabled={
                      !this.props.componentsRestrictions.verticallyMovable
                    }
                    value={offsetY}
                    min={-Infinity}
                    max={Infinity}
                    onChange={(value: AnyFixMe) => {
                      this.props.translateComponentTo('y', value);
                    }}
                    biParams={this.getBIParams()}
                    className="small"
                  />
                )}
              </DragLayoutConsumer>
            ) : (
              <StepperY
                target={'toolbar_y'}
                disabled={!this.props.componentsRestrictions.verticallyMovable}
                min={-Infinity}
                max={Infinity}
                onChange={(value: number) => {
                  this.moveComponent('y', value);
                }}
                biParams={this.getBIParams()}
                className="small"
              />
            )}
          </label>
        </div>
        <div key="seperator-inner-form" className="seperator" />
        <div key="inner-form" className="inner form">
          {!this.shouldShowFreezePositionToggle() ? (
            <div key="containable">
              <span className="layout-title show-on-all-pages-title">
                {translate('toolbar_show_on_all_pages')}
              </span>
              <label>
                <ShowOnAllPagesToggle
                  origin="toolbar"
                  selectedComponents={this.props.components}
                  selectedComponentsTypes={this.getSelectedComponentsTypes()}
                  isComponentShowOnAllPages={
                    this.props.isComponentShowOnAllPages
                  }
                  disabled={this.isShowOnAllPagesToggleDisabled()}
                  isPage={this.props.isPage}
                  isToggleAllowed={
                    this.props.componentsRestrictions.canToggleShowOnAllPages
                  }
                  toggleShowOnAllPages={this.props.toggleShowOnAllPages}
                  reportBI={this.props.reportBI}
                />
              </label>
            </div>
          ) : null}
          {this.shouldShowFreezePositionToggle() ? (
            <div key="canBeFixed">
              <span className="layout-title show-on-all-pages-title">
                {translate('toolbar_fixed_position')}
              </span>
              <label className="row show-on-all-pages-row">
                <ToggleSwitch
                  key="fixedPosition"
                  value={this.props.componentsRestrictions.fixed}
                  onChange={this.props.toggleFixedPosition}
                  size="MEDIUM"
                  className="show-on-all-pages emphasise"
                  disabled={!this.props.componentsRestrictions.canBeFixed}
                />
              </label>
            </div>
          ) : null}
        </div>
        <div key="layers-panel-button-feature">
          <div className="seperator" />
          <div
            key="layers-panel-button"
            className="layers-panel-button-container"
          >
            <button
              onClick={this.props.openLayersPanel}
              data-aid="layers-panel-button"
              className="layers-panel-button"
            >
              {translate('Layers_Title')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderWithPositioning(this.renderWithDragLimits);
  }
}

const ConnectedAlwaysVisibleToolbar = _.flowRight(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    toolbarMapper.mapStateToProps,
    toolbarMapper.mapDispatchToProps,
  ),
  utils.hoc.draggable,
)(Toolbar) as any as React.ComponentType<any>; //TODO fixme

export const ConnectedToolbar = util.hoc.withConditionalRender(
  util.hoc.STORES.EDITOR_API,
  shouldShowToolbar,
)(ConnectedAlwaysVisibleToolbar);

ConnectedToolbar.pure = Toolbar;

export const ToolbarWithQuickTip = higherOrderComponents.overlappedCompTooltip(
  BaseUI.popoverTemplates.quickTip,
)(ConnectedToolbar);

export default ConnectedToolbar;
