module.exports = {
  id: "Stylable_StylableButton_27",
  preset: { rect: { width: 56, height: 71, x: 268, y: 745 }, tags: null },
  structure: {
    type: "Component",
    metaData: { sig: "inq-339", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
    layout: {
      width: 40,
      height: 40,
      x: 274,
      y: 759,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wixui.StylableButton",
    parent: "comp-knzvqsjj",
    data: {
      type: "StylableButton",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "q4x-295",
        pageId: "c1dmp",
      },
      label: "Close",
      svgId: "7d72932f870a315ce1b030fcc29ba4e5.svg",
    },
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "b80-264", pageId: "c1dmp" },
    },
    style: {
      type: "ComponentStyle",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "4ie-209",
        pageId: "c1dmp",
      },
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #282626;border-radius: 100px;border: 0px solid rgb(40, 38, 38)}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;fill: #FFFFFF;width: 18px;height: 18px}.root:hover::icon {\n    width: 21px;\n    height: 21px;\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      componentClassName: "wixui.StylableButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    id: "comp-km90gbw1",
    components: [],
  },
};
