import React, { type FC } from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import styles from './saleIndicatorA.scss';
import { cx } from '@/util';

interface SaleIndicatorAProps {
  isActive: boolean;
}

const SaleIndicatorA: FC<SaleIndicatorAProps> = ({ isActive }) => {
  return (
    <div
      className={cx(styles.saleIndicatorA, {
        [styles.saleIndicatorA_active]: isActive,
      })}
    >
      <symbols.symbol
        name="freeIcon_unfilled_Gift"
        className={styles.saleIndicatorA__icon}
      />
    </div>
  );
};

export default SaleIndicatorA;
