import { createEntryPoint } from '@/apilib';
import { BaseDragApiKey } from '@/apis';
import { Scope } from './baseDragScope';
import { createBaseDragApi } from './baseDragApi';

export const BaseDragEntrypoint = createEntryPoint({
  Scope,
  name: 'BaseDragApi',
  initialize() {},
  publicApi({ contributeApi }) {
    contributeApi(BaseDragApiKey, (scope) => createBaseDragApi(scope));
  },
});
