// @ts-nocheck
import { array } from '@/util';
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';

const { immutableSplice } = array;

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 601,
      height: 105,
      x: 6.5,
      y: 220,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 101,
          originalValue: 391,
          type: 'BOTTOM_PARENT',
          locked: false,
          targetComponent: 'comp-im0njf2v',
        },
      ],
      fixedPosition: false,
    },
    controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             component_00: {
  //                components: [
  //                    form,
  //                    ...
  //                ]
  //            },
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: immutableSplice(structure.components[0].components, 0, 1, {
        ...structure.components[0].components[0],
        components: immutableSplice(
          structure.components[0].components[0].components,
          0,
          0,
          form,
        ),
      }),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.LIGHTBOX_SUBSCRIBE04);
  onClick = onClick(wixFormsConstants.FormPreset.LIGHTBOX_SUBSCRIBE04);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};
