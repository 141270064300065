import wixDataSuperAppButton from './wixDataSuperAppButton';
import dynamicPagesStartingPanel from './components/dynamicPagesStartingPanel/dynamicPagesStartingPanel';
import wixDataPanel from './panel/WixDataPanel';
import { isDataModeOn, onceDataModeInitialized } from './dataMode';
import { getCollections, selectUserCollections } from './collections';
import { EVENTS } from './consts';
import { WixDataEntryPoint } from './scope';
import { triggerRefreshBindingIfNeededMobile } from './text';
import suggestDynamicPagePanel from './components/suggestDynamicPagePanel/suggestDynamicPagePanel';
import { notifyDataBindingApplication } from './notifyApplication';

function onPreviewReady(editorAPI: AnyFixMe) {
  wixDataSuperAppButton.register(editorAPI);
}

export {
  EVENTS,
  onPreviewReady,
  dynamicPagesStartingPanel,
  wixDataPanel as panel,
  isDataModeOn,
  getCollections,
  onceDataModeInitialized,
  WixDataEntryPoint,
  triggerRefreshBindingIfNeededMobile,
  suggestDynamicPagePanel,
  selectUserCollections,
  notifyDataBindingApplication,
};
