const WEEKLY_TIMETABLE_WIDGET_IMAGE =
  '/addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_editorX_en.png';

const WEEKLY_TIMETABLE_WIDGET_WIX_STUDIO_IMAGE =
  '/addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_wix_studio_en.png';
const WEEKLY_TIMETABLE_PRESET_KEY = 'Wix_Bookings_Weekly_Timetable_Widget';
const WEEKLY_TIMETABLE_WIDGET_ID = '3c675d25-41c7-437e-b13d-d0f99328e347';
const WEEKLY_TIMETABLE_TITLE_KEY =
  'add_section_heading_bookings_weeklytimetable_input';
const WEEKLY_TIMETABLE_TOOLTIP_TITLE_KEY =
  'add_section_heading_bookings_weeklytimetable_input_tooltip_title';
const WEEKLY_TIMETABLE_TOOLTIP_DESCRIPTION_KEY =
  'add_section_heading_bookings_weeklytimetable_input_tooltip_description';

export default {
  WEEKLY_TIMETABLE_WIDGET_IMAGE,
  WEEKLY_TIMETABLE_PRESET_KEY,
  WEEKLY_TIMETABLE_WIDGET_ID,
  WEEKLY_TIMETABLE_TITLE_KEY,
  WEEKLY_TIMETABLE_TOOLTIP_TITLE_KEY,
  WEEKLY_TIMETABLE_TOOLTIP_DESCRIPTION_KEY,
  WEEKLY_TIMETABLE_WIDGET_WIX_STUDIO_IMAGE,
};
