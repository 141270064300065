import { stateMapperArgsSelectors } from '@/stateManagement';
import { getCompRestrictions } from '../../common';
import { sections } from '@/util';
import { utils } from '@/core';

const { createSelector } = stateMapperArgsSelectors.selector;
const { editorAPISel, editorAPIMouseSel } = stateMapperArgsSelectors.rootSels;
const { selectedCompsSel } = stateMapperArgsSelectors.selectedCompsSels;

import { layoutSel } from './layoutSelectors';

import type { CSSProperties } from 'react';
import type { CompLayout } from 'types/documentServices';

const { mobileUtil } = utils;

const MOVE_TO_FOOTER_BUTTON_VISIBILITY_DISTANCE_THRESHOLD = 21;

function isCompBottomCloseToY(layout: CompLayout, y: number) {
  const compBottom = layout.bounding.y + layout.bounding.height;
  const distanceFromBottom = y - compBottom;

  return (
    distanceFromBottom >= 0 &&
    distanceFromBottom <= MOVE_TO_FOOTER_BUTTON_VISIBILITY_DISTANCE_THRESHOLD
  );
}

const getComponentToCalcSel = createSelector(
  [editorAPISel, selectedCompsSel],
  (editorAPI, comps) => {
    const { dsRead } = editorAPI;

    const areComponentsOfSamePage =
      editorAPI.components.areComponentsOfSamePage(comps);
    const masterPageComponent = dsRead.siteSegments.getSiteStructure();

    const isDirectChildOfMasterPage = editorAPI.utils.isSameRef(
      editorAPI.components.getContainer(comps),
      masterPageComponent,
    );
    const isCurrentPageLandingPage = editorAPI.isCurrentPageLandingPage();

    const isEditingText = editorAPI.text.isEditingText();

    const isMobileEditor = editorAPI.isMobileEditor();
    const shouldDisableInMobile =
      isMobileEditor &&
      comps.some(
        (compRef) =>
          !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
            compRef,
          ),
      );
    const isShowOnFixedPosition =
      editorAPI.components.layout.isShowOnFixedPosition(comps);

    const isGroupedComponent = editorAPI.components.is.groupedComponent(comps);

    if (
      isGroupedComponent || // when moving one component inside container
      !areComponentsOfSamePage ||
      shouldDisableInMobile ||
      isCurrentPageLandingPage ||
      isShowOnFixedPosition ||
      isEditingText
    ) {
      return false;
    }

    const isUsingSectionsLayout =
      editorAPI.siteSegments.isUsingSectionsLayout();
    const pagesContainer = dsRead.siteSegments.getPagesContainer();

    const compRestrictions = getCompRestrictions(editorAPI);
    const { canToggleShowOnAllPages } = compRestrictions;
    const isShowOnAllPages = editorAPI.components.isShowOnAllPages(comps);

    if (isUsingSectionsLayout && isDirectChildOfMasterPage) {
      const footer = dsRead.siteSegments.getFooter();
      return {
        component: footer,
      };
    } else if (!isShowOnAllPages && canToggleShowOnAllPages) {
      return {
        component: pagesContainer,
        shouldAddHeight: true,
      };
    }

    return null;
  },
);

export const shouldShowMoveToFooterSel = createSelector(
  [editorAPIMouseSel, getComponentToCalcSel, layoutSel, selectedCompsSel],
  (editorAPI, dataToCalc, layout, compRef) => {
    if (!dataToCalc) {
      return false;
    }

    if (sections.isSectionsEnabled()) {
      return false;
    }

    const isMobileOnlyComponent =
      mobileUtil.isSelectionContainsMobileOnlyComponent(editorAPI, compRef);
    const isPageComponent = !mobileUtil.isShownOnAllPagesComponent(
      editorAPI,
      compRef,
    );

    if (
      editorAPI.isMobileEditor() &&
      isPageComponent &&
      !isMobileOnlyComponent
    ) {
      return false;
    }

    const layoutToCalc = editorAPI.components.layout.stage.getRelativeToScreen(
      dataToCalc.component,
    );
    const distance = dataToCalc.shouldAddHeight
      ? layoutToCalc.y + layoutToCalc.height
      : layoutToCalc.y;

    return isCompBottomCloseToY(layout, distance);
  },
);

const getMoveToFooterStyle = (layout: CompLayout): CSSProperties => {
  const compBottomY = layout.bounding.y + layout.bounding.height;
  const compCenterX = layout.bounding.x + layout.bounding.width / 2;

  return {
    top: compBottomY - 14,
    left: compCenterX,
    whiteSpace: 'nowrap',
  };
};

export const moveToFooterStyleSel = createSelector(
  [shouldShowMoveToFooterSel, layoutSel],
  (shouldShowMoveToFooterSel, layout) =>
    shouldShowMoveToFooterSel ? getMoveToFooterStyle(layout) : null,
);
