import React from 'react';
import styles from './hiddenMobileItemsTooltip.scss';
import { Text, Tooltip } from '@wix/wix-base-ui';
import { Hint } from '@wix/wix-ui-icons-common';

interface OwnProps {
  onActionClick: () => void;
  onDismiss: () => void;
  shouldDisplayTooltip: boolean;
}

class HiddenMobileItemsTooltip extends React.Component<OwnProps> {
  render() {
    return (
      <Tooltip
        content={
          <div
            className={styles.contentContainer}
            data-hook="hidden-mobile-elements-tooltip"
          >
            <Hint className={styles.tooltipIcon} />
            <div className={styles.textsContainer}>
              <Text size="small" skin="standard" weight="bold">
                mobile_hidden_items_FTE_tooltip_title
              </Text>
              <Text
                enableEllipsis={false}
                size="small"
                skin="secondary"
                weight="thin"
              >
                mobile_hidden_items_FTE_tooltip_text
              </Text>
              <span onClick={this.props.onActionClick}>
                <Text size="small" skin="link">
                  mobile_hidden_items_FTE_tooltip_CTA
                </Text>
              </span>
            </div>
          </div>
        }
        alignment="RIGHT"
        arrowDistance={18}
        disabled={!this.props.shouldDisplayTooltip}
        isOpen={this.props.shouldDisplayTooltip}
        marginRight={12}
        onClose={this.props.onDismiss}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

export default HiddenMobileItemsTooltip;
