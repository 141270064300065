// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import CustomTooltip from './customTooltipWithContextMenu';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';

const WELCOME_SCREEN = 'welcome screen';

// eslint-disable-next-line react/prefer-es6-class
const WelcomeScreenMobileTooltip = createReactClass({
  displayName: 'welcomeScreenMobileTooltip',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    id: PropTypes.string,
    helpId: PropTypes.string,
    openPanel: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      existingWelcomeScreen: this.props.welcomeScreenConfig,
      errorGettingWelcomeScreen: !(
        this.props.welcomeScreenConfig?.error ?? false
      ),
    };
  },

  editWelcomeScreen() {
    this.props.dispatchBIEvent(
      coreBi.events.mobileWelcomeScreenPanel.EDIT_WELCOME_SCREEN_MOBILE_TOOLS,
      {
        mobile_tool: WELCOME_SCREEN,
      },
    );
    this.props.openPanel('welcomeScreen.welcomeScreenPanel');
  },

  removeWelcomeScreen() {
    this.props.dispatchBIEvent(
      coreBi.events.mobileWelcomeScreenPanel.REMOVE_WELCOME_SCREEN_MOBILE_TOOLS,
      {
        mobile_tool: WELCOME_SCREEN,
      },
    );
    this.props.removeWelcomeScreen(() => {
      this.props.saveConfigToEditorState(null);
      this.setState({ existingWelcomeScreen: false });
    });
  },

  render() {
    return React.createElement(CustomTooltip, {
      isTooltipContentOnAddVisible:
        !this.state.existingWelcomeScreen &&
        this.state.errorGettingWelcomeScreen,
      isEditRemoveSectionVisible: !!(
        this.state.existingWelcomeScreen && this.state.errorGettingWelcomeScreen
      ),
      onEditClick: this.editWelcomeScreen,
      onRemoveClick: this.removeWelcomeScreen,
      isErrorContentVisible: !this.state.errorGettingWelcomeScreen,
      errorContent: 'mobile_welcome_screen_error_unavailable',
    });
  },
});

const mapStateToProps = ({ editorAPI }) => ({
  openPanel: editorAPI.panelManager.openPanel,
  welcomeScreenConfig:
    stateManagement.welcomeScreen.selectors.getWelcomeScreenConfig(
      editorAPI.store.getState(),
    ),
});

const mapDispatchToProps = (dispatch) => ({
  saveConfigToEditorState: (conf) => {
    dispatch(
      stateManagement.welcomeScreen.actions.setLocalWelcomeScreenConfig(
        conf,
        true,
      ),
      // If we're changing the config, by definition "isDirty" will be true
    );
  },

  removeWelcomeScreen: (onSuccess) => {
    dispatch(
      stateManagement.welcomeScreen.actions.deleteRemoteWelcomeScreenConfig(
        onSuccess,
      ),
    );
  },

  dispatchBIEvent: (...args) => {
    dispatch(stateManagement.bi.actions.event(...args));
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(WelcomeScreenMobileTooltip);
