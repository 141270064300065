import _ from 'lodash';
import { EditorAPIKey } from '@/apis';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';
import constants from '@/constants';
import type { EditorVersionsInfo } from 'types/core';
import accountAPIData from './accountAPIData';
import type { Shell } from '@/apilib';
import { accountActions, accountSelectors } from './state';
import type { AccountStateFull } from './state';
import { getMyUserAccounts } from '@wix/ambassador-identity-account-v2-account/http';
import { HttpClient } from '@wix/http-client';

const httpClient = new HttpClient();

interface IOpenSettingsParams {
  excludeCategory?: boolean;
  tab?: string;
  referrer?: string;
  path?: string;
  onPanelClosed?: () => void;
}

interface ConcurrentUsersResponse {
  errorCode?: number;
}

type InvalidStatuses = 'NOT_LOGGED_IN' | 'USER_NOT_AUTHORIZED_FOR_SITE';

const SITE_SETTINGS_PANEL_NAME = 'rEditor.panels.siteSettingsPanel';
const SITE_SETTINGS_HELP_ID = '49cebc11-f15c-4cf8-82d9-2c36c5397520';

export function createAccountApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const shellStore = shell.getStore<AccountStateFull>();

  const MY_ACCOUNT = `${util.serviceTopology.dashboardUrl.replace(
    /create\//,
    '',
  )}/`;
  const BUSINESS_MANAGER = util.serviceTopology.businessManagerUrl;
  const SEO_WIZ_APP_DEF_ID = '1480c568-5cbd-9392-5604-1148f5faffa0';

  function isUserOwner() {
    const { permissionsInfo } = util.editorModel;
    return (
      permissionsInfo &&
      permissionsInfo.ownerId === permissionsInfo.loggedInUserId
    );
  }

  function isUserContributor() {
    return !editorAPI.account.isUserOwner();
  }

  function openConnectDomain(flowId: string, isFirstPublish: boolean) {
    const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
    const isDraft = editorAPI.dsRead.generalInfo.isDraft();
    isFirstPublish = _.isBoolean(isFirstPublish)
      ? isFirstPublish
      : !editorAPI.dsRead.generalInfo.isSitePublished();
    const isPremiumSite = editorAPI.site.isPremium();
    editorAPI.panelManager.openPanel(
      'savePublish.panels.connectDomain',
      {
        isFirstSave: isFirstSave || isDraft,
        isFirstPublish,
        isPremium: isPremiumSite,
        title: 'PREMIUM_PUBLISH_TITLE',
        subtitle: isPremiumSite
          ? 'PREMIUM_PUBLISH_SUBTITLE'
          : 'CONNECT_DOMAIN_DIALOG_FREE_SUBTITLE',
        flowId,
      },
      true,
    );
  }

  function openSettings(params: IOpenSettingsParams) {
    editorAPI.panelManager.openPanel(SITE_SETTINGS_PANEL_NAME, {
      helpId: SITE_SETTINGS_HELP_ID,
      ...params,
    });
  }

  function openReleaseManager(): void {
    openSettings({ tab: 'release-manager' });
  }

  function openSiteBranchesManager({ inNewTab }: AnyFixMe): void {
    if (inNewTab) {
      const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();

      window.open(
        `${BUSINESS_MANAGER}/${metaSiteId}/site-branches/?referralInfo=EDITOR`,
      );
    } else {
      openSettings({ excludeCategory: true, tab: 'site-branches' });
    }
  }

  function openRevisions(): void {
    const dontShowAgainWasChecked =
      !!stateManagement.userPreferences.selectors.getSiteUserPreferences(
        constants.USER_PREFS.SITE_REVISIONS.SITE_HISTORY_DONT_SHOW_AGAIN,
      )(editorAPI.store.getState());
    if (dontShowAgainWasChecked) {
      openSiteHistory();
    } else {
      editorAPI.panelManager.openPanel(
        'panels.messagePanels.siteRevisionsPanel',
        {
          onButtonClick(shouldContinue: AnyFixMe) {
            if (shouldContinue) {
              editorAPI.account.openSiteHistory();
            }
          },
        },
      );
    }
  }

  function shouldShowSiteSettingsBranchWarning(): boolean {
    const state = editorAPI.store.getState();
    const siteSettingsWarningDontShowAgain =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        constants.USER_PREFS.BRANCHES.SITE_SETTINGS_WARNING_DONT_SHOW_AGAIN,
      )(state);
    const hasBranches =
      stateManagement.branches.selectors.getHasBranches(state);

    return !siteSettingsWarningDontShowAgain && hasBranches;
  }

  function openSiteSettingsBranchWarning({ dashboardUrl }: AnyFixMe): void {
    editorAPI.panelManager.openPanel(
      'rEditor.panels.siteSettingsBranchWarning',
      {
        dashboardUrl,
      },
      true,
    );
  }
  function openSiteSettingsBranchWarningIfNeeded({
    dashboardUrl,
  }: AnyFixMe): void {
    if (shouldShowSiteSettingsBranchWarning()) {
      openSiteSettingsBranchWarning({ dashboardUrl });
    }
  }

  function manage(sameTab?: boolean) {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    window.open(
      `${BUSINESS_MANAGER}/${metaSiteId}/home?referralInfo=EDITOR`,
      sameTab ? '_self' : '_blank',
    );
  }

  function setUpBusiness(sameTab?: boolean) {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    window.open(
      `${BUSINESS_MANAGER}/${metaSiteId}?referralInfo=EDITOR`,
      sameTab ? '_self' : '_blank',
    );
  }

  function myAccountAction(action: string): void {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    window.open(
      `${BUSINESS_MANAGER}/${metaSiteId}/home?referrer==EDITOR&action=${action}`,
    );
  }

  function openPremiumSettings(tab: string): void {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    window.open(
      `${MY_ACCOUNT}sites/${metaSiteId}/manage-premium/${tab}?referralAdditionalInfo=DA_managePremium`,
    );
  }

  function openSiteHistory() {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const url = experiment.isOpen('specs.wos.SiteHistoryBM')
      ? `${BUSINESS_MANAGER}/${metaSiteId}/site-history`
      : `${MY_ACCOUNT}history/${metaSiteId}/?referralInfo=EDITOR`;
    window.open(url);
  }

  function upgrade(referralAdditionalInfo: string): void {
    const origin = referralAdditionalInfo.replace(/edhtml_/, '') || 'unknown';
    editorAPI.bi.event(coreBi.events.editor.UPGRADE_EVENT, { origin });
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const referralInfo = referralAdditionalInfo
      ? `referralAdditionalInfo=${referralAdditionalInfo}`
      : '';
    let url =
      util.serviceTopology.premiumServerUrl || 'https://premium.wix.com';
    url += `/wix/api/premiumStart?${referralInfo}&siteGuid=${metaSiteId}`;
    window.open(url);
  }

  function getEditorVersionsInfo(): null | EditorVersionsInfo {
    return window.editorVersionsInfo || null;
  }

  function getWixSEOWizURL(referralInfo: AnyFixMe) {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const address = `${BUSINESS_MANAGER}/${metaSiteId}/app/${SEO_WIZ_APP_DEF_ID}?app=seo`;
    return referralInfo ? `${address}&referralInfo=${referralInfo}` : address;
  }

  function openWixSEOWiz(referralInfo: AnyFixMe) {
    window.open(editorAPI.account.getWixSEOWizURL(referralInfo), '_blank');
  }

  function getSiteDashboardURL() {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    return `${BUSINESS_MANAGER}/${metaSiteId}`;
  }

  async function getUserInvalidLoggedStatus(): Promise<InvalidStatuses | null> {
    const msid = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const esi = util.editorModel.editorSessionId;

    try {
      const data: ConcurrentUsersResponse = await util.http.fetchJson(
        `${editorAPI.concurrentUsers.BASE_CONCURRENT_USERS_URL_ENDPOINT}?msid=${msid}&esi=${esi}`,
      );
      switch (data?.errorCode) {
        case -15:
          return editorAPI.dsRead.errors.save.NOT_LOGGED_IN;
        case -14:
          return editorAPI.dsRead.errors.save.USER_NOT_AUTHORIZED_FOR_SITE;
        default:
          return null;
      }
    } catch (e) {
      return null;
    }
  }

  function handleInvalidLoggedUser(invalidStatus: InvalidStatuses): void {
    const panelProps =
      accountAPIData.INVALID_LOGGED_STATUS_PROPS_DATA[invalidStatus];
    if (panelProps) {
      editorAPI.panelManager.openPanel(
        constants.PANELS.SAVE_PUBLISH.FAIL_PANEL,
        { ...panelProps, errorType: invalidStatus },
      );
    }
  }

  const getCurrentAccount = () => {
    const accountId = editorAPI.dsRead.generalInfo.getUserInfo().accountId;
    const state = shellStore.getState();
    return accountSelectors.getAccount(state, accountId);
  };

  const fetchAccounts = async () => {
    const { data } = await httpClient.request(getMyUserAccounts({}));
    shellStore.dispatch(
      accountActions.setAccounts({ accounts: data.accounts }),
    );
  };

  const getAccounts = () => {
    const state = shellStore.getState();
    return accountSelectors.getAccounts(state);
  };

  const isStudioAccount = () => {
    const state = shellStore.getState();
    return accountSelectors.getIsStudioAccount(state);
  };

  return {
    isUserOwner,
    isUserContributor,
    openConnectDomain,
    openSettings,
    openRevisions,
    openReleaseManager,
    openSiteBranchesManager,
    setUpBusiness,
    manage,
    myAccountAction,
    openPremiumSettings,
    openSiteHistory,
    upgrade,
    getEditorVersionsInfo,
    getWixSEOWizURL,
    openWixSEOWiz,
    getSiteDashboardURL,
    getUserInvalidLoggedStatus,
    handleInvalidLoggedUser,
    openSiteSettingsBranchWarningIfNeeded,
    fetchAccounts,
    getAccounts,
    isStudioAccount,
    getCurrentAccount,
  };
}
