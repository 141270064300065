import {
  START_LOADING,
  END_LOADING,
  SET_TRIGGER,
  type PagesActions,
} from './pagesActions';

export enum TriggerSources {
  MENU_ITEM = 'MENU_ITEM',
  QUICK_NAVIGATION = 'QUICK_NAVIGATION',
}

export interface Trigger {
  source: TriggerSources;
  nonePageId?: string;
}

export interface PagesState {
  loadingPageId: string | null;
  loadingTrigger: Trigger;
}

const pagesInititalState: PagesState = {
  loadingPageId: null,
  loadingTrigger: null,
};

export const pagesReducer = (
  state = pagesInititalState,
  action: PagesActions,
): PagesState => {
  switch (action.type) {
    case START_LOADING: {
      return {
        ...state,
        loadingPageId: action.pageId,
      };
    }
    case END_LOADING: {
      return {
        ...state,
        loadingTrigger: null,
        loadingPageId: null,
      };
    }
    case SET_TRIGGER: {
      return { ...state, loadingTrigger: action.trigger };
    }
    default:
      return state;
  }
};
