import React, { useState, useCallback } from 'react';

import { TreeListItem, Text, Button, Tooltip } from '@wix/wix-base-ui';
import { translate, i18n } from '@/i18n';
import { Trans } from 'react-i18next';
import styles from './slotPlaceholderItem.scss';
import type { SlotPlaceholder, SlotPlaceholderItemProps } from './types';

const SLOT_PLACEHOLDER_DEFAULT_NAME = 'SlotsPlaceholder';

const SlotPlaceholderItem: React.FC<SlotPlaceholderItemProps> = ({
  highlightSlot,
  unhighlightSlot,
  moveToSlot,
  openWidgetPluginsPanel,
  slotPlaceholder,
  slotPlaceholderIndex,
  pluginName,
  isPopulated,
  isSelected,
}) => {
  const [isTooltopHovered, setTooltipHovered] = useState(false);

  const handleTootltipMouseEnter = () => setTooltipHovered(true);
  const handleTootltipMouseLeave = () => setTooltipHovered(false);

  const onSlotHighlight = useCallback(() => {
    highlightSlot(slotPlaceholder.slotRef);
  }, [highlightSlot, slotPlaceholder]);

  const onSlotUnhighlight = useCallback(() => {
    unhighlightSlot(slotPlaceholder.slotRef);
  }, [unhighlightSlot, slotPlaceholder]);

  const onSlotMove = useCallback(() => {
    moveToSlot(slotPlaceholder.slotRef);
  }, [moveToSlot, slotPlaceholder]);

  const onOpenWidgetsPluginPanel = useCallback(() => {
    openWidgetPluginsPanel(slotPlaceholder.slotRef);
  }, [openWidgetPluginsPanel, slotPlaceholder]);

  const getPlaceholderDisplayName = (
    slotPlaceholder: SlotPlaceholder,
    slotPlaceholderIndex: number,
  ) => {
    if (slotPlaceholder.name === SLOT_PLACEHOLDER_DEFAULT_NAME) {
      return translate('PLATFORM_Widget_Slots_Move_Plugin_Placeholder_Label', {
        Placeholder_Number: slotPlaceholderIndex,
      });
    }

    return slotPlaceholder.name;
  };

  const isSlotDisabled = isPopulated && !isSelected;

  return (
    <div onMouseEnter={onSlotHighlight} onMouseLeave={onSlotUnhighlight}>
      <TreeListItem
        className={styles.slotPlaceholderItem}
        dataHook={`slots.movePluginIndex:${slotPlaceholderIndex}`}
        shouldTranslate={false}
        selected={isSelected}
        disabled={isSlotDisabled}
        isFocused={isTooltopHovered}
        onClick={onSlotMove}
        label={
          <Text
            size="small"
            weight="thin"
            skin="standard"
            disabled={isSlotDisabled}
            shouldTranslate={false}
          >
            {getPlaceholderDisplayName(slotPlaceholder, slotPlaceholderIndex)}
          </Text>
        }
        suffix={
          <Tooltip
            onOpen={handleTootltipMouseEnter}
            onClose={handleTootltipMouseLeave}
            content={
              <div className={styles.slotPlaceholderItemTooltip}>
                <Text
                  size="small"
                  enableEllipsis={false}
                  shouldTranslate={false}
                >
                  <Trans
                    i18n={i18n}
                    i18nKey="PLATFORM_Widget_Slots_Move_Plugin_Disabled_Tooltip"
                    values={{
                      Plugin_Name: pluginName,
                    }}
                  />
                </Text>{' '}
                <span onClick={onOpenWidgetsPluginPanel}>
                  <Text size="small" skin="link" enableEllipsis={false}>
                    {'PLATFORM_Widget_Slots_Move_Plugin_Disabled_Tooltip_Link'}
                  </Text>
                </span>
              </div>
            }
            disabled={!isSlotDisabled}
            interactive={true}
          >
            <div>
              <Button className="btn-sm" disabled={isSlotDisabled}>
                {translate(
                  'PLATFORM_Widget_Slots_Move_Plugin_Placement_MoveHere_CTA',
                )}
              </Button>
            </div>
          </Tooltip>
        }
      />
    </div>
  );
};

export default SlotPlaceholderItem;
