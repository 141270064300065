import { domMeasurements } from '@/stateManagement';
import type { MapStateToProps } from 'types/redux';
import type { SectionDropZoneOwnProps } from './SectionsDropZone';

export interface SectionsDropZoneStateProps {
  left: number;
  width: number;
}

export const mapStateToProps: MapStateToProps<
  SectionsDropZoneStateProps,
  SectionDropZoneOwnProps
> = (stateMapperArgs) => {
  const { state } = stateMapperArgs;

  const siteScale = domMeasurements.selectors.getSiteScale(state);
  const previewPosition = domMeasurements.selectors.getPreviewPosition(state);
  const width = previewPosition.width * siteScale;

  return {
    left: (previewPosition.width - width) / 2,
    width,
  };
};
