import React, { useEffect } from 'react';
import {
  createSelector,
  createStructuredSelector,
} from '../../selectors/selector';
import {
  editorAPIMouseSel,
  editorStateMouseOpsSel,
} from '../selectors/rootSelectors';
import { selectedCompsSel } from '../selectors/selectedCompsSelectors';
import { hoc } from '@/util';
import LazyComponent from '@/lazyComponent';

import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';

const getSingleSelectedImageSel = createSelector(
  [editorAPIMouseSel, selectedCompsSel],
  (editorAPI, selectedComponents) => {
    if (selectedComponents && selectedComponents.length === 1) {
      const singleComponent = selectedComponents[0];
      const compDataType = editorAPI.components.data.get(singleComponent)?.type;
      if (compDataType === 'Image') {
        return singleComponent;
      }
    }
    return null;
  },
);

const imageCropSel = createSelector(
  [editorStateMouseOpsSel],
  (state) => state.imageCrop,
);

const mapStateToProps = createStructuredSelector({
  imageCropData: imageCropSel,
  imageComponent: getSingleSelectedImageSel,
});

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    exitCropMode: () => {
      editorAPI.imageCrop.toggleCropMode(false, () => {});
    },
  };
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ImageCropWrapper = (props: Props) => {
  const { imageComponent, exitCropMode } = props;

  useEffect(
    () => {
      if (!imageComponent) {
        exitCropMode();
      }
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageComponent],
  );

  if (!imageComponent) {
    return null;
  }

  return (
    <LazyComponent moduleName="maskCrop.layer" key="maskCrop" {...props} />
  );
};

const shoudRenderImageCropWrapperSel = createSelector(
  [imageCropSel],
  (data) => data.isOn,
);

export default hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  shoudRenderImageCropWrapperSel,
)(
  hoc.connect(
    hoc.STORES.MOUSE_OPS,
    mapStateToProps,
    mapDispatchToProps,
  )(ImageCropWrapper),
);
