:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._control-illustration_1hxh7_7 svg {
  margin-top: 20px; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._control-illustration_1hxh7_7 svg._symbol-publish_test_site_override_warning_1hxh7_10 {
  margin-top: 0; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ._steps-title_1hxh7_13 {
  font-weight: 600; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ._error-description_1hxh7_16 {
  max-width: 375px; }
  ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ._error-description_1hxh7_16 span {
    margin-right: 4px; }
  ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ._error-description_1hxh7_16 ._error-description-secondary-text_1hxh7_20 {
    margin-top: 10px; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ._error-description_1hxh7_16,
._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ._description_1hxh7_24,
._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 h3 {
  margin-bottom: 16px;
  margin-top: 0; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ol {
  padding: 0 0 0 24px;
  margin: 0; }
  ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ol li {
    list-style: inherit;
    margin-bottom: 5px; }
    ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel__message_1hxh7_13 ol li:last-of-type {
      margin-bottom: 0; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel-help-text_1hxh7_38 {
  margin: 0; }
  ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel-help-text_1hxh7_38 span {
    color: #3b4057; }
  ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel-help-text_1hxh7_38 ._link-part_1hxh7_42 {
    padding-left: 0.4em;
    color: #116dff; }
    ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel-help-text_1hxh7_38 ._link-part_1hxh7_42:hover {
      cursor: pointer;
      text-decoration: underline; }
  ._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7 ._fail-panel-help-text_1hxh7_38 ._error-code-part_1hxh7_48 {
    padding-left: 0.4em; }

._message-panel-frame_1hxh7_7._fail-panel_1hxh7_7._test-site-override-fail-panel_1hxh7_51 {
  width: 615px; }

._failPanel_1hxh7_54 ol {
  padding-left: 18px; }

._failPanel_1hxh7_54 li {
  list-style: inherit; }

._failPanel_1hxh7_54 a {
  color: #116dff; }
  ._failPanel_1hxh7_54 a:hover {
    cursor: pointer;
    text-decoration: underline; }

._failPanel_1hxh7_54 ._error-description-secondary-text_1hxh7_20 {
  margin-top: 10px; }

._failPanel_1hxh7_54 ._errorDescriptionText_1hxh7_69 {
  margin-right: 4px; }

._failPanel_1hxh7_54 .fail-panel-help-text {
  font-size: 14px; }
  ._failPanel_1hxh7_54 .fail-panel-help-text .link-part {
    margin-left: 4px; }
