// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';
import constants from '@/constants';
import { platformConnectedGfpp, getGfppNotifyUtils } from '@/platform';
import { getRefComponentRootChild } from '@/documentServices';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

import type {
  GFPPData,
  GFPPActionsDictionary,
} from '@wix/editor-component-types';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const wrapOnClick = (actions: GFPPActionsDictionary) =>
  _.mapValues(actions, (action) => {
    const refComponentOnClick = action.onClick;
    if (refComponentOnClick) {
      const onClick = (editorAPI: EditorAPI, compRefs: CompRef[]) => {
        const refComponent =
          editorAPI.dsRead.components.refComponents.getRefHostCompPointer(
            compRefs[0],
          );
        refComponentOnClick(editorAPI, [refComponent]);
      };
      return _.defaults({ onClick }, action);
    }
    return action;
  });

const getGfppDefinition = (
  editorAPI: EditorAPI,
  compRefs: CompRef[],
): GFPPData => {
  const compRef = compRefs[0];
  const rootChildRef = getRefComponentRootChild(editorAPI.dsRead, compRef);

  const compGfppData: GFPPData = {
    additionalCompForGfpp: rootChildRef,
    mainActions: [],
    enabledActions: [
      ...(experiment.isOpen('se_widgetPlugins')
        ? [ACTIONS.WIDGET_PLUGINS]
        : []),
      ...(experiment.isOpen('se_disableAnimationGfppForRefferdComponent')
        ? [ACTIONS.ANIMATION]
        : []),
    ],
  };

  const deviceType = editorAPI.isDesktopEditor() ? 'desktop' : 'mobile';

  const perculateChildManifestToRef = (refComponentGfppData: GFPPData) => {
    const childData = editorAPI.components.data.get([
      refComponentGfppData.additionalCompForGfpp,
    ]);
    if (childData?.applicationId) {
      const gfppDataFromApp = editorAPI.platform.controllers.getStageData(
        refComponentGfppData.additionalCompForGfpp,
      )?.gfpp;
      if (gfppDataFromApp) {
        const { notifyGfppButtonClicked } = getGfppNotifyUtils(
          editorAPI,
          compRefs[0],
          childData.applicationId,
        );
        const overrideOnlyExistingActions = true;
        refComponentGfppData.presetActions =
          platformConnectedGfpp.generateIconButtonsOverride(
            deviceType,
            gfppDataFromApp,
            refComponentGfppData,
            notifyGfppButtonClicked,
            compGfppData.additionalCompForGfpp,
            editorAPI,
            overrideOnlyExistingActions,
          );
      }
    }
  };

  compGfppData.mergeGfpp = (refComponentGfppData, childGfppData) => {
    perculateChildManifestToRef(refComponentGfppData);
    refComponentGfppData.presetActions = {
      ...childGfppData.presetActions,
      ...wrapOnClick(refComponentGfppData.presetActions),
    };

    if (experiment.isOpen('se_removeTedundentGfppSliceForRefComp')) {
      refComponentGfppData.mainActions = [
        ...(refComponentGfppData.mainActions || []),
        ...childGfppData.mainActions,
      ].filter(Boolean);
    } else {
      refComponentGfppData.mainActions = [
        ...(refComponentGfppData.mainActions || []),
        ...childGfppData.mainActions,
      ]
        .filter(Boolean)
        .slice(0, 2);
    }
  };

  return compGfppData;
};

export default getGfppDefinition;
