'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_paragraphs",
    "subNavigationTitle": "add_section_label_paragraphs",
    "presetTitle": "add_section_label_paragraphs",
    "tooltipTitle": "add_section_label_paragraphs",
    "automationId": "add-panel-section-paragraphsSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "Text_Paragraphs_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 80,
                    "x": 11,
                    "y": 16,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.3em\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdj5.text')}</span></span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdj5"
            },
            "preset": {"rect": {"width": 324, "height": 112, "x": 0, "y": 0}, "tags": null}
        }, {
            "id": "Text_Paragraphs_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 63,
                    "x": 10,
                    "y": 131,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.2em\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif\"><span style=\"font-size:16px\"><span><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdja.text')}</span></span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdja"
            },
            "preset": {"rect": {"width": 324, "height": 101, "x": 0, "y": 112}, "tags": null}
        }, {
            "id": "Text_Paragraphs_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 84,
                    "x": 11,
                    "y": 228,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.3em\"><span style=\"letter-spacing:0.03em\"><span style=\"font-size:16px\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdjc.text')}</span></span></span></span></p>`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdjc"
            },
            "preset": {"rect": {"width": 324, "height": 113, "x": 0, "y": 213}, "tags": null}
        }, {
            "id": "Text_Paragraphs_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 60,
                    "x": 10,
                    "y": 346,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.35em\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdjd.text')}</span></span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdjd"
            },
            "preset": {"rect": {"width": 324, "height": 97, "x": 0, "y": 326}, "tags": null}
        }, {
            "id": "Text_Paragraphs_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 80,
                    "x": 10,
                    "y": 439,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.35em\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdjg.text')}</span></span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdjg"
            },
            "preset": {"rect": {"width": 324, "height": 113, "x": 0, "y": 423}, "tags": null}
        }, {
            "id": "Text_Paragraphs_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 84,
                    "x": 11,
                    "y": 553,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:14px; line-height:1.5em\"><span style=\"font-size:14px\"><span style=\"font-family:libre baskerville,serif\"><span style=\"letter-spacing:0.03em\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdji.text')}</span></span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdji"
            },
            "preset": {"rect": {"width": 324, "height": 123, "x": 0, "y": 536}, "tags": null}
        }, {
            "id": "Text_Paragraphs_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 90,
                    "x": 11,
                    "y": 671,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-size:15px\"><span style=\"letter-spacing:0.01em\"><span><span style=\"font-style:italic\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdjk.text')}</span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdjk"
            },
            "preset": {"rect": {"width": 324, "height": 120, "x": 0, "y": 659}, "tags": null}
        }, {
            "id": "Text_Paragraphs_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 302,
                    "height": 84,
                    "x": 10,
                    "y": 796,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.4em\"><span style=\"font-size:15px\"><span style=\"font-family:playfair display,serif\"><span style=\"letter-spacing:0.03em\"><span style=\"color:#000000\">${i18n.translate('comp-jb3sxdjn.text')}</span></span></span></span></p>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-jb3sxdjn"
            },
            "preset": {"rect": {"width": 324, "height": 119, "x": 0, "y": 779}, "tags": null}
        }],
        "compTypes": ["wysiwyg.viewer.components.WRichText"]
    },
    "help": {"hide": false, "text": "add_section_info_text_paragraphs"}
}
