'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_vectorart_logos_label",
        "subNavigationTitle": "add_section_vectorart_logos_label",
        "presetTitle": "add_section_vectorart_logos_label",
        "tooltipTitle": "add_section_vectorart_logos_label",
        "automationId": "add-panel-section-logosBadgesSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/vectorArt/logosSection.v1.png",
            "retinaImage": {
                "src": "addPanelData/sections/vectorArt/logosSection-@2x.v1.png",
                "dimensions": {
                    "width": 648,
                    "height": 1262
                }
            },
            "imageHover": null,
            "items": [{
                "id": "VectorArt_Logos_&_Badges_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 162,
                        "height": 104,
                        "x": 9,
                        "y": 27,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "fa7d9cf5adf64788a54aa14312c99084.svg",
                        "title": "grange-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w1v",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkfu37"
                },
                "preset": {
                    "rect": {"width": 81, "height": 53, "x": 9, "y": 28},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "7, 30, 34, 12, 93, 20, 108, 64, 90, 93, 11, 94, 0, 65"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 170,
                        "height": 116,
                        "x": 121,
                        "y": 26,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0a691fb900fd4aac8376faf1dfb4b165.svg",
                        "title": "63",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w1z",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkf93t"
                },
                "preset": {
                    "rect": {"width": 85, "height": 58, "x": 121, "y": 28},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "122, 22, 112, 91, 121, 95, 218, 99, 219, 79, 204, 15"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 110,
                        "height": 226,
                        "x": 251,
                        "y": 28,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a8717d25b1844ae78e3ab3104260ad8c.svg",
                        "title": "media-107-19",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w23",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkelvn"
                },
                "preset": {
                    "rect": {"width": 56, "height": 113, "x": 251, "y": 28},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "262, 24, 240, 81, 248, 152, 317, 153, 318, 18"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 224,
                        "height": 132,
                        "x": 102,
                        "y": 109,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "fe846e299e0447a889988bf959c3f2c3.svg",
                        "title": "media-107-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w3n",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wk8aoi"
                },
                "preset": {
                    "rect": {"width": 112, "height": 67, "x": 102, "y": 110},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "114, 102, 91, 140, 128, 188, 212, 189, 231, 160, 207, 114"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 106,
                        "height": 106,
                        "x": 9,
                        "y": 117,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "f59966be45ae44b78a68de8cb5088ceb.svg",
                        "title": "43_2_4",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w1r",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkgicw"
                },
                "preset": {
                    "rect": {"width": 53, "height": 53, "x": 9, "y": 117},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "3, 105, 74, 106, 76, 183, 2, 184"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 130,
                        "height": 196,
                        "x": 249,
                        "y": 171,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "40837b6c35c94dc689a515236d39feb2.svg",
                        "title": "media-107-08",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w38",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wk9ej0"
                },
                "preset": {
                    "rect": {"width": 66, "height": 98, "x": 249, "y": 171},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "259, 170, 236, 206, 243, 275, 263, 281, 308, 281, 319, 235, 319, 163"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 144,
                        "height": 130,
                        "x": 122,
                        "y": 202,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "09b89d69f160497c877db2501208c4ec.svg",
                        "title": "Abstract Triangles   ",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2q",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkb7cx"
                },
                "preset": {
                    "rect": {"width": 73, "height": 65, "x": 122, "y": 203},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "144, 198, 106, 274, 120, 284, 204, 279, 211, 261, 172, 193"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 120,
                        "height": 126,
                        "x": 10,
                        "y": 211,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "029295eeb2574d86925a97e0ad0f531b.svg",
                        "title": "28",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w31",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wka17p"
                },
                "preset": {
                    "rect": {"width": 61, "height": 63, "x": 10, "y": 211},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "4, 203, 78, 202, 86, 273, 19, 284, 2, 270"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 266,
                        "height": 78,
                        "x": 10,
                        "y": 300,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "64b2fa6719f24e14944178a69c946d47.svg",
                        "title": "19",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2y1",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wka7jt"
                },
                "preset": {
                    "rect": {"width": 133, "height": 39, "x": 10, "y": 302},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "4, 296, 4, 356, 150, 349, 151, 291"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 76,
                        "x": 182,
                        "y": 302,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "024dc6b893e24492ad84880f16d2da4d.svg",
                        "title": "41",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2k",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkbuki"
                },
                "preset": {
                    "rect": {"width": 132, "height": 38, "x": 182, "y": 304},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "168, 296, 172, 347, 195, 353, 315, 353, 320, 339, 321, 293"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 140,
                        "height": 136,
                        "x": 242,
                        "y": 367,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "3b017380a2ad4876ab25418a2f2757c4.svg",
                        "title": "grange-04",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w27",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkdrxq"
                },
                "preset": {
                    "rect": {"width": 70, "height": 69, "x": 242, "y": 368},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "246, 369, 228, 427, 237, 450, 314, 437, 321, 407, 317, 364, 269, 359"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 100,
                        "height": 234,
                        "x": 18,
                        "y": 369,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "aca6ee4c46d44f82ab69fbbeab6e9e2f.svg",
                        "title": "media-107-16",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2v1",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkai75"
                },
                "preset": {
                    "rect": {"width": 51, "height": 117, "x": 18, "y": 369},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "21, 363, 44, 360, 80, 379, 79, 490, 50, 497, 27, 497, 8, 448, 8, 381"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 158,
                        "height": 110,
                        "x": 116,
                        "y": 368,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "969c889f99a94ef38c6cbe9d7c484e2a.svg",
                        "title": "6 2",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2b",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkda0a"
                },
                "preset": {
                    "rect": {"width": 79, "height": 55, "x": 116, "y": 370},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "137, 363, 103, 403, 99, 420, 114, 436, 205, 438, 216, 411, 174, 359"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_14",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 136,
                        "height": 106,
                        "x": 245,
                        "y": 456,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "b49e56e1ed904537939a7efb1467a7fc.svg",
                        "title": "media-107-14",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2t",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkaxgs"
                },
                "preset": {
                    "rect": {"width": 68, "height": 54, "x": 245, "y": 457},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "241, 465, 232, 500, 248, 521, 306, 522, 322, 496, 320, 456, 287, 449"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_15",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 166,
                        "height": 102,
                        "x": 114,
                        "y": 456,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8756c20158f349fcb3fb593ea851e5c9.svg",
                        "title": "9",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w3c",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wk96ru"
                },
                "preset": {
                    "rect": {"width": 83, "height": 51, "x": 114, "y": 458},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "101, 456, 107, 519, 210, 522, 209, 450, 155, 447"}
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_16",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 130,
                        "height": 202,
                        "x": 8,
                        "y": 509,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "389f182176c94e72be17c20be9e419d4.svg",
                        "title": "47",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w3i",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wk8zoo"
                },
                "preset": {
                    "rect": {"width": 66, "height": 102, "x": 8, "y": 509},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "30, 505, 5, 535, 10, 605, 39, 626, 52, 625, 83, 558, 83, 524, 67, 505"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_17",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 148,
                        "height": 150,
                        "x": 118,
                        "y": 534,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c7008bdd96ee435990bab4a98a152526.svg",
                        "title": "11",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2n",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkbmio"
                },
                "preset": {
                    "rect": {"width": 75, "height": 75, "x": 118, "y": 534},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "112, 544, 101, 581, 125, 615, 158, 625, 190, 617, 203, 586, 206, 548, 192, 532, 157, 527, 129, 533"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }, {
                "id": "VectorArt_Logos_&_Badges_18",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 150,
                        "height": 128,
                        "x": 239,
                        "y": 541,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c38465d68b864c78b85217232c76385f.svg",
                        "title": "6",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w2h",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9wkcb2x"
                },
                "preset": {
                    "rect": {"width": 75, "height": 64, "x": 239, "y": 543},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "256, 533, 223, 570, 226, 590, 258, 617, 295, 619, 320, 597, 320, 570, 289, 534"
                    }
                },
                "mediaManager": {
                    "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
                }
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "styleOverride": {"paddingTop": "25px"},
            "label": "add_section_vectorart_logos_more_tooltip_title",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_vector_art_logos",
            "path": "public/98285ba8ab164d17a935742bd019e150/879d761ba619433794b02f7343a3bcd4"
        },
        "help": {"hide": false, "text": "add_section_vectorart_logos_tooltip_description"}
    }
