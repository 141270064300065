import { events } from '@/coreBi';
import { fedopsLogger, sections as sectionsUtils } from '@/util';

import {
  hasPagesWithAnchorsInRoot,
  hasPagesWithSectionsInRoot,
} from '../utils';
import { MigrationFlow } from '../types';
import { MIGRATION_VERSION } from '../version';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';
import type { MigrationOrigin } from '../constants';

export function beforeMigration(
  scope: Scope,
  {
    flow,
    origin,
    pagesRefs,
  }: {
    flow: MigrationFlow;
    origin: MigrationOrigin;
    pagesRefs: CompRef[];
  },
) {
  const { documentServices, autosaveManager, savePublish } = scope.editorAPI;

  autosaveManager.init({ enabled: false });
  savePublish.lockSavePublish();
  documentServices.tpa.section.defaults.isContainable.set(true);
  documentServices.documentMode.setShouldKeepChildrenInPlace(false);
  scope.migrationStatus.setMigrationStarted();

  // save migration version before actual migration in case of restore session after failure
  if (flow === MigrationFlow.HeavySite || flow === MigrationFlow.Editor) {
    sectionsUtils.updateSiteSectionsEditorData(scope.editorAPI, {
      isSectionsEnabled: false,
      migrationVersion: MIGRATION_VERSION,
    });
  }

  const revision = documentServices.generalInfo.getRevision();

  scope.editorAPI.bi.event(events.sectionsMigration.MIGRATION_START, {
    flow,
    origin,
    revision:
      flow === MigrationFlow.Editor || flow === MigrationFlow.Editor2Fix
        ? revision + 1 // Editor flow has additional save before start
        : revision,
    template_id: documentServices.generalInfo.getSiteOriginalTemplateId(),
    is_anchor_in_structure: hasPagesWithAnchorsInRoot(
      documentServices,
      pagesRefs,
    ),
    is_section_in_structure: hasPagesWithSectionsInRoot(
      documentServices,
      pagesRefs,
    ),
  });

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.SECTIONS_MIGRATION.BULK_MIGRATION,
  );
}
