:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._editorSearch_e8mos_7 {
  margin: 0 0 12px;
  padding: 12px 0;
  text-align: center; }
  ._editorSearch_e8mos_7 ._label_e8mos_11 {
    display: block; }
  ._editorSearch_e8mos_7 ._actionButton_e8mos_13 {
    margin-top: 14px;
    background-color: #fff; }
    ._editorSearch_e8mos_7 ._actionButton_e8mos_13.control-button svg path {
      fill: currentColor; }
    ._editorSearch_e8mos_7 ._actionButton_e8mos_13.control-button .control-label {
      color: currentColor; }
