import ImageToBackground from './components/imageToBackground';
import {
  isImageToBackgroundEnabled,
  backgroundCandidatesCompTypes,
  getMainBackgroundCandidate,
} from './api';
import type { Scope } from './scope';

const MIN_COMP_SIZE_FOR_IMAGE_TO_BG_BUTTON = 150;

export function init(scope: Scope) {
  const { workspace, selection, components } = scope;
  workspace.contributeEditBoxComponent(ImageToBackground, () => {
    const selectedComponents = selection.getSelectedComponents();
    if (selectedComponents.length > 1) {
      return false;
    }

    if (!isImageToBackgroundEnabled(scope, selectedComponents)) {
      return false;
    }

    const { width: selectedCompWidth, height: selectedCompHeight } =
      components.layout.get(selectedComponents);
    const selectedComponentContainer =
      components.getContainerOrScopeOwner(selectedComponents);

    if (
      selectedCompWidth < MIN_COMP_SIZE_FOR_IMAGE_TO_BG_BUTTON ||
      selectedCompHeight < MIN_COMP_SIZE_FOR_IMAGE_TO_BG_BUTTON ||
      (!backgroundCandidatesCompTypes.includes(
        components.getType(selectedComponentContainer),
      ) &&
        !components.is.group(selectedComponentContainer))
    ) {
      return false;
    }

    const mainBackgroundCandidate = getMainBackgroundCandidate(
      scope,
      selectedComponents[0],
    );

    return !!mainBackgroundCandidate;
  });
}
