// @ts-nocheck
import _ from 'lodash';

function generatePanelToken(appDefinitionId) {
  return _.uniqueId(`platform_${appDefinitionId}_`);
}

function generateStaticGlobalPanelToken(panelName) {
  return _.uniqueId(`platform_${panelName}`);
}

export { generatePanelToken, generateStaticGlobalPanelToken };
