import { DATA_BINDING } from '@wix/app-definition-ids';

import { EVENTS } from '@/wixData';
import type { EditorAPI } from '@/editorAPI';
import { serviceTopology } from '@/util';
import { LEGACY_WIX_APPS_NAMES } from '@/constants';

import type { LinkObject, ImageObject } from './types';
import type { WixAppsAppNames } from './constants';

export const getLinkHref = (link: LinkObject): string => {
  return link.protocol && link.address
    ? `${link.protocol}://${link.address}`
    : '';
};

export const rebuildTextWithLinks = (text: string, links: LinkObject[]) => {
  return links.reduce((acc, link) => {
    const { target } = link;
    const href = getLinkHref(link);

    if (!href) {
      return acc;
    }

    const linkAttributes = `target="${target}" href="${href}"`;

    return acc.replace(`dataquery="#${link.linkId}"`, linkAttributes);
  }, text);
};

const walkChildNodes = (
  node: HTMLElement,
  process: (node: HTMLElement) => void,
): void => {
  process(node);

  Array.from(node.children).forEach((child) =>
    walkChildNodes(child as HTMLElement, process),
  );
};

export const sanitizeHtmlContent = (htmlString: string) => {
  const allowedCssRules = [
    'text-align',
    'font-weight',
    'font-style',
    'text-decoration',
  ];

  const container = document.createElement('div');
  container.innerHTML = htmlString;

  walkChildNodes(container, (node) => {
    const ruleNames = Array.from(node.style);

    ruleNames.forEach((ruleName) => {
      if (allowedCssRules.includes(ruleName)) {
        return;
      }

      node.style.removeProperty(ruleName);
    });
  });

  return container.innerHTML;
};

export const getWixImageDescriptor = (
  wixImageObject: ImageObject,
  appName?: WixAppsAppNames,
) => {
  const { src, title, width, height } = wixImageObject;

  const staticImageMatch = src.match(/^(http:\/\/)?(images\/.*)/);

  if (staticImageMatch) {
    if (appName) {
      const baseUrl = serviceTopology.scriptsLocationMap.wixapps;
      return `${baseUrl}/javascript/wixapps/apps/${appName}/${staticImageMatch[2]}`;
    }

    return null;
  }

  return `wix:image://v1/${src}/${
    title || src
  }#originWidth=${width}&originHeight=${height}`;
};

export const getRichTextContent = (text: string, links: LinkObject[]) => {
  text = rebuildTextWithLinks(text, links);
  text = sanitizeHtmlContent(text);
  text = text.replace(/hatul/g, 'p');
  return text;
};

export const isNewsAppPartName = (appPartName: LEGACY_WIX_APPS_NAMES) =>
  appPartName === LEGACY_WIX_APPS_NAMES.NEWS_A ||
  appPartName === LEGACY_WIX_APPS_NAMES.NEWS_B;

export const getSchemaFilePath = (collectionId: AnyFixMe) =>
  `.schemas/${collectionId}.json`;

export const getDataBindingApplicationId = (editorAPI: EditorAPI) => {
  return editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING)
    .applicationId;
};

export const provisionWixCodeIfNeeded = async (editorAPI: EditorAPI) => {
  if (!editorAPI.dsRead.wixCode.isProvisioned()) {
    await new Promise((resolve, reject) => {
      editorAPI.wixCode.provision({
        onSuccess: resolve,
        onError: reject,
      });
    });
  }
};

export const enableDataMode = (
  editorAPI: EditorAPI,
  origin = 'responsivePagesPanel/turnOnData',
) => {
  editorAPI.dsActions.platform.notifyApplication(
    getDataBindingApplicationId(editorAPI),
    {
      eventType: EVENTS.enableDataModeClicked,
      eventPayload: {
        // https://github.com/wix-private/wix-data-client/blob/2cd2f940058aede87eb562dd028b06399703181d/dbsm-editor-worker/src/core-module/events.js#L28
        origin,
      },
    },
  );
};

export const openContentManagerPanelIfNeeded = (editorAPI: EditorAPI) => {
  const CONTENT_MANAGER_PANEL_NAME = 'wixData.panel';

  if (!editorAPI.panelManager.isPanelOpened(CONTENT_MANAGER_PANEL_NAME)) {
    editorAPI.panelManager.openPanel(CONTENT_MANAGER_PANEL_NAME, {}, null);
  }
};

export const refreshSchemasCacheForDataBindingApp = async (
  editorAPI: EditorAPI,
) => {
  const dataBindingWorkerPrivateAPI =
    await editorAPI.platform.getAppPrivateApi(DATA_BINDING);

  await (
    dataBindingWorkerPrivateAPI as {
      refreshSchemasCache: () => Promise<void>;
    }
  ).refreshSchemasCache();
};
