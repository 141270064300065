import React from 'react';
import { Checkbox } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import { frames, PanelCloseOrigin } from '@/panels';
import { SafeInjectHtml } from '@/util';

const { MessagePanelFrame } = frames;

interface GoogleTranslateWarningPanelFrameProps {
  panelName: string;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  dontShowAgain: boolean;
  howMuchLeftAfterTranslation: number;
  source: 'site-translation' | 'element-translation';
  onDontShowAgainChange: (value: boolean) => void;
}

export const GoogleTranslateWarningPanelFrame: React.FC<
  GoogleTranslateWarningPanelFrameProps
> = (props) => {
  const dontShowAgainCheckbox = (
    <Checkbox
      labelAfterSymbol={true}
      shouldTranslate={false}
      label={translate('Notification_Box_Dont_Show_Again')}
      value={props.dontShowAgain}
      onChange={props.onDontShowAgainChange}
    />
  );

  return (
    <MessagePanelFrame
      panelName={props.panelName}
      illustration={null}
      title={translate(
        'gfpp_mainaction_google_translate_enoughCredits_modal_header',
      )}
      confirmLabel={translate(
        'google_translate_enoughCredits_modal_autoTranslate',
      )}
      onConfirm={props.onConfirm}
      cancelLabel={translate('google_translate_enoughCredits_modal_cancel')}
      onCancel={(origin: string) => {
        if (origin === PanelCloseOrigin.HEADER_CLOSE_BUTTON) props.onClose();
        else props.onCancel();
      }}
      sideActions={dontShowAgainCheckbox}
    >
      <SafeInjectHtml
        tag="span"
        html={translate('google_translate_enoughCredits_modal_subheader', {
          machine_translation_words_left: props.howMuchLeftAfterTranslation,
        })}
      />
    </MessagePanelFrame>
  );
};
