import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 296,
        height: 397,
        x: 6,
        y: 12,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'hk45n',
      },
      parent: 'comp-kgujmci1',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        title: '',
        uri: '6e950387eaaa402aae1e185c5c4820a8.jpg',
        description: '',
        width: 5120,
        height: 3413,
        alt: 'Smiling Young Man',
        crop: {
          x: 1480,
          y: 0,
          width: 2557,
          height: 3413,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Smiling Young Man',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'hk45n',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 2,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqklka04',
        recommendedScale: 1,
        recommendedWidth: 280,
        recommendedHeight: 373,
        orderIndex: 0,
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 296,
        height: 131,
        x: 6,
        y: 287,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'hk45n',
      },
      parent: 'comp-kgujmci1',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        svgId: '06f469158f5346a3be7e4100e38370cd.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'hk45n',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 317,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqklka13',
        recommendedScale: 1,
        recommendedWidth: 279,
        recommendedHeight: 165,
        orderIndex: 1,
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        overrideColors: {
          color1: '#2E55DC',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 1,
          stroke: '#423737',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 100,
            type: 'percentage',
          },
        },
      },
      scopedTransformations: {
        'variants-kgujmcio': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'hk45n',
          },
          hidden: false,
          rotate: 0,
          skew: {
            x: 0,
            y: 0,
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
          scale: {
            x: 1,
            y: 1.45,
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: 'i8yjldko',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 147,
        height: 30,
        x: 24,
        y: 377,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'hk45n',
      },
      parent: 'comp-kgujmci1',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        label: translate('interactions_preset_profile_dev_CTA'),
        svgId: '11062b_e9770525106541b09175d8aa8119707d.svg',
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: -319,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqklka35',
        recommendedScale: 0.93333334,
        recommendedWidth: 160,
        recommendedHeight: 42,
        orderIndex: 2,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
      },
      scopedTransformations: {
        'variants-kgujmcio': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'hk45n',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: rgba(255,255,255,0);padding-right: 12px}.root:hover{background: rgba(255,255,255,0);border: 0px solid value(site_1_5);padding: 0px;padding-right: 12px}.root:hover::label{\n    color: #FFFFFF}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit;justify-content: space-between}.root::label{transition: inherit;letter-spacing: 0em;margin: 0px 4px 0px 0px;font-family: helvetica-w01-light,helvetica-w02-light,sans-serif;font-size: 14px;color: #FFFFFF}.root::icon{transition: inherit;margin: 0px 0px 0px 4px;width: 40px;height: 40px;fill: #FFFFFF}.root:hover::icon{\n    fill: #FFFFFF}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 196,
        height: 24,
        x: 24,
        y: 307,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'hk45n',
      },
      parent: 'comp-kgujmci1',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        text: `<h2 class="font_2" style="font-size:20px"><span style="font-size:20px"><span style="color:#FFFFFF"><span style="font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif">${translate(
          'interactions_preset_profile_dev_name',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'hk45n',
        },
        brightness: 1,
        packed: false,
        minHeight: 23,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        author: 'studio',
        offsetX: 18,
        offsetY: 336,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqklka52',
        recommendedScale: 1,
        recommendedWidth: 142,
        recommendedHeight: 24,
        orderIndex: 3,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
      },
      scopedTransformations: {
        'variants-kgujmcio': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -65,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'hk45n',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 196,
        height: 19,
        x: 24,
        y: 339,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'hk45n',
      },
      parent: 'comp-kgujmci1',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        text: `<h2 class="font_2" style="font-size:15px"><span style="font-size:15px"><span style="font-family:helvetica-w01-light,helvetica-w02-light,sans-serif"><span style="color:#FFFFFF">${translate(
          'interactions_preset_profile_dev_position',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'hk45n',
        },
        brightness: 1,
        packed: false,
        minHeight: 14,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 33,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqklka7',
        recommendedScale: 0.93333334,
        recommendedWidth: 72,
        recommendedHeight: 18,
        orderIndex: 4,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
      },
      scopedTransformations: {
        'variants-kgujmcio': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -65,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'hk45n',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 251,
        height: 35,
        x: 24,
        y: 370,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'hk45n',
      },
      parent: 'comp-kgujmci1',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        text: `<h2 class="font_2" style="font-size: 12px; line-height: 1.4em;"><span style="font-size:12px;"><span style="font-family:helvetica-w01-light,helvetica-w02-light,sans-serif"><span style="color:#FFFFFF">${translate(
          'interactions_preset_profile_dev_description',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'hk45n',
        },
        brightness: 1,
        packed: false,
        minHeight: 35,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 30,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqklka82',
        recommendedScale: 1,
        recommendedWidth: 250,
        recommendedHeight: 40,
        orderIndex: 5,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'hk45n',
        },
      },
      scopedTransformations: {
        'variants-kgujmcio': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -65,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'hk45n',
          },
        },
      },
      style: 'txtNew',
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 312,
    height: 433,
    x: 334,
    y: 88,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'hk45n',
  },
  parent: 'hk45n',
  mobileHints: {
    type: 'MobileHints',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'hk45n',
    },
    author: 'studio',
    originalCompId: 'comp-kgqklk97',
    recommendedScale: 1,
    recommendedWidth: 280,
    recommendedHeight: 484,
  },
  variants: {
    'variants-kgujmcio': {
      componentId: 'comp-kgujmci1',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'hk45n',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujmcio': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'hk45n',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'hk45n',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};
