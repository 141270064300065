// @ts-nocheck
import _ from 'lodash';
import $ from 'zepto';
import * as fontUtils from './fontUtils';

function getMostCommonTextSize(html, themeFonts) {
  const fontSizesToCharsCount = {};
  const tempDiv = window.document.createElement('div');

  tempDiv.innerHTML = html;
  countCharsForEachFontSize(tempDiv, fontSizesToCharsCount, themeFonts, '');
  return parseInt(getMaxValueKey(fontSizesToCharsCount), 10);
}

function getFirstTextAlignment(html) {
  return (
    _.isString(html) &&
    _.get(html.match(/text\-align:\s*((left|center|right|justify))/), 1, 'left')
  );
}

function getFirstColor(html) {
  return (
    _.isString(html) &&
    _.get(
      html.match(/\sclass=\"(color_\d+)\"/) ||
        html.match(/[;"\d]color:\s?(#?[A-Z\d()]+;?)/),
      1,
    )
  );
}

function getMaxValueKey(map) {
  let max;
  let maxKey = '';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(map, function (value, key) {
    if (max === undefined || max < value) {
      max = value;
      maxKey = key;
    }
  });

  return maxKey;
}

function countCharsForEachFontSize(
  node,
  fontSizesToCharsCount,
  themeFonts,
  fontSize,
) {
  if (node.nodeName === '#text' || node.nodeName === '#comment') {
    return;
  }
  fontSize = getFontSize(node, themeFonts)
    ? getFontSize(node, themeFonts)
    : fontSize;

  node.childNodes.forEach((childNode) => {
    if (childNode.nodeName === '#text') {
      if (fontSize) {
        const innerTextLength = childNode.textContent.replace(/ /g, '').length;
        if (!fontSizesToCharsCount[fontSize]) {
          fontSizesToCharsCount[fontSize] = 0;
        }
        fontSizesToCharsCount[fontSize] += innerTextLength;
      }
    } else {
      countCharsForEachFontSize(
        childNode,
        fontSizesToCharsCount,
        themeFonts,
        fontSize,
      );
    }
  });
}

function getFontSize(node, themeFonts) {
  let fontSize = $(node).css('fontSize');

  if (!fontSize) {
    Array.prototype.forEach.call(node.classList, (className) => {
      if (themeFonts[className]) {
        fontSize = fontUtils.parseStyleFont(className, themeFonts).size;
      }
    });
  }
  return fontSize;
}

export { getMostCommonTextSize, getFirstTextAlignment, getFirstColor };
