// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import { cx, hoc, imageTransform, panelUtils } from '@/util';
import { translate } from '@/i18n';
import * as platformUtils from '@wix/santa-platform-utils';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import * as compPanelInfra from '@/compPanelInfra';
import * as addPanelInfra from '@/addPanelInfra';
import { Button, Composites, RadioButtons, TextLabel } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';

const {
  connect,
  STORES: { DS_ONLY },
} = hoc;

const COLUMN_WIDTH = 9;
const COLUMN_SPACING_WIDTH = 12;
const MAX_GRID_WIDTH = 240;
const CUSTOMIZE_ACTIONS = {
  CUSTOM: 'Custom',
};

//scale down image size, so it will end on the nearest column in the grid
function getNewImageWidth(imageWidth) {
  if (imageWidth > MAX_GRID_WIDTH) {
    return MAX_GRID_WIDTH;
  }
  const columnAndSpacing = COLUMN_WIDTH + COLUMN_SPACING_WIDTH;
  return (
    Math.floor((imageWidth + COLUMN_SPACING_WIDTH) / columnAndSpacing) *
      columnAndSpacing -
    COLUMN_SPACING_WIDTH
  );
}

//TYPE WAS GENERATED, remove this line when reviewed
interface ChangeVariationsPanelProps {
  title?: string;
  helpId?: string;
  selectedComponent?: AnyFixMe | Array<AnyFixMe>;
  notifyApplication: FunctionFixMe;
  changeVariation: FunctionFixMe;
  presets: Array<{
    mainImageUrl?: string;
    displayName?: string;
    variationId: string;
    presetWidth?: number;
  }>;
  eventName?: string;
  keepOverrides?: boolean;
  applicationId?: number;
  token?: string;
  customizeButton?: AnyFixMe;
}

class ChangeVariationsPanel extends React.Component<ChangeVariationsPanelProps> {
  static displayName = 'changeVariationsPanel';

  static propTypes = {
    title: PropTypes.string,
    helpId: PropTypes.string,
    selectedComponent: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
    notifyApplication: PropTypes.func.isRequired,
    changeVariation: PropTypes.func.isRequired,
    presets: PropTypes.arrayOf(
      PropTypes.shape({
        mainImageUrl: PropTypes.string,
        displayName: PropTypes.string,
        variationId: PropTypes.string.isRequired,
        presetWidth: PropTypes.number,
      }),
    ).isRequired,
    eventName: PropTypes.string,
    keepOverrides: PropTypes.bool,
    applicationId: PropTypes.number,
    token: PropTypes.string,
    customizeButton: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.initialOverrides = props.getOverridesWithPrimaryRole(
      props.selectedComponent?.[0],
    );

    this.state = {
      selectedVariationId: props.getSelectedVariationId(
        props.selectedComponent?.[0],
      ),
      isCustomDesignOpen: false,
    };
  }

  getTitle = () => {
    return this.props.title || 'Platform_CustomDesign_Header_Default';
  };

  shouldTranslateTitle = () => {
    return !this.props.title;
  };

  getHelpId = () => {
    return this.props.helpId || 'e9f90ca5-0616-4676-ab67-e54162f082b3';
  };

  showCustomizeDesignButton = () => {
    const action = this.props.customizeButton?.action;
    if (action === CUSTOMIZE_ACTIONS.CUSTOM) {
      return !!this.props.customizeButton?.config?.url;
    }
    return false;
  };

  getSectionWrapperProps = () => {
    return {
      title: 'Platform_Design_Section_Label_Explore',
      type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
      parentType: addPanelDataConsts.PANEL_TYPES.DESIGN_PANEL,
      showSectionHeader: true,
    };
  };

  isSelectedItem = (preset) => {
    return this.state.selectedVariationId === preset.variationId;
  };

  onCustomizeDesign = () => {
    this.setState({
      isCustomDesignOpen: true,
    });
  };

  onBackClick = () => {
    return this.state.isCustomDesignOpen
      ? () => {
          this.setState({
            isCustomDesignOpen: false,
          });
        }
      : undefined;
  };

  getVariationOptions = () => {
    return this.props.presets?.map((preset) => ({
      value: preset.variationId,
      label: preset.displayName,
      automationId: 'preset-item',
    }));
  };

  shouldShowPresetImages = () => {
    return this.props.presets[0]?.mainImageUrl;
  };

  getPresets = () => {
    return this.props.presets?.reduce((result, preset) => {
      const originalImageData = platformUtils.mediaSrcHandler.parseMediaSrc(
        preset.mainImageUrl,
        'image',
      );
      const originalImageWidth = originalImageData.width;
      const originalImageHeight = originalImageData.height;

      const numberWidthToCheck = [
        preset.presetWidth,
        originalImageWidth,
      ].filter((n) => typeof n === 'number');

      const newImageWidth = getNewImageWidth(Math.min(...numberWidthToCheck));
      result.push({
        variationId: preset.variationId,
        imageWidth: newImageWidth,
        imageHeight: (originalImageHeight * newImageWidth) / originalImageWidth,
        fittingType: imageTransform.fittingTypes.SCALE_TO_FIT,
        imageData: _.merge(originalImageData, {
          uri: originalImageData.mediaId,
          quality: {
            unsharpMask: {
              amount: 0,
              radius: 0,
              threshold: 0,
            },
            quality: 85,
          },
        }),
      });
      return result;
    }, []);
  };

  onPanelClose = () => {
    this.props.removeUnusedOverrides(this.props.selectedComponent?.[0]);
  };

  onPresetClicked = (preset) => {
    if (this.props.eventName) {
      this.props.notifyApplication(
        this.props.applicationId,
        this.props.eventName,
        preset.variationId,
      );
    } else {
      this.props.changeVariation(
        this.props.selectedComponent?.[0],
        preset.variationId,
        this.initialOverrides,
        this.props.keepOverrides,
      );
    }
    this.setState({ selectedVariationId: preset.variationId });
  };

  render() {
    return (
      <compPanelInfra.compPanelFrame
        contentClass="changeVariationsPanelContent"
        backButtonLabel="design_panel_back_button_label"
        onBackClick={this.onBackClick()}
        onClose={this.onPanelClose}
        title={this.getTitle()}
        shouldTranslate={this.shouldTranslateTitle()}
        helpId={this.getHelpId()}
        {...panelUtils.getFrameProps(this.props)}
        className={cx(
          'changeVariationsPanel',
          panelUtils.getFrameProps(this.props)?.className,
        )}
      >
        {!this.state.isCustomDesignOpen ? (
          <>
            {this.shouldShowPresetImages() ? (
              <addPanelInfra.sectionWrapper
                key="selectVariationImages"
                {...this.getSectionWrapperProps()}
                className={cx(
                  'section-wrapper',
                  { withDesignBtn: this.showCustomizeDesignButton() },
                  this.getSectionWrapperProps()?.className,
                )}
              >
                <ul className="presets-wrapper">
                  {this.getPresets()?.map((preset) => (
                    <li
                      data-aid="preset-item"
                      onClick={() => this.onPresetClicked(preset)}
                      key={preset.variationId}
                      className="preset-item"
                    >
                      <baseUI.image
                        key={preset.imageData.uri}
                        draggable={false}
                        imageData={preset.imageData}
                        imageWidth={preset.imageWidth}
                        imageHeight={preset.imageHeight}
                        fittingType={preset.fittingType}
                      />
                      {this.isSelectedItem(preset) ? (
                        <symbols.symbol
                          key="selectedItem"
                          name="selectedItemDesignPanel"
                          className="design-panel-selected-item"
                        />
                      ) : null}
                    </li>
                  ))}
                </ul>
              </addPanelInfra.sectionWrapper>
            ) : null}
            {!this.shouldShowPresetImages() ? (
              <Composites.RadioButtonsLabeled
                automationId="selectVariationRadioButtons"
                key="selectVariationRadioButtons"
              >
                <TextLabel value="AppStudio_Design_Panel_Choose_Variation" />
                <RadioButtons
                  value={this.state.selectedVariationId}
                  onChange={(variationId) =>
                    this.onPresetClicked({ variationId })
                  }
                  shouldTranslate={false}
                  options={this.getVariationOptions()}
                />
              </Composites.RadioButtonsLabeled>
            ) : null}
            {this.showCustomizeDesignButton() ? (
              <Composites.ButtonLargeFixedBottom
                key="customizeDesignBtn"
                className="customize-design-btn"
              >
                <Button
                  onClick={this.onCustomizeDesign}
                  automationId="customizeDesignBtn"
                >
                  <symbols.symbol
                    name="customize_design_drop_light"
                    className="customize-button-symbol"
                  />
                  <span>{translate('design_customize_button')}</span>
                </Button>
              </Composites.ButtonLargeFixedBottom>
            ) : null}
          </>
        ) : null}

        {this.state.isCustomDesignOpen && this.showCustomizeDesignButton() ? (
          <PlatformPanelApplicationFrame
            key="customizeDesignState"
            url={this.props?.customizeButton?.config?.url}
            token={this.props.token}
            initialData={this.props?.customizeButton?.config?.initialData}
            panelClass="platformComponentPanel"
            height="472px"
            width="100%"
          />
        ) : null}
      </compPanelInfra.compPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeVariation: (compRef, variationId, customOverrides, keepOverrides) =>
    dispatch(
      stateManagement.platform.actions.changeVariation(
        compRef,
        variationId,
        customOverrides,
        keepOverrides,
      ),
    ),
  notifyApplication: (applicationId, eventName, variationId) =>
    dispatch(
      stateManagement.platform.actions.notifyApplication(
        applicationId,
        eventName,
        { variationId },
      ),
    ),
  removeUnusedOverrides: (refCompPtr) =>
    dispatch(
      stateManagement.platform.actions.removeUnusedOverrides(refCompPtr),
    ),
});

const isInternalRef = (refCompPtr, dsRead) =>
  dsRead.components.data.get(refCompPtr)?.type === 'InternalRef';

const mapStateToProps = ({ dsRead }) => ({
  getOverridesWithPrimaryRole: (refCompPtr) =>
    dsRead.appStudioWidgets.getOverridesWithPrimaryRole(refCompPtr),
  getSelectedVariationId: (refCompPtr) =>
    isInternalRef(refCompPtr, dsRead)
      ? dsRead.components.data.get(refCompPtr)?.pageId
      : dsRead.components.data.get(refCompPtr)?.variationId,
});

export default connect(
  DS_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(ChangeVariationsPanel);
