import _ from 'lodash';
import * as coreBi from '@/coreBi';
import { AddPresetApiKey } from '@/addPreset';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { fedopsLogger } from '@/util';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { openAccessibilityWizard } from '@/platform/api/editor/accessibility/accessibility';
import * as tpa from '@/tpa';

import { dispatchEditorAPI } from '../../../topBarComponent/topBarMapper';

import type { EditorAPI } from '@/editorAPI';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  SettingsMenuAPIOwnProps,
  SettingsMenuAPIStateProps,
  SettingsMenuAPIDispatchProps,
} from './settingsMenuAPI';

const { exitInteractionModeIfNeeded } = stateManagement.interactions.actions;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapStateToProps: MapStateToProps<
  SettingsMenuAPIStateProps,
  SettingsMenuAPIOwnProps
> = ({ editorAPI }) => {
  const isFirstSave =
    _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
  const isDraftMode =
    _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;
  const isMobileOptimizedSite =
    _.invoke(editorAPI, 'dsRead.mobile.isOptimized') || false;
  const isSitePasswordEnabled = _.invoke(
    editorAPI,
    'dsRead.pages.permissions.hasPassword',
    _.invoke(editorAPI, 'dsRead.pages.getMasterPageId'),
  );
  const currentEditorState = editorAPI.store.getState();
  const isFirstManualSave = !getSiteUserPreferences(
    constants.USER_PREFS.SAVE.USER_MADE_MANUAL_SAVE,
  )(currentEditorState);

  return {
    isFirstSave,
    isDraftMode,
    isFirstManualSave,
    isMobileOptimizedSite,
    isSitePasswordEnabled,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  SettingsMenuAPIDispatchProps,
  SettingsMenuAPIOwnProps
> = (dispatch) => ({
  save: (origin, sourceOfStart) => {
    const editorAPI = dispatch(getEditorAPI);
    editorAPI.saveManager.save({ origin, sourceOfStart });
  },
  getInvalidLoggedUser: async (): Promise<string | null> => {
    const editorAPI = dispatch(getEditorAPI);
    return editorAPI.account.getUserInvalidLoggedStatus();
  },
  handleInvalidLoggedUser: (invalidStatus: string): void => {
    const editorAPI = dispatch(getEditorAPI);
    return editorAPI.account.handleInvalidLoggedUser(invalidStatus);
  },
  sendBiForLoginRelatedError: (): void => {
    const editorAPI = dispatch(getEditorAPI);
    editorAPI.bi.event(coreBi.events.panels.login_related_error_message_show);
  },
  openSettings: dispatchEditorAPI(dispatch, (editorAPI, tab, referrer) =>
    editorAPI.account.openSettings({ tab, referrer }),
  ),
  openPremiumSettings: dispatchEditorAPI(dispatch, (editorAPI, tab) =>
    editorAPI.account.openPremiumSettings(tab),
  ),
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  upgrade: dispatchEditorAPI(dispatch, (editorAPI, referralAdditionalInfo) =>
    editorAPI.account.upgrade(referralAdditionalInfo),
  ),
  openWixSEOWiz: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openWixSEOWiz('EDITOR_MENU'),
  ),
  openAddPage404ErrorPage: dispatchEditorAPI(dispatch, (editorAPI) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_OPEN,
    );

    const addPresetAPI = editorAPI.host.getAPI(AddPresetApiKey);
    addPresetAPI.pages.setCategoryByUniquePagesUri('error404');
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'addPreset.panels.addPagePanel',
        {
          origin: 'settingsMenu',
          biCategory: 'CUSTOM_MAIN_MENU',
        },
      ),
    );
  }),
  exitInteractionModeIfNeeded: dispatchEditorAPI(dispatch, (editorAPI) => {
    exitInteractionModeIfNeeded(editorAPI);
  }),
  manage: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.manage(),
  ),
  openAccessibilityWizard: dispatchEditorAPI(dispatch, openAccessibilityWizard),
  notifyMultilingualAppToOpen: () =>
    dispatch(
      stateManagement.multilingual.actions.notifyMultilingualAppToOpen({
        biOrigin: tpa.constants.BI.initiator.TOP_BAR,
      }),
    ),
});

export { mapStateToProps, mapDispatchToProps };
