{
  "CLICK_ON_ELEMENT_IN_TREE": {
    "evid": 930,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "CLICK_ON_TAB_IN_PANEL": {
    "evid": 931,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string",
      "action": "string"
    }
  },
  "STATE_CLICK": {
    "evid": 932,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "ADD_LAYER_CLICK": {
    "evid": 933,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "FILL_PICKER_TAB_CLICK": {
    "evid": 935,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "ADD_GRADIENT_CLICK": {
    "evid": 936,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "STYLABLE_PANEL_CLOSE": {
    "evid": 938,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "STYLABLE_SETTINGS_CHANGED": {
    "evid": 1119,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "MOVE_FOCAL_POINT_CLICK": {
    "evid": 940,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "FILL_TYPE_CLICK": {
    "evid": 941,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "COMPONENT_PART_TOGGLE": {
    "evid": 937,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "TEXT_DROPDOWN_SELECT": {
    "evid": 939,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "PALETTE_SELECT": {
    "evid": 405,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "COLOR_PICKER_CHANGE_COLORS_CLICK": {
    "evid": 131,
    "src": 38,
    "fields": {
      "part": "number",
      "state": "string"
    }
  },
  "LAYER_ACTION_CLICK": {
    "evid": 1514,
    "src": 38,
    "fields": {
      "component_id": "string",
      "component_type": "string",
      "action": "string",
      "state": "string"
    }
  },
  "COMPONENT_EDITED": {
    "evid": 1399,
    "src": 38,
    "fields": {
      "component_id": "string",
      "component_type": "string",
      "control_type": "string",
      "control_id": "string",
      "new_value": "string",
      "prev_value": "string",
      "panel_type": "string",
      "stage_type": "string"
    }
  }
}
