// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Bubble } from '@wix/wix-base-ui';
import DefaultUpgradePanel from './defaultUpgradePanel';

function template() {
  return (
    <Bubble
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      arrowAlignment="top"
      contentStyle={this.bubbleContentStyle}
      arrowStyle={this.bubbleArrowStyle}
      maxWidth={600}
      className="upgrade-drop-panel-top-bar"
    >
      <DefaultUpgradePanel
        isFirstSave={this.props.isFirstSave}
        isDraftMode={this.props.isDraftMode}
        isActivePersonalSaleCampaign={this.props.isActivePersonalSaleCampaign}
        getPersonalSaleTranslatedLabel={
          this.props.getPersonalSaleTranslatedLabel
        }
        getPersonalSaleCampaignSymbolName={
          this.props.getPersonalSaleCampaignSymbolName
        }
        save={this.props.save}
        sendBi={this.props.sendBi}
        accountUpgrade={this.props.accountUpgrade}
        closePanel={this.props.closePanel}
        discountValue={this.props.discountValue}
      />
    </Bubble>
  );
}

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingBottom: '24px',
  paddingTop: '30px',
  minWidth: '540px',
  top: '60px',
};

class UpgradeDropPanel extends React.Component {
  constructor(props) {
    super(props);

    this.bubbleContentStyle = BUBBLE_CONTENT_STYLE;
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
  }

  render() {
    return template.call(this);
  }
}

UpgradeDropPanel.displayName = 'UpgradeDropPanel';

UpgradeDropPanel.propTypes = {
  accountUpgrade: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  sendBi: PropTypes.func.isRequired,
  isFirstSave: PropTypes.bool.isRequired,
  isDraftMode: PropTypes.bool.isRequired,
  getPersonalSaleTranslatedLabel: PropTypes.func.isRequired,
  getPersonalSaleCampaignSymbolName: PropTypes.func.isRequired,
  isActivePersonalSaleCampaign: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default UpgradeDropPanel;
