//@ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { Tab, VerticalTabs } from '@wix/wix-base-ui';
import * as core from '@/core';
import * as baseUI from '@/baseUI';
import * as coreBi from '@/coreBi';
import { PanelHeader } from '@wix/wix-base-ui';
import constants from '@/constants';
import { cx, blockOuterScrollMixin, workspace } from '@/util';
import { translate } from '@/i18n';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as superAppBi from '../bi/superAppBi';
import superAppTooltipUpgrade from './superAppTooltipUpgrade';
import superAppManagePanel from './menuPanels/superAppManagePanel';
import superAppPagesPanel from './menuPanels/superAppPagesPanel';
import superAppAddElementsPanel from './menuPanels/superAppAddElementsPanel';
import superAppLearnMorePanel from './menuPanels/superAppLearnMorePanel';

const MAX_TABS_WIDTH = 200;

function template() {
  const selectedTab = this.getSelectedTab();
  const CustomHeader = this.props.superApp.customHeader;
  // Allows us to have helpId in the root where the panelFrame is rendered || new workspace
  this.props.setHelpId?.(selectedTab.helpId);

  const getTabLabel = ({ title, tabWrapper }) => {
    if (tabWrapper) {
      const TabWrapper = tabWrapper;
      return <TabWrapper>{translate(title)}</TabWrapper>;
    }
    return translate(title);
  };

  const renderTabs = (tabs) => {
    // TODO: refactor vertical tabs in new workspace
    return this.isNewWorkspace ? (
      <VerticalTabs
        value={selectedTab?.title}
        onChange={(value) =>
          this.selectTab(tabs.find((tab) => tab.title === value))
        }
        maxWidth={this.props.tabsWidth ? this.props.tabsWidth : MAX_TABS_WIDTH}
      >
        {tabs.map(
          ({ title, className, tabWrapper, showDotIndicator }, index) => (
            <Tab
              key={`tab${index}`}
              value={title}
              showDotIndicator={showDotIndicator}
              label={getTabLabel({ title, tabWrapper })}
              className={className}
              dataHook={'tab-name'}
              shouldTranslate={false}
            />
          ),
        )}
      </VerticalTabs>
    ) : (
      <ul>
        {tabs.map((tab) => (
          <li
            onClick={() => {
              this.selectTab(tab);
            }}
            key={tab.title}
            style={{
              height: '100px',
            }}
            className="tab"
          >
            <span
              className={cx('tabNameWrapper', {
                [tab.className]: !!tab.className,
                isSelected: selectedTab.title === tab.title,
              })}
            >
              <span className="tabName" data-hook="tab-name">
                {getTabLabel({ title: tab.title, tabWrapper: tab.tabWrapper })}
              </span>
              {tab.showDotIndicator && <span className="dotIndicator" />}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const { tabs, bottomTabs } = this.getTabs();

  const getTabsStyle = () =>
    this.props.tabsWidth
      ? {
          width: `${this.props.tabsWidth}px`,
        }
      : null;

  const getContentStyle = () =>
    this.props.contentWidth
      ? {
          width: `${this.props.contentWidth}px`,
        }
      : null;

  return (
    <div
      onWheel={this.blockOuterScroll}
      className={`super-app-menu-panel left-panel-frame-content ${
        selectedTab.isDynamicHeight ? 'dynamic-height' : ''
      }`}
    >
      {!this.props.superApp.hideTabs && (
        <div className="tabs" style={getTabsStyle()}>
          {CustomHeader && (
            <div className="custom-header">
              <CustomHeader />
            </div>
          )}
          {renderTabs(tabs)}
          {this.shouldShowSeparator() && (
            <div key="separator" className="separator" />
          )}
          {this.shouldShowBottomTabs() && renderTabs(bottomTabs)}
          {this.showExternalTooltip() && (
            <div key="tooltip-external">{this.getExternalTab()}</div>
          )}
          {this.showPremiumLink() && (
            <div key="premium">
              <div className="premium">
                <baseUI.tooltip
                  value={this.getTooltipValue()}
                  alignment={constants.UI.TOOLTIP.ALIGNMENT.BOTTOM}
                  styleType={constants.UI.TOOLTIP.STYLE_TYPE.NORMAL}
                  interactive={true}
                  width="432px"
                  id="superAppUpgradePopoverId"
                >
                  <p onClick={this.onUpgradeLinkClicked}>
                    {translate(this.props.superApp.upgradeLinkText)}
                  </p>
                </baseUI.tooltip>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={cx({
          content: true,
          'inner-navigation-open': this.state.isInnerNavigationOpened,
        })}
        style={getContentStyle()}
      >
        {!this.isNewWorkspace && (
          <PanelHeader
            noHelpBtn={!selectedTab.helpId}
            onHelp={this.onHelpButtonClick}
            onClose={this.props.onClose}
            className="dark"
          >
            {translate(selectedTab.title)}
          </PanelHeader>
        )}
        <div
          className={`selected-content-wrapper ${
            selectedTab.isDynamicHeight ? 'dynamic-height' : ''
          }`}
        >
          <selectedTab.content
            superApp={this.props.superApp}
            onClickItemOverride={this.props.onClickItemOverride}
            selectTabByName={this.selectTabByName}
            onChangeStateOnInnerNavigationOpen={
              this.onChangeStateOnInnerNavigationOpen
            }
          />
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'SuperAppMenuPanel',
  mixins: [core.mixins.editorAPIMixin, blockOuterScrollMixin],
  propTypes: {
    superApp: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedTabName: PropTypes.string,
  },
  getInitialState() {
    const { tabs } = this.getTabs();
    const selectedTab =
      tabs.find((tab) => tab.name === this.props.selectedTabName) || tabs[0];
    this.isNewWorkspace = workspace.isNewWorkspaceEnabled();
    return {
      selectedTab,
    };
  },
  componentDidUpdate(prevProps) {
    if (prevProps.selectedTabName !== this.props.selectedTabName) {
      this.selectTabByName(this.props.selectedTabName);
    }
  },
  getTabs() {
    const superAppProps = this.props.superApp;

    const defaultTabs = [
      {
        name: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.MANAGE,
        isDynamicHeight: false,
        title: superAppProps.panel.manage.title,
        helpId: superAppProps.panel.manage.helpId,
        isHidden: superAppProps.panel.manage.isHidden,
        content: superAppManagePanel,
        isBottomTab: superAppProps.panel.manage.isBottomTab,
      },
      {
        name: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.PAGES,
        isDynamicHeight: true,
        title: superAppProps.panel.pages.title,
        helpId: superAppProps.panel.pages.helpId,
        isHidden: superAppProps.panel.pages.isHidden,
        content: superAppPagesPanel,
        isBottomTab: superAppProps.panel.pages.isBottomTab,
      },
      {
        name: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.ADD,
        isDynamicHeight: true,
        title: superAppProps.panel.addElements.title,
        helpId: superAppProps.panel.addElements.helpId,
        isHidden: superAppProps.panel.addElements.isHidden,
        content: superAppAddElementsPanel,
        isBottomTab: superAppProps.panel.addElements.isBottomTab,
      },
      {
        name: constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.LEARN_MORE,
        isDynamicHeight: false,
        title: superAppProps.panel.learnMore.title,
        isHidden: superAppProps.panel.learnMore.isHidden,
        content: superAppLearnMorePanel,
        isBottomTab: superAppProps.panel.learnMore.isBottomTab,
      },
    ];

    const upperTabs = defaultTabs.filter(
      (tab) => !tab.isHidden && !tab.isBottomTab,
    );

    const formatTab = (tab) => ({
      name: tab.name || constants.SUPER_APPS.MENU_PANEL_TAB_NAMES.MANAGE,
      isDynamicHeight: tab.isDynamicHeight,
      title: tab.title,
      helpId: tab.helpId,
      content: tab.template,
      className: tab.isPremiumSkin ? 'upgrade' : '',
      showDotIndicator: tab.showDotIndicator,
      tabWrapper: tab.tabWrapper,
    });

    if (superAppProps.extraTabs) {
      superAppProps.extraTabs.forEach(function (extraTab) {
        upperTabs.splice(extraTab.index, 0, formatTab(extraTab));
      });
    }

    const bottomTabs = defaultTabs.filter(
      (tab) => tab.isBottomTab && !tab.isHidden,
    );

    if (superAppProps.bottomTabs) {
      superAppProps.bottomTabs.forEach(function (bottomTab) {
        bottomTabs.splice(bottomTab.index, 0, formatTab(bottomTab));
      });
    }

    return { tabs: upperTabs, bottomTabs };
  },
  shouldShowBottomTabs() {
    const superAppProps = this.props.superApp;
    const defaultBottomTabsExists = Object.values(superAppProps.panel).some(
      (tab) => tab?.isBottomTab,
    );
    return superAppProps.bottomTabs || defaultBottomTabsExists;
  },
  showPremiumLink() {
    const superAppProps = this.props.superApp;
    return (
      superAppProps.hasPremiumPlan &&
      !superAppProps.isPremium(this.getEditorAPI())
    );
  },
  showExternalTooltip() {
    return this.props.superApp.tooltip && this.props.superApp.tooltip.external;
  },
  shouldShowSeparator() {
    return (
      this.showExternalTooltip() ||
      this.showPremiumLink() ||
      this.shouldShowBottomTabs()
    );
  },
  selectTab(tab) {
    const superAppProps = this.props.superApp;

    const params = {
      panel: tab.name,
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(superAppBi.events.PANEL_SELECT, params);
    this.setState({
      selectedTab: tab,
      isInnerNavigationOpened: false,
    });
  },
  selectTabByName(tabName) {
    const { tabs } = this.getTabs();
    const tabToSelect = tabs.find(({ name }) => name === tabName);
    if (tabToSelect) {
      this.selectTab(tabToSelect);
    }
  },
  getSelectedTab() {
    return this.state.selectedTab;
  },
  openAppPremiumPackagePicker() {
    const superAppProps = this.props.superApp;
    const params = {
      origin: 'TooltipUpgradeLink',
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(superAppBi.events.UPGRADE_CLICK, params);
    superAppProps.openAppPremiumPackagePicker(this.getEditorAPI());
    baseUI.tooltipManager.hide('superAppUpgradePopoverId', true);
  },
  openMigrationPopup() {
    this.props.superApp.openSwitchSitesPopup(this.getEditorAPI());
    this.getEditorAPI().bi.event(
      coreBi.events.wixstores.WIXSTORES_MIGRATION_UPGRADE_CLICKED,
      { origin: 'upgrade_popup' },
    );
    baseUI.tooltipManager.hide('superAppUpgradePopoverId', true);
  },
  getStyle() {
    return {
      width: addPanelDataConsts.CATEGORY_VIEW.TOTAL_WIDTH,
    };
  },
  onHelpButtonClick() {
    const editorAPI = this.getEditorAPI();
    const superAppProps = this.props.superApp;
    const params = {
      panel: this.state.selectedTab.name,
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    editorAPI.bi.event(superAppBi.events.HELP_ICON_CLICK, params);
    const biHelpParams = {
      panel_name: this.state.selectedTab.name,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
      component: superAppProps.appName,
      learn_more: false,
    };
    editorAPI.panelManager.openHelpCenter(
      this.getSelectedTab().helpId,
      null,
      biHelpParams,
    );
  },
  onUpgradeLinkClicked() {
    this.getUpgradeDefinitions().upgrade();
  },
  getUpgradeDefinitions() {
    return {
      title: this.props.superApp.tooltip.upgrade.title,
      text: this.props.superApp.tooltip.upgrade.text,
      label: this.props.superApp.tooltip.upgrade.label,
      image: this.props.superApp.tooltip.upgrade.image,
      upgrade: this.openAppPremiumPackagePicker,
    };
  },
  getTooltipValue() {
    return React.createElement(
      superAppTooltipUpgrade,
      this.getUpgradeDefinitions(),
    );
  },
  getExternalTab() {
    return React.createElement(
      this.props.superApp.tooltip.external.template,
      this.props.superApp.tooltip.external,
    );
  },
  onChangeStateOnInnerNavigationOpen(newState) {
    this.setState({ isInnerNavigationOpened: newState });
  },
  render: template,
});
