import type {
  MapDispatchToProps,
  Dispatch,
  DispatchMapperArgs,
} from 'types/redux';
import type { EditorState } from '@/stateManagement';
import { bi } from '@/stateManagement';
import { upgradeClicked, premiumPackagePickerLoad } from '../../bi';
import {
  selectPremiumLimits,
  selectHasPremium,
  openPremiumUpgradeFlow,
} from '../../premium';
import {
  selectTotalCollectionItemCount,
  selectLiveCollectionItemCounts,
} from '../../collections';

export interface UpgradeOwnProps {}

export interface UpgradeDispatchProps {
  openPremiumPackagePicker: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  UpgradeDispatchProps,
  UpgradeOwnProps
> = (dispatch: Dispatch) => {
  const openPremiumPackagePicker = dispatch(
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      { editorAPI }: DispatchMapperArgs,
    ) =>
      () => {
        const state = getState();

        const { collectionCountLimit, itemCountLimit } =
          selectPremiumLimits(state);

        dispatch(
          bi.actions.event(upgradeClicked, {
            environment: 'EDITOR',
            origin: 'editor_6th_button',
            num_of_collections: selectLiveCollectionItemCounts(state).length,
            collections_limit: collectionCountLimit,
            num_of_items: selectTotalCollectionItemCount(state),
            items_limit: itemCountLimit,
            isPremium: selectHasPremium(state),
          }),
        );

        const BI_REFERRAL_INFO = 'editor_6btn_panel_upgrade_tab';

        dispatch(
          bi.actions.event(premiumPackagePickerLoad, {
            referralAdditionalInfo: BI_REFERRAL_INFO,
          }),
        );

        openPremiumUpgradeFlow(editorAPI, BI_REFERRAL_INFO);
      },
  );
  return {
    openPremiumPackagePicker,
  };
};

export const mapStateToProps: null = null;
