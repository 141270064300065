/*eslint max-lines: [2, { "max": 1574, "skipComments": true, "skipBlankLines": true}]*/
import _ from 'lodash';
import { DesignerPanelsApiKey } from '@/apis';
import { utils } from '@/wixApps';
import experiment from 'experiment';
import { DATA_BINDING } from '@wix/app-definition-ids';
import constants from '@/constants';
import {
  fedopsLogger,
  isAdvancedMenuOpen,
  isCustomMenusEnabled,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
  link,
  multilingual,
  sections,
} from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import * as platform from '@/platform';
import { EVENTS, isDataModeOn, notifyDataBindingApplication } from '@/wixData';
import * as stateManagement from '@/stateManagement';
import {
  api as menuAPI,
  constants as menuConstants,
  type IPageMenuItem,
} from '@/menu';
import { utils as gfppUtils } from '@/gfppData';
import type { EditorAPI } from '@/editorAPI';
import * as actions from '../../../api/actions/actions';
import { getMenuItemBiType } from '../../../utils';
const { openLeftPanel } = stateManagement.panels.actions;
import type { MenuData } from 'types/documentServices';
import type {
  OnItemAddedHandler,
  OnRenameHandler,
  OnSettingsChangeHandler,
} from '../../types';
import type {
  MenuItemAction,
  IPagesPanelMenu,
  MenuItemListAction,
} from '../../../api/menusPages';
import { DefaultActionTypes } from '../../types/handlers';
import {
  getActionSymbolByName,
  getActionSymbolByType,
} from '../../../api/actions/symbols';

const {
  linkTypeValidators: { isDynamicPageLink, isSectionLink },
} = link;

const { createMenuApi } = menuAPI;

const PAGE_BACKGROUND = 'page_background';

interface CommonParams {
  editorAPI: EditorAPI;
  menuItem: IPageMenuItem;
  isDesktopViewMode: boolean;
  isTranslating: boolean;
  onShowSettingsCallback: OnSettingsChangeHandler;
  onItemAdded: OnItemAddedHandler;
  onRename: OnRenameHandler;
  renderedMenuItems: IPageMenuItem[];
  multiPageMenu: boolean;
  biCategory: string;
}

type AddDesktopActionFN = (
  params: CommonParams & {
    menuData: MenuData;
    actions: MenuItemAction[];
  },
) => void;

type GetActionsList = (
  params: CommonParams & {
    menuData: MenuData;
    defaultActions?: MenuItemAction[];
  },
) => MenuItemAction[];

function sanitizeHash(str?: string) {
  if (typeof str === 'string') return str.replace('#', '');

  return str;
}

const getMenuItemPageId = (menuItem: IPageMenuItem) => {
  // @ts-expect-error
  return menuItem?.pageData?.id || sanitizeHash(menuItem?.link?.pageId);
};

const CONTEXT_MENU_ORIGIN = 'pages_panel_page_context_menu';

const reportActionBi = (
  editorAPI: EditorAPI,
  fields: {
    pageId: string;
    category: string;
    source: string;
    type: string;
    isOpenedPage?: boolean;
  },
) => {
  editorAPI.bi.event(coreBi.events.pages.pagesPanel.quick_actions_menu_select, {
    page_id: fields.pageId,
    source: fields.source,
    category: fields.category,
    type: fields.type,
    is_opened_page: fields.isOpenedPage,
  });
};

const openSetDynamicPageDialog = (
  editorAPI: EditorAPI,
  pageMenuItem: IPageMenuItem,
) => {
  const pageId = pageMenuItem.pageData.id;
  const { applicationId } =
    editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);
  editorAPI.dsActions.platform.notifyApplication(applicationId, {
    eventType: EVENTS.addDynamicPageClicked,
    eventPayload: {
      pageRef: editorAPI.dsRead.pages.getReference(pageId),
      origin: 'quickActionsMenu',
    },
  });
};

function canBeSetAsDynamicPage(editorAPI: EditorAPI, menuItem: IPageMenuItem) {
  const pageId = menuItem.pageData?.id;
  if (!pageId || isCustomErrorPage(editorAPI, menuItem)) {
    return false;
  }

  return editorAPI.dsRead.routers.pages.isConnectablePage(pageId);
}

function renameMenuItem(
  editorAPI: EditorAPI,
  menuId: string,
  menuItem: IPageMenuItem,
  newLabel?: string,
) {
  if (!newLabel || menuItem.label === newLabel) {
    return;
  }

  editorAPI.menu.updateItem(menuId, menuItem.id, { label: newLabel });
  editorAPI.history.debouncedAdd('Rename Menu Item');
}

function canSubpage(menuItem: IPageMenuItem) {
  const isFirstPage = menuItem.position === 0;
  const isAdvancedMenuSubmenu = isAdvancedMenuOpen() && !menuItem.link;

  return !menuItem.parent && !isFirstPage && !isAdvancedMenuSubmenu;
}

function moveChildrenTo(
  editorAPI: EditorAPI,
  menuData: MenuData,
  menuItem: IPageMenuItem,
  parentId: string,
  position: number,
) {
  _.forEachRight(menuItem.items, function (menuItemSubItem) {
    editorAPI.menus.moveMenuItem(
      menuData,
      menuItemSubItem.id,
      parentId,
      position,
      false,
    );
  });
}

function subpage({
  editorAPI,
  menuData,
  menuItem,
  futureParent,
  renderedMenuItems,
  biCategory,
}: {
  editorAPI: EditorAPI;
  menuData: MenuData;
  menuItem: IPageMenuItem;
  futureParent: any;
  renderedMenuItems: IPageMenuItem[];
  biCategory: string;
}) {
  if (!futureParent) {
    const indexInRendered = renderedMenuItems.findIndex(
      (item) => item.id === menuItem.id,
    );
    futureParent = renderedMenuItems[indexInRendered - 1];
  }
  const position = futureParent.items.length;

  moveChildrenTo(editorAPI, menuData, menuItem, futureParent.id, position);
  editorAPI.menus.moveMenuItem(
    menuData,
    menuItem.id,
    futureParent.id,
    position,
    false,
  );
  editorAPI.bi.event(coreBi.events.topbar.pages.pages_menu_sub_page_created, {
    origin: 'quick actions',
    source: biCategory,
    type: menuItem.items.length ? 'Subsubpage' : 'Subpage',
  });
  editorAPI.history.add('subpaging a page');
}

const canMoveUp = (menuItem: IPageMenuItem) => _.isObject(menuItem.parent);

const moveUp = (
  editorAPI: EditorAPI,
  menuData: MenuData,
  menuItem: IPageMenuItem,
  biCategory: string,
) => {
  const grandParentId = menuItem.parent.parent?.id;
  const position = menuItem.parent.position + 1;

  editorAPI.menus.moveMenuItem(
    menuData,
    menuItem.id,
    grandParentId,
    position,
    false,
  );
  editorAPI.bi.event(
    coreBi.events.topbar.pages.top_bar_PAGES_menu_user_reverted_to_main_page,
    { origin: 'quick actions', source: biCategory },
  );

  editorAPI.history.add('moving a page out of its parent');
};

const isCustomErrorPage = (editorAPI: EditorAPI, menuItem: IPageMenuItem) => {
  const pageId = menuItem?.pageData?.id;
  return pageId && editorAPI.pages.isCustomErrorPage(pageId);
};

/**
 * Sets page hidden on desktop and mobile.
 * Non-boolean flags are ignored and replaced with current values.
 * @param {core.EditorAPI} editorAPI
 * @param {menuId: string,} menuId - id of a menu
 * @param {MenuItemsRepositoryItem} menuItem - data returned from menuItemsRepository
 * @param {boolean} isHiddenDesktop - set hidden on desktop,
 * @param {boolean} isHiddenMobile - The author of the book.
 */
const setHidden = (
  editorAPI: EditorAPI,
  menuId: string,
  menuItem: IPageMenuItem,
  isHiddenDesktop: boolean,
  isHiddenMobile?: boolean,
) => {
  const updatedFields: any = {};
  if (typeof isHiddenDesktop === 'boolean') {
    updatedFields.hidePage = isHiddenDesktop;
  } else {
    isHiddenDesktop = !menuItem.isVisible;
  }
  if (typeof isHiddenMobile === 'boolean') {
    updatedFields.mobileHidePage = isHiddenMobile;
  } else {
    isHiddenMobile = !menuItem.isVisibleMobile;
  }

  if (menuItem.pageData) {
    editorAPI.pages.data.update(menuItem.pageData.id, updatedFields);
  } else {
    editorAPI.dsActions.menu.updateItem(menuId, menuItem.id, {
      isVisible: !isHiddenDesktop,
      isVisibleMobile: !isHiddenMobile,
    });
  }

  editorAPI.history.add('hiding a page');
};

const addPageBGActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  biCategory,
  isDesktopViewMode,
  isTranslating,
}) => {
  if (sections.isSectionsEnabled() && menuItem.type.isPage) {
    const title = translate('Pages_Actions_Page_Background');
    const isMobile = !isDesktopViewMode;

    const action = {
      title,
      ...getActionSymbolByName('background_action'),
      onClick: () => {
        const openPageBackgroundPanel = () => {
          if (isTranslating || isMobile) {
            editorAPI.panelManager.openPanel(
              constants.ROOT_COMPS.LEFTBAR.BACKGROUND_PANEL_NAME,
              {
                origin: CONTEXT_MENU_ORIGIN,
              },
            );
            return;
          }

          editorAPI.store.dispatch(
            openLeftPanel(constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME, {
              selectedView: constants.DESIGN_PANEL.VIEWS.BACKGROUND,
              origin: CONTEXT_MENU_ORIGIN,
            }),
          );
        };

        openPageBackgroundPanel();

        const pageId = menuItem?.pageData?.id;
        const currPageId = editorAPI.dsRead.pages.getFocusedPageId();

        reportActionBi(editorAPI, {
          pageId,
          category: PAGE_BACKGROUND,
          source: biCategory,
          type: getMenuItemBiType(menuItem),
          isOpenedPage: pageId == currPageId,
        });
      },
      isTools: true,
    };
    actions.push(action);
  }
};

const addPageDesignActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuItem,
  actions,
  biCategory,
  isDesktopViewMode,
}) => {
  if (!isDesktopViewMode) return;

  if (sections.isSectionsEnabled() && menuItem.type.isPage) {
    const shouldHidePageDesignAction =
      gfppUtils.shouldHidePageDesignButton(editorAPI);

    if (shouldHidePageDesignAction) return;

    const pageRef = editorAPI.dsRead.pages.getReference(menuItem.pageData.id);

    const action = {
      title: translate('Pages_Actions_Page_Design'),
      symbolName: 'pageDesign',
      onClick: async () => {
        await editorAPI.pages.navigateToAsync(pageRef.id);
        editorAPI.selection.selectComponentByCompRef(pageRef);
        editorAPI.openComponentPanel(constants.componentPanels.design);

        reportActionBi(editorAPI, {
          pageId: pageRef.id,
          category: 'pageDesign',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
      isTools: true,
    };
    actions.push(action);
  }
};

const addConnectToDataIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuItem,
  actions,
  biCategory,
  isDesktopViewMode,
}) => {
  if (!isDesktopViewMode || !isDataModeOn(editorAPI)) return;

  const isDataBindingAppInstalled =
    editorAPI.platform.isAppActive(DATA_BINDING);

  if (
    sections.isSectionsEnabled() &&
    isDataBindingAppInstalled &&
    menuItem.type.isPage
  ) {
    const pageRef = editorAPI.dsRead.pages.getReference(menuItem.pageData.id);
    const isConnected =
      stateManagement.platform.selectors.isConnectedToDataBindingController(
        pageRef,
        editorAPI.dsRead,
      );

    const action = {
      title: translate(
        isConnected
          ? 'Pages_Actions_Page_Connected'
          : 'Pages_Actions_Page_Connect',
      ),
      ...getActionSymbolByName('connectToBackgroundData_action'),
      onClick: () => {
        editorAPI.selection.selectComponentByCompRef(pageRef);
        platform.connection.notifyConnectClicked(editorAPI, [pageRef]);

        const pageId = pageRef?.id;

        reportActionBi(editorAPI, {
          pageId,
          category: 'connectToData',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
      isTools: !shouldUseNewBindingExperience(),
      isCmsAction: shouldUseNewBindingExperience(),
    };
    actions.push(action);
  }
};

const shouldUseNewBindingExperience = () =>
  experiment.isOpen('specs.wixDataClient.ClassicInspectorPanel');

const addDataConnectionsListIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuItem,
  actions,
  biCategory,
  isDesktopViewMode,
}) => {
  if (!shouldUseNewBindingExperience()) return;
  if (!isDesktopViewMode || !isDataModeOn(editorAPI)) return;

  const isDataBindingAppInstalled =
    editorAPI.platform.isAppActive(DATA_BINDING);

  if (
    sections.isSectionsEnabled() &&
    isDataBindingAppInstalled &&
    menuItem.type.isPage
  ) {
    const pageRef = editorAPI.dsRead.pages.getReference(menuItem.pageData.id);

    const action = {
      title: translate('Pages_Actions_Page_Connections'),
      ...getActionSymbolByName('connectToData_action'),
      onClick: () => {
        notifyDataBindingApplication(editorAPI, {
          eventType: 'openConnectionsListClicked',
          eventPayload: {
            pageRef,
          },
        });

        const pageId = pageRef?.id;

        reportActionBi(editorAPI, {
          pageId,
          category: 'openConnectionList',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
      isCmsAction: true,
    };

    actions.push(action);
  }
};

const getTitleForRenameAction = (
  menuItem: IPageMenuItem,
  isTranslating: boolean,
) => {
  if (isCustomMenusEnabled() && menuItem.type.isDropdown) {
    return translate('PagesPanel_Actions_Folder_Options_Rename');
  }

  if (sections.isSectionsEnabled() && isSectionLink(menuItem.link)) {
    return translate('Pages_Actions_Page_Rename_Section');
  }

  if (isTranslating) {
    return translate('Pages_Actions_Page_Edit_Name');
  }

  return translate('Pages_Actions_Page_Rename');
};

const addRenameActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  onRename,
  biCategory,
  isTranslating,
}) => {
  const title = getTitleForRenameAction(menuItem, isTranslating);

  actions.push({
    title,
    ...getActionSymbolByName('rename_action'),
    onClick: (menuItemId, newLabel, biAction = 'rename', source_type) => {
      let oldLabel = menuItem.label;

      if (menuItem.pageData) {
        oldLabel = menuItem.pageData.title;

        editorAPI.menus.renamePage(
          menuItem.pageData.id,
          menuItem.pageData.title,
          newLabel,
        );
      } else {
        renameMenuItem(editorAPI, menuData.id, menuItem, newLabel);
      }

      editorAPI.bi.event(
        coreBi.events.pages.pagesPanel.quick_actions_menu_select,
        {
          page_id: getMenuItemPageId(menuItem),
          source: biCategory,
          category: biAction,
          old_name: oldLabel,
          new_name: newLabel,
          type: getMenuItemBiType(menuItem),
          source_type,
        },
      );
      onRename();
    },
    isRename: true,
  });
};

const addHideShowActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  biCategory,
}) => {
  if (isCustomErrorPage(editorAPI, menuItem)) {
    return;
  }

  if (isCustomMenusEnabled()) {
    const showHideAction =
      stateManagement.userPreferences.selectors.getGlobalUserPreferences(
        menuConstants.SHOW_DISABLED_HIDE_ON_PAGES_PANEL,
      )(editorAPI.store.getState());

    if (!showHideAction || isMainMenuFlowEnabled()) {
      return;
    }

    actions.push({
      defaultActionType: DefaultActionTypes.HIDE_SHOW_TYPE,
      title: translate('Pages_Actions_Remove_From_Menus'),
      ...getActionSymbolByName('hide_action'),
      disabled: true,
      tooltipText: translate('Pages_Actions_Remove_From_Menus_Tooltip'),
    });

    return;
  }

  if (menuItem.isVisible) {
    const title = experiment.isOpen('se_renameActionHideFromMenu')
      ? translate('Pages_Actions_Page_Hide_Menu')
      : translate('Pages_Actions_Page_Hide');

    actions.push({
      title,
      ...getActionSymbolByName('hide_action'),
      onClick: () => {
        setHidden(editorAPI, menuData.id, menuItem, true);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'hide',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  } else {
    const title = experiment.isOpen('se_renameActionHideFromMenu')
      ? translate('Pages_Actions_Page_Unhide_Menu')
      : translate('Pages_Actions_Page_Unhide');

    actions.push({
      title,
      ...getActionSymbolByName('show_action'),
      onClick: () => {
        setHidden(editorAPI, menuData.id, menuItem, false);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'show',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const addRemoveFromMainMenuIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  biCategory,
}) => {
  if (isMainMenuFlowEnabled()) {
    const menuAPI = createMenuApi(editorAPI);

    const mainMenu = menuAPI.getMainMenu();
    const pageId = menuItem.pageData?.id;

    if (!pageId) {
      return;
    }

    const menuItemIdFromMainMenu = menuAPI.getItemByPageId(mainMenu.id, pageId);

    if (!menuItemIdFromMainMenu || !mainMenu) {
      return;
    }

    actions.push({
      defaultActionType: DefaultActionTypes.REMOVE_FROM_MAIN_MENU_TYPE,
      title: translate('Pages_Actions_Remove_From_Default_Menu'),
      ...getActionSymbolByName('hide_action'),
      onClick: () => {
        menuAPI.removeItem(mainMenu.id, menuItemIdFromMainMenu);

        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'remove_from_main_menu',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const addSubPageMoveUpActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  renderedMenuItems,
  biCategory,
  isTranslating,
}) => {
  if (isCustomErrorPage(editorAPI, menuItem)) {
    return;
  }

  if (isCustomMenusEnabled() && !isNewPagesPanelEnabled()) {
    return;
  }

  if (canSubpage(menuItem) && !isNewPagesPanelEnabled()) {
    actions.push({
      title: translate('Pages_Actions_Page_Subpage'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Pages_Actions_Page_Subpage_Tooltip')
          : '',
      disabled:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions'),
      ...getActionSymbolByName('subpage_action'),
      onClick: () => {
        subpage({
          editorAPI,
          menuData,
          menuItem,
          futureParent: null,
          renderedMenuItems,
          biCategory,
        });
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'subpage',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  } else if (canMoveUp(menuItem)) {
    actions.push({
      title: translate('Pages_Actions_Page_MoveUp'),
      ...getActionSymbolByName('main_page_action'),
      onClick: () => {
        moveUp(editorAPI, menuData, menuItem, biCategory);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'mainpage',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const addMoveToFolderActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  renderedMenuItems,
  onItemAdded,
  biCategory,
}) => {
  if (!isNewPagesPanelEnabled() || isCustomErrorPage(editorAPI, menuItem)) {
    return;
  }

  const isFolder = !menuItem.link;
  if (!menuItem.parent && !isFolder) {
    const folders = menuData.items.filter((item) => !item.link);

    const moveToFolderActions = folders.map((folder) => ({
      title: folder.label,
      symbolName: 'folderPageType',
      onClick: () => {
        subpage({
          editorAPI,
          menuData,
          menuItem,
          futureParent: folder,
          renderedMenuItems,
          biCategory,
        });
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'subpage',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
      folderId: folder.id,
    }));

    const lastItem =
      folders[folders.length - 1] || menuData.items[menuData.items.length - 1];

    const addFolderAction = {
      title: translate('SiteMenu_RightClick_Add_New_Folder_Label'),
      symbolName: 'addFolder',
      onClick: () => {
        editorAPI.menus.addFolder(menuData.id, lastItem, (newFolderId) => {
          const updatedMenu = editorAPI.menu.getById(menuData.id);
          // const newFolder = updatedMenu.menuItems.find(
          const newFolder = updatedMenu.items.find(
            ({ id }) => id === newFolderId,
          );
          subpage({
            editorAPI,
            menuData: updatedMenu,
            menuItem,
            futureParent: newFolder,
            renderedMenuItems,
            biCategory,
          });
          onItemAdded(newFolderId);
        });
      },
      isFolderAction: true,
      folderId: 'add-new',
    };

    const dividerAction = { isDivider: true, key: 'folder-actions-divider' };

    actions.push({
      isFolder: true,
      title: 'SiteMenu_RightClick_Move_to_Folder_Label',
      symbolName: 'addFolder',
      actions: [...moveToFolderActions, dividerAction, addFolderAction], // check nested actions
    });
  }
};

const addPageCMSActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
}) => {
  if (experiment.isOpen('se_addDesignerPage')) {
    actions.push({
      title: 'Pages CMS',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        const designerPanelsApi = editorAPI.host.getAPI(DesignerPanelsApiKey);
        // @ts-expect-error because it accepts enum only
        designerPanelsApi.openSavePresetPanel('page');
      },
    });
    actions.push({
      title: 'Tag page sections',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        const designerPanelsApi = editorAPI.host.getAPI(DesignerPanelsApiKey);
        designerPanelsApi.openTagDesignersSectionsPanel();
      },
    });
  }
};

const addSectionCMSActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
}) => {
  if (experiment.isOpen('se_addDesignerSection')) {
    actions.push({
      title: 'Scan Current Section',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        const designerPanelsApi = editorAPI.host.getAPI(DesignerPanelsApiKey);
        // @ts-expect-error because it accepts enum only
        designerPanelsApi.openSavePresetPanel('section');
      },
    });
    actions.push({
      title: 'Scan Kit Panel',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        editorAPI.host.getAPI(DesignerPanelsApiKey).openScanKitPanel();
      },
    });
    actions.push({
      title: 'Scan LayoutFamily',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        editorAPI.host.getAPI(DesignerPanelsApiKey).openLayoutFamilyScanPanel();
      },
    });
    actions.push({
      title: 'Scan Animation Kit',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        editorAPI.host.getAPI(DesignerPanelsApiKey).openScanAnimationKitPanel();
      },
    });
  }
};

const addDuplicateActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  onItemAdded,
  biCategory,
  isTranslating,
}) => {
  const shouldIgnoreDynamicPageLink =
    isCustomMenusEnabled() && isDynamicPageLink(menuItem.link);

  const isApplicable =
    editorAPI.menus.canDuplicate(menuItem) &&
    !isCustomErrorPage(editorAPI, menuItem) &&
    !shouldIgnoreDynamicPageLink;

  if (
    isTranslating &&
    !experiment.isOpen('se_multilingual_addDisabledPageActions')
  ) {
    return;
  }

  if (isApplicable) {
    const staticPagesCount = editorAPI.pages.getStaticPagesCount();
    const title =
      isCustomMenusEnabled() && menuItem.type.isDropdown
        ? translate('PagesPanel_Actions_Folder_Options_Duplicate')
        : translate('Pages_Actions_Page_Duplicate');

    const duplicateItem = () => {
      editorAPI.menus.duplicateMenuItem(menuData.id, menuItem, onItemAdded);
      editorAPI.pages.openDynamicPagesPopupIfNeeded(staticPagesCount + 1);
    };

    actions.push({
      defaultActionType: DefaultActionTypes.DUPLICATE_TYPE,
      title,
      ...getActionSymbolByName('duplicate_action'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Pages_Actions_Page_Duplicate_Tooltip')
          : '',
      disabled:
        !editorAPI.pages.canAddStaticPage(staticPagesCount) ||
        (isTranslating &&
          experiment.isOpen('se_multilingual_addDisabledPageActions')),
      onClick: () => {
        const dontShowAgainWasChecked =
          !!stateManagement.userPreferences.selectors.getSiteUserPreferences(
            constants.USER_PREFS.DYNAMIC_PAGES.SUGGEST_ON_DUPLICATE
              .DONT_SHOW_AGAIN,
          )(editorAPI.store.getState());

        const shouldShowDynamicPageSuggestion =
          !menuItem.pageData?.managingAppDefId &&
          menuItem.type.isPage &&
          canBeSetAsDynamicPage(editorAPI, menuItem) &&
          !dontShowAgainWasChecked;

        editorAPI.bi.event(
          coreBi.events.pages.pagesPanel.quick_actions_menu_select,
          {
            page_id: getMenuItemPageId(menuItem),
            biCategory,
            category: 'duplicate',
            type: getMenuItemBiType(menuItem),
          },
        );

        if (shouldShowDynamicPageSuggestion) {
          const wixDataEnabled = isDataModeOn(editorAPI);

          editorAPI.bi.event(
            coreBi.events.pages.pagesPanel
              .convert_to_dynamic_on_duplicate_modal,
            {
              action_name: 'show',
              origin: 'duplicate_1_page',
              is_first_data_modal: !wixDataEnabled,
            },
          );

          editorAPI.panelManager.openPanel(
            'wixData.suggestDynamicPagePanel',
            {
              async onPrimaryActionClick() {
                await provisionWixCodeIfNeeded(
                  editorAPI,
                  'suggest_dynamic_pages_on_duplicate',
                  'convert_to_dynamic_on_duplicate',
                );
                openSetDynamicPageDialog(editorAPI, menuItem);
              },
              onSecondaryActionClick() {
                duplicateItem();
              },
              wixDataEnabled,
            },
            true,
          );
        } else {
          duplicateItem();
        }
      },
    });
  }
};

const addSetAsHomePageActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  isDesktopViewMode,
  isTranslating,
  biCategory,
}) => {
  if (
    isTranslating &&
    !experiment.isOpen('se_multilingual_addDisabledPageActions')
  ) {
    return;
  }

  const isSetAsHomepageAllowed =
    menuItem.type.isPage &&
    isDesktopViewMode &&
    !isCustomErrorPage(editorAPI, menuItem);
  const pageId = menuItem?.pageData?.id;

  if (isSetAsHomepageAllowed) {
    actions.push({
      defaultActionType: DefaultActionTypes.SET_AS_HOMEPAGE_TYPE,
      title: translate('Pages_Actions_Set_Home_Page'),
      ...getActionSymbolByName('set_as_homepage_action'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Pages_Actions_Set_Home_Page_Tooltip')
          : '',
      disabled:
        editorAPI.pages.isHomePage(pageId) ||
        (isTranslating &&
          experiment.isOpen('se_multilingual_addDisabledPageActions')),
      onClick: () => {
        editorAPI.homePage.set(pageId);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'setAsHomepage',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const isCopyPageActionAllowed = (
  editorAPI: EditorAPI,
  menuItem: IPageMenuItem,
  isDesktopViewMode: boolean,
) =>
  menuItem.type.isPage &&
  editorAPI.copyPaste.canCopyPage(menuItem.pageData.id) &&
  isDesktopViewMode &&
  !isCustomErrorPage(editorAPI, menuItem);

const addCopyPageActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  isDesktopViewMode,
  isTranslating,
}) => {
  if (
    isTranslating &&
    !experiment.isOpen('se_multilingual_addDisabledPageActions')
  ) {
    return;
  }

  if (isCopyPageActionAllowed(editorAPI, menuItem, isDesktopViewMode)) {
    actions.push({
      defaultActionType: DefaultActionTypes.COPY_TYPE,
      title: translate('Pages_Actions_Page_Copy'),
      ...getActionSymbolByName('copy_action'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Pages_Actions_Page_Copy_Tooltip')
          : '',
      disabled:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions'),
      onClick: () => {
        fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.COPY_PAGE);
        editorAPI.copyPaste.copyPage(menuItem.pageData.id, {
          origin: 'action_menu',
        });
        fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.COPY_PAGE);
      },
    });
  }
};

const addRemoveAction: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  biCategory,
  multiPageMenu,
  isTranslating,
}) => {
  if (
    isTranslating &&
    !experiment.isOpen('se_multilingual_addDisabledPageActions')
  ) {
    return;
  }

  if (multiPageMenu || !menuItem.type.isPage) {
    const title =
      isCustomMenusEnabled() && menuItem.type.isDropdown
        ? translate('PagesPanel_Actions_Folder_Options_Delete')
        : translate('Pages_Actions_Page_Delete');

    actions.push({
      defaultActionType: DefaultActionTypes.REMOVE_TYPE,
      title,
      ...getActionSymbolByName('delete_action'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Pages_Action_Page_Delete_Tooltip')
          : '',
      disabled:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions'),
      onClick: () => {
        editorAPI.menus.removeMenuItem(menuData.id, menuItem, multiPageMenu);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'delete',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const addGoogleTranslateActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
}) => {
  const translateFn =
    multilingual.googleTranslateFactory.getTranslateMethodForMenuItem(
      editorAPI,
      stateManagement,
      menuItem,
      (translated) =>
        (actions as MenuItemListAction[])
          .find((action) => action.isRename)
          .onClick(menuItem.id, translated, 'google translate'),
    );
  if (translateFn) {
    actions.push({
      title: translate('Pages_Actions_Page_Translate'),
      symbolName: 'googleTranslateMenuItem',
      onClick: translateFn,
    });
  }
};

const addChangeLinkActionIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  actions,
  menuItem,
  biCategory,
}) => {
  if (menuItem.type.isLink) {
    if (isCustomMenusEnabled()) {
      const isDynamicPageLinkItem = isDynamicPageLink(menuItem.link);

      if (isDynamicPageLinkItem) {
        return;
      }
    }

    actions.push({
      title: translate('Pages_Actions_Link_Change'),
      ...getActionSymbolByName('change_link_action'),
      onClick: () => {
        editorAPI.menus.updateLink(menuData.id, menuItem, menuItem.link);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'change link',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const showCMLoaderPanel = (editorAPI: EditorAPI) => {
  const appName = translate(
    'SiteApp_WixData_AddCollectionPanel_InstallationLoader_AppName',
  );
  editorAPI.panelManager.openPanel('panels.focusPanels.fakeProgressBarPanel', {
    appName,
    isDone: false,
  });
};

const hideCMLoaderPanel = (editorAPI: EditorAPI) => {
  editorAPI.panelManager.closePanelByName(
    'panels.focusPanels.fakeProgressBarPanel',
    '',
  );
};

const provisionWixCodeIfNeeded = async (
  editorAPI: EditorAPI,
  origin: string,
  biEntryPoint: string = origin,
) => {
  if (!isDataModeOn(editorAPI) && !editorAPI.developerMode.isEnabled()) {
    showCMLoaderPanel(editorAPI);
    await utils.provisionWixCodeIfNeeded(editorAPI);
    utils.enableDataMode(editorAPI, origin);
    hideCMLoaderPanel(editorAPI);

    editorAPI.bi.event(coreBi.events.addPanel.DATA_MODE_ON_ACTIVATION_CLICK, {
      status: true,
      origin: 'manual',
      entry_point: biEntryPoint,
    });
  }
};

const addSetDynamicPageIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  biCategory,
  isTranslating,
}) => {
  if (
    isTranslating &&
    !experiment.isOpen('se_multilingual_addDisabledPageActions')
  ) {
    return;
  }

  if (menuItem.type.isPage && canBeSetAsDynamicPage(editorAPI, menuItem)) {
    actions.push({
      isCmsAction: shouldUseNewBindingExperience(),
      title: translate('Platform_Page_Actions_Set_Dynamic_Page'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Platform_Page_Actions_Set_Dynamic_Page_Tooltip')
          : '',
      disabled:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions'),
      ...getActionSymbolByName('convert_to_dynamic_page_action'),
      onClick: async () => {
        await provisionWixCodeIfNeeded(
          editorAPI,
          'convert_to_dynamic_from_page_actions',
        );

        openSetDynamicPageDialog(editorAPI, menuItem);
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'Set Page Dynamic',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }
};

const addSeoActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  biCategory,
  isDesktopViewMode,
  onShowSettingsCallback,
}) => {
  const pageId = menuItem?.pageData?.id;
  const isApplicable =
    menuItem.type.isPage &&
    isDesktopViewMode &&
    !isCustomErrorPage(editorAPI, menuItem);

  if (isApplicable) {
    actions.unshift(
      {
        title: translate(constants.PAGE_SETTINGS_TABS.TITLE_KEYS.BASIC_SEO),
        ...getActionSymbolByName('seo_action'),
        onClick: () => {
          onShowSettingsCallback({
            tabType: constants.PAGE_SETTINGS_TABS.SEO,
            pageId,
          });

          reportActionBi(editorAPI, {
            pageId: getMenuItemPageId(menuItem),
            category: 'tabSEO',
            source: biCategory,
            type: getMenuItemBiType(menuItem),
          });
        },
        isSettings: true,
      },
      {
        title: translate(constants.PAGE_SETTINGS_TABS.TITLE_KEYS.SOCIAL),
        ...getActionSymbolByName('social_share_action'),
        onClick: () => {
          onShowSettingsCallback({
            tabType: constants.PAGE_SETTINGS_TABS.SOCIAL_NEW,
            pageId,
          });
          reportActionBi(editorAPI, {
            pageId: getMenuItemPageId(menuItem),
            category: 'socialShare',
            source: biCategory,
            type: getMenuItemBiType(menuItem),
          });
        },
        isSettings: true,
      },
    );
  }
};

const addSettingsActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  actions,
  menuItem,
  isDesktopViewMode,
  isTranslating,
  onShowSettingsCallback,
  biCategory,
}) => {
  if (
    isTranslating &&
    !experiment.isOpen('se_multilingual_addDisabledPageActions')
  ) {
    return;
  }

  if (menuItem.type.isPage && isDesktopViewMode) {
    const settingsTab = {
      title: translate('Pages_Actions_Page_Settings'),
      ...getActionSymbolByName('settings_action'),
      tooltipText:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions')
          ? translate('Pages_Actions_Page_Settings_Tooltip')
          : '',
      disabled:
        isTranslating &&
        experiment.isOpen('se_multilingual_addDisabledPageActions'),
      onClick: () => {
        onShowSettingsCallback({
          tabType: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
          pageId: menuItem.pageData.id,
        });

        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'tabPageInfo',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
      isSettings: true,
    };

    actions.unshift(settingsTab);
  }
};

const getDesktopActions: GetActionsList = (fnParams) => {
  const params: Parameters<AddDesktopActionFN>[0] = {
    ...fnParams,
    actions: [],
  };

  addSeoActionsIfApplicable(params);
  addSettingsActionsIfApplicable(params);
  addChangeLinkActionIfApplicable(params);
  addPageBGActionIfApplicable(params);
  addPageDesignActionIfApplicable(params);
  addConnectToDataIfApplicable(params);
  addDataConnectionsListIfApplicable(params);
  if (shouldUseNewBindingExperience()) {
    addSetDynamicPageIfApplicable(params);
  }
  addRenameActionIfApplicable(params);
  addGoogleTranslateActionsIfApplicable(params);
  addPageCMSActionIfApplicable(params);
  addSectionCMSActionIfApplicable(params);
  addDuplicateActionIfApplicable(params);

  addCopyPageActionIfApplicable(params);

  addSetAsHomePageActionIfApplicable(params);

  if (!shouldUseNewBindingExperience()) {
    addSetDynamicPageIfApplicable(params);
  }
  addHideShowActionsIfApplicable(params);
  addRemoveFromMainMenuIfApplicable(params);
  addSubPageMoveUpActionsIfApplicable(params);
  addMoveToFolderActionsIfApplicable(params);
  addRemoveAction(params);

  return params.actions;
};

const getMobileActions: GetActionsList = ({
  editorAPI,
  menuData,
  menuItem,
  onShowSettingsCallback,
  biCategory,
}) => {
  const mobilePageSettings = {
    title: translate('Mobile_Pages_Actions_Page_Settings'),
    ...getActionSymbolByName('settings_action'),
    onClick: () => {
      const pageId = menuItem?.pageData?.id;
      onShowSettingsCallback({
        tabType: constants.PAGE_SETTINGS_TABS.MOBILE_LAYOUT,
        pageId,
      });
      reportActionBi(editorAPI, {
        pageId: getMenuItemPageId(menuItem),
        category: 'mobileSettings',
        source: biCategory,
        type: getMenuItemBiType(menuItem),
      });
    },
  };
  const hideMobilePage = {
    title: translate('MenuItem_Hide_Mobile_Action'),
    ...getActionSymbolByName('mobile_hide_action'),
    onClick: () => {
      setHidden(editorAPI, menuData.id, menuItem, undefined, true);
      editorAPI.bi.event(
        coreBi.events.pages.pagesPanel.quick_action_hide_show_mobile_click,
        {
          page_id: getMenuItemPageId(menuItem),
          source: biCategory,
          category: 'hide',
        },
      );
    },
  };

  const showMobilePage = {
    title: translate('MenuItem_Show_Mobile_Action'),
    ...getActionSymbolByName('mobile_show_action'),
    onClick: () => {
      setHidden(editorAPI, menuData.id, menuItem, undefined, false);
      editorAPI.bi.event(
        coreBi.events.pages.pagesPanel.quick_action_hide_show_mobile_click,
        {
          page_id: getMenuItemPageId(menuItem),
          source: biCategory,
          category: 'show',
        },
      );
    },
  };

  const pageBackground = {
    title: translate('Pages_Actions_Page_Background'),
    ...getActionSymbolByName('background_action'),
    onClick: () => {
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.BACKGROUND_PANEL_NAME,
      );
    },
  };
  if (sections.isSectionsEnabled() && menuItem.type.isPage) {
    if (isCustomMenusEnabled()) {
      return [mobilePageSettings, pageBackground];
    }

    const hideOrShowItem = menuItem.isVisibleMobile
      ? hideMobilePage
      : showMobilePage;
    return [mobilePageSettings, hideOrShowItem, pageBackground];
  }

  if (isCustomMenusEnabled()) {
    return [mobilePageSettings];
  }

  const hideOrShowItem = menuItem.isVisibleMobile
    ? hideMobilePage
    : showMobilePage;
  return [mobilePageSettings, hideOrShowItem];
};

const getAppSettingsActionsIfApplicable: GetActionsList = ({
  editorAPI,
  menuData,
  menuItem,
  isDesktopViewMode,
  onShowSettingsCallback,
  biCategory,
}) => {
  if (isDesktopViewMode) {
    const appDefId = editorAPI.dsRead.platform.getAppDataByApplicationId(
      menuData.appId,
    ).appDefinitionId;
    const rawSettings = editorAPI.pages.dynamicPages.getActionByAppDefId(
      appDefId,
      'pageSettings',
      [
        platform.constants.pages.page_settings.pageInfo.type,
        platform.constants.pages.page_settings.seo.type,
      ],
    );

    return rawSettings.map((action) => ({
      title: action.title,
      ...getActionSymbolByType(action),
      onClick: () => {
        const pageId = menuItem?.pageData?.id;

        onShowSettingsCallback({
          pageId,
          // @ts-expect-error
          routerId: menuItem?.link?.routerId,
          // @ts-expect-error
          innerRoute: menuItem?.link?.innerRoute,
          tabType: action.type,
        });

        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: action.type,
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
      isSettings: true,
    }));
  }

  return [];
};

const addAppActionsIfApplicable: AddDesktopActionFN = ({
  editorAPI,
  menuData,
  menuItem,
  onShowSettingsCallback,
  actions,
  biCategory,
  isTranslating,
}) => {
  //TODO: once WEED-6199 is done add support of actions change it to loading actions from appmanifest and notifying app

  const pageId = menuItem?.pageData?.id;
  const appDefId = editorAPI.dsRead.platform.getAppDataByApplicationId(
    menuData.appId,
  ).appDefinitionId;
  const pageInfoSettings = editorAPI.pages.dynamicPages.getActionByAppDefId(
    appDefId,
    'pageSettings',
    [platform.constants.pages.page_settings.pageInfo.type],
  )[0];

  if (!pageInfoSettings) {
    return;
  }

  if (menuItem.isVisible) {
    actions.push({
      title: translate('Pages_Actions_Page_Hide'),
      ...getActionSymbolByName('hide_action'),
      onClick: () => {
        onShowSettingsCallback({
          tabType: pageInfoSettings.type,
          pageId,
          // @ts-expect-error
          routerId: menuItem?.link?.routerId,
          // @ts-expect-error
          innerRoute: menuItem?.link?.innerRoute,
        });
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'hide',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  } else {
    actions.push({
      title: translate('Pages_Actions_Page_Unhide'),
      ...getActionSymbolByName('show_action'),
      onClick: () => {
        onShowSettingsCallback({
          tabType: pageInfoSettings.type,
          pageId,
          // @ts-expect-error
          routerId: menuItem?.link?.routerId,
          // @ts-expect-error
          innerRoute: menuItem?.link?.innerRoute,
        });
        reportActionBi(editorAPI, {
          pageId: getMenuItemPageId(menuItem),
          category: 'hide',
          source: biCategory,
          type: getMenuItemBiType(menuItem),
        });
      },
    });
  }

  actions.push({
    title: isTranslating
      ? translate('Pages_Actions_Page_Edit_Name')
      : translate('Pages_Actions_Page_Rename'),
    ...getActionSymbolByName('rename_action'),
    onClick: () => {
      onShowSettingsCallback({
        tabType: pageInfoSettings.type,
        pageId,
        // @ts-expect-error
        routerId: menuItem?.link?.routerId,
        // @ts-expect-error
        innerRoute: menuItem?.link?.innerRoute,
      });
      reportActionBi(editorAPI, {
        pageId: getMenuItemPageId(menuItem),
        category: 'rename',
        source: biCategory,
        type: getMenuItemBiType(menuItem),
      });
    },
  });
};

const getDesktopApplicationMenuActions: GetActionsList = (params) => {
  const actions = getAppSettingsActionsIfApplicable(params);

  addAppActionsIfApplicable({ ...params, actions });
  addSubPageMoveUpActionsIfApplicable({ ...params, actions });
  return actions;
};

const getApplicationPageActionsAndSettings: GetActionsList = ({
  editorAPI,
  menuData,
  menuItem,
  renderedMenuItems,
  onShowSettingsCallback,
  onItemAdded,
  onRename,
  biCategory,
  isTranslating,
  isDesktopViewMode,
  multiPageMenu,
  defaultActions = [],
}) => {
  const pageId = menuItem.pageData.id;
  const appDefinitionId = menuItem.pageData.managingAppDefId;
  const applicationPageActionsAndSettins = actions.applicationPage(
    editorAPI,
    pageId,
    {
      appDefinitionId,
      isDesktop: true,
      biCategory,
      onShowSettings: onShowSettingsCallback,
      onRename,
      defaultActions,
      tabAppDefinitionId: menuItem?.appDefinitionId,
    },
    menuItem.pageData.title,
  );

  const [removeAction] = _.remove(applicationPageActionsAndSettins, {
    isRemove: true,
  });

  const params: Parameters<AddDesktopActionFN>[0] = {
    editorAPI,
    menuData,
    actions: applicationPageActionsAndSettins,
    menuItem,
    biCategory,
    isDesktopViewMode,
    isTranslating,
    onShowSettingsCallback,
    onItemAdded,
    onRename,
    multiPageMenu,
    renderedMenuItems,
  };

  addGoogleTranslateActionsIfApplicable(params);
  addHideShowActionsIfApplicable(params);
  addSubPageMoveUpActionsIfApplicable(params);

  if (isMainMenuFlowEnabled()) {
    addRemoveFromMainMenuIfApplicable(params);
  }

  if (removeAction) {
    applicationPageActionsAndSettins.push(removeAction);
  }
  return applicationPageActionsAndSettins;
};

type GetActionsForMenuItem = (
  params: CommonParams & { menu: IPagesPanelMenu },
) => MenuItemAction[];

const getActionsForMenuItem: GetActionsForMenuItem = (params) => {
  const menuData = params.editorAPI.dsRead.menu.getById(
    params.menu.id,
    params.editorAPI.menus.shouldUseOriginalLanguage(),
  );
  const allParams: Parameters<GetActionsList>[0] = {
    ...params,
    menuData,
  };

  if (menuData.appId) {
    if (!params.isDesktopViewMode) {
      return null;
    }

    return getDesktopApplicationMenuActions(allParams);
  }

  if (!allParams.isDesktopViewMode) {
    return getMobileActions(allParams);
  }

  if (allParams.menuItem?.pageData?.managingAppDefId) {
    const defaultActions = getDesktopActions(allParams);
    return getApplicationPageActionsAndSettings({
      ...allParams,
      defaultActions,
    });
  }

  return getDesktopActions(allParams);
};
export { getActionsForMenuItem };
