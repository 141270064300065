import { BasePublicApi } from '@/apilib';
import type { Scope } from './scope';
import { fixedStage } from '@/util';
import { domMeasurements } from '@/stateManagement';

const { getEditorContentLayout } = domMeasurements.selectors;

export function isStageFixedInDesktop(scope: Scope) {
  return fixedStage.isFixedStageEnabled() && !scope.editorAPI.isMobileEditor();
}

export function isStageHorizontallyScrollable({ store }: Scope) {
  return store.isStageHorizontallyScrollable();
}
export function setIsStageHorizontallyScrollable(
  { store }: Scope,
  newValue: boolean,
) {
  return store.setIsStageHorizontallyScrollable(newValue);
}

export function getOffsetRightForComponentsFloatingRight(scope: Scope) {
  const { scroll, editorAPI } = scope;
  const currentScroll = scroll.get().scrollLeft;

  const maxScrollLeft = `${editorAPI.site.getWidth()}px - ${
    editorAPI.getEditingAreaPosition().width
  }px`;

  return isStageHorizontallyScrollable(scope)
    ? `calc(${maxScrollLeft} - ${currentScroll}px)`
    : '0';
}

export function getCenteredComponentsLeft(
  scope: Scope,
  offsetLeft: number,
  defaultLeft: string | number,
) {
  const { scroll, editorAPI } = scope;
  if (!isStageHorizontallyScrollable(scope)) return { left: defaultLeft };

  const leftPosition =
    editorAPI.getEditingAreaPosition().width * offsetLeft +
    scroll.get().scrollLeft;

  return {
    left: `${leftPosition}px`,
    transform: `translateX(-50%)`,
  };
}

export function isTopBarMinimized(scope: Scope) {
  const { editorConfig, language, store } = scope.editorAPI;
  return (
    fixedStage.isFixedStageEnabled() &&
    language?.multilingual.isEnabled() &&
    getEditorContentLayout(store.getState()).width < editorConfig.minTopBarWidth
  );
}

export class FixedStageApi extends BasePublicApi<Scope> {
  isStageFixedInDesktop = this.bindScope(isStageFixedInDesktop);
  isStageHorizontallyScrollable = this.bindScope(isStageHorizontallyScrollable);
  setIsStageHorizontallyScrollable = this.bindScope(
    setIsStageHorizontallyScrollable,
  );
  getOffsetRightForComponentsFloatingRight = this.bindScope(
    getOffsetRightForComponentsFloatingRight,
  );
  getCenteredComponentsLeft = this.bindScope(getCenteredComponentsLeft);
  isTopBarMinimized = this.bindScope(isTopBarMinimized);
}
