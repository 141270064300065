// @ts-nocheck
import _ from 'lodash';
import {
  Composites,
  EditableUrl,
  Heading,
  PanelHeaderCenteredText,
} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';
import * as utils from '@wix/santa-editor-utils';
import * as util from '@/util';
import { translate } from '@/i18n';
import INNER_PANELS from './publishInnerPanelTypes';
import * as PublishRCSuccessPanelMapper from './publishRCSuccessPanelMapper';
import * as coreBi from '@/coreBi';

import React from 'react';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import ManageExposure from '../content/manageExposure';
import { cx } from '@/util';

const isNewVeloWorkspaceEnabled =
  util.workspace.isNewWorkspaceEnabled() &&
  util.wixCodeUtils.isNewVeloWorkspace();

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;
const PUBLISH_RC_SUCCESS_PANEL =
  'savePublish.panels.publish.publishRCSuccessPanel';
const BUSINESS_MANAGER = util.serviceTopology.businessManagerUrl;

//TYPE WAS GENERATED, remove this line when reviewed
interface PublishRCSuccessPanelProps {
  isFakePanel?: boolean;
  contentPanelType?: AnyFixMe;
  configuration: AnyFixMe;
  sitePublicUrl: string;
  closePanel: FunctionFixMe;
  panelName?: string;
}

class PublishRCSuccessPanel extends React.Component<PublishRCSuccessPanelProps> {
  static displayName = 'publishRCSuccessPanel';

  static propTypes = {
    isFakePanel: PropTypes.bool,
    contentPanelType(props, propName, componentName) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      if (!props[propName] || !_.some(INNER_PANELS, props[propName])) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to ${componentName}. Validation failed.`,
        );
      }
    },
    configuration: PropTypes.object.isRequired,
    sitePublicUrl: PropTypes.string.isRequired,
    closePanel: PropTypes.func.isRequired,
    panelName: PropTypes.string,
  };

  getShortcuts = () => {
    return {
      esc: this.onClose,
      enter: this.handlePrimaryButtonClicked,
    };
  };

  onClose = () => {
    this.props.panelManager.closePanelByName(this.props.panelName);
  };

  getFrameProps = () => {
    return {
      panelName: this.props.panelName,
      shouldHideHeader: !isNewVeloWorkspaceEnabled,
    };
  };

  getSiteUrl = () => {
    const {
      configuration,
      sitePublicUrl,
      rcPublicUrl,
      siteProtocol,
      isDomainConnected,
    } = this.props;
    const siteUrl = configuration.sitePremiumDomain || sitePublicUrl;
    const regex = /(?:^https?:\/\/)?(?:www\.)?(.*)/i;
    const queryString = rcPublicUrl.substring(rcPublicUrl.indexOf('?') + 1);
    const params = util.url.parseUrlParams(queryString);

    let url = siteUrl.replace(
      regex,
      siteProtocol + (isDomainConnected ? '://www.$1' : '://$1'),
    );
    url = util.url.setUrlParam(url, 'siteRevision', params.siteRevision);
    if (params.branchId) {
      url = util.url.setUrlParam(url, 'branchId', params.branchId);
    }
    return url;
  };

  getFrameClass = () => {
    let frameClass = 'publish-success-panel save-publish-panel';
    const innerPanelClass = 'manage-exposure-inner-panel';
    frameClass += innerPanelClass ? ` contains-${innerPanelClass}` : '';

    return frameClass;
  };

  onDoneAndBI = (biEventDef) => {
    this.sendActionButtonClickedBI(biEventDef);
    this.onDone();
  };

  onDone = () => {
    this.onClose();
  };

  viewYourSite = () => {
    this.sendViewSiteBI();
    window.open(this.props.rcPublicUrl);
  };

  getManageExposurePanelProps = () => {
    return {
      closePanel: this.onDone,
      actionCallback: this.openReleaseManager,
    };
  };

  openReleaseManager = () => {
    this.sendGotoReleaseManagerBI();
    window.open(
      `${BUSINESS_MANAGER}/${this.props.metaSiteId}/release-manager-client?referralInfo=EDITOR`,
      '_blank',
    );
  };

  getPrimaryButtonText = () => {
    return 'Release_Candidate_Screen_Done_Button';
  };

  handlePrimaryButtonClicked = () => {
    const publishEvents = coreBi.events.publish;
    const biEventDef = publishEvents.Create_RC_Clicked;
    this.onDoneAndBI(biEventDef);
  };

  sendActionButtonClickedBI = (biEventDef) => {
    const params = {
      panel_name: PUBLISH_RC_SUCCESS_PANEL,
    };

    if (biEventDef.fields.revision) {
      const { rcPublicUrl } = this.props;
      const queryString = rcPublicUrl.substring(rcPublicUrl.indexOf('?') + 1);
      params.revision = util.url.parseUrlParams(queryString).siteRevision;
    }

    this.sendBI(biEventDef, params);
  };

  sendViewSiteBI = () => {
    this.sendActionButtonClickedBI(
      coreBi.events.publish.publish_rc_view_your_site_click,
    );
  };

  sendGotoReleaseManagerBI = () => {
    this.sendActionButtonClickedBI(
      coreBi.events.publish.publish_rc_goto_release_manager_click,
      {
        origin: 'RC panel',
      },
    );
  };

  sendBI = (biEvent, biFields) => {
    this.props.bi.event(biEvent, biFields);
  };

  renderNewWorkspacePanelFrame = () => {
    return (
      <panels.frames.CustomPanelFrame
        panelName={this.props.panelName}
        className="publish-rc"
        dataHook="publish-rc-success-panel"
        onCloseButtonClick={() => this.onClose()}
        footerContent={
          <div className="publish-rc-footer">
            <baseUI.button
              label={this.getPrimaryButtonText()}
              onClick={this.handlePrimaryButtonClicked}
              className={util.cx('btn-confirm-primary', 'btn-md')}
            />
          </div>
        }
        onOuterClick={this.onClose}
        keyboardShortcuts={this.getShortcuts()}
      >
        <div className="publish-rc-header">
          <div className="publish-rc-header-main">
            <Heading shouldTranslate={false} multiline>
              {translate('Test_Site_Screen_Title')}
            </Heading>
          </div>
          <Heading shouldTranslate={false} multiline appearance="h4">
            {translate('Test_Site_Screen_Text')}
          </Heading>
        </div>
        <div>
          <div className="address">
            <div className="address-url">
              <EditableUrl value={this.getSiteUrl()} isReadOnly />
            </div>

            <baseUI.button
              label="SAVE_PUBLISH_BUTTON_VIEW"
              onClick={this.viewYourSite}
              className={util.cx('btn-md', 'btn-confirm-secondary')}
            />
          </div>

          <ManageExposure
            closePanel={this.onDone}
            actionCallback={this.openReleaseManager}
          />
        </div>
      </panels.frames.CustomPanelFrame>
    );
  };

  render() {
    if (isNewVeloWorkspaceEnabled) {
      return this.renderNewWorkspacePanelFrame();
    }

    return (
      <panels.frames.FocusPanelFrame
        frameClassName="save-publish-panel-frame"
        dataHook="publish-rc-success-panel"
        keyboardShortcuts={this.getShortcuts()}
        {...this.getFrameProps()}
        className={cx(this.getFrameClass(), this.getFrameProps()?.className)}
      >
        <div className="publish-rc-old">
          <Composites.BigPanelHeader>
            <PanelHeaderCenteredText
              title={'Test_Site_Screen_Title'}
              noHelpBtn={true}
              titleType="T16"
              onClose={this.onClose}
              className="dark"
            />
          </Composites.BigPanelHeader>

          <div className="address-bar-wrapper">
            <span className="desc">{translate('Test_Site_Screen_Text')}</span>
            <span className="address">
              <EditableUrl value={this.getSiteUrl()} isReadOnly />
            </span>

            <baseUI.button
              label={'Test_Site_Screen_View_Button'}
              onClick={this.viewYourSite}
              className="view-site-button"
            />
          </div>

          <div key="innerPanel" className="publish-inner-panel">
            <ManageExposure
              closePanel={this.onDone}
              actionCallback={this.openReleaseManager}
            />
          </div>

          <div className="publish-success-footer">
            <div className="footer-buttons">
              <baseUI.button
                label="Release_Candidate_Screen_Done_Button"
                onClick={this.handlePrimaryButtonClicked}
                className="btn-confirm-primary"
              />
            </div>
          </div>
        </div>
      </panels.frames.FocusPanelFrame>
    );
  }
}

const connectedPublishRCSuccessPanel = _.flowRight(
  utils.hoc.draggable,
  connect(
    EDITOR_API,
    PublishRCSuccessPanelMapper.mapStateToProps,
    PublishRCSuccessPanelMapper.mapDispatchToProps,
  ),
)(PublishRCSuccessPanel);

connectedPublishRCSuccessPanel.pure = PublishRCSuccessPanel;

export default connectedPublishRCSuccessPanel;
