import React, { type FC, useEffect, useState } from 'react';
import experiment from 'experiment';
import { Text } from '@wix/wix-base-ui';
import { frames } from '@/panels';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { createBIeventsCallbacks } from '../../welcomePanelBI';

import type { ErrorBIData, SendBI } from '../../types';
import type { PanelTypes } from '../../constants';

interface Props {
  onClose: (origin?: string) => void;
  sendBI: SendBI;
  errorBIData: ErrorBIData | null;
  panelType: PanelTypes;
}

const ErrorMessagePanel: FC<Props> = ({
  onClose,
  sendBI,
  errorBIData,
  panelType,
}) => {
  const { sendBIError } = createBIeventsCallbacks(sendBI);
  const errorMessage = translate(
    'ai_template_injection_high_traffic_error_text',
  );
  const welcomePanelOptExperimentIsOpen = experiment.isOpen(
    'se_welcomePanelOptimization',
  );
  const [hasErrorSent, setErrorSent] = useState(false);

  useEffect(() => {
    if (!hasErrorSent && errorMessage && sendBIError) {
      sendBIError({
        query: errorBIData.params,
        errorMessage,
        gptParams: errorBIData?.currentGptParamsVersion,
        prompt: errorBIData.currentPromptsVersion,
        tokens: errorBIData.tokenUsage,
        panelType,
      });
      setErrorSent(true);
    }
  }, [
    sendBIError,
    errorMessage,
    hasErrorSent,
    setErrorSent,
    errorBIData,
    panelType,
  ]);

  return (
    <frames.MessagePanelFrame
      panelName="ai-injection-into-template-error-panel"
      dataHook="ai-injection-into-template-error-panel"
      title={translate('ai_template_injection_high_traffic_error_title')}
      illustration={
        welcomePanelOptExperimentIsOpen ? (
          <Symbol name="serverError_NewWorkspace" />
        ) : (
          <baseUI.symbol name="unsupportedComponentIllustration" />
        )
      }
      confirmOnEnterPress
      cancelOnEscPress
      cancelOnClickOutside
      confirmLabel={translate('ai_template_injection_high_traffic_error_cta')}
      onConfirm={onClose}
      onCancel={onClose}
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {errorMessage}
      </Text>
    </frames.MessagePanelFrame>
  );
};

export default ErrorMessagePanel;
