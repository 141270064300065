import { InfoIcon, Text } from '@wix/wix-base-ui';
import React from 'react';
import styles from './addSectionCategoryHeader.scss';

interface AddSectionCategoryHeaderProps {
  longTitle: string;
  tooltipTitle: string;
  tooltipDescription: string;
  linkText: string;
  handleTooltipOpen: () => void;
  handleTooltipClose: () => void;
  onLinkClick: () => void;
}

export const AddSectionCategoryHeader = ({
  longTitle,
  tooltipTitle,
  tooltipDescription,
  linkText,
  handleTooltipOpen,
  handleTooltipClose,
  onLinkClick,
}: AddSectionCategoryHeaderProps) => {
  return (
    <div className={styles.addSectionCategoryHeader}>
      <Text size="small">{longTitle}</Text>
      <div onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
        <InfoIcon
          title={tooltipTitle}
          shouldTranslate={true}
          text={tooltipDescription}
          linkText={linkText}
          onLinkClick={onLinkClick}
          alignment={'RIGHT'}
          tooltipMarginRight={6}
        />
      </div>
    </div>
  );
};
