const FORM_BUILDER_APP_ID = '14ce1214-b278-a7e4-1373-00cebd1bef7c';
const FormPreset = {
  CONTACT_FORM: 'contact-form',
  POTENTIAL_CUSTOMERS: 'potential-customers',
  SALES_LEAD: 'sales-lead',
  SUPPORT_REQUEST: 'support-request',
  TESTIMONIAL: 'testimonial',
  JOB_APPLICATION: 'job-application',
  CUSTOMER_SATISFACTION: 'customer-satisfaction',
  PRICE_QUOTE: 'price-quote',
  CONTEST_ENTRY: 'contest-entry',
  QUIZ: 'quiz',
  REGISTRATION_FORM: 'registration-form',
  CONTACT01: 'contact01',
  CONTACT02: 'contact02',
  CONTACT03: 'contact03',
  CONTACT04: 'contact04',
  CONTACT05: 'contact05',
  CONTACT06: 'contact06',
  CONTACT07: 'contact07',
  CONTACT08: 'contact08',
  CONTACT09: 'contact09',
  CONTACT10: 'contact10',
  CONTACT11: 'contact11',
  CONTACT12: 'contact12',
  CONTACT13: 'contact13', //multi
  CONTACT14: 'contact14',
  SALES_LEAD01: 'salesLead01',
  SALES_LEAD02: 'salesLead02',
  JOB_APPLICATION01: 'jobApplication01',
  JOB_APPLICATION02: 'jobApplication02',
  JOB_APPLICATION03: 'jobApplication03',
  JOB_APPLICATION04: 'jobApplication04',
  JOB_APPLICATION05: 'jobApplication05',
  JOB_APPLICATION06: 'jobApplication06',
  JOB_APPLICATION07: 'jobApplication07',
  JOB_APPLICATION08: 'jobApplication08',
  SUPPORT_REQUEST01: 'support01',
  SUPPORT_REQUEST02: 'support02',
  SUPPORT_REQUEST03: 'support03',
  TESTIMONIAL01: 'testimonials01',
  TESTIMONIAL02: 'testimonials02',
  TESTIMONIAL03: 'testimonials03',
  FEEDBACK01: 'feedback01',
  FEEDBACK02: 'feedback02',
  FEEDBACK03: 'feedback03',
  FEEDBACK04: 'feedback04',
  FEEDBACK05: 'feedback05',
  CONTEST_ENTRY01: 'enterContest01',
  CONTEST_ENTRY02: 'enterContest02',
  CONTEST_ENTRY03: 'enterContest03',
  CONTEST_ENTRY04: 'enterContest04',
  CONTEST_ENTRY05: 'enterContest05',
  CONTEST_ENTRY06: 'enterContest06',
  CONTEST_ENTRY07: 'enterContest07',
  CONTEST_ENTRY08: 'enterContest08',
  QUIZ01: 'quiz01',
  QUIZ02: 'quiz02',
  QUIZ03: 'quiz03',
  QUIZ04: 'quiz04',
  PRICE_QUOTE01: 'getAQuote01',
  PRICE_QUOTE02: 'getAQuote02',
  PRICE_QUOTE03: 'getAQuote03',
  SUBSCRIBERS01: 'subscribers01',
  SUBSCRIBERS02: 'subscribers02',
  SUBSCRIBERS03: 'subscribers03',
  SUBSCRIBERS04: 'subscribers04',
  SUBSCRIBERS05: 'subscribers05',
  SUBSCRIBERS06: 'subscribers06',
  SUBSCRIBERS07: 'subscribers07',
  SUBSCRIBERS08: 'subscribers08',
  SUBSCRIBERS09: 'subscribers09',
  SUBSCRIBERS10: 'subscribers10',
  SUBSCRIBERS11: 'subscribers11',
  STRIP01: 'strips_contact01',
  STRIP02: 'strips_contact02',
  STRIP03: 'strips_contact03',
  STRIP04: 'strips_contact04',
  LIGHTBOX_CONTACT01: 'lightbox_contact01',
  LIGHTBOX_CONTACT02: 'lightbox_contact02',
  LIGHTBOX_CONTACT03: 'lightbox_contact03',
  LIGHTBOX_CONTACT04: 'lightbox_contact04',
  LIGHTBOX_CONTACT05: 'lightbox_contact05',
  LIGHTBOX_SUBSCRIBE01: 'lightbox_subscribe01',
  LIGHTBOX_SUBSCRIBE02: 'lightbox_subscribe02',
  LIGHTBOX_SUBSCRIBE03: 'lightbox_subscribe03',
  LIGHTBOX_SUBSCRIBE04: 'lightbox_subscribe04',
  LIGHTBOX_SUBSCRIBE05: 'lightbox_subscribe05',
  LIGHTBOX_PROMOTION01: 'lightbox_promotion01',
  INTERACTIVE_CONTACT01: 'interactive_contact01',
  PAYMENT01: 'paymentForm01',
  PAYMENT02: 'paymentForm02',
  PAYMENT03: 'paymentForm03',
  PAYMENT04: 'paymentForm04',
  PAYMENT05: 'paymentForm05',
  PAYMENT06: 'paymentForm06',
  PAYMENT07: 'paymentForm07',
  MULTISTEP01: 'multi-step-sales',
  MULTISTEP02: 'multi-step-registration-form',
  MULTISTEP03: 'multi-step-volunteer-application-form',
  MULTISTEP04: 'multi-step-job-application',
  DONATIONS01: 'donationForm01',
  DONATIONS02: 'donationForm02',
  DONATIONS03: 'donationForm03',
  DONATIONS04: 'donationForm04',
  COVID19_HEALTH_DECLARATION: 'covid19HealthDeclaration',
  GYM_HEALTH_WAIVER: 'gymHealthWaiver',
  GYM_HEALTH_WAIVER02: 'gymHealthWaiver02',
  HEALTH_DECLARATION: 'healthDeclaration',
  HEALTH_DECLARATION02: 'healthDeclaration02',
  MEDICAL_QUESTIONNAIRE: 'medicalQuestionnaire',
  EMERGENCY_CONTACT: 'emergencyContact',
  PHOTO_CONSENT: 'photoConsnet',
};

const imageStyle = { height: '100%', width: '100%' };
const formStyle = {
  metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
  type: 'TopLevelStyle',
  style: {},
  styleType: 'custom',
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
};

const CONTROLLER_TYPES = {
  REGISTRATION_FORM: 'registrationForm',
  GET_SUBSCRIBERS: 'getSubscribers',
  WIX_FORMS: 'wixForms',
  MULTI_STEP_FORM: 'multiStepForm',
};

export default {
  FORM_BUILDER_APP_ID,
  FormPreset,
  imageStyle,
  formStyle,
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  componentType: 'wysiwyg.viewer.components.FormContainer',
  CONTROLLER_TYPES,
};
