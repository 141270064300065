import { errorReporter } from '../../utils';
import { reParent } from './commonTasks';
import experiment from 'experiment';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SendBiFunction } from 'types/bi';
import type { SectionDescription, MobileComponent } from '../types';

export const reParentMobileChildren = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sections: SectionDescription[],
  sendBI: SendBiFunction,
): SectionDescription[] => {
  return sections.map((section: SectionDescription) => {
    if (section.mobile === null) {
      return section;
    }

    const mobileSectionRef = documentServices.components.getMobileRef(
      section.ref,
    );

    const reParentedChildren = section.mobile.children.map(
      (mobileComp: MobileComponent) => {
        const hasReParented = reParent(
          documentServices,
          pageRef,
          mobileSectionRef,
          mobileComp.ref,
          sendBI,
        );

        return {
          ...mobileComp,
          hasReParented,
        };
      },
    );

    section.childrenToKeepMobileParent?.forEach((mobileComp) => {
      reParent(
        documentServices,
        pageRef,
        mobileComp.keepParentRef,
        mobileComp.ref,
        sendBI,
      );
    });

    return {
      ...section,
      mobile: {
        ...section.mobile,
        children: reParentedChildren,
      },
    };
  });
};

export const reLayoutMobileChildren = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sections: SectionDescription[],
) => {
  sections.forEach((section: SectionDescription) => {
    const mobileSectionRef = documentServices.components.getMobileRef(
      section.ref,
    );

    if (section.mobile === null) {
      documentServices.mobile.hiddenComponents.hide(mobileSectionRef);

      return;
    }

    const sectionMobileTop = section.mobile.top;
    const sectionMobileHeight = Math.max(
      section.mobile.bottom - section.mobile.top,
      0,
    );

    documentServices.components.layout.update(mobileSectionRef, {
      y: sectionMobileTop,
      height: sectionMobileHeight,
    });

    section.mobile.children.forEach((mobileComp: MobileComponent) => {
      const mobileCompRef = mobileComp.ref;
      const prevLayout = mobileComp.layout;
      const nextY = mobileComp.hasReParented
        ? prevLayout.y - sectionMobileTop
        : prevLayout.y;

      documentServices.components.layout.update(mobileCompRef, {
        ...prevLayout,
        y: nextY,
      });
    });

    // update section layout after children layout updates due to DS rules that prohibit parent height less than children height
    documentServices.components.layout.update(mobileSectionRef, {
      height: sectionMobileHeight,
    });

    if (
      // eslint-disable-next-line lodash/prefer-is-nil
      sectionMobileTop === undefined ||
      sectionMobileTop === null ||
      sectionMobileHeight === undefined ||
      sectionMobileHeight === null
    ) {
      errorReporter(
        new Error(`mobile layout update error: value is not a number`),
      );
    }
  });
};

export function updateComponentsMobileHintsOnAPage(
  documentServices: DocumentServicesObject,
  pagesRef: CompRef,
) {
  const shouldCreateMobileHints = !experiment.isOpen(
    'se_blockUnblockRunMobileAlgoInSectionsMigration',
  );

  if (shouldCreateMobileHints) {
    // @ts-expect-error
    documentServices.mobileConversion.createMobileHintsForPageComponents(
      pagesRef.id,
    );
  }
}
