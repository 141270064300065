import type { EditorAPI } from '@/editorAPI';
import {
  interactions,
  stateMapperArgsSelectors,
  selection,
} from '@/stateManagement';
import { sections } from '@/util';
import * as sectionsSelectors from '../selectors';

const {
  rootSels,
  otherSels,
  selector: { createSelector },
} = stateMapperArgsSelectors;

const { dsReadSel, editorAPIMouseSel, editorAPISel, editorStateSel } = rootSels;

const { isSectionsEnabled } = sections;

export const canShowSectionBoxSelector = createSelector(
  [otherSels.isPinModeSel, editorStateSel, dsReadSel, editorAPISel],
  (isPinMode, editorState, dsRead, editorAPI) =>
    isSectionsEnabled() &&
    !isPinMode &&
    !dsRead?.pages.popupPages.isPopupOpened() &&
    !(
      interactions.selectors.isInInteractionMode(editorState) &&
      interactions.selectors.showInteractionModeControls(editorState)
    ) &&
    !editorAPI.componentFocusMode.isEnabled(),
);

export const shouldShowSelectedSectionBoxSel = createSelector(
  [
    canShowSectionBoxSelector,
    editorAPIMouseSel,
    sectionsSelectors.canSelectSectionLike,
  ],
  (canShowSectionBox, editorAPI, canSelectSection) => {
    return (
      canShowSectionBox &&
      canSelectSection &&
      Boolean(
        editorAPI.sections.getSelectedSection() ||
          editorAPI.sections.getFocusedSection(),
      ) &&
      shouldShowBoxWhenSectionActionsAreHovered(editorAPI)
    );
  },
);

export const shouldShowHoveredSectionBoxSel = createSelector(
  [canShowSectionBoxSelector, editorAPIMouseSel],
  (canShowSectionBox, editorAPI) => {
    if (!canShowSectionBox) return false;
    return Boolean(editorAPI.sections.getHoveredSection());
  },
);

const shouldShowBoxWhenSectionActionsAreHovered = (editorAPI: EditorAPI) => {
  const selectedSection = editorAPI.sections.getSelectedSectionLike();
  const hoveredSection = editorAPI.sections.getHoveredSectionLike();
  if (selectedSection?.id === hoveredSection?.id) return true;
  return !selection.selectors.shouldHideSelectionWhenStageActionsHovered(
    editorAPI,
  );
};
