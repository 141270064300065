import type { CompRef } from 'types/documentServices';
import type { EditorApiAgentTool } from '../types';

const userUISelectedComponentTool: EditorApiAgentTool = {
  name: 'userUISelectedComponent',
  func: (scope) => {
    const { editorAPI } = scope;
    const selectedComponents: CompRef[] =
      editorAPI.selection.getSelectedComponents();

    return {
      success: true,
      content: JSON.stringify(selectedComponents.map(({ id }) => id)),
    };
  },
};

export default userUISelectedComponentTool;
