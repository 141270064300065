import _ from 'lodash';

import { MigrationFlow } from '../../../types';

import { getDesktopSectionsDescriptions } from './getDesktopSectionsDescriptions';
import { getMobileSectionsDescriptions } from './getMobileSectionsDescriptions';
import { collocateDesktopSections } from './collocateDesktopSections';
import { collocateMobileSections } from './collocateMobileSections';
import { ensureNamesUniq } from './ensureNamesUniq';
import { removeLegacySections } from './removeLegacySections';
import { removeRedundantNesting } from './removeRedundantNesting';
import { resolveZIndexOverlapping } from './resolveZIndexOverlapping';
import { resolveSmallSections } from './resolveSmallSections';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SectionDescription, GroupingItem } from '../../types';

export function getSectionsToCreate(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    grouping,
    flow,
  }: {
    pageRef: CompRef;
    grouping: GroupingItem[];
    flow: MigrationFlow;
  },
): SectionDescription[] {
  const sectionsDescriptions = getDesktopSectionsDescriptions(
    documentServices,
    pageRef,
    grouping,
  );

  return _.flow(
    (sectionsDescriptions) =>
      collocateDesktopSections(documentServices, pageRef, sectionsDescriptions),
    (sectionsDescriptions) =>
      resolveZIndexOverlapping(documentServices, pageRef, sectionsDescriptions),
    resolveSmallSections,
    ensureNamesUniq,
    (sectionsDescriptions) =>
      getMobileSectionsDescriptions(
        documentServices,
        pageRef,
        sectionsDescriptions,
      ),
    (sectionsDescriptions) =>
      collocateMobileSections(documentServices, pageRef, sectionsDescriptions),
    (sectionsDescriptions) =>
      removeLegacySections(documentServices, sectionsDescriptions),
    (sectionsDescriptions) =>
      flow === MigrationFlow.Editor || flow === MigrationFlow.Editor2Fix
        ? sectionsDescriptions
        : removeRedundantNesting(
            documentServices,
            pageRef,
            sectionsDescriptions,
          ),
  )(sectionsDescriptions);
}
