// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import HiddenItem from '../hiddenItem/hiddenItem';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  hiddenComps: Array<{
    label: string;
    compId: string;
    compType: string;
    iconUrl?: string;
    svgName?: string;
    isEssential?: boolean;
    css?: AnyFixMe;
  }>;
  pageId: string;
  title: string;
  sectionType: string;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

export default class extends React.Component<Props> {
  static displayName = 'hiddenItemsContainer';

  static propTypes = {
    hiddenComps: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        compId: PropTypes.string.isRequired,
        compType: PropTypes.string.isRequired,
        iconUrl: PropTypes.string,
        svgName: PropTypes.string,
        isEssential: PropTypes.bool,
        css: PropTypes.object,
      }),
    ).isRequired,
    pageId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sectionType: PropTypes.string.isRequired,
    sendBi: PropTypes.func,
  };

  render() {
    return (
      <div className="hidden-items-container">
        <div className="container-header">{this.props.title}</div>
        <div className="container-comps">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.hiddenComps, (comp) => (
            <HiddenItem
              key={comp.compId}
              {
                /* eslint-disable-next-line you-dont-need-lodash-underscore/assign */
                ..._.assign(comp, {
                  pageId: this.props.pageId,
                  sectionType: this.props.sectionType,
                  sendBi: this.props.sendBi,
                })
              }
            />
          ))}
        </div>
      </div>
    );
  }
}
