import experiment from 'experiment';

import * as util from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import { HelpMenuItemsSlotPlacement } from '@/extensionSlots';
import { getHelpMenuAPI } from './helpMenuAPI';

import type { SendBiFunction } from 'types/bi';
import type { IMenuBarListItem } from '../../types';
import type {
  HelpMenuAPIOwnProps,
  HelpMenuAPIStateProps,
  HelpMenuAPIDispatchProps,
} from './helpMenuAPI';
import type { MenuBarItemsDispatchProps } from '../menuStructure';

const { WIDGET_ID } = constants.LIVE_CHAT;
const { HELP_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

const HELP_CHAT_CONDITION = () =>
  util.chatManager.isAnswerWidgetInitialized() &&
  experiment.isOpen('se_hideUnifiedChat');

export interface HelpListItemsOwnProps extends HelpMenuAPIOwnProps {}
export interface HelpListItemsStateProps extends HelpMenuAPIStateProps {
  inInteractionMode: boolean;
  hasNewReleasesNotification: boolean;
  isChatVisible: boolean;
  isChatLoaded: boolean;
  isSiteMigratedFromAdi: boolean;
}
export interface HelpListItemsDispatchProps extends HelpMenuAPIDispatchProps {
  sendBi: SendBiFunction;
}

export interface HelpListItemsProps
  extends HelpListItemsOwnProps,
    HelpListItemsStateProps,
    HelpListItemsDispatchProps,
    MenuBarItemsDispatchProps {}

export const getHelpListItemsByProps = (
  props: HelpListItemsProps,
): IMenuBarListItem[] => {
  const helpMenuAPI = getHelpMenuAPI(props);
  const tourEnabled =
    util.tours.isWelcomeTourEnabled() ||
    util.workspace.isNewWorkspaceFTETourEnabled();
  const { isAllowed } = props;

  return [
    {
      key: HELP_MENU_ITEMS.HELP_EDITOR_SEARCH,
      automationId: 'list-item-help-editor-search',
      leftTitle: 'TOPBAR_HELP_EDITOR_SEARCH',
      rightTitle: isAllowed('top-bar_menu-help-search.interactive')
        ? 'TOPBAR_HELP_EDITOR_SEARCH_PROMOTION_TITLE'
        : 'TOPBAR_HELP_EDITOR_SEARCH_PROMOTION_TITLE_DISABLED',
      description: isAllowed('top-bar_menu-help-search.interactive')
        ? 'TOPBAR_HELP_EDITOR_SEARCH_PROMOTION_TEXT'
        : 'TOPBAR_HELP_EDITOR_SEARCH_PROMOTION_TEXT_DISABLED',
      symbolName: isNewWorkspace
        ? 'search_NewWorkspace'
        : 'menubarHelpCenterPromotion',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_EDITOR_SEARCH]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'search_the_editor',
        });
      },
      isDisabled: !isAllowed('top-bar_menu-help-search.interactive'),
      condition: () => isAllowed('top-bar_menu-help-search.visible'),
    },
    {
      key: HELP_MENU_ITEMS.HELP_CENTER,
      automationId: 'list-item-help-center',
      leftTitle: 'TOPBAR_HELP_EDITOR_HELP',
      rightTitle: 'TOPBAR_HELP_CENTER_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_CENTER_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'helpCenter_NewWorkspace'
        : 'menubarHelpCenterPromotion',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_CENTER]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help_center',
        });
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_WIX_LEARN,
      automationId: 'list-item-wix-learn',
      leftTitle: 'TOPBAR_HELP_LESSONS',
      rightTitle: 'TOPBAR_HELP_LESSONS_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_LESSONS_PROMOTION_TEXT',
      symbolName: 'wixLearnVideoLessons',
      condition() {
        return experiment.isOpen('se_WixLearnInHelpMenu');
      },
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_WIX_LEARN]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'video_lessons',
        });
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_ADI_TO_EDITOR_TOUR,
      automationId: 'list-item-help-adiToEditor-tour',
      leftTitle: 'TOPBAR_HELP_TOUR',
      rightTitle: 'TOPBAR_HELP_TOUR_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_TOUR_PROMOTION_TEXT',
      symbolName: 'welcomeTour_NewWorkspace',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_ADI_TO_EDITOR_TOUR]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help-adiToEditor-tour',
        });
      },
      isDisabled: !isAllowed(
        'top-bar_menu-help-adi-to-editor-tour.interactive',
      ),
      condition() {
        return (
          (props.isSiteMigratedFromAdi ||
            experiment.isOpen('se_sectionsMigrationFromAdiFTEForQA')) &&
          isAllowed('top-bar_menu-help-adi-to-editor-tour.visible')
        );
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_EDITOR_TO_EDITOR_TOUR,
      automationId: 'list-item-help-editorToEditor-tour',
      leftTitle: 'TOPBAR_HELP_TOUR',
      rightTitle: 'TOPBAR_HELP_TOUR_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_TOUR_PROMOTION_TEXT',
      symbolName: 'welcomeTour_NewWorkspace',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_EDITOR_TO_EDITOR_TOUR]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help-editorToEditor-tour',
        });
      },
      isDisabled: !isAllowed(
        'top-bar_menu-help-editor-to-editor-tour.interactive',
      ),
      condition() {
        const isSectionsEnabled =
          experiment.isOpen('se_sectionsMigrationEditor') &&
          util.sections.isSectionsEnabled();
        const isSectionsMigration = experiment.isOpen(
          'se_sectionsMigrationEditorFTEForQA',
        );
        return (
          (isSectionsEnabled || isSectionsMigration) &&
          isAllowed('top-bar_menu-help-editor-to-editor-tour.visible')
        );
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_EXTENSION_SLOT,
      isExtensionSlot: true,
      extensionSlotPlacementComponent: HelpMenuItemsSlotPlacement,
      orderedExtensionsIds: [
        /* 'replace-by-real-help-home-extension-id' */
      ],
    },
    {
      isDisabled: props.inInteractionMode,
      key: HELP_MENU_ITEMS.HELP_KB_SHORTCUTS,
      automationId: 'list-item-kb-shortcuts',
      leftTitle: 'TOPBAR_HELP_KB_SHORTCUTS',
      rightTitle: 'TOPBAR_HELP_QUESTION_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_QUESTION_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'keyboardShortcuts_NewWorkspace'
        : 'menubarKeyboardShortcuts',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_KB_SHORTCUTS]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help_kb_shortcuts',
        });
      },
      condition: () => isAllowed('top-bar_menu-help-kb-shortcuts.visible'),
    },
    {
      key: HELP_MENU_ITEMS.HELP_VIDEOS_SEPARATOR,
      isSeparator: true,
    },
    {
      key: HELP_MENU_ITEMS.HELP_WELCOME_TOUR,
      automationId: 'list-item-help-welcome-tour',
      leftTitle: 'Topbar_Help_First-Time-Tour',
      rightTitle: 'Topbar_Help_First-Time-Tour_Promotion_Title',
      description: 'Topbar_Help_First-Time-Tour_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'welcomeTour_NewWorkspace'
        : 'menubarWelcomeTour',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_WELCOME_TOUR]();
      },
      isDisabled: !isAllowed('top-bar_menu-help-welcome-tour.interactive'),
      condition() {
        return (
          tourEnabled && isAllowed('top-bar_menu-help-welcome-tour.visible')
        );
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_VIDEOS,
      automationId: 'list-item-help-videos',
      leftTitle: 'TOPBAR_HELP_HOWTO_VIDEOS',
      rightTitle: 'TOPBAR_HELP_HOWTO_VIDEOS_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_HOWTO_VIDEOS_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'helpVideos_NewWorkspace'
        : 'menubarStepByStepVideo',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_VIDEOS]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help_videos',
        });
      },
      isDisabled: !isAllowed('top-bar_menu-help-videos.interactive'),
      condition() {
        return experiment.isOpen('se_video_help_top_bar');
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_CONNECT_DOMAIN,
      automationId: 'list-item-help-connect-domain',
      leftTitle: 'TOPBAR_HELP_CONNECT_DOMAIN',
      rightTitle: 'TOPBAR_HELP_CONNECT_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_CONNECT_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'connectDomain_NewWorkspace'
        : 'menubarConnectDomain',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_CONNECT_DOMAIN]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help_connect_domain',
        });
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_SEO,
      automationId: 'list-item-help-seo',
      leftTitle: 'TOPBAR_HELP_SEO',
      rightTitle: 'TOPBAR_HELP_SEO_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_SEO_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'seo_NewWorkspace'
        : 'menubarGettingFoundOnlineSeo',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_SEO]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help_seo',
        });
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_NEW_RELEASES,
      automationId: 'list-item-help-new-releases',
      hasNotification:
        props.hasNewReleasesNotification &&
        isAllowed('top-bar_menu-help-new-releases.interactive'),
      leftTitle: 'Topbar_Help_NewReleases_Label',
      rightTitle: isAllowed('top-bar_menu-help-new-releases.interactive')
        ? 'Topbar_Help_NewReleases_Promotion_Title'
        : 'TOPBAR_HELP_NewReleases_Promotion_Title_Disabled',
      description: isAllowed('top-bar_menu-help-new-releases.interactive')
        ? 'Topbar_Help_NewReleases_Promotion_Text'
        : 'TOPBAR_HELP_NewReleases_Promotion_Text_Disabled',
      symbolName: isNewWorkspace
        ? 'newReleases_NewWorkspace'
        : 'menubarNewReleases',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_NEW_RELEASES]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help-new-releases',
        });
      },
      isDisabled: !isAllowed('top-bar_menu-help-new-releases.interactive'),
      condition: () => isAllowed('top-bar_menu-help-new-releases.visible'),
    },
    {
      isSeparator: true,
    },
    {
      key: HELP_MENU_ITEMS.HELP_TERMS_OF_USE,
      automationId: 'list-item-terms-of-use',
      leftTitle: 'TOPBAR_HELP_TERMS_OF_USE',
      rightTitle: 'TOPBAR_HELP_TERMS_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_TERMS_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'termsOfUse_NewWorkspace'
        : 'menubarTermsOfUse',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_TERMS_OF_USE]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'terms_of_use',
        });
      },
    },
    {
      key: HELP_MENU_ITEMS.HELP_PRIVACY_POLICY,
      automationId: 'list-item-privacy-policy',
      leftTitle: 'TOPBAR_HELP_PRIVACY_POLICY',
      rightTitle: 'TOPBAR_HELP_PRIVACY_PROMOTION_TITLE',
      description: 'TOPBAR_HELP_PRIVACY_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'privacyPolicy_NewWorkspace'
        : 'menubarPrivacyPolicy',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_PRIVACY_POLICY]();
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'privacy_policy',
        });
      },
    },
    {
      isSeparator: true,
      condition: HELP_CHAT_CONDITION,
    },
    {
      key: HELP_MENU_ITEMS.HELP_CHAT,
      automationId: 'list-item-chat-panel',
      isCheckbox: true,
      checkboxValue: props.isChatVisible,
      isDisabled: !props.isChatLoaded,
      leftTitle: 'Topbar_Help_LiveChat_Label',
      rightTitle: 'Topbar_Help_LiveChat_Promotion_Title',
      description: 'Topbar_Help_LiveChat_Promotion_Text',
      symbolName: 'descriptive_Editor_Top Bar_Chat',
      onClick() {
        helpMenuAPI[HELP_MENU_ITEMS.HELP_CHAT]();
        props.sendBi(coreBi.events.liveChat.open_widget, {
          open_method: 'click',
          current_platform: 'Editor',
          lang: 'en',
          module_name: 'CHAT',
          source_name: `1_${WIDGET_ID}`,
          referral_url: window.location.href,
        });
        props.sendBi(coreBi.events.topbar.top_bar_HELP_menu_click, {
          category: 'help_live_chat',
        });
      },
      condition: HELP_CHAT_CONDITION,
    },
  ];
};
