import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionItem, CustomScroll } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getRecommendedToolDataHook } from '../../automationIds';
import { BI_EVENTS_IDS } from '../../biEvents';
import { Section } from '../Section/Section';

import type { RecommendedTool } from '../../services/recommendedTools/RecommendedTools';
import type { BiEventFields } from 'types/bi';

import styles from './RecommendedTools.scss';

interface RecommendedToolsProps {
  tools: RecommendedTool[];
  sendBi: (evid: number, biParams: BiEventFields) => void;
}

export const RecommendedTools: React.FC<RecommendedToolsProps> = ({
  tools,
  sendBi,
}) => {
  const [translate] = useTranslation();

  const renderIllustration = (tool: RecommendedTool) => {
    if (tool.imageSrc) {
      return (
        <img
          src={tool.imageSrc}
          alt={tool.name}
          className={styles.illustration}
        />
      );
    }

    return (
      <Symbol
        name="plasterMedium"
        customDimensions={{ width: 48, height: 48 }}
      />
    );
  };

  const onToolClick = (tool: RecommendedTool) => {
    tool.executeAction();
    sendBi(BI_EVENTS_IDS.RECOMMENDED_TOOL_CLICKED, {
      tool: translate(tool.name, { lng: 'en' }),
    });
  };

  return (
    <CustomScroll flex="1">
      <Section
        heading={translate(
          'App_Manager_Ecomm_Segment_Main_Panel_Section3_Header',
        )}
      >
        <TransitionGroup>
          {tools.map((tool) => (
            <CSSTransition
              key={tool.metadata.offerId}
              timeout={500}
              addEndListener={(node, done) =>
                node.addEventListener('transitionend', done, false)
              }
              classNames={{
                exit: styles.fadeAndSlideOut,
                exitActive: styles.fadeAndSlideOutActive,
              }}
            >
              <ActionItem
                key={tool.metadata.offerId}
                title={tool.name}
                illustration={renderIllustration(tool)}
                description={tool.description}
                tooltipContent="tpa_add_panel_add_to_site"
                actionIcon={<Symbol name="arrowRightThin" />}
                onClick={() => onToolClick(tool)}
                dataHook={getRecommendedToolDataHook(tool.metadata.offerId)}
                className={styles.item}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Section>
    </CustomScroll>
  );
};
