import type { EntryPoint } from '@/apilib';

import { EditorAPIKey } from '@/apis';

import * as ascendSuperAppButton from './button/ascendSuperAppButton';
import ascendMenuPanel from './appMenu/panels/ascendMenuPanel/ascendMenuPanel';
import { AscendApiKey } from './publicApiKey';
import { createAscendApi } from './ascendPublicApi';

export const AscendEntrypoint: EntryPoint = {
  name: 'AscendApi',
  declareAPIs: () => [AscendApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(AscendApiKey, () => createAscendApi(shell));
  },
};

function register(editorAPI: AnyFixMe) {
  ascendSuperAppButton.register(editorAPI);
}

export type { AscendApi } from './ascendPublicApi';

export { register, ascendMenuPanel as panel };
