'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_contactform",
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/dynamicContactFormsSection_en/dynamicContactFormsSection_en.png",
            "imageHover": null,
            "items": [{
                "id": "Contact_Dynamic_Contact_Forms_(New)_1",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.OverlappingButtonSkin",
                    "layout": {
                        "width": 608,
                        "height": 317,
                        "x": 20,
                        "y": 27,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Submit",
                        "successMessage": "Thanks! Message sent.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "What's Your Name?",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "What's Your Email?",
                            "required": true
                        }, {
                            "name": "phone",
                            "inputType": "tel",
                            "displayLabel": "What's Your Number?",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "How Can We Help?",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#20CE88",
                                "bg2": "#141414",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#34D1B6",
                                "brw": "0",
                                "brw2": "0",
                                "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_85-heavy1475544",
                                "fnt2": "normal normal normal 25px/1.4em cookie",
                                "labelTxt": "#141414",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#141414",
                                "txt2": "#FCFCFC",
                                "txtError": "#8b0000",
                                "txtSuccess": "#bada55"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "brw2": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.OverlappingButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0xg"
                },
                "preset": {"rect": {"width": 324, "height": 185, "x": 0, "y": 0}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_2",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.FullWidthButtonSkin",
                    "layout": {
                        "width": 608,
                        "height": 336,
                        "x": 20,
                        "y": 412,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Get In Touch",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Your Name",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Your Email",
                            "required": true
                        }, {
                            "name": "phone",
                            "inputType": "tel",
                            "displayLabel": "Your Phone No.",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Ask us anything...",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#FFD45A",
                                "bg2": "#112A4B",
                                "boxShadowToggleOn-shd": "true",
                                "brw": "0",
                                "fnt": "normal normal normal 20px/1.4em belinda-w00-regular",
                                "fnt2": "normal normal normal 17px/1.4em avenir-lt-w01_35-light1475496",
                                "shd": "0 0px 0px transparent",
                                "txt1": "#112A4B",
                                "txt3": "#112A4B",
                                "txt2": "#FFFFFF",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt3": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.FullWidthButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0w1"
                },
                "preset": {"rect": {"width": 324, "height": 194, "x": 0, "y": 185}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_3",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.FullWidthButtonSkin",
                    "layout": {
                        "width": 608,
                        "height": 295,
                        "x": 20,
                        "y": 788,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Submit Now",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Full Name",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email Address",
                            "required": true
                        }, {
                            "name": "phone",
                            "inputType": "tel",
                            "displayLabel": "Phone Number",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Write Your Request",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "alpha-brd2": "1",
                                "brd": "#141414",
                                "brd2": "#141414",
                                "fnt": "normal normal normal 18px/1.4em baskervillemtw01-smbdit",
                                "fnt2": "normal normal normal 18px/1.4em baskervillemtw01-smbdit",
                                "txt4": "#141414",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "alpha-brd2": "value",
                                "brd": "value",
                                "brd2": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "txt1": "value",
                                "txt3": "value",
                                "txt4": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.LineOnlySkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0w3"
                },
                "preset": {"rect": {"width": 324, "height": 167, "x": 0, "y": 379}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin",
                    "layout": {
                        "width": 618,
                        "height": 39,
                        "x": 15,
                        "y": 1131,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Reserve",
                        "successMessage": "Thanks! Message sent.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Contact Email",
                            "required": true
                        }, {"name": "subject", "inputType": "text", "displayLabel": "Subject", "required": false}]
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": true},
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#B5F3E8",
                                "bg2": "#1D2CF3",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "0",
                                "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                "fnt2": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                "labelTxt": "#414141",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#FCFCFC",
                                "txt2": "#FFFFFF",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0xb"
                },
                "preset": {"rect": {"width": 324, "height": 61, "x": 0, "y": 546}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_5",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.FieldAnimationSkin",
                    "layout": {
                        "width": 608,
                        "height": 323,
                        "x": 20,
                        "y": 1244,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "RSVP",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": " Your name",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Your email",
                            "required": true
                        }, {
                            "name": "customField",
                            "inputType": "text",
                            "displayLabel": "Can you come?",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Special requests?",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-bg3": "1",
                                "bg1": "#566FB8",
                                "bg2": "#566FB8",
                                "bg3": "#FFCBAA",
                                "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                "fnt2": "normal normal normal 15px/1.4em libre+baskerville",
                                "txt1": "#FCFCFC",
                                "txt2": "#FFFFFF",
                                "txt4": "#354A81",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-bg3": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "bg3": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt4": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.FieldAnimationSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0w6"
                },
                "preset": {"rect": {"width": 324, "height": 195, "x": 0, "y": 607}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_6",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.FieldAnimationSkin",
                    "layout": {
                        "width": 274,
                        "height": 323,
                        "x": 20,
                        "y": 1634,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Reply",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Name",
                            "required": false
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email",
                            "required": true
                        }, {
                            "name": "customField",
                            "inputType": "text",
                            "displayLabel": "How was the event?",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "We'd love to know more...",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-bg3": "1",
                                "bg1": "#FCFCFC",
                                "bg2": "#141414",
                                "bg3": "#566FB8",
                                "fnt": "normal normal normal 15px/1.4em times+new+roman",
                                "fnt2": "italic normal normal 15px/1.4em times+new+roman",
                                "txt1": "#E8E6E6",
                                "txt2": "#FCFCFC",
                                "txt4": "#FFFFFF",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-bg3": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "bg3": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt4": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.FieldAnimationSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0wg"
                },
                "preset": {"rect": {"width": 162, "height": 212, "x": 0, "y": 802}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_7",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.OverlappingButtonSkin",
                    "layout": {
                        "width": 272,
                        "height": 370,
                        "x": 356,
                        "y": 1634,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Apply",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Name",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email",
                            "required": true
                        }, {
                            "name": "address",
                            "inputType": "text",
                            "displayLabel": "Street Address",
                            "required": false
                        }, {
                            "name": "address",
                            "inputType": "text",
                            "displayLabel": "City",
                            "required": false
                        }, {"name": "message", "inputType": "text", "displayLabel": "Add a message", "required": false}]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#FCFCFC",
                                "bg2": "#FCFCFC",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FF4F4F",
                                "brw": "2",
                                "brw2": "2",
                                "fnt": "normal normal normal 14px/1.4em futura-lt-w01-book",
                                "fnt2": "italic normal normal 18px/1.4em futura-lt-w01-book",
                                "labelTxt": "#0C4266",
                                "shd": "0 0px 0px transparent",
                                "txt2": "#0C4266",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "brw2": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.OverlappingButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0wj"
                },
                "preset": {"rect": {"width": 162, "height": 212, "x": 162, "y": 802}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_8",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.VerticalForm",
                    "layout": {
                        "width": 608,
                        "height": 387,
                        "x": 20,
                        "y": 2062,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Send",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "What's your name?",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "What's your email?",
                            "required": true
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "What's on your mind? Let us know",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#E6DECA",
                                "bg2": "#7B736F",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#141414",
                                "brw": "0",
                                "fnt": "normal normal normal 20px/1.4em nimbus-sans-tw01con",
                                "fnt2": "normal normal normal 20px/1.4em nimbus-sans-tw01con",
                                "rd": "0px 0px 10px 0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#000000",
                                "txt2": "#FCFCFC",
                                "txt3": "#7B736F",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt3": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.VerticalForm"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0wn"
                },
                "preset": {"rect": {"width": 324, "height": 233, "x": 0, "y": 1014}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_9",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft",
                    "layout": {
                        "width": 613,
                        "height": 297,
                        "x": 15,
                        "y": 2527,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Get in touch",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Name:",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email:",
                            "required": true
                        }, {
                            "name": "phone",
                            "inputType": "tel",
                            "displayLabel": "Phone:",
                            "required": false
                        }, {"name": "message", "inputType": "text", "displayLabel": "Message:", "required": false}]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#E6E6E6",
                                "bg2": "#112A4B",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#112A4B",
                                "brw": "0",
                                "fnt": "normal normal normal 15px/1.4em noticia+text",
                                "fnt2": "normal normal normal 17px/1.4em noticia+text",
                                "rd": "5px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#112A4B",
                                "txt2": "#FCFCFC",
                                "txt3": "#112A4B",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt3": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0wp"
                },
                "preset": {"rect": {"width": 324, "height": 181, "x": 0, "y": 1247}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_10",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.LineOnlySkin",
                    "layout": {
                        "width": 274,
                        "height": 321,
                        "x": 20,
                        "y": 2888,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Order Now",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Your Name",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Your Email",
                            "required": true
                        }, {
                            "name": "phone",
                            "inputType": "tel",
                            "displayLabel": "Your Phone",
                            "required": false
                        }, {
                            "name": "customField",
                            "inputType": "text",
                            "displayLabel": "Which Pizza?",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Any Special Requests?",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "alpha-brd2": "1",
                                "brd": "#141414",
                                "brd2": "#141414",
                                "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                "fnt2": "italic normal normal 15px/1.4em helvetica-w01-light",
                                "txt1": "#141414",
                                "txt3": "#141414",
                                "txt4": "#141414",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "alpha-brd2": "value",
                                "brd": "value",
                                "brd2": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "txt1": "value",
                                "txt3": "value",
                                "txt4": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.LineOnlySkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0ws"
                },
                "preset": {"rect": {"width": 162, "height": 197, "x": 0, "y": 1428}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_11",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.FieldAnimationSkin",
                    "layout": {
                        "width": 274,
                        "height": 323,
                        "x": 354,
                        "y": 2896,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Send",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Name",
                            "required": false
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email",
                            "required": true
                        }, {
                            "name": "customField",
                            "inputType": "text",
                            "displayLabel": "Age",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Write your answer here",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-bg3": "1",
                                "bg1": "#354A81",
                                "bg2": "#B5F3E8",
                                "bg3": "#B5F3E8",
                                "fnt": "normal normal normal 14px/1.4em proxima-n-w01-reg",
                                "fnt2": "italic normal normal 15px/1.4em proxima-n-w01-reg",
                                "txt1": "#B0E8E3",
                                "txt2": "#354A81",
                                "txt4": "#354A81",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-bg3": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "bg3": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt4": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.FieldAnimationSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0wv"
                },
                "preset": {"rect": {"width": 162, "height": 197, "x": 162, "y": 1428}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_12",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.VerticalForm",
                    "layout": {
                        "width": 608,
                        "height": 333,
                        "x": 20,
                        "y": 3278,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Send It",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "YOUR NAME",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "YOUR EMAIL",
                            "required": true
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "TELL US HOW WE'RE DOING",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#FF4F4F",
                                "bg2": "#141414",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#141414",
                                "brw": "0",
                                "fnt": "normal normal 700 14px/1.4em raleway",
                                "fnt2": "normal normal normal 14px/1.4em raleway",
                                "rd": "0px 0px 0px 0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#141414",
                                "txt2": "#FCFCFC",
                                "txt3": "#141414",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txt3": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.VerticalForm"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0wx"
                },
                "preset": {"rect": {"width": 324, "height": 196, "x": 0, "y": 1625}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_13",
                "structure": {
                    "type": "Component",
                    "skin": "contactform.OverlappingButtonSkin",
                    "layout": {
                        "width": 608,
                        "height": 333,
                        "x": 20,
                        "y": 3711,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Get a Quote",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Name",
                            "required": true
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email",
                            "required": true
                        }, {
                            "name": "customField",
                            "inputType": "text",
                            "displayLabel": "Business",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Tell us more about your project",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "0",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#FCFCFC",
                                "bg2": "#AE9A64",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#AE9A64",
                                "brw": "2",
                                "brw2": "2",
                                "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_85-heavy1475544",
                                "fnt2": "normal normal normal 25px/1.4em cookie",
                                "labelTxt": "#AE9A64",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt2": "#FFFFFF",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "brw2": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "contactform.OverlappingButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0x1"
                },
                "preset": {"rect": {"width": 324, "height": 192, "x": 0, "y": 1821}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_14",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.DefaultContactForm",
                    "layout": {
                        "width": 283,
                        "height": 182,
                        "x": 20,
                        "y": 4100,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Order Now",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "Your Name",
                            "required": true
                        }, {
                            "name": "customField",
                            "inputType": "text",
                            "displayLabel": "Company",
                            "required": false
                        }, {
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Email",
                            "required": true
                        }, {
                            "name": "phone",
                            "inputType": "tel",
                            "displayLabel": "Phone",
                            "required": false
                        }, {
                            "name": "message",
                            "inputType": "text",
                            "displayLabel": "Place your order",
                            "required": false
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#A2A3E9",
                                "bg2": "#FCFCFC",
                                "boxShadowToggleOn-shd": "false",
                                "brw": "0",
                                "fnt": "normal normal normal 13px/1.4em futura-lt-w01-book",
                                "fnt2": "normal normal normal 16px/1.4em futura-lt-w01-book",
                                "labelTxt": "#FCFCFC",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#FCFCFC",
                                "txt2": "#A2A3E9",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.DefaultContactForm"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0x4"
                },
                "preset": {"rect": {"width": 162, "height": 175, "x": 0, "y": 2013}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_15",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.BasicContactFormSkin",
                    "layout": {
                        "width": 283,
                        "height": 270,
                        "x": 345,
                        "y": 4100,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Reserve",
                        "successMessage": "Success! Message received.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Contact Email",
                            "required": true
                        }, {
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "1st Guest",
                            "required": false
                        }, {
                            "name": "name",
                            "inputType": "text",
                            "displayLabel": "2nd Guest",
                            "required": false
                        }, {"name": "message", "inputType": "text", "displayLabel": "Add a Note", "required": false}]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#C7F7D8",
                                "bg2": "#C7F7D8",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "0",
                                "fnt": "normal normal normal 14px/1.4em futura-lt-w01-book",
                                "fnt2": "normal normal normal 15px/1.4em futura-lt-w01-book",
                                "labelTxt": "#4D4D4D",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt1": "#000000",
                                "txt2": "#000000",
                                "txtError": "#C71212",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.BasicContactFormSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0x7"
                },
                "preset": {"rect": {"width": 162, "height": 175, "x": 162, "y": 2013}, "tags": null}
            }, {
                "id": "Contact_Dynamic_Contact_Forms_(New)_16",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin",
                    "layout": {
                        "width": 618,
                        "height": 41,
                        "x": 15,
                        "y": 4426,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.DynamicContactForm",
                    "data": {
                        "type": "DynamicContactForm",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "toEmailAddress": "",
                        "bccEmailAddress": "",
                        "submitButtonLabel": "Reserve",
                        "successMessage": "Thanks! Message sent.",
                        "validationErrorMessage": "Please add required info.",
                        "errorMessage": "Please add a valid email.",
                        "textDirection": "left",
                        "onSubmitBehavior": "message",
                        "formName": "",
                        "dynamicFields": [{
                            "name": "email",
                            "inputType": "email",
                            "displayLabel": "Contact Email",
                            "required": true
                        }]
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg1": "1",
                                "alpha-bg2": "1",
                                "alpha-brd": "1",
                                "bg1": "#E8E6E6",
                                "bg2": "#B45AD3",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "0",
                                "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                "fnt2": "normal normal normal 15px/1.4em avenir-lt-w01_85-heavy1475544",
                                "labelTxt": "#a9a9a9",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txtError": "#C71212",
                                "txt2": "#FCFCFC",
                                "txtSuccess": "#67BD31"
                            },
                            "propertiesSource": {
                                "alpha-bg1": "value",
                                "alpha-bg2": "value",
                                "alpha-brd": "value",
                                "bg1": "value",
                                "bg2": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "fnt2": "value",
                                "labelTxt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt1": "value",
                                "txt2": "value",
                                "txtError": "value",
                                "txtSuccess": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.DynamicContactForm",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jb29w0yf"
                },
                "preset": {"rect": {"width": 324, "height": 61, "x": 0, "y": 2188}, "tags": null}
            }],
            "compTypes": ["wysiwyg.viewer.components.DynamicContactForm"]
        },
        "help": {"hide": false, "text": "add_section_info_custom"}
    }