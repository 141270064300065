// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import { CustomScroll } from '@wix/wix-base-ui';
import BlogSinglePostInfo from '../../../settings/tabs/subPages/blogSinglePostInfo';
import TpaProductPageInfo from '../../../settings/tabs/subPages/tpaProductPageInfo';
import TpaCartPageInfo from '../../../settings/tabs/subPages/tpaCartPageInfo';
import TpaThankYouPageInfo from '../../../settings/tabs/subPages/tpaThankYouPageInfo';
import PageSettingsMobileCommon from './pageSettingsMobileCommon';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'pageSettingsMobileSubpage',
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    actions: PropTypes.object,
    closeSettingsPanel: PropTypes.func.isRequired,
  },
  render() {
    const { pageData } = this.props.menuItem;
    const { blogTypeUtils } = core.utils;
    const { wixStoreTypeUtils } = core.utils;
    const editorAPI = this.getEditorAPI();

    return (
      <PageSettingsMobileCommon
        title={this.props.menuItem.label}
        closeSettingsPanel={this.props.closeSettingsPanel}
      >
        <CustomScroll>
          <div className="wixapps-subpage">
            {blogTypeUtils.isBlog(editorAPI, pageData) ? (
              <BlogSinglePostInfo
                key="page-settings-mobile-blog-single-post"
                {...this.props}
              />
            ) : null}
            {wixStoreTypeUtils.isWixStoreProductPage(pageData) ? (
              <TpaProductPageInfo
                key="page-settings-mobile-tpa-product-page"
                {...this.props}
              />
            ) : null}
            {wixStoreTypeUtils.isWixStoreCartPage(pageData) ? (
              <TpaCartPageInfo
                key="page-settings-mobile-tpa-cart-page"
                {...this.props}
              />
            ) : null}
            {wixStoreTypeUtils.isWixStoreThankYouPage(pageData) ? (
              <TpaThankYouPageInfo
                key="page-settings-mobile-tpa-thank-you-page"
                {...this.props}
              />
            ) : null}
          </div>
        </CustomScroll>
      </PageSettingsMobileCommon>
    );
  },
});
