// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import constants from '@/constants';
import React from 'react';
import * as notifications from '@/notifications';
import { RichText } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  name?: string;
  type: AnyFixMe;
  title?: string;
  message: string;
  linkAction?: {
    caption: string;
    onClick: FunctionFixMe;
  };
  close: FunctionFixMe;
  onClose: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'platformNotification';

  static propTypes = {
    name: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    type: PropTypes.oneOf(_.values(constants.NOTIFICATIONS.TYPES)).isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    linkAction: PropTypes.shape({
      caption: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    close: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  close = () => {
    this.props.onClose();
    this.props.close();
  };

  onLinkClicked = () => {
    this.props.linkAction.onClick();
    this.props.close();
  };

  render() {
    const { linkAction } = this.props;

    return (
      <notifications.notificationFrame
        type={this.props.type}
        close={this.close}
      >
        <div className="platform-notification-content">
          {this.props.title ? (
            <RichText
              key="notification-title"
              type="T07"
              className="notification-title"
            >
              {this.props.title}
            </RichText>
          ) : null}
          <RichText>
            <p className="notification-message">{this.props.message}</p>
          </RichText>

          {linkAction?.caption ? (
            <RichText>
              <a
                onClick={this.onLinkClicked}
                key="notification-link-action"
                className="notification-link"
              >
                {linkAction.caption}
              </a>
            </RichText>
          ) : null}
        </div>
      </notifications.notificationFrame>
    );
  }
}
