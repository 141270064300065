import _ from 'lodash';
import experiment from 'experiment';

import constants from '@/constants';
import { tpaUtils } from '@/util';
import { settings } from '@/tpa';
import {
  EditorRestrictionsApiKey,
  QuickEditApiKey,
  SwitchLayoutApiKey,
} from '@/apis';
import type {
  AppManagerOpenByURLProps,
  DesktopPanels,
  MediaPanelOpenByURLProps,
  MobilePanels,
  Panels,
} from './openPanelByURL.types';
import type { EditorAPI } from '@/editorAPI';

const WIX_STORE_APP_DEF_ID = tpaUtils.getStoresAppDefId();

const panels: DesktopPanels = {
  addPagePanel: {
    name: 'addPreset.panels.addPagePanel',
    propsGenerator() {
      return {
        origin: 'deepLink',
      };
    },
  },
  addSectionPanel: {
    name: constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
    propsGenerator(category: string) {
      return {
        origin: 'deepLink',
        category,
      };
    },
  },

  newAddPanel: {
    name: constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
    shouldOpenPanelByRestrictions(editorAPI) {
      const editorRestrictionsApi = editorAPI.host.getAPI(
        EditorRestrictionsApiKey,
      );
      return editorRestrictionsApi.allowed('left-bar_add.interactive');
    },
    propsGenerator(category, sectionId) {
      return {
        category,
        sectionId,
      };
    },
  },
  siteSettingsPanel: {
    name: 'rEditor.panels.siteSettingsPanel',
    propsGenerator(tabName, category, ...extraParams) {
      return {
        extraParams,
        category,
        tab: tabName,
      };
    },
  },
  pagesPanel: {
    name: 'pages.pagesPanel',
    propsGenerator(settingsTabType) {
      return {
        initialSettingsTabType: settingsTabType,
        origin: 'browser_url',
      };
    },
  },
  feedMe: {
    name: 'panels.focusPanels.feedbackPanel',
  },
  blog: {
    name: 'blog.menuPanel',
  },
  newBlog: {
    name: 'blog.newMenuPanel',
  },
  background: {
    name: 'rEditor.panels.backgroundPanel',
  },
  wixStore: {
    name: 'wixStore.panels.wixStorePanel',
    shouldOpenPanelByRestrictions(editorAPI) {
      return editorAPI.dsRead.tpa.app.isInstalled(WIX_STORE_APP_DEF_ID);
    },
  },
  mobileFriendlyPanel: {
    name: 'panels.focusPanels.mobileFriendlyPanel',
  },
  media: {
    name: 'rEditor.panels.mediaManagerPanel',
    propsGenerator(...panelProps) {
      return panelProps.reduce((props, panelProp) => {
        const [propName, propValue] = panelProp.split('-');

        switch (propName) {
          case 'withmediastudio':
            return { ...props, shouldOpenMediaStudioOnMount: true };
          case 'withvideomaker':
            return { ...props, shouldOpenVideoMakerOnMount: true };
          case 'withmediamanager':
            const withMediaManagerProps: {
              shouldOpenMediaManagerOnMount: boolean;
              mediaManagerOpenPathSourceOnMount?: string;
            } = {
              shouldOpenMediaManagerOnMount: true,
            };

            if (propValue) {
              withMediaManagerProps.mediaManagerOpenPathSourceOnMount =
                propValue;
            }

            return { ...props, ...withMediaManagerProps };
          default:
            return props;
        }
      }, {} as MediaPanelOpenByURLProps);
    },
  },
  appManager: {
    name: 'appManager.appManagerPanel',
    propsGenerator(selectedApp: string): AppManagerOpenByURLProps {
      return {
        selectedAppFromUrl: selectedApp,
      };
    },
  },
  tpaSettings: {
    name: constants.PANEL_NAMES.TPA_SETTINGS_PANEL,
    openFunction: (editorAPI) => {
      const tpaComponent = editorAPI.components
        .getAllComponents_DEPRECATED_BAD_PERFORMANCE(
          editorAPI.pages.getFocusedPageId(),
        )
        .filter(
          (compRef) =>
            editorAPI.components.getPage(compRef).id !== 'masterPage',
        )
        .find((compRef) =>
          editorAPI.isTpa(editorAPI.components.getType(compRef)),
        );
      editorAPI.selection.addComponentToSelectionByRef(tpaComponent);
      settings.open(editorAPI, [tpaComponent]);
    },
    propsGenerator() {
      return {
        origin: 'deepLink',
      };
    },
  },
  quickEdit: {
    name: 'quickEdit',
    openFunction: (editorAPI: EditorAPI) => {
      const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
      if (quickEditAPI.isQuickEditAvailable()) {
        const [firstSection] =
          editorAPI.sections.getPageSectionsSortedByStageOrder(
            editorAPI.pages.getFocusedPage(),
          );
        if (firstSection) {
          quickEditAPI.openPanel({
            rootControlCompRef: firstSection,
            origin: 'deepLink',
          });
        }
      }
    },
  },
  switchLayout: {
    name: 'switchLayout',
    openFunction: (editorAPI: EditorAPI) => {
      const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
      if (switchLayoutAPI.isSwitchLayoutAvailable()) {
        const [firstSection] =
          editorAPI.sections.getPageSectionsSortedByStageOrder(
            editorAPI.pages.getFocusedPage(),
          );
        if (firstSection) {
          switchLayoutAPI.openSwitchLayoutPanel({
            sectionRef: firstSection,
            origin: 'deepLink',
          });
        }
      }
    },
  },
  siteGeneratorPlayground: {
    name: 'designerPanels.siteGeneratorPlaygroundPanel',
    propsGenerator(initialLayoutFamilyName: string, initialKitName: string) {
      return {
        initialLayoutFamilyName,
        initialKitName,
      };
    },
  },
};

const mobilePanels: MobilePanels = {
  //!! if you're adding new panel, add it to renderIframe/shouldLoadMobile.ts
  mobileHidden: {
    name: 'mobileEditor.hiddenItemsPanel',
  },
  mobileBackground: {
    name: 'rEditor.panels.backgroundPanel',
  },
  mobileSiteSettingsPanel: {
    name: 'rEditor.panels.siteSettingsPanel',
    propsGenerator(tabName) {
      return {
        tab: tabName,
      };
    },
  },
  mobileElements: {
    name: 'mobileEditor.mobileElementsPanel',
    propsGenerator(categoryID) {
      return {
        mobileElementId: categoryID,
      };
    },
  },
  mobileLayoutOptimizer: {
    name: 'mobileEditor.layoutOptimizerPanel',
  },
};

function getAllPanels() {
  const allPanels: Panels = _.merge({}, panels, mobilePanels);

  if (experiment.isOpen('se_customErrorPageOmniSetup')) {
    allPanels.customErrorPagePanel = {
      name: 'panels.focusPanels.customErrorPagePanel',
    };
  }
  return _.mapKeys(allPanels, function (value, key) {
    return key.toLowerCase();
  });
}

function getMobilePanels() {
  return _.mapKeys(mobilePanels, function (value, key) {
    return key.toLowerCase();
  });
}

export { getAllPanels, getMobilePanels };
