import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import { Composites, Illustration, RichText, Text } from '@wix/wix-base-ui';
import * as coreBi from '@/coreBi';

import * as core from '@/core';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as baseUI from '@/baseUI';
import * as panels from '@/panels';
import * as util from '@/util';
import { SafeInjectHtml } from '@/util';

import type { FailPanelMultiKeyStepDef } from '../../constants/savePublishErrorPanelData';

import styles from './failPanel.scss';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'failPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    titleKey: PropTypes.string.isRequired,
    description: PropTypes.string,
    stepsTitle: PropTypes.string,
    steps: PropTypes.array,
    helpMessage: PropTypes.string.isRequired,
    helpLinkMessage: PropTypes.string.isRequired,
    helpSuffix: PropTypes.string,
    headerHelpLink: PropTypes.string,
    helpLink: PropTypes.string.isRequired,
    errorCode: PropTypes.number,
    errorType: PropTypes.string,
    symbolName: PropTypes.string,
    errorDescription: PropTypes.object,
    biEvent: PropTypes.object,
    biParams: PropTypes.object,
    failHelpId: PropTypes.string,
    biConfirm: PropTypes.object,
    biHelp: PropTypes.object,
    biCancel: PropTypes.object,
    biOrigin: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    confirmLabel: PropTypes.string,
    panelName: PropTypes.string,
    translateArgs: PropTypes.object,
    hideSymbol: PropTypes.bool,
    dontShowAgainKey: PropTypes.string,
    onDontShowAgainChange: PropTypes.func,
    userPrefType: PropTypes.string,
    isNewWorkspace: PropTypes.bool,
    className: PropTypes.string,
  },
  getDefaultProps() {
    return {
      showSteps: true,
      showFooterText: true,
      panelName: 'savePublish.panels.common.failPanel',
      translateArgs: {},
    };
  },
  translateProp(prop: AnyFixMe) {
    return (
      this.props[prop] &&
      translate(this.props[prop], this.props.translateArgs[prop])
    );
  },
  getShortcuts() {
    const self = this;
    return {
      esc() {
        self.getEditorAPI().panelManager.closePanelByName(self.props.panelName);
        self.onCancel('esc');
      },
      enter() {
        self.getEditorAPI().panelManager.closePanelByName(self.props.panelName);
        self.onConfirm();
      },
    };
  },
  getErrorCodeMessage() {
    //eslint-disable-next-line lodash/prefer-is-nil
    return this.props.errorCode !== undefined && this.props.errorCode !== null
      ? ` ${translate('SAVE_PUBLISH_SITE_ERROR_CODE', {
          status_code: this.props.errorCode,
        })}`
      : '';
  },
  getFooterText() {
    if (this.props.errorCode === -41218) {
      return '';
    }
    return React.createElement(
      'p',
      { className: 'fail-panel-help-text' },
      React.createElement(
        'span',
        { className: 'text-part' },
        translate(this.props.helpMessage),
      ),
      React.createElement(
        'a',
        {
          onClick: this.openHelpLink,
          className: 'link-part',
        },
        translate(this.props.helpLinkMessage),
      ),
      this.props.helpSuffix
        ? React.createElement(
            'span',
            { className: 'help-suffix' },
            translate(this.props.helpSuffix),
          )
        : null,
      !this.props.errorDescription && this.props.errorCode
        ? React.createElement(
            'span',
            { className: 'error-code-part' },
            this.getErrorCodeMessage(),
          )
        : null,
    );
  },
  getConfirmLabel() {
    if (this.props.errorCode === -41218) {
      return 'PUBLISH_ERROR_SITE_DELETED_LINK';
    }
    return this.props.confirmLabel || 'SAVE_PUBLISH_ERROR_BUTTON_OK';
  },
  getBIActionTypeByErrorType() {
    const editorAPI = this.getEditorAPI();
    switch (this.props.errorType) {
      case editorAPI.dsRead.errors.save.NOT_LOGGED_IN:
        return 'logged_out';
      case editorAPI.dsRead.errors.save.USER_NOT_AUTHORIZED_FOR_SITE:
        return 'wrong_account';
      default:
        return 'other';
    }
  },
  onCancel(origin: AnyFixMe) {
    if (this.props.onCancel) {
      this.props.onCancel(origin);
    }
    if (this.props.biCancel) {
      this.getEditorAPI().bi.event(this.props.biCancel);
    }
  },
  onConfirm() {
    const editorAPI = this.getEditorAPI();
    if (this.props.biConfirm) {
      editorAPI.bi.event(this.props.biConfirm);
    }
    editorAPI.bi.event(coreBi.events.panels.login_releted_error_popup_click, {
      ctaName: 'ok',
      actionsType: this.getBIActionTypeByErrorType(),
      action: this.props.biOrigin || 'other',
    });

    if (this.props.errorCode === -41218) {
      window.location.href = 'https://wix.com/';
      return;
    }

    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  },
  openHelpLink() {
    const editorAPI = this.getEditorAPI();
    if (this.props.biHelp) {
      editorAPI.bi.event(this.props.biHelp);
    }

    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(
      this.props.helpLink,
      null,
      biHelpParams,
    );
  },
  sendPanelLoadedBiEvent() {
    if (this.props.biEvent && this.props.biParams) {
      this.getEditorAPI().bi.event(this.props.biEvent, this.props.biParams);
    }
  },
  componentDidMount() {
    this.sendPanelLoadedBiEvent();
  },
  getMultiStepKeyValue(
    multiKeyStep: FailPanelMultiKeyStepDef[],
  ): React.Component[] {
    return multiKeyStep.reduce((acc, { isLink, key, translationLink }) => {
      const translationValue = translate(key);
      const URL = translationLink
        ? translate(translationLink)
        : `https://${translationValue}`;
      acc.push(
        isLink ? (
          <a
            key={key}
            onClick={() => {
              this.getEditorAPI().bi.event(
                coreBi.events.panels.login_releted_error_popup_click,
                {
                  ctaName: 'wix.com',
                  actionsType: this.getBIActionTypeByErrorType(),
                  action: this.props.biOrigin || 'other',
                },
              );
              window.open(URL, '_blank');
            }}
          >
            {`${translationValue} `}
          </a>
        ) : (
          <span key={key}>{`${translationValue} `}</span>
        ),
      );
      return acc;
    }, []);
  },
  getSteps() {
    return this.props.steps?.map((step: AnyFixMe) => {
      const isMultiKeysStep = Array.isArray(step);
      return (
        <li key={isMultiKeysStep ? step[0].key : step}>
          {isMultiKeysStep ? this.getMultiStepKeyValue(step) : translate(step)}
        </li>
      );
    });
  },
  getErrorDescription() {
    return (
      <p className="error-description">
        <span className={styles.errorDescriptionText}>
          {translate(this.props.errorDescription.text, {
            status_code: this.props.errorCode,
          })}
        </span>
        {this.props.errorDescription.secondaryText ? (
          <div className={styles['error-description-secondary-text']}>
            {translate(this.props.errorDescription.secondaryText)}
          </div>
        ) : null}
        {this.props.errorDescription.helpId ? (
          <a
            onClick={() => {
              this.getEditorAPI().panelManager.openHelpCenter(
                this.props.errorDescription.helpId,
              );
            }}
          >
            {this.props.errorDescription.helpText
              ? translate(this.props.errorDescription.helpText)
              : null}
          </a>
        ) : null}
      </p>
    );
  },
  getOnHelp() {
    const helpId =
      this.props.headerHelpLink ||
      this.props.helpLink ||
      this.props.errorDescription?.helpId;
    if (helpId) {
      return () => {
        this.getEditorAPI().panelManager.openHelpCenter(helpId);
      };
    }
  },
  getNewWorkspaceMessageFootnote() {
    // Because of differences between new and old workspace modal frames
    // we need to make sure that there is actually something to render in the footer
    if (!this.props.showFooterText || !this.props.helpMessage) {
      return null;
    }

    return (
      <p className="fail-panel-help-text">
        <span className="text-part">{translate(this.props.helpMessage)}</span>
        <a onClick={this.openHelpLink} className="link-part">
          {translate(this.props.helpLinkMessage)}
        </a>
        {this.props.helpSuffix && (
          <span className="help-suffix">
            {translate(this.props.helpSuffix)}
          </span>
        )}
        {this.props.errorCode && (
          <span className="error-code-part"> {this.getErrorCodeMessage()}</span>
        )}
      </p>
    );
  },
  renderInTheNewMassagePanelFrame() {
    return (
      <panels.frames.MessagePanelFrame
        theme={this.props.theme}
        dataHook={'save-publish-fail-panel'} // Should we add errorType here?
        panelName={this.props.panelName}
        className={util.cx(styles.failPanel, this.props.className)}
        title={translate(this.props.titleKey)}
        cancelLabel={translate(this.props.cancelLabel)}
        confirmLabel={translate(this.getConfirmLabel())}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        onHelp={this.getOnHelp()}
        dontShowAgainKey={this.props.dontShowAgainKey}
        userPrefType={this.props.userPrefType}
        footnote={this.getNewWorkspaceMessageFootnote()}
        illustration={
          this.props.symbolName ? (
            <baseUI.symbol name={this.props.symbolName} />
          ) : null
        }
        isSingleInstance={true}
      >
        <Text
          size="medium"
          weight="normal"
          skin="secondary"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {this.props.errorDescription ? (
            this.getErrorDescription()
          ) : (
            <SafeInjectHtml
              tag="p"
              html={this.translateProp('description')}
              className="description"
            />
          )}

          {this.props.showSteps && this.props.stepsTitle ? (
            <div key="stepsTitle" className="steps-title">
              {this.translateProp('stepsTitle')}
            </div>
          ) : null}

          {this.props.showSteps ? <ol key="steps">{this.getSteps()}</ol> : null}
        </Text>
      </panels.frames.MessagePanelFrame>
    );
  },

  render() {
    return util.workspace.isNewWorkspaceEnabled() &&
      util.wixCodeUtils.isNewVeloWorkspace() ? (
      this.renderInTheNewMassagePanelFrame()
    ) : (
      <panels.frames.OldMessagePanelFrame
        dataHook={'save-publish-fail-panel'}
        panelName={this.props.panelName}
        cancelLabel={this.props.cancelLabel}
        panelTitle={translate(this.props.titleKey)}
        confirmLabel={this.getConfirmLabel()}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        onHelp={this.getOnHelp()}
        footerText={this.props.showFooterText ? this.getFooterText() : null}
        keyboardShortcuts={this.getShortcuts()}
        className={util.cx('fail-panel', this.props.className)}
        dontShowAgainKey={this.props.dontShowAgainKey}
        userPrefType={this.props.userPrefType}
        onDontShowAgainChange={this.props.onDontShowAgainChange}
      >
        <Composites.RichTextWithIllustration>
          {!this.props.hideSymbol && (
            <Illustration>
              <baseUI.symbol name={this.props.symbolName || 'plasterMedium'} />
            </Illustration>
          )}
          <Composites.RichText>
            {this.props.errorCode !== -41218 ? (
              <RichText
                type="T01"
                key="errorNot-41218"
                className="fail-panel__message"
              >
                {this.props.errorDescription
                  ? this.getErrorDescription()
                  : null}
                {!this.props.errorDescription ? (
                  <SafeInjectHtml
                    tag="p"
                    html={this.translateProp('description')}
                    className="description"
                  />
                ) : null}
                {this.props.showSteps && this.props.stepsTitle ? (
                  <div key="stepsTitle" className="steps-title">
                    {this.translateProp('stepsTitle')}
                  </div>
                ) : null}
                {this.props.showSteps ? (
                  <ol key="steps">{this.getSteps()}</ol>
                ) : null}
              </RichText>
            ) : null}
            {this.props.errorCode === -41218 ? (
              <RichText
                type="T01"
                key="error-41218"
                className="fail-panel__message"
              >
                <h3>{translate('PUBLISH_ERROR_SITE_DELETED_TITLE')}</h3>
                <p className="description">
                  {translate('PUBLISH_ERROR_SITE_DELETED_BODY')}
                </p>
              </RichText>
            ) : null}
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </panels.frames.OldMessagePanelFrame>
    );
  },
});
