// @ts-nocheck
import React from 'react';
import { cx } from '@/util';

export default class extends React.Component {
  static displayName = 'textEffectButton';

  render() {
    return (
      <span className={this.props.className}>
        <button
          onClick={this.props.onClick}
          className={cx({
            'text-effect-button': true,
            selected: this.props.isSelected,
          })}
        >
          <span>{this.props.children}</span>
        </button>
      </span>
    );
  }
}
