import React from 'react';
import { ParentsNegativeScaleAndSkewWrapper } from './parentsNegativeScaleAndSkewWrapper';

interface CenterDot {
  negativeScaleAndSkewString?: string;
  parentsNegativeScaleAndSkewString?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CenterDot = ({
  negativeScaleAndSkewString = '',
  parentsNegativeScaleAndSkewString = [],
}: CenterDot) => {
  const cssPosition: React.CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
  };

  return (
    <ParentsNegativeScaleAndSkewWrapper
      parentsNegativeScaleAndSkewString={parentsNegativeScaleAndSkewString}
      cssPosition={cssPosition}
    >
      <div
        className="center-dot"
        style={{
          ...cssPosition,
          transform: `${negativeScaleAndSkewString} translate(-50%, -50%)`,
        }}
      />
    </ParentsNegativeScaleAndSkewWrapper>
  );
};
