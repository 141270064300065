import * as core from '@/core';
import type { EditorAPI } from '@/editorAPI';
import * as actions from './actions/actions';
import type {
  BasicAction,
  OnRenameHandler,
  OnSettingsChangeHandler,
} from '../panel/types';
import type { PagesData, AppData } from 'types/documentServices';
import type { ActionPermissions } from '@wix/platform-editor-sdk';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import { translate } from '@/i18n';
import type {
  PageRef,
  PagesDataFromAppManifest,
} from '@wix/document-services-types';

const tpaUtils = core.utils.tpaTypeUtils;

const getPagePermissionSymbol = (pageData: AnyFixMe) => {
  const isMembersPermission = pageData?.pageSecurity?.requireLogin;
  if (isMembersPermission) {
    return 'membersPagePermission';
  }
  const isPasswordPermission = pageData?.pageSecurity?.dialogLanguage;
  if (isPasswordPermission) {
    return 'passwordPagePermission';
  }
  return null;
};

export interface GroupActionRawData extends ActionPermissions {
  title: string;
  icon: string;
  event: string;
}

export interface IAppPageAction extends BasicAction {
  isRename?: boolean;
  isRemove?: boolean;
  isSettings?: boolean;
}

export interface IAppAction extends BasicAction {
  event: string;
  type: string;
}

export interface PageDescriptor {
  orderIndex?: number;
  icon?: string;
  tooltip?: string;
}

export interface IAppPage {
  id: string;
  tpaPageId?: string;
  title: string;
  subtitle?: string;
  inBracketsTitle?: string;
  permissionSymbol?: string;
  typeSymbol?: string;
  info?: string;
  pageDescriptor?: PageDescriptor;
  helpId?: string;
  actions?: IAppPageAction[];
  replacers?: string[];
  variants?: string[];
  isVariant?: boolean;
  replacerOf?: string;
  managingAppDefId?: string;
  isDeactivated?: boolean;
}

export interface PagesManaged {
  helpId: string;
  id: string;
  title: string;
  pagesData?: IAppPage[];
  biCategory: string;
  emptyStateUrl?: string;
}

export interface PagesGrouped extends PagesManaged {
  actions?: IAppAction[];
  pagesGroups: PagesGroupWithAction[];
}

enum ReplacerType {
  REPLACER = 'replacer',
  VARIANT = 'variant',
}

type ReplacersMap = Record<
  string,
  {
    hostAppDefinitionId: string;
    replacerOfPageId: string;
    type: ReplacerType;
  }
>;

export interface PageGroupAction {
  title: string;
  symbolName: string;
  onClick: () => void;
  tooltip?: string;
}

export interface GroupPagesAndTitle {
  title: string;
  pages: string[];
  id?: string;
}

export interface PagesGroupWithAction extends GroupPagesAndTitle {
  groupActions?: PageGroupAction[] | null;
}

export interface PagesGroupWithRawActions extends GroupPagesAndTitle {
  groupActions?: GroupActionRawData[];
}

interface GroupSettingsRouter {
  groupBy: 'routers';
  groupActions?: PageGroupAction[];
  defaultGroupName?: string;
}

interface GroupSettingsCustom {
  groupBy: 'custom';
  customGroups: PagesGroupWithRawActions[];
  defaultGroupName?: string;
}

const getPageIcon = (
  pageDescriptor: PageDescriptor,
  managingAppDefId: string,
) => {
  return pageDescriptor?.icon ?? (managingAppDefId ? 'appsNewLight' : 'page');
};

const getAppPagesDataWithActions = (
  editorAPI: EditorAPI,
  pagesData: IAppPage[],
  onShowSettings: OnSettingsChangeHandler,
  onRename: OnRenameHandler,
  appDefId: string,
  biCategory?: string,
): IAppPage[] => {
  if (!pagesData) {
    return [];
  }
  return pagesData.map(
    ({
      id,
      title,
      permissionSymbol,
      pageDescriptor,
      tpaPageId,
      managingAppDefId,
      replacerOf,
      replacers,
      variants,
      isVariant,
      subtitle,
      inBracketsTitle,
      isDeactivated,
    }) => ({
      id,
      title,
      tpaPageId,
      permissionSymbol,
      replacerOf,
      replacers,
      variants,
      subtitle,
      inBracketsTitle,
      isDeactivated,
      typeSymbol: getPageIcon(pageDescriptor, managingAppDefId),
      info: pageDescriptor?.tooltip,
      actions: actions.applicationPage(
        editorAPI,
        id,
        {
          appDefinitionId: managingAppDefId,
          isDesktop: editorAPI.isDesktopEditor(),
          biCategory,
          onShowSettings,
          onRename,
          replacers,
          variants,
          isVariant,
          replacerOf,
          tabAppDefinitionId: appDefId,
        },
        title,
      ),
      managingAppDefId,
    }),
  );
};

const getAppPageData = (
  editorAPI: EditorAPI,
  pageData: PagesData,
  withDescriptor: boolean = true,
): IAppPage => {
  const pageRef = editorAPI.dsRead.pages.getReference(pageData.id);
  const pageDescriptor: PageDescriptor = {
    orderIndex: 0,
    ...editorAPI.pages.appPages.getPageDescriptor(
      pageData.managingAppDefId,
      pageRef,
    ),
  };

  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const extraData: Partial<IAppPage> = {};
  const pageVariations = platformAPI.pageReplace.getPageVariations({
    ...pageRef,
    pageId: pageRef.id,
  });
  if (pageVariations?.variants?.length > 0) {
    extraData.variants = pageVariations.variants;
    extraData.inBracketsTitle = translate(
      'PLATFORM_flow_customization_pages_panel_default_page_label',
    );
  }
  if (pageVariations?.replacers?.length > 0) {
    extraData.replacers = pageVariations.replacers;
    extraData.inBracketsTitle = translate(
      'PLATFORM_flow_customization_pages_panel_default_page_label',
    );
    const replacerPageRef = editorAPI.dsRead.pages.getReference(
      pageVariations.replacers[0],
    ) as PageRef;
    extraData.isDeactivated = platformAPI.pageReplace.isReplacerActive({
      ...replacerPageRef,
      id: pageVariations?.replacers[0],
    });
  }

  return {
    id: pageData.id,
    tpaPageId: pageData.tpaPageId,
    title: pageData.title,
    managingAppDefId: pageData.managingAppDefId,
    permissionSymbol: getPagePermissionSymbol(pageData),
    pageDescriptor: withDescriptor
      ? pageDescriptor
      : { orderIndex: pageDescriptor.orderIndex },
    ...extraData,
  };
};

const getAppPagesData = (
  editorAPI: EditorAPI,
  appPagesData: PagesData[],
  withDescriptor: boolean = true,
): IAppPage[] =>
  appPagesData
    .map((pageData) => getAppPageData(editorAPI, pageData, withDescriptor))
    .sort((a, b) => b.pageDescriptor.orderIndex - a.pageDescriptor.orderIndex);

const pagesManagedByApps = (
  editorAPI: EditorAPI,
  pagesData: PagesData[],
  withDescriptor: boolean,
): PagesManaged[] => {
  const supportedApplications = editorAPI.dsRead.platform
    .getInstalledEditorApps()
    .map((app) => app.appDefinitionId);
  const supportedApplicationsSet = new Set(supportedApplications);

  const pagesGroupsByAppDefId = pagesData.reduce(
    (acc, pageData) => {
      const pageAppDefId = pageData.managingAppDefId;

      const tpaAppDefId = editorAPI.dsRead.platform.getAppDataByApplicationId(
        pageData.tpaApplicationId,
      ).appDefinitionId;
      const appAskedToFilterPageInCSM =
        pageData.managingAppDefId === tpaAppDefId &&
        tpaUtils.isPageNotVisibleInPagesMenu(editorAPI.dsRead, pageData);

      if (
        pageAppDefId &&
        supportedApplicationsSet.has(pageAppDefId) &&
        !appAskedToFilterPageInCSM
      ) {
        acc[pageAppDefId] = acc[pageAppDefId] || [];
        acc[pageAppDefId].push(pageData);
      }

      return acc;
    },
    {} as Record<string, PagesData[]>,
  );

  return supportedApplications
    .map((appDefId) => {
      const popUps = (
        editorAPI.pages.popupPages.getDataListOrdered() as AnyFixMe
      ).filter(
        ({ managingAppDefId }: AnyFixMe) => managingAppDefId === appDefId,
      );
      const appPages = pagesGroupsByAppDefId[appDefId]
        ? pagesGroupsByAppDefId[appDefId].concat(popUps)
        : [];

      const applicationPagesSettings =
        editorAPI.pages.appPages.getApplicationSettings(appDefId) ||
        ({} as AnyFixMe);
      const appDataClientSpecMap =
        editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId) ||
        ({
          appDefinitionName: undefined,
        } as AppData);

      if (appPages.length > 0) {
        return {
          id: appDefId,
          title:
            applicationPagesSettings.displayName ||
            appDataClientSpecMap.appDefinitionName,
          pagesData: getAppPagesData(editorAPI, appPages, withDescriptor),
          biCategory: appDefId,
          helpId: applicationPagesSettings.helpId,
        };
      }
      if (applicationPagesSettings.emptyState) {
        return {
          id: appDefId,
          title: applicationPagesSettings.displayName,
          emptyStateUrl: applicationPagesSettings.emptyState.url,
          biCategory: appDefId,
          helpId:
            applicationPagesSettings.emptyState.helpId ||
            applicationPagesSettings.helpId,
        };
      }
      return null;
    })
    .filter((page) => page !== null);
};

const groupPagesByRouters = (
  editorAPI: EditorAPI,
  appPages: PagesGrouped,
  groupSettings: GroupSettingsRouter,
): PagesGrouped => {
  const allRouters = editorAPI.pages.dynamicPages.getMenuItems();
  const groupsMap: Record<string, AnyFixMe> = {};

  appPages.pagesData.forEach((pageData) => {
    const groupTitle =
      allRouters.find((router) =>
        router.items.find((item) => item.id === pageData.id),
      )?.label ?? groupSettings.defaultGroupName;
    groupsMap[groupTitle] = groupsMap[groupTitle] || {
      title: groupTitle,
      pages: [],
    };
    groupsMap[groupTitle].pages.push(pageData.id);
  });

  // Add the groups only if there are groups and the ungrouped pages is not the only group
  const pagesGroups: { title: string; pages: string[] }[] =
    Object.values(groupsMap);
  if (
    pagesGroups.length > 1 ||
    (pagesGroups.length === 1 &&
      pagesGroups[0].title !== groupSettings.defaultGroupName)
  ) {
    appPages.pagesGroups = pagesGroups.sort((pagesGroup) =>
      pagesGroup.title !== groupSettings.defaultGroupName ? 1 : -1,
    );
  }

  return appPages;
};

const groupPagesByCustom = (
  editorAPI: EditorAPI,
  appPages: PagesGrouped,
  groupSettings: GroupSettingsCustom,
): PagesGrouped => {
  const pagesIdsMap = appPages.pagesData.reduce(
    (acc, pageData) => ({ ...acc, [pageData.id]: pageData.tpaPageId }),
    {},
  );

  const tpaPagesIdsMap = appPages.pagesData.reduce(
    (acc, pageData) =>
      pageData.tpaPageId ? { ...acc, [pageData.tpaPageId]: pageData.id } : acc,
    {},
  );

  const customGroups: PagesGroupWithAction[] = (
    groupSettings.customGroups || []
  )
    .map(({ title, pages, groupActions, id }) => {
      const pagesGroup: GroupPagesAndTitle = { title, pages: [], id };
      pagesGroup.pages = (pages || [])
        .map(
          (pageId) =>
            tpaPagesIdsMap[pageId as keyof typeof tpaPagesIdsMap] ||
            (pageId in pagesIdsMap && pageId),
        )
        .filter(Boolean);
      pagesGroup.pages.forEach((pageId) => {
        delete pagesIdsMap[pageId as keyof typeof pagesIdsMap];
      });
      return {
        ...pagesGroup,
        groupActions: actions.groupActions(
          editorAPI,
          appPages.id,
          id,
          groupActions,
          appPages.biCategory,
        ),
      };
    })
    .filter(({ pages }) => pages.length);

  if (customGroups.length) {
    const nonGroupedPages = Object.keys(pagesIdsMap);
    if (nonGroupedPages.length) {
      customGroups.unshift({
        title: groupSettings.defaultGroupName,
        pages: nonGroupedPages,
        id: `default-group-id-${appPages.id}`,
      });
    }
    appPages.pagesGroups = customGroups;
  }

  return appPages;
};

const groupPagesMethods = {
  routers: groupPagesByRouters,
  custom: groupPagesByCustom,
};

const groupedPagesManagedByApps = (
  editorAPI: EditorAPI,
  pagesData: PagesData[],
  withDescriptor?: boolean,
): PagesGrouped[] =>
  pagesManagedByApps(editorAPI, pagesData, withDescriptor).map((appPages) => {
    const applicationPagesSettings =
      editorAPI.pages.appPages.getApplicationSettings(appPages.id) ||
      ({} as AnyFixMe);
    const groupSettings = applicationPagesSettings?.grouping ?? {};
    groupSettings.defaultGroupName =
      applicationPagesSettings?.displayName ?? '';

    return (
      groupPagesMethods[groupSettings.groupBy as keyof typeof groupPagesMethods]
        ? groupPagesMethods[
            groupSettings.groupBy as keyof typeof groupPagesMethods
          ](editorAPI, appPages as AnyFixMe, groupSettings)
        : appPages
    ) as AnyFixMe;
  });

const getReplacerPageData = (
  editorAPI: EditorAPI,
  pagesData: PagesData[],
  appPagesData: IAppPage[],
  replacersMap: ReplacersMap,
  withDescriptor?: boolean,
) =>
  Object.keys(replacersMap).reduce(
    (replacersByApp: Record<string, IAppPage[]>, replacerId: string) => {
      const { hostAppDefinitionId, replacerOfPageId } =
        replacersMap[replacerId];
      if (!replacersByApp[hostAppDefinitionId]) {
        replacersByApp[hostAppDefinitionId] = [];
      }

      const platformAPI = editorAPI.host.getAPI(
        EditorPlatformHostIntegrationAPI,
      );

      const replacerData = pagesData.find(({ id }) => id === replacerId);

      const appSettings = editorAPI.pages.appPages.getApplicationSettings(
        replacerData?.managingAppDefId,
      ) as PagesDataFromAppManifest;
      const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(
        replacerData?.managingAppDefId,
      );
      const appName =
        (appSettings?.displayName || appData?.appDefinitionName) ??
        translate('PLATFORM_flow_customization_pages_panel_custom_page_label');
      const pageRef = editorAPI.dsRead.pages.getReference(replacerData.id);
      const existingPageData = appPagesData.find(({ id }) => id === replacerId);
      const isDeactivated = !platformAPI.pageReplace.isReplacerActive({
        ...pageRef,
        pageId: replacerData.id,
        id: replacerData.id,
      });
      const variantType: ReplacerType = ReplacerType.VARIANT;
      const extraPageData = createExtraPageData(
        appName,
        replacerOfPageId,
        isDeactivated,
        replacersMap[replacerId].type === variantType,
      );
      if (existingPageData) {
        replacersByApp[hostAppDefinitionId].push({
          ...existingPageData,
          ...extraPageData,
        });
      } else {
        replacersByApp[hostAppDefinitionId].push({
          ...getAppPageData(editorAPI, replacerData, withDescriptor),
          ...extraPageData,
        });
      }
      return replacersByApp;
    },
    {},
  );

const getAllReplacerPageInitialData = (
  appsPages: PagesGrouped[],
): ReplacersMap => {
  const replacersData: ReplacersMap = {};
  appsPages.forEach((appPagesTab) => {
    appPagesTab.pagesData?.forEach((pageData) => {
      if (pageData.variants) {
        pageData.variants.forEach((variantId) => {
          const variantType: ReplacerType = ReplacerType.VARIANT;
          if (variantId) {
            replacersData[variantId] = {
              hostAppDefinitionId: appPagesTab.id,
              replacerOfPageId: pageData.id,
              type: variantType,
            };
          }
        });
      }
      if (pageData.replacers) {
        pageData.replacers.forEach((replacerId) => {
          const replacerType: ReplacerType = ReplacerType.REPLACER;
          replacersData[replacerId] = {
            hostAppDefinitionId: appPagesTab.id,
            replacerOfPageId: pageData.id,
            type: replacerType,
          };
        });
      }
    });
  });
  return replacersData;
};

const createExtraPageData = (
  appName: string,
  replacerOfPageId: string,
  isDeactivated: boolean,
  isVariant: boolean,
) => {
  const extraPageData = {
    appName,
    replacerOf: replacerOfPageId,
    ...(!isVariant && { isDeactivated }),
    isVariant,
  };

  return extraPageData;
};

const getAppPagesDataWithVariations = (
  editorAPI: EditorAPI,
  appsPages: PagesGrouped[],
  pagesData: PagesData[],
  withDescriptor?: boolean,
): PagesGrouped[] => {
  const allAppPagesData: IAppPage[] = appsPages.reduce((acc, { pagesData }) => {
    if (pagesData) {
      acc.push(...pagesData);
    }
    return acc;
  }, []);
  const replacersData = getReplacerPageData(
    editorAPI,
    pagesData,
    allAppPagesData,
    getAllReplacerPageInitialData(appsPages),
    withDescriptor,
  );
  const allReplacers = Object.values(replacersData).reduce(
    (acc, replacers) => acc.concat(replacers),
    [],
  );

  appsPages.forEach((appPages) => {
    appPages.pagesData = appPages.pagesData?.map((pageData) => {
      const pageAsReplacerData = allReplacers.find(
        ({ id }) => id === pageData.id,
      );
      if (pageAsReplacerData) {
        return pageAsReplacerData;
      }
      return pageData;
    });
    const replacers = replacersData?.[appPages.id]?.filter(
      (appPage) => !appPage.isVariant,
    );
    if (replacers) {
      appPages.pagesData = appPages.pagesData.concat(replacers);
    }
    return appPages;
  });
  return appsPages;
};

const getAppPages = (
  editorAPI: EditorAPI,
  pagesData: PagesData[],
): PagesGrouped[] => {
  const appsPages = groupedPagesManagedByApps(editorAPI, pagesData);
  const appsPagesWithVariations = getAppPagesDataWithVariations(
    editorAPI,
    appsPages,
    pagesData,
  );

  return appsPagesWithVariations.map((appPages) => {
    return {
      ...appPages,
      actions: actions.application(
        editorAPI,
        appPages.id,
        editorAPI.isDesktopEditor(),
        appPages.biCategory,
      ),
    };
  });
};

export default {
  getAppPages,
  getAppPagesDataWithActions,
};
