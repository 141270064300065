import _ from 'lodash';
import * as queries from '../queries/openBehaviorsQueries';
import type { EditorAPI } from '@/editorAPI';

const getPopupTitle = _.curry(function getPopupTitle(
  editorAPI: AnyFixMe,
  behaviors: AnyFixMe,
) {
  const { popupId } = behaviors[0];

  return editorAPI.pages.getPageTitle(popupId);
});

function between(a: AnyFixMe, x: AnyFixMe, b: AnyFixMe) {
  return a < x && x < b;
}

export function getPageSelectorPanelProps(editorAPI: EditorAPI) {
  const popupPointer = editorAPI.pages.popupPages.getCurrentPopup();
  const allPageIds = editorAPI.dsRead.pages.getPageIdList();
  const allOpenBehaviors = queries.getDenormalizedBehaviors(
    editorAPI,
    editorAPI.pages.popupPages.getOpenBehaviorsForPopups(),
    allPageIds,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const currentOpenBehaviors = _.filter(allOpenBehaviors, {
    popupId: popupPointer.id,
  });
  const otherOpenBehaviors = _.reject(allOpenBehaviors, {
    popupId: popupPointer.id,
  });
  const occupiedPages = _(otherOpenBehaviors)
    .groupBy('pageId')
    .mapValues(getPopupTitle(editorAPI))
    .value();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  const availablePageIds = _.difference(allPageIds, _.keys(occupiedPages));
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const selectedPageIds = _.map(currentOpenBehaviors, 'pageId');

  return {
    selectAll: {
      visible: allPageIds.length > 1,
      disabled: availablePageIds.length === 0,
      indeterminate: between(
        0,
        currentOpenBehaviors.length,
        availablePageIds.length,
      ),
      checked:
        selectedPageIds.length > 0 &&
        selectedPageIds.length === availablePageIds.length,
    },
    selectedPageIds,
    occupiedPages,
    availablePageIds,
  };
}
