// @ts-nocheck
import ReactDOM from 'reactDOM';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as mediaPanelInfra from '@/mediaManagerPanelInfra';
import addPanelDataConsts from '@/addPanelDataConsts';
import responsiveDragOverride from '../responsive/responsiveDragOverride';
import { ThemeSection } from './liveComponentsSections/themeSection';
import { PersonalizedSection } from './liveComponentsSections/personalizedSection';
import designDataPresetSection from './designSections/designDataPresetSection';
import presetSection from './presetSection/presetSection';
import presetListSection from './listSection/presetListSection';
import responsiveSection from './responsive/responsiveSection';
import { ImageSection } from './listSection/imageSection';
import dataSection from './listSection/dataSection';
import accountSettingsListSection from './listSection/accountSettingsListSection/accountSettingsListSection';
import togglesListSection from './listSection/togglesListSection/togglesListSection';
import genericListSection from './listSection/genericListSection';
import addPagesSection from './addPagesSection/addPagesSection';
import generalSection from './generalSection/generalSection';
import welcomeSection from './welcomeSection/welcomeSection';
import { translate } from '@/i18n';

const modules = initModules();

const RESPONSIVE_DRAG_SECTIONS = {
  [addPanelDataConsts.SECTIONS_TYPES.PRESET]: true,
  [addPanelDataConsts.SECTIONS_TYPES.RESPONSIVE_PRESET_MANAGER]: true,
  [addPanelDataConsts.SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX]: true,
};

const RESPONSIVE_PRESETS_MANAGERS = [
  addPanelDataConsts.SECTIONS_TYPES.RESPONSIVE_PRESET_MANAGER,
  addPanelDataConsts.SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX,
];

function initModules() {
  const temp = {};
  temp[addPanelDataConsts.SECTIONS_TYPES.THEME] = ThemeSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED] = PersonalizedSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.PRESET] = presetSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.PRESET_LIST] = presetListSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.IMAGE] = ImageSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.GENERAL] = generalSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.DATA] = dataSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.GENERIC_LIST_SECTION] =
    genericListSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.PAGE] = addPagesSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.RESPONSIVE_PRESET_MANAGER] =
    responsiveSection;

  temp[addPanelDataConsts.SECTIONS_TYPES.DESIGN_DATA_PRESET] =
    designDataPresetSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.DESIGN_DATA_THEME_PRESET] =
    designDataPresetSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED_DESIGN] =
    designDataPresetSection;
  temp[addPanelDataConsts.SECTIONS_TYPES.WELCOME] = welcomeSection;

  temp[addPanelDataConsts.SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX] =
    mediaPanelInfra.DynamicMediaBox;

  temp[addPanelDataConsts.SECTIONS_TYPES.ACCOUNT_SETTINGS_LIST] =
    accountSettingsListSection;

  temp[addPanelDataConsts.SECTIONS_TYPES.TOGGLES_LIST] = togglesListSection;

  return temp;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'baseGenerator',
  propTypes: {
    hide: PropTypes.bool,
    id: PropTypes.string.isRequired,
    props: PropTypes.object,
    showSectionHeader: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    parentType: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func,
    SectionDragSourceWrapper: PropTypes.func,
    transformStructureToResponsive: PropTypes.func,
    sectionIndex: PropTypes.number,
  },
  getOffsetTop() {
    const dom = ReactDOM.findDOMNode(this);
    return dom ? dom.offsetTop : -1;
  },
  getOffsetHeight() {
    const dom = ReactDOM.findDOMNode(this);
    return dom ? dom.offsetHeight : -1;
  },
  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)
    );
  },
  registerSectionResolver(resolver) {
    this.payloadResolver = resolver;
  },
  getExtraSectionProps() {
    switch (this.props.type) {
      case addPanelDataConsts.SECTIONS_TYPES.RESPONSIVE_PRESET_MANAGER:
        return {
          registerPayloadResolver: this.registerSectionResolver,
        };
      case addPanelDataConsts.SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX:
        return {
          registerPayloadResolver: this.registerSectionResolver,
        };
      default:
        return {};
    }
  },
  render() {
    const BaseGenerator = React.createElement(
      modules[this.props.type],
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, this.props, this.getExtraSectionProps()),
    );
    if (
      this.props.SectionDragSourceWrapper &&
      RESPONSIVE_DRAG_SECTIONS[this.props.type]
    ) {
      let polygonSectionItem = null;

      const setDragVectorShapeItem = (item) => {
        polygonSectionItem = item;
      };
      const items = this.props?.props?.items;
      return React.createElement(
        this.props.SectionDragSourceWrapper,
        {
          createPayload: (eventData, mouseEvent) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/includes
            const isResponsivePresetManager = _.includes(
              RESPONSIVE_PRESETS_MANAGERS,
              this.props.type,
            );
            if (isResponsivePresetManager) {
              if (!this.payloadResolver) {
                return;
              }
              const dragPayloadFromPresetPayload = this.payloadResolver(
                eventData,
                mouseEvent,
              );
              if (!dragPayloadFromPresetPayload) {
                return;
              }

              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line you-dont-need-lodash-underscore/assign
              const payloadOptions = _.assign(
                {},
                dragPayloadFromPresetPayload.options,
                {
                  category: this.props.categoryId,
                  section: translate(this.props.title),
                },
              );
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line you-dont-need-lodash-underscore/assign
              const payload = _.assign({}, dragPayloadFromPresetPayload, {
                options: payloadOptions,
              });
              return responsiveDragOverride.getDragPayloadFromPresetPayload(
                payload,
                eventData,
              );
            }
            return responsiveDragOverride.getEditorPresetDragPayload(
              eventData.clientX,
              eventData.clientY,
              items,
              this.props.transformStructureToResponsive,
              {
                appDefinitionId: this.props.appDefinitionId,
                category: this.props.categoryId,
                section: translate(this.props.title),
              },
              polygonSectionItem,
            );
          },
        },
        React.cloneElement(BaseGenerator, { setDragVectorShapeItem }),
      );
    }
    return BaseGenerator;
  },
});
