'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_TextInput",
    "subNavigationTitle": "add_section_heading_input",
    "topTitle": "add_section_title_input",
    "presetTitle": "add_section_heading_input",
    "tooltipTitle": "add_section_label_TextInput_tooltip_title",
    "automationId": "add-panel-section-textInputSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/textInputSection_${util.languages.getLanguageCode()}/textInputSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_TextInput_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
                "layout": {
                    "width": 304,
                    "height": 43,
                    "x": 10,
                    "y": 19,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextInput",
                "data": {
                    "type": "TextInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Add your Name",
                    "value": "",
                    "textType": "text"
                },
                "props": {
                    "type": "TextInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 14,
                    "placeholder": "Add your Name",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgd": "1",
                            "alpha-bge": "0",
                            "alpha-bgf": "0",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "bg": "#F5FFFE",
                            "bgd": "#FFFFFF",
                            "bge": "#FF4040",
                            "bgf": "#F5FFFE",
                            "bgh": "#F2FAF9",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#919191",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#000000",
                            "brdh": "#525252",
                            "brw": "2",
                            "brwd": "1",
                            "brwf": "2",
                            "brwh": "2",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fntlbl": "normal normal 700 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#000000",
                            "txt2": "#7F808A",
                            "txtlbl": "#61615F",
                            "txtlblrq": "#61615F",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin"
                },
                "activeModes": {},
                "id": "comp-iw39j53w"
            },
            "preset": {"rect": {"width": 324, "height": 78, "x": 0, "y": 0}}
        }, {
            "id": "User_Input_TextInput_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
                "layout": {
                    "width": 304,
                    "height": 37,
                    "x": 10,
                    "y": 93,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextInput",
                "data": {
                    "type": "TextInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "What's your number?",
                    "value": "",
                    "textType": "tel"
                },
                "props": {
                    "type": "TextInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 0,
                    "placeholder": "What's your number?",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgd": "0",
                            "alpha-bge": "0",
                            "alpha-bgf": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdf": "0",
                            "alpha-brdh": "0",
                            "bg": "#F5FFFE",
                            "bge": "#FF4040",
                            "bgf": "#F5FFFE",
                            "bgh": "#E8FFFD",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#525252",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#000000",
                            "brdh": "#000000",
                            "brw": "0",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "fntlbl": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "rd": "0px",
                            "shd": "0.00px 2.00px 0px 0px rgba(140,132,250,1)",
                            "txt": "#525252",
                            "txt2": "#8C84FA",
                            "txtlbl": "#8C84FA",
                            "txtlblrq": "#8C84FA",
                            "txtd": "#E8E8E8"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin"
                },
                "activeModes": {},
                "id": "comp-iw39j53w1"
            },
            "preset": {"rect": {"width": 324, "height": 68, "x": 0, "y": 78}}
        }, {
            "id": "User_Input_TextInput_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 10,
                    "y": 161,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextInput",
                "data": {
                    "type": "TextInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Street Address",
                    "value": "",
                    "textType": "text"
                },
                "props": {
                    "type": "TextInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "center",
                    "textPadding": 12,
                    "placeholder": "Street Address",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bgd": "#FFFFFF",
                            "bge": "#FFFFFF",
                            "bgf": "#FFFFFF",
                            "bgh": "#FEFAFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#67D6D6",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#408F8F",
                            "brdh": "#59BAB6",
                            "brw": "1",
                            "fnt": "italic normal normal 15px/1.4em georgia",
                            "fntlbl": "italic normal normal 14px/1.4em georgia",
                            "rd": "0px",
                            "shd": "0.00px 2.00px 0px 0px rgba(103,214,214,1)",
                            "txt": "#49D6D6",
                            "txt2": "#A3E6E6",
                            "txtlbl": "#49D6D6",
                            "txtlblrq": "#49D6D6",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin"
                },
                "activeModes": {},
                "id": "comp-iw39j53x"
            },
            "preset": {"rect": {"width": 324, "height": 78, "x": 0, "y": 146}}
        }, {
            "id": "User_Input_TextInput_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 10,
                    "y": 238,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextInput",
                "data": {
                    "type": "TextInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "How many bottles of beer on the wall?",
                    "value": "",
                    "textType": "text"
                },
                "props": {
                    "type": "TextInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 14,
                    "placeholder": "How many bottles of beer on the wall?",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgd": "1",
                            "alpha-bge": "1",
                            "alpha-bgf": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdd": "1",
                            "alpha-brdf": "1",
                            "alpha-brdh": "1",
                            "bg": "#4BDB7B",
                            "bgd": "#DBDBDB",
                            "bge": "#FF4040",
                            "bgf": "#4BDB7B",
                            "bgh": "#5EE08A",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#52CCA0",
                            "brdd": "#E6E6E6",
                            "brde": "#FF4040",
                            "brdf": "#599981",
                            "brdh": "#52CCA0",
                            "brw": "0",
                            "brwd": "0",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "fntlbl": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "rd": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txt2": "#FFFFFF",
                            "txtlbl": "#4BDB7B",
                            "txtlblrq": "#4BDB7B",
                            "txtd": "#FFFFFF"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin"
                },
                "activeModes": {},
                "id": "comp-iw39j53x1"
            },
            "preset": {"rect": {"width": 324, "height": 76, "x": 0, "y": 224}}
        }, {
            "id": "User_Input_TextInput_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
                "layout": {
                    "width": 304,
                    "height": 45,
                    "x": 10,
                    "y": 316,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextInput",
                "data": {
                    "type": "TextInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Website Address",
                    "value": "",
                    "textType": "url"
                },
                "props": {
                    "type": "TextInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 18,
                    "placeholder": "Website Address",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bgd": "0",
                            "alpha-bge": "0",
                            "bge": "#FF4040",
                            "bgf": "#ffffff",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#E8E8E8",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#E8E8E8",
                            "brdh": "#F5F5F5",
                            "brw": "0",
                            "brwf": "1",
                            "fnt": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "fntlbl": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold",
                            "rd": "4px",
                            "shd": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "txt": "#000000",
                            "txt2": "#999999",
                            "txtlbl": "#000000",
                            "txtlblrq": "#000000",
                            "txtd": "#D4D4D4"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin"
                },
                "activeModes": {},
                "id": "comp-iw39j53y"
            },
            "preset": {"rect": {"width": 324, "height": 76, "x": 0, "y": 300}}
        }, {
            "id": "User_Input_TextInput_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 10,
                    "y": 392,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextInput",
                "data": {
                    "type": "TextInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Level of Education",
                    "value": "",
                    "textType": "text"
                },
                "props": {
                    "type": "TextInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 12,
                    "placeholder": "Level of Education",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bgd": "#FFFFFF",
                            "bge": "#FFFFFF",
                            "bgh": "#EAF7FF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#C6E2F7",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#00A6FF",
                            "brdh": "#C6E2F7",
                            "brw": "1",
                            "fnt": "normal normal normal 15px/1.4em helvetica-w01-light",
                            "fntlbl": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "rd": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#00A6FF",
                            "txt2": "#B6C1CD",
                            "txtlbl": "#00A6FF",
                            "txtlblrq": "#00A6FF",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin"
                },
                "activeModes": {},
                "id": "comp-iw39j53y1"
            },
            "preset": {"rect": {"width": 324, "height": 82, "x": 0, "y": 376}}
        }],
        "compTypes": ["wysiwyg.viewer.components.inputs.TextInput"]
    },
    "help": {"hide": false, "text": "add_section_info_text_textinput"}
}
