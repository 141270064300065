import constants from '@/constants';
import * as platformEvents from 'platformEvents';

type Callback = () => void;

interface Params {
  label?: string;
  nextPage?: string;
  __appParams?: any;
  /* there are more keys in params: actionType, currentPage, etc */
}

function register(editorAPI: AnyFixMe) {
  const { pluginService } = editorAPI;

  pluginService.registerPlugin(
    constants.HISTORY.REDO,
    constants.DS_ACTIONS.ADD_PAGE,
    function (params: Params, callback: Callback) {
      if (
        params.nextPage &&
        params.nextPage !== editorAPI.dsRead.pages.getFocusedPageId()
      ) {
        editorAPI.pages.navigateTo(params.nextPage, callback);
      } else {
        callback();
      }
    },
  );

  pluginService.registerPlugin(
    constants.HISTORY.REDO,
    '*',
    (params: Params, callback: Callback) => {
      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.redo({
          label: params ? params.label : '',
          params: params.__appParams || null,
        }),
      );
      callback();
    },
  );

  pluginService.registerPlugin(
    constants.HISTORY.UNDO,
    '*',
    (params: Params, callback: Callback) => {
      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.undo({
          label: params ? params.label : '',
          params: params.__appParams || null,
        }),
      );
      callback();
    },
  );
}

export { register };
