:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._bulletItem_uf6g0_7 {
  display: flex;
  margin-top: 10px; }

._bulletItemText_uf6g0_11 {
  margin-left: 10px; }

._learn-more_uf6g0_14 {
  margin-top: 20px; }

._prepare-panel_uf6g0_17 ._control-message-modal_uf6g0_17 {
  width: 602px; }
