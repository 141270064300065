import * as tpaAddComponentService from './tpaAddComponentService';
import * as tpaAddAppService from './tpaAddAppService';
import * as tpaAddRemoveDSService from './tpaAddRemoveDSService';
import * as tpaAppInstallingService from './tpaAppInstallingService';
import * as tpaAlertsService from './tpaAlertsService';
import * as pendingAppsService from './pendingAppsService';
import * as tpaAutoInstallPluginService from './tpaAutoInstallPluginService';

export {
  tpaAddComponentService,
  tpaAddAppService,
  tpaAddRemoveDSService,
  tpaAppInstallingService,
  tpaAlertsService,
  pendingAppsService,
  tpaAutoInstallPluginService,
};
