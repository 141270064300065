// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as util from '@/util';
import { translate } from '@/i18n';
import React from 'react';
import * as baseUI from '@/baseUI';
import { TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'SuperAppFullPanel',
  mixins: [core.mixins.editorAPIMixin, util.blockOuterScrollMixin],
  propTypes: {
    onClose: PropTypes.func.isRequired,
    fullPanel: PropTypes.any.isRequired,
  },
  getSymbol() {
    return this.props.fullPanel.symbol;
  },
  getImage() {
    return this.props.fullPanel.image;
  },
  onClick() {
    return this.props.fullPanel.onClick();
  },
  getDescription() {
    return this.props.fullPanel.description;
  },
  getTitle() {
    return this.props.fullPanel.title;
  },
  getLabel() {
    return this.props.fullPanel.buttonLabel;
  },
  render() {
    return (
      <div onWheel={this.blockOuterScroll} className="super-app-full-panel">
        <div className="actions">
          <div onClick={this.props.onClose} className="close button">
            <symbols.symbol
              name="headerCloseButton"
              className="headerCloseButton"
            />
          </div>
        </div>
        <div className="super-app-full-panel-content">
          {this.getSymbol() ? (
            <div key="symbol" className="super-app-full-panel-symbol">
              <baseUI.symbol name={this.getSymbol()} />
            </div>
          ) : null}
          {this.getImage() ? (
            <div key="image" className="super-app-full-panel-image">
              <img src={this.getImage()} />
            </div>
          ) : null}
          <div className="super-app-full-panel-title">
            <span>{translate(this.getTitle())}</span>
          </div>
          <div className="super-app-full-panel-description">
            <TextLabel
              enableEllipsis={false}
              type="T01"
              value={this.getDescription()}
            />
          </div>
          <div className="buttons-container">
            <div>
              <baseUI.button
                data-aid="button"
                label={this.getLabel()}
                onClick={() => {
                  this.onClick();
                }}
                className="btn-confirm-primary manage-btn"
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
});
