import type { Shell } from '@/apilib';
import { EditorAPIKey } from '@/apis';

export function createTabIndicationApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  function deactivateAnimation() {
    editorAPI.updateState({
      tabIndicationState: {
        indication: 'INACTIVE',
        activeMode: undefined,
      },
    });
  }

  function activateDeleteAnimation() {
    editorAPI.updateState(
      {
        tabIndicationState: {
          indication: 'DELETE',
          activeMode: undefined,
        },
      },
      deactivateAnimation,
    );
  }

  function activateAddAnimation(activeMode: AnyFixMe) {
    editorAPI.updateState(
      {
        tabIndicationState: {
          indication: 'ADD',
          activeMode,
        },
      },
      deactivateAnimation,
    );
  }

  function activateApplyAnimation(activeMode: AnyFixMe) {
    editorAPI.updateState(
      {
        tabIndicationState: {
          indication: 'APPLY',
          activeMode,
        },
      },
      deactivateAnimation,
    );
  }

  function getState() {
    return editorAPI.store.getState().tabIndicationState;
  }

  return {
    activateDeleteAnimation,
    activateAddAnimation,
    activateApplyAnimation,
    getState,
  };
}
