import { translate } from '@/i18n';
import { runInContext } from '../../../common/utils';
import type { EditorAPI } from '@/editorAPI';
import type { AppData, PageRef, RouterRef } from 'types/documentServices';

function deleteRouterPage(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: {
    pageRef: PageRef;
    routerRef: RouterRef;
    pageToNavigateAfterRemove: PageRef;
  },
) {
  return new Promise<void>(function (resolve, reject) {
    const appDefinitionId = appData?.appDefinitionId;
    const routerData = editorAPI.dsRead.routers.get.byRef(options.routerRef);
    if (!routerData || routerData.appDefinitionId !== appDefinitionId) {
      reject(); //eslint-disable-line prefer-promise-reject-errors
      return;
    }

    const removalOptions: any = { shouldShowEditorRemovePanel: false };
    if (options.pageToNavigateAfterRemove) {
      removalOptions.pageToNavigateAfterRemove =
        options.pageToNavigateAfterRemove;
    }

    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.pages.remove(
        options.pageRef.id,
        function () {
          resolve();
        },
        function () {
          reject(); //eslint-disable-line prefer-promise-reject-errors
        },
        removalOptions,
      ),
    );
  });
}

function isValidPrefix(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: { prefix: string },
) {
  const applicationId = appData?.applicationId;
  const isValidPrefixResult = editorAPI.dsRead.routers.isValidPrefix(
    applicationId,
    options.prefix,
  );

  if (!isValidPrefixResult.valid) {
    switch (isValidPrefixResult.errorCode) {
      case 1:
        isValidPrefixResult.message = translate(
          'DYNAMIC_PAGES_VALIDATION_PREFIX_CAN_NOT_BE_EMPTY',
        );
        break;
      case 2:
        isValidPrefixResult.message = translate(
          'DYNAMIC_PAGES_VALIDATION_PREFIX_IS_TOO_LONG',
        );
        break;
      case 3:
        const pageName = isValidPrefixResult?.metadata?.pageTitle;
        isValidPrefixResult.message = translate(
          'DYNAMIC_PAGES_VALIDATION_PREFIX_IN_USE_BY_ANOTHER_URISEO',
          { PageName: pageName },
        );
        break;
      case 4:
        isValidPrefixResult.message = translate(
          'DYNAMIC_PAGES_VALIDATION_PREFIX_CONTAINS_INVALID_CHARCTERS',
        );
        break;
      case 5:
        isValidPrefixResult.message = translate(
          'DYNAMIC_PAGES_VALIDATION_PREFIX_IS_FORBIDDEN_WORD',
        );
        break;
      case 6:
        const appDefinitionName =
          isValidPrefixResult?.metadata?.appDefinitionName;
        isValidPrefixResult.message = translate(
          'DYNAMIC_PAGES_VALIDATION_PREFIX_IN_USE_BY_ANOTHER_APP',
          { AppName: appDefinitionName },
        );
        break;
    }
  }
  return isValidPrefixResult;
}

function connectPageToRouter(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: {
    routerRef: RouterRef;
    pageRef: PageRef;
    pageRoles?: string[];
    slug?: string;
  },
) {
  return new Promise<void>(function (resolve, reject) {
    const appDefinitionId = appData?.appDefinitionId;
    const routerData = editorAPI.dsRead.routers.get.byRef(options.routerRef);
    if (!routerData) {
      reject(new Error('the router does not exist'));
      return;
    }
    if (routerData.appDefinitionId !== appDefinitionId) {
      reject(new Error('the router does not belong to the app'));
      return;
    }
    const currentPage = editorAPI.pages.getFocusedPageId();
    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.dsActions.routers.pages.connect(
        options.routerRef,
        options.pageRef,
        options.pageRoles,
        options.slug ? { innerRoute: options.slug } : undefined,
      ),
    );
    if (currentPage === options.pageRef.id) {
      editorAPI.pages.navigateTo(options.pageRef.id, resolve);
    }
    resolve();
  });
}

const pages = {
  delete: deleteRouterPage,
  connect: connectPageToRouter,
};

export { isValidPrefix, pages };
