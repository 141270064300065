// @ts-nocheck
import React from 'react';
import { FocusPanelFrame } from '../frames';

export default class extends React.Component {
  static displayName = 'exampleFocusPanel';

  render() {
    return (
      <FocusPanelFrame
        title="Example Focus Panel"
        panelName={this.props.panelName}
        className="example-focus-panel"
      >
        <iframe src="http://www.w3schools.com" className="content-iframe" />
      </FocusPanelFrame>
    );
  }
}
