import React from 'react';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';

import * as baseUI from '@/baseUI';
import * as panels from '@/panels';

interface SaveReminderPanelFrameProps {
  panelName: string;
  title: string;
  content: string;
  confirmLabel: string;
  onConfirm: () => void;
  keyboardShortcuts: Object;
}

export const NewWorkspaceSaveReminderPanelFrame: React.FC<
  SaveReminderPanelFrameProps
> = (props) => {
  return (
    <panels.frames.MessagePanelFrame
      panelName={props.panelName}
      title={props.title}
      confirmLabel={props.confirmLabel}
      onConfirm={props.onConfirm}
      illustration={<baseUI.symbol name="saveYourWork_NewWorkspace" />}
      confirmOnEnterPress
    >
      {props.content}
    </panels.frames.MessagePanelFrame>
  );
};

export const SaveReminderPanelFrame: React.FC<SaveReminderPanelFrameProps> = (
  props,
) => {
  return (
    <panels.frames.OldMessagePanelFrame
      panelName={props.panelName}
      panelTitle={props.title}
      confirmLabel={props.confirmLabel}
      onConfirm={props.onConfirm}
      keyboardShortcuts={props.keyboardShortcuts}
      className="save-reminder-panel save-publish-panel"
    >
      <Composites.RichTextWithIllustration>
        <Illustration>
          <baseUI.symbol name="saveYourWork" />
        </Illustration>
        <Composites.RichText>
          <RichText type="T01">{props.content}</RichText>
        </Composites.RichText>
      </Composites.RichTextWithIllustration>
    </panels.frames.OldMessagePanelFrame>
  );
};
