import type React from 'react';

export interface CharacterSetItemType {
  characterSet: string;
  label: string;
  value?: boolean;
  tooltip?: string;
  disabled?: boolean;
  position?: string;
  style?: React.CSSProperties;
}

export const CHARACTER_SET_DATA = [
  {
    characterSet: 'latin',
    position: '0px 0px',
    label: 'add_languages_english',
    tooltip: 'add_languages_english_tooltip',
    disabled: true,
  },
  {
    characterSet: 'latin-ext',
    position: '-15px -46px',
    label: 'add_languages_eastern_european',
    tooltip: 'add_languages_eastern_european_tooltip',
  },
  {
    characterSet: 'cyrillic',
    position: '-15px -19px',
    label: 'add_languages_cyrillic',
    tooltip: 'add_languages_cyrillic_tooltip',
  },
  {
    characterSet: 'japanese',
    position: '-15px -123px',
    label: 'add_languages_japanese',
  },
  {
    characterSet: 'korean',
    position: '-15px -96px',
    label: 'add_languages_korean',
  },
  {
    characterSet: 'arabic',
    position: '-15px -150px',
    label: 'add_languages_arabic',
  },
  {
    characterSet: 'hebrew',
    position: '-15px -70px',
    label: 'add_languages_hebrew',
  },
];
