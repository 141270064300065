._iframe-invisible_o0miw_1 {
  visibility: hidden; }

._preloader-container_o0miw_4 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

._preloader-timeout-container_o0miw_12 {
  width: auto;
  height: 100%;
  padding: 12px 12px 12px; }
