import type {
  TextClassificationResponseData,
  TextClassificationProtoResponse,
} from '../types';

export default function parseProtoData(
  data: TextClassificationProtoResponse,
): TextClassificationResponseData | {} {
  const textTypes = data?.output?.values?.textTypes;
  if (textTypes) {
    return Object.entries(textTypes.propertyValMap.mapVal).reduce(
      (acc, [compId, value]) => ({
        ...acc,
        [compId]: {
          classification: value.propertyValMap.mapVal?.classification?.strVal,
        },
      }),
      {},
    );
  }
  return {};
}
