import { fedopsLogger } from '@/util';

import { fetchPredictionModel } from './fetchPredictionModel';

import type { DocumentServicesObject } from 'types/documentServices';
import type { GroupingItem } from '../../api/getPageGrouping';

interface PredictionComp {
  id: string;
}

interface PredictionGrouping {
  tag: string;
  id: string;
  children?: PredictionComp[];
}

export async function getPredictedGrouping(
  documentServices: DocumentServicesObject,
  pageId: string,
): Promise<GroupingItem[]> {
  const pageTitle = documentServices.pages.getPageTitle(pageId);
  // eslint-disable-next-line no-console
  console.time('[Sections Migration] Prediction time');

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.SECTIONS_MIGRATION.PAGE_PREDICTION,
  );

  const predictionModel = await fetchPredictionModel(documentServices, pageId);

  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.SECTIONS_MIGRATION.PAGE_PREDICTION,
  );

  const rootGroups =
    predictionModel?.children?.find(
      (group: { id: string }) => group.id === 'G_0',
    )?.children ?? [];

  // TODO: remove it before Editor Migration launch
  // eslint-disable-next-line no-console
  console.log('[Sections Migration] Prediction Model', predictionModel);

  const grouping = rootGroups
    .map(
      (item: PredictionGrouping): GroupingItem => ({
        name: pageTitle,
        contentRole: null,
        children:
          item.tag === 'Component'
            ? [item.id]
            : item.children?.map(({ id }: PredictionComp) => id),
      }),
    )
    .filter((item: GroupingItem) => item.children?.length);

  // eslint-disable-next-line no-console
  console.timeEnd('[Sections Migration] Prediction time');

  return grouping;
}
