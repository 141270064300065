._container_eooys_1 {
  position: relative;
  width: 100%;
  height: 100%; }

._message_eooys_6 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

._dismiss_eooys_17 {
  margin-top: 10px; }

._slideFadingIn_eooys_20 {
  opacity: 0;
  transform: translateY(100%); }

._slideFadingInActive_eooys_24 {
  opacity: 1;
  transform: translateY(0);
  transition: all 250ms; }

._delayedTransition_eooys_29 {
  transition-delay: 125ms; }

._longDelayedTransition_eooys_32 {
  transition-delay: 1.25s; }

._business-app-success-animation_eooys_35 {
  width: 100%;
  height: 100%; }
