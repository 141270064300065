import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createComponentPanelPreloaderApi } from './componentPanelPreloaderWrapper';
import { ComponentPanelPreloaderApiKey } from './publicApiKey';

export const ComponentPanelPreloaderEntrypoint: EntryPoint = {
  name: 'ComponentPanelPreloaderApi',
  declareAPIs: () => [ComponentPanelPreloaderApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(ComponentPanelPreloaderApiKey, () =>
      createComponentPanelPreloaderApi(shell),
    );
  },
};
