{
  "type": "Component",
  "skin": "wysiwyg.viewer.skins.AppPartSkin",
  "layout": {
    "width": 670,
    "height": 1008,
    "x": 0,
    "y": 0,
    "scale": 1,
    "rotationInDegrees": 0,
    "fixedPosition": false
  },
  "componentType": "wixapps.integration.components.AppPart",
  "data": {
    "type": "AppPart",
    "metaData": {
      "isPreset": false,
      "schemaVersion": "1.0",
      "isHidden": false
    },
    "appInnerID": "12",
    "appPartName": 123454321,
    "viewName": "MediaZigzagPage",
    "appLogicCustomizations": [
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "key": "readMorePosition",
        "value": "left",
        "fieldId": "vars",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "mode": "view",
        "key": "comp.italic",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "mode": "view",
        "key": "comp.bold",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "view",
        "key": "comp.italic",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "view",
        "key": "comp.bold",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "view",
        "key": "comp.italic",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "view",
        "key": "comp.bold",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "view",
        "key": "comp.italic",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "view",
        "key": "comp.bold",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "view",
        "key": "comp.italic",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "view",
        "key": "comp.bold",
        "value": "false",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "ReadMoreBtn",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "text",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "text",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "comp.style",
        "value": "Body S",
        "fieldId": "date",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "VideoPost",
        "view": "MediaInner",
        "mode": "view",
        "key": "comp.style",
        "value": "wp2",
        "fieldId": "video",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "PhotoPost",
        "view": "MediaInner",
        "mode": "view",
        "key": "comp.style",
        "value": "wp2",
        "fieldId": "photo",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "text",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "comp.style",
        "value": "Heading M",
        "fieldId": "title",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "text",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "ReadMoreBtn",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "comp.style",
        "value": "Body M",
        "fieldId": "text",
        "format": "*"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "14",
        "fieldId": "def_13"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "*",
        "key": "padding",
        "value": "22",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "12",
        "fieldId": "def_11"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "0",
        "fieldId": "def_11"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "*",
        "key": "padding",
        "value": "22",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "*",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "*",
        "key": "padding",
        "value": "22",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "*",
        "key": "layout.width",
        "value": "254",
        "fieldId": "MediaSize"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "*",
        "key": "padding",
        "value": "22",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "*",
        "key": "layout.width",
        "value": "254",
        "fieldId": "MediaSize"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "*",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "*",
        "key": "padding",
        "value": "22",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "*",
        "key": "layout.width",
        "value": "254",
        "fieldId": "MediaSize"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "*",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "*",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "*",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "13",
        "fieldId": "title"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "10",
        "fieldId": "title"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "11",
        "fieldId": "title"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "13",
        "fieldId": "title"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "def_10"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "def_27"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "def_10"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "*",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "comp.size",
        "value": "16",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "13",
        "fieldId": "title"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "15",
        "fieldId": "text"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "15",
        "fieldId": "text"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "*",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "ReadMoreBtn"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRight",
        "mode": "*",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "ReadMoreBtn"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeft",
        "mode": "*",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "ReadMoreBtn"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "22",
        "fieldId": "def_11"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "ReadMoreBtn"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "view",
        "key": "layout.spacerAfter",
        "value": "20",
        "fieldId": "def_13"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "view",
        "key": "comp.size",
        "value": "22",
        "fieldId": "def_17"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzag",
        "mode": "view",
        "key": "layout.spacerBefore",
        "value": "22",
        "fieldId": "WholePost"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottom",
        "mode": "*",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "ReadMoreBtn"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTop",
        "mode": "*",
        "key": "layout.spacerBefore",
        "value": "0",
        "fieldId": "ReadMoreBtn"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "*",
        "key": "padding",
        "value": "20",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "*",
        "key": "comp.size",
        "value": "30",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "*",
        "key": "padding",
        "value": "20",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "*",
        "key": "padding",
        "value": "20",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "*",
        "key": "comp.size",
        "value": "30",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "*",
        "key": "padding",
        "value": "20",
        "fieldId": "vars"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "*",
        "key": "comp.size",
        "value": "30",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "mode": "view",
        "key": "comp.style",
        "value": "c1",
        "fieldId": "def_2"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaTopPage",
        "mode": "*",
        "key": "comp.format",
        "value": "longDate",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaBottomPage",
        "mode": "*",
        "key": "comp.format",
        "value": "longDate",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaRightPage",
        "mode": "*",
        "key": "comp.format",
        "value": "longDate",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "*",
        "key": "comp.format",
        "value": "longDate",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "mode": "*",
        "key": "comp.format",
        "value": "longDate",
        "fieldId": "date"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "*",
        "key": "comp.size",
        "value": "30",
        "fieldId": "PostSpace"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "mode": "view",
        "key": "comp.style",
        "value": "c1",
        "fieldId": "def_2"
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaLeftPage",
        "key": "layout.width",
        "value": "303",
        "fieldId": "MediaSize",
        "format": ""
      },
      {
        "type": "AppPartCustomization",
        "forType": "Array",
        "view": "MediaLeftPage",
        "key": "comp.pack",
        "value": "end",
        "fieldId": "paginationNavigationBox",
        "format": ""
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "key": "comp.size",
        "value": "20",
        "fieldId": "PostSpace",
        "format": ""
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "key": "padding",
        "value": "30",
        "fieldId": "vars",
        "format": ""
      },
      {
        "type": "AppPartCustomization",
        "forType": "Post",
        "view": "MediaZigzagPage",
        "key": "readMorePosition",
        "value": "left",
        "fieldId": "vars",
        "format": ""
      },
      {
        "type": "AppPartCustomization",
        "forType": "Array",
        "view": "MediaZigzagPage",
        "key": "comp.pack",
        "value": "end",
        "fieldId": "paginationNavigationBox",
        "format": ""
      }
    ],
    "appLogicParams": {
      "filter": {
        "type": "AppPartParam",
        "value": ""
      },
      "limit": {
        "type": "AppPartParam",
        "value": "20"
      },
      "sort": {
        "type": "AppPartParam",
        "value": "{'date.iso':-1}"
      }
    }
  },
  "props": {
    "type": "AppPartProperties",
    "metaData": {
      "schemaVersion": "1.0"
    },
    "direction": "ltr"
  },
  "style": {
    "type": "TopLevelStyle",
    "id": "blog_4de5abc5-6da2-4f97-acc3-94bb74285072_1",
    "metaData": {
      "isPreset": false,
      "schemaVersion": "1.0",
      "isHidden": false
    },
    "style": {
      "properties": {
        "rd": "7px"
      },
      "propertiesSource": {
        "rd": "value"
      },
      "groups": {}
    },
    "componentClassName": "",
    "pageId": "",
    "compId": "",
    "styleType": "system",
    "skin": "wysiwyg.viewer.skins.AppPartSkin"
  },
  "id": "i6852dq0_0"
}
