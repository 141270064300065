// @ts-nocheck
import programsListWidget from './programsListWidget/programsListWidget';
import type { EditorAPI } from '@/editorAPI';

export default {
  getCategorySections(editorAPI: EditorAPI) {
    return {
      programsListWidget: programsListWidget({ editorAPI }),
    };
  },
};
