import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import * as coreBi from '@/coreBi';
import * as Panels from '@/panels';
import * as tpa from '@/tpa';
import { Button, Composites, PromotionalList } from '@wix/wix-base-ui';
import utils from '../appMenu/utils';
import { symbol as SymbolComponent } from '@wix/santa-editor-symbols';
import type { IntentWelcomePanelProps } from '../common/wixStoreIntentService';
import { tpaUtils } from '@/util';

interface IIntentWelcomePanel extends IntentWelcomePanelProps {
  panelName: string;
  closeByESC: Function;
  close: (e: AnyFixMe) => void;
  onCtaClick: (e: AnyFixMe) => void;
  handleShouldCloseOnBlur: Function;
  shouldNotCloseOnBlur: boolean;
}

class IntentWelcomePanel extends React.Component<IIntentWelcomePanel> {
  getKeyboardShortcuts() {
    return {
      esc: this.props.closeByESC,
    };
  }

  render() {
    return (
      <div data-aid="ecom-custom-welcome-screen">
        <Panels.frames.FocusPanelFrame
          panelName={this.props.panelName}
          shouldHideHeader={true}
          shouldHideHelp={true}
          isBareMode={true}
          className={`welcome-screen-panel ${this.props.className}`}
          onClose={this.props.handleShouldCloseOnBlur}
          keyboardShortcuts={this.getKeyboardShortcuts()}
          shouldNotCloseOnBlur={this.props.shouldNotCloseOnBlur}
        >
          <header className="panel-header dark" key="header">
            <button className="close" onClick={this.props.close}>
              <SymbolComponent name="headerCloseButton" />
            </button>
            <div className="welcome-panel-header-title">
              {translate(this.props.title)}
            </div>
            <SymbolComponent
              className="store-image"
              name={this.props.imageName}
            />
          </header>

          <div className="content">
            <Composites.PromotionalList>
              <PromotionalList items={this.props.bullets} />
            </Composites.PromotionalList>
            <div className="find-button">
              <baseUI.button
                className="btn-confirm-primary manage-btn"
                onClick={this.props.onCtaClick}
                label={this.props.cta}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button onClick={this.props.close} className="btn-text">
                {translate(this.props.customizedLink)}
              </Button>
            </div>
          </div>
        </Panels.frames.FocusPanelFrame>
      </div>
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe, props: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  const close = () => editorAPI.panelManager.closePanelByName(props.panelName);
  return {
    close,
    onCtaClick() {
      if (editorAPI.dsRead.tpa.app.isInstalled(tpaUtils.getStoresAppDefId())) {
        utils.openStoreManager(editorAPI, 'FTEstartAddingProducts', {
          state: '/products',
          origin: props.panelName,
        });
      } else {
        const appMarketPanelProps = {
          urlParams: {
            slug: tpaUtils.getStoresAppDefId(),
          },
        };

        tpa.appMarketTabService.openAppMarketTab(
          appMarketPanelProps,
          editorAPI.panelManager.openPanel,
        );
      }
      close();
    },
    closeByESC() {
      editorAPI.bi.event(
        coreBi.events.welcomeVideoPanel.WELCOME_SCREEN_CLOSED,
        {
          panel_name: props.panelName,
          origin: 'ESC',
        },
      );
      close();
    },
    handleShouldCloseOnBlur() {
      editorAPI.bi.event(
        coreBi.events.welcomeVideoPanel.WELCOME_SCREEN_CLOSED,
        {
          panel_name: props.panelName,
          origin: 'outerClick',
        },
      );
      return true;
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(IntentWelcomePanel);
