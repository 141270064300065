import _ from 'lodash';
import * as coreBi from '@/coreBi';
import { arrayUtils } from '@/util';
import {
  componentSelectors,
  selectionActions,
  interactionsSelectors,
} from '@/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { ComponentsModesApi } from './componentsModesApi';

export function createComponentsReparentApi({
  editorAPI,
  componentsModesApi,
}: {
  editorAPI: EditorAPI;
  componentsModesApi: ComponentsModesApi;
}) {
  function buildCompRootsInfoArr(compRefs: CompRef[], compRoots: AnyFixMe) {
    return _.zipWith(compRefs, compRoots, function (ref, root) {
      return {
        ref,
        root,
      };
    });
  }

  function buildCompModefulAncestorsInfoArr(
    compRefs: CompRef[],
    compModefulAncestors: AnyFixMe,
  ) {
    return _.zipWith(compRefs, compModefulAncestors, function (ref, ancestor) {
      return {
        ref,
        ancestor,
      };
    });
  }

  function sortCompRefsToEnsureConnections(
    compRefs: CompRef[],
    attachCandidateRef: CompRef,
  ): CompRef[] {
    const isFromMasterPage = editorAPI.components.isShowOnAllPages(compRefs);
    const isToMasterPage =
      editorAPI.components.isShowOnAllPages(attachCandidateRef);
    const shouldMoveControllersToTheTop = !isFromMasterPage && isToMasterPage;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      compRefs,
      function (res, compRef) {
        const compType = editorAPI.dsRead.components.getType(compRef);
        if (compType === 'platform.components.AppController') {
          if (shouldMoveControllersToTheTop) {
            res.unshift(compRef);
          } else {
            res.push(compRef);
          }
        } else if (shouldMoveControllersToTheTop) {
          res.push(compRef);
        } else {
          res.unshift(compRef);
        }
        return res;
      },
      [],
    );
  }

  function sendSetContainerBI(
    compRefsArr: CompRef[],
    attachCandidateRef: CompRef,
    currentContainer: CompRef,
  ) {
    const sourceType = editorAPI.components.getType(currentContainer);
    const isSourceRepeated =
      editorAPI.components.is.repeatedComponent(currentContainer);

    const targetType = editorAPI.components.getType(attachCandidateRef);

    const isTargetRepeated =
      editorAPI.components.is.repeatedComponent(attachCandidateRef);

    const { componentHasInteraction } = interactionsSelectors;

    const isTargetWithInteraction = componentHasInteraction(
      editorAPI,
      attachCandidateRef,
    );

    const isTargetContainerComp =
      editorAPI.components.is.container(attachCandidateRef);

    const isTargetHoverBoxComponent =
      isTargetContainerComp && isTargetWithInteraction;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compRefsArr, function (compRef) {
      const componentType = editorAPI.components.getType(compRef);

      editorAPI.bi.event(coreBi.events.stage.ATTACH_TO, {
        origin: 'stage',
        component_type: componentType,
        component_id: compRef.id,
        source_component: sourceType,
        target_component: targetType,
        target_component_id: attachCandidateRef.id,
        is_target_repeated: isTargetRepeated,
        is_source_repeated: isSourceRepeated,
        is_target_hover: isTargetHoverBoxComponent,
      });
    });
  }

  function setContainer(
    compRefs: CompRef | CompRef[],
    attachCandidateRef: CompRef,
    ignorePostUpdateSelection?: boolean,
  ) {
    const wasShowOnAllPages = editorAPI.components.isShowOnAllPages(compRefs);
    const compRefsArr = arrayUtils.asArray(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const compRoots = _.map(compRefsArr, editorAPI.components.getPage);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const compFirstModefulAncestors = _.map(
      compRefsArr,
      editorAPI.components.modes.getFirstAncestorWithActiveModes,
    );
    const sortedCompRefs = sortCompRefsToEnsureConnections(
      compRefsArr,
      attachCandidateRef,
    );
    const currentContainer = editorAPI.components.getContainer(compRefsArr);

    editorAPI.editorEventRegistry.dispatch(
      editorAPI.editorEventRegistry.constants.events.SET_CONTAINER_BEFORE,
      editorAPI,
      // @ts-expect-error
      sortedCompRefs,
      attachCandidateRef,
    );

    const updatedCompRefsSorted = sortedCompRefs.map((compRef) =>
      editorAPI.dsActions.components.setContainer(compRef, attachCandidateRef),
    );
    // NOTE: keep initial compRefsArr order in updatedCompRefs
    const updatedCompRefs = compRefsArr.map(
      (compRef) => updatedCompRefsSorted[sortedCompRefs.indexOf(compRef)],
    );
    const updatedRefsAndRoots = buildCompRootsInfoArr(
      updatedCompRefs,
      compRoots,
    );
    const updatedRefsAndModefulAncestors = buildCompModefulAncestorsInfoArr(
      updatedCompRefs,
      compFirstModefulAncestors,
    );

    if (
      !wasShowOnAllPages &&
      editorAPI.utils.isSameRef(
        attachCandidateRef,
        editorAPI.dsRead.siteSegments.getHeader(),
      )
    ) {
      editorAPI.panelHelpers.openDragToHeaderPanel();
    }

    editorAPI.dsActions.waitForChangesApplied(function () {
      editorAPI.pluginService.getPlugin('setContainer', '*')(
        attachCandidateRef,
        updatedRefsAndRoots,
        updatedRefsAndModefulAncestors,
        editorAPI.components,
        editorAPI.behaviors,
      );

      if (!ignorePostUpdateSelection) {
        editorAPI.selection.selectComponentByCompRef(updatedCompRefs);
      }

      const focusedContainer =
        editorAPI.selection.getParentToFocus(updatedCompRefs);
      editorAPI.store.dispatch(
        selectionActions.setFocusedContainer(focusedContainer),
      );
      const appContainerToFocus = arrayUtils.isMultiselect(updatedCompRefs)
        ? null
        : componentSelectors.getAppContainerIfExists(
            _.head(updatedCompRefs),
            focusedContainer,
            editorAPI.dsRead,
          );
      editorAPI.store.dispatch(
        selectionActions.setAppContainer(appContainerToFocus),
      );
      if (
        editorAPI.utils.isSameRef(focusedContainer, attachCandidateRef) &&
        componentsModesApi.isModefulComponent(attachCandidateRef)
      ) {
        editorAPI.tabIndicationState.activateAddAnimation(true);
      }

      sendSetContainerBI(updatedCompRefs, attachCandidateRef, currentContainer);
      componentsModesApi.addDefaultSingleModeAnimations(updatedCompRefs);

      if (
        editorAPI.components.is.group(currentContainer) &&
        editorAPI.components.getChildren(currentContainer).length === 1
      ) {
        editorAPI.components.ungroup(currentContainer);
      }
    });

    return updatedCompRefs.length > 1
      ? updatedCompRefs
      : _.head(updatedCompRefs);
  }

  return {
    setContainer,
  };
}
