/*eslint max-lines: [2, { "max": 1970, "skipComments": true, "skipBlankLines": true}]*/
import createReactClass from 'create-react-class';
import React from 'react';
import _ from 'lodash';
import experiment from 'experiment';
import { Divider, ToggleSwitch } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import constants, { RCM_ORIGIN } from '@/constants';
import * as helpIds from '@/helpIds';
import keyboardMap from '@/keyboardShortcuts';
import * as util from '@/util';
import { EditorRestrictionsApiKey, ImageToBackgroundApiKey } from '@/apis';
import ShowOnAllPagesToggle from '../showOnAllPagesToggle/showOnAllPagesToggle';
import ItemBasic from './menuItems/rcmItemBasic';
import ItemWithShortcut from './menuItems/rcmItemWithShortcut';
import ItemWithSubItems from './menuItems/rcmItemWithSubItems';
import PagesMenuContextMenu from './menusByOrigin/pagesMenuContextMenu';
import PagesMenuItemContextMenu from './menusByOrigin/pagesMenuItemContextMenu';
import { RightClickDebug } from './rightClickDebug/rightClickDebug';
import advancedCustomActions from './advancedCustomActions';
import customActions from './customActions';
import { handlePinClick } from './handlers/handlePinClick';
import { mapDispatchToProps, mapStateToProps } from './rightClickMenuMapper';

import type { RightClickMenuItems } from './util/rcmDataParser';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { UIRestrictionRCM } from './uiRestriction';

const { ACTIONS: GFPP_ACTIONS } = constants.ROOT_COMPS.GFPP;

const { getSessionUserPreferences, getSiteUserPreferences } =
  stateManagement.userPreferences.selectors;

const MY_ACCOUNT = `${util.serviceTopology.dashboardUrl.replace(
  /create\//,
  '',
)}/`;
const SUBMENU_OFFSET = 14;
const RCM_CLIENT_HEIGHT_OFFSET = 3;
const UP = -1;
const DOWN = 1;
const MIDDLE = 2;
const PASTE_OFFSET = 30;

// TODO: fix offsets

const { getViewPort } = stateManagement.domMeasurements.selectors;
let editorAPI: EditorAPI;
const bi = coreBi;

interface ContextMenuItem extends Record<string, unknown> {
  key: string;
  disabled?: boolean;
  children?: ContextMenuItem[];
}

interface CompCommonBiParams {
  component_id: string;
  component_type: string;
}

function shouldShowHideLabel(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
): boolean {
  return (
    (editorAPI.isMobileEditor() &&
      compRefs.some(editorAPI.components.is.hiddenable)) ||
    isRefComponentHiddenable(compRefs)
  );
}

function shouldShowMobileOnlyMixesMultiselectLabel(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
): boolean {
  return (
    editorAPI.isMobileEditor() &&
    compRefs.some(
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent,
    ) &&
    compRefs.some(
      (compRef) =>
        !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
          compRef,
        ),
    )
  );
}

function shouldShowMobileOnlyDeleteLabel(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
): boolean {
  return (
    editorAPI.isMobileEditor() &&
    compRefs.every(
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent,
    )
  );
}

function isCompInCollection(compRef: CompRef, compsCollection: CompRef[]) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(compsCollection, (comp) => _.isEqual(comp, compRef));
}

function isRefComponentHiddenable(selectedComponents: CompRef[]) {
  return (
    stateManagement.components.selectors.isReferredComponent(
      _.head(selectedComponents),
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
    ) && _.some(selectedComponents, editorAPI.components.is.removable)
  );
}

function getVerticalPositionAccordingToViewPort(
  viewPort: AnyFixMe,
  element: Element,
  yPosition: number,
) {
  const rcmTop = yPosition;
  const rcmHeight = util.elementUtils.getOuterHeight(element);
  const rcmBottom = rcmTop + rcmHeight;
  const topY = viewPort.stageLayout.top + viewPort.y;

  if (rcmBottom > viewPort.bottomY) {
    const possibleRcmTop = rcmTop - rcmHeight;

    return Math.max(topY, possibleRcmTop);
  }

  return rcmTop;
}

function getHorizontalPositionAccordingToViewPort(
  viewPort: AnyFixMe,
  element: Element,
  xPosition: number,
) {
  const rcmLeft = xPosition;
  const rcmWidth = util.elementUtils.getOuterWidth(element);
  const rcmRight = rcmLeft + rcmWidth;

  if (rcmRight - window.pageXOffset > viewPort.rightX) {
    const possibleRcmTop = rcmLeft - rcmWidth;
    if (possibleRcmTop < viewPort.x) {
      return rcmLeft + viewPort.x;
    }
    return possibleRcmTop;
  }

  return rcmLeft;
}

function getPositionAccordingToViewPort(
  viewPortLayout: AnyFixMe,
  element: Element,
  position: AnyFixMe,
) {
  const xPosition = getHorizontalPositionAccordingToViewPort(
    viewPortLayout,
    element,
    position.x,
  );
  const yPosition = getVerticalPositionAccordingToViewPort(
    viewPortLayout,
    element,
    position.y,
  );

  return { x: xPosition, y: yPosition };
}

function shouldOpenSubMenuToLeft(
  viewPortLayout: AnyFixMe,
  element: Element,
  position: AnyFixMe,
) {
  const subMenus = element ? element.querySelectorAll('.subMenu') : null;
  if (subMenus && subMenus.length === 0) {
    return false;
  }
  const widestSubMenu = _.maxBy(subMenus, (subMenu) =>
    util.elementUtils.getOuterWidth(subMenu),
  );
  return (
    position.x +
      util.elementUtils.getOuterWidth(element) +
      util.elementUtils.getOuterWidth(widestSubMenu) >
    viewPortLayout.rightX
  );
}

function setBIRCMParam(
  paramName: string,
  paramValue: unknown,
  biParams: object,
) {
  _.set(biParams, paramName, paramValue);
}

const getHistoryParamsWithOverrides = (compsBIParams: CompCommonBiParams[]) => {
  if (!compsBIParams?.length) return {};

  return {
    component_id: compsBIParams
      .map((compParam) => compParam.component_id)
      .join(','),
    component_type: compsBIParams
      .map((compParam) => compParam.component_type)
      .join(','),
  };
};

export interface RightClickMenuOwnProps {}

export interface RightClickMenuStateProps {
  menuItems: RightClickMenuItems;
  selectedComponents: CompRef[];
  clipboard: AnyFixMe;
  previewPosition: AnyFixMe;
  isComponentShowOnAllPages: boolean;
  forceSOAPForNonContainable: boolean;
  removeSelectedGuide: Function;
  removeAllGuidesInRuler: Function;
  context: object;
  origin: RCM_ORIGIN;
  biOrigin: string;
  editorMousePosition: {
    x: number;
    y: number;
  };
  viewerMousePosition: {
    x?: number;
    y?: number;
  };
  shouldShowComponentActions: boolean;
  openRCMInteraction?: ReturnType<typeof util.fedopsLogger.mapInteraction>;
}

export interface RightClickMenuDispatchProps {
  setHoverBox: (compRef: CompRef) => void;
  clearHoverBox: () => void;
  toggleBackgroundTargetHighlight: (
    backgroundTargetCompRef: CompRef,
    shouldHighlight: boolean,
  ) => void;
}

export interface RightClickMenuProps
  extends RightClickMenuStateProps,
    RightClickMenuDispatchProps,
    RightClickMenuOwnProps {}
type ScrollPosition = typeof UP | typeof MIDDLE | typeof DOWN | null;

// eslint-disable-next-line react/prefer-es6-class
export const RightClickMenuComponent = createReactClass<RightClickMenuProps>({
  displayName: 'rightClickMenu',
  mixins: [core.mixins.editorAPIMixin],
  compType: null,
  onOuterClick() {
    editorAPI.closeRightClickMenu();
  },

  getInitialState() {
    editorAPI = this.getEditorAPI();
    return {
      position: this.props.editorMousePosition,
      openSubMenuToLeft: false,
      subMenuPositionY: 0,
      scrollPosition: UP,
    };
  },
  componentDidMount() {
    this.compType = editorAPI.components.getType(this.props.selectedComponents);
    this.updatePositionRelativeToViewPort();
    this.onScrollEvent();
    this.props.openRCMInteraction?.end?.();
    editorAPI.bi.event(bi.events.rightClickMenu.RIGHT_CLICK_MENU_OPEN, {
      isScrollable: this.isScrollable(),
      component: this.compType,
      component_id: this.props.selectedComponents?.[0]?.id,
      origin: this.props.biOrigin,
    });
    ErrorReporter.breadcrumb('rightClick menu opened', {
      origin: this.props.origin,
      compRef: this.props.selectedComponents?.[0],
    });
  },
  componentDidUpdate(prevProps) {
    if (this.props.selectedComponents) {
      const isSameComponent = _.isEqual(
        prevProps.selectedComponents,
        this.props.selectedComponents,
      );

      if (!isSameComponent) {
        this.updatePositionRelativeToViewPort();
      }
    }
  },

  componentWillUnmount() {
    editorAPI.bi.event(bi.events.rightClickMenu.RIGHT_CLICK_MENU_CLOSE, {
      origin: this.props.biOrigin,
      component: this.compType,
    });
  },
  originContainsRulersOrGuides() {
    const { origin } = this.props;

    return origin.includes('ruler') || origin.includes('guide');
  },
  getSelectedCompsRestrictions() {
    const editorState = editorAPI.store.getState();
    return stateManagement.selection.selectors.getSelectedCompRestrictions(
      editorState,
    );
  },
  updatePositionRelativeToViewPort() {
    const rcmDOMNode = this.refs.rcmRoot;
    const editorState = editorAPI.store.getState();
    const viewPort = getViewPort(editorState);
    const positionRelativeToViewPort = getPositionAccordingToViewPort(
      viewPort,
      rcmDOMNode,
      this.state.position,
    );
    const openSubMenuToLeft = shouldOpenSubMenuToLeft(
      viewPort,
      rcmDOMNode,
      this.state.position,
    );
    this.setState({
      position: positionRelativeToViewPort,
      openSubMenuToLeft,
    });
  },
  onOverlappingItemMouseEnter(subItem: AnyFixMe) {
    editorAPI.bi.event(bi.events.rightClickMenu.OVERLAPPING_ITEMS_HOVER, {
      component: isCompInCollection(
        subItem.component,
        this.props.selectedComponents,
      )
        ? 'selected'
        : 'other',
      origin: this.props.biOrigin,
    });

    this.props.setHoverBox(subItem.component);
  },
  onOverlappingItemMouseLeave() {
    this.props.clearHoverBox();
  },

  calculateSubMenuTop(e: React.MouseEvent<HTMLElement>) {
    const menu = this.refs.rcmStageContentWrapper;
    const position =
      e.currentTarget?.offsetTop - menu.scrollTop - SUBMENU_OFFSET;

    this.setState({ subMenuPositionY: position });
  },

  startIntervalScroll(scrollDirection: ScrollPosition) {
    const menu = this.refs.rcmStageContentWrapper;
    let newPosition, canScroll;

    this.scrollIntervalId = setInterval(() => {
      newPosition = menu.scrollTop + scrollDirection;
      canScroll =
        scrollDirection === UP
          ? newPosition !== -1
          : newPosition <= menu.scrollHeight - menu.clientHeight;
      if (canScroll) {
        menu.scrollTo(0, newPosition);
        if (this.state.scrollPosition !== MIDDLE) this.toggleScrollArrows();
      } else {
        this.toggleScrollArrows();
        this.stopIntervalScroll();
      }
    }, 5);
  },

  setScrollPosition(scrollPosition: ScrollPosition): void {
    this.setState({ scrollPosition });
  },

  stopIntervalScroll() {
    if (this.scrollIntervalId) {
      clearInterval(this.scrollIntervalId);
      this.scrollIntervalId = null;
    }
  },

  isScrollable() {
    const menu = this.refs.rcmStageContentWrapper;
    const noMenuScroll =
      menu?.scrollHeight <= menu?.clientHeight + RCM_CLIENT_HEIGHT_OFFSET;

    return !(!menu || noMenuScroll);
  },

  onScrollEvent(e?: React.MouseEvent<HTMLElement>): boolean {
    if (this.state.scrollPosition === null) return;

    if (!this.isScrollable()) {
      this.setScrollPosition(null);
      return;
    }

    if (e?.nativeEvent.type === 'mouseout') {
      const scrollDirection =
        (e.target as AnyFixMe).className === 'scroll-arrow-up' ? UP : DOWN;
      this.startIntervalScroll(scrollDirection);
      editorAPI.bi.event(bi.events.rightClickMenu.RCM_SCROLL_BUTTON_HOVERED, {
        component: this.compType,
        scroll_direction: scrollDirection === UP ? 'up' : 'down',
      });
    } else {
      this.toggleScrollArrows();
    }
  },

  toggleScrollArrows(): void {
    const menu = this.refs.rcmStageContentWrapper;
    if (!menu) return;
    const isAtTop = !menu.scrollTop;
    const isAtBottom =
      Math.ceil(menu.scrollTop) >= menu.scrollHeight - menu.clientHeight;
    let position = MIDDLE;

    if (isAtTop) {
      position = UP;
    } else if (isAtBottom) {
      position = DOWN;
    }

    if (this.state.scrollPosition !== position)
      this.setScrollPosition(position);
  },

  getClasses() {
    return {
      rightClickMenu: true,
      'open-sub-menu-left': this.state.openSubMenuToLeft,
      'fixed-position':
        this.getEditorAPI().components.layout.isShowOnFixedPosition(
          this.props.selectedComponents,
        ),
    };
  },
  onRightClick(evt: React.MouseEvent) {
    evt.preventDefault();
  },

  shouldShowNotificationOnUndo(undoCount: number) {
    const topBarSiteHistoryUsed =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        'topBarSiteHistoryUsed',
      )(editorAPI.store.getState());
    const siteHistoryNotificationAppeared =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        'siteHistoryNotificationAppeared',
      )(editorAPI.store.getState());
    const isFirstSave =
      _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
    const isDraftMode =
      _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;
    return (
      !isFirstSave &&
      !isDraftMode &&
      !siteHistoryNotificationAppeared &&
      !topBarSiteHistoryUsed &&
      undoCount >= constants.USER_PREFS.UNDO.UNDO_THRESHOLD
    );
  },

  undo() {
    const { biOrigin } = this.props;
    const biParams =
      editorAPI.documentServices.history.getUndoLastSnapshotParams();
    this.getEditorAPI().history.undo();
    editorAPI.closeRightClickMenu();

    let undoCount =
      (getSessionUserPreferences(constants.USER_PREFS.UNDO.UNDO_COUNT)(
        editorAPI.store.getState(),
      ) as number) || 0;
    if (this.shouldShowNotificationOnUndo(undoCount)) {
      const metaSiteId = editorAPI.documentServices.generalInfo.getMetaSiteId();
      const url = `${MY_ACCOUNT}history/${metaSiteId}/?referralInfo=EDITOR`;
      const onNotificationLinkClick = () => {
        window.open(url);
      };
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: 'Notifications_Site_History_Text',
          title: 'Site History',
          type: 'info',
          origin: this.props.biOrigin,
          link: {
            caption: 'Notifications_Site_History_Link',
            onNotificationLinkClick,
          },
        }),
      );
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          'siteHistoryNotificationAppeared',
          true,
        ),
      );
    } else {
      undoCount += 1;
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          constants.USER_PREFS.UNDO.UNDO_COUNT,
          undoCount,
        ),
      );
    }
    editorAPI.bi.event(bi.events.editor.undo_redo_component_change, {
      type: 'undo',
      origin: biOrigin,
      pageId: biParams?.currentPage,
      date_of_action: biParams?.date_of_action,
      actionName: biParams?.label,
      component_id: biParams?.component_id,
      component_type: biParams?.component_type,
    });
  },

  cutComponent() {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.CUT_COMPONENT,
    );
    const compsBIParams = editorAPI.bi.getComponentsBIParams(
      this.props.selectedComponents,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      compsBIParams,
      _.partial(setBIRCMParam, 'origin', this.props.biOrigin),
    );

    editorAPI.bi.reportBI(bi.events.rightClickMenu.CUT, compsBIParams);
    editorAPI.closeRightClickMenu();
    editorAPI.selectedComponent.cut();

    editorAPI.history.add(
      'cut component via rightClickMenu',
      getHistoryParamsWithOverrides(compsBIParams),
    );
  },
  copyComponent() {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.COPY_COMPONENTS,
    );

    const compsBIParams = editorAPI.bi.getComponentsBIParams(
      this.props.selectedComponents,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      compsBIParams,
      _.partial(setBIRCMParam, 'origin', this.props.biOrigin),
    );

    editorAPI.selectedComponent.copy();
    editorAPI.closeRightClickMenu();

    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.COPY_COMPONENTS,
    );
    editorAPI.bi.reportBI(bi.events.rightClickMenu.COPY, compsBIParams);
  },
  pasteComponent(shouldApplyTheme: boolean) {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.PAST_COMPONENT_OR_TPA,
    );

    const FEDOPS_INTERACTION = editorAPI.clipboard.isCrossSite()
      ? util.fedopsLogger.INTERACTIONS.PASTE_COMPONENTS_CROSS_SITE
      : util.fedopsLogger.INTERACTIONS.PASTE_COMPONENTS_SAME_SITE;
    util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);

    const compsBIParams = editorAPI.bi.getComponentsBIParams(
      this.props.selectedComponents,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      compsBIParams,
      _.partial(setBIRCMParam, 'origin', this.props.biOrigin),
    );

    const isFixedStageEnabled = util.fixedStage.isFixedStageEnabled();

    const pasteOffsetRelativeToEditorType = editorAPI.isMobileEditor()
      ? PASTE_OFFSET / 2
      : PASTE_OFFSET;
    const positionToPaste = {
      x: this.props.viewerMousePosition.x + pasteOffsetRelativeToEditorType,
      y:
        this.props.editorMousePosition.y -
        util.topBar.getHeightConst() -
        (isFixedStageEnabled ? constants.UI.FIXED_STAGE_MARGIN_TOP : 0),
    };

    editorAPI.copyPaste
      .paste(positionToPaste, shouldApplyTheme, this.props.biOrigin)
      .then(() => {
        editorAPI.closeRightClickMenu();
        editorAPI.history.add('paste component via rightClickMenu', {
          isAddingComponent: true,
        });

        util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
        editorAPI.bi.reportBI(bi.events.rightClickMenu.PASTE, compsBIParams);
      });
  },
  pastePage(shouldApplyTargetTheme: boolean) {
    const FEDOPS_INTERACTION = editorAPI.clipboard.isCrossSite()
      ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
      : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
    util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);

    return editorAPI.copyPaste
      .pastePage({
        shouldApplyTargetTheme,
        origin: 'rcm_stage',
      })
      .then(() => {
        editorAPI.closeRightClickMenu();
        util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
      });
  },
  paste(shouldApplyTheme: boolean) {
    if (editorAPI.copyPaste.hasComponentToPaste()) {
      return this.pasteComponent(shouldApplyTheme);
    }

    if (editorAPI.copyPaste.hasPageToPaste()) {
      return this.pastePage(shouldApplyTheme);
    }
  },

  applyModeFromClipboard() {
    const compsBIParams = editorAPI.bi.getComponentsBIParams(
      this.props.selectedComponents,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      compsBIParams,
      _.partial(setBIRCMParam, 'origin', this.props.biOrigin),
    );

    editorAPI.copyPaste.applyModeFromClipboard(
      editorAPI.selection.getSelectedComponents(),
    );
    editorAPI.closeRightClickMenu();

    editorAPI.bi.reportBI(
      bi.events.rightClickMenu.APPLY_MODE_FROM_CLIPBOARD,
      compsBIParams,
    );
  },
  async duplicateComponent() {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.DUPLICATE_COMPONENT_OR_TPA,
    );
    const compsBIParams = editorAPI.bi.getComponentsBIParams(
      this.props.selectedComponents,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      compsBIParams,
      _.partial(setBIRCMParam, 'origin', this.props.biOrigin),
    );

    await editorAPI.selectedComponent.duplicate();
    editorAPI.closeRightClickMenu();
    if (editorAPI.selection.getSelectedComponentType() !== 'Column') {
      editorAPI.history.add('duplicate component via rightClickMenu', {
        isAddingComponent: true,
      });
    }

    editorAPI.bi.reportBI(bi.events.rightClickMenu.DUPLICATE, compsBIParams);
  },
  removeComponent() {
    const compsBIParams = editorAPI.bi.getComponentsBIParams(
      this.props.selectedComponents,
    );

    compsBIParams.forEach((compParams) => {
      setBIRCMParam('removal_method', this.props.biOrigin, compParams);
      setBIRCMParam('origin', this.props.biOrigin, compParams);
    });

    editorAPI.bi.reportBI(bi.events.editor.component_removed, compsBIParams);

    const hasUndoBeenUsed = !!getSiteUserPreferences(
      constants.USER_PREFS.UNDO.UNDO_PREVIOUSLY_USED,
    )(editorAPI.store.getState());
    const firstTimeDeleteUsed = !!getSiteUserPreferences(
      constants.USER_PREFS.DELETE.FIRST_TIME_DELETE_USED,
    )(editorAPI.store.getState());
    if (!hasUndoBeenUsed || !firstTimeDeleteUsed) {
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          constants.USER_PREFS.DELETE.FIRST_TIME_DELETE_USED,
          true,
        ),
      );
      const onNotificationLinkClick = () => {
        editorAPI.panelManager.openHelpCenter(helpIds.NOTIFICATIONS.UNDO_REDO);
      };
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: 'Notifications_Undo_Redo_Text',
          title: 'Undo Redo',
          type: 'info',
          origin: this.props.biOrigin,
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick,
          },
        }),
      );
    }

    const selectedPopupComponent: CompRef =
      editorAPI.selection.isPopupPageSelected(this.props.selectedComponents)
        ? _.head(this.props.selectedComponents)
        : editorAPI.selection.getSelectedPopup(this.props.selectedComponents);
    if (selectedPopupComponent) {
      editorAPI.components.removePopupWithMessagePanel(
        selectedPopupComponent,
        this.props.biOrigin,
      );
      return;
    }
    return new Promise<void>((resolve) => {
      editorAPI.closeRightClickMenu();

      editorAPI.selectedComponent
        .remove(this.props.biOrigin)
        .then((removed: boolean) => {
          if (removed) {
            editorAPI.history.add(
              'removed component via rightClickMenu',
              getHistoryParamsWithOverrides(compsBIParams),
            );
            resolve();
          }
        });
    });
  },
  getHideInteractionsItem(items: AnyFixMe) {
    return (
      <ul key="rcmHideComponentOnInteraction">
        <ItemWithShortcut
          item={items.interactionsHide}
          label={'gfpp_tooltip_interactions_hide_element'}
          shortcut="Del"
          onClick={this.hideOnInteraction}
        />
      </ul>
    );
  },
  addToSavedComponents() {
    const openPanelInteraction = util.fedopsLogger.mapInteraction(
      util.fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_SAVE_PANEL,
    );
    openPanelInteraction.start();
    const { supportedComponents, hasUnsupportedComponents } =
      editorAPI.savedComponents.filterUnsupportedComponents(
        this.props.selectedComponents,
      );

    const funnelId = _.uniqueId('saved-comp-');
    editorAPI.bi.event(coreBi.events.savedComponents.open_save_panel, {
      funnel_id: funnelId,
      origin: this.props.biOrigin,
      content_type:
        supportedComponents.length > 1
          ? 'multiselect'
          : editorAPI.components.getType(supportedComponents),
    });
    editorAPI.panelManager.openPanel(
      'panels.focusPanels.saveToMyElementsPanel',
      {
        origin: this.props.biOrigin,
        selectedComponents: supportedComponents,
        hasUnsupportedComponents,
        funnelId,
        openPanelInteraction,
      },
    );

    editorAPI.closeRightClickMenu();
  },

  async toggleShowOnAllPages() {
    const { selectedComponents, biOrigin } = this.props;

    await editorAPI.components.toggleShowOnAllPages(
      this.props.selectedComponents,
    );

    editorAPI.dsActions.waitForChangesApplied(function () {
      const isShowOnAllPages =
        editorAPI.components.isShowOnAllPages(selectedComponents);

      editorAPI.bi.event(bi.events.rightClickMenu.SHOW_ON_ALL_PAGES, {
        new_status: isShowOnAllPages,
        origin: biOrigin,
      });
    });
  },
  toggleFixedPosition() {
    const { selectedComponents, biOrigin } = this.props;

    editorAPI.components.layout.toggleFixedPosition(selectedComponents);

    editorAPI.dsActions.waitForChangesApplied(function () {
      const isFixedPosition = editorAPI.components.is.fixed(selectedComponents);
      const compType = editorAPI.components.getType(selectedComponents[0]);

      editorAPI.bi.event(bi.events.rightClickMenu.FIXED_POSITION, {
        origin: biOrigin,
        new_status: isFixedPosition,
        component: compType,
      });
    });
  },

  handlePinClick() {
    handlePinClick(editorAPI, {
      selectedComponents: this.props.selectedComponents,
      biOrigin: this.props.biOrigin,
    });
  },

  handleA11yClick(selectedComponent: CompRef[]) {
    const openA11yPanelInteraction = util.fedopsLogger.mapInteraction(
      util.fedopsLogger.INTERACTIONS.A11Y.OPEN_PANEL,
    );
    openA11yPanelInteraction.start();
    editorAPI.closeRightClickMenu();
    editorAPI.accessibility.openA11yPanel(selectedComponent[0], null, {
      openPanelInteraction: openA11yPanelInteraction,
    });
  },

  moveForward() {
    editorAPI.components.arrangement.moveForward(this.props.selectedComponents);
    editorAPI.bi.event(bi.events.rightClickMenu.MOVE_FORWARD, {
      origin: this.props.biOrigin,
    });
  },
  moveBackward() {
    editorAPI.components.arrangement.moveBackward(
      this.props.selectedComponents,
    );
    editorAPI.bi.event(bi.events.rightClickMenu.MOVE_BACKWARDS, {
      origin: this.props.biOrigin,
    });
  },
  moveToFront() {
    editorAPI.components.arrangement.moveToFront(this.props.selectedComponents);
    editorAPI.bi.event(bi.events.rightClickMenu.BRING_TO_FRONT, {
      origin: this.props.biOrigin,
    });
  },
  moveToBack() {
    editorAPI.components.arrangement.moveToBack(this.props.selectedComponents);
    editorAPI.history.add('component - move to back');
    editorAPI.bi.event(bi.events.rightClickMenu.SEND_TO_BACK, {
      origin: this.props.biOrigin,
    });
  },
  onArrangeHover() {
    editorAPI.bi.event(bi.events.rightClickMenu.MENU_WITH_SUB_ITEMS_HOVER, {
      category: 'Arrange',
      component: this.compType,
      origin: this.props.biOrigin,
    });
  },
  onOverlappingItemsHover() {
    editorAPI.bi.event(bi.events.rightClickMenu.MENU_WITH_SUB_ITEMS_HOVER, {
      category: 'Overlapping Items',
      component: this.compType,
      origin: this.props.biOrigin,
    });
  },
  onImageToBackgroundItemWithSubItemsHover() {
    editorAPI.bi.event(bi.events.rightClickMenu.MENU_WITH_SUB_ITEMS_HOVER, {
      component_id: this.props.selectedComponents?.[0]?.id,
      category: 'Image to Background',
      component: this.compType,
      origin: this.props.biOrigin,
    });
  },

  onOverlappingItemClick(subItem: AnyFixMe) {
    editorAPI.selection.selectComponentByCompRef(subItem.component);
    editorAPI.selection.setIsHiddenComponentDraggedNext(true);
    editorAPI.closeRightClickMenu();

    editorAPI.bi.event(bi.events.rightClickMenu.OVERLAPPING_ITEM_CLICK, {
      origin: this.props.biOrigin,
      component: isCompInCollection(
        subItem.component,
        this.props.selectedComponents,
      )
        ? 'selected'
        : 'other',
    });
  },
  getOverlappingItemsButton(items: AnyFixMe) {
    return (
      <ItemWithSubItems
        ref="overlappingItems"
        label="RightClick_Menu_Overlapping_Items_Label"
        item={items.overlappingItems}
        key="rcmOverlapping"
        countTotal={items.overlappingItems.subItems.length}
        onMouseOver={(e) => {
          this.onOverlappingItemsHover();
          this.calculateSubMenuTop(e);
        }}
        subMenuPositionY={this.state.subMenuPositionY}
      >
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(items.overlappingItems.subItems, (item) => (
          <ItemBasic
            item={item}
            label={item.label}
            key={item.key}
            onClick={this.onOverlappingItemClick.bind(this, item)}
            shouldTranslate={false}
            onMouseEnter={this.onOverlappingItemMouseEnter.bind(this, item)}
            onMouseLeave={this.onOverlappingItemMouseLeave}
          />
        ))}
        {items.layers && [
          <div
            className={util.cx('layers-divider-wrapper', {
              'with-margins': experiment.isOpen('se_newIconsForLayers'),
            })}
          >
            <Divider key="divider" className="layers-divider" />
          </div>,
          <ItemBasic
            key={items.layers.key}
            item={items.layers}
            label={items.layers.label}
            onClick={this.onLayersItemClick}
            automationId={items.layers.automationId}
          />,
        ]}
      </ItemWithSubItems>
    );
  },
  onImageToBgItemClick(
    item: CompRef,
    isMultivarant: boolean,
    event?: React.MouseEvent,
  ) {
    event?.stopPropagation();

    const startTime = performance.now();
    const imageToBackgroundAPI = editorAPI.host.getAPI(ImageToBackgroundApiKey);

    const imageData = editorAPI.selection.getSelectedComponentData();
    imageToBackgroundAPI.setImageAsComponentBackground(
      item,
      imageData,
      startTime,
      {
        origin: 'rcm',
        action_name: isMultivarant
          ? 'set_as_background_multivariant'
          : 'set_as_background',
        action_type: 'click',
        correlationId: util.guidUtils.getGUID(),
      },
    );

    this.props.toggleBackgroundTargetHighlight(item, false);
    editorAPI.closeRightClickMenu();
  },
  onImageToBgItemMouseEnter(
    backgroundTargetCompRef: CompRef,
    isMultivarant: boolean,
  ) {
    const imageToBackgroundAPI = editorAPI.host.getAPI(ImageToBackgroundApiKey);

    this.props.toggleBackgroundTargetHighlight(backgroundTargetCompRef, true);
    imageToBackgroundAPI.sendImageToBackgroundEntryPointActionBI(
      backgroundTargetCompRef,
      {
        origin: 'rcm',
        action_name: isMultivarant
          ? 'set_as_background_multivariant'
          : 'set_as_background',
        action_type: 'hover',
      },
    );
  },
  onImageToBgItemMouseLeave(backgroundTargetCompRef: CompRef) {
    this.props.toggleBackgroundTargetHighlight(backgroundTargetCompRef, false);
  },
  getImageToBgButton({ imageToBackground }: RightClickMenuItems) {
    const imageToBGCandidates = imageToBackground.subItems;
    if (!imageToBGCandidates || !imageToBGCandidates.length) {
      return null;
    }
    return imageToBGCandidates.length === 1 ? (
      <ItemBasic
        ref="imageToBG"
        label="RightClick_fluid_set_background_label"
        key="imageToBg"
        item={imageToBackground}
        onMouseEnter={() =>
          this.onImageToBgItemMouseEnter(
            imageToBGCandidates[0].component,
            false,
          )
        }
        onMouseLeave={() =>
          this.onImageToBgItemMouseLeave(imageToBGCandidates[0].component)
        }
        onClick={() =>
          this.onImageToBgItemClick(imageToBGCandidates[0].component, false)
        }
        automationId="imageToBackgroundItem"
      />
    ) : (
      <ItemWithSubItems
        ref="imageToBg"
        label="RightClick_fluid_set_background_overlapping_label"
        item={imageToBackground}
        key="imageToBg"
        onMouseOver={(e) => {
          this.calculateSubMenuTop(e);
          this.onImageToBackgroundItemWithSubItemsHover();
        }}
        onClick={() =>
          this.onImageToBgItemClick(imageToBGCandidates[0].component, true)
        }
        subMenuPositionY={this.state.subMenuPositionY}
        automationId="imageToBackgroundItem"
      >
        {imageToBGCandidates.map((item) => (
          <ItemBasic
            item={item}
            label={item.label}
            key={item.key}
            onClick={(e: React.MouseEvent) =>
              this.onImageToBgItemClick(item.component, true, e)
            }
            onMouseEnter={() =>
              this.onImageToBgItemMouseEnter(item.component, true)
            }
            onMouseLeave={() => this.onImageToBgItemMouseLeave(item.component)}
            shouldTranslate={false}
            automationId={`imageToBackgroundSubitemItem_${item.key}`}
          />
        ))}
      </ItemWithSubItems>
    );
  },
  getDetachImageFromBackgroundItem({
    detachImageFromBackground,
  }: RightClickMenuItems) {
    return (
      <ItemBasic
        key="detachImageFromBackground"
        item={detachImageFromBackground}
        label="RightClick_fluid_set_background_detach_label"
        onClick={this.onDetachImageFromBackgroundClick}
        automationId="detachImageFromBackgroundItem"
        enabled={detachImageFromBackground.enabled}
      />
    );
  },
  onDetachImageFromBackgroundClick() {
    const startTime = performance.now();
    const imageToBackgroundAPI = editorAPI.host.getAPI(ImageToBackgroundApiKey);

    imageToBackgroundAPI.detachImageFromBackground(
      this.props.selectedComponents[0],
      startTime,
    );
    editorAPI.closeRightClickMenu();
  },
  getArrangeItemsButton(items: AnyFixMe, shortcuts: AnyFixMe) {
    return (
      <ItemWithSubItems
        item={items.arrange}
        label="RightClick_Menu_Arrange_Label"
        key="rcmArrange"
        onMouseOver={(e) => {
          this.onArrangeHover();
          this.calculateSubMenuTop(e);
        }}
        subMenuPositionY={this.state.subMenuPositionY}
      >
        <ItemWithShortcut
          item={items.arrange.subItems.moveToFront}
          label="RightClick_Menu_Bring_to_Front_Label"
          shortcut={shortcuts.SEND_TO_FRONT.label}
          onClick={this.moveToFront}
        />
        <ItemWithShortcut
          item={items.arrange.subItems.moveForward}
          label="RightClick_Menu_Move_Forward_Label"
          shortcut={shortcuts.SEND_FORWARD.label}
          onClick={this.moveForward}
        />
        <ItemWithShortcut
          item={items.arrange.subItems.moveBackward}
          label="RightClick_Menu_Move_Backward_Label"
          shortcut={shortcuts.SEND_BACKWARD.label}
          onClick={this.moveBackward}
        />
        <ItemWithShortcut
          item={items.arrange.subItems.moveToBack}
          label="RightClick_Menu_Send_to_Back_Label"
          shortcut={shortcuts.SEND_TO_BACK.label}
          onClick={this.moveToBack}
        />
      </ItemWithSubItems>
    );
  },
  onLayersItemClick() {
    util.editorWixRecorder.addLabel(
      'panels.toolPanels.layersPanel panel opened',
    );

    const origin = this.props.biOrigin;
    editorAPI.bi.event(bi.events.panels.PANEL_OPENED, {
      panel_name: 'panels.toolPanels.layersPanel',
      origin,
    });
    editorAPI.store.dispatch(
      stateManagement.layersPanel.actions.showLayersPanel(origin),
    );
    editorAPI.closeRightClickMenu();
  },
  removeAllGuidesInRuler() {
    editorAPI.bi.event(coreBi.events.rulers.GUIDE_DELETED, {
      origin: 'remove_all_button',
    });
    this.props.removeAllGuidesInRuler();
    editorAPI.closeRightClickMenu();
  },
  removeCurrentGuideInRuler() {
    editorAPI.bi.event(coreBi.events.rulers.GUIDE_DELETED, {
      origin: 'current_ruler',
    });
    this.props.removeSelectedGuide();
    editorAPI.closeRightClickMenu();
  },
  getApplyToRemoveAllGuides() {
    return {
      label: 'Ruler_Context_Menu_Remove_All',
      enabled: true,
    };
  },
  getApplyToInfoItem() {
    return {
      label: 'RightClick_Menu_Copy_Design_Label',
      enabled: true,
    };
  },
  openApplyToDialog(comp: CompRef) {
    editorAPI.bi.event(coreBi.events.applyStyle.COPY_STYLE_RCM_CLICK, {
      component_id: comp.id,
      component_type: editorAPI.components.getType(comp),
    });
    editorAPI.styles.openApplyStylePanel(comp);
  },

  applyToAllModes() {
    editorAPI.components.modes.applyCurrentToAllModes(
      editorAPI.selection.getSelectedComponents(),
    );
    editorAPI.closeRightClickMenu();
  },

  getCustomActions() {
    const actions =
      customActions[
        editorAPI.components.getType(this.props.selectedComponents)
      ] || [];

    if (_.isFunction(actions)) {
      return actions(this.getEditorAPI());
    }

    return actions;
  },

  getAdvancedCustomActions() {
    const actions =
      advancedCustomActions[
        editorAPI.components.getType(this.props.selectedComponents)
      ] || [];

    return actions.filter(
      (action) =>
        !action.condition ||
        action.condition(editorAPI, this.props.selectedComponents),
    );
  },

  saveStyleToTheme(comp: CompRef, themeStyleId: string) {
    const themeStyle = editorAPI.dsRead.theme.styles.get(themeStyleId);
    const compStyle = editorAPI.dsRead.components.style.get(comp);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newStyle = _.assign(
      compStyle,
      _.pick(themeStyle, ['id', 'type', 'styleType']),
    );
    editorAPI.theme.styles.update(themeStyleId, newStyle);
  },

  shouldHideViewProperties() {
    const overriddenItems =
      stateManagement.rightClickMenu.selectors.getOverriddenItems(
        editorAPI.store.getState(),
      );
    return overriddenItems?.viewProperties?.isHidden;
  },

  openPropertiesPanel() {
    if (!editorAPI.developerToolBar.isEnabled()) {
      editorAPI.bi.event(bi.events.PROPERTIES_PANEL_TOGGLE, {
        site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        state: 'open',
        origin: 'component_right_click_menu',
      });
      editorAPI.developerToolBar.toggle();
    }

    editorAPI.closeRightClickMenu();
  },

  toggleDeveloperToolBar() {
    editorAPI.bi.event(bi.events.PROPERTIES_PANEL_TOGGLE, {
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      state: editorAPI.developerToolBar.isEnabled() ? 'close' : 'open',
      origin: 'component_right_click_menu',
    });
    editorAPI.developerToolBar.toggle();
    editorAPI.closeRightClickMenu();
  },

  isShowOnAllPagesToggleDisabled() {
    if (!this.props.selectedComponents) {
      return true;
    }
    if (
      !editorAPI.components.canToggleShowOnAllPages(
        this.props.selectedComponents,
      )
    ) {
      if (this.props.forceSOAPForNonContainable) return false;
      return true;
    }
    return editorAPI.components.shouldDisableShowOnAllPages(
      this.props.selectedComponents,
    );
  },
  getDeleteItemLabelKey() {
    const { selectedComponents } = this.props;
    if (
      shouldShowMobileOnlyMixesMultiselectLabel(editorAPI, selectedComponents)
    ) {
      return 'RightClick_Menu_Mobile_Remove_Hide_Label';
    } else if (shouldShowMobileOnlyDeleteLabel(editorAPI, selectedComponents)) {
      return 'RightClick_Menu_Mobile_Delete_Label';
    } else if (shouldShowHideLabel(editorAPI, selectedComponents)) {
      return 'RightClick_Menu_Mobile_Hide_Label';
    }
    return 'RightClick_Menu_Delete_Label';
  },
  getSelectedComponentsTypes() {
    const selectedComponentsData =
      this.getEditorAPI().components.data.get(this.props.selectedComponents) ||
      [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(selectedComponentsData, 'type');
  },
  shouldHideCopyDesign() {
    const isStudioCopyDesignExperimentOpen = experiment.isOpen(
      'se_studioCopyDesign',
    ); //HACK For exposing mobile menu copy design only to studio
    if (
      editorAPI.components.getType(this.props.selectedComponents) ===
        'wysiwyg.viewer.components.MenuToggle' &&
      !isStudioCopyDesignExperimentOpen
    ) {
      return true;
    }
    return stateManagement.rightClickMenu.selectors.getOverriddenItems(
      editorAPI.store.getState(),
    ).copyDesign.isHidden;
  },
  shouldHideAddToSavedComponents() {
    return stateManagement.rightClickMenu.selectors.getOverriddenItems(
      editorAPI.store.getState(),
    ).addToSavedComponents.isHidden;
  },

  toggleFlipHoriz() {
    editorAPI.components.flip(this.props.selectedComponents, 'horizontal');
    editorAPI.bi.event(bi.events.rightClickMenu.RIGHT_CLICK_MENU_FLIP_CLICK, {
      component_type: this.compType,
      actionName: 'flip_hor',
    });
  },

  toggleFlipVert() {
    editorAPI.components.flip(this.props.selectedComponents, 'vertical');
    editorAPI.bi.event(bi.events.rightClickMenu.RIGHT_CLICK_MENU_FLIP_CLICK, {
      component_type: this.compType,
      actionName: 'flip_vert',
    });
  },

  hideOnInteraction() {
    editorAPI.closeRightClickMenu();
    editorAPI.store.dispatch(
      stateManagement.interactions.actions.hideComponent({
        origin: this.props.biOrigin,
      }),
    );
  },

  sortGfppActions(gfppItems: any[]) {
    const possibleFirstLevelActions = new Set([
      GFPP_ACTIONS.HELP,
      GFPP_ACTIONS.UPGRADE,
      GFPP_ACTIONS.CONNECT,
    ]);
    const compsWithDesignAsMainAction = new Set<string>([
      constants.COMP_TYPES.CONTAINER,
      constants.COMP_TYPES.FOOTER,
      constants.COMP_TYPES.HEADER,
      constants.COMP_TYPES.FIVE_GRID_LINE,
      constants.COMP_TYPES.VERTICAL_LINE,
      constants.COMP_TYPES.POPUP_CLOSE_ICON_BUTTON,
    ]);
    const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
    const componentType = editorAPI.components.getType(selectedComponent);
    const removeDesignDuplicate = (action: any) =>
      !(
        compsWithDesignAsMainAction.has(componentType) &&
        action.action === GFPP_ACTIONS.DESIGN
      );
    const itemsToReturn = gfppItems.reduce(
      (acc: any, action: any, i: number) => {
        if (i === 0 || possibleFirstLevelActions.has(action.action)) {
          acc.firstLevelActions.push(action);
        } else if (
          !(
            compsWithDesignAsMainAction.has(componentType) &&
            action.action === GFPP_ACTIONS.DESIGN
          )
        ) {
          acc.secondLevelActions.push(action);
        }
        return acc;
      },
      { firstLevelActions: [], secondLevelActions: [] },
    );
    if (itemsToReturn.secondLevelActions.length < 2) {
      itemsToReturn.firstLevelActions = gfppItems.filter(removeDesignDuplicate);
      itemsToReturn.secondLevelActions = [];
    }
    return itemsToReturn;
  },

  getGfppItem(gfppItem: any) {
    return gfppItem.item.subItems ? (
      <ItemWithSubItems
        shouldTranslate={gfppItem.shouldTranslate}
        item={gfppItem.item}
        label={gfppItem.item.label}
        key={gfppItem.item.label}
      >
        {gfppItem.item.subItems.map((subItem: any) => (
          <ItemBasic
            shouldTranslate={subItem.shouldTranslate}
            item={{ enabled: subItem.enabled }}
            key={subItem.label}
            label={subItem.label}
            onClick={subItem.onClick}
            onMouseEnter={subItem.onMouseEnter}
            onMouseLeave={subItem.onMouseLeave}
            automationId={subItem?.automationId}
          />
        ))}
      </ItemWithSubItems>
    ) : (
      <ItemBasic
        shouldTranslate={gfppItem.shouldTranslate}
        item={gfppItem.item}
        label={gfppItem.item.label}
        key={gfppItem.item.label}
        onClick={gfppItem.onClick}
        automationId={gfppItem?.automationId}
      />
    );
  },

  getGfppItems(gfppItems: any) {
    if (_.isEmpty(gfppItems)) {
      return null;
    }

    return (
      <ul key="gfppItems" data-section="gfppItems">
        {gfppItems.map((gfppItem: any) => this.getGfppItem(gfppItem))}
      </ul>
    );
  },

  shouldRenderEditingList(items: any) {
    return (
      items.cut ||
      items.paste ||
      (items.styleCanBeApplied && !this.shouldHideCopyDesign()) ||
      items.copy ||
      items.duplicate ||
      items.pasteAndApplyTheme ||
      items.remove
    );
  },

  shouldShowDeleteItem() {
    const editorRestrictionsApi = editorAPI.host.getAPI(
      EditorRestrictionsApiKey,
    );
    const isHideItemVisible = editorRestrictionsApi.allowed<UIRestrictionRCM>(
      'rcm_hide-item.visible',
    );
    const shouldShowHide = shouldShowHideLabel(
      editorAPI,
      this.props.selectedComponents,
    );
    return !shouldShowHide || (shouldShowHide && isHideItemVisible);
  },

  shouldShowDeleteWithDivider() {
    return (
      editorAPI.isMobileEditor() &&
      !shouldShowMobileOnlyDeleteLabel(editorAPI, this.props.selectedComponents)
    );
  },

  getDeleteItem(items: any) {
    return (
      <ItemWithShortcut
        item={items.remove}
        label={this.getDeleteItemLabelKey()}
        shortcut="Del"
        onClick={this.removeComponent}
      />
    );
  },

  getAddToSavedComponents(items: any) {
    const editorAPI = this.getEditorAPI();
    const label =
      util.sections.isSectionsEnabled() &&
      editorAPI.sections.isSection(this.props.selectedComponents[0])
        ? 'RightClick_Menu_Section_Saved_Sections_Label'
        : 'RightClick_Menu_SaveToMyDesigns_Label';

    return (
      <ItemBasic
        item={items.addToSavedComponents}
        label={label}
        onClick={this.addToSavedComponents}
      />
    );
  },

  getPinItem(items: any) {
    return (
      <ItemWithShortcut
        item={items.pin}
        label={items.pin.label}
        value={items.pin.value}
        onClick={this.handlePinClick}
        key="rcmPin"
      />
    );
  },

  getPlaceholderGroup(): ContextMenuItem {
    return {
      key: 'placeholderGroup',
      disabled: this.props.selectedComponents,
      children: [
        {
          key: 'placeholderGroupItem',
          label: 'RightClick_Menu_Paste_Label',
          item: this.props.menuItems.paste,
          shortcut: keyboardMap.editor.PASTE.label,
          disabled: !this.props.menuItems.paste,
          onClick: this.paste,
        },
      ],
    };
  },

  getRulerGroup(): ContextMenuItem {
    const current = {
      key: 'rulerGroupCurrentItem',
      label: 'Ruler_Context_Menu_Remove_Current',
      item: this.getApplyToRemoveAllGuides(),
      onClick: this.removeCurrentGuideInRuler,
    };
    const vertical = {
      key: 'rulerGroupVerticalItem',
      label: 'Ruler_Context_Menu_Remove_All_Vertical',
      item: this.getApplyToRemoveAllGuides(),
      onClick: this.removeAllGuidesInRuler,
    };
    const horizontal = {
      key: 'rulerGroupHorizontalItem',
      label: 'Ruler_Context_Menu_Remove_All_Horizontal',
      item: this.getApplyToRemoveAllGuides(),
      onClick: this.removeAllGuidesInRuler,
    };
    const map = new Map([
      [RCM_ORIGIN.RULERS_VERTICAL, [vertical]],
      [RCM_ORIGIN.RULERS_HORIZONTAL, [horizontal]],
      [RCM_ORIGIN.GUIDE_VERTICAL, [current, vertical]],
      [RCM_ORIGIN.GUIDE_HORIZONTAL, [current, horizontal]],
    ]);

    return {
      key: 'rulerGroup',
      children: map.get(this.props.origin) || [],
    };
  },

  getApplyGroup(): ContextMenuItem {
    return {
      key: 'applyGroup',
      disabled:
        !this.props.menuItems.applyToAllModes &&
        !this.props.menuItems.applyModeFromClipboard,
      children: [
        {
          key: 'applyGroupAllModesItem',
          disabled: !this.props.menuItems.applyToAllModes,
          item: this.props.menuItems.applyToAllModes,
          label: this.props.menuItems.applyToAllModes?.label,
          onClick: this.applyToAllModes,
        },
        {
          key: 'applyGroupApplyModeFromClipboardItem',
          disabled: !this.props.menuItems.applyModeFromClipboard,
          item: this.props.menuItems.applyModeFromClipboard,
          label: 'RightClick_Menu_HB_Apply_From_Clipboard',
          onClick: this.applyModeFromClipboard,
        },
      ],
    };
  },

  getResetInteractionGroup(): ContextMenuItem {
    return {
      key: 'resetInteractionGroup',
      disabled: !this.props.menuItems.resetInteraction,
      children: [
        {
          key: 'resetInteractionGroupItem',
          label: this.props.menuItems.resetInteraction?.label,
          onClick: this.props.menuItems.resetInteraction?.onClick,
          item: this.props.menuItems.resetInteraction,
          automationId: this.props.menuItems.resetInteraction?.automationId,
        },
      ],
    };
  },

  getScanPageToCMSGroup(): ContextMenuItem {
    return {
      key: 'scanPageToCMSGroup',
      disabled: !this.props.menuItems.scanPageToCMS,
      children: [
        {
          key: 'scanPageToCMSGroupItem',
          shouldTranslate: false,
          label: this.props.menuItems.scanPageToCMS?.label,
          onClick: this.props.menuItems.scanPageToCMS?.onClick,
          item: this.props.menuItems.scanPageToCMS,
        },
      ],
    };
  },

  validateContextMenuItems(items: ContextMenuItem[] = []): ContextMenuItem[] {
    return items.reduce<ContextMenuItem[]>(
      (accumulator, { disabled, children, ...payload }) => {
        if (disabled) {
          return accumulator;
        }

        const validChildren = this.validateContextMenuItems(children);

        if (validChildren.length) {
          return [...accumulator, { ...payload, children }];
        }

        if (!children) {
          return [...accumulator, payload];
        }

        return accumulator;
      },
      [],
    );
  },

  renderContextMenuItems(items: ContextMenuItem[], level = 0) {
    const validItems: ContextMenuItem[] = this.validateContextMenuItems(items);

    return validItems.map(({ children, ...payload }: AnyFixMe) => {
      if (level === 0) {
        return (
          <ul {...payload}>
            {this.renderContextMenuItems(children, level + 1)}
          </ul>
        );
      }

      if (payload.shortcut) {
        return <ItemWithShortcut {...payload} />;
      }

      return <ItemBasic {...payload} />;
    });
  },

  render() {
    const items = this.props.menuItems;
    const editorAPI = this.getEditorAPI();
    const shortcuts = keyboardMap.editor;
    const customActions = this.getCustomActions();
    const advancedCustomActions = this.getAdvancedCustomActions();
    const compPointer = _.head(this.props.selectedComponents);
    const interactionHideAvailable = !!items.interactionsHide;

    if (this.props.isInSwitchLayoutMode) {
      return null;
    }

    return (
      <util.outerClick onOuterClick={this.onOuterClick}>
        <div
          style={{
            top: this.state.position.y,
            left: this.state.position.x,
          }}
          onContextMenu={this.onRightClick}
          key="rcm"
          ref="rcmRoot"
          className={util.cx(this.getClasses())}
        >
          {(this.state.scrollPosition === DOWN ||
            this.state.scrollPosition === MIDDLE) && (
            <div
              className="scroll-arrow-up"
              onMouseEnter={this.onScrollEvent}
              onMouseLeave={this.stopIntervalScroll}
            >
              <Symbol name="arrowDown" />
            </div>
          )}

          {this.renderContextMenuItems([
            this.getPlaceholderGroup(),
            this.getRulerGroup(),
          ])}

          {this.props.origin === RCM_ORIGIN.PAGES_MENU && (
            <PagesMenuContextMenu key="pagesMenu" />
          )}
          {this.props.origin === RCM_ORIGIN.PAGES_MENU_ITEM && (
            <PagesMenuItemContextMenu key="pagesMenuItem" />
          )}
          {this.props.selectedComponents &&
          this.props.shouldShowComponentActions ? (
            <div
              key="selectedCompItems"
              className={util.cx({
                rcmStageContentWrapper: true,
                scrollable: this.state.scrollPosition,
              })}
              ref="rcmStageContentWrapper"
              onWheel={_.throttle(this.onScrollEvent, 200, {
                trailing: false,
              })}
            >
              {this.renderContextMenuItems([this.getApplyGroup()])}

              {this.getGfppItems(items.gfppItems)}

              {items.imageToBackground && (
                <ul key="imageToBg">{this.getImageToBgButton(items)}</ul>
              )}

              {items.detachImageFromBackground && (
                <ul key="detachImageFromBG">
                  {this.getDetachImageFromBackgroundItem(items)}
                </ul>
              )}

              {items.overlappingItems ? (
                <ul key="rcmOverlapping">
                  {this.getOverlappingItemsButton(items)}
                  {items.arrange
                    ? this.getArrangeItemsButton(items, shortcuts)
                    : null}
                </ul>
              ) : null}

              {this.renderContextMenuItems([
                this.getResetInteractionGroup(),
                this.getScanPageToCMSGroup(),
              ])}

              {customActions.length ? (
                <ul key="customActions">
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(customActions, (customAction, customActionIndex) => (
                    <ItemBasic
                      key={`customAction_${customActionIndex}`}
                      item={customAction}
                      label={
                        _.isFunction(customAction.label)
                          ? customAction.label(editorAPI, compPointer)
                          : customAction.label
                      }
                      onClick={customAction.onClick.bind(
                        null,
                        editorAPI,
                        compPointer,
                      )}
                      enabled={
                        _.isFunction(customAction.enabled) &&
                        customAction.enabled.bind(null, editorAPI, compPointer)
                      }
                    />
                  ))}
                </ul>
              ) : null}

              {items.setA11y ? (
                <ul key="rcmA11y">
                  <ItemWithShortcut
                    item={items.setA11y}
                    label="gfpp_mainaction_accessibility"
                    onClick={() =>
                      this.handleA11yClick(items.setA11y.selectedComponent)
                    }
                    key="rcmA11y"
                  />
                </ul>
              ) : null}

              {experiment.isOpen('rightClickDebug') && (
                <RightClickDebug
                  calculateSubMenuTop={this.calculateSubMenuTop}
                  subMenuPositionY={this.state.subMenuPositionY}
                />
              )}

              {this.shouldRenderEditingList(items) && (
                <ul>
                  {items.cut ? (
                    <ItemWithShortcut
                      item={items.cut}
                      label="RightClick_Menu_Cut_Label"
                      shortcut={shortcuts.CUT.label}
                      onClick={this.cutComponent}
                      key="rcmCut"
                    />
                  ) : null}
                  {items.copy ? (
                    <ItemWithShortcut
                      item={items.copy}
                      label="RightClick_Menu_Copy_Label"
                      automationId="rcmItemCopy"
                      shortcut={shortcuts.COPY.label}
                      onClick={this.copyComponent}
                      key="rcmCopy"
                    />
                  ) : null}
                  {items.styleCanBeApplied && !this.shouldHideCopyDesign()
                    ? // TODO: Fix this the next time the file is edited.
                      // eslint-disable-next-line you-dont-need-lodash-underscore/map
                      _.map(this.props.selectedComponents, (comp) => (
                        <ItemBasic
                          label="RightClick_Menu_Copy_Design_Label"
                          item={this.getApplyToInfoItem()}
                          key={`${comp.id}.debugInfo`}
                          onClick={() => {
                            this.openApplyToDialog(comp);
                          }}
                        />
                      ))
                    : null}
                  {items.paste ? (
                    <ItemWithShortcut
                      item={items.paste}
                      label="RightClick_Menu_Paste_Label"
                      automationId="rcmItemPaste"
                      shortcut={shortcuts.PASTE.label}
                      onClick={this.paste.bind(this, false)}
                      key="rcmPaste"
                    />
                  ) : null}
                  {items.pasteAndApplyTheme ? (
                    <ItemWithShortcut
                      item={items.pasteAndApplyTheme}
                      label="RightClick_Menu_PasteandApplyTheme_Label"
                      shortcut={shortcuts.PASTE_AND_APPLY_THEME.label}
                      onClick={this.paste.bind(this, true)}
                      key="rcmPasteAndApplyTheme"
                    />
                  ) : null}
                  {items.duplicate ? (
                    <ItemWithShortcut
                      item={items.duplicate}
                      label="RightClick_Menu_Duplicate_Label"
                      shortcut={shortcuts.DUPLICATE.label}
                      onClick={this.duplicateComponent}
                      key="rcmDuplicate"
                    />
                  ) : null}
                  {items.remove &&
                  !this.shouldShowDeleteWithDivider() &&
                  this.shouldShowDeleteItem()
                    ? this.getDeleteItem(items)
                    : null}
                  {items.hideHeaderFooter ? (
                    <ItemBasic
                      item={items.hideHeaderFooter}
                      label={items.hideHeaderFooter.label}
                      onClick={items.hideHeaderFooter.onClick}
                      key={items.hideHeaderFooter.automationId}
                    />
                  ) : null}
                </ul>
              )}

              {items.flip ? (
                <ul key="flips">
                  <ItemBasic
                    key="rcmFlipHoriz"
                    label="RightClick_Menu_Flip_Horizontal_Label"
                    item={items.flip}
                    onClick={this.toggleFlipHoriz}
                  />
                  <ItemBasic
                    key="rcmFlipVert"
                    label="RightClick_Menu_Flip_Vertical_Label"
                    item={items.flip}
                    onClick={this.toggleFlipVert}
                  />
                </ul>
              ) : null}

              {items.moveTo ? (
                <ul key="rcmArrangeOverlapping">
                  {items.moveTo ? (
                    <ItemWithSubItems
                      item={items.moveTo}
                      label="RightClick_Menu_Mobile_Menu_Moveto"
                      key="MoveTo"
                      onMouseOver={this.calculateSubMenuTop}
                      subMenuPositionY={this.state.subMenuPositionY}
                    >
                      {items.moveTo.subItems.moveToMenu ? (
                        <ItemBasic
                          item={items.moveTo.subItems.moveToMenu}
                          key="moveToMenu"
                          label="RightClick_Menu_Mobile_Menu_Moveto_MenuBox"
                          onClick={items.moveTo.subItems.moveToMenu.onClick}
                        />
                      ) : null}
                      {items.moveTo.subItems.moveToHeader ? (
                        <ItemBasic
                          item={items.moveTo.subItems.moveToHeader}
                          key="moveToHeader"
                          label="RightClick_Menu_Mobile_Menu_Moveto_Header"
                          onClick={items.moveTo.subItems.moveToHeader.onClick}
                        />
                      ) : null}
                      {items.moveTo.subItems.moveToFooter ? (
                        <ItemBasic
                          item={items.moveTo.subItems.moveToFooter}
                          key="moveToFooter"
                          label="RightClick_Menu_Mobile_Menu_Moveto_Footer"
                          onClick={items.moveTo.subItems.moveToFooter.onClick}
                        />
                      ) : null}
                    </ItemWithSubItems>
                  ) : null}
                </ul>
              ) : null}

              {(items.addToSavedComponents &&
                !this.shouldHideAddToSavedComponents()) ||
              items.pin ? (
                <ul key="add_to_saved_elements">
                  {items.addToSavedComponents &&
                  !this.shouldHideAddToSavedComponents()
                    ? this.getAddToSavedComponents(items)
                    : null}
                  {items.pin ? this.getPinItem(items) : null}
                </ul>
              ) : null}

              {items.menuContainer ? (
                <ul key="menu_container_rcm">
                  <ItemBasic
                    item={items.menuContainer}
                    onClick={items.menuContainer.onClick}
                    shouldTranslate={false}
                  />
                </ul>
              ) : null}

              {interactionHideAvailable &&
                this.props.selectedComponents.length === 1 &&
                this.getHideInteractionsItem(items)}

              {items.remove &&
              this.shouldShowDeleteWithDivider() &&
              this.shouldShowDeleteItem() ? (
                <ul key="rcmRemove">{this.getDeleteItem(items)}</ul>
              ) : null}

              {advancedCustomActions.length ? (
                <ul key="advancedCustomActions">
                  {/* eslint-disable-next-line max-lines */}
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(
                    advancedCustomActions,
                    (advancedCustomAction, advancedCustomActionIndex) => (
                      <ItemBasic
                        key={`advancedCustomAction_${advancedCustomActionIndex}`}
                        automationId={advancedCustomAction.automationId}
                        item={advancedCustomAction}
                        label={
                          _.isFunction(advancedCustomAction.label)
                            ? advancedCustomAction.label(editorAPI, compPointer)
                            : advancedCustomAction.label
                        }
                        onClick={advancedCustomAction.onClick.bind(
                          null,
                          editorAPI,
                          compPointer,
                        )}
                        tooltip={advancedCustomAction.tooltip}
                        enabled={
                          _.isFunction(advancedCustomAction.enabled) &&
                          advancedCustomAction.enabled.bind(
                            null,
                            editorAPI,
                            compPointer,
                          )
                        }
                      />
                    ),
                  )}
                </ul>
              ) : null}

              {items.saveToTheme &&
              this.props.selectedComponents.length === 1 ? (
                <ul key="themeStylesExperiment">
                  <ItemWithSubItems
                    item={{ enabled: true }}
                    label="Save to theme"
                    shouldTranslate={false}
                    key="saveToTheme"
                  >
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                    {_.map(items.saveToTheme.styleIds, (styleId) => (
                      <ItemBasic
                        shouldTranslate={false}
                        item={{ label: styleId, enabled: true }}
                        key={`${this.props.selectedComponents[0]}_themeStyle_${styleId}`}
                        onClick={() => {
                          this.saveStyleToTheme(
                            this.props.selectedComponents[0],
                            styleId,
                          );
                        }}
                      />
                    ))}
                  </ItemWithSubItems>
                </ul>
              ) : null}
              {items.showOnAllPages || items.fixedPosition ? (
                <ul key="rcmShowOnAllPagesFixPosition">
                  {items.showOnAllPages ? (
                    <li key="rcmShowOnAllPages" className="flex">
                      <ShowOnAllPagesToggle
                        origin={this.props.biOrigin}
                        selectedComponents={this.props.selectedComponents}
                        selectedComponentsTypes={this.getSelectedComponentsTypes()}
                        isComponentShowOnAllPages={
                          this.props.isComponentShowOnAllPages
                        }
                        disabled={this.isShowOnAllPagesToggleDisabled()}
                        isPage={editorAPI.utils.isPage(
                          this.props.selectedComponents,
                        )}
                        isToggleAllowed={
                          this.props.forceSOAPForNonContainable ||
                          this.getSelectedCompsRestrictions()
                            .canToggleShowOnAllPages
                        }
                        toggleShowOnAllPages={_.partial(
                          editorAPI.components.toggleShowOnAllPages,
                          this.props.selectedComponents,
                        )}
                        reportBI={editorAPI.bi.event}
                        labelType="T17"
                        label="toolbar_show_on_all_pages"
                        className="secondary-color"
                      />
                    </li>
                  ) : null}
                  {items.fixedPosition ? (
                    <li
                      key="rcmFixedPosition"
                      className={util.cx({
                        disabled: !items.fixedPosition.enabled,
                        flex: true,
                      })}
                    >
                      <ToggleSwitch
                        value={items.fixedPosition.value}
                        label="RightClick_Menu_Fixed_Position_Label"
                        onChange={this.toggleFixedPosition}
                        disabled={!items.fixedPosition.enabled}
                        size="MEDIUM"
                        labelType="T17"
                        widthByTextLength={true}
                        className={`${this.props.className} secondary-color`}
                      />
                    </li>
                  ) : null}
                </ul>
              ) : null}

              {editorAPI.developerMode.isEnabled() &&
              !this.shouldHideViewProperties() ? (
                <ul key="rcmDevMode">
                  {editorAPI.developerToolBar.isInCodePanel() ? (
                    <ItemWithShortcut
                      label={
                        editorAPI.developerToolBar.isEnabled()
                          ? 'RightClick_Menu_PropertiesAndEventsPanel_Hide_Label'
                          : 'RightClick_Menu_PropertiesAndEventsPanel_Show_Label'
                      }
                      automationId="rcmItemPropertiesPanel"
                      ref="openDevTools"
                      item={{ enabled: true }}
                      shortcut={shortcuts.TOGGLE_DEVELOPER_TOOLBAR.label}
                      onClick={this.toggleDeveloperToolBar}
                    />
                  ) : (
                    <ItemBasic
                      label="PLATFORM_RightClick_Menu_Open_Properties_Panel"
                      automationId="rcmItemPropertiesPanel"
                      ref="openDevTools"
                      item={{ enabled: true }}
                      onClick={this.openPropertiesPanel}
                    />
                  )}
                </ul>
              ) : null}
            </div>
          ) : null}
          {(this.state.scrollPosition === UP ||
            this.state.scrollPosition === MIDDLE) && (
            <div
              className="scroll-arrow-down"
              onMouseEnter={this.onScrollEvent}
              onMouseLeave={this.stopIntervalScroll}
            >
              <Symbol name="arrowDown" />
            </div>
          )}
        </div>
      </util.outerClick>
    );
  },
});

export default RightClickMenuComponent;

export const RightClickMenu = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(RightClickMenuComponent);

RightClickMenu.pure = RightClickMenuComponent;
