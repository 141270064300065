'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_columns_team",
    "subNavigationTitle": "add_section_label_columns_team",
    "presetTitle": "add_section_label_columns_team",
    "tooltipTitle": "add_section_label_columns_team",
    "automationId": "add-panel-section-columnsTeamSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/columnsTeamSection_en/columnsTeamSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Columns_ColumnsTeam_1",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 523,
                            "height": 56,
                            "x": 229,
                            "y": 70,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 44px; text-align: center;\"><span style=\"font-size:44px;\"><span style=\"font-family:palatino linotype,serif\"><span style=\"color:#2A2A2A\">Meet The Team</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 40,
                                "x": 20,
                                "y": 42,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 44px; text-align: center;\"><span style=\"font-size:44px;\"><span style=\"font-family:palatino linotype,serif\"><span style=\"color:#2A2A2A\">Meet The Team</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 32,
                            "height": 5,
                            "x": 472,
                            "y": 151,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#0F0F0F", "lnw": "3"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 32,
                                "height": 5,
                                "x": 143,
                                "y": 99,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#0F0F0F", "lnw": "3"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Container",
                        "components": [{
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 142,
                                    "height": 18,
                                    "x": 94,
                                    "y": 291,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:20px; line-height:0.8em; text-align:center\"><span style=\"font-size:20px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#0F0F0F\"><span style=\"font-weight:normal\">Jon&nbsp;Doe</span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 92,
                                        "height": 18,
                                        "x": 113,
                                        "y": 276,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:20px; line-height:0.8em; text-align:center\"><span style=\"font-size:20px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#0F0F0F\"><span style=\"font-weight:normal\">Jon&nbsp;Doe</span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "brightness": 1,
                                        "packed": false
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                                "layout": {
                                    "width": 41,
                                    "height": 41,
                                    "x": 145,
                                    "y": 333,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.LinkBar",
                                "data": {
                                    "type": "ImageList",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "items": [{
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "Grey LinkedIn Icon",
                                        "uri": "22fa9ffe67ef4289a150e75a66ee4933.png",
                                        "width": 200,
                                        "height": 200,
                                        "alt": "",
                                        "link": {
                                            "type": "ExternalLink",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "url": "https://www.linkedin.com/company/wix-com",
                                            "target": "_blank"
                                        }
                                    }]
                                },
                                "props": {
                                    "type": "LinkBarProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "gallery": "social_icons",
                                    "iconSize": 42,
                                    "spacing": 7,
                                    "bgScale": 1,
                                    "orientation": "HORIZ"
                                },
                                "style": "lb1",
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                                    "layout": {
                                        "width": 36,
                                        "height": 34,
                                        "x": 140,
                                        "y": 294,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.LinkBar",
                                    "data": {
                                        "type": "ImageList",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "items": [{
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "Grey LinkedIn Icon",
                                            "uri": "22fa9ffe67ef4289a150e75a66ee4933.png",
                                            "width": 200,
                                            "height": 200,
                                            "alt": "",
                                            "link": {
                                                "type": "ExternalLink",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "url": "https://www.linkedin.com/company/wix-com",
                                                "target": "_blank"
                                            }
                                        }]
                                    },
                                    "props": {
                                        "type": "LinkBarProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "gallery": "social_icons",
                                        "iconSize": 35,
                                        "spacing": 15,
                                        "bgScale": 1,
                                        "orientation": "HORIZ"
                                    },
                                    "style": "lb1"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 239,
                                    "height": 38,
                                    "x": 47,
                                    "y": 394,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.3em; text-align:center\"><span style=\"font-size:14px\"><span style=\"color:#000000\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 264,
                                        "height": 42,
                                        "x": 28,
                                        "y": 333,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.3em; text-align:center\"><span style=\"font-size:14px\"><span style=\"color:#000000\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "brightness": 1,
                                        "packed": false
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                "layout": {
                                    "width": 216,
                                    "height": 217,
                                    "x": 59,
                                    "y": 44,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WPhoto",
                                "data": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "guy2.jpg",
                                    "uri": "8d13be_8c9171d931e04e32bb29cd0d41aaec56.jpg",
                                    "description": "",
                                    "width": 4800,
                                    "height": 3203,
                                    "alt": ""
                                },
                                "props": {
                                    "type": "WPhotoProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "displayMode": "fill",
                                    "onClickBehavior": "goToLink",
                                    "effectName": "none"
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-brd": "1",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#FFFFFF",
                                            "brw": "0px",
                                            "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                        },
                                        "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                    "layout": {
                                        "width": 211,
                                        "height": 217,
                                        "x": 53,
                                        "y": 38,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "guy2.jpg",
                                        "uri": "8d13be_8c9171d931e04e32bb29cd0d41aaec56.jpg",
                                        "description": "",
                                        "width": 4800,
                                        "height": 3203,
                                        "alt": ""
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink",
                                        "effectName": "none"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brw": "0px",
                                                "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                            },
                                            "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 333,
                                "height": 468,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 414,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 146,
                                    "height": 18,
                                    "x": 89,
                                    "y": 291,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:20px; line-height:0.8em; text-align:center\"><span style=\"font-size:20px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#0F0F0F\"><span style=\"font-weight:normal\">Jane Doe</span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 92,
                                        "height": 18,
                                        "x": 113,
                                        "y": 268,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:20px; line-height:0.8em; text-align:center\"><span style=\"font-size:20px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#0F0F0F\"><span style=\"font-weight:normal\">Jane Doe</span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "brightness": 1,
                                        "packed": false
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                                "layout": {
                                    "width": 42,
                                    "height": 41,
                                    "x": 140,
                                    "y": 333,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.LinkBar",
                                "data": {
                                    "type": "ImageList",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "items": [{
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "Grey LinkedIn Icon",
                                        "uri": "22fa9ffe67ef4289a150e75a66ee4933.png",
                                        "width": 200,
                                        "height": 200,
                                        "alt": "",
                                        "link": {
                                            "type": "ExternalLink",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "url": "https://www.linkedin.com/company/wix-com",
                                            "target": "_blank"
                                        }
                                    }]
                                },
                                "props": {
                                    "type": "LinkBarProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "gallery": "social_icons",
                                    "iconSize": 42,
                                    "spacing": 7,
                                    "bgScale": 1,
                                    "orientation": "HORIZ"
                                },
                                "style": "lb1",
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                                    "layout": {
                                        "width": 36,
                                        "height": 34,
                                        "x": 140,
                                        "y": 291,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.LinkBar",
                                    "data": {
                                        "type": "ImageList",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "items": [{
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "Grey LinkedIn Icon",
                                            "uri": "22fa9ffe67ef4289a150e75a66ee4933.png",
                                            "width": 200,
                                            "height": 200,
                                            "alt": "",
                                            "link": {
                                                "type": "ExternalLink",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "url": "https://www.linkedin.com/company/wix-com",
                                                "target": "_blank"
                                            }
                                        }]
                                    },
                                    "props": {
                                        "type": "LinkBarProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "gallery": "social_icons",
                                        "iconSize": 35,
                                        "spacing": 15,
                                        "bgScale": 1,
                                        "orientation": "HORIZ"
                                    },
                                    "style": "lb1"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 239,
                                    "height": 38,
                                    "x": 42,
                                    "y": 394,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.3em; text-align:center\"><span style=\"font-size:14px\"><span style=\"color:#000000\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 270,
                                        "height": 42,
                                        "x": 26,
                                        "y": 325,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.3em; text-align:center\"><span style=\"font-size:14px\"><span style=\"color:#000000\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "brightness": 1,
                                        "packed": false
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                "layout": {
                                    "width": 216,
                                    "height": 217,
                                    "x": 49,
                                    "y": 44,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WPhoto",
                                "data": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "guy4.jpg",
                                    "uri": "8d13be_823ed7dfb3484ce3b731ee9f43e002be.jpg",
                                    "description": "",
                                    "width": 769,
                                    "height": 769,
                                    "alt": ""
                                },
                                "props": {
                                    "type": "WPhotoProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "displayMode": "fill",
                                    "onClickBehavior": "goToLink",
                                    "effectName": "none"
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-brd": "1",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#FFFFFF",
                                            "brw": "0px",
                                            "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                        },
                                        "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                    "layout": {
                                        "width": 216,
                                        "height": 217,
                                        "x": 52,
                                        "y": 30,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "guy4.jpg",
                                        "uri": "8d13be_823ed7dfb3484ce3b731ee9f43e002be.jpg",
                                        "description": "",
                                        "width": 769,
                                        "height": 769,
                                        "alt": ""
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink",
                                        "effectName": "none"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brw": "0px",
                                                "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                            },
                                            "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 323,
                                "height": 468,
                                "x": 333,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 413,
                                    "x": 0,
                                    "y": 414,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                "layout": {
                                    "width": 210,
                                    "height": 217,
                                    "x": 54,
                                    "y": 44,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WPhoto",
                                "data": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "guy3.jpg",
                                    "uri": "8d13be_d18869067c9846de9d6493c7ab35ec4f.jpg",
                                    "description": "",
                                    "width": 1920,
                                    "height": 1280,
                                    "alt": ""
                                },
                                "props": {
                                    "type": "WPhotoProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "displayMode": "fill",
                                    "onClickBehavior": "goToLink",
                                    "effectName": "none"
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-brd": "1",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#FFFFFF",
                                            "brw": "0px",
                                            "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                        },
                                        "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                    "layout": {
                                        "width": 211,
                                        "height": 217,
                                        "x": 53,
                                        "y": 41,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "guy3.jpg",
                                        "uri": "8d13be_d18869067c9846de9d6493c7ab35ec4f.jpg",
                                        "description": "",
                                        "width": 1920,
                                        "height": 1280,
                                        "alt": ""
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink",
                                        "effectName": "none"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brw": "0px",
                                                "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                            },
                                            "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                    }
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 146,
                                    "height": 18,
                                    "x": 89,
                                    "y": 291,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size: 20px; line-height: 0.8em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#0F0F0F\"><span style=\"font-weight:normal\">James Doe</span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 108,
                                        "height": 19,
                                        "x": 107,
                                        "y": 278,
                                        "scale": 1.1025,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size: 20px; line-height: 0.8em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#0F0F0F\"><span style=\"font-weight:normal\">James Doe</span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "brightness": 1,
                                        "packed": false
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                                "layout": {
                                    "width": 42,
                                    "height": 41,
                                    "x": 140,
                                    "y": 333,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.LinkBar",
                                "data": {
                                    "type": "ImageList",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "items": [{
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "Grey LinkedIn Icon",
                                        "uri": "22fa9ffe67ef4289a150e75a66ee4933.png",
                                        "width": 200,
                                        "height": 200,
                                        "alt": "",
                                        "link": {
                                            "type": "ExternalLink",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "url": "https://www.linkedin.com/company/wix-com",
                                            "target": "_blank"
                                        }
                                    }]
                                },
                                "props": {
                                    "type": "LinkBarProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "gallery": "social_icons",
                                    "iconSize": 42,
                                    "spacing": 7,
                                    "bgScale": 1,
                                    "orientation": "HORIZ"
                                },
                                "style": "lb1",
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                                    "layout": {
                                        "width": 36,
                                        "height": 36,
                                        "x": 140,
                                        "y": 299,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.LinkBar",
                                    "data": {
                                        "type": "ImageList",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "items": [{
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "Grey LinkedIn Icon",
                                            "uri": "22fa9ffe67ef4289a150e75a66ee4933.png",
                                            "width": 200,
                                            "height": 200,
                                            "alt": "",
                                            "link": {
                                                "type": "ExternalLink",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "url": "https://www.linkedin.com/company/wix-com",
                                                "target": "_blank"
                                            }
                                        }]
                                    },
                                    "props": {
                                        "type": "LinkBarProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "gallery": "social_icons",
                                        "iconSize": 35,
                                        "spacing": 15,
                                        "bgScale": 1,
                                        "orientation": "HORIZ"
                                    },
                                    "style": "lb1"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 239,
                                    "height": 38,
                                    "x": 42,
                                    "y": 394,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.3em; text-align:center\"><span style=\"font-size:14px\"><span style=\"color:#000000\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 260,
                                        "height": 42,
                                        "x": 30,
                                        "y": 345,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.3em; text-align:center\"><span style=\"font-size:14px\"><span style=\"color:#000000\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                        "brightness": 1,
                                        "packed": false
                                    },
                                    "style": "txtNew"
                                }
                            }],
                            "layout": {
                                "width": 323,
                                "height": 468,
                                "x": 657,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 474,
                                    "x": 0,
                                    "y": 827,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }],
                        "layout": {
                            "width": 980,
                            "height": 468,
                            "x": 0,
                            "y": 171,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                        "props": {
                            "type": "StripColumnsContainerProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullWidth": false,
                            "columnsMargin": 0,
                            "frameMargin": 0,
                            "rowMargin": 0,
                            "siteMargin": 0
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 1
                            }
                        },
                        "style": "strc1",
                        "mobileStructure": {
                            "type": "Container",
                            "layout": {
                                "width": 320,
                                "height": 1301,
                                "x": 0,
                                "y": 117,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                            "props": {
                                "type": "StripColumnsContainerProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullWidth": false,
                                "columnsMargin": 0,
                                "frameMargin": 0,
                                "rowMargin": 0,
                                "siteMargin": 0
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "strc1"
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 679,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FAFAFA",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 1372,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FAFAFA",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 679,
                    "x": 0,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 1372,
                        "x": 0,
                        "y": 108,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioiijvzp"
            },
            "preset": {
                "rect": {"width": 324, "height": 177.20000000000002, "x": -0.8, "y": 0},
                "label": "add_preset_columns_team_1",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTeam_2",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 238,
                        "height": 514,
                        "x": -136,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "GettyImages-124893619.jpg",
                                "uri": "035244_49fa1767a96f459f8a97bf165f96784b~mv2_d_4676_4871_s_4_2.jpg",
                                "description": "private/",
                                "width": 4676,
                                "height": 4871,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "{color_11}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 250,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "GettyImages-124893619.jpg",
                                    "uri": "035244_49fa1767a96f459f8a97bf165f96784b~mv2_d_4676_4871_s_4_2.jpg",
                                    "description": "private/",
                                    "width": 4676,
                                    "height": 4871,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_11}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 237,
                            "height": 120,
                            "x": 1,
                            "y": 69,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:0.9em; text-align:center\"><span style=\"color:#00305B\"><span style=\"font-size:60px\"><span style=\"font-family:raleway,sans-serif\"><span style=\"font-weight:bold\">OUR</span> TEAM</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 202,
                                "height": 37,
                                "x": 59,
                                "y": 31,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:0.9em; text-align:center\"><span style=\"color:#00305B\"><span style=\"font-size:60px\"><span style=\"font-family:raleway,sans-serif\"><span style=\"font-weight:bold\">OUR</span> TEAM</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 232,
                            "height": 128,
                            "x": 3,
                            "y": 275,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"color:#00305B\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"letter-spacing:-0.02em;\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to the font.&nbsp;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 272,
                                "height": 93,
                                "x": 23,
                                "y": 87,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"color:#00305B\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"letter-spacing:-0.02em;\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to the font.&nbsp;</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 44,
                            "height": 5.55556,
                            "x": 100,
                            "y": 228,
                            "scale": 1,
                            "rotationInDegrees": 138,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#00305B", "lnw": "6"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 238,
                            "height": 26,
                            "x": 0,
                            "y": 436,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"color:#00305B\"><span style=\"text-decoration:underline\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Read More</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 89,
                                "height": 26,
                                "x": 116,
                                "y": 195,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"color:#00305B\"><span style=\"text-decoration:underline\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Read More</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 238,
                        "height": 514,
                        "x": 202,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#EAF4F6",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 251,
                            "x": 0,
                            "y": 259,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EAF4F6",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 238,
                        "height": 514,
                        "x": 540,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "GettyImages-145680711.jpg",
                                "uri": "035244_8ea2789a36434fa5abddc1502b977886~mv2_d_4676_4871_s_4_2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 4676,
                                "height": 4871,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 250,
                            "x": 0,
                            "y": 519,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "GettyImages-145680711.jpg",
                                    "uri": "035244_8ea2789a36434fa5abddc1502b977886~mv2_d_4676_4871_s_4_2.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 4676,
                                    "height": 4871,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 238,
                        "height": 514,
                        "x": 878,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "GettyImages-535587703.jpg",
                                "uri": "035244_429896c0c9db41bdbeee497c2b6a090c~mv2_d_4676_4871_s_4_2.jpg",
                                "description": "private/",
                                "width": 4676,
                                "height": 4871,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#B3F2FF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 250,
                            "x": 0,
                            "y": 778,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "GettyImages-535587703.jpg",
                                    "uri": "035244_429896c0c9db41bdbeee497c2b6a090c~mv2_d_4676_4871_s_4_2.jpg",
                                    "description": "private/",
                                    "width": 4676,
                                    "height": 4871,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#B3F2FF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 514,
                    "x": 0,
                    "y": 986,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 9,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#EAF4F6",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 1028,
                        "x": 0,
                        "y": 2082,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 9,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#EAF4F6",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ip2d91dy"
            },
            "preset": {
                "rect": {"width": 324, "height": 142, "x": -0.8, "y": 177.20000000000002},
                "label": "add_preset_columns_team_2",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTeam_3",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 327,
                            "height": 140,
                            "x": 164,
                            "y": 108,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.9em; text-align:right\"><span style=\"font-size:70px\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#3588CC\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">THE</span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.9em; text-align:right\"><span style=\"font-size:70px\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#3588CC\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">TEAM</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 113,
                                "x": 10,
                                "y": 28,
                                "scale": 1.4071004226562505,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.9em; text-align:right\"><span style=\"font-size:70px\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#3588CC\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">THE</span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.9em; text-align:right\"><span style=\"font-size:70px\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#3588CC\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">TEAM</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 490,
                        "height": 515,
                        "x": -90,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#045184",
                            "colorOpacity": 0.85,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 161,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#045184",
                                "colorOpacity": 0.85,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                        "layout": {
                            "width": 110,
                            "height": 30,
                            "x": 0,
                            "y": 424,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.LinkBar",
                        "data": {
                            "type": "ImageList",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "items": [{
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Facebook - White Circle",
                                "uri": "e0678ef25486466ba65ef6ad47b559e1.png",
                                "width": 200,
                                "height": 200,
                                "alt": ""
                            }, {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "LinkedIn - White Circle",
                                "uri": "f61c7a3b4b4947b28511a25034973383.png",
                                "width": 200,
                                "height": 200,
                                "alt": ""
                            }, {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Twitter - White Circle",
                                "uri": "c4392d634a0148fda8b7b2b0ad98293b.png",
                                "width": 200,
                                "height": 200,
                                "alt": ""
                            }]
                        },
                        "props": {
                            "type": "LinkBarProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "gallery": "social_icons",
                            "iconSize": 32,
                            "spacing": 7,
                            "bgScale": 1,
                            "orientation": "HORIZ"
                        },
                        "style": "lb1",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                            "layout": {
                                "width": 134,
                                "height": 36,
                                "x": 91,
                                "y": 290,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.LinkBar",
                            "data": {
                                "type": "ImageList",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "items": [{
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Facebook - White Circle",
                                    "uri": "e0678ef25486466ba65ef6ad47b559e1.png",
                                    "width": 200,
                                    "height": 200,
                                    "alt": ""
                                }, {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "LinkedIn - White Circle",
                                    "uri": "f61c7a3b4b4947b28511a25034973383.png",
                                    "width": 200,
                                    "height": 200,
                                    "alt": ""
                                }, {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Twitter - White Circle",
                                    "uri": "c4392d634a0148fda8b7b2b0ad98293b.png",
                                    "width": 200,
                                    "height": 200,
                                    "alt": ""
                                }]
                            },
                            "props": {
                                "type": "LinkBarProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "gallery": "social_icons",
                                "iconSize": 35,
                                "spacing": 15,
                                "bgScale": 1,
                                "orientation": "HORIZ"
                            },
                            "style": "lb1"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 297,
                            "height": 222,
                            "x": 3,
                            "y": 109,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"display:none\">&nbsp;</span><span style=\"font-family:bree-w01-thin-oblique,sans-serif\"><span style=\"font-size:16px\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. I&rsquo;m a great place for you to tell a story and let your users know a little more about you.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 266,
                                "height": 204,
                                "x": 24,
                                "y": 30,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"display:none\">&nbsp;</span><span style=\"font-family:bree-w01-thin-oblique,sans-serif\"><span style=\"font-size:16px\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. I&rsquo;m a great place for you to tell a story and let your users know a little more about you.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 490,
                        "height": 515,
                        "x": 580,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#E21C21",
                            "colorOpacity": 0.65,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 356,
                            "x": 0,
                            "y": 161,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#E21C21",
                                "colorOpacity": 0.65,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 515,
                    "x": 0,
                    "y": 1696,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "mediaRef": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": "Therapy Session",
                            "uri": "12c18de97a854bbe961f4b3d52f13b46.jpg",
                            "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                            "width": 5371,
                            "height": 3256,
                            "alt": "",
                            "artist": {"id": "mobile_designItem_iv83x9363", "name": ""}
                        },
                        "color": "{color_11}",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv83x93a"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 517,
                        "x": 0,
                        "y": 3140,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Therapy Session",
                                "uri": "12c18de97a854bbe961f4b3d52f13b46.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 5371,
                                "height": 3256,
                                "alt": "",
                                "artist": {"id": "mobile_designItem_iv83x8ys3", "name": ""}
                            },
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv83x939"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ip2b96fy"
            },
            "preset": {
                "rect": {"width": 324, "height": 143, "x": -0.8, "y": 319.20000000000005},
                "label": "add_preset_columns_team_3",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTeam_4",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 246,
                            "height": 38,
                            "x": 0,
                            "y": 79,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:30px; text-align:center\"><span style=\"font-size:30px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:rgb(47, 46, 46)\">Jessica Lake</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 36,
                                "x": 20,
                                "y": 36,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:30px; text-align:center\"><span style=\"font-size:30px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:rgb(47, 46, 46)\">Jessica Lake</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 123,
                            "height": 5,
                            "x": 61,
                            "y": 307,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": "hl3",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 60,
                                "height": 5,
                                "x": 130,
                                "y": 187,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": "hl3"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 246,
                            "height": 128,
                            "x": 0,
                            "y": 142,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to </span></span><span style=\"font-size:15px\"><span style=\"color:#000000\">the font.&nbsp;</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 93,
                                "x": 20,
                                "y": 78,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to </span></span><span style=\"font-size:15px\"><span style=\"color:#000000\">the font.&nbsp;</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                        "layout": {
                            "width": 40,
                            "height": 40,
                            "x": 102,
                            "y": 339,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.LinkBar",
                        "data": {
                            "type": "ImageList",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "items": [{
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Black Twitter Icon",
                                "uri": "c7d035ba85f6486680c2facedecdcf4d.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "url": "http://www.twitter.com/wix",
                                    "target": "_blank"
                                }
                            }]
                        },
                        "props": {
                            "type": "LinkBarProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "gallery": "social_icons",
                            "iconSize": 40,
                            "spacing": 6,
                            "bgScale": 1,
                            "orientation": "HORIZ"
                        },
                        "style": "lb1",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                            "layout": {
                                "width": 36,
                                "height": 34,
                                "x": 140,
                                "y": 202,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.LinkBar",
                            "data": {
                                "type": "ImageList",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "items": [{
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Black Twitter Icon",
                                    "uri": "c7d035ba85f6486680c2facedecdcf4d.png",
                                    "description": "",
                                    "width": 200,
                                    "height": 200,
                                    "alt": "",
                                    "link": {
                                        "type": "ExternalLink",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "url": "http://www.twitter.com/wix",
                                        "target": "_blank"
                                    }
                                }]
                            },
                            "props": {
                                "type": "LinkBarProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "gallery": "social_icons",
                                "iconSize": 35,
                                "spacing": 15,
                                "bgScale": 1,
                                "orientation": "HORIZ"
                            },
                            "style": "lb1"
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": -136,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFEF98",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 266,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFEF98",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 200,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Woman with Short Blond Hair",
                                "uri": "3963a192bdc842a1b166ff968c0217ef.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 2171,
                                "height": 2799,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#F7F5E1",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 220,
                            "x": 0,
                            "y": 266,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Woman with Short Blond Hair",
                                    "uri": "3963a192bdc842a1b166ff968c0217ef.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                    "width": 2171,
                                    "height": 2799,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#F7F5E1",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 1
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 246,
                            "height": 38,
                            "x": 0,
                            "y": 79,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:30px; text-align:center\"><span style=\"font-size:30px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:rgb(47, 46, 46)\">Rick Landry</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 189,
                                "height": 36,
                                "x": 66,
                                "y": 37,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:30px; text-align:center\"><span style=\"font-size:30px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:rgb(47, 46, 46)\">Rick Landry</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 246,
                            "height": 128,
                            "x": 0,
                            "y": 142,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to </span></span><span style=\"font-size:15px\"><span style=\"color:#000000\">the font.&nbsp;</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 93,
                                "x": 20,
                                "y": 80,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or double click me to add your own content and make changes to </span></span><span style=\"font-size:15px\"><span style=\"color:#000000\">the font.&nbsp;</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "brightness": 1,
                                "packed": false
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 123,
                            "height": 5,
                            "x": 61,
                            "y": 307,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": "hl3",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 60,
                                "height": 5,
                                "x": 130,
                                "y": 192,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": "hl3"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                        "layout": {
                            "width": 40,
                            "height": 40,
                            "x": 102,
                            "y": 339,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.LinkBar",
                        "data": {
                            "type": "ImageList",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "items": [{
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Black Twitter Icon",
                                "uri": "c7d035ba85f6486680c2facedecdcf4d.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "url": "http://www.twitter.com/wix",
                                    "target": "_blank"
                                }
                            }]
                        },
                        "props": {
                            "type": "LinkBarProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "gallery": "social_icons",
                            "iconSize": 40,
                            "spacing": 6,
                            "bgScale": 1,
                            "orientation": "HORIZ"
                        },
                        "style": "lb1",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                            "layout": {
                                "width": 36,
                                "height": 34,
                                "x": 140,
                                "y": 204,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.LinkBar",
                            "data": {
                                "type": "ImageList",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "items": [{
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Black Twitter Icon",
                                    "uri": "c7d035ba85f6486680c2facedecdcf4d.png",
                                    "description": "",
                                    "width": 200,
                                    "height": 200,
                                    "alt": "",
                                    "link": {
                                        "type": "ExternalLink",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "url": "http://www.twitter.com/wix",
                                        "target": "_blank"
                                    }
                                }]
                            },
                            "props": {
                                "type": "LinkBarProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                                "gallery": "social_icons",
                                "iconSize": 35,
                                "spacing": 15,
                                "bgScale": 1,
                                "orientation": "HORIZ"
                            },
                            "style": "lb1"
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 536,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFEF98",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 266,
                            "x": 0,
                            "y": 486,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFEF98",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 871,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Young Man with Black Hat",
                                "uri": "06ea43656ff24cdab99843e32057b4c3.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 811,
                                "height": 1045,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#F7F5E1",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 220,
                            "x": 0,
                            "y": 752,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Young Man with Black Hat",
                                    "uri": "06ea43656ff24cdab99843e32057b4c3.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                    "width": 811,
                                    "height": 1045,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#F7F5E1",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 1
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 450,
                    "x": 0,
                    "y": 2411,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "{color_11}",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 972,
                        "x": 0,
                        "y": 3704,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-iomiyz4q"
            },
            "preset": {
                "rect": {"width": 324, "height": 130, "x": -0.8, "y": 462.20000000000005},
                "label": "add_preset_columns_team_4",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripColumnsContainer"]
    },
    "help": {"hide": false, "text": "add_section_info_text_column_team"}
}
