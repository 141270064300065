import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { AppData } from '../components/privateAppsProvider/privateAppsProvider';
import type { Dispatch, DispatchMapperArgs } from 'types/redux';
import { isMinorUpdatesOn } from './utils';
import { privateAppsUpdateAppVersion } from '@wix/bi-logger-blocks-consumption/v2';
import { TEST_VERSION, hasMajorUpdate, hasUpdates } from './appDetailsUtils';
import type { EditorAPI } from '@/editorAPI';
import type { PlatformAppDescription } from '@wix/document-services-types';
import { pendingAppsService } from '@/tpa';
import type { EditorState } from '@/stateManagement';

export interface UpdateAppOptions {
  app: AppData;
  version?: string;
}

export interface UpdateAppDispatchProps {
  updateApp: (options: { app: AppData; version: string }) => Promise<unknown>;
}

export const reportBiUpdateApp = (options: {
  origin: 'app item' | 'context menu' | 'app details page';
  version: string;
  appId: string;
}) => util.biLogger.report(privateAppsUpdateAppVersion(options));

export const updateApp =
  ({
    app,
    version = util.appStudioUtils.formatSemver(
      app.latestVersion,
      isMinorUpdatesOn(app?.appFields?.installedVersion),
    ),
  }: UpdateAppOptions) =>
  async (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) => {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_APP,
    );
    const isReponsiveBlocksApp = util.appStudioUtils.isResponsiveBlocksVersion(
      app.blocksVersion,
    );
    dispatch(stateManagement.selection.actions.clearSelectedComponents());
    dispatch(
      stateManagement.platform.actions.updateApp({
        applicationId: String(app.applicationId),
        appVersion:
          version !== 'latest' && !version.startsWith('^')
            ? util.appStudioUtils.formatSemver(version, true)
            : version,
        ...(!isReponsiveBlocksApp
          ? {
              payload: {
                updateType: 'MAJOR',
              },
            }
          : null),
      }),
    ).then(() => {
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_APP,
      );

      pendingAppsService.updateNotification(editorAPI);
    });
  };

export const shouldShowUpdateAction = (app: AppData): boolean => {
  if (hasUpdates(app) && app?.appFields?.installedVersion !== TEST_VERSION) {
    if (util.appStudioUtils.isResponsiveBlocksVersion(app.blocksVersion)) {
      return (
        hasMajorUpdate(app) ||
        !isMinorUpdatesOn(app?.appFields?.installedVersion)
      );
    }

    return true;
  }

  return false;
};

export const getAllAvailableToUpdateApps = (editorAPI: EditorAPI) => {
  const apps = editorAPI.dsRead.platform.getInstalledEditorApps();
  return apps.reduce(
    (acc: AppData[], appDescription: PlatformAppDescription) => {
      const app: AppData = {
        ...editorAPI.dsRead.platform.getAppDataByAppDefId(
          appDescription.appDefinitionId,
        ),
        latestVersion: stateManagement.platform.selectors.getLatestVersions(
          editorAPI.store.getState(),
        )[appDescription.appDefinitionId],
      } as AppData;
      if (shouldShowUpdateAction(app)) {
        acc.push(app);
      }
      return acc;
    },
    [],
  );
};
