:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._progressbar-wrapper_1cqkp_7 {
  margin: 0 -24px; }

._placeholder_1cqkp_10 {
  margin: 0 auto;
  background-color: #eff2f5;
  border: 1px solid #dfe5eb;
  border-radius: 4px;
  margin-bottom: 36px; }
  ._placeholder_1cqkp_10:after {
    content: '';
    display: block;
    height: 100%;
    position: relative;
    background: linear-gradient(to right, #eff2f5 10%, #fff 18%, #eff2f5 33%);
    background-size: 1000px 100px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: _placeHolderShimmer_1cqkp_1;
    animation-timing-function: linear; }

._lp-wrapper_1cqkp_29 {
  height: 114px;
  padding: 0 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.12);
  margin-bottom: 18px; }

._lp-title_1cqkp_39 {
  margin-bottom: 10px; }

._lp-bar-wrapper_1cqkp_42 {
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: #d6e6fe;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden; }

._lp-bar-gradient_1cqkp_51 {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  animation: _progressBarRevealing_1cqkp_1 9s linear; }
  ._lp-bar-gradient_1cqkp_51:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    border-radius: 3px;
    background: linear-gradient(87.44deg, #0021ff 0%, #116dff 25%, #5ff2ae 50%, #116dff 75%, #0021ff 100%);
    background-size: 300% 200%;
    background-repeat: repeat;
    will-change: background-position;
    animation: _progressBarBackground_1cqkp_1 2s infinite; }

@keyframes _placeHolderShimmer_1cqkp_1 {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes _progressBarRevealing_1cqkp_1 {
  0% {
    right: 100%; }
  100% {
    right: 0%; } }

@keyframes _progressBarBackground_1cqkp_1 {
  0% {
    background-position: right center; }
  100% {
    background-position: left center; } }
