import _ from 'lodash';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function filterSickComponentActions(
  migrationActions: AnyFixMe,
  sickActions: AnyFixMe,
) {
  return function (editorApi: AnyFixMe, compRef: AnyFixMe) {
    const editedCompData = editorApi.components.data.get(compRef);
    const listId = editedCompData.appPartName;

    const healthyMigrationActions = migrationActions;

    const actions =
      editorApi.dsRead.wixapps.appbuilder.metadata.wasPartLoadedSuccessfully(
        listId,
      )
        ? healthyMigrationActions
        : sickActions;

    return _.isFunction(actions)
      ? actions(editorApi, listId, compRef)
      : actions;
  };
}

export default {
  mainActions: filterSickComponentActions([], []),
  enabledActions: filterSickComponentActions(
    [ACTIONS.SETTINGS, ACTIONS.HELP],
    [ACTIONS.HELP],
  ),
  mobileEnabledActions: filterSickComponentActions(
    [ACTIONS.SETTINGS, ACTIONS.HIDE, ACTIONS.HELP],
    [ACTIONS.HIDE, ACTIONS.HELP],
  ),
  presetActions: {
    help: {
      helpId: '4f57fc6a-5862-49eb-b408-a2e2488cb563',
    },
  },
};
