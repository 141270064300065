import React, { PureComponent } from 'react';
import * as util from '@/util';
import * as symbols from '@wix/santa-editor-symbols';
import TopBarButton from '../topBarButton/topBarButtonWithTooltip';
import DropDown from '../dropDown/dropDown';
import TestSiteRCPanel from '../dropPanel/testSiteRC/testSiteRC';
import TooltipContent from '../tooltipContent';
import type { SendBiFunction } from 'types/bi';
import type { ProgressStatus } from '@/constants';
import * as coreBi from '@/coreBi';
import _ from 'lodash';
import withSendBi, { type WithSendBiProps } from '../hocs/withSendBi';
import { mapStateToProps } from './testSiteRCMappers';

const customSymbol = React.createElement(symbols.symbol, {
  name: 'arrowDown',
  color: 'red',
});

type TestSiteRCButtonProps = WithSendBiProps &
  TestSiteRCButtonOwnProps &
  TestSiteRCButtonStateProps;

export interface TestSiteRCButtonStateProps {
  testSiteButtonActionLabel: string;
}

export interface TestSiteRCButtonOwnProps {
  status: ProgressStatus;
  className?: string;
  disabled: boolean;
  sendBi: SendBiFunction;
  delayedFadeOut: boolean;
  isPanelOpen: boolean;
  openPanel: () => void;
  closePanel: () => void;
}

class TestSiteRCButton extends PureComponent<TestSiteRCButtonProps> {
  handleClick = () => {
    if (this.props.isPanelOpen) {
      this.props.closePanel();
    } else {
      this.props.openPanel();
    }
    this.props.sendBi(coreBi.events.testSite.TEST_SITE_DROPDOWN_CLICKED, {
      origin: this.props.testSiteButtonActionLabel,
    });
  };

  render() {
    const className = util.cx({
      'top-bar-test-site-rc-btn': true,
      'btn-confirm-secondary': true,
    });

    const tooltip = this.props.isPanelOpen ? null : (
      <TooltipContent
        tooltipData={{
          title: 'TopBar_DevMode_Preview_Options_Tooltip_Title',
          text: 'TopBar_DevMode_Preview_Options_Tooltip_Description',
        }}
      />
    );

    const TOOLTIP_MARGIN_BOTTOM = 13;
    const PANEL_RIGHT_MARGIN = 9;

    return (
      <DropDown
        isOpen={this.props.isPanelOpen}
        panelRightMargin={PANEL_RIGHT_MARGIN}
        delayedFadeOut={this.props.delayedFadeOut}
        onPanelMouseEnter={this.props.openPanel}
        onPanelMouseLeave={this.props.closePanel}
        panelContent={<TestSiteRCPanel />}
        panelTopMargin={11}
      >
        <TopBarButton
          automationId="top-bar-button-test-site-rc"
          customSymbol={customSymbol}
          onClick={this.handleClick}
          className={className}
          tooltip={tooltip}
          tooltipMarginBottom={TOOLTIP_MARGIN_BOTTOM}
          disabled={this.props.disabled}
        />
      </DropDown>
    );
  }
}

const ConnectedTestSiteRCButton = _.flow(
  util.hoc.connect(util.hoc.STORES.EDITOR_API, mapStateToProps),
  withSendBi,
)(TestSiteRCButton) as React.ComponentType<TestSiteRCButtonOwnProps>;
(ConnectedTestSiteRCButton as AnyFixMe).pure = TestSiteRCButton;

export default ConnectedTestSiteRCButton;
