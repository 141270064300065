:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_1ey9o_7 {
  padding: 12px 9px;
  position: relative; }

._button_1ey9o_11 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); }
  ._button_1ey9o_11:hover {
    opacity: 0.9;
    background: rgba(17, 109, 255, 0.1); }
  ._button_1ey9o_11.selected {
    background: url("https://static.parastorage.com/services/santa-resources/dist/editor/rEditor/rootComps/gfpp/sparklesBoldAnimationBackground.v2.svg");
    background-size: contain; }
    ._button_1ey9o_11.selected:hover {
      background: url("https://static.parastorage.com/services/santa-resources/dist/editor/rEditor/rootComps/gfpp/sparklesBoldAnimationBackgroundHover.svg");
      background-size: contain; }
    ._button_1ey9o_11.selected .symbol {
      fill: #fff;
      transform: scale(0.7); }
  ._button_1ey9o_11._disabled_1ey9o_31, ._button_1ey9o_11:disabled {
    pointer-events: none;
    opacity: 0.6; }
  ._button_1ey9o_11:not(.aiToolsWasOpened) .symbol .around-shapes {
    animation: _play-circle-shapes-rotate_1ey9o_1 12s infinite;
    animation-timing-function: linear;
    animation-delay: 3s; }

@keyframes _play-circle-shapes-rotate_1ey9o_1 {
  0% {
    rotate: 0deg; }
  5% {
    transform: translateX(-6px); }
  15% {
    opacity: 0.6;
    transform: translateX(-6px); }
  20%,
  100% {
    opacity: 1;
    rotate: 360deg; } }
  ._button_1ey9o_11 .symbol {
    width: 42px;
    height: 42px; }
    ._button_1ey9o_11 .symbol .around-shapes {
      opacity: 1; }
    ._button_1ey9o_11 .symbol:hover .around-shapes {
      animation: _circle-shapes-rotate_1ey9o_1 6s infinite;
      animation-timing-function: linear;
      opacity: 1; }

@keyframes _circle-shapes-rotate_1ey9o_1 {
  0% {
    rotate: 0deg; }
  30% {
    transform: translateX(-6px); }
  70% {
    opacity: 0.6;
    transform: translateX(-6px); }
  100% {
    opacity: 1;
    rotate: 360deg; } }
    ._button_1ey9o_11 .symbol:hover .ai-background {
      animation: _bg-rotate_1ey9o_1 10s infinite;
      animation-timing-function: linear;
      transform-origin: center;
      transform-box: fill-box;
      rotate: 0deg; }

@keyframes _bg-rotate_1ey9o_1 {
  0% {
    rotate: 0deg; }
  40% {
    transform: translateX(8px); }
  80% {
    transform: translateY(-2px); }
  100% {
    rotate: 350deg; } }
  ._button_1ey9o_11 .around-shapes {
    opacity: 0;
    transform-origin: center;
    transform-box: fill-box;
    rotate: 0deg; }

._floating-block_1ey9o_94 {
  position: absolute;
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none; }
  ._wrapper_1ey9o_7:hover ._floating-block_1ey9o_94 {
    opacity: 1; }
