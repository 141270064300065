._lists-wrapper_1miuc_1 {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  flex: 1; }

._lists-wrapper_1miuc_1::-webkit-scrollbar-track {
  width: 5px;
  height: 8px;
  background: transparent; }

._available-apps-list_1miuc_12 {
  margin-top: 24px; }

._pending-update-pimple_1miuc_15 {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center; }
