import { translate } from '@/i18n';
import panelConstants from './constants';
const title = translate('SiteMenu_Custom404_Page_Default_Name');

export default {
  title,
  definition: {
    type: 'Page',
    id: 'c1dmp',
    components: [
      {
        parent: 'c1dmp',
        layout: {
          x: 229,
          y: 29,
          fixedPosition: false,
          width: 487,
          height: 250,
          scale: 1,
          rotationInDegrees: 0,
        },
        metaData: {
          pageId: 'c1dmp',
        },
        componentType: 'wysiwyg.viewer.components.VectorImage',
        type: 'Component',
        id: 'comp-k2vvwjx7',
        skin: 'skins.viewer.VectorImageSkin',
        data: {
          type: 'VectorImage',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          svgId: '11062b_4a0c53962acf4f968e73daf68f8b8e3c.svg',
          title: '404 Cactus',
          id: 'dataItem-k2vvwjx8',
        },
        props: {
          id: 'propItem-k2vvwjxa',
          type: 'VectorImageProperties',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            autoGenerated: false,
            pageId: 'c1dmp',
          },
          displayMode: 'fit',
          flip: 'none',
        },
        mobileHints: {
          type: 'MobileHints',
          author: 'duplicate',
          originalCompId: 'comp-j6qle20z',
          recommendedScale: 1,
          recommendedWidth: 88,
          recommendedHeight: 100,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          id: 'mobileHints-k2vvwjxe',
        },
        design: {
          type: 'VectorImageDesignData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          shapeStyle: {
            opacity: 1,
            strokeWidth: 4,
            stroke: '#5E97FF',
            strokeOpacity: 1,
            enableStroke: false,
          },
          overrideColors: {
            color1: 'color_18',
          },
          id: 'dataItem-k2vvwjxa1',
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          style: {
            properties: {},
            propertiesSource: {},
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.VectorImage',
          pageId: '',
          compId: 'i8yjldka_0',
          styleType: 'custom',
          skin: 'skins.viewer.VectorImageSkin',
          id: 'style-k2vvwjxe1',
        },
      },
      {
        type: 'Component',
        id: 'comp-k2c27eq1',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 600,
          height: 195,
          x: 190,
          y: 341,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        parent: 'c1dmp',
        metaData: { pageId: 'c1dmp' },
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          text: `<h2 class="font_2" style="text-align:center; font-size: 28px"><span class="color_18">${translate(
            'custom404_page_template_title_text',
          )}</span></h2>`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
          id: 'dataItem-k2c27eqw',
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        id: 'comp-k2c29xns',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 377,
          height: 52,
          x: 301,
          y: 394,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        parent: 'c1dmp',
        metaData: { pageId: 'c1dmp' },
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          text: `<p class="font_7" style="text-align: center; line-height: 1.5em;"><span style="letter-spacing:0em;"><span class="color_15">${translate(
            'custom404_page_template_body_text',
          )}</span></span></p>`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
          id: 'dataItem-k2c29xoj',
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'c1dmp',
          },
          brightness: 1,
          packed: false,
          minHeight: 52,
          id: 'propItem-k2c2a228',
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        id: 'comp-k2c2bufn',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
        layout: {
          width: 155,
          height: 42,
          x: 412,
          y: 471,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.SiteButton',
        parent: 'c1dmp',
        metaData: { pageId: 'c1dmp' },
        data: {
          type: 'LinkableButton',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          label: translate('custom404_page_template_button_text'),
          link: {
            type: 'PageLink',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'c1dmp',
            },
            pageId: '#c1dmp',
            target: '_self',
          },
          id: 'dataItem-k2c2buga',
        },
        props: {
          type: 'ButtonProperties',
          metaData: { schemaVersion: '1.0', pageId: 'c1dmp' },
          align: 'center',
          margin: 0,
          id: 'propItem-k2c2bugc',
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
          },
          style: {
            properties: {
              'alpha-bg': '0',
              'alpha-bgh': '1',
              'alpha-brd': '1',
              'alpha-brdh': '0',
              'alpha-txt': '1',
              'alpha-txth': '1',
              bg: '#FFFFFF',
              bgh: 'color_18',
              'boxShadowToggleOn-shd': 'false',
              brd: 'color_18',
              brdh: '#323232',
              brw: '2',
              fnt: 'font_9',
              rd: '20px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              txt: 'color_18',
              txth: '#FFFFFF',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-bgh': 'value',
              'alpha-brd': 'value',
              'alpha-brdh': 'value',
              'alpha-txt': 'value',
              'alpha-txth': 'value',
              bg: 'value',
              bgh: 'theme',
              'boxShadowToggleOn-shd': 'value',
              brd: 'theme',
              brdh: 'value',
              brw: 'value',
              fnt: 'theme',
              rd: 'value',
              shd: 'value',
              txt: 'theme',
              txth: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.SiteButton',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.button.BasicButton',
          id: 'style-k2c3axu6',
        },
      },
    ],
    skin: 'wysiwyg.viewer.skins.page.TransparentPageSkin',
    layout: {
      width: 1903,
      height: 706,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'mobile.core.components.Page',
    metaData: { pageId: 'c1dmp' },
    data: {
      type: 'Page',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'masterPage',
      },
      title,
      hideTitle: true,
      icon: '',
      pageUriSEO: panelConstants.defaultUrl,
      hidePage: true,
      isMobileLandingPage: false,
      underConstruction: false,
      tpaApplicationId: 0,
      pageSecurity: { requireLogin: false },
      isPopup: false,
      indexable: false,
      isLandingPage: false,
      pageBackgrounds: {
        desktop: {
          custom: true,
          ref: {
            type: 'BackgroundMedia',
            metaData: {
              isPreset: false,
              schemaVersion: '2.0',
              isHidden: false,
              pageId: 'masterPage',
            },
            color: '{color_11}',
            alignType: 'top',
            fittingType: 'fill',
            scrollType: 'fixed',
          },
          isPreset: true,
        },
        mobile: {
          custom: true,
          ref: {
            type: 'BackgroundMedia',
            metaData: {
              isPreset: false,
              schemaVersion: '2.0',
              isHidden: false,
              pageId: 'masterPage',
            },
            color: '{color_11}',
            alignType: 'top',
            fittingType: 'fill',
            scrollType: 'fixed',
            colorOverlay: '',
            colorOverlayOpacity: 0,
          },
          isPreset: true,
          mediaSizing: 'viewport',
        },
      },
      translationData: { uriSEOTranslated: false },
      ignoreBottomBottomAnchors: true,
      ogImage: '',
    },
    props: {
      type: 'PageProperties',
      metaData: {
        schemaVersion: '1.0',
        autoGenerated: false,
        pageId: 'c1dmp',
      },
      desktop: { minHeight: 706 },
      id: 'propItem-k2c259np',
    },
    mobileHints: {
      type: 'MobileHints',
      hidden: false,
      metaData: { pageId: 'c1dmp' },
      id: 'mobileHints-j8u779np',
    },
    style: 'p1',
    behaviors: {
      type: 'ObsoleteBehaviorsList',
      items: '[]',
      metaData: { pageId: 'c1dmp' },
      id: 'behavior-jg9pfpzt',
    },
    activeModes: {},
  },
};
