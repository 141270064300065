import addPanelDataConsts, { type AddMenuSection } from '@/addPanelDataConsts';
import superAppsConstants from '../../superApps/superAppsConstants';
import * as service from './service';

const appDefinitionId = superAppsConstants.MEMBERS_WIDGET.APP_DEF_ID;
const widgetId = superAppsConstants.MEMBERS_WIDGET.WIDGET;

const onClick = service.onClickFactory(appDefinitionId, widgetId);
const onDrop = service.onDropFactory(appDefinitionId, widgetId);

const getSection = function (): AddMenuSection {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_memberswidget',
    subNavigationTitle: 'add_section_label_memberswidget',
    presetTitle: 'add_section_label_memberswidget',
    tooltipTitle: 'add_section_label_memberswidget',
    sectionName: 'membersWidgetSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
    props: {
      onClick,
      onDrop,
      image:
        'addPanelData/sections/membersWidgetSection_en/membersWidgetSection_en.png',
      items: [
        {
          id: 'Members_Widget_1',
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 980,
              height: 825,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            style: 'tpaw0',
            activeModes: {},
          },
          preset: { rect: { width: 324, height: 288, x: 0, y: 0 } },
        },
      ],
    },
    help: {
      hide: false,
      text: 'add_section_label_memberswidget_tooltip_description',
    },
  };
};

const registerSection = service.registerSectionFactory(getSection);

export { registerSection };
