import createReactClass from 'create-react-class';
import * as util from '@/util';

// eslint-disable-next-line react/prefer-es6-class
const UnifiedChatWrapper = createReactClass({
  displayName: 'UnifiedChatWrapper',
  componentDidMount() {
    util.chatManager.showChat();
  },
  componentWillUnmount() {
    util.chatManager.hideChat();
  },
  render: () => null,
});

export default UnifiedChatWrapper;
