"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_label_ratings_write",
    subNavigationTitle: "add_section_label_ratings_category",
    topTitle: "add_section_title_ratings_write",
    presetTitle: "add_section_heading_ratings_write",
    tooltipTitle: "add_section_label_ratings_write_tooltip_title",
    automationId: "add-panel-section-ratingsInputSection",
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        iconEnabledForComps: {}
    },
    props: {
        image:
            "addPanelData/sections/developer_en/ratingsInputSection_en.png",
        imageHover: "addPanelData/sections/developer_en/ratingsInputSection_hover_en.png",
        items: [
            {
                id: "User_Input_RatingsInput_1",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 285,
                        height: 62,
                        x: 11,
                        y: 15,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsInput",
                    data: {
                        type: "RatingsInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        titleText: i18n.translate("ratings_write_preset_star_text_title"),
                        labels: {
                            "1": i18n.translate("ratings_write_settings_onhover_1star_placeholder"),
                            "2": i18n.translate("ratings_write_settings_onhover_2star_placeholder"),
                            "3": i18n.translate("ratings_write_settings_onhover_3star_placeholder"),
                            "4": i18n.translate("ratings_write_preset_star_hover_label"),
                            "5": i18n.translate("ratings_write_settings_onhover_5star_placeholder")
                        },
                        svgId: "503047d3d3884f6097b82dec7af52fa8.svg"
                    },
                    props: {
                        type: "RatingsInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showTitle: true,
                        showLabels: true,
                        labelPosition: "top",
                        labelAlignment: "center",
                        a11yLabelPosition:"side",
                        a11yLabelAlignment: "center",
                        shapeSize: 32,
                        shapeSpacing: 15,
                        direction: "ltr",
                        required: false
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                emptyShapeColor: "#FFFFFF",
                                filledShapeColor: "#FFCB05",
                                labelFont:
                                    "normal normal 700 15px/1.4em futura-lt-w01-book",
                                labelFontColor: "color_15",
                                shapeBorderColor: "#FFCB05",
                                shapeBorderWidth: "10",
                                errorFilledShapeColor: "#FFFFFF",
                                errorShapeBorderColor: "#FF4040",
                                errorShapeBorderWidth: "10",
                                errorLabelFontColor: "#FF4040"
                            },
                            propertiesSource: {
                                emptyShapeColor: "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value",
                                errorFilledShapeColor: "value",
                                errorShapeBorderColor: "value",
                                errorShapeBorderWidth: "value",
                                errorLabelFontColor: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jj8hp4bk"
                },
                preset: {
                    rect: {width: 161, height: 105, x: 0, y: 0},
                    tags: null
                }
            },
            {
                id: "User_Input_RatingsInput_2",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 285,
                        height: 65,
                        x: 175,
                        y: 12,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsInput",
                    data: {
                        type: "RatingsInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        titleText: i18n.translate("ratings_write_preset_circle_text_title"),
                        labels: {
                            "1": i18n.translate("ratings_write_settings_onhover_1star_placeholder"),
                            "2": i18n.translate("ratings_write_settings_onhover_2star_placeholder"),
                            "3": i18n.translate("ratings_write_preset_circle_hover_label"),
                            "4": i18n.translate("ratings_write_settings_onhover_4star_placeholder"),
                            "5": i18n.translate("ratings_write_settings_onhover_5star_placeholder")
                        },
                        svgId: "05378efaa01c474eb81d55b0e0144068.svg"
                    },
                    props: {
                        type: "RatingsInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showTitle: true,
                        showLabels: true,
                        labelPosition: "top",
                        labelAlignment: "center",
                        a11yLabelPosition:"side",
                        a11yLabelAlignment: "center",
                        shapeSize: 32,
                        shapeSpacing: 15,
                        direction: "ltr",
                        required: false
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                emptyShapeColor: "#9DEAEA",
                                filledShapeColor: "#42CCCC",
                                labelFont:
                                    "normal normal 700 15px/1.4em helvetica-w01-light",
                                labelFontColor: "#235E8D",
                                shapeBorderColor: "#9DEAEA",
                                shapeBorderWidth: "0",
                                errorFilledShapeColor: "#FF4040",
                                errorShapeBorderColor: "#FF4040",
                                errorShapeBorderWidth: "0",
                                errorLabelFontColor: "#999997"
                            },
                            propertiesSource: {
                                emptyShapeColor: "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                labelFontColor: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value",
                                errorFilledShapeColor: "value",
                                errorShapeBorderColor: "value",
                                errorShapeBorderWidth: "value",
                                errorLabelFontColor: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jj8hp4bw"
                },
                preset: {
                    rect: {width: 161, height: 105, x: 161, y: 0},
                    tags: null
                }
            },
            {
                id: "User_Input_RatingsInput_3",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 285,
                        height: 61,
                        x: 11,
                        y: 112,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsInput",
                    data: {
                        type: "RatingsInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        titleText: i18n.translate("ratings_write_preset_heart_text_title"),
                        labels: {
                            "1": i18n.translate("ratings_write_settings_onhover_1star_placeholder"),
                            "2": i18n.translate("ratings_write_settings_onhover_2star_placeholder"),
                            "3": i18n.translate("Ratings_write_preset_heart_hover_label"),
                            "4": i18n.translate("ratings_write_settings_onhover_4star_placeholder"),
                            "5": i18n.translate("ratings_write_settings_onhover_5star_placeholder")
                        },
                        svgId: "df738905bc4f402cb8291135f9310a45.svg"
                    },
                    props: {
                        type: "RatingsInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showTitle: true,
                        showLabels: true,
                        labelPosition: "top",
                        labelAlignment: "center",
                        a11yLabelPosition:"side",
                        a11yLabelAlignment: "center",
                        shapeSize: 32,
                        shapeSpacing: 15,
                        direction: "ltr",
                        required: false
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                emptyShapeColor: "#FFFFFF",
                                filledShapeColor: "#F36F7A",
                                labelFont:
                                    "normal normal 700 15px/1.4em avenir-lt-w01_35-light1475496",
                                labelFontColor: "color_15",
                                shapeBorderColor: "#F36F7A",
                                shapeBorderWidth: "10",
                                errorFilledShapeColor: "#E3E3E3",
                                errorShapeBorderColor: "#FF4040",
                                errorShapeBorderWidth: "10",
                                errorLabelFontColor: "#FF4040"
                            },
                            propertiesSource: {
                                emptyShapeColor: "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value",
                                errorFilledShapeColor: "value",
                                errorShapeBorderColor: "value",
                                errorShapeBorderWidth: "value",
                                errorLabelFontColor: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jj8hp4cl"
                },
                preset: {
                    rect: {width: 161, height: 105, x: 0, y: 104},
                    tags: null
                }
            },
            {
                id: "User_Input_RatingsInput_4",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 285,
                        height: 41,
                        x: 175,
                        y: 132,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsInput",
                    data: {
                        type: "RatingsInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        titleText: i18n.translate("Ratings_write_preset_alien_text_title"),
                        labels: {
                            "1": i18n.translate("ratings_write_settings_onhover_1star_placeholder"),
                            "2": i18n.translate("ratings_write_settings_onhover_2star_placeholder"),
                            "3": i18n.translate("ratings_write_settings_onhover_3star_placeholder"),
                            "4": i18n.translate("ratings_write_preset_alien_hover_label"),
                            "5": i18n.translate("ratings_write_settings_onhover_5star_placeholder")
                        },
                        svgId: "23dc714b88c1462eac760cfb220971b1.svg"
                    },
                    props: {
                        type: "RatingsInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showTitle: true,
                        showLabels: true,
                        labelPosition: "top",
                        labelAlignment: "center",
                        a11yLabelPosition:"side",
                        a11yLabelAlignment: "center",
                        shapeSize: 32,
                        shapeSpacing: 15,
                        direction: "ltr",
                        required: false
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                emptyShapeColor: "#C0E6CD",
                                filledShapeColor: "#55DD82",
                                labelFont:
                                    "normal normal 700 15px/1.4em bree-w01-thin-oblique",
                                labelFontColor: "#2B2B2B",
                                shapeBorderColor: "#55DD82",
                                shapeBorderWidth: "0",
                                errorFilledShapeColor: "#FF4040",
                                errorShapeBorderColor: "#FF4040",
                                errorShapeBorderWidth: "0",
                                errorLabelFontColor: "#999997"
                            },
                            propertiesSource: {
                                emptyShapeColor: "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                labelFontColor: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value",
                                errorFilledShapeColor: "value",
                                errorShapeBorderColor: "value",
                                errorShapeBorderWidth: "value",
                                errorLabelFontColor: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jj8hp4c8"
                },
                preset: {
                    rect: {width: 161, height: 105, x: 161, y: 104},
                    tags: null
                }
            }
        ],
        compTypes: ["wixui.RatingsInput"]
    },
    help: {hide: false, text: "add_section_label_ratings_write_tooltip_description"}
}


