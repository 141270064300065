import { events } from '@/coreBi';

import type { MigrationFlow } from '../types';
import type { SectionsMigrationScope as Scope } from '../scope';

export const reportMigrationNotPossible = (
  scope: Scope,
  {
    flow,
    isTimeLimitation,
    hasMigrationIssue,
    hasPartialMigrationIssue,
    hasTooManyPages,
    isNotOnBoarding,
    hasBeenFailedMigrated,
    isSaveIssue,
    isCancelledByUser,
  }: {
    flow: MigrationFlow;
    isTimeLimitation?: boolean;
    hasMigrationIssue?: boolean;
    hasPartialMigrationIssue?: boolean;
    hasTooManyPages?: boolean;
    isNotOnBoarding?: boolean;
    isSaveIssue?: boolean;
    hasBeenFailedMigrated?: boolean;
    isCancelledByUser?: boolean;
  },
) => {
  let reason;

  switch (true) {
    case hasBeenFailedMigrated:
      reason = 'already_failed_on_this_version';
      break;
    case isNotOnBoarding:
      reason = 'not_from_onboarding';
      break;
    case isTimeLimitation:
      reason = 'time_limitation';
      break;
    case isCancelledByUser:
      reason = 'canceled_by_user';
      break;
    case isSaveIssue:
      reason = 'save_issue';
      break;
    case hasPartialMigrationIssue:
      reason = 'partial_migration_issue';
      break;
    case hasMigrationIssue:
      reason = 'migration_issue';
      break;
    case hasTooManyPages:
      reason = 'too_many_pages';
      break;
    default:
      reason = 'other';
  }

  scope.editorAPI.bi.event(events.sectionsMigration.MIGRATION_NOT_POSSIBLE, {
    reason,
    flow,
    template_id:
      scope.editorAPI.documentServices.generalInfo.getSiteOriginalTemplateId(),
  });
};
