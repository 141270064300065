import React, { PureComponent } from 'react';

import * as util from '@/util';
import * as coreBi from '@/coreBi';
import * as symbols from '@wix/santa-editor-symbols';

import TopBarButton from '../topBarButton/topBarButtonWithTooltip';
import DropDown from '../dropDown/dropDown';
import PublishRCPanel from '../dropPanel/publishRC/publishRC';
import TooltipContent from '../tooltipContent';

import type { SendBiFunction } from 'types/bi';
import type { ProgressStatus } from '@/constants';

const customSymbol = React.createElement(symbols.symbol, { name: 'arrowDown' });

interface PublishRCButtonProps {
  status: ProgressStatus;
  className?: string;
  disabled: boolean;
  sendBi: SendBiFunction;
  delayedFadeOut: boolean;
  isPanelOpen: boolean;
  openPanel: () => void;
  closePanel: () => void;
}

class PublishRCButton extends PureComponent<PublishRCButtonProps> {
  handleClick = () => {
    if (this.props.isPanelOpen) {
      this.props.closePanel();
    } else {
      this.props.openPanel();
    }

    this.props.sendBi(coreBi.events.publish.PUBLISH_DROPDOWN_CLICKED, {
      origin: 'topbar',
    });
  };

  panelContent = (<PublishRCPanel />);

  render() {
    const className = util.cx({
      'top-bar-publish-rc-btn': true,
    });

    const tooltip = this.props.isPanelOpen ? null : (
      <TooltipContent
        tooltipData={{
          title: 'TOPBAR_PUBLISH_TEST_SITE_TOOLTIP_TITLE',
          text: 'TOPBAR_PUBLISH_TEST_SITE_TOOLTIP_BODY',
        }}
      />
    );

    const tooltipMarginBottom = 13;
    const panelRightMargin = 9;

    return (
      <DropDown
        isOpen={this.props.isPanelOpen}
        panelRightMargin={panelRightMargin}
        delayedFadeOut={this.props.delayedFadeOut}
        onPanelMouseEnter={this.props.openPanel}
        onPanelMouseLeave={this.props.closePanel}
        panelContent={this.panelContent}
        panelTopMargin={11}
      >
        <TopBarButton
          automationId="top-bar-button-publish-rc"
          customSymbol={customSymbol}
          onClick={this.handleClick}
          className={className}
          tooltip={tooltip}
          tooltipMarginBottom={tooltipMarginBottom}
          disabled={this.props.disabled}
        />
      </DropDown>
    );
  }
}

export default PublishRCButton;
