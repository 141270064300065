import { bi as TPAbi, appMarketUtils } from '@/tpa';
import type { AppInstallOrigin } from '@wix/editor-platform-host-integration-apis';
import type { EditorAPI } from '@/editorAPI';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

interface PlatformOriginEditor {
  type: EditorType;
  initiator: InstallInitiator.Editor;
  info: {
    type: InstallationOriginType;
  };
}

interface PlatformOriginApp {
  type: EditorType.Classic;
  initiator: InstallInitiator.App;
  info: {
    /**
     * AppDefinitionId of the app that initiated the installation.
     * Passed when an application is installed by another application
     */
    appDefinitionId: string;
  };
}
export type PlatformOrigin = AppInstallOrigin;

export const isPlatformOriginEditor = (
  origin: PlatformOrigin,
): origin is PlatformOriginEditor =>
  origin?.initiator === InstallInitiator.Editor;

export const isPlatformOriginAPP = (
  origin: PlatformOrigin,
): origin is PlatformOriginApp => origin?.initiator === InstallInitiator.App;

export interface BiBaseEvent {
  app_name: string;
  app_id: string;
  origin: PlatformOrigin;
  flowId?: string;
  allAppsToInstall?: string[];
}

export interface BiErrorEvent extends BiBaseEvent {
  errorCode: string;
  error_desc: string;
}

export interface BiInstalledEvent extends BiBaseEvent {
  isProvision?: boolean;
}

const startDurations: Record<string, number> = {};

const getAppType = (editorAPI: EditorAPI, appDefinitionId: string) => {
  const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
  return appData ? appMarketUtils.getAppType(appData) : null;
};

const getOriginInfo = (origin: PlatformOrigin): string => {
  if (isPlatformOriginEditor(origin)) {
    return origin?.info?.type;
  }
  if (isPlatformOriginAPP(origin)) {
    return origin?.info?.appDefinitionId;
  }
  return JSON.stringify(origin);
};

const reportBIOnPlatformTryToInstall = (
  editorAPI: EditorAPI,
  eventData: BiBaseEvent,
) => {
  const { origin, ...eventDataWithoutOrigin } = eventData;
  startDurations[eventData.app_id] = new Date().getTime();
  editorAPI.bi.event(TPAbi.events.PLATFORM_APP_TRY_TO_INSTALL, {
    ...eventDataWithoutOrigin,
    origin: getOriginInfo(origin),
  });
};

const reportBIOnPlatformAppAdded = (
  editorAPI: EditorAPI,
  eventData: BiInstalledEvent,
) => {
  const duration = startDurations[eventData.app_id]
    ? new Date().getTime() - startDurations[eventData.app_id]
    : null;
  const { origin, ...eventDataWithoutOrigin } = eventData;
  editorAPI.bi.event(TPAbi.events.PLATFORM_APP_ADDED, {
    ...eventDataWithoutOrigin,
    ...(duration ? { duration } : {}),
    app_type: getAppType(editorAPI, eventData.app_id),
    origin: getOriginInfo(origin),
  });
  if (duration) {
    delete startDurations[eventData.app_id];
  }
};

const reportBIOnPlatformAppError = (
  editorAPI: EditorAPI,
  eventData: BiErrorEvent,
) => {
  const { origin, ...eventDataWithoutOrigin } = eventData;
  editorAPI.bi.event(TPAbi.events.PLATFORM_APP_FAILED, {
    ...eventDataWithoutOrigin,
    app_type: getAppType(editorAPI, eventData.app_id),
    origin: getOriginInfo(origin),
  });
  if (startDurations[eventData.app_id]) {
    delete startDurations[eventData.app_id];
  }
};

export {
  getOriginInfo,
  reportBIOnPlatformTryToInstall,
  reportBIOnPlatformAppAdded,
  reportBIOnPlatformAppError,
};
