import { notifications } from '@/stateManagement';
import type { AnimationsScope } from '../scope';
import type { AnimationKitDefinition } from '../api/types';
import { applySaveAndPreviewAnimations, getSectionsToAnimate } from './utils';

const { showUserActionNotification } = notifications.actions;

export const mapStateToProps = ({
  addPresetApi,
  globalAnimationsApi,
}: AnimationsScope) => {
  return {
    animationKits: addPresetApi.getAnimationKits(),
    siteAnimationKit: globalAnimationsApi.getSiteAnimationKit(),
  };
};

export const mapDispatchToProps = ({
  editorAPI,
  globalAnimationsApi,
}: AnimationsScope) => {
  const { dispatch } = editorAPI.store;
  const { focusedFirstSection, focusedRestSections, header, footer } =
    getSectionsToAnimate(editorAPI);

  return {
    applyAnimations: async (animationKit: AnimationKitDefinition) => {
      const { title } = animationKit;

      try {
        await applySaveAndPreviewAnimations(
          editorAPI,
          globalAnimationsApi,
          animationKit,
        );

        // showUserActionNotification used here for POC
        dispatch(
          showUserActionNotification({
            type: 'success',
            message: `Animation kit ${title} applied`,
            shouldTranslate: false,
          }),
        );
      } catch (error: MaybeError) {
        // showUserActionNotification used here for POC
        dispatch(
          showUserActionNotification({
            type: 'error',
            message: `Animation kit ${title} failed`,
            shouldTranslate: false,
          }),
        );
      }
    },
    startPreview: (animationKit: AnimationKitDefinition) => {
      globalAnimationsApi.startPreview(
        [header, footer],
        animationKit.headerFooterKit,
      );
      globalAnimationsApi.startPreview(
        [focusedFirstSection],
        animationKit.firstSectionKit,
      );
      globalAnimationsApi.startPreview(focusedRestSections, animationKit.title);
    },
    stopPreview: () => {
      globalAnimationsApi.stopPreview([
        header,
        focusedFirstSection,
        ...focusedRestSections,
        footer,
      ]);
    },
  };
};

export type MapStateToPropsType = ReturnType<typeof mapStateToProps>;
export type MapDispatchToPropsType = ReturnType<typeof mapDispatchToProps>;
