export const BI_EVENTS_IDS = {
  OPEN_APP_MARKET: 164,
  APP_ACTION_CLICKED: 1087,
  APP_LIST_ACTION_CLICKED: 1088,
  PANEL_LOADED: 1098,
  TAB_CHANGED: 1086,
  PANEL_ERROR: 1120,
  SETUP_STEP_LOADED: 1289,
  SETUP_STEP_ACTION_CLICKED: 1285,
  SETUP_STEP_COMPLETED: 1286,
  SETUP_COMPLETED: 1287,
  RECOMMENDED_TOOL_CLICKED: 1288,
} as const;
