import React from 'react';
import { BasePublicApi } from '@/apilib';
import {
  TextEditorLocation,
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
} from '@/constants';
import { highlights } from '@/stateManagement';
import {
  QUICK_EDIT_PANEL_WIDTH,
  drillInActionMap,
  FULL_HEIGHT_INNER_PANELS,
} from './consts';
import { translate } from '@/i18n';
import { sections, keyboardShortcuts } from '@/util';
import { getContextMenuConfig } from './quickEditContextMenuConfig';
import {
  getFilteredChildren,
  getQuickEditDrillInPanelConfig,
  getComponentControlWrapperProps,
  getTitleWithLanguage,
} from './quickEditUtils';
import {
  isNotTransparent,
  isComponentInInteractionMode,
  QuickEditCompPanelWrapper,
  QuickEditDrillInPanelWrapper,
} from '@/quickEditEngine';
import {
  reportPanelClose,
  reportPanelOpen,
  reportSecondaryPanelClose,
  reportSecondaryPanelOpen,
  setInitialBiData,
  setInnerPanelBiData,
} from './quickEditBi';
import type { QuickEditScope } from './quickEditEntryPoint';
import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';
import type { PanelOpenConfig } from '@/workspaceRightPanel';
import type { UIResourceRestriction } from '@/editorRestrictions';
import type { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';

import experiment from 'experiment';

const isQuickEditExperimentOpen = experiment.isOpen(
  'se_openQuickEditWhenSecondaryLanguageSelected',
);

export interface QuickEditConfig {
  rootControlCompRef?: CompRef;
  origin: string;
  zoomScale?: number;
  onOpen?: () => void;
  onClose?: () => void;
}

const panelName = WORKSPACE_RIGHT_PANEL_NAMES.QUICK_EDIT;

const selectSection = (editorAPI: EditorAPI, sectionRef: CompRef) => {
  const [selectedRef] = editorAPI.selection.getSelectedComponents();
  if (!sectionRef || !selectedRef) {
    return;
  }
  const isSameRefAsSelected = editorAPI.utils.isSameRef(
    sectionRef,
    selectedRef,
  );
  if (isSameRefAsSelected) {
    return;
  }
  editorAPI.selection.selectComponentByCompRef(sectionRef);
};

export const openQuickEditPanel = (
  scope: QuickEditScope,
  { rootControlCompRef, origin, zoomScale, onOpen, onClose }: QuickEditConfig,
  groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
) => {
  const {
    editorAPI,
    editorRestrictionsApi,
    sectionsAPI,
    store,
    workspaceRightPanelApi,
    workspaceModesApi,
  } = scope;
  rootControlCompRef =
    rootControlCompRef ||
    sectionsAPI.getFocusedSection() ||
    sectionsAPI.getHoveredSection();
  workspaceModesApi.setSelectionInterceptorEnabled(false);
  editorAPI.panelManager.closeAllPanels();
  selectSection(editorAPI, rootControlCompRef);
  setInitialBiData(store, origin, rootControlCompRef);
  reportPanelOpen(panelName, origin, store);
  const isAllowed = (uxResource: UIResourceRestriction) =>
    editorRestrictionsApi.allowed(uxResource);

  if (!groupType) {
    groupType =
      isQuickEditExperimentOpen &&
      editorAPI.language.isCurrentLanguageSecondary()
        ? WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION_MULTILINGUAL
        : WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION;
  }

  const title =
    isQuickEditExperimentOpen && editorAPI.language.isCurrentLanguageSecondary()
      ? getTitleWithLanguage(editorAPI)
      : translate('edit_section_panel_header');

  const quickEditOpenPanelConfig: PanelOpenConfig = {
    panelName,
    panelWidth: QUICK_EDIT_PANEL_WIDTH,
    title,
    onClose: (closeOrigin: string) => {
      workspaceModesApi.setSelectionInterceptorEnabled();
      keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.EDITOR);
      editorAPI.text.setCurrentTextEditor(null, TextEditorLocation.QUICKEDIT);
      store.setIsQuickEditPanelOpen(false);
      store.setIsQuickEditLoading(false);
      store.setCurrentEditedCompId(null);
      reportPanelClose(panelName, rootControlCompRef?.id, closeOrigin, store);
      store.setQuickEditBiData(null);

      if (onClose) {
        onClose();
      }
    },
    groupType,
    contextMenuConfig: getContextMenuConfig(scope),
    drillInUniqueIds: [] as Array<string>,
    zoomModeConfig: {
      enableCompPanels: true,
      enableRightClickMenu: false,
      zoomScale,
    },
  };
  const compPanelDrillIn = {
    panelConfig: {
      panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_COMP_PANEL,
      panelTitle: '',
      onClose: () => {
        store.setCurrentEditedCompId(null);
        editorAPI.store.dispatch(highlights.actions.clearHighlights());
        reportSecondaryPanelClose(store);
      },
      backTooltip: translate('edit_section_back_to_content_tooltip'),
    },
    panelComponent: () =>
      React.createElement(QuickEditCompPanelWrapper, {
        getDrillInAction: (compId) => {
          const compRef = editorAPI.components.get.byId(compId);
          const compType = editorAPI.components.getType(compRef);
          const drillInAction = !isAllowed('quick-edit_drillin-action.visible')
            ? null
            : drillInActionMap[compType];
          return drillInAction
            ? React.createElement(drillInActionMap[compType], {
                compId,
              })
            : null;
        },
        isCompInInteractionMode: (compRef: CompRef) =>
          isComponentInInteractionMode(editorAPI, compRef),
        getPanelStyle: (panelType: QuickEditControlActions) => {
          if (FULL_HEIGHT_INNER_PANELS.has(panelType)) {
            return {
              height: '100%',
            };
          }
          return {};
        },
        onCompPanelLoaded: (
          compId: string,
          panelName: string,
          compType: string,
        ) => {
          setInnerPanelBiData(store, compId, panelName, compType);
          reportSecondaryPanelOpen(store, compId, panelName, compType);
        },
      }),
  };

  const quickEditDrillIn = {
    panelConfig: getQuickEditDrillInPanelConfig(scope, rootControlCompRef),
    panelComponent: () =>
      React.createElement(QuickEditDrillInPanelWrapper, {
        drillInPanelIndex: 1,
        getDrillInPanelData: (drillInPanelIndex: number) =>
          store.getDrillInPanels()[drillInPanelIndex],
        onDrillInPanelLoaded: (
          compId: string,
          panelName: string,
          compType: string,
        ) => {
          reportSecondaryPanelOpen(store, compId, panelName, compType);
          store.setDrillInPanelBiData({
            openTime: performance.now(),
            component_id: compId,
            panel_name: panelName,
            component_type: compType,
          });
        },
        ...getComponentControlWrapperProps(scope),
      }),
  };

  const drillInPanelsData = workspaceRightPanelApi.open(
    'quickEdit',
    quickEditOpenPanelConfig,
    [compPanelDrillIn, quickEditDrillIn],
  );
  store.setDrillInPanels(
    drillInPanelsData.map((data) => ({ panelData: data })),
  );

  keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.QUICK_EDIT);
  store.setIsQuickEditPanelOpen(true);

  if (onOpen) {
    onOpen();
  }
};

const updateLanguageInTitle = (scope: QuickEditScope, languageCode: string) => {
  const { editorAPI, workspaceRightPanelApi } = scope;
  const title = getTitleWithLanguage(editorAPI, languageCode);

  workspaceRightPanelApi.updateActivePanelTitle(title);
};

export const isQuickEditPanelOpen = (scope: QuickEditScope) => {
  return scope.store.getIsQuickEditPanelOpen();
};

export const isQuickEditAvailable = ({
  editorAPI,
}: QuickEditScope): boolean => {
  return sections.isSectionsEnabled() && !editorAPI.isMobileEditor();
};

export const shouldEnableQuickEdit = (
  { editorAPI }: QuickEditScope,
  compRef: CompRef,
) => {
  if (!compRef) return false;
  const compType = editorAPI.components.getType(compRef);
  const hasComps = getFilteredChildren(editorAPI, compRef, true).length > 0;
  return isNotTransparent(editorAPI, compRef, compType) || hasComps;
};

export class QuickEditApi extends BasePublicApi<QuickEditScope> {
  openPanel = this.bindScope(openQuickEditPanel);
  isQuickEditPanelOpen = this.bindScope(isQuickEditPanelOpen);
  isQuickEditAvailable = this.bindScope(isQuickEditAvailable);
  updateLanguageInTitle = this.bindScope(updateLanguageInTitle);
}
