import { sections } from '@/util';
import { EditorPermissionsApiKey } from './publicApiKey';

import type { EntryPoint } from '@/apilib';

export interface EditorPermissions {
  has(permissionName: string): boolean;
}

export function createEditorPermissionsEntryPoint(
  editorPermissions: EditorPermissions,
): EntryPoint {
  return {
    name: 'EditorPermissions',
    declareAPIs: () => [EditorPermissionsApiKey],
    attach: (shell) => {
      shell.contributeAPI(EditorPermissionsApiKey, () => {
        return {
          has: (permissionName: string) => {
            if (!sections.isSectionsEnabled()) {
              // Product definition - enforce restrictions only for editor 2
              return true;
            }
            return editorPermissions.has(permissionName);
          },
        };
      });
    },
  };
}
