import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import { getPostRegistrationSubCategory } from '../sitePrefsService';
import type {
  IPageDescription,
  ISectionBrief,
  ListContentItem,
  CeType,
  ContentManager,
} from '@/presetApi';
import { adapter } from '@/presetApi';
import { BookingsServiceType } from '../api/bookings-types';

async function initContentManager(editorAPI: EditorAPI) {
  const { ContentManager } = await adapter();
  const contentManager = new ContentManager({
    origin: 'BOOKINGS-INSTALLATION',
    locale: 'en',
  });
  const topic = getPostRegistrationSubCategory(editorAPI);
  const categories = await contentManager.suggest(topic);
  const selectedCategory = categories.results[0];
  await contentManager.initContent(
    selectedCategory.industryId,
    selectedCategory.structureId,
    {},
  );
  return contentManager;
}

function getSyncedSection(pages: IPageDescription[]): ISectionBrief {
  return _(pages)
    .flatMap((page) => [...page.sections])
    .find(
      (section) =>
        section.syncWithBooking && section.syncWithBooking !== 'none',
    );
}

export async function getDynamicContentForServices(
  editorAPI: EditorAPI,
  contentManager: ContentManager = null,
): Promise<{
  servicesContent: ListContentItem;
  servicesType: BookingsServiceType;
}> {
  if (!contentManager) {
    contentManager = await initContentManager(editorAPI);
  }
  const section = getSection(contentManager);
  const servicesContent = getServiceContent(contentManager, section);
  const mapSyncWithBookingsToServiceType: AnyFixMe = {
    single: BookingsServiceType.INDIVIDUAL,
    group: BookingsServiceType.GROUP,
    course: BookingsServiceType.COURSE,
  };
  const servicesType =
    mapSyncWithBookingsToServiceType[section.syncWithBooking];
  return { servicesContent, servicesType };
}

export const getSection = (contentManager: ContentManager): ISectionBrief => {
  const pages = contentManager.getPages();
  return getSyncedSection(pages);
};

export const getHomeSectionByCeType = (
  contentManager: ContentManager,
  ceType: CeType,
): ISectionBrief => {
  const homePage = contentManager.getPages()[0];
  return homePage?.sections?.find((section) => section.ceType === ceType);
};

export const getServiceContent = (
  contentManager: ContentManager,
  section: ISectionBrief,
) => contentManager.generateListContentItems(section, 1)[0];
