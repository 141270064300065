import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import * as util from '@/util';
import ascendBi from '../common/bi/ascendBi';

import superApp from '../../superApp';
import * as stateManagement from '@/stateManagement';

interface AscendMenuPanelProps {
  superApp: any;
  isTopPremium: boolean;
  sendBi: any;
  biBaseParams: any;
  isFetchSucceed: boolean;
  removeAscendFeatures: boolean;
}

class AscendMenuPanel extends React.Component<AscendMenuPanelProps> {
  componentDidMount(): void {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.ASCEND_PANEL_VIEW, biBaseParams);
  }

  getSuperApp() {
    const { isTopPremium, isFetchSucceed, removeAscendFeatures } = this.props;
    const showUpgradeTab = isFetchSucceed && !isTopPremium;

    return superApp.getAscendSuperApp(showUpgradeTab, removeAscendFeatures);
  }

  render() {
    return (
      <superAppMenuInfra.superAppPanel
        panelClass="ascend-panel"
        superApp={this.getSuperApp()}
      />
    );
  }
}

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const removeAscendFeatures =
    stateManagement.ascend.selectors.getRemoveAscendFeatures(state);
  const isTopPremium = stateManagement.ascend.selectors.getIsTopPremium(state);
  const isFetchSucceed = stateManagement.ascend.selectors.isFetchSucceed(state);
  const biBaseParams = ascendBi.biUtils.getBiBaseParams({ editorAPI, state });

  return {
    removeAscendFeatures,
    isTopPremium,
    biBaseParams,
    isFetchSucceed,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AscendMenuPanel);
ConnectedComponent.pure = AscendMenuPanel;

export default ConnectedComponent;
