// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['add_section_label_rich_text_box'],
      sections: [addPanel.sections.getSections().developer.richTextBoxSection],
    };
  },
};
