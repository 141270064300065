import { ToursApiKey } from './api';
import { createHelpTour } from './tours/helpTour.conf';

import type { EditorAPI } from '@/editorAPI';

export const startHelpTour = async (editorAPI: EditorAPI) => {
  const tourApi = editorAPI.host.getAPI(ToursApiKey);
  const helpTour = tourApi.createTour(createHelpTour);

  return await tourApi.startTour(helpTour);
};
