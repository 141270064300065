import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { cx } from '@/util';
import constants from '@/constants';
import { TextLabel } from '@wix/wix-base-ui';
import { PresetPreviewComponent } from '@/presetApi';
import {
  disablePageScroll,
  disableScrollTransition,
  enablePageScroll,
  getCurrentTranslatePosition,
  getThumbnailWidth,
  resetScrollTransition,
  setScrollTransitionDuration,
} from './siteCreationUtils';
import styles from './siteCreationAdditionalPagesThumbnail.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TRANSITION_DURATION_MULTIPLIER } from './siteCreationConstants';

import type { PageAlternative } from '@/presetApi';
import type { SiteCreationProps } from './siteCreationPagesView';

const { SITE_CREATION: SITE_CREATION_CONSTS } = constants;

interface SiteCreationAdditionalPagesThumbnailProps {
  id: string;
  name: string;
  isSelected: boolean;
  presetIndex: number;
  onPageClick: (id: string) => void;
  scale: number;
  page: PageAlternative;
  fireContentScrolled: SiteCreationProps['fireContentScrolled'];
}

const SiteCreationAdditionalPagesThumbnail: React.FC<SiteCreationAdditionalPagesThumbnailProps> =
  React.memo(
    ({
      id,
      name,
      isSelected,
      presetIndex,
      onPageClick,
      scale,
      page,
      fireContentScrolled,
    }) => {
      const debouncedScrollEvent = useMemo(
        () =>
          _.debounce(
            () =>
              fireContentScrolled(
                SITE_CREATION_CONSTS.SCREENS.PAGES,
                presetIndex,
                'manual',
              ),
            300,
          ),
        [fireContentScrolled, presetIndex],
      );
      const thumbnailRef = useRef<HTMLDivElement>();
      const scrollBarRef = useRef<HTMLDivElement>();
      const [overflowHeight, setOverflowHeight] = useState<number>(0);
      const [scrollBarHeight, setScrollBarHeight] =
        useState<React.CSSProperties['height']>(0);

      const transformElement = (position: number) => {
        const thumbFrame = thumbnailRef.current
          .firstElementChild as HTMLDivElement;
        thumbFrame.style.transform = `translateY(-${position}px)`;

        const scrollPosition =
          (position * thumbnailRef.current.clientHeight) /
            thumbFrame.scrollHeight || 0;
        scrollBarRef.current.style.top = `${scrollPosition}px`;
      };

      const onIntentScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (!overflowHeight) return;
        debouncedScrollEvent();

        const currentPosition = getCurrentTranslatePosition(
          thumbnailRef.current.firstElementChild as HTMLDivElement,
        );

        const newPosition = Math.max(currentPosition + event.deltaY, 0);
        const translateTo =
          newPosition > overflowHeight ? overflowHeight : newPosition;

        disableScrollTransition(thumbnailRef, scrollBarRef);
        transformElement(translateTo);
      };

      const afterPresetRender = () => {
        const { scrollHeight, clientHeight: thumbnailHeight } =
          thumbnailRef.current;

        if (scrollHeight <= thumbnailHeight) return;

        setScrollBarHeight(`${(thumbnailHeight / scrollHeight) * 100}%`);

        const overflowHeight = scrollHeight - thumbnailHeight;
        setOverflowHeight(overflowHeight > 0 ? overflowHeight : 0);
      };

      const startAutoScroll = (): void => {
        if (!overflowHeight) return;
        fireContentScrolled(
          SITE_CREATION_CONSTS.SCREENS.PAGES,
          presetIndex,
          'autoscroll',
        );
        const { scrollHeight, clientHeight } = thumbnailRef.current;
        const transitionDuration =
          (scrollHeight / clientHeight) * TRANSITION_DURATION_MULTIPLIER;
        disablePageScroll();
        setScrollTransitionDuration(
          thumbnailRef,
          scrollBarRef,
          transitionDuration,
        );
        transformElement(scrollHeight - clientHeight);
      };

      const resetScroll = (): void => {
        enablePageScroll();
        resetScrollTransition(thumbnailRef, scrollBarRef);
        transformElement(0);
      };

      return (
        <div
          onMouseEnter={startAutoScroll}
          onMouseLeave={resetScroll}
          key={id}
          className={cx(styles.additionalPageThumbnailWrapper, {
            selected: isSelected,
          })}
          onClick={() => onPageClick(id)}
          data-hook="additional-pages-thumbnail"
        >
          <div className="additional-pages-thumbnail-header">
            <TextLabel
              value={name}
              shouldTranslate={false}
              className={styles.additionalPageThumbnailText}
            />
            {isSelected ? (
              <Symbol name="SignVBlue" className={styles.pagesSelectedV} />
            ) : (
              <span className="additional-page-radio-button" />
            )}
          </div>
          <div
            onWheel={onIntentScroll}
            className={cx(styles.additionalPageThumbnail, {
              'additional-page-thumbnail': true,
              selected: isSelected,
            })}
            style={{ width: getThumbnailWidth(scale) }}
            ref={thumbnailRef}
          >
            <PresetPreviewComponent
              scale={scale}
              // @ts-expect-error
              presetPreview={page.presetPreviews}
              onAfterRender={afterPresetRender}
              kit={page.kit}
            />
            <div
              ref={scrollBarRef}
              className={styles.fpdScrollbar}
              style={{
                top: 0,
                height: scrollBarHeight,
              }}
            />
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return prevProps.isSelected === nextProps.isSelected;
    },
  );

SiteCreationAdditionalPagesThumbnail.displayName =
  'SiteCreationAdditionalPagesThumbnail';

export default SiteCreationAdditionalPagesThumbnail;
