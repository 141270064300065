import { fedopsLogger, media } from '@/util';
import { SectionType, type AnySection } from '@wix/add-panel-common';
import React, { useEffect, useRef } from 'react';

export const withTrackFirstPresetSectionLoad = (WrappedComponent: AnyFixMe) => {
  const WithTrackFirstPresetSectionLoad = React.forwardRef<
    HTMLDivElement,
    { items: AnySection[] }
  >((props, ref) => {
    const didTrack = useRef(false);
    const { getMediaUrl } = media;
    const sections = props.items;
    const firstPresetSection = sections.find(
      ({ type }) => type === SectionType.Preset,
    );
    const imageSrc =
      firstPresetSection && getMediaUrl(firstPresetSection.props.image);

    useEffect(
      () => {
        if (!imageSrc) {
          return;
        }

        const imageObject = new Image();
        imageObject.src = imageSrc;
        imageObject.onload = () => {
          if (didTrack.current === false) {
            didTrack.current = true;
            fedopsLogger.interactionEnded(
              fedopsLogger.INTERACTIONS.ADD_PANEL
                .OLD_ADD_PANEL_FIRST_PRESET_SECTION_LOAD,
            );
          }
        };
      }, // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [imageSrc, fedopsLogger],
    );

    return <WrappedComponent {...props} ref={ref} />;
  });

  WithTrackFirstPresetSectionLoad.displayName =
    'WithTrackFirstPresetSectionLoad';

  return WithTrackFirstPresetSectionLoad;
};
