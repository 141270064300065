module.exports = {
  id: "Stylable_StylableButton_12",
  preset: { rect: { width: 64, height: 78, x: 194, y: 296 }, tags: null },
  structure: {
    type: "Component",
    metaData: { sig: "inq-437", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
    layout: {
      width: 47,
      height: 47,
      x: 202,
      y: 311,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wixui.StylableButton",
    parent: "comp-knzvqsjj",
    data: {
      type: "StylableButton",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "q4x-265",
        pageId: "c1dmp",
      },
      label: "Mail",
      svgId: "11062b_d97586817ce64d2d83900ce8d82ea569.svg",
    },
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-221", pageId: "c1dmp" },
    },
    style: {
      type: "ComponentStyle",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "4ie-593",
        pageId: "c1dmp",
      },
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 200px;background: #97BCC8;border: 0px solid rgb(151, 188, 200)}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;width: 31px;height: 31px;fill: #074151}.root:hover::icon {\n    width: 29px;\n    height: 29px;\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      componentClassName: "wixui.StylableButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    id: "comp-km97dvac",
    components: [],
  },
};
