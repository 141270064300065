import React from 'react';
import { ContextMenu, ContextMenuAction, IconButton } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { BusinessAppHeaderLayout } from '../BusinessAppHeaderLayout/BusinessAppHeaderLayout';
import { AppIcon } from '../AppIcon/AppIcon';
import { AUTOMATION_IDS } from '../../automationIds';

import type { AppAction } from '../../services/platform/platform.types';

export interface BusinessAppHeaderProps {
  title: string;
  description: string;
  imageSrc: string;
  generalAppActions: AppAction[];
  onContextMenuOpen: () => void;
  onContextMenuActionClick: (action: AppAction) => void;
}

const getActionSymbol = (action: any) => {
  if (action?.symbol) {
    return <action.symbol className="symbol" />;
  }
  if (action?.symbolName) {
    return <Symbol name={action.symbolName} />;
  }
  return null;
};
export const BusinessAppHeader: React.FC<BusinessAppHeaderProps> = ({
  title,
  description,
  imageSrc,
  generalAppActions,
  onContextMenuOpen,
  onContextMenuActionClick,
}) => {
  return (
    <BusinessAppHeaderLayout
      illustration={<AppIcon src={imageSrc} alt={title} />}
      title={title}
      subtitle={description}
      dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_HEADER}
      action={
        <ContextMenu
          alignment="RIGHT"
          direction="BOTTOM"
          customButton={
            <IconButton skin="standard" priority="inverted">
              <Symbol
                name="freeIcon_3dots"
                style={{
                  width: '30px',
                  height: '30px',
                }}
              />
            </IconButton>
          }
          onToggle={(isOpen: boolean) => isOpen && onContextMenuOpen()}
        >
          {generalAppActions.map((action) => {
            return (
              <ContextMenuAction
                key={action.id}
                onClick={() => onContextMenuActionClick(action)}
              >
                {getActionSymbol(action)}
                {action.title}
              </ContextMenuAction>
            );
          })}
        </ContextMenu>
      }
    />
  );
};
