import {
  type FormData,
  ScanSwitchLayoutValuesCategories,
  type TextData,
} from './scanSwitchLayoutPresetTypes';

export function scanSwitchLayoutValuesReducer(
  state: FormData,
  action: Record<string, any>,
) {
  const { name, value, category, rule, idx } = action;
  const {
    INITIAL,
    UPDATE_ID,
    UPDATE_GROUP_LAYOUT,
    UPDATE_RULE,
    ADD_RULE,
    REMOVE_RULE,
    SET_DATA,
  } = ScanSwitchLayoutValuesCategories;
  switch (category) {
    case INITIAL:
      return {
        ...action.value,
      };
    case UPDATE_ID:
      return {
        ...state,
        id: action.value,
      };
    case UPDATE_GROUP_LAYOUT:
      return {
        ...state,
        groupLayout: action.value,
      };
    case UPDATE_RULE:
      const ruleToUpdate = [...state[rule as keyof typeof state]];
      ruleToUpdate[idx] = { ...(ruleToUpdate[idx] as any), [name]: value };
      return {
        ...state,
        [rule]: ruleToUpdate,
      };
    case ADD_RULE:
      const ruleToAdd = [...state[rule as keyof typeof state], value];
      return {
        ...state,
        [rule]: ruleToAdd,
      };
    case REMOVE_RULE:
      const ruleToRemove = (
        state[rule as keyof typeof state] as Array<TextData | ImageData>
      ).filter((_: any, i: number) => i !== idx);
      return {
        ...state,
        [rule]: ruleToRemove,
      };
    case SET_DATA:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
}
