import { composeProtoInput } from '@wix/editor-prediction-utils';

export default function getSerializedProtoData(
  data: AnyFixMe,
  additionalData: AnyFixMe,
): any {
  return {
    values: {
      SectionData: composeProtoInput(data),
      ...additionalData,
    },
  };
}
