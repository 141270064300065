import React, { type ChangeEvent } from 'react';
import { ScanSwitchLayoutValuesCategories } from './scanSwitchLayoutPresetTypes';
import styles from './scanSwitchLayoutPreset.scss';
import { aspectRatioValues } from './scanSwitchLayoutPresetConsts';

interface SectionScanTextFormProps {
  category?: string;
  rule?: string;
  handleChange: (
    category: string,
    formIdx: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleCloseMessage: () => void;
  handleCleanInput: (
    category: string,
    formIdx: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  formState: AnyFixMe;
  formKeys: string[];
  ruleCountIdx?: number;
  ruleCount?: number;
}

const { IMAGE_DATA } = ScanSwitchLayoutValuesCategories;

export const ScanSwitchLayoutPresetForm = ({
  rule,
  handleChange,
  handleCleanInput,
  handleCloseMessage,
  formState,
  formKeys,
  ruleCountIdx,
  ruleCount,
}: SectionScanTextFormProps) => {
  return (
    <div className={styles.scanSwitchLayoutPresetTextForm}>
      {formKeys.map((key) => {
        if (key === 'nickname' && ruleCount < 2) {
          return null;
        }
        if (key === 'aspectRatio') {
          return (
            <div
              className={styles.scanSwitchLayoutPresetInputContainer}
              key={key + ruleCountIdx}
            >
              <p>{key}</p>
              <select
                name={key}
                className={styles.scanSwitchLayoutPresetInput}
                value={formState[key]}
                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                  handleChange(rule, ruleCountIdx, event)
                }
                onFocus={handleCloseMessage}
              >
                {aspectRatioValues.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          );
        }
        return (
          <div
            className={styles.scanSwitchLayoutPresetInputContainer}
            key={key + ruleCountIdx}
          >
            <p>{key}</p>
            <input
              name={key}
              value={formState[key] || ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(rule, ruleCountIdx, event)
              }
              onFocus={handleCloseMessage}
              onBlur={(event: ChangeEvent<HTMLInputElement>) =>
                handleCleanInput(rule, ruleCountIdx, event)
              }
              style={{ width: '100%' }}
              autoComplete="off"
              className={styles.scanSwitchLayoutPresetInput}
            />
          </div>
        );
      })}
      {rule === IMAGE_DATA && (
        <div className={styles.scanSwitchLayoutPresetCheckbox}>
          <input
            key="isImageComponent"
            type="checkbox"
            value={formState.isImageComponent}
            checked={formState.isImageComponent}
            id="isImageComponent"
            name="isImageComponent"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleChange(rule, ruleCountIdx, event)
            }
            onFocus={handleCloseMessage}
          />
          <label htmlFor="isImageComponent">isImageComponent</label>
        </div>
      )}
    </div>
  );
};
