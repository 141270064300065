import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import experiment from 'experiment';

import * as util from '@/util';

import { PAGE_CATEGORIES as pageCategories } from '../../utils';

import PromoDescription from './promoDescription';
import GenericDescription from './genericDescription';
import { pagesAddPagesSelectionClick } from '@wix/bi-logger-editor/v2';

interface DynamicPagesIntroViewProps {
  title: string;
  hasDynamicPages: boolean;
  onClose: () => void;
  onHelp: (articleId: string) => void;
  addDynamicPage: () => void;
  openNewDynamicPagePanel: () => void;
  hasUserCollections: boolean;
}

class DynamicPagesIntroView extends React.Component<DynamicPagesIntroViewProps> {
  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  openHelpPanel = () =>
    this.props.onHelp(pageCategories.DYNAMIC_PAGES_INTRO.helpId);

  getBannerScr() {
    if (this.isNewWorkspace) {
      return util.browserUtil.isRetina()
        ? 'addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection@x2.png'
        : 'addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection.png';
    }

    return 'panels/pages/add_dynamic_pages_intro.png';
  }

  renderPanelHeader() {
    return (
      <PanelHeader
        onClose={this.props.onClose}
        onHelp={this.openHelpPanel}
        className="header light"
      >
        <span className="title-pages-view-pp">{this.props.title}</span>
      </PanelHeader>
    );
  }

  getDescription() {
    const {
      hasDynamicPages,
      addDynamicPage,
      hasUserCollections,
      openNewDynamicPagePanel,
    } = this.props;

    const newDynamicPageFlowEnabled = experiment.isOpen(
      'specs.wixDataClient.NewDynamicPageFlow',
    );

    const onMainActionClick = () => {
      util.biLogger.report(
        pagesAddPagesSelectionClick({
          origin: 'dynamic_page_intro',
          category: 'add_dynamic_page',
          source: 'dataBinding',
        }),
      );
      openNewDynamicPagePanel();
    };

    const onThirdActionClick = () => {
      util.biLogger.report(
        pagesAddPagesSelectionClick({
          origin: 'dynamic_page_intro',
          category: 'add_preset',
          source: 'dataBinding',
        }),
      );

      addDynamicPage();
    };

    if (hasDynamicPages) {
      return <GenericDescription onMainActionClick={addDynamicPage} />;
    }

    if (newDynamicPageFlowEnabled && hasUserCollections) {
      return (
        <PromoDescription
          mainActionLabel="NewPages_Panel_Add_Dynamic_Page_Primary_ButtonCM"
          thirdActionLabel="NewPages_Panel_Add_Dynamic_Page_Secondary_ButtonCM"
          onMainActionClick={onMainActionClick}
          onSecondaryActionClick={this.openHelpPanel}
          onThirdActionClick={onThirdActionClick}
          showIconsOnButtons
        />
      );
    }

    return (
      <PromoDescription
        mainActionLabel="NewPages_Panel_Add_Dynamic_Page_ButtonCM"
        onMainActionClick={addDynamicPage}
        onSecondaryActionClick={this.openHelpPanel}
      />
    );
  }

  render() {
    const bannerSrc = this.getBannerScr();

    return (
      <div className="dynamic-pages-intro-view-pp">
        {!this.isNewWorkspace && this.renderPanelHeader()}
        <img src={util.media.getMediaUrl(bannerSrc)} className="banner" />
        {this.getDescription()}
      </div>
    );
  }
}

export default DynamicPagesIntroView;
