'use strict'

const _ = require('lodash')

const PREFERRED_SIZE = 640
const MIN_SIZE = 240

const getSizeByWidth = (width, aspect) => ({width, height: width / aspect})

const getSizeByHeight = (height, aspect) => ({width: height * aspect, height})



function getMediaProportionSize(aspectRatio) {
    let layout
    const landscape = aspectRatio > 1
    const isAspectInRange = aspectRatio < PREFERRED_SIZE / MIN_SIZE && aspectRatio > MIN_SIZE / PREFERRED_SIZE
    if (isAspectInRange) { //eslint-disable-line wix-editor/prefer-ternary
        layout = landscape ? getSizeByWidth(PREFERRED_SIZE, aspectRatio) : getSizeByHeight(PREFERRED_SIZE, aspectRatio)
    } else {
        layout = landscape ? getSizeByHeight(MIN_SIZE, aspectRatio) : getSizeByWidth(MIN_SIZE, aspectRatio)
    }
    return layout
}


function getAspectRatio(videoData) {
    const quality = _.head(videoData.qualities)
    return quality.width / quality.height
}


function getMediaPlayerContainerStructure(editorAPI, videoData, videoSize) {
    const structure = _.omit(editorAPI.components.buildDefaultComponentStructure('wysiwyg.viewer.components.MediaPlayer'), 'data')
    const hasAlpha = _.includes(videoData.mediaFeatures, 'alpha')
    structure.design.background = {
        type: 'BackgroundMedia',
        mediaRef: videoData,
        alignType: 'center',
        fittingType: 'fill',
        imageOverlay: null,
        colorOverlay: '',
        colorOverlayOpacity: 0
    }
    structure.layout = {
        width: videoSize.width,
        height: videoSize.height
    }
    structure.skin = 'wysiwyg.viewer.skins.mediaPlayerSkin'
    structure.style = 'mp1'
    structure.type = 'Container'
    _.assign(structure.props, {
        autoplay: true,
        loop: true,
        mute: true,
        disableAudio: false,
        playerInteraction: {
            click: hasAlpha ? 'none' : 'toggle',
            rollIn: 'none',
            rollOut: 'none'
        }
    })
    return structure
}

function getMediaPlayerOverlayControlsStructure(editorAPI, overlaySize, videoSize) {
    const overlay = _.omit(editorAPI.components.buildDefaultComponentStructure('wysiwyg.viewer.components.MediaOverlayControls'), 'data')
    overlay.layout = {
        width: overlaySize,
        height: overlaySize,
        x: (videoSize.width - overlaySize) / 2,
        y: (videoSize.height - overlaySize) / 2
    }
    overlay.design = {
        type: 'MediaControlsDesignData',
        metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
        iconsDefaultDesign: {
            type: 'VectorImageDesignData',
            metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
            shapeStyle: {
                opacity: 0.8,
                strokeOpacity: 1,
                stroke: '#000000',
                enableStroke: false,
                strokeWidth: 1
            },
            overrideColors: {
                color1: '#000000'
            }
        },
        icons: [
            {
                type: 'ControlIconDesignData',
                metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                name: 'playButton',
                svgId: '0da768_661bc0cafffa432db3753ad5d17e4f10.svg' //icon #05, 1 color play in circle
            }
        ]
    }
    overlay.skin = 'skins.viewer.mediaOverlayControlsDefaultSkin'
    overlay.style = {
        type: 'TopLevelStyle',
        id: 'mocntDefault',
        style: {
            groups: {},
            properties: {},
            propertiesSource: {}
        },
        styleType: 'custom',
        skin: 'skins.viewer.mediaOverlayControlsDefaultSkin'
    }

    return overlay
}

function getMediaPlayerControlsStructure(editorAPI, videoData, videoSize, controlsHeight) {
    const controls = _.omit(editorAPI.components.buildDefaultComponentStructure('wysiwyg.viewer.components.MediaControls'), 'data')
    controls.props.showStoryboard = 'none'
    controls.layout = {
        width: videoSize.width,
        height: controlsHeight,
        x: 0,
        y: videoSize.height - controlsHeight
    }
    controls.design = {
        type: 'MediaControlsDesignData',
        metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
        iconsDefaultDesign: {
            type: 'VectorImageDesignData',
            metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
            shapeStyle: {
                opacity: 1,
                strokeOpacity: 1,
                stroke: '#000000',
                enableStroke: false,
                strokeWidth: 1
            },
            overrideColors: {
                color1: '#ffffff'
            }
        },
        icons: [
            {
                type: 'ControlIconDesignData',
                metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                name: 'volumeOn',
                svgId: '0da768_4026746ebab74627a513aa0df1511dea.svg'
            },
            {
                type: 'ControlIconDesignData',
                metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                name: 'volumeOff',
                svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg'
            },
            {
                type: 'ControlIconDesignData',
                metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                name: 'noAudio',
                svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
                iconDesign: {
                    type: 'VectorImageDesignData',
                    metaData: {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    overrideColors: {
                        color1: '#a9a9a9'
                    }
                }
            }
        ]
    }
    controls.skin = 'skins.viewer.mediaControlsNoControlsSkin'
    controls.style = {
        type: 'TopLevelStyle',
        id: 'mcntLight',
        style: {
            groups: {},
            properties: {},
            propertiesSource: {}
        },
        styleType: 'custom',
        skin: 'skins.viewer.mediaControlsNoControlsSkin'
    }

    return controls
}

function getMediaPlayerStructure(videoData, info, editorAPI) {

    const aspectRatio = getAspectRatio(videoData)
    const videoSize = getMediaProportionSize(aspectRatio)

    const overlaySize = 86
    const controlsHeight = 44

    const structure = getMediaPlayerContainerStructure(editorAPI, videoData, videoSize)
    const overlay = getMediaPlayerOverlayControlsStructure(editorAPI, overlaySize, videoSize)
    const controls = getMediaPlayerControlsStructure(editorAPI, videoData, videoSize, controlsHeight)

    structure.components = [overlay, controls]
    return structure
}

/**
 * Save media manager path for the given id to site prefs
 * Expects a single comp
 * @param editorAPI
 * @param id
 * @param path
 */

function onItemsSelected(props, editorAPI, items, info) {
    _.forEach(items, function (item) {
        const videoData = props.getMediaPlayerVideoObject(item, info)
        const videoboxStructure = getMediaPlayerStructure(videoData, info, editorAPI)
        props.onClick(videoboxStructure, props.sectionTitle, '')
        editorAPI.store.dispatch(props.setSessionUserPreferences(`last_media_path_${videoData.videoId}`, info.path))
    })
}


module.exports = function (editorAPI, props) {
    const category = editorAPI.mediaServices.mediaManager.categories[props.category]
    editorAPI.bi.event(props.addPanelBiEvent.ADD_MENU_MORE_CLICK, {
        category: props.categoryId,
        section: props.sectionName
    })
    editorAPI.mediaServices.mediaManager.open(category, props.openSource, {
        multiSelect: true,
        callback: function (items, info) {
            onItemsSelected(props, editorAPI, items, info)
        },
        path: props.path
    })
}
