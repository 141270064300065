import { debugLayouts, initDebugLayoutsOnSelect } from './api';
import type { Scope } from './scope';
import { isDebugMode } from '@/util';

export async function init(scope: Scope) {
  await scope.editorCoreApi.hooks.initReady.promise;
  if (isDebugMode()) {
    (window as any).debugLayouts = () => debugLayouts(scope);
  }
  initDebugLayoutsOnSelect(scope);
}
