import constants from '@/constants';
import { sections as sectionsUtils } from '@/util';
import { sections } from '@/stateManagement';
import { addBlankSectionToEmptyPageIfNeeded } from '../../enforcement/addBlankSection';

import type { RemovePlugin } from 'types/editorPlugins';
import type { CompRef } from 'types/documentServices';
import type { Scope } from '@/sections';
import { isMeshLayoutEnabled } from '@/layout';

const { shiftComponents, getComponentsBelowPosition } = sectionsUtils;

const removeOverlappingAnchors = async (
  { editorAPI, components }: Scope,
  sectionRef: CompRef,
) => {
  const sectionLayout = components.layout.get_rect(sectionRef);

  const page = editorAPI.pages.getCurrentPage();
  const pageAnchors = components.get.byType(constants.COMP_TYPES.ANCHOR, page);
  const pageAnchorsWithLayout = pageAnchors.map((ref) => ({
    ref,
    layout: components.layout.get_rect(ref),
  }));

  sectionLayout.y += 1;

  const anchorsToRemove = pageAnchorsWithLayout.filter(({ layout }) => {
    const sectionTop = sectionLayout.y;
    const sectionBottom = sectionTop + sectionLayout.height;
    const top = layout.y;
    const bottom = top + layout.height;

    return top >= sectionTop && bottom < sectionBottom;
  });

  anchorsToRemove.forEach(({ ref }) => components.remove(ref));
};

export const getSectionRemovePlugin =
  (scope: Scope): RemovePlugin =>
  async (compRef, allowRemoveMessage, originRemove) => {
    const { editorAPI } = scope;
    editorAPI.store.dispatch(sections.actions.setHoveredSectionLike(null));
    editorAPI.store.dispatch(
      sections.actions.setIsSectionActionsBarHovered(false),
    );

    const sectionLayout = editorAPI.components.layout.get_rect(compRef);

    const componentsToShift = getComponentsBelowPosition(
      editorAPI,
      sectionLayout.y + sectionLayout.height,
    );

    removeOverlappingAnchors(scope, compRef);

    await originRemove();

    // NOTE: mesh layout anchors will shift next sections automatically
    if (!isMeshLayoutEnabled()) {
      shiftComponents(editorAPI, componentsToShift, -sectionLayout.height);
    }

    addBlankSectionToEmptyPageIfNeeded(scope);
  };
