import React, { type FC } from 'react';

import { frames } from '@/panels';
import { translate } from '@/i18n';

import { Checkbox, Text, TextButton } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import styles from './PublishErrorPanel.scss';

interface PublishErrorPanelNonDevUIProps {
  showPanelAgain: boolean;
  setShowPanelAgain: any;
  handleClose: (origin: string) => void;
}

const PublishErrorPanelNonDevUI: FC<PublishErrorPanelNonDevUIProps> = ({
  showPanelAgain,
  setShowPanelAgain,
  handleClose,
}) => (
  <frames.MessagePanelFrame
    panelName="ai-injection-into-template-error-panel"
    dataHook="ai-injection-into-template-error-panel"
    title={'Publish halted'}
    illustration={<Symbol name="installer-builder-empty-state" />}
    confirmOnEnterPress
    cancelOnEscPress
    cancelOnClickOutside
    sideActions={
      <Checkbox
        label="Don't show this again"
        labelAfterSymbol
        value={showPanelAgain}
        onChange={setShowPanelAgain}
      />
    }
    confirmLabel={translate('Publish Anyway')}
    cancelLabel={translate('Return to Editor')}
    onCancel={() => handleClose('button')}
    onSecondaryAction={() => handleClose('x')}
  >
    <Text size="small" skin="secondary" enableEllipsis={false}>
      Errors in your site’s code were found. Publishing a site with code errors
      may break parts of your site and impact your business.
    </Text>

    <div className={styles.wrapper_nonDevMode}>
      <TextButton
        size="small"
        onClick={() => {
          console.log('Need to download');
        }}
        prefixIcon={<Symbol name="corvid_npm__download" />}
      >
        Download build.log
      </TextButton>

      <Text size="small" skin="secondary" enableEllipsis={false}>
        output and consult your developer before publishing.
      </Text>
    </div>
  </frames.MessagePanelFrame>
);

export default PublishErrorPanelNonDevUI;
