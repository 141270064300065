import compPanelMixin from './mixins/compPanelMixin';
import panelSwitcherMixin from './mixins/panelSwitcherMixin';
import appLogicCustomizationMixin from './mixins/wixapps/appLogicCustomizationMixin';
import wixappsPanelMixin from './mixins/wixapps/wixappsPanelMixin';
import wixappsViewCacheInvalidationMixin from './mixins/wixapps/wixappsViewCacheInvalidationMixin';
import compPanelFrame, {
  type BannerProps,
} from './compPanelFrame/compPanelFrame';
import externalCompPanel, {
  type ComponentPanelSource,
  wrapExternalComponentPanel,
} from './compPanelFrame/externalCompPanel';
import showBubbleOnXButtonRemove from './popupContainer/showBubbleOnXButtonRemove';
import * as bi from './bi/bi';

function onPreviewReady(editorAPI: AnyFixMe) {
  editorAPI.registerRemovePlugin(
    'wysiwyg.viewer.components.PopupCloseIconButton',
    showBubbleOnXButtonRemove.bind(null, editorAPI),
  );
}

export type { ComponentPanelSource, BannerProps };

export {
  onPreviewReady,
  compPanelMixin,
  panelSwitcherMixin,
  // Wixapps mixins are required for wixapps design panels in designPanel package.
  wixappsPanelMixin,
  appLogicCustomizationMixin,
  wixappsViewCacheInvalidationMixin,
  compPanelFrame,
  externalCompPanel,
  wrapExternalComponentPanel,
  bi,
};
