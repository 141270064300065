import * as util from '@/util';
import React from 'react';
import { translate } from '@/i18n';
import {
  AccordionSection,
  Divider,
  InfoIcon,
  Preloader,
  TextLabel,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import experiment from 'experiment';
import { OnlyBackendLoginFrame } from './onlyBackendLoginFrame';

interface BackendLoginOnlySettingsProps {
  disabled: boolean;
  isBackendLoginOnlyOn: () => Promise<boolean>;
  setBackendLoginOnlyStatus: (toggle: boolean) => Promise<void>;
  navigateToVeloPanel: () => void;
}

const BackendLoginOnlySettings: React.FC<BackendLoginOnlySettingsProps> = ({
  disabled,
  isBackendLoginOnlyOn,
  setBackendLoginOnlyStatus,
  navigateToVeloPanel,
}) => {
  const [shouldOnlyBackendLoginFramePop, setShouldOnlyBackendLoginFramePop] =
    React.useState(false);

  const [isBackendLoginOnlyOnState, setBackendLoginOnlyOnState] =
    React.useState<boolean>();

  React.useEffect(() => {
    isBackendLoginOnlyOn().then((isOn) => {
      setBackendLoginOnlyOnState(isOn);
    });
  }, [isBackendLoginOnlyOn]);

  //eslint-disable-next-line @wix/santa/no-falsy-experiment
  if (!experiment.isOpen('se_allowOnlyBackendLoginSetting')) {
    return <></>;
  }

  const disabledToggleTooltipContent = experiment.isOpen(
    'se_allowOnlyBackendLoginSettingAdvance',
  ) ? (
    <span className="info-icon-content">
      <div className="info-icon-text">
        {translate(
          'Pages_Custom_Signup_API_Restrictions_Toggle_Tooltip_Content',
        )}
      </div>
      <div className="info-icon-link" onClick={navigateToVeloPanel}>
        {translate(
          'Pages_Custom_Signup_API_Restrictions_Toggle_Tooltip_Action',
        )}
      </div>
    </span>
  ) : (
    translate('Pages_Custom_Signup_API_Restrictions_Toggle_Tooltip_Content')
  );

  const toggleBackendLoginOnlyOn = (toggle: boolean) => {
    setBackendLoginOnlyStatus(toggle);
    setBackendLoginOnlyOnState(toggle);
  };

  return (
    <>
      <div key="advancedSettings" className="backend-calls-settings">
        <AccordionSection
          shouldTranslate={false}
          // Signup & Login API Restrictions
          label={translate('Pages_Custom_Signup_Settings_Advanced_Settings')}
          removePadding={true}
        >
          <Divider long={true} className="divider-margin" />
          {isBackendLoginOnlyOnState !== undefined ? (
            <>
              <div className="settings-margin">
                <div className="backend-calls-toggle">
                  <div className="backend-calls-toggle-title">
                    <TextLabel
                      enableEllipsis={false}
                      // Only allow backend calls for Signup and Login APIs
                      value={translate(
                        'Pages_Custom_Signup_Settings_Allow_Only_Backend_Signup',
                      )}
                      shouldTranslate={false}
                    />
                    <span className="info-icon">
                      <InfoIcon
                        // Allowing backend calls only, will disable the option for client login calls.
                        text={translate(
                          'Pages_Custom_Signup_API_Restrictions_Tooltip_Content',
                        )}
                        // Learn More
                        linkText={translate(
                          'Pages_Custom_Signup_API_Restrictions_Tooltip_Learn_More',
                        )}
                        onLinkClick={() => {
                          window.open(
                            `https://support.wix.com/${util.languages.getLanguageCode()}/article/velo-backend-signup-and-login `,
                          );
                        }}
                      />
                    </span>
                  </div>
                  {disabled ? (
                    <Tooltip interactive content={disabledToggleTooltipContent}>
                      <ToggleSwitch
                        value={isBackendLoginOnlyOnState}
                        disabled={disabled}
                      />
                    </Tooltip>
                  ) : (
                    <ToggleSwitch
                      value={isBackendLoginOnlyOnState}
                      onChange={(toggle: boolean) =>
                        toggle
                          ? setShouldOnlyBackendLoginFramePop(true)
                          : toggleBackendLoginOnlyOn(toggle)
                      }
                      disabled={disabled}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            // fetching isBackendLoginOnlyOn state
            <div className="loader">
              <Preloader />
            </div>
          )}
        </AccordionSection>
      </div>
      {shouldOnlyBackendLoginFramePop && (
        <OnlyBackendLoginFrame
          panelName="BackendLoginOnly"
          // Allow only backend calls for APIs?
          title={translate(
            'Pages_Custom_Signup_API_Restrictions_Enable_Confirmation_Modal_Title',
          )}
          // Allow
          confirmLabel={translate(
            'Pages_Custom_Signup_API_Restrictions_Enable_Confirmation_Modal_Confirm_Label',
          )}
          // Cancel
          cancelLabel={translate(
            'Pages_Custom_Signup_API_Restrictions_Enable_Confirmation_Modal_Cancel_Label',
          )}
          // Note: Changes to this setting apply immediately without saving or publishing your site.
          footnote={translate(
            'Pages_Custom_Signup_API_Restrictions_Enable_Confirmation_Modal_Footer_Description',
          )}
          onCancel={() => setShouldOnlyBackendLoginFramePop(false)}
          onConfirm={async () => {
            toggleBackendLoginOnlyOn(true);
            setShouldOnlyBackendLoginFramePop(false);
          }}
        >
          {/*
            This will disable frontend calls, so neither the Default or Custom Signup & Login forms won’t work on your live site.
          */}
          {translate(
            'Pages_Custom_Signup_API_Restrictions_Enable_Confirmation_Modal_Description',
          )}
        </OnlyBackendLoginFrame>
      )}
    </>
  );
};

export default BackendLoginOnlySettings;
