import type { AccountState, AccountStateFull } from './accountReducer';

const getAccountState = (state: AccountStateFull): AccountState =>
  state.account;

const getAccount = (state: AccountStateFull, accountId: string) => {
  const accountState = getAccountState(state);
  return accountState.accounts?.find(
    (account) => account.accountId === accountId,
  );
};

const getAccounts = (state: AccountStateFull) =>
  getAccountState(state).accounts;

const getIsStudioAccount = (state: AccountStateFull) =>
  getAccountState(state).isStudioAccount;

export const accountSelectors = {
  getAccount,
  getAccounts,
  getIsStudioAccount,
};
