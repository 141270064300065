import type { SectionsMigrationScope as Scope } from '../scope';
import * as stateManagement from '@/stateManagement';
import * as helpIds from '@/helpIds';
import {
  getMigrationSkipCount,
  HEAVY_SITES,
  isMigrationCancelled,
  isTimeLimitError,
} from '../utils';
import { MigrationFlow } from '../types';
import { MIGRATION_SKIP_LIMIT } from '../constants';

export function showNotification(
  scope: Scope,
  {
    messageType,
    message,
    link = HEAVY_SITES.NOTIFICATION_LEARN_MORE_LINK_TEXT,
    onClick = () =>
      scope.editorAPI.panelManager.openHelpCenter(
        helpIds.HEAVY_SITES_MIGRATION_HELP_IDS.LEARN_MORE,
      ),
  }: {
    messageType: 'error' | 'info' | 'success';
    message: string;
    link?: string;
    onClick?: () => void;
  },
) {
  scope.editorAPI.store.dispatch(
    stateManagement.notifications.actions.showUserActionNotification({
      message,
      link: {
        caption: link,
        onNotificationLinkClick: onClick,
      },
      type: messageType,
      timeout: 20000,
    }),
  );
}

export function showErrorNotification(
  scope: Scope,
  {
    flow,
    error,
  }: {
    flow: MigrationFlow;
    error: Error;
  },
) {
  const shouldShowErrorNotification =
    flow === MigrationFlow.Editor || flow === MigrationFlow.HeavySite;

  if (!shouldShowErrorNotification) {
    return;
  }

  if (isTimeLimitError(error)) {
    showNotification(scope, {
      messageType: 'info',
      message: HEAVY_SITES.NOTIFICATION_TIME_OUT,
    });
  } else if (
    isMigrationCancelled(error) &&
    getMigrationSkipCount(scope) >= MIGRATION_SKIP_LIMIT
  ) {
    showNotification(scope, {
      messageType: 'info',
      message: HEAVY_SITES.NOTIFICATION_SKIPPED_THIRD,
    });
  } else if (!isMigrationCancelled(error)) {
    showNotification(scope, {
      messageType: 'error',
      message: HEAVY_SITES.NOTIFICATION_TECH_ERROR,
    });
  }
}
