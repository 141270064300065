// @ts-nocheck

const REPEATER_STATE = {
  type: 'RepeaterContainer',
  id: 'comp-k9b5r2t11',
  components: [
    {
      type: 'Container',
      id: 'comp-k9b5r2t52',
      components: [
        {
          type: 'Component',
          id: 'comp-k9b5r2td1',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 130,
            height: 31,
            x: 31,
            y: 44,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'uw2wd',
          },
          parent: 'comp-k9b5r2t52',
          data: {
            type: 'RepeatedData',
            original: {
              type: 'StyledText',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'uw2wd',
              },
              text: '<h2 class="font_2"><span class="color_15">Title</span></h2>',
              stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
              linkList: [],
            },
            overrides: {
              item1: {
                type: 'StyledText',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'uw2wd',
                },
                text: '<h2 class="font_2"><span class="color_15">Title</span></h2>',
                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                linkList: [],
              },
              'item-k5uwo6us': {
                type: 'StyledText',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'uw2wd',
                },
                text: '<h2 class="font_2"><span class="color_15">Title</span></h2>',
                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                linkList: [],
              },
            },
            id: 'dataItem-k9b5r2te',
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'uw2wd',
            },
            brightness: 1,
            packed: true,
            id: 'propItem-k9b5r2tg',
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            author: 'duplicate',
            originalCompId: 'comp-k5uwlt9r1',
            recommendedScale: 1.3214285,
            recommendedWidth: 262,
            recommendedHeight: 37,
            id: 'mobileHints-k9b5r2ti',
          },
          style: 'txtNew',
          connections: {
            type: 'ConnectionList',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            items: [
              {
                type: 'ConnectionItem',
                role: 'text1',
                controllerId: 'dataItem-k9b5r2sb',
                isPrimary: true,
              },
            ],
            id: 'connection-k9b5r2tj',
          },
          custom: {
            originalNicknameContext: {
              id: 'comp-k9b5r2s8',
              type: 'DESKTOP',
            },
          },
        },
        {
          type: 'Component',
          id: 'comp-k9b5r2to',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 130,
            height: 76,
            x: 31,
            y: 91,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'uw2wd',
          },
          parent: 'comp-k9b5r2t52',
          data: {
            type: 'RepeatedData',
            original: {
              type: 'StyledText',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'uw2wd',
              },
              text: '<p class="font_8" style="font-size:15px; line-height:1.3em"><span class="color_15"><span style="letter-spacing:0.03em"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span style="font-size:15px">Avenir Light is a clean and stylish font favored by designers.</span></span></span></span></p>',
              stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
              linkList: [],
            },
            overrides: {
              item1: {
                type: 'StyledText',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'uw2wd',
                },
                text: '<p class="font_8" style="font-size:15px; line-height:1.3em"><span class="color_15"><span style="letter-spacing:0.03em"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span style="font-size:15px">Avenir Light is a clean and stylish font favored by designers.</span></span></span></span></p>',
                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                linkList: [],
              },
              'item-k5uwo6us': {
                type: 'StyledText',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'uw2wd',
                },
                text: '<p class="font_8" style="font-size:15px; line-height:1.3em"><span class="color_15"><span style="letter-spacing:0.03em"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span style="font-size:15px">Avenir Light is a clean and stylish font favored by designers.</span></span></span></span></p>',
                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                linkList: [],
              },
            },
            id: 'dataItem-k9b5r2tp',
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'uw2wd',
            },
            brightness: 1,
            packed: true,
            id: 'propItem-k9b5r2tq',
          },
          style: 'txtNew',
          connections: {
            type: 'ConnectionList',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            items: [
              {
                type: 'ConnectionItem',
                role: 'text2',
                controllerId: 'dataItem-k9b5r2sb',
                isPrimary: true,
              },
            ],
            id: 'connection-k9b5r2tq1',
          },
          custom: {
            originalNicknameContext: {
              id: 'comp-k9b5r2s8',
              type: 'DESKTOP',
            },
          },
          mobileStructure: {
            layout: {
              width: 280,
              height: 10,
              x: 20,
              y: 67,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            props: {
              type: 'WRichTextProperties',
              metaData: {
                schemaVersion: '1.0',
                autoGenerated: true,
                pageId: 'uw2wd',
              },
              brightness: 1,
              packed: false,
            },
            metaData: {
              originalCompId: 'comp-k9b5r2to',
              author: 'duplicate',
            },
          },
        },
        {
          type: 'Component',
          id: 'comp-k9b5r2tk',
          skin: 'wysiwyg.viewer.skins.button.BasicButton',
          layout: {
            width: 141,
            height: 30,
            x: 29,
            y: 199,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.SiteButton',
          metaData: {
            pageId: 'uw2wd',
          },
          parent: 'comp-k9b5r2t52',
          data: {
            type: 'RepeatedData',
            original: {
              type: 'LinkableButton',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'uw2wd',
              },
              label: 'Button',
            },
            overrides: {
              item1: {
                type: 'LinkableButton',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'uw2wd',
                },
                label: 'Button',
              },
              'item-k5uwo6us': {
                type: 'LinkableButton',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'uw2wd',
                },
                label: 'Button',
              },
            },
            id: 'dataItem-k9b5r2tl',
          },
          props: {
            type: 'ButtonProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'uw2wd',
            },
            align: 'center',
            margin: 0,
            id: 'propItem-k9b5r2tn',
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            author: 'studio',
            recommendedScale: 1,
            recommendedWidth: 120,
            recommendedHeight: 40,
            id: 'mobileHints-k9b5r2tn1',
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            style: {
              properties: {
                'boxShadowToggleOn-shd': 'false',
                bgh: 'color_12',
                'alpha-brdh': '1',
                bg: 'color_17',
                'alpha-brd': '1',
                brw: '1',
                fnt: 'font_8',
                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                brdd: '#CCCCCC',
                txt: 'color_15',
                'alpha-bgd': '1',
                rd: '0px 0px 0px 0px',
                brdh: 'color_15',
                brd: 'color_15',
                'alpha-bg': '0',
                'alpha-bgh': '0.6',
                txtd: '#FFFFFF',
                txth: '#000000',
                bgd: '#CCCCCC',
                'alpha-brdd': '1',
              },
              propertiesSource: {
                'boxShadowToggleOn-shd': 'value',
                bgh: 'theme',
                'alpha-brdh': 'value',
                bg: 'theme',
                'alpha-brd': 'value',
                brw: 'value',
                fnt: 'theme',
                shd: 'value',
                brdd: 'value',
                txt: 'theme',
                'alpha-bgd': 'value',
                rd: 'value',
                brdh: 'theme',
                brd: 'theme',
                'alpha-bg': 'value',
                'alpha-bgh': 'value',
                txtd: 'value',
                txth: 'value',
                bgd: 'value',
                'alpha-brdd': 'value',
              },
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.SiteButton',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.button.BasicButton',
            id: 'style-k9b5r2tn2',
          },
          connections: {
            type: 'ConnectionList',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            items: [
              {
                type: 'ConnectionItem',
                role: 'button1',
                controllerId: 'dataItem-k9b5r2sb',
                isPrimary: true,
              },
            ],
            id: 'connection-k9b5r2tn3',
          },
          custom: {
            originalNicknameContext: {
              id: 'comp-k9b5r2s8',
              type: 'DESKTOP',
            },
          },
        },
      ],
      layout: {
        width: 200,
        height: 261,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.MediaContainer',
      metaData: {
        pageId: 'uw2wd',
      },
      parent: 'comp-k9b5r2t11',
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uw2wd',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 0,
        offsetOrigin: 'leftTop',
        recommendedScale: 1,
        recommendedWidth: 320,
        orderIndex: 0,
        id: 'mobileHints-k9b5r2tc',
      },
      design: {
        type: 'RepeatedData',
        original: {
          type: 'MediaContainerDesignData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uw2wd',
          },
          background: {
            type: 'BackgroundMedia',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            color: '{color_11}',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
          },
          cssStyle: {
            cssBorder: {
              width: {
                top: {
                  value: 0,
                  unit: 'px',
                },
                right: {
                  value: 0,
                  unit: 'px',
                },
                bottom: {
                  value: 0,
                  unit: 'px',
                },
                left: {
                  value: 0,
                  unit: 'px',
                },
              },
              style: {
                top: 'solid',
                right: 'solid',
                left: 'solid',
                bottom: 'solid',
              },
              color: {
                top: {
                  red: 232,
                  green: 230,
                  blue: 230,
                  alpha: 1,
                },
                right: {
                  red: 232,
                  green: 230,
                  blue: 230,
                  alpha: 1,
                },
                left: {
                  red: 232,
                  green: 230,
                  blue: 230,
                  alpha: 1,
                },
                bottom: {
                  red: 232,
                  green: 230,
                  blue: 230,
                  alpha: 1,
                },
              },
            },
            cssBorderRadius: {
              topLeft: {
                value: 0,
                unit: 'px',
              },
              topRight: {
                value: 0,
                unit: 'px',
              },
              bottomLeft: {
                value: 0,
                unit: 'px',
              },
              bottomRight: {
                value: 0,
                unit: 'px',
              },
            },
          },
          themeMappings: {
            'cssBorder.color.bottom': 'color_12',
            'cssBorder.color.left': 'color_12',
            'cssBorder.color.right': 'color_12',
            'cssBorder.color.top': 'color_12',
          },
          charas: 'design-k9b5kzzi',
          dataChangeBehaviors: [],
        },
        overrides: {
          item1: {
            type: 'MediaContainerDesignData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            background: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'uw2wd',
              },
              color: '{color_11}',
              colorOpacity: 1,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'WixVideo',
            },
            cssStyle: {
              cssBorder: {
                width: {
                  top: {
                    value: 0,
                    unit: 'px',
                  },
                  right: {
                    value: 0,
                    unit: 'px',
                  },
                  bottom: {
                    value: 0,
                    unit: 'px',
                  },
                  left: {
                    value: 0,
                    unit: 'px',
                  },
                },
                style: {
                  top: 'solid',
                  right: 'solid',
                  left: 'solid',
                  bottom: 'solid',
                },
                color: {
                  top: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                  right: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                  left: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                  bottom: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                },
              },
              cssBorderRadius: {
                topLeft: {
                  value: 0,
                  unit: 'px',
                },
                topRight: {
                  value: 0,
                  unit: 'px',
                },
                bottomLeft: {
                  value: 0,
                  unit: 'px',
                },
                bottomRight: {
                  value: 0,
                  unit: 'px',
                },
              },
            },
            themeMappings: {
              'cssBorder.color.bottom': 'color_12',
              'cssBorder.color.left': 'color_12',
              'cssBorder.color.right': 'color_12',
              'cssBorder.color.top': 'color_12',
            },
            charas: 'design-k9b5kzzi',
            dataChangeBehaviors: [],
          },
          'item-k5uwo6us': {
            type: 'MediaContainerDesignData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'uw2wd',
            },
            background: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'uw2wd',
              },
              color: '#FFFFFF',
              colorOpacity: 1,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'WixVideo',
              mediaTransforms: {
                scale: 1,
              },
            },
            cssStyle: {
              cssBorder: {
                width: {
                  top: {
                    value: 0,
                    unit: 'px',
                  },
                  right: {
                    value: 0,
                    unit: 'px',
                  },
                  bottom: {
                    value: 0,
                    unit: 'px',
                  },
                  left: {
                    value: 0,
                    unit: 'px',
                  },
                },
                style: {
                  top: 'solid',
                  right: 'solid',
                  left: 'solid',
                  bottom: 'solid',
                },
                color: {
                  top: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                  right: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                  left: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                  bottom: {
                    red: 232,
                    green: 230,
                    blue: 230,
                    alpha: 1,
                  },
                },
              },
              cssBorderRadius: {
                topLeft: {
                  value: 0,
                  unit: 'px',
                },
                topRight: {
                  value: 0,
                  unit: 'px',
                },
                bottomLeft: {
                  value: 0,
                  unit: 'px',
                },
                bottomRight: {
                  value: 0,
                  unit: 'px',
                },
              },
            },
            themeMappings: {
              'cssBorder.color.bottom': 'color_12',
              'cssBorder.color.left': 'color_12',
              'cssBorder.color.right': 'color_12',
              'cssBorder.color.top': 'color_12',
            },
            charas: 'design-k9b5kzzn',
            dataChangeBehaviors: [],
          },
        },
        id: 'dataItem-k9b5r2ta',
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uw2wd',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.MediaContainer',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer',
        id: 'style-k9b5r2tc1',
      },
      connections: {
        type: 'ConnectionList',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uw2wd',
        },
        items: [
          {
            type: 'ConnectionItem',
            role: 'container1',
            controllerId: 'dataItem-k9b5r2sb',
            isPrimary: true,
          },
        ],
        id: 'connection-k9b5r2td',
      },
      custom: {
        originalNicknameContext: {
          id: 'comp-k9b5r2s8',
          type: 'DESKTOP',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 410,
    height: 261,
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.Repeater',
  metaData: {
    pageId: 'uw2wd',
  },
  parent: 'comp-k9b5r2sd',
  data: {
    type: 'Repeater',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'uw2wd',
    },
    items: ['item1', 'item-k5uwo6us'],
    id: 'dataItem-k9b5r2t3',
  },
  props: {
    type: 'CardsLayoutProperties',
    metaData: {
      schemaVersion: '1.0',
      autoGenerated: false,
      pageId: 'uw2wd',
    },
    alignment: 'left',
    gap: {
      vertical: 10,
      horizontal: 10,
    },
    id: 'propItem-k9b5r2t4',
  },
  mobileHints: {
    type: 'MobileHints',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'uw2wd',
    },
    author: 'studio',
    recommendedScale: 1,
    recommendedWidth: 320,
    id: 'mobileHints-k9b5r2t41',
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'uw2wd',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '0',
        bg: '61,155,233,1',
        'boxShadowToggleOn-shd': 'false',
        brd: '#324158',
        brw: '0px',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    id: 'style-k9b5r2t5',
  },
};

const CONTAINER_STATE = {
  layout: {
    x: 0,
    y: 0,
    fixedPosition: false,
    width: 600,
    height: 400,
    scale: 1,
    rotationInDegrees: 0,
  },
  components: [],
  componentType: 'wysiwyg.viewer.components.StateBoxState',
  type: 'Container',
  design: {
    type: 'MediaContainerDesignData',
    background: {
      type: 'BackgroundMedia',
      color: '{color_11}',
      colorOpacity: 1,
      alignType: 'center',
      fittingType: 'fill',
      scrollType: 'none',
      colorOverlay: '',
      colorOverlayOpacity: 0,
      showOverlayForMediaType: 'WixVideo',
      mediaRef: null,
      imageOverlay: null,
      filterEffect: null,
    },
    charas: 'design-k4ck4vur',
    dataChangeBehaviors: [],
  },
  style: 'stateBoxState1',
};

export { REPEATER_STATE, CONTAINER_STATE };
