// @ts-nocheck
const SINGLE_SERVICE_WIDGET_IMAGE_V3 =
  'wixBookings/addPanel/preset_editor_single_service_widget.v3.png';
const SINGLE_SERVICE_PRESET_KEY = 'Wix_Bookings_Single_Service_Widget';
const SINGLE_SERVICE_TITLE_KEY =
  'SiteApp_WixBookings_AddElement_SingleServiceWidget';

const SINGLE_SERVICE_WIDGET_EDITOR_X_IMAGE =
  'wixBookings/addPanel/bookings_single_service_widget_editorX.png';

const SINGLE_SERVICE_WIDGET_WIX_STUDIO_IMAGE =
  'wixBookings/addPanel/bookings_single_service_widget_WixStudio.png';

const SINGLE_SERVICE_EDITOR_X_PRESET_KEY =
  'Wix_Bookings_Single_Service_Widget_Editor_X';

export {
  SINGLE_SERVICE_WIDGET_IMAGE_V3,
  SINGLE_SERVICE_PRESET_KEY,
  SINGLE_SERVICE_TITLE_KEY,
  SINGLE_SERVICE_WIDGET_EDITOR_X_IMAGE,
  SINGLE_SERVICE_EDITOR_X_PRESET_KEY,
  SINGLE_SERVICE_WIDGET_WIX_STUDIO_IMAGE,
};
