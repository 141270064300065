:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_z35mi_7 {
  display: block;
  position: absolute; }

._panelContent_z35mi_11 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%; }

._tree_z35mi_17 {
  padding-top: 15px; }
  ._tree_z35mi_17 .tree-section-divider {
    background: #fff;
    height: 36px; }
    ._tree_z35mi_17 .tree-section-divider:hover {
      background: #e7f0ff; }
  ._tree_z35mi_17 .tree-list-item {
    padding-left: 20px; }
  ._tree_z35mi_17 .tree-list-item-icon .symbol {
    width: 14px;
    height: 14px; }
  ._tree_z35mi_17 .tree-list-item.selected .tree-list-item-icon path {
    fill: #116dff; }
  ._tree_z35mi_17 .tree-list-item-text-wrapper {
    flex: 1; }
  ._tree_z35mi_17 button {
    cursor: pointer; }
    ._tree_z35mi_17 button:hover {
      color: #116dff; }
    ._tree_z35mi_17 button:disabled, ._tree_z35mi_17 button:disabled:hover {
      color: #bcbcbc;
      cursor: default; }

._unsectionedTree_z35mi_41 .tree-list-item {
  background-color: #fbd0cd; }

._treeItem_z35mi_44._treeItemHighlighted_z35mi_44 .tree-section-divider .tree-section-text .control-label-base {
  color: #e62214; }

._actionButtonSet_z35mi_47 {
  padding: 14px 24px; }

._contextButton_z35mi_50 {
  margin-left: 5px; }

hr._divider_z35mi_53 {
  margin: 15px 0 0; }

._spinnerWrapper_z35mi_56 {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 15px; }
