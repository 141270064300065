'use strict'

module.exports = {
    id: 'add_preset_popups_Contact_2',
    structure: {
        type: 'Page',
        id: 'hawys',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im0fk2hw',
                components: [
                    {
                        type: 'Component',
                        id: 'comp-im0fk2i6',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 282,
                            height: 72,
                            x: 12,
                            y: 180,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:34px; line-height:0.9em; text-align:center"><span style="color:#00E3AA;"><span style="font-family:lulo-clean-w01-one-bold,sans-serif"><span style="font-size:34px">Keep In Touch!</span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im0fk2i8'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-ipf5cddm'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im0fk2ih',
                        layout: {
                            width: 240,
                            height: 52,
                            x: 55,
                            y: 279,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:18px; line-height:1.4em"><span style="color:#FFFFFE;"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Use this space to invite your visitors to say hello.</span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im0fk2ii'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im0fk2ij'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im0fy5d2',
                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                        layout: {
                            width: 122,
                            height: 36,
                            x: 1162,
                            y: 722,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: true,
                            docked: {bottom: {px: 20}, right: {px: 141}}
                        },
                        componentType: 'wysiwyg.viewer.components.LinkBar',
                        data: {
                            type: 'ImageList',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            items: [
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'White Twitter Icon',
                                    uri: '01ab6619093f45388d66736ec22e5885.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://twitter.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    title: 'White Instagram Icon',
                                    uri: '81af6121f84c41a5b4391d7d37fce12a.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.instagram.com/wix/',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'White Facebook Icon',
                                    uri: '23fd2a2be53141ed810f4d3dcdcd01fa.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.facebook.com/wix',
                                        target: '_blank'
                                    }
                                }
                            ],
                            id: 'dataItem-im0fy5dd'
                        },
                        props: {
                            type: 'LinkBarProperties',
                            metaData: {schemaVersion: '1.0'},
                            gallery: 'social_icons',
                            iconSize: 36,
                            spacing: 7,
                            bgScale: 1,
                            orientation: 'HORIZ',
                            id: 'propItem-im0fy5dj'
                        },
                        style: 'lb1'
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 34,
                            'height': 43,
                            'x': 361,
                            'y': 20,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-im0fk2hw',
                        'metaData': {
                            'pageId': 'hawys'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'hawys'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'hawys'
                            },
                            'maintainAspectRatio': true
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'hawys'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#FFFFFF',
                                    'stroke': '#000000',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button2'
                                }
                            ],
                            'metaData': {
                                'pageId': 'hawys'
                            }
                        },
                        'mobileStructure': {
                            'layout': {
                                'width': 24,
                                'height': 24,
                                'x': 275,
                                'y': 24,
                                'scale': 1,
                                'rotationInDegrees': 0,
                                'fixedPosition': false
                            },
                            'metaData': {
                                'originalCompId': 'comp-isregsqg',
                                'author': 'duplicate'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 411,
                    height: 778,
                    x: 791,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'right',
                    verticalAlignment: 'top',
                    alignmentType: 'fullHeight',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im0fk2i0'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im0fk2i01'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: '#660658'},
                        propertiesSource: {bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im0fk2i2'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"SlideIn","delay":0.2,"duration":0.5,"params":{"direction":"right"},"action":"screenIn","targetId":""},{"name":"SlideOut","delay":0.2,"duration":0.5,"params":{"direction":"right"},"action":"exit","targetId":""}]',
                    id: 'behavior-iomlweei1'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 778,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Contact_2',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'blank-hawys',
            hidePage: true,
            underConstruction: false,
            tpaApplicationId: 0,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#45807B',
                        colorOpacity: 0.74,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#45807B',
                        colorOpacity: 0.74,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'hawys'
        },
        style: 'p1',
        tpaComps: []
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 242},
        label: 'add_preset_popups_Contact_2',
        tags: null
    }
}
