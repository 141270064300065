import type { BasicAction } from './handlers';
import React from 'react';

export interface MainRouterViewAction {
  event: string;
  title: string;
  biActionName?: string;
  onClick: () => void;
}

export interface SecondaryRouterViewAction {
  event: string;
  symbol?: React.FC;
  symbolName: string;
  tooltipContent: string;
  biActionName?: string;
  onClick: () => void;
}

export interface RouterViewActions {
  mainAction?: MainRouterViewAction;
  secondaryActions?: SecondaryRouterViewAction[];
}

export interface RouterAction extends BasicAction {
  event?: string;
  type?: string;
}
