._root_xr6bn_1 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border: 1px solid rgba(217, 225, 232, 0.6);
  padding: 3px 6px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  z-index: 9998; }
  ._root_xr6bn_1 > *:not(:first-child) {
    margin-left: 10px; }

._button_xr6bn_13 {
  padding: 12px;
  height: 20px;
  font-size: 14px; }

._closeButton_xr6bn_18 {
  display: inline;
  color: #3899ec; }

._text_xr6bn_22 {
  font-size: 14px;
  color: #7e8796; }
