import _ from 'lodash';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { BaseDragPlugin } from './common';

function handleDragAndCopy(editorAPI: EditorAPI, selectedComp: CompRef[]) {
  if (
    editorAPI.components.is.duplicatable(selectedComp as AnyFixMe) &&
    selectedComp.length === 1 &&
    editorAPI.components.getChildren(selectedComp).length < 1
  ) {
    selectedComp.forEach((compRef) => {
      // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
      const serializedComponentData = editorAPI.components.serialize(compRef);
      const componentContainer = editorAPI.components.getContainer(compRef);
      const selectedCompNickName = editorAPI.components.getNickname(compRef);

      editorAPI.components.code.setNickname(
        compRef,
        selectedCompNickName + _.uniqueId('DAC'),
      );

      editorAPI.waitForChangesApplied(async () => {
        const addResult = await editorAPI.components.add(
          componentContainer,
          serializedComponentData,
        );

        editorAPI.waitForChangesApplied(async () => {
          const duplicatedCompRef = await waitForAddedCompRef(addResult);
          editorAPI.afterDuplicateComponentCallbacks.forEach((cb) => {
            cb(editorAPI, [duplicatedCompRef], [compRef]);
          });
        });
      });
    });
  }
}

export const DragAndCopyPlugin: BaseDragPlugin = {
  init: ({ pluginFactoryScope }) => {
    const { shouldDragAndCopy, editorAPI, selectedComp } = pluginFactoryScope;

    if (!shouldDragAndCopy) {
      return;
    }
    // hooks.onDragStart.tap(() => { //TODO check why not working
    handleDragAndCopy(editorAPI, selectedComp);
    // });
  },
};
