:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._addSectionCategoryHeader_1x7sf_7 {
  display: flex;
  justify-content: space-between;
  margin: 17px 0 30px;
  padding-bottom: 7px;
  border-bottom: 1px solid #dfe5eb; }
  ._addSectionCategoryHeader_1x7sf_7 span {
    color: #000624; }
