import React, { useEffect, useState, type FC } from 'react';

import { biLogger } from '@/util';
import * as util from '@/util';

import { panelOpened, panelHeaderClose } from '@wix/bi-logger-editor/v2';

import {
  type PublishErrorPanelDispatchProps,
  type PublishErrorPanelStateProps,
  mapDispatchToProps,
  mapStateToProps,
} from './PublishErrorPanelMapper';
import PublishErrorPanelDevUI from './PublishErrorPanelDevUI';
import PublishErrorPanelNonDevUI from './PublishErrorPanelNonDevUI';

export interface PublishErrorPanelProps
  extends PublishErrorPanelDispatchProps,
    PublishErrorPanelStateProps {}

const PublishErrorPanel: FC<PublishErrorPanelProps> = ({
  closePanel,
  isDevMode,
  error,
  openBuildLog,
}) => {
  const [showPanelAgain, setShowPanelAgain] = useState<boolean>(false);

  useEffect(() => {
    biLogger.report(
      panelOpened({
        panel_name: 'PublishErrorPanel',
        origin: 'PublishErrorPanel',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendBiOnClose = (target: string) => {
    biLogger.report(
      panelHeaderClose({
        panel_name: 'PublishErrorPanel',
        origin: target,
      }),
    );
  };

  const handleClose = (origin: string) => {
    sendBiOnClose(origin);

    closePanel();
  };

  const handleOpenBuildLog = () => {
    openBuildLog(error.deploymentId);
  };

  return isDevMode ? (
    <PublishErrorPanelDevUI
      showPanelAgain={showPanelAgain}
      setShowPanelAgain={setShowPanelAgain}
      handleClose={handleClose}
      errorResult={error.tasks}
      handleOpenBuildLog={handleOpenBuildLog}
    />
  ) : (
    <PublishErrorPanelNonDevUI
      showPanelAgain={showPanelAgain}
      setShowPanelAgain={setShowPanelAgain}
      handleClose={handleClose}
    />
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PublishErrorPanel);
