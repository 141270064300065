import { appMarketHandlers } from '@/tpa';
import { wrapWithPropsTransformer } from '../utils';
import { PRICING_PLANS } from '@wix/app-definition-ids';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

import type {
  Dispatch,
  StateMapperArgs,
  MapDispatchToPropsFunction,
} from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';

export interface PricingPlansWelcomeOwnProps {
  pricingPlansMainAction: () => void;
}

export interface PricingPlansWelcomeDispatchProps {
  installPricingPlansApp: () => void;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  PricingPlansWelcomeDispatchProps,
  PricingPlansWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    installPricingPlansApp: async () => {
      try {
        const platformOrigin = {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AddPanel,
          },
        };
        appMarketHandlers.addApp(editorAPI, {
          appDefId: PRICING_PLANS,
          platformOrigin,
        });
      } catch (e) {
        ErrorReporter.captureException(e, {
          tags: { tpaInstallation: 'PricingPlans' },
        });
      }
    },
  };
};

export const pricingPlansWelcomeMapper = wrapWithPropsTransformer<
  PricingPlansWelcomeDispatchProps,
  PricingPlansWelcomeOwnProps
>(mapDispatchToProps, (props) => ({
  pricingPlansMainAction: props.installPricingPlansApp,
}));
