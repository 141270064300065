import React from 'react';

import { translate } from '@/i18n';
import { cx, hoc } from '@/util';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import MenuButtonLabel from '../menuButtonLabel/menuButtonLabel';
import {
  type AiButtonDispatchProps,
  type AiButtonStateProps,
  mapDispatchToProps,
  mapStateToProps,
} from './aiButtonMapper';

import styles from './aiButton.scss';

export interface AiButtonOwnProps {}

export interface AiButtonProps
  extends AiButtonOwnProps,
    AiButtonStateProps,
    AiButtonDispatchProps {}

const AiButton: React.FC<AiButtonProps> = ({
  isSelected,
  toggleLeftBarAiPanel,
  sendBIButtonHover,
  sendBIButtonClick,
  isEnabled,
  saveInteractionStateToSitePreferences,
  aiToolsWasOpened,
}) => {
  const handleMouseEnter = () => {
    sendBIButtonHover('aiTools');
  };

  const handleClick = () => {
    saveInteractionStateToSitePreferences();

    sendBIButtonClick('aiTools');

    toggleLeftBarAiPanel();
  };

  return (
    <div className={styles.wrapper}>
      <button
        data-aid="leftbar-ai-button"
        disabled={!isEnabled}
        className={cx(styles.button, {
          selected: isSelected,
          aiToolsWasOpened,
        })}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        <Symbol
          name={isSelected ? 'sparklesBold' : 'sparklesBoldAnimation'}
          className={styles.symbol}
        />
      </button>

      <MenuButtonLabel
        automationId="leftbar-ai-button-label"
        className={styles.floatingBlock}
        text={translate('LEFTBAR_ai_tools_LABEL')}
        shouldTranslate={false}
      />
    </div>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AiButton);
