'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require('experiment')

const unifyExperienceIsOpen = experiment.isOpen('specs.wixData.UnifyExperience')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.DATA,
    'hide': false,
    'title': unifyExperienceIsOpen
        ? 'PLATFORM_ADD_SECTION_UNIFIED_HEADING3'
        : 'PLATFORM_ADD_SECTION_HEADING3',
    'showSectionHeader': true,
    'props': {
        items: [
            {
                title: unifyExperienceIsOpen
                    ? 'PLATFORM_ADD_SECTION_UNIFIED_LABEL_NEW_PRESET'
                    : 'PLATFORM_ADD_SECTION_LABEL_NEW_PRESET',
                desc: unifyExperienceIsOpen
                    ? 'PLATFORM_ADD_SECTION_UNIFIED_NEW_PRESET_DESCRIPTION'
                    : 'PLATFORM_ADD_SECTION_NEW_PRESET_DESCRIPTION',
                tooltip: 'PLATFORM_ADD_SECTION_PRESET_TOOLTIP',
                image: 'addPanelData/sections/dataBinding/addPreset.png',
                action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_PRESET
            }
        ]
    }
}
