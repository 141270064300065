'use strict'

const languageSelectorSection = require('../sections/menu/languageSelectorSection')
const horizontalMenusSection = require('../sections/menu/horizontalMenusSection')
const verticalMenusSection = require('../sections/menu/verticalMenusSection')
const verticalAnchorsMenuSection = require('../sections/menu/verticalAnchorsMenuSection')
const stylableHorizontalMenuSection = require('../sections/menu/stylableHorizontalMenu/section')
const anchorsSection = require('../sections/other/anchorsSection')
const stylableButtonSection = require('../sections/button/stylableButtonSectionRedesign')
const paginationSection = require('../sections/developer/paginationSection')
const breadcrumbsSection = require('../sections/menu/breadcrumbsSection')

const textButtonsSection = require('../sections/button/textButtonsSection')

module.exports = {
    languageSelectorSection,
    horizontalMenusSection,
    verticalMenusSection,
    verticalAnchorsMenuSection,
    stylableHorizontalMenuSection,
    anchorsSection,
    breadcrumbsSection,

    textButtonsSection,
    stylableButtonSection,
    paginationSection
}
