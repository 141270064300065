:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_63kay_7 {
  width: 420px;
  max-width: 420px; }

._titleWrapper_63kay_11 {
  display: flex;
  padding: 18px 24px;
  align-items: center; }

._titleSymbol_63kay_16 {
  padding-right: 18px;
  flex: 0 0 auto; }

._subtitleWrapper_63kay_20 {
  padding: 24px 24px 6px; }

._list_63kay_23 {
  display: flex;
  flex-flow: column;
  padding: 0 18px; }
  ._list_63kay_23 > li {
    flex: 0 0 100%;
    padding: 6px;
    box-sizing: border-box; }

._item_63kay_32 {
  border-radius: 4px;
  border: 1px solid #dfe5eb;
  padding: 18px 12px;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  ._item_63kay_32 .spark,
  ._item_63kay_32 .spark-1,
  ._item_63kay_32 .spark-2 {
    transform-origin: center;
    transform-box: fill-box; }
  ._item_63kay_32 .spark-1,
  ._item_63kay_32 .spark-2 {
    transform: scale(0); }

@keyframes _illus-big-spark-animation_63kay_1 {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes _illus-spark-1-animation_63kay_1 {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }
  ._item_63kay_32 .colors-bg-shape,
  ._item_63kay_32 .hide-bg-shape {
    transform-origin: center;
    transform-box: fill-box; }

@keyframes _orbit-bg-shape-animation_63kay_1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  ._item_63kay_32 .hide-bg-shape {
    opacity: 0; }
  ._item_63kay_32:hover {
    box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17);
    transform: translate(0px, -4px); }
    ._item_63kay_32:hover ._itemAction_63kay_81 svg {
      fill: #116dff; }
    ._item_63kay_32:hover .spark {
      animation: _illus-big-spark-animation_63kay_1 0.6s linear; }
    ._item_63kay_32:hover .spark-1 {
      animation: _illus-spark-1-animation_63kay_1 0.4s linear 0.25s; }
    ._item_63kay_32:hover .spark-2 {
      animation: _illus-spark-1-animation_63kay_1 0.4s linear 0.1s; }
    ._item_63kay_32:hover .colors-bg-shape {
      animation: _orbit-bg-shape-animation_63kay_1 0.6s linear; }
    ._item_63kay_32:hover .hide-bg-shape {
      opacity: 1;
      animation: _orbit-bg-shape-animation_63kay_1 0.43s linear; }
  ._item_63kay_32 .control-text + .control-text {
    padding-top: 2px;
    display: inline-block; }

._itemBody_63kay_98 {
  flex: 1 1 100%;
  justify-content: space-between;
  padding: 0 12px; }

._itemSymbol_63kay_103 {
  flex: 0 0 auto;
  max-width: 48px; }

._itemAction_63kay_81 {
  flex: 0 0 auto; }
