import type { EntryPoint } from '@/apilib';
import { SelectionApiKey, EditorAPIKey, PanelManagerApiKey } from '@/apis';
import { createPanelHelpersApi } from './panelHelpersApi';
import { PanelHelpersApiKey } from './publicApiKey';

export const PanelHelpersApiEntryPoint: EntryPoint = {
  name: 'PanelHelpersApi',
  declareAPIs: () => [PanelHelpersApiKey],
  getDependencyAPIs: () => [EditorAPIKey, SelectionApiKey, PanelManagerApiKey],
  attach(shell) {
    shell.contributeAPI(PanelHelpersApiKey, () => createPanelHelpersApi(shell));
  },
};
