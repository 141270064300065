// @ts-nocheck
import _ from 'lodash';
import { DATA_BINDING } from '@wix/app-definition-ids';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';

const { getDeviceType } = stateManagement.leftBar.selectors;

function getDashboardUrl(editorAPI, appData, token, options) {
  return new Promise((resolve, reject) => {
    const appDefIdFromOptions = options?.appDefinitionId;
    const appDefinitionId = appDefIdFromOptions || appData.appDefinitionId;
    const applicationId = appDefIdFromOptions
      ? editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId)
          ?.applicationId
      : appData.applicationId;
    const isSaved = isSiteSaved(editorAPI);
    const isDraftMode = isSiteInDraftMode(editorAPI);
    const onSaveSuccess = () => {
      if (appDefinitionId === DATA_BINDING) {
        resolve(getWixCodeAppInMyAccountUrl(editorAPI, appDefinitionId));
      } else if (
        editorAPI.dsRead.tpa.app.isHybrid(applicationId) ||
        editorAPI.dsRead.tpa.app.isDashboardAppOnly(applicationId)
      ) {
        resolve(getAppInMyAccountUrl(editorAPI, appDefinitionId));
      } else {
        reject('The given appDefinitionId does not have an dashboard URL.'); //eslint-disable-line prefer-promise-reject-errors
      }
    };
    if (isSaved && !isDraftMode) {
      onSaveSuccess();
      return;
    }
    editorAPI.saveManager.saveInBackground(
      function () {
        onSaveSuccess();
      },
      reject,
      'getDashboardUrl',
    );
  });
}

function getAscendUpgradeUrl(editorAPI, appData, token, options) {
  const isSaved = isSiteSaved(editorAPI);
  const isDraftMode = isSiteInDraftMode(editorAPI);
  if (!isSaved || isDraftMode) {
    throw new Error('Site has never been saved');
  }

  const origin = options?.origin;
  const slug = options?.slug;
  const msid = editorAPI.dsRead.generalInfo.getMetaSiteId();

  return stateManagement.ascend.selectors.getAscendUrl({ msid, slug, origin });
}

function getBusinessManagerUrl(editorAPI) {
  const isSaved = isSiteSaved(editorAPI);
  const isDraftMode = isSiteInDraftMode(editorAPI);
  if (!isSaved || isDraftMode) {
    throw new Error('Site has never been saved');
  }

  const bizManagerBaseUrl = util.serviceTopology.businessManagerUrl;
  const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
  return util.url.joinURL(bizManagerBaseUrl, metaSiteId);
}

function getEditorSessionId() {
  return util.editorSessionUtil.editorSessionId;
}

function getAppInMyAccountUrl(editorAPI, appDefinitionId) {
  const domain = getCurrentDomain();
  return [
    '//',
    domain,
    '/my-account/sites/',
    editorAPI.dsRead.generalInfo.getMetaSiteId(),
    '/app/',
    appDefinitionId,
    '?referralInfo=editor',
  ].join('');
}

function getWixCodeAppInMyAccountUrl(editorAPI) {
  const domain = getCurrentDomain();
  return [
    '//',
    domain,
    '/my-account/sites/',
    editorAPI.dsRead.generalInfo.getMetaSiteId(),
    '/simple-app',
    '?app=wixcode',
    '&referralInfo=editor_platform',
  ].join('');
}

function getEditorMode(editorAPI) {
  const state = editorAPI.store.getState();
  return getDeviceType(state);
}

const loadedTime = window.mainLoaded || 0;
const getEditorReadyTimestamp = function () {
  return _.now() - loadedTime;
};

function getCurrentDomain() {
  const domainMatch = window.location.hostname.match(/editor\.(.*)/);

  // If could not extract domain, return default domain
  return domainMatch ? `www.${domainMatch[1]}` : 'www.wix.com';
}

function isSiteSaved(editorAPI) {
  return (
    !editorAPI.dsRead.generalInfo.isFirstSave() &&
    !editorAPI.dsRead.generalInfo.isDraft()
  );
}

function isSiteInDraftMode(editorAPI) {
  return editorAPI.dsRead.generalInfo.isDraft();
}

function getEditorReferrer() {
  return window.document.referrer;
}

function getConsentPolicy() {
  return window.consentPolicyManager
    ? window.consentPolicyManager.getCurrentConsentPolicy()
    : undefined;
}

function getCurrentViewport(editorAPI: EditorAPI) {
  const viewMode = editorAPI.dsRead.viewMode.get();

  return {
    type: viewMode,
    range:
      viewMode === editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE
        ? {
            min: 0,
            max: constants.ROOT_COMPS.MOBILE_EDITOR.MOBILE_FRAME_WIDTH,
          }
        : {
            min: constants.ROOT_COMPS.MOBILE_EDITOR.MOBILE_FRAME_WIDTH,
            max: Number.MAX_SAFE_INTEGER,
          },
  };
}

const isSilentInstall = (editorAPI: EditorAPI) =>
  editorAPI.platform.applications.isSilentInstallRunning();

export {
  getDashboardUrl,
  getBusinessManagerUrl,
  getEditorSessionId,
  getEditorReadyTimestamp,
  getEditorMode,
  getEditorReferrer,
  getConsentPolicy,
  getAscendUpgradeUrl,
  isSilentInstall,
  getCurrentViewport,
};
