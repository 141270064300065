// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Text, TextButton } from '@wix/wix-base-ui';

function template(props) {
  return (
    <div
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className="add-tooltip-pp add-tooltip-pp_se-madefor-font"
    >
      <div className="add-tooltip-pp_se-madefor-font__title">
        <Text
          skin="secondary"
          size="medium"
          weight="bold"
          enableEllipsis={false}
        >
          {props.title}
        </Text>
      </div>
      <div>
        <Text skin="secondary" size="small" enableEllipsis={false}>
          {props.description}
        </Text>
      </div>
      <div>
        <TextButton size="small" onClick={props.onLearnMore}>
          {props.learnMoreText || 'NewPages_Panel_Add_Folder_Learnmore'}
        </TextButton>
      </div>
    </div>
  );
}

const addTooltip = template;

addTooltip.displayName = 'addTooltip';
addTooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  learnMoreText: PropTypes.string,
  onLearnMore: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default addTooltip;
