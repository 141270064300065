export enum SegmentIndicationAreaKeys {
  Left = 'Left',
  Right = 'Right',
}

export enum SegmentType {
  Header = 'Header',
  Footer = 'Footer',
}

export const AUTOMATION_IDS = {
  label: 'site-segment-indication-label',
  getAreaHook: (key: SegmentIndicationAreaKeys) =>
    `site-segment-indication-${key}`,
};
