:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_e5yud_7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  padding-right: 24px;
  width: 460px;
  height: 60px;
  box-sizing: border-box; }

._icon_e5yud_17, ._iconTodo_e5yud_17, ._iconCompleted_e5yud_17,
._iconSkipped_e5yud_18 {
  flex: none;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: #116dff; }

._iconTodo_e5yud_17._iconDimmed_e5yud_25 {
  color: #bcbcbc; }

._iconCompleted_e5yud_17,
._iconSkipped_e5yud_18 {
  background: #e7f0ff;
  border-radius: 50%; }
  ._iconCompleted_e5yud_17._iconDimmed_e5yud_25,
  ._iconSkipped_e5yud_18._iconDimmed_e5yud_25 {
    color: #fff;
    background: #bcbcbc; }

._iconSkipped_e5yud_18 path {
  transform: scale(0.96); }

._title_e5yud_40 {
  flex: auto;
  min-width: 5em; }

._titleCrossed_e5yud_44 [data-hook='setup-step-title-text'] {
  text-decoration: line-through; }

._actions_e5yud_47 {
  flex: none;
  display: inline-flex;
  margin-left: auto;
  padding-left: 18px; }
  ._actions_e5yud_47 > *:not(:first-child) {
    margin-left: 12px; }

._actionButton_e5yud_55.btn-sm {
  font-size: 12px; }
