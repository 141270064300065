import * as util from '@/util';
import { fedopsLogger } from '@/util';
import type { ThunkAction, MapDispatchToProps } from 'types/redux';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';
import type {
  ConfirmPublishPanelDispatchProps,
  ConfirmPublishPanelOwnProps,
} from './confirmPublishPanel';
import type { EditorAPI } from '@/editorAPI';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  ConfirmPublishPanelDispatchProps,
  ConfirmPublishPanelOwnProps
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    closePanel: editorAPI.panelManager.closePanelByName,
    publishSiteIfAvailable: (origin: string) => {
      const isPublishAvailable =
        !editorAPI.savePublish.isSavePublishLocked() &&
        editorAPI.savePublish.canUserPublish();

      if (!isPublishAvailable) {
        return;
      }
      const shouldSaveBeforePublish =
        editorAPI.publishManager.shouldSaveBeforePublish() ||
        editorAPI.publishManager.hasOverriddenSaveAndPublish();

      const sourceOfStart: PublishInteractionStartedSource =
        'confirmPublishPanel_confirmButton';

      if (shouldSaveBeforePublish) {
        editorAPI.publishManager.saveAndPublish({
          origin,
          sourceOfStart,
        });
        return;
      }

      // NOTE: https://github.com/wix-private/santa-editor/issues/37029
      fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
        paramsOverrides: {
          sourceOfStart,
        },
      });

      editorAPI.publishManager.publish({
        origin,
        sourceOfStart,
      });
    },
  };
};

export const connectConfirmPublishPanel = <TComponentProps>(
  Component: React.ComponentClass<TComponentProps>,
) =>
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    null,
    mapDispatchToProps,
  )(util.hoc.renderWhenMutated(Component));
