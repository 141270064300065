import React, { useState } from 'react';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import { frames } from '@/panels';
import { RichText } from '@wix/wix-base-ui';
import constants from '@/constants';
import { translate } from '@/i18n';
import { Checkbox } from '@wix/wix-base-ui';
import { userPreferences } from '@/stateManagement';

import type { BIEventDefinition } from 'types/documentServices';
import type { MapDispatchToPropsFunction, ThunkAction } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';

const { MessagePanelFrame } = frames;
const { setSiteUserPreferences } = userPreferences.actions;
const { DONT_SHOW_AGAIN } =
  constants.USER_PREFS.DYNAMIC_PAGES.SUGGEST_ON_DUPLICATE;

interface BIEventParams {
  action_name: string;
  origin: string;
  is_first_data_modal: boolean;
}

interface SuggestDynamicPagePanelDispatchProps {
  sendBi: (biEvent: BIEventDefinition, biParamsParams: BIEventParams) => void;
  setDontShowAgainPreferences: (dontShowAgain: boolean) => void;
}

interface SuggestDynamicPagePanelOwnProps {
  panelName: string;
  wixDataEnabled: boolean;
  onPrimaryActionClick: () => Promise<void>;
  onSecondaryActionClick: () => void;
}

enum BI_ACTIONS {
  SHOW = 'show',
  CLOSE = 'close',
  CONVERT = 'click_convert',
  HELP = 'help_article',
  SELECT_DONT_SHOW_AGAIN = 'select_not_show_again',
  DESELECT_DONT_SHOW_AGAIN = 'deselect_not_show_again',
  DUPLICATE = 'duplicate',
}

const BI_ORIGIN = 'duplicate_1_page';

const SuggestDynamicPagePanel: React.FC<
  SuggestDynamicPagePanelOwnProps & SuggestDynamicPagePanelDispatchProps
> = ({
  panelName,
  wixDataEnabled,
  sendBi,
  onPrimaryActionClick,
  onSecondaryActionClick,
  setDontShowAgainPreferences,
}) => {
  const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false);

  const dispatchBiEvent = (actionName: BI_ACTIONS) => {
    sendBi(
      coreBi.events.pages.pagesPanel.convert_to_dynamic_on_duplicate_modal,
      {
        action_name: actionName,
        origin: BI_ORIGIN,
        is_first_data_modal: !wixDataEnabled,
      },
    );
  };

  const dontShowAgainHandler = (checked: boolean) => {
    setDontShowAgainChecked(checked);
    if (checked) {
      dispatchBiEvent(BI_ACTIONS.SELECT_DONT_SHOW_AGAIN);
    } else {
      dispatchBiEvent(BI_ACTIONS.DESELECT_DONT_SHOW_AGAIN);
    }
  };

  const setDontShowAgainIfChecked = () => {
    if (dontShowAgainChecked) {
      setDontShowAgainPreferences(true);
    }
  };

  const dontShowAgainCheckbox = (
    <Checkbox
      dataHook="SuggestDynamicPage-dont-show-again"
      label="Notification_Box_Dont_Show_Again"
      labelAfterSymbol
      value={dontShowAgainChecked}
      onChange={dontShowAgainHandler}
    />
  );

  return (
    <MessagePanelFrame
      dataHook="suggest-dynamic-page-panel"
      panelName={panelName}
      title={translate(
        'Pages_Actions_Page_Duplicate_Static_to_Dynamic_Modal_Title',
      )}
      illustration={
        <img
          src={util.media.getMediaUrl(
            'addPanelData/sections/dataBinding/contentManagerNew.png',
          )}
        />
      }
      confirmLabel={translate(
        'Pages_Actions_Page_Duplicate_Static_to_Dynamic_Modal_Primary_Button',
      )}
      onConfirm={() => {
        dispatchBiEvent(BI_ACTIONS.CONVERT);
        setDontShowAgainIfChecked();
        onPrimaryActionClick();
      }}
      cancelLabel={translate(
        'Pages_Actions_Page_Duplicate_Static_to_Dynamic_Modal_Secondary_Button',
      )}
      onSecondaryAction={() => {
        dispatchBiEvent(BI_ACTIONS.DUPLICATE);
        setDontShowAgainIfChecked();
        onSecondaryActionClick();
      }}
      onCancel={() => dispatchBiEvent(BI_ACTIONS.CLOSE)}
      sideActions={dontShowAgainCheckbox}
    >
      <RichText type="T01">
        <p>
          {translate(
            'Pages_Actions_Page_Duplicate_Static_to_Dynamic_Modal_Body',
          )}
        </p>
        <a
          href={translate(
            'Pages_Actions_Page_Duplicate_Static_to_Dynamic_Modal_Ur',
          )}
          target="_blank"
          onClick={() => dispatchBiEvent(BI_ACTIONS.HELP)}
        >
          {translate(
            'Pages_Actions_Page_Duplicate_Static_to_Dynamic_Modal_LinkText',
          )}
        </a>
      </RichText>
    </MessagePanelFrame>
  );
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToPropsFunction<
  SuggestDynamicPagePanelDispatchProps,
  SuggestDynamicPagePanelOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    sendBi: editorAPI.bi.event,
    setDontShowAgainPreferences: (newValue) =>
      dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN, newValue)),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(SuggestDynamicPagePanel);
