import React, { type FC } from 'react';
import {
  EditorOpenedPanelsProvider,
  type OpenedPanel,
} from '@wix/extensions-slots-host';

import * as reselect from 'reselect';

import { hoc } from '@/util';
import type { MapStateToProps } from 'types/redux';
import * as stateManagement from '@/stateManagement';

interface EditorOpenedPanelsStateProps {
  openedPanels: OpenedPanel[];
}

const getOpenedPanels = reselect.createSelector(
  stateManagement.panels.selectors.selectOpenPanels,
  (panels) =>
    panels.map(({ name, token }) => ({
      panelName: name,
      token,
    })),
);

const mapStateToProps: MapStateToProps<EditorOpenedPanelsStateProps> = ({
  state,
}) => ({
  openedPanels: getOpenedPanels(state),
});

const EditorOpenedPanelsStateProvider: FC<EditorOpenedPanelsStateProps> = (
  props,
) => {
  return (
    <EditorOpenedPanelsProvider openedPanels={props.openedPanels}>
      {props.children}
    </EditorOpenedPanelsProvider>
  );
};

const ConnectedEditorOpenedPanelsProvider = hoc.connect(
  hoc.STORES.STATE_ONLY,
  mapStateToProps,
)(EditorOpenedPanelsStateProvider);

export default ConnectedEditorOpenedPanelsProvider;
