// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['POPUPS_X_DesignPanel_More'],
      sections: [
        addPanel.sections.getSections().popup.popUpsCloseIconButtonsSection,
      ],
    };
  },
};
