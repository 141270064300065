import type { Category } from '@wix/add-panel-common';
import { myWidgetsAddPanelEnhancer } from './myWidgetsAddPanelEnhancer';

export interface AddPanelEnhanceDataOptions {
  myWidgets: any;
}

export const enhanceAddPanelData = (
  categories: Category[],
  addPanelEnhanceDataOptions: AddPanelEnhanceDataOptions,
): void => {
  myWidgetsAddPanelEnhancer(categories, addPanelEnhanceDataOptions);
};
