import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import promoteBi from '../common/bi/promoteBi';
import { biLogger } from '@/util';
import { PromoteScope } from '../../../scope';

import superApp from '../../superApp';

export interface OwnProps {}

type PromoteMenuPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

class PromoteMenuPanel extends React.Component<PromoteMenuPanelProps> {
  getSuperApp() {
    return superApp.getPromoteSuperApp();
  }

  render() {
    return (
      <superAppMenuInfra.superAppPanel
        panelClass="promote-panel"
        superApp={this.getSuperApp()}
        tabsWidth={201}
      />
    );
  }
}

const mapStateToProps = (scope: PromoteScope) => {
  const biBaseParams = promoteBi.biUtils.getBiBaseParams(scope);

  return {
    biBaseParams,
  };
};

const mapDispatchToProps = () => ({
  sendBi: (event: any, parameters: object) =>
    biLogger.report({ ...event, ...parameters }),
});

export default connectWithScope(
  () => PromoteScope,
  PromoteMenuPanel,
  mapStateToProps,
  mapDispatchToProps,
);
