export const CHAR_LIMIT_MAX_VALUE = 50;
export const CHAR_LIMIT_MAX_VALUE_FREE_TEXT = 350;

export const CHAR_LIMIT_MIN_THRESHOLD = 30;
export const CHAR_LIMIT_MIN_THRESHOLD_FREE_TEXT = 300;

export const getValidationMessage = ({
  value,
  isRequired,
  max,
}: {
  value: string;
  isRequired?: boolean;
  max?: number;
}): string => {
  if (isRequired && !value.length) {
    return 'ai_text_creator_panel_mandatory_field_tooltip_error';
  } else if (max && value.length > max) {
    return max === CHAR_LIMIT_MAX_VALUE
      ? 'ai_text_creator_panel_topic_tooltip_error'
      : 'ai_text_creator_panel_free_text_tooltip_error';
  }
  return '';
};

export const validateByKey = {
  businessType: (): string => '',
  businessName: (value: string) =>
    getValidationMessage({
      value,
      isRequired: false,
    }),
  businessDescription: (value: string) =>
    getValidationMessage({
      value,
      isRequired: true,
      max: CHAR_LIMIT_MAX_VALUE_FREE_TEXT,
    }),
};
