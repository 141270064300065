import React, { memo, type FC } from 'react';
import {
  TextInputMultiline,
  Composites,
  TextLabel,
  InfoIcon,
  CharLimit,
} from '@wix/wix-base-ui';

import styles from './freeTextInput.scss';

export interface FreeTextInputProps {
  label: string;
  placeholder?: string;
  value: string;
  maxValue?: number;
  minThreshold?: number;
  invalidMessage?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onTooltipOpen?: () => void;
}

const FreeTextInput: FC<FreeTextInputProps> = memo(function FreeTextInput({
  label,
  placeholder,
  value,
  maxValue = 300,
  minThreshold = 350,
  invalidMessage,
  onChange,
  onBlur,
  onTooltipOpen,
}) {
  return (
    <Composites.TextInputMultilineLabeled>
      <TextLabel type="T02" value={label} />
      <CharLimit
        maxValue={maxValue}
        value={value?.length}
        minThreshold={minThreshold}
      />
      {value?.length < minThreshold && (
        <InfoIcon
          small
          text="ai_template_injection_panel_free_text_tooltip_text"
          onTooltipOpen={onTooltipOpen}
        />
      )}
      <TextInputMultiline
        automationId="ai-free-text-input"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        isValid={!invalidMessage}
        invalidMessage={invalidMessage}
        hideSuccessIndication
        className={styles.input}
      />
    </Composites.TextInputMultilineLabeled>
  );
});

export { FreeTextInput };
