import constants from '@/constants';
import { bi } from '@/stateManagement';
import { contentManagerButtonOrigin, EVENTS } from '../../consts';
import { notifyDataBindingApplication } from '../../notifyApplication';
import { getHasDynamicPages } from '../../dynamicPages';
import { createCollectionInNotEmptyStateClicked, videoClicked } from '../../bi';
import { isDataModeOn } from '../../dataMode';
import {
  getIsPermittedToAddCollection,
  selectCollectionsLoaded,
} from '../../collections';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import { thButtonManageContentClickedCreateAPreset } from '@wix/bi-logger-editor/v2';
import { biLogger } from '@/util';

import type { ConnectorData } from '@wix/wix-data-connector-settings-client';
import type { EditorState } from '@/stateManagement';
import type { Schema } from '@wix/wix-data-schema-types';
import type {
  MapStateToProps,
  MapDispatchToProps,
  Dispatch,
  DispatchMapperArgs,
} from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { CollectionsGroup } from '../utils/collections-group';

export interface CollectionsListOwnProps {
  title: string;
  subtitle: string;
  collections: Schema[];
  showActionButtons: boolean;
  showVideoTooltip: boolean;
  collectionCount?: number;
  isUserCollectionsList?: boolean;
  selectTabByName: (tabName: string) => void;
  collectionsGroup?: CollectionsGroup;
  externalConnectors?: ConnectorData[];
  showFormsMigrationHelper?: boolean;
  ignoreGrouping?: boolean;
}

export interface CollectionsListStateProps {
  isLoading: boolean;
  hasDynamicPages: boolean;
  isPermittedToAddCollection: boolean;
}

export interface CollectionsListDispatchProps {
  openCreateCollectionPanel: () => void;
  openVideoPanel: (videoId: string, isEmptyState: boolean) => void;
  openContentManagerCollectionsList: (params?: {
    collectionsGroup?: string;
  }) => void;
  openAddPresetPanel: () => void;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
}

export const mapStateToProps: MapStateToProps<
  CollectionsListStateProps,
  CollectionsListOwnProps
> = ({ state, editorAPI }) => ({
  isLoading: !selectCollectionsLoaded(state) || !isDataModeOn(editorAPI),
  hasDynamicPages: getHasDynamicPages(editorAPI),
  isPermittedToAddCollection: getIsPermittedToAddCollection(editorAPI),
});

export const mapDispatchToProps: MapDispatchToProps<
  CollectionsListDispatchProps,
  CollectionsListOwnProps
> = (dispatch) => {
  const { editorAPI } = dispatch(
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      args: DispatchMapperArgs,
    ) => args,
  );
  const sendBi = (event: BiEventDefinition, params: BiEventFields) =>
    dispatch(bi.actions.event(event, params));

  return {
    openCreateCollectionPanel: () => {
      sendBi(createCollectionInNotEmptyStateClicked, null);
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.createCollectionButtonClicked,
        eventPayload: { origin: contentManagerButtonOrigin },
      });
    },
    openVideoPanel: (videoId, isEmptyState) => {
      editorAPI.panelManager.openPanel(
        constants.PANELS.PLAYER_PANEL,
        {
          videoId,
          origin: contentManagerButtonOrigin,
          videoName: 'manage_collections',
        },
        true,
      );

      sendBi(videoClicked, {
        tabName: 'content_manager',
        videoUrl: getYoutubeUrl(videoId),
        isEmptyState,
      });
    },
    openContentManagerCollectionsList: ({ collectionsGroup } = {}) =>
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openContentManagerCollectionsList,
        eventPayload: { origin: contentManagerButtonOrigin, collectionsGroup },
      }),

    openAddPresetPanel: () => {
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openAddPreset,
        eventPayload: { origin: contentManagerButtonOrigin },
      });
      biLogger.report(thButtonManageContentClickedCreateAPreset({}));
    },
    sendBi,
  };
};
