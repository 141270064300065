import _ from 'lodash';
import { EditorAPIKey } from '@/apis';
import * as util from '@/util';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import type { Shell } from '@/apilib';

const BASE_CONCURRENT_USERS_URL_ENDPOINT = `/concurrent-editing-session-watcher/watcher/report`;

export function createConcurrentUsersApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  if (
    !isResponsiveEditor() &&
    typeof BroadcastChannel === 'function' &&
    util.url.getParameterByName('disableSave') !== 'true'
  ) {
    const channel = new BroadcastChannel('tab');
    channel.postMessage(`Alive! msid: ${window.editorModel.metaSiteId}`);
    channel.addEventListener('message', (msg) => {
      if (
        msg.data.includes('Alive') &&
        msg.data.includes(window.editorModel.metaSiteId)
      ) {
        const { editorSessionId } = util.editorModel;
        editorAPI.bi.event(
          coreBi.events.concurrentUsers.DUPLICATE_TAB_OPENED_NAVIGATE_AWAY,
          {
            esi: editorSessionId,
          },
        );
        _.defer(() => {
          window.location.href = `https://manage.wix.com/dashboard/${window.editorModel.metaSiteId}/website-channel`;
        });
      }
    });
  }

  const AUTOPILOT_UPDATE_PANEL = 'panels.messagePanels.autopilotUpdatePanel';
  const AUTOPILOT_USER = 'auto_update_service@wix.com';
  const OPTIMUS_USER = 'optimus@wix.com';
  const urlEndpointTemplate = _.template(
    `${BASE_CONCURRENT_USERS_URL_ENDPOINT}?msid=<%= msid %>&esi=<%= esi %>`,
  );

  const CONCURRENT_USER_CHECK_INTERVAL_MS = 60 * 1000;

  let lastKnownConcurrentUsers: AnyFixMe = null;
  let timeoutPID: AnyFixMe = null;
  let isCheckRunning = false;
  let isFirstUser: AnyFixMe = null;
  let isUserLoggedIn = true;
  let sentBI = false;

  function shouldCheckForConcurrentUsers() {
    const savePublishSelectors = stateManagement.savePublish.selectors;
    return _.defaultTo(
      savePublishSelectors.getOverridingShouldCheckForConcurrentUsers(
        editorAPI.store.getState(),
      ),
      true,
    );
  }

  function checkForConcurrentUsers(isFirstConcurrencyCheck: AnyFixMe) {
    if (!shouldCheckForConcurrentUsers()) {
      return;
    }
    if (!doesUserNameMatchCookieValue()) {
      stopConcurrentUsersCheck();
    } else if (isSiteATemplate()) {
      timeoutPID = window.setTimeout(function () {
        checkForConcurrentUsers(isFirstConcurrencyCheck);
      }, CONCURRENT_USER_CHECK_INTERVAL_MS);
    } else {
      timeoutPID = window.setTimeout(
        function () {
          const handler = _.partial(
            handleConcurrentUser,
            isFirstConcurrencyCheck,
          );
          getUsersEditingSiteInfo(handler);
        },
        isFirstConcurrencyCheck ? 0 : CONCURRENT_USER_CHECK_INTERVAL_MS,
      );
    }
  }

  function doesUserNameMatchCookieValue() {
    const userInfo = editorAPI.dsRead.generalInfo.getUserInfo();
    const wixClientCookieValue = util.cookie.getCookie('wixClient');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return userInfo?.name && _.includes(wixClientCookieValue, userInfo.name);
  }

  function isSiteATemplate() {
    return (
      editorAPI.dsRead.generalInfo.isFirstSave() ||
      editorAPI.dsRead.generalInfo.isDraft()
    );
  }

  function isOptimusUser(concurrentUsers: string[]) {
    return !!concurrentUsers.find((user) => user === OPTIMUS_USER);
  }

  function isAutopilotUser(concurrentUsers: string[]) {
    return !!concurrentUsers.find((user) => user === AUTOPILOT_USER);
  }

  function handleConcurrentUser(
    isFirstConcurrencyCheck: boolean,
    concurrentUsers: string[],
  ) {
    if (isFirstConcurrencyCheck && isFirstUser === null) {
      isFirstUser = _.isEmpty(concurrentUsers);
    }
    if (
      !_.isEmpty(concurrentUsers) &&
      !doesFirstArrayContainSecond(lastKnownConcurrentUsers, concurrentUsers)
    ) {
      const unknownConcurrentUser = getUnknownUserToDisplay(
        lastKnownConcurrentUsers,
        concurrentUsers,
      );
      lastKnownConcurrentUsers = concurrentUsers;
      if (isAutopilotUser(concurrentUsers) || isOptimusUser(concurrentUsers)) {
        if (
          !isFirstUser &&
          !window.queryUtil.isParameterTrue('disableUpdateInProgressModal')
        ) {
          editorAPI.panelManager.openPanel(AUTOPILOT_UPDATE_PANEL);
        }
      } else {
        const concurrentUserState = {
          isFirstUser,
          concurrentUsers,
          concurrentUserToDisplay: unknownConcurrentUser,
        };

        if (!sentBI) {
          sentBI = true;
          editorAPI.bi.event(
            coreBi.events.concurrentUsers.CONCURRENT_USER_EDITING,
            {
              isSameUser: !concurrentUsers.length,
            },
          );
        }

        editorAPI.store.dispatch(
          stateManagement.concurrentUsers.actions.updateConcurrentUsers(
            concurrentUserState,
          ),
        );
      }
    }
    if (isUserLoggedIn) {
      checkForConcurrentUsers(false);
    }
  }

  function getUnknownUserToDisplay(
    knownUsersList: AnyFixMe,
    usersList: AnyFixMe,
  ): AnyFixMe {
    const valueSet: Record<string, AnyFixMe> = {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(knownUsersList, function (knownUser) {
      valueSet[knownUser] = valueSet;
    });
    let unknownUser = null;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(usersList, function (user) {
      if (!valueSet[user]) {
        unknownUser = user;
        return false;
      }
    });
    return unknownUser;
  }

  function doesFirstArrayContainSecond(array1: AnyFixMe, array2: AnyFixMe) {
    if (array1 === array2) {
      return true;
    } else if (
      (array1 && !array2) ||
      (!array1 && array2) ||
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/size
      _.size(array1) < _.size(array2)
    ) {
      return false;
    }
    const valuesSet: Record<string, AnyFixMe> = {};
    const containingSetSize = array1.length;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(array1, function (val) {
      valuesSet[val] = val;
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(array2, function (val) {
      valuesSet[val] = val;
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/size
    return containingSetSize === _.size(valuesSet);
  }

  function getUsersEditingSiteInfo(callback: AnyFixMe) {
    if (callback) {
      const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
      const { editorSessionId } = util.editorModel;
      performConcurrencyQuery(metaSiteId, editorSessionId, callback);
    }
  }

  function performConcurrencyQuery(
    msid: AnyFixMe,
    esi: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const USER_LOGGED_OUT = -15;
    const EXPIRED_OR_INVALID_SESSION = -12;
    const OFAC_COUNTRY_INVALID_SESSION = -16;
    const urlToQuery = urlEndpointTemplate({ msid, esi });
    let concurrentUsers: AnyFixMe = null;

    util.http
      .fetchJson(urlToQuery)
      .then((data: AnyFixMe) => {
        if (data) {
          if (
            !data.success &&
            (data.errorCode === USER_LOGGED_OUT ||
              data.errorCode === EXPIRED_OR_INVALID_SESSION ||
              data.errorCode === OFAC_COUNTRY_INVALID_SESSION)
          ) {
            isUserLoggedIn = false;
            stopConcurrentUsersCheck();
          }
          concurrentUsers = data.otherUsersOnSite || null;
        }
        callback(concurrentUsers);
      })
      .catch(() => callback(concurrentUsers));
  }

  function startConcurrentUsersCheck() {
    isUserLoggedIn = true;
    if (!isCheckRunning) {
      isCheckRunning = true;
      checkForConcurrentUsers(true);
    }
  }

  function stopConcurrentUsersCheck() {
    if (timeoutPID) {
      window.clearTimeout(timeoutPID);
      timeoutPID = null;
    }
    lastKnownConcurrentUsers = null;
    isCheckRunning = false;
  }

  return {
    checkForConcurrentUsers: startConcurrentUsersCheck,
    stopCheckForConcurrentUsers: stopConcurrentUsersCheck,
    BASE_CONCURRENT_USERS_URL_ENDPOINT,
  };
}
