import React, { useEffect } from 'react';
import { cx, fedopsLogger } from '@/util';
import styles from './addPresetPreviewerPreview.scss';

import type { EditorInteractionName } from 'types/fedops';
import type { ExtraPreviewProps } from '../types';
interface AddPresetPreviewerPreviewProps extends ExtraPreviewProps {
  id: string;
  displayMinHeight?: number;
  interactionKey: EditorInteractionName;
  previewComponent: React.ReactElement;
  isPresetReady?: boolean;
  isBackgroundTransparent?: boolean;
}

const AddPresetPreviewerPreview = React.memo(
  ({
    id,
    displayMinHeight,
    interactionKey,
    previewComponent,
    isPresetReady,
    isBackgroundTransparent,
  }: AddPresetPreviewerPreviewProps) => {
    useEffect(() => {
      fedopsLogger.interactionStarted(interactionKey, {
        customParams: { presetId: id },
      });
    }, [interactionKey, id]);

    const getPresetPreviewStyle = (): React.CSSProperties => {
      const style: React.CSSProperties = {};
      if (displayMinHeight && !previewComponent) {
        style.minHeight = displayMinHeight;
      }
      if (isBackgroundTransparent) {
        style.backgroundColor = 'transparent';
      }
      return style;
    };

    return (
      <div
        className={styles['add-preset-previewer-preview']}
        style={getPresetPreviewStyle()}
      >
        <div
          className={cx(styles['add-preset-previewer-preview-wrapper'], {
            [styles.loading]: !previewComponent || !isPresetReady,
          })}
        >
          {previewComponent}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.isPresetReady && nextProps.isPresetReady;
  },
);

AddPresetPreviewerPreview.displayName = 'AddPresetPreviewerPreview';

export default AddPresetPreviewerPreview;
