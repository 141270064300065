import React, { type FunctionComponent } from 'react';
import { WithRestrictionsRender } from '@/editorRestrictions';

import * as util from '@/util';

import Button from '../topBarButton/topBarButtonWithTooltip';
import TooltipContent from '../tooltipContent';

import connect from './deviceSwitchConnect';
import type {
  DeviceSwitchStateProps,
  DeviceSwitchDispatchProps,
} from './deviceSwitchConnect';

interface DeviceSwitchProps
  extends DeviceSwitchStateProps,
    DeviceSwitchDispatchProps {}

const DeviceSwitch: FunctionComponent<DeviceSwitchProps> = (props) => {
  const deviceSwitchClassName = util.cx({
    'top-bar-device-switch': true,
    'top-bar-device-switch_desktop': !props.isMobileEditor,
    'top-bar-device-switch_mobile': props.isMobileEditor,
  });

  const desktopBtnClassName = util.cx({
    'top-bar-btn-device': true,
    desktop: true,
  });
  const mobileBtnClassName = util.cx({
    'top-bar-btn-device': true,
    mobile: true,
  });

  const getDesktopBtnTooltipData = () => {
    if (props.isFixedStageEnabled) {
      return {
        title: 'TOPBAR_DESKTOP_TOOLTIP_TITLE_FLUID',
        text: 'TOPBAR_DESKTOP_TOOLTIP_BODY_FLUID',
      };
    }
    return {
      title: props.isMobileEditor
        ? 'TOPBAR_DESKTOP_TOOLTIP_TITLE'
        : 'TOPBAR_DESKTOP_TITLE_NEW',
      text: 'TOPBAR_DESKTOP_TOOLTIP_BODY',
    };
  };
  const getMobileBtnTooltipData = () => {
    if (props.isFixedStageEnabled) {
      return {
        title: 'TOPBAR_MOBILE_TOOLTIP_TITLE_FLUID',
        text: 'TOPBAR_MOBILE_TOOLTIP_BODY_FLUID',
      };
    }
    return {
      title: props.isMobileEditor
        ? 'TOPBAR_MOBILE_TITLE_NEW'
        : 'TOPBAR_MOBILE_TOOLTIP_TITLE_NEW',
      text: 'TOPBAR_MOBILE_TOOLTIP_BODY_NEW',
    };
  };
  const mobileSymbolName = util.workspace.isNewWorkspaceEnabled()
    ? 'topBarMobile_NewWorkspace'
    : 'topBarNewMobile';
  const desktopSymbolName = util.workspace.isNewWorkspaceEnabled()
    ? 'topBarDesktop_NewWorkspace'
    : 'topBarNewDesktop';
  const desktopBtnClickHandler = () => {
    if (props.isMobileEditor) {
      props.toggleMobileEditor(
        false,
        'topBar_switchToDesktop',
        'editor_view_mode_desktop',
      );
    }
  };
  const mobileBtnClickHandler = () => {
    if (!props.isMobileEditor) {
      props.toggleMobileEditor(
        true,
        'topBar_switchToMobile',
        'editor_view_mode_mobile',
      );
    }
  };

  return (
    <div
      className={deviceSwitchClassName}
      data-aid="top-bar-device-switch-section"
    >
      <Button
        automationId="top-bar-button-switch-mode-desktop"
        toggled={!props.isMobileEditor}
        symbolName={desktopSymbolName}
        className={desktopBtnClassName}
        onClick={desktopBtnClickHandler}
        tooltip={TooltipContent({
          tooltipData: getDesktopBtnTooltipData(),
        })}
      />
      <WithRestrictionsRender
        name={
          props.isPreviewMode
            ? 'top-bar_mobile-device-switch-preview'
            : 'top-bar_mobile-device-switch-edit'
        }
      >
        {({ disabled }) => (
          <Button
            automationId="top-bar-button-switch-mode-mobile"
            toggled={props.isMobileEditor}
            disabled={
              props.isGoToMobileDisabled || (disabled && !props.isPreviewMode)
            }
            symbolName={mobileSymbolName}
            className={mobileBtnClassName}
            onClick={mobileBtnClickHandler}
            tooltip={
              disabled && !props.isPreviewMode
                ? null
                : TooltipContent({
                    tooltipData: getMobileBtnTooltipData(),
                  })
            }
          />
        )}
      </WithRestrictionsRender>
    </div>
  );
};

export default connect(DeviceSwitch);
