import type { EditorAPI } from '@/editorAPI';
import { addPanelAddCompService } from '@/tpa';
import type { CompStructure } from 'types/documentServices';

export interface AddCompOnClickParams {
  appDefinitionId: string;
  widgetId: string;
  compStructure: CompStructure;
  tags: string;
  itemId: string;
  sectionTitle: string;
  categoryId: string;
  biOrigin: string;
  position: { x: number; y: number } | void;
}

export const addCompOnClick = (
  editorAPI: EditorAPI,
  {
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    sectionTitle,
    categoryId,
    biOrigin,
    position,
  }: AddCompOnClickParams,
) =>
  addPanelAddCompService.addCompOnClick(
    editorAPI,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    {
      origin: biOrigin,
      sectionTitle,
      category: categoryId,
    },
    { position },
  );

export interface AddCompOnDropParams {
  appDefinitionId: string;
  widgetId: string;
  biOrigin: string;
}

export const addCompOnDrop =
  (
    editorAPI: EditorAPI,
    { appDefinitionId, widgetId, biOrigin }: AddCompOnDropParams,
  ) =>
  (coords: AnyFixMe, itemData: AnyFixMe, parentRef: AnyFixMe) =>
    addPanelAddCompService.addCompOnDrop(
      editorAPI,
      appDefinitionId,
      widgetId,
      coords,
      itemData,
      parentRef,
      () => {},
      { origin: biOrigin },
    );
