// @ts-nocheck
import React from 'react';

export default class extends React.Component<any> {
  static displayName = 'prefetch';

  render() {
    return <link rel="prefetch prerender" href={this.getHref()} />;
  }

  getHref = () => {
    if (this.props.base) {
      return `${this.props.base.replace(/\/$/, '')}/${this.props.href.replace(
        /^\//,
        '',
      )}`;
    }
    return this.props.href;
  };
}
