import React from 'react';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import {
  Checkbox,
  Composites,
  InfoIcon,
  Text as TextBaseUI,
  TextButton,
} from '@wix/wix-base-ui';
import * as panels from '@/panels';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { PanelResolveType } from '@wix/platform-editor-sdk';

const CheckboxWithInfoIcon = Composites.CheckboxWithInfoIcon;
const Text: AnyFixMe = TextBaseUI;

class PlatformUninstallConfirmationPanel extends React.Component<
  PlatformUninstallConfirmationPanelProps,
  PlatformUninstallConfirmationPanelState
> {
  private dependenciesAppsObject: DependenciesAppsObject[];
  constructor(props: AnyFixMe) {
    super(props);
    const appsToDelete: AnyFixMe = {};
    this.dependenciesAppsObject = props
      .getInstalledAppsDependency(this.props.appDefinitionId)
      .map((dep: AnyFixMe) => {
        const appData = props.getAppData(dep.appDefinitionId);
        appsToDelete[appData.appDefinitionId] = !!dep.isRequired;
        return {
          label: appData.appDefinitionName,
          disabled: dep.isRequired,
          appDefinitionId: appData.appDefinitionId,
        };
      });
    this.state = {
      appsToDelete,
    };
  }
  static defaultProps = {
    secondaryActionText: translate('PLATFORM_Delete_Modal_Apps_Cancel'),
    headerText: 'PLATFORM_Delete_Modal_Apps_Breadcrumb',
    bottomDescriptionText: translate('PLATFORM_Delete_Modal_Apps_Text'),
    titleText: translate('PLATFORM_Delete_Modal_Apps_Title'),
    learnMore: translate('PLATFORM_Delete_Modal_Apps_Learn_More'),
    premiumTextCTA: translate(
      'PLATFORM_Delete_Modal_Multiple_Apps_Premium_Text_CTA',
    ),
    mainActionText: translate('PLATFORM_Delete_Modal_Apps_Delete'),
    premiumLink: 'https://premium.wix.com/wix/api/PaymentConsole',
  };

  getKeyboardShortcuts = () => ({
    esc: () => this.props.closePanel(ErrorPanelResolveType.CLOSE_ACTION),
  });

  secClick = (origin?: string) => {
    if (
      origin === panels.PanelCloseOrigin.HEADER_CLOSE_BUTTON ||
      origin === panels.PanelCloseOrigin.ESCAPE_PRESS
    ) {
      return this.props.closePanel(PanelResolveType.CLOSE_ACTION);
    }
    this.props.closePanel(ErrorPanelResolveType.SECONDARY_ACTION);
  };

  mainClick = () => {
    this.props.closePanel({
      status: ErrorPanelResolveType.MAIN_ACTION,
      appsToDelete: Object.keys(this.state.appsToDelete).filter(
        (appDefId) => this.state.appsToDelete[appDefId],
      ),
    });
  };

  getKeyWithAppName = (key: string, appName?: string) =>
    translate(key, {
      App_Name: appName || this.props.appName,
    });

  toggleAppChange = (app: AnyFixMe, checkValue: AnyFixMe) => {
    const { appsToDelete } = this.state;
    appsToDelete[app.appDefinitionId] = checkValue;
    this.setState({
      appsToDelete,
    });
  };

  getTopDescriptionText = (isMultipleAppsUninstall: AnyFixMe) => (
    <div data-aid="top-description-text" className="textLabel">
      {isMultipleAppsUninstall ? (
        <>
          <div>{translate('PLATFORM_Delete_Modal_Multiple_Apps_Text1')}</div>{' '}
          <TextButton
            enableEllipsis={false}
            onClick={() => this.props.openHelpCenter()}
          >
            {'PLATFORM_Delete_Modal_Multiple_Apps_Text1_Learn_More'}
          </TextButton>
        </>
      ) : (
        <div data-aid="title-text">
          {`${this.props.titleText}`}
          <span className="appName">{` ${this.getKeyWithAppName(
            'PLATFORM_Delete_Modal_Apps_Title_AppName',
          )}`}</span>
        </div>
      )}
    </div>
  );

  getBottomDescriptionText = (isMultipleAppsUninstall: AnyFixMe) => (
    <div data-aid="bottom-description-text" className="textLabel">
      {isMultipleAppsUninstall ? (
        `${this.getKeyWithAppName('PLATFORM_Delete_Modal_Multiple_Apps_Text2')}`
      ) : (
        <>
          <div>{this.props.bottomDescriptionText}</div>
          <TextButton
            enableEllipsis={false}
            shouldTranslate={false}
            onClick={() => this.props.openHelpCenter()}
          >
            {` ${this.props.learnMore}`}
          </TextButton>
        </>
      )}
    </div>
  );

  getCheckBoxes = (isMultipleAppsUninstall: AnyFixMe) =>
    isMultipleAppsUninstall ? (
      <Composites.Checkboxes>
        {this.dependenciesAppsObject.map((app) => (
          <CheckboxWithInfoIcon key={app.label}>
            {app.disabled ? (
              <InfoIcon
                shouldTranslate={false}
                text={this.getKeyWithAppName(
                  'PLATFORM_Delete_Modal_Multiple_Apps_Checkbox_Tooltip',
                  app.label,
                )}
              />
            ) : null}
            <Checkbox
              value={this.state.appsToDelete[app.appDefinitionId]}
              disabled={app.disabled}
              label={this.getKeyWithAppName(
                'PLATFORM_Delete_Modal_Multiple_Apps_Checkbox_Text',
                app.label,
              )}
              dataHook={`uninstall-confirmation-checkbox-app-change-${app.appDefinitionId}`}
              onChange={(checkValue: AnyFixMe) =>
                this.toggleAppChange(app, checkValue)
              }
              labelAfterSymbol={true}
              shouldTranslate={false}
            />
          </CheckboxWithInfoIcon>
        ))}
      </Composites.Checkboxes>
    ) : null;

  getPanelHeader = (isMultipleAppsUninstall: AnyFixMe) =>
    isMultipleAppsUninstall
      ? this.getKeyWithAppName('PLATFORM_Delete_Modal_Multiple_Apps_Title')
      : translate(this.props.headerText);

  render() {
    return (
      <panels.frames.MessagePanelFrame
        dataHook="platform-uninstall-confirmation-panel"
        className="platform-uninstall-confirmation-panel-new-workspace"
        panelName={this.props.panelName}
        cancelOnEscPress={true}
        onConfirm={this.mainClick}
        confirmLabel={this.props.mainActionText}
        title={this.getPanelHeader(this.dependenciesAppsObject.length)}
        illustration={
          <symbols.symbol
            name="deleteGeneralIcon_NewWorkspace"
            className="symbol"
          />
        }
        onHelp={this.props.openHelpCenter}
        onCancel={this.secClick}
        cancelLabel={this.props.secondaryActionText}
        footnote={
          this.props.isPremium(this.props.applicationId) && (
            <Text
              skin="secondary"
              automationId="premium-uninstall-banner"
              size="small"
              shouldTranslate={false}
            >
              {`${this.getKeyWithAppName(
                'PLATFORM_Delete_Modal_Multiple_Apps_Premium_Text',
              )}
              `}
              <a href={this.props.premiumLink} target="_blank">
                {` ${this.props.premiumTextCTA}`}
              </a>
            </Text>
          )
        }
        theme="destructive"
        cancelOnClickOutside={false}
      >
        <Text enableEllipsis={false} shouldTranslate={false}>
          {this.getTopDescriptionText(this.dependenciesAppsObject.length)}
          {this.getBottomDescriptionText(this.dependenciesAppsObject.length)}
          {this.getCheckBoxes(this.dependenciesAppsObject.length)}
        </Text>
      </panels.frames.MessagePanelFrame>
    );
  }
}

const mapStateToProps = ({ dsRead }: AnyFixMe) => {
  const isPremium = (applicationId: AnyFixMe) =>
    dsRead.tpa.app.isPremium(applicationId);
  const getInstalledAppsDependency = (appDefinitionId: AnyFixMe) =>
    stateManagement.platform.selectors.getInstalledAppsDependency(
      dsRead,
      appDefinitionId,
    );
  const getAppData = (appDefinitionId: AnyFixMe) =>
    dsRead.platform.getAppDataByAppDefId(appDefinitionId);

  return { isPremium, getInstalledAppsDependency, getAppData };
};

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const helpId = 'e54ed9ea-4095-4b72-9727-43a446eafd05';
  const closePanel = (origin: AnyFixMe) => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    ownProps.onPanelClose(origin);
  };

  const openHelpCenter = () =>
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId));

  return {
    closePanel,
    openHelpCenter,
  };
};

enum ErrorPanelResolveType {
  MAIN_ACTION = 'mainActionClicked',
  SECONDARY_ACTION = 'secActionClicked',
  CLOSE_ACTION = 'closeActionClicked',
}

interface InstalledAppsDependencyInfo {
  app: object;
  isRequired: boolean;
}

interface PlatformUninstallConfirmationPanelProps {
  closePanel(data: AnyFixMe): void;
  openHelpCenter(): void;
  headerText: string;
  titleText: string;
  bottomDescriptionText: string;
  learnMore: string;
  premiumTextCTA: string;
  secondaryActionText: string;
  mainActionText: string;
  onPanelClose(): void;
  panelName: string;
  appName: string;
  premiumLink: string;
  applicationId: string;
  appDefinitionId: string;
  isPremium(string: string): boolean;
  getInstalledAppsDependency(
    appDefinitionId: string,
  ): InstalledAppsDependencyInfo[];
  getAppData(string: string): object;
}

interface PlatformUninstallConfirmationPanelState {
  appsToDelete: AnyFixMe;
}

interface DependenciesAppsObject {
  label: string;
  disabled: boolean;
  appDefinitionId: string;
}

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(PlatformUninstallConfirmationPanel);
