:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._block_l1grl_7 {
  position: relative;
  background: #fff;
  border-radius: 0;
  padding: 24px 0;
  margin: 0 0 12px;
  box-shadow: 0 0 20px 0 rgba(22, 45, 61, 0.03);
  text-decoration: none; }
  ._block_l1grl_7 ._blockTitle_l1grl_15 {
    margin-bottom: 16px;
    font-weight: 500;
    padding: 0 24px; }
    ._block_l1grl_7 ._blockTitle_l1grl_15 ._blockTitleLabel_l1grl_19 {
      color: #000624; }
