const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_24",
  preset: { rect: { width: 141, height: 75, x: 183, y: 670 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: value(site_1_1);\n    border-radius: 50px;\n    border: 2px solid #C74300\n}\n.root:hover{\n    border-radius: 20px;\n    background: #C74300;\n    border: 2px solid #C74300\n}\n.root:hover::icon{\n    width: 26px;\n    height: 26px\n}\n.root:hover::label {\n    color: #FFFFFF;\n}\n.root[disabled]{\n    background: #E2E2E2\n}\n.root[disabled]::label{\n    color: #8F8F8F\n}\n.root[disabled]::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    font-size: 16px;\n    display: initial;\n    font-family: futura-lt-w01-light,sans-serif;\n    color: #C74300;\n    letter-spacing: 0.05em\n}\n.root::icon{\n    transition: inherit;\n    fill: #8B6755;\n    width: 20px;\n    height: 20px;\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-528",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "hmj-989",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_get_started"),
      svgId: "11062b_a67643df07304d9bb39d3653dac8bb91.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmdekmzo",
    layout: {
      x: 195,
      fixedPosition: false,
      y: 687,
      scale: 1,
      height: 42,
      rotationInDegrees: 0,
      width: 117,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-943", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-333", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
