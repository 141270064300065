import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

import React from 'react';
import TextEffectButton from './textEffectButton';
import * as UI from '@/baseUI';
import constants from '@/constants';

export interface TextEffectsProps {
  value: string;
  onChange: (textEffect: string) => void;
  shadowEffects?: AnyFixMe;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<TextEffectsProps>({
  displayName: 'textEffects',
  mixins: [baseUI.reportUIChangeMixin],
  render() {
    return (
      <div className="text-effect-container">
        <UI.tooltip
          value="text_editor_effects_tooltip_no_effect"
          styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
        >
          <span
            onClick={() => {
              this.handleOnChange('effect_0');
            }}
            className={`text-none-icon ${
              this.props.value === 'EFFECT_0' ? 'selected' : ''
            }`}
          />
        </UI.tooltip>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.getShadowEffects(), (effect, effectIndex) => (
          <TextEffectButton
            // @ts-expect-error
            isSelected={this.props.value === effectIndex}
            onClick={() => {
              this.handleOnChange(effectIndex);
            }}
            key={effectIndex}
            className={effectIndex}
          >
            <span
              style={{
                textShadow: effect.cssData,
              }}
              className="text-shadow-effect"
            >
              {translate(`text_editor_${effectIndex}`)}
            </span>
          </TextEffectButton>
        ))}
      </div>
    );
  },
  getDefaultProps() {
    return {
      value: 'effect_0',
      onChange() {},
    };
  },
  contextTypes: {
    reportUIChange: PropTypes.func,
  },
  getShadowEffects() {
    const effects = _.clone(this.props.shadowEffects);
    if (effects.effect_0) {
      delete effects.effect_0;
    }
    return effects;
  },
  handleOnChange(textEffect: AnyFixMe) {
    this.props.onChange(textEffect);
    const biValue = textEffect === 'effect_0' ? 'none' : textEffect;
    this.reportUIChange({ value: biValue, control_name: 'text_effects' });
  },
});
