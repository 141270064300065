// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

const FORBIDDEN_MOVE_TO_COMPONENTS = [
  'wysiwyg.viewer.components.MenuToggle',
  'wysiwyg.viewer.components.MenuContainer',
];

function setLayoutInThMiddleOfTheContainer(editorAPI, comp, container) {
  const containerLayout = editorAPI.components.layout.get_size(container);
  const compLayout = editorAPI.components.layout.get_size(comp);
  editorAPI.components.layout.update(comp, {
    y: (containerLayout.height - compLayout.height) / 2,
    x: (containerLayout.width - compLayout.width) / 2,
  });
}

function scrollToComponentThenSelectIt(editorAPI, comp) {
  const layout = editorAPI.components.layout.getRelativeToStructure(comp);
  editorAPI.ui.scroll.animateScroll(
    { x: 0, y: Math.max(layout.y - 30, 0) },
    0.5,
    () => {
      editorAPI.selection.selectComponentByCompRef(comp);
    },
  );
}

function moveCompToSiteSegments(
  editorAPI,
  comp,
  containerToMoveTo,
  isDescendantOfMenuContainer,
) {
  editorAPI.selection.deselectComponents(comp);
  editorAPI.closeRightClickMenu();
  const newCompPointer = editorAPI.components.setContainer(
    comp,
    containerToMoveTo,
    true,
  );

  editorAPI.dsActions.waitForChangesApplied(function () {
    setLayoutInThMiddleOfTheContainer(
      editorAPI,
      newCompPointer,
      containerToMoveTo,
    );
    editorAPI.dsActions.waitForChangesApplied(function () {
      if (isDescendantOfMenuContainer) {
        const menuContainer =
          editorAPI.documentServices.mobile.menuContainer.get();
        editorAPI.store
          .dispatch(
            stateManagement.inlinePopup.actions.close(menuContainer, true),
          )
          .then(() => {
            scrollToComponentThenSelectIt(editorAPI, comp);
          });
      } else {
        scrollToComponentThenSelectIt(editorAPI, comp);
      }
    });
  });
}

async function reparentToMenuContainer(editorAPI, component, menuContainer) {
  editorAPI.selection.deselectComponents();
  await editorAPI.store.dispatch(
    stateManagement.inlinePopup.actions.open(menuContainer, true),
  );

  const newCompPointer = editorAPI.components.setContainer(
    component,
    menuContainer,
    true,
  );
  await editorAPI.waitForChangesAppliedAsync();

  setLayoutInThMiddleOfTheContainer(editorAPI, newCompPointer, menuContainer);

  await editorAPI.waitForChangesAppliedAsync();
  editorAPI.selection.selectComponentByCompRef(component);
}

function moveToMenu(editorAPI, component, menuContainer, isDescendantOfFooter) {
  editorAPI.selection.deselectComponents(component);
  editorAPI.closeRightClickMenu();

  if (isDescendantOfFooter) {
    editorAPI.ui.scroll.animateScroll({ x: 0, y: 0 }, 0.5, () => {
      reparentToMenuContainer(editorAPI, component, menuContainer);
    });
  } else {
    reparentToMenuContainer(editorAPI, component, menuContainer);
  }
}

function getMoveToFooterItem(
  editorAPI,
  component,
  footerComp,
  isDescendantOfHeader,
  isDescendantOfMenuContainer,
) {
  const isContainableInFooterContainer = editorAPI.components.is.containable(
    component,
    footerComp,
  );
  const canBeMovedToFooter =
    isContainableInFooterContainer &&
    (isDescendantOfHeader || isDescendantOfMenuContainer);

  if (!canBeMovedToFooter) return null;

  return {
    moveToFooter: {
      icon: editorAPI.components.getIconInfo(footerComp),
      enabled: true,
      onClick: moveCompToSiteSegments.bind(
        this,
        editorAPI,
        component,
        footerComp,
        isDescendantOfMenuContainer,
      ),
    },
  };
}

function getMoveToHeaderItem(
  editorAPI,
  component,
  headerComp,
  isDescendantOfFooter,
  isDescendantOfMenuContainer,
) {
  const isContainableInHeaderContainer = editorAPI.components.is.containable(
    component,
    headerComp,
  );
  const canBeMovedToHeader =
    isContainableInHeaderContainer &&
    (isDescendantOfFooter || isDescendantOfMenuContainer);

  if (!canBeMovedToHeader) return null;

  return {
    moveToHeader: {
      icon: editorAPI.components.getIconInfo(headerComp),
      enabled: true,
      onClick: moveCompToSiteSegments.bind(
        this,
        editorAPI,
        component,
        headerComp,
        isDescendantOfMenuContainer,
      ),
    },
  };
}

function getMoveToMenuItem(
  editorAPI,
  component,
  menuContainer,
  isDescendantOfMenuContainer,
  isDescendantOfHeader,
  isDescendantOfFooter,
) {
  const isContainableInMenuContainer = editorAPI.components.is.containable(
    component,
    menuContainer,
  );
  const canBeMovedToMenu =
    !isDescendantOfMenuContainer &&
    isContainableInMenuContainer &&
    (isDescendantOfHeader || isDescendantOfFooter);

  if (!canBeMovedToMenu) return null;

  return {
    moveToMenu: {
      icon: { svgName: 'tinyMenu' },
      enabled: true,
      onClick: moveToMenu.bind(
        this,
        editorAPI,
        component,
        menuContainer,
        isDescendantOfFooter,
      ),
    },
  };
}

function getMoveToSubItems(editorAPI, component) {
  const footerComp = editorAPI.dsRead.siteSegments.getFooter();
  const headerComp = editorAPI.dsRead.siteSegments.getHeader();
  const menuContainer = editorAPI.documentServices.mobile.menuContainer.get();
  const isDescendantOfFooter = editorAPI.components.isDescendantOfComp(
    component,
    footerComp,
  );
  const isDescendantOfHeader = editorAPI.components.isDescendantOfComp(
    component,
    headerComp,
  );
  const isDescendantOfMenuContainer = editorAPI.components.isDescendantOfComp(
    component,
    menuContainer,
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(
    {},
    getMoveToMenuItem(
      editorAPI,
      component,
      menuContainer,
      isDescendantOfMenuContainer,
      isDescendantOfHeader,
      isDescendantOfFooter,
    ),
    getMoveToHeaderItem(
      editorAPI,
      component,
      headerComp,
      isDescendantOfFooter,
      isDescendantOfMenuContainer,
    ),
    getMoveToFooterItem(
      editorAPI,
      component,
      footerComp,
      isDescendantOfHeader,
      isDescendantOfMenuContainer,
    ),
  );
}

function createMoveToItem(editorAPI, component) {
  if (
    !editorAPI.isMobileEditor() ||
    !editorAPI.components.isShowOnAllPages(component) ||
    FORBIDDEN_MOVE_TO_COMPONENTS.includes(
      editorAPI.components.getType(component),
    )
  ) {
    return null;
  }

  const subItems = getMoveToSubItems(editorAPI, component);

  if (_.isEmpty(subItems)) {
    return null;
  }

  return {
    type: 'subItems',
    enabled: true,
    subItems,
  };
}

export { createMoveToItem as build };
