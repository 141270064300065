// @ts-nocheck
import _ from 'lodash';

function isSchemaFile(schemasFolderId, path) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/starts-with, you-dont-need-lodash-underscore/ends-with
  return _.startsWith(path, schemasFolderId) && _.endsWith(path, '.json');
}

function extractCollectionName(schemasFolderId, path) {
  return path.replace(schemasFolderId, '').replace('.json', '');
}

function navigateToCollection(editorAPI, appData, token, options) {
  return editorAPI.wixCode.collectionsTree.navigateToCollection(
    editorAPI,
    options.name,
  );
}

function openFile(editorAPI, appData, token, options) {
  const filePath = options.path;
  const schemasFolderId =
    editorAPI.wixCode.fileSystem.getRoots().schemas.location;
  if (isSchemaFile(schemasFolderId, filePath)) {
    const name = extractCollectionName(schemasFolderId, options.path);
    return navigateToCollection(editorAPI, appData, token, { name });
  }
  return editorAPI.wixCode.fileTree.navigateToFile(
    editorAPI,
    options.path,
    _.pick(options, ['functionName', 'caretOffset', 'caretLocation']),
  );
}

function isTreePaneOpen(editorAPI) {
  return editorAPI.developerMode.ui.treePane.isOpen();
}

function toggleTreePane(editorAPI) {
  return new Promise(function (resolve) {
    editorAPI.developerMode.ui.treePane.toggle(function () {
      resolve();
    });
  });
}

const treePane = {
  isOpen: isTreePaneOpen,
  toggle: toggleTreePane,
};

export { openFile, navigateToCollection, treePane };
