// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import React from 'react';
import constants from '@/constants';
import PopupTemplate from './popupTemplate';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'sizeRestrictionPanel',
  componentDidMount() {
    const editorAPI = this.getEditorAPI();
    const { store } = editorAPI;
    store.dispatch(
      stateManagement.bi.actions.event(
        coreBi.events.pinToScreen.NOTIFICATION_TOO_BIG,
        {
          state: this.props.state,
          component_type:
            stateManagement.selection.selectors.getSelectedComponentType(
              store.getState(),
              editorAPI.dsRead,
            ),
        },
      ),
    );
  },
  render() {
    return (
      <PopupTemplate
        panelName={this.props.panelName}
        h1text={this.props.title}
        symbolName="bigtopin"
        bodyText={this.props.content}
        dontShowAgainKey={
          constants.PANELS.PIN_TOO_BIG.PIN_TOO_BIG_DONT_SHOW_AGAIN
        }
        helpId="7827a21e-04e0-4bdd-9ee0-b68677fa7a5a"
        userPrefType="site"
      />
    );
  },
});
