import { getInnerTextString } from '@wix/editor-content-injector';
import { isRuleIncluded } from './scanUtils';

import type { ComponentsAPI } from './scanTypes';
import type { CompRef } from 'types/documentServices';

export function createTextInjectionMap(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
): string {
  const { text } = componentsAPI.data.get(compRef);
  if (!text) {
    return;
  }

  return getInnerTextString(text);
}

export function createImageInjectionMap(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
) {
  const isNoFaceImage = isRuleIncluded(componentsAPI, compRef, 'NoFaceImage');
  const uri =
    getBackgroundImageUri(componentsAPI, compRef) ||
    getBackgroundFromOneColumnStrip(componentsAPI, compRef) ||
    getComponentImageUri(componentsAPI, compRef) ||
    getSvgUri(componentsAPI, compRef);

  const isSvg = isImageSvg(uri);

  return {
    uri,
    isSvg,
    isNoFaceImage,
  };
}

export function getComponentImageUri(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
) {
  return componentsAPI.data.get(compRef)?.uri;
}

export function getBackgroundImageUri(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
) {
  return (
    componentsAPI.design.get(compRef)?.background?.mediaRef?.uri ||
    componentsAPI.design.get(compRef)?.background?.mediaRef?.posterImageRef?.uri
  );
}

export function getSvgUri(componentsAPI: ComponentsAPI, compRef: CompRef) {
  return componentsAPI.data.get(compRef)?.uri;
}

export function isImageSvg(uri: string) {
  if (!uri) {
    return false;
  }
  return uri.toLowerCase().endsWith('.svg');
}

export function getBackgroundFromOneColumnStrip(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
) {
  if (
    componentsAPI.getType(compRef).includes('StripColumnsContainer') &&
    componentsAPI.getChildren(compRef).length === 1
  ) {
    const columnCompRef = componentsAPI.getChildren(compRef)[0];
    return getBackgroundImageUri(componentsAPI, columnCompRef);
  }
  return;
}

export function createButtonInjectionMap(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
) {
  return componentsAPI.data.get(compRef)?.label || '';
}
