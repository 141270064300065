const BLOG_SRC = 69;
const BLOG_ENDPOINT = 'blog-app-users';

const ORIGINS = {
  OLD_BLOG_6TH_BUTTON: 'old blog 6th button',
  NEW_BLOG_6TH_BUTTON: 'new blog 6th button',
};

const events = {
  BLOG_ELEMENT_ADDED: {
    evid: 322,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      element_type: 'string',
      layout: 'string',
      instance_id: 'guid',
      is_first_time_view: 'bool', // TODO: ask Itai if we need this
    },
  },
  BLOG_QUICK_MIGRATION_DONE: {
    evid: 394,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      is_first: 'bool',
      origin: 'string',
      role: 'string',
    },
  },
  BLOG_QUICK_MIGRATION_FAILED: {
    evid: 396,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      is_first: 'bool',
      migration_status: 'string',
      origin: 'string',
      role: 'string',
    },
  },
  BLOG_QUICK_MIGRATION_ACTION: {
    evid: 397,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      do_not_show_again: 'bool',
      post_id: 'string',
      action: 'string',
      origin: 'string',
    },
  },
  BLOG_QUICK_MIGRATION_LEARN_MORE: {
    evid: 398,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      role: 'string',
      action: 'string',
      origin: 'string',
    },
  },
  BLOG_MAGIC_MIGRATION_MODAL_SHOWN: {
    evid: 405,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      modal_name: 'string',
      origin: 'string',
      new_blog_valid: 'bool',
    },
  },
  BLOG_MAGIC_MIGRATION_MODAL_ACTION: {
    evid: 406,
    src: BLOG_SRC,
    endpoint: BLOG_ENDPOINT,
    fields: {
      modal_name: 'string',
      action: 'string',
      origin: 'string',
    },
  },
} as const;

export { ORIGINS, events };
