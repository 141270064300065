// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import React from 'react';
import * as baseUI from '@/baseUI';
import { Tooltip } from '@wix/wix-base-ui';
import { cx, translationMixin } from '@/util';
import { BaseUiTextWrapper } from './baseUiTextWrapper';
import experiment from 'experiment';

import type { RCMItemDataType } from '../types';

const DEFAULT_ICON_SIZE = 14;

export interface RcmItemBasicProps {
  shouldTranslate?: boolean;
  item: RCMItemDataType;
  onClick: Function;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  label?: string;
  automationId?: string;
  tooltip?: any;
  enabled?: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<RcmItemBasicProps>({
  displayName: 'rcmItemBasic',
  mixins: [translationMixin],
  isEnabled() {
    if (_.isFunction(this.props.enabled)) {
      return this.props.enabled();
    }

    return this.props.item.enabled;
  },
  render() {
    const enabled = this.isEnabled();
    const label: string = this.translateIfNeeded(
      this.props.label || this.props.item.label,
    );

    return (
      <Tooltip
        content={this.props.tooltip}
        arrowAlignment="right"
        alignment="RIGHT"
        openOnMouseEnter={!!this.props.tooltip}
      >
        <li
          data-aid={this.props.automationId || ''}
          onClick={enabled ? this.props.onClick : function () {}}
          onContextMenu={enabled ? this.props.onClick : function () {}}
          onMouseEnter={this.props.onMouseEnter || _.noop}
          onMouseLeave={this.props.onMouseLeave || _.noop}
          className={cx({
            'item-basic': true,
            'item-basic-flex': true,
            'with-wbu-icons': experiment.isOpen('se_newIconsForLayers'),
            disabled: !enabled,
            hoverable: enabled,
          })}
        >
          {this.props.item.icon ? (
            <span
              key="icon"
              className={`icon ${
                this.props.item.icon.css?.containerSize
                  ? 'with-custom-icon-size'
                  : ''
              }`}
              style={{
                width:
                  this.props.item.icon.css?.containerSize || DEFAULT_ICON_SIZE,
                height:
                  this.props.item.icon.css?.containerSize || DEFAULT_ICON_SIZE,
              }}
            >
              <baseUI.MenuIcon icon={this.props.item.icon} />
            </span>
          ) : null}
          <span className="label">
            <BaseUiTextWrapper text={label} disabled={!enabled} />
          </span>
        </li>
      </Tooltip>
    );
  },
});
