import React, { type ComponentType } from 'react';

import { hoc, media, workspace, browserUtil } from '@/util';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './databaseWelcomeSectionMapper';
import type {
  DatabaseWelcomeDispatchProps,
  DatabaseWelcomeOwnProps,
  DatabaseWelcomeStateProps,
} from './types';

class DatabaseWelcomeSection extends React.Component<
  DatabaseWelcomeOwnProps &
    DatabaseWelcomeStateProps &
    DatabaseWelcomeDispatchProps
> {
  private isNewWorkspace: boolean = workspace.isNewWorkspaceEnabled();

  getPromotionalListItems = () => {
    return [
      'SiteApp_WixData_AddCollectionPanel_Bullet1',
      'SiteApp_WixData_AddCollectionPanel_Bullet2',
      'SiteApp_WixData_AddCollectionPanel_Bullet3',
    ];
  };

  getBannerImgSrc() {
    let { bannerImage } = this.props;

    if (!bannerImage) {
      if (this.isNewWorkspace) {
        bannerImage = browserUtil.isRetina()
          ? 'addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection@x2.png'
          : 'addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection.png';
      } else {
        bannerImage =
          'addPanelData/sections/dataBinding/databaseWelcomeSection.png';
      }
    }

    return media.getMediaUrl(bannerImage);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={this.getBannerImgSrc()}
          title="SiteApp_WixData_AddCollectionPanel_CMS_Title"
          subtitle="SiteApp_WixData_AddCollectionPanel_CMS_SubTitle"
          promotionalListItems={this.getPromotionalListItems()}
          mainActionLabel="SiteApp_WixData_AddCollectionPanel_AddtoSite_Button"
          secondaryActionLabel="SiteApp_WixData_AddCollectionPanel_SecondaryLink"
          onMainActionClick={this.props.turnDataModeOn}
          onSecondaryActionClick={this.props.openWelcomeHelpSection}
        />
      </div>
    );
  }
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  false,
)(
  hoc.renderWhenMutated(DatabaseWelcomeSection),
) as ComponentType<DatabaseWelcomeOwnProps>;
