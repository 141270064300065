import React from 'react';
import styles from './styles.scss';
import { AIDS } from './constants';
import { blocksMap } from './blocks';
import { frames } from '@/panels';
import { CustomScroll } from '@wix/wix-base-ui';

import { outerClick as OuterClick } from '@/util';

import type { Props, HelpBlock } from './types';

const { RightPanelFrame } = frames;

const PANEL_TOGGLE_BUTTON_SELECTOR = '.help-home-toggle';
const MODAL_WINDOW_SELECTOR = '.panel-frame-container';
const HELP_CENTER_SELECTOR = '[data-panel="help-center"]';
const CLICK_OUTSIDE_EXCEPTIONS = [
  PANEL_TOGGLE_BUTTON_SELECTOR,
  MODAL_WINDOW_SELECTOR,
  HELP_CENTER_SELECTOR,
];
const isClickOutsideException = (node: AnyFixMe) =>
  CLICK_OUTSIDE_EXCEPTIONS.some((selector) => Boolean(node.closest(selector)));

const Block: React.FC<Props & { type: HelpBlock }> = ({ type, ...props }) => {
  const BlockComponent = blocksMap[type];
  return <BlockComponent {...props} />;
};

const HelpHome: React.FC<Props> = (props) => {
  const { blocks, onOpen, popularHelpArticles, close } = props;

  const handleClickOutside = React.useCallback(
    (event) => {
      if (!isClickOutsideException(event.target) && close) {
        close();
      }
    },
    [close],
  );

  React.useEffect(
    () => {
      onOpen(blocks, popularHelpArticles);
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <OuterClick onOuterClick={handleClickOutside}>
      <RightPanelFrame
        panelName="rEditor.HelpHomePanel"
        title="Editor_Help_Panel_Header_Title"
        automationId={AIDS.PANEL}
      >
        <div className={styles.blocks}>
          <CustomScroll flex="1">
            <div className={styles.list}>
              {blocks.map((blockType) => (
                <Block type={blockType} {...props} />
              ))}
            </div>
          </CustomScroll>
        </div>
      </RightPanelFrame>
    </OuterClick>
  );
};

export default HelpHome;
