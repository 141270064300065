:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._additionalPageThumbnailWrapper_1i1aw_7 {
  transition: transform 250ms ease-in, box-shadow 250ms ease-in;
  transform-origin: bottom;
  margin-bottom: 34px;
  position: relative;
  outline: 1px solid #dfe5eb;
  border-radius: 4px;
  box-shadow: 0 4px 7px 0 #162d3d2a;
  cursor: pointer; }
  ._additionalPageThumbnailWrapper_1i1aw_7 .additional-pages-thumbnail-header {
    padding: 0 12px;
    height: 40px;
    background-color: #f7f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #dfe5eb; }
    ._additionalPageThumbnailWrapper_1i1aw_7 .additional-pages-thumbnail-header .additional-page-radio-button {
      width: 14px;
      height: 14px;
      border-radius: 2px;
      border: 1px solid #116dff;
      background-color: transparent; }
  ._additionalPageThumbnailWrapper_1i1aw_7:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 20px 0 #162d3d1f;
    outline: 2px solid #80b1ff; }
    ._additionalPageThumbnailWrapper_1i1aw_7:hover .additional-pages-thumbnail-header {
      background-color: #e7f0ff; }
    ._additionalPageThumbnailWrapper_1i1aw_7:hover .additional-page-radio-button {
      background-color: #d6e6fe; }
  ._additionalPageThumbnailWrapper_1i1aw_7.selected {
    outline: 2px solid #116dff; }
    ._additionalPageThumbnailWrapper_1i1aw_7.selected:hover {
      outline: 2px solid #116dff; }
    ._additionalPageThumbnailWrapper_1i1aw_7.selected .additional-pages-thumbnail-header {
      background-color: #e7f0ff; }
    ._additionalPageThumbnailWrapper_1i1aw_7.selected .additional-page-radio-button {
      background-color: #116dff; }

._additionalPageThumbnailText_1i1aw_48 {
  font-size: 14px;
  line-height: 18px;
  color: #000624; }
  ._additionalPageThumbnailText_1i1aw_48 span {
    font-family: Madefor;
    font-weight: 500; }

._fpdScrollbar_1i1aw_56 {
  visibility: hidden;
  width: 4px;
  position: absolute;
  right: 3px;
  top: 0;
  background-color: rgba(194, 199, 203, 0.5);
  height: 75%;
  border-radius: 30px; }

._additionalPageThumbnail_1i1aw_7 {
  margin: 0 auto;
  max-height: 14vw;
  min-height: 10vw;
  overflow: hidden;
  box-shadow: 0 12px 20px rgba(22, 45, 61, 0.12);
  border-radius: 0 0 4px 4px;
  position: relative; }
  ._additionalPageThumbnail_1i1aw_7::-webkit-scrollbar {
    width: 0; }
  ._additionalPageThumbnail_1i1aw_7 > div {
    pointer-events: none;
    transition-property: top, transform;
    transition-timing-function: ease, ease;
    transition-duration: 3s, 3s;
    transition-delay: 0.2s, 0.2s; }
  ._additionalPageThumbnail_1i1aw_7:hover > div {
    will-change: transform; }
  ._additionalPageThumbnail_1i1aw_7:hover > ._fpdScrollbar_1i1aw_56 {
    visibility: visible; }
  ._additionalPageThumbnail_1i1aw_7 * {
    font-size: inherit;
    line-height: inherit;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0; }
