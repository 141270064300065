'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const {DATA_BINDING} = require('@wix/app-definition-ids')
const i18n = require('@/i18n')
const resourcePath = 'addPanelData/sections/dataBinding/'

const addDataset = ({id, title, desc, tooltip, action, settings = {}}) => ({
    id,
    title,
    desc,
    tooltip,
    disabledTooltipNoCollection: 'PLATFORM_ADD_SECTION_DISABLED_STATE_TOOLTIP',
    image: `${resourcePath}addDataset.png`,
    disabledImage: `${resourcePath}addDatasetDisabled.png`,
    symbol: 'plusBig',
    structure: {
        type: 'Component',
        skin: 'platform.components.skins.controllerSkin',
        layout: {
            width: 40,
            height: 40,
            x: 20,
            y: 15,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'platform.components.AppController',
        data: {
            type: 'AppController',
            applicationId: DATA_BINDING,
            name: i18n.translate('PLATFORM_ONSTAGE_UNDEFINED_DATASET_LABEL'),
            controllerType: 'dataset',
            settings: JSON.stringify(settings)
        },
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
        style: {
            type: 'TopLevelStyle',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            style: {
                groups: {},
                properties: {
                    'alpha-bg': '1',
                    'alpha-bgh': '1',
                    'alpha-brd': '1',
                    'alpha-brdh': '1',
                    'alpha-txt': '1',
                    'alpha-txth': '1',
                    bg: '#3D9BE9',
                    bgh: '#2B689C',
                    'boxShadowToggleOn-shd': 'false',
                    brd: '#2B689C',
                    brdh: '#3D9BE9',
                    brw: '0px',
                    fnt: 'normal normal normal 14px/1.4em raleway',
                    rd: '20px',
                    shd: '0 1px 4px rgba(0, 0, 0, 0.6);',
                    txt: '#FFFFFF',
                    txth: '#FFFFFF'
                },
                propertiesSource: {
                    bg: 'value',
                    bgh: 'value',
                    brd: 'value',
                    brdh: 'value',
                    brw: 'value',
                    fnt: 'value',
                    rd: 'value',
                    shd: 'value',
                    txt: 'value',
                    txth: 'value'
                }
            },
            componentClassName: 'platform.components.AppController',
            skin: 'platform.components.skins.controllerSkin'
        }
    },
    action
})

const datasetTranslationKeys = Object.assign(
    {tooltip: 'PLATFORM_ADD_SECTION_DATASET_TOOLTIP'}, 
    {
        title: 'PLATFORM_ADD_SECTION_LABEL_DATASET',
        desc: 'Platform_Add_Section_CMS_Dataset_Description'
    })

const formDatasetTranslationKeys = Object.assign(
    {tooltip: 'Platform_Add_Section_Form_Dataset_Tooltip'}, 
    {
        title: 'Platform_Add_Section_Label_Form_Dataset',
        desc: 'Platform_Add_Section_Form_Dataset_Description'
    })

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.DATA,
    'hide': false,
    'title': 'PLATFORM_ADD_SECTION_HEADING1',
    'automationId': 'add-panel-section-dataBindingSection',
    'showSectionHeader': true,
    'props': {
        items: [
            addDataset(Object.assign(
                {
                    id: 'add_dataset',
                    action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_DATASET
                }, 
                datasetTranslationKeys
            )),
            addDataset(Object.assign(
                {
                    id: 'add_form_dataset',
                    action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_FORM_DATASET,
                    settings: {
                        dataset: {
                            readWriteType: 'WRITE'
                        }
                    }
                }, 
                formDatasetTranslationKeys
            ))
        ]
    }
}
