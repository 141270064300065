import React from 'react';
import _ from 'lodash';

import * as baseUI from '@/baseUI';
import * as textControls from '@/textControls';

import * as advancedStyleControls from '../utils/controlMap';

export interface AdvancedStyleDynamicControlProps {
  type: string;
  propsToPass?: object;
  paramKey?: string;
}

const AdvancedStyleDynamicControl: React.FC<
  AdvancedStyleDynamicControlProps
> = (props) => {
  const { type, paramKey, propsToPass } = props;

  const elemToCreate =
    textControls[type as keyof typeof textControls] ||
    advancedStyleControls[type as keyof typeof advancedStyleControls] ||
    baseUI[type as keyof typeof baseUI];

  if (!elemToCreate) {
    return React.createElement('div', type);
  }

  return React.createElement(
    elemToCreate,
    _.merge({ automationId: paramKey }, propsToPass, _.omit(props, 'type')),
  );
};

AdvancedStyleDynamicControl.displayName = 'advanced-style-ui-control';

export default AdvancedStyleDynamicControl;
