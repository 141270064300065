import _ from 'lodash';
import React from 'react';
import * as panels from '@/panels';
import * as util from '@/util';
import { translate } from '@/i18n';
import { fedopsLogger } from '@/util';
import experiment from 'experiment';
import { connectWithScope } from '@/apilib';
import type { EditorInteractionName } from 'types/fedops';
import { calcMinHeight } from '../../addPresetUtil';
import { AddPresetScope } from '../../addPresetApiEntry';
import {
  NOTIFICATION_HEIGHT,
  SCAN_EXTRA_HEIGHT,
  MODAL_SIZE_RATIO,
} from '../../addPresetConsts';
import {
  type AddPagePanelStateProps,
  type AddPagePanelDispatchProps,
  mapDispatchToProps,
  mapStateToProps,
  type AddPagePanelOwnProps,
} from './addPagePanelMapper';
import { Preloader } from '@wix/wix-base-ui';
import { BusinessTypePanelLink } from '@/baseUI';
import { AddPagesLeftBar } from './components/addPagesLeftBar';
import { AddPageNotificationBanner } from './components/addPageNotificationBanner';
import { CategoryHeader } from './components/categoryHeader';
import { ErrorStateContent } from './components/errorStateContent';
import { AddPageThumbnail } from '../pagePreview/addPageThumbnail';
import AddPagePreInstallApp from './components/addPagePreInstallApp';
import AddPagePostInstallApp from './components/addPagePostInstallApp';
import AddPresetHtmlPreview from '../../previews/addPresetHtmlPreview';
import AddPresetPreviewerPreview from '../../previews/addPresetPreviewerPreview';

import type {
  PagePresetDefinition,
  PreviewComponentsEntry,
  PresetReadyEntry,
  AddPageCategory,
  ThemeMap,
  AddPageThumbnailPreviewProps,
} from '../../types';

interface AddPagePanelState {
  errorStateContent: ErrorStateContent | null;
  contentScrolled: boolean;
  pages: PagePresetDefinition[];
  selectedCategory: number;
  firstPreviewReady: boolean;
  areAllPresetsReady: boolean;
  previewerReactComponents: PreviewComponentsEntry;
  presetsReady: PresetReadyEntry;
}

interface AddPagePanelProps
  extends AddPagePanelDispatchProps,
    AddPagePanelStateProps,
    AddPagePanelOwnProps {}

interface ColumnDefinition {
  pages: PagePresetDefinition[];
  height: number;
}

interface ThumbnailsResolveMap {
  [pageId: string]: () => void;
}

const THUMBNAIL_BORDER_OFFSET = 2;

function getLowestColumnIndex(columnsDefinition: ColumnDefinition[]): number {
  let minIndex = 0;
  columnsDefinition.forEach((columnDefinition, columnIndex) => {
    if (columnDefinition.height < columnsDefinition[minIndex].height) {
      minIndex = columnIndex;
    }
  });
  return minIndex;
}

class AddPagePanel extends React.Component<
  AddPagePanelProps,
  AddPagePanelState
> {
  constructor(props: AddPagePanelProps) {
    super(props);

    this.state = {
      previewerReactComponents: {},
      presetsReady: {},
      errorStateContent: null,
      selectedCategory: props.initialSelectedCategory,
      contentScrolled: false,
      pages: [],
      firstPreviewReady: false,
      areAllPresetsReady: false,
    };
    util.keyboardShortcuts.registerContext(
      util.keyboardShortcuts.CONTEXTS.ADD_PAGE_PANEL,
      {
        [util.keyboardShortcuts.specialKeys.esc]: props.closeModal,
      },
    );
  }

  componentDidMount() {
    this.startLoadTimeStamp = Date.now();
    if (!this.props.categories[this.state.selectedCategory].application) {
      this.loadPages(this.state.selectedCategory).then(
        async (pages: PagePresetDefinition[]) => {
          this.loadedPagesHandler(pages);
          await this.setPreviewerReactComponentsWithBatches(pages);
        },
      );
    }
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_OPEN,
    );
    this.lastKeyboardContext = util.keyboardShortcuts.getContext();
    util.keyboardShortcuts.setContext(
      util.keyboardShortcuts.CONTEXTS.ADD_PAGE_PANEL,
    );
    this.debounceScrollHandler = _.debounce(
      this.props.sendContentScrolledEvent,
      300,
    );
  }

  componentWillUnmount() {
    util.keyboardShortcuts.setContext(this.lastKeyboardContext);
    this.props.setSelectedCategoryId(
      this.props.categories[this.state.selectedCategory].id,
    );
  }

  private thumbnailsResolveMap: ThumbnailsResolveMap;
  private thumbnailsWrapperRef = React.createRef<HTMLDivElement>();
  private debounceScrollHandler: (
    scrollTop: number,
    categoryTitle: string,
  ) => void;
  private startLoadTimeStamp: number;
  private lastKeyboardContext: string;

  setPreviewerReactComponentsWithBatches = async (
    pages: PagePresetDefinition[],
  ) => {
    const firstBatch = pages.slice(0, 3);
    const secondBatch = pages.slice(3, 6);
    const thirdBatch = pages.slice(6, pages.length);
    for (const pagesBatch of [firstBatch, secondBatch, thirdBatch]) {
      if (pagesBatch.length) {
        await this.updatePreviewerComponentsWithBatches(pagesBatch);
      }
    }
  };

  updatePreviewerComponentsWithBatches = async (
    pages: PagePresetDefinition[],
  ) => {
    if (this.shouldUseNewPreview()) {
      const columnWidth = this.getColumnWidth();
      const previewerReactComponentsBatch =
        await this.props.getPreviewComponentsEntry(
          pages,
          columnWidth,
          columnWidth - THUMBNAIL_BORDER_OFFSET,
          this.onPreviewReady,
          this.props.categories[this.state.selectedCategory],
        );
      this.setState({
        previewerReactComponents: {
          ...this.state.previewerReactComponents,
          ...previewerReactComponentsBatch,
        },
      });
    }
  };

  onCategoryChanged = (selectedCategory: number) => {
    const { categories, isApplicationInstalled } = this.props;
    const { application } = categories[selectedCategory];
    const isInstalled = application
      ? isApplicationInstalled(application.appDefinitionId)
      : false;
    if (selectedCategory !== this.state.selectedCategory) {
      this.props.sendCategoryChangeEvent(
        categories[selectedCategory].title,
        isInstalled,
      );
      this.setState({
        selectedCategory,
        pages: [],
        firstPreviewReady: false,
        areAllPresetsReady: false,
        previewerReactComponents: {},
        presetsReady: {},
      });
      this.startLoadTimeStamp = Date.now();
      if (!this.props.categories[selectedCategory].application) {
        this.loadPages(selectedCategory).then(async (pages) => {
          this.loadedPagesHandler(pages);
          await this.setPreviewerReactComponentsWithBatches(pages);
        });
      }
    }
  };

  shouldUseNewPreview() {
    return (
      this.props.isContentInjectionAvailableForPages ||
      experiment.isOpen('se_previewerIntegrationAddPage') ||
      experiment.getValue('se_previewerOrContentInjectionInAddPage') ===
        'previewer'
    );
  }

  getErrorStateContent = (
    loadedPages: PagePresetDefinition[],
  ): ErrorStateContent | null => {
    if (loadedPages.length > 0) {
      return null;
    }
    if (!window.navigator.onLine) {
      return {
        title: 'add_page_preset_error_internet_title',
        symbolName: 'internetConnectionError',
        actionText: 'add_page_preset_error_internet_CTA',
        description: 'add_page_preset_error_internet_text',
        actionHandler: this.refreshHandler,
      };
    }
    const { categories } = this.props;
    const category = categories[this.state.selectedCategory];
    if (category.application) {
      // For apps showing static content instead of dynamic fetching
      return null;
    }
    return {
      title: 'add_page_preset_error_loading_title',
      learnMore: 'add_page_preset_error_link',
      symbolName: 'plasterMed',
      actionText: 'add_page_preset_error_loading_CTA',
      description: 'add_page_preset_error_loading_text',
      actionHandler: this.refreshHandler,
      learnMoreAction: () => {
        this.props.openHelpCenter('aa46e642-a046-45b8-a0f3-fdc39d58e020');
      },
    };
  };

  loadedPagesHandler = (pages: PagePresetDefinition[]) => {
    this.setState({
      pages,
      errorStateContent: this.getErrorStateContent(pages),
    });
  };

  refreshHandler = () => {
    this.setState({
      pages: [],
      firstPreviewReady: false,
      areAllPresetsReady: false,
      errorStateContent: null,
    });
    this.startLoadTimeStamp = Date.now();
    this.loadPages(this.state.selectedCategory).then(this.loadedPagesHandler);
  };

  onScroll = (event: any) => {
    const { scrollTop } = event.target;
    const { contentScrolled, selectedCategory } = this.state;
    const { categories } = this.props;

    this.debounceScrollHandler(scrollTop, categories[selectedCategory].title);
    if (
      (scrollTop > 0 && contentScrolled) ||
      (scrollTop === 0 && !contentScrolled)
    ) {
      return;
    }

    this.setState({
      contentScrolled: !contentScrolled,
    });
  };

  loadPages = async (
    selectedCategory: number,
    shouldLoadPartially: boolean = true,
  ): Promise<PagePresetDefinition[]> => {
    const { categories, loadPages, language, numOfColumns } = this.props;
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
        .ADD_PAGE_PREVIEWER_ALL_READY,
      {
        customParams: {
          categoryId: categories[selectedCategory]?.id,
          categoryName: categories[selectedCategory]?.title,
        },
      },
    );

    const pages = await loadPages(categories, selectedCategory, language);
    const numOfPages = numOfColumns * 2;
    shouldLoadPartially = shouldLoadPartially && !this.shouldUseNewPreview();
    const renderPages = shouldLoadPartially
      ? pages.slice(0, numOfPages)
      : pages;
    this.thumbnailsResolveMap = {};
    const promiseMap = renderPages
      .filter((page, i) => shouldLoadPartially || i >= numOfPages)
      .map(
        (page) =>
          new Promise<void>((resolve) => {
            this.thumbnailsResolveMap[page._id] = resolve;
          }),
      );

    Promise.all(promiseMap).then(() => {
      const endLoadThumbnails = Date.now();
      this.props.sendThumbnailsLoadedEvent(
        shouldLoadPartially ? 'viewReady' : 'allReady',
        categories[selectedCategory].title,
        endLoadThumbnails - this.startLoadTimeStamp,
      );
      if (shouldLoadPartially) {
        this.loadPages(selectedCategory, false).then(this.loadedPagesHandler);
      } else {
        this.setState({ areAllPresetsReady: true });
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
            .ADD_PAGE_PREVIEWER_ALL_READY,
          {
            customParams: {
              categoryId: categories[selectedCategory]?.id,
              categoryName: categories[selectedCategory]?.title,
            },
          },
        );
      }
    });

    return renderPages;
  };

  getColumnWidth = () => {
    if (this.thumbnailsWrapperRef.current) {
      const { numOfColumns } = this.props;
      const wrapperWidth = this.thumbnailsWrapperRef.current.clientWidth;

      return (wrapperWidth - 54 * (numOfColumns - 1)) / numOfColumns;
    }
  };

  getColumns = (): ColumnDefinition[] => {
    const { numOfColumns } = this.props;
    return this.state.pages.reduce(
      (columns, thumbnail) => {
        const lowestColumnIndex = getLowestColumnIndex(columns);
        columns[lowestColumnIndex].pages.push(thumbnail);
        columns[lowestColumnIndex].height += thumbnail.previewHeight;
        return columns;
      },
      Array(numOfColumns)
        .fill(undefined)
        .map(() => ({ pages: [], height: 0 })),
    );
  };

  setFirstPreviewReady = () => {
    if (!this.state.firstPreviewReady) {
      const { categories } = this.props;
      const { selectedCategory } = this.state;
      const endLoadFirstThumbnail = Date.now();
      this.props.sendThumbnailsLoadedEvent(
        'firstReady',
        categories[selectedCategory].title,
        endLoadFirstThumbnail - this.startLoadTimeStamp,
      );
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
          .ADD_PAGE_PREVIEWER_FIRST_READY,
        {
          customParams: {
            categoryId: categories[selectedCategory]?.id,
            categoryName: categories[selectedCategory]?.title,
          },
        },
      );
      this.setState({
        firstPreviewReady: true,
      });
    }
  };

  shouldShowNotification = (): boolean => {
    return this.props.shouldShowUniquePageMessage(
      this.props.categories[this.state.selectedCategory],
    );
  };

  setPresetReady = (id: string) => {
    if (!this.state.presetsReady[id]) {
      this.setState({
        presetsReady: {
          ...this.state.presetsReady,
          [id]: true,
        },
      });
    }
  };

  onPreviewReady = (
    pageId: string,
    fedopsInteractionKey: EditorInteractionName,
  ) => {
    const { categories } = this.props;
    const { selectedCategory } = this.state;
    if (!this.state.presetsReady[pageId]) {
      this.setFirstPreviewReady();
      this.setPresetReady(pageId);
      fedopsLogger.interactionEnded(fedopsInteractionKey, {
        customParams: {
          presetId: pageId,
          categoryName: categories[selectedCategory]?.title || '',
        },
      });
      const resolvePreviewerFunction =
        this.thumbnailsResolveMap[pageId] || _.noop;
      resolvePreviewerFunction();
    }
  };

  getPreviewForPagePreview = async (
    pageDef: PagePresetDefinition,
    currentSiteFontsUrls: string[],
    currentSiteThemeMap: ThemeMap,
    width: number,
    previewHeight: number,
  ): Promise<React.ReactElement> => {
    const isNewPreview =
      pageDef.shouldUsePreviewer && this.shouldUseNewPreview();
    if (isNewPreview) {
      const previewerReactComponents =
        await this.props.createSinglePreviewerComponent(
          pageDef,
          width,
          previewHeight,
          this.onPreviewReady,
          this.state.previewerReactComponents?.[pageDef?._id]?.compStructure,
        );
      return (
        <AddPresetPreviewerPreview
          id={pageDef._id}
          interactionKey={
            fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
              .ADD_PAGE_PREVIEWER_PREVIEW_LOAD
          }
          previewComponent={previewerReactComponents[0]}
          isPresetReady={this.state.presetsReady?.[pageDef._id]}
          isBackgroundTransparent={true}
        />
      );
    }

    return (
      <AddPresetHtmlPreview
        key={pageDef._id}
        previewHtmlUrl={pageDef.previewHtmlUrl}
        fontsUrls={currentSiteFontsUrls}
        containerWidth={width}
        currentSiteThemeMap={currentSiteThemeMap}
        themeMap={pageDef.themeMap}
        onReady={() => {}}
        height={previewHeight}
        interactionKey={
          fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
            .ADD_PAGE_HTML_PREVIEW_LOAD
        }
        shouldOptimizeImages={false}
        enableScrolling={true}
        shouldWrapWithHeight={false}
      />
    );
  };

  handleOpenPreviewThumbnail = async (
    pageDef: PagePresetDefinition,
    selectedCategory: AddPageCategory,
  ) => {
    const {
      addPage,
      sendPreviewClickEvent,
      panelOpenOrigin,
      currentSiteFontsUrls,
      currentSiteThemeMap,
      openPreviewThumbnail,
    } = this.props;

    const width = Math.round(window.innerWidth * MODAL_SIZE_RATIO);
    const heightOffset = this.props.shouldShowUniquePageMessage(
      selectedCategory,
    )
      ? NOTIFICATION_HEIGHT
      : 0;
    const height =
      calcMinHeight(pageDef.previewHeight, width) +
      heightOffset +
      SCAN_EXTRA_HEIGHT;

    const previewComponent = await this.getPreviewForPagePreview(
      pageDef,
      currentSiteFontsUrls,
      currentSiteThemeMap,
      width,
      height,
    );

    openPreviewThumbnail(
      pageDef,
      selectedCategory,
      previewComponent,
      width,
      height,
      !this.shouldUseNewPreview(),
      () => addPage(pageDef, selectedCategory, panelOpenOrigin, 'previewModal'),
    );
    sendPreviewClickEvent(pageDef, selectedCategory);
  };

  getAddPagePanelPages = () => {
    const {
      labels,
      categories,
      addPage,
      currentSiteThemeMap,
      isReplacePage,
      panelOpenOrigin,
      upgrade,
      openHelpCenter,
      currentSiteFontsUrls,
    } = this.props;
    const { contentScrolled, selectedCategory, firstPreviewReady } = this.state;
    const columnWidth = this.getColumnWidth();

    const getPreview = (
      pageDef: PagePresetDefinition,
      previewProps: AddPageThumbnailPreviewProps,
    ) => {
      const isNewPreview =
        pageDef.shouldUsePreviewer && this.shouldUseNewPreview();

      const pageId = pageDef._id;

      if (isNewPreview) {
        return (
          <AddPresetPreviewerPreview
            id={pageId}
            interactionKey={
              fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
                .ADD_PAGE_PREVIEWER_PREVIEW_LOAD
            }
            previewComponent={
              this.state.previewerReactComponents?.[pageId]?.reactElement
            }
            isPresetReady={this.state.presetsReady?.[pageId]}
            isBackgroundTransparent={true}
          />
        );
      }

      return (
        <AddPresetHtmlPreview
          key={pageDef._id}
          previewHtmlUrl={pageDef.previewHtmlUrl}
          fontsUrls={currentSiteFontsUrls}
          containerWidth={columnWidth - THUMBNAIL_BORDER_OFFSET}
          currentSiteThemeMap={currentSiteThemeMap}
          themeMap={pageDef.themeMap}
          onReady={() => {
            this.onPreviewReady(
              pageDef._id,
              fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
                .ADD_PAGE_HTML_PREVIEW_LOAD,
            );
          }}
          height={previewProps.height}
          interactionKey={
            fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
              .ADD_PAGE_HTML_PREVIEW_LOAD
          }
        />
      );
    };

    const getAddPageThumbnail = (pageDef: PagePresetDefinition) => {
      return (
        <AddPageThumbnail
          isPremiumSite={this.props.isPremiumSite}
          upgrade={upgrade}
          category={categories[selectedCategory]}
          tooltipShowEvent={this.props.sendUpgradeTooltipShowEvent}
          isReplacePage={isReplacePage(pageDef)}
          key={pageDef._id}
          {...pageDef}
          openPreview={() => {
            this.handleOpenPreviewThumbnail(
              pageDef,
              categories[selectedCategory],
            );
          }}
          isPremium={categories[selectedCategory].isPremium}
          addPage={() =>
            addPage(
              pageDef,
              categories[selectedCategory],
              panelOpenOrigin,
              'thumbnail',
              this.state.previewerReactComponents?.[pageDef._id]?.compStructure,
            )
          }
          containerWidth={columnWidth - THUMBNAIL_BORDER_OFFSET}
          isPresetReady={this.state.presetsReady?.[pageDef._id]}
          renderPreview={(previewProps) => getPreview(pageDef, previewProps)}
        />
      );
    };

    return (
      <div
        className={`add-page-panel-content ${
          contentScrolled ? 'add-page-panel-content-scrolled' : ''
        }`}
        onScroll={this.onScroll}
      >
        {!firstPreviewReady && (
          <div className="preloader-wrapper">
            <Preloader className="medium" />
          </div>
        )}
        {this.shouldShowNotification() ? (
          <AddPageNotificationBanner
            isPremiumSite={this.props.isPremiumSite}
            category={categories[selectedCategory]}
            upgrade={upgrade}
            openHelpCenter={openHelpCenter}
          />
        ) : null}
        <div className="main-wrapper">
          <CategoryHeader
            openHelpCenter={openHelpCenter}
            selectedCategory={categories[selectedCategory]}
            labels={labels}
            isPremiumSite={this.props.isPremiumSite}
          />
          <section
            ref={this.thumbnailsWrapperRef}
            className="thumbnails-wrapper"
          >
            {columnWidth &&
              this.getColumns().map((column, index) => (
                <div
                  key={`${selectedCategory}_${index}`}
                  className="thumbnails-column"
                  style={{ width: columnWidth }}
                >
                  {column.pages.map((pageDef) => getAddPageThumbnail(pageDef))}
                </div>
              ))}
          </section>
        </div>
      </div>
    );
  };

  getAddPagePanelContent = () => {
    const {
      categories,
      isApplicationInstalled,
      pageAddedCallback,
      shouldDisablePostInstallSecondaryAction,
    } = this.props;
    const { selectedCategory } = this.state;
    const category = categories[selectedCategory];
    if (category.application) {
      const shouldDisableSecondaryAction =
        shouldDisablePostInstallSecondaryAction(
          category.application.appDefinitionId,
        );
      return isApplicationInstalled(category.application.appDefinitionId) ? (
        <AddPagePostInstallApp
          category={category}
          pageAddedCallback={pageAddedCallback}
          shouldDisableSecondaryAction={shouldDisableSecondaryAction}
        />
      ) : (
        <AddPagePreInstallApp
          category={category}
          pageAddedCallback={pageAddedCallback}
        />
      );
    }
    return this.getAddPagePanelPages();
  };

  render() {
    const {
      categories,
      addBlankPage,
      isPremiumSite,
      isContentInjectionAvailableForPages,
    } = this.props;
    const { errorStateContent, selectedCategory } = this.state;
    return (
      <panels.frames.FocusPanelFrame
        title={translate('add_page_preset_header_title')}
        panelName={this.props.panelName}
        helpId="3cf70609-a555-45f8-95b0-210adf3f98a1"
        disableBlockingScroll={true}
        className={`add-page-panel ${
          this.state.areAllPresetsReady
            ? 'add-page-panel-content-all-ready'
            : ''
        }`}
        subtitle={
          isContentInjectionAvailableForPages ? (
            <BusinessTypePanelLink origin="Page panel" />
          ) : null
        }
      >
        <AddPagesLeftBar
          selectedCategory={selectedCategory}
          categories={categories}
          onCategoryChanged={this.onCategoryChanged}
          onBlankButtonClicked={addBlankPage}
          isPremiumSite={isPremiumSite}
        />
        {errorStateContent ? (
          <ErrorStateContent {...errorStateContent} />
        ) : (
          this.getAddPagePanelContent()
        )}
      </panels.frames.FocusPanelFrame>
    );
  }
}

export default connectWithScope(
  () => AddPresetScope,
  AddPagePanel,
  mapStateToProps,
  mapDispatchToProps,
);
