import React from 'react';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import {
  Composites,
  Illustration,
  ProgressBar,
  TextLabel,
} from '@wix/wix-base-ui';
import { FocusPanelFrame } from '../../frames';
import type { ThunkAction, Dispatch } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';

const RETINA_ILLUSTRATION = util.workspace.isNewWorkspaceEnabled()
  ? 'panels/focusPanels/new-app-installer-progress-panel_x2.gif'
  : 'panels/focusPanels/app-installer-progress-panel_x2.gif';

const ILLUSTRATION = util.workspace.isNewWorkspaceEnabled()
  ? 'panels/focusPanels/new-app-installer-progress-panel_x1.gif'
  : 'panels/focusPanels/app-installer-progress-panel_x1.gif';

const FAIL_PANEL_PROPS = {
  titleKey: 'tpa_messages_dashboard_before_save_error_title',
  description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
  steps: [
    'tpa_messages_upgrade_before_save_error_step_1',
    'tpa_messages_upgrade_before_save_error_step_2',
    'tpa_messages_upgrade_before_save_error_step_3',
  ],
  helpMessage: 'tpa_messages_dashboard_before_save_error_support',
  helpLinkMessage: 'tpa_messages_upgrade_before_save_error_support_link',
  helpLink: '', //TODO helpLink
};

interface ProgressBarPanelProps {
  panelName: string;
  closePanel?: () => void;
  openFailPanel?: (props: typeof FAIL_PANEL_PROPS) => void;
  isDone: boolean;
  title: string;
  progressMax: number;
  step?: number;
  taskDisplayName?: string;
  illustration?: string;
  isError?: boolean;
  panelClosed?: () => void;
  hideText?: boolean;
}

class ProgressBarPanel extends React.Component<ProgressBarPanelProps> {
  static propTypes = {
    closePanel: PropTypes.func.isRequired,
    openFailPanel: PropTypes.func.isRequired,
    isDone: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    progressMax: PropTypes.number.isRequired,
    step: PropTypes.number,
    taskDisplayName: PropTypes.string,
    illustration: PropTypes.string,
    isError: PropTypes.bool,
    panelClosed: PropTypes.func,
  };

  static defaultProps = {
    illustration: util.media.getMediaUrl(
      util.browserUtil.getDevicePixelRatio() === 2
        ? RETINA_ILLUSTRATION
        : ILLUSTRATION,
    ),
    isError: false,
    step: 0,
    hideText: false,
  };

  getKeyboardShortcuts = () => ({
    esc: () => {
      if (this.props.step >= this.props.progressMax - 1) {
        this.props.closePanel();
      }
    },
  });

  componentDidUpdate() {
    if (this.props.isError) {
      this.props.closePanel();
      this.props.openFailPanel(FAIL_PANEL_PROPS);
    }
    if (this.props.isDone) {
      setTimeout(this.props.closePanel, 1000);
    }
  }

  render() {
    return (
      <FocusPanelFrame
        automationId="progress-bar-panel"
        panelName={this.props.panelName}
        shouldHideHeader={true}
        shouldNotCloseOnBlur={true}
        width="564px"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        className="progress-bar-panel"
      >
        <div className="tasks-section">
          <Composites.ProgressBarWithIllustration>
            <Illustration>
              <img src={this.props.illustration} className="illustration" />
            </Illustration>
            <Composites.ProgressBar>
              <TextLabel
                type="T03"
                value={this.props.title}
                className="progress-bar-title"
                automationId="progress-bar-title"
                shouldTranslate={false}
              />
              <ProgressBar
                automationId="progress-bar-percentage"
                progress={this.props.step}
                progressMax={this.props.progressMax}
                hideText={this.props.hideText}
              />
              <TextLabel
                automationId="progress-bar-task-name"
                type="T02"
                value={this.props.taskDisplayName}
                shouldTranslate={false}
              />
            </Composites.ProgressBar>
          </Composites.ProgressBarWithIllustration>
        </div>
      </FocusPanelFrame>
    );
  }
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: ProgressBarPanelProps,
) => {
  const closePanel = () => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    const editorAPI = dispatch(getEditorAPI);
    editorAPI.panelHelpers.progressBarWasClosed();
    if (ownProps?.panelClosed) {
      ownProps.panelClosed();
    }
  };
  const openFailPanel = (props: typeof FAIL_PANEL_PROPS) => {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'savePublish.panels.common.failPanel',
        props,
      ),
    );
    if (ownProps?.panelClosed) {
      ownProps.panelClosed();
    }
  };
  return {
    closePanel,
    openFailPanel,
  };
};

export default util.hoc.connect(
  util.hoc.STORES.STATE_AND_DS,
  null,
  mapDispatchToProps,
)(ProgressBarPanel);
