import updateOperationsPlugins from '../updateOperationsPlugins';
import { PagesApiKey } from '@/apis';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';

function getPageSeo(editorAPI: AnyFixMe, pageId: AnyFixMe) {
  pageId = (pageId && pageId.replace(/^#/, '')) || editorAPI.homePage.get();
  const homePageData = editorAPI.dsRead.pages.data.get(pageId);
  if (!homePageData) return null;

  return {
    name: homePageData.pageTitleSEO,
    description: homePageData.descriptionSEO,
    keywords: homePageData.metaKeywordsSEO,
  };
}

function setSiteSeo(editorAPI: AnyFixMe, seo: AnyFixMe) {
  if (!seo) return;
  editorAPI.site.setSEOSiteKeywords(seo.keywords);
  if (experiment.isOpen('se_removeSeoSet')) {
    return;
  }
  editorAPI.site.setSEOSiteName(seo.name);
  editorAPI.site.setSEOSiteDescription(seo.description);
}

export default {
  register(editorAPI: EditorAPI) {
    updateOperationsPlugins.setHomePagePlugins.list.push(function (
      editorAPI: AnyFixMe,
      newHomePageId: AnyFixMe,
    ) {
      setSiteSeo(editorAPI, getPageSeo(editorAPI, newHomePageId));
    });

    editorAPI.host
      .getAPI(PagesApiKey)
      .hooks.updatePageHook.tap(({ pageId }) => {
        const isHomePage = pageId === editorAPI.homePage.get();
        if (isHomePage) {
          setSiteSeo(editorAPI, getPageSeo(editorAPI, pageId));
        }
      });
  },
};
