import React, { type FC, useRef } from 'react';
import { SlotsStateProvider } from '@wix/extensions-slots-host';
import { extensionsSlots } from '@/stateManagement';
import { hoc } from '@/util';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';

type SlotsState = extensionsSlots.SlotsState;

const mapStateToProps: MapStateToProps<{ slots: SlotsState }> = (stateData) => {
  const slots = extensionsSlots.selectors.getSlots(stateData.state);

  return {
    slots,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch,
) => ({
  registerSlotPortal: (
    slotId: string,
    extensionId: string,
    portalId: string,
  ) => {
    dispatch(
      extensionsSlots.actions.registerSlotPortal(slotId, extensionId, portalId),
    );
  },
  unregisterSlotPortal: (
    slotId: string,
    extensionId: string,
    portalId: string,
  ) => {
    dispatch(
      extensionsSlots.actions.unregisterSlotPortal(
        slotId,
        extensionId,
        portalId,
      ),
    );
  },
  updateSlotPortalProps: (
    slotId: string,
    extensionId: string,
    portalId: string,
    portalProps: Record<string, any>,
  ) => {
    dispatch(
      extensionsSlots.actions.updateSlotPortalProps(
        slotId,
        extensionId,
        portalId,
        portalProps,
      ),
    );
  },
  registerSlotPortalNode: (
    portalId: string,
    placementId: string,
    portalNodeId: string,
  ) => {
    dispatch(
      extensionsSlots.actions.registerSlotPortalNode(
        portalId,
        placementId,
        portalNodeId,
      ),
    );
  },
  unregisterSlotPortalNode: (portalId: string, placementId: string) => {
    dispatch(
      extensionsSlots.actions.unregisterSlotPortalNode(portalId, placementId),
    );
  },
});

interface StateProps {
  slots: SlotsState;
}

interface DispatchProps {
  registerSlotPortal: (
    slotId: string,
    extensionId: string,
    portalId: string,
  ) => void;
  unregisterSlotPortal: (
    slotId: string,
    extensionId: string,
    portalId: string,
  ) => void;
  updateSlotPortalProps: (
    slotId: string,
    extensionId: string,
    portalId: string,
    ownProps: Record<string, any>,
  ) => void;
  registerSlotPortalNode: (
    portalId: string,
    placementId: string,
    portalNodeId: string,
  ) => void;
  unregisterSlotPortalNode: (portalId: string, placementId: string) => void;
}

const useSlotsAPIFactory = (props: DispatchProps) => {
  const {
    registerSlotPortal,
    registerSlotPortalNode,
    updateSlotPortalProps,
    unregisterSlotPortal,
    unregisterSlotPortalNode,
  } = props;

  const { current: slotsAPI } = useRef({
    registerSlotPortal,
    registerSlotPortalNode,
    updateSlotPortalProps,
    unregisterSlotPortal,
    unregisterSlotPortalNode,
  });

  return slotsAPI;
};

const EditorSlotsStateProvider: FC<StateProps & DispatchProps> = (props) => {
  const slotsAPI = useSlotsAPIFactory(props);
  return (
    <SlotsStateProvider slots={props.slots} slotsAPI={slotsAPI}>
      {props.children}
    </SlotsStateProvider>
  );
};

const ConnectedEditorSlotsStateProvider = hoc.connect(
  hoc.STORES.STATE_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(EditorSlotsStateProvider);

export default ConnectedEditorSlotsStateProvider;
