import { fixedStage as fixedStageUtils } from '@/util';
import { isCurrentSiteWidthEqualsToNew } from '../utils';
import { Scope } from '../scope';

export function updateFixedStageState(scope: Scope) {
  const { documentServices } = scope.editorAPI;
  const isDesiredSiteWidth = isCurrentSiteWidthEqualsToNew(documentServices);

  fixedStageUtils._setFixedStageEnabled(isDesiredSiteWidth);
}
