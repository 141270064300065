// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as util from '@/util';
import * as addBlog from '../../../../addPanel/addBlog';
import * as blogUtils from '../../../../utils/blogUtils';
import constants from '@/constants';
import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'promotionalStep1Blog',
  mixins: [core.mixins.editorAPIMixin, util.propTypesFilterMixin],
  installApp(done) {
    const editorAPI = this.getEditorAPI();
    const currentPage = editorAPI.dsRead.pages.getFocusedPageId();
    addBlog.addBlogPage(editorAPI, undefined, 'button', function () {
      editorAPI.history.add('adding a blog', { currentPage });
      if (done && _.isFunction(done)) {
        done();
      }
    });
  },
  openAppMarket() {
    const editorAPI = this.getEditorAPI();
    const biHelpParams = {
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
      panel_name: blogUtils.superAppPanelName,
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(
      'ccb372ad-1ae2-46c8-90d7-34e6534624a9',
      null,
      biHelpParams,
    );
  },
  getSliderImages() {
    return [
      util.media.getMediaUrl('blog/appMenuPanel/gallery/blogGallery01.png'),
      util.media.getMediaUrl('blog/appMenuPanel/gallery/blogGallery02.png'),
      util.media.getMediaUrl('blog/appMenuPanel/gallery/blogGallery03.png'),
      util.media.getMediaUrl('blog/appMenuPanel/gallery/blogGallery04.png'),
    ];
  },
  render() {
    return (
      <superAppMenuInfra.firstTimeTourPanels.installAppSlide
        installApp={this.installApp}
        origin="promotionalPanel"
        superAppPanelName={blogUtils.superAppPanelName}
        superAppButtonLabel={blogUtils.superAppButtonLabel}
        superAppPanelClassName={blogUtils.superAppPanelClassName}
        superAppSymbolName={blogUtils.superAppSymbolName}
        title="SiteApp_Blog_PromotionalFTUE_Step1_Title"
        subTitle="SiteApp_Blog_PromotionalFTUE_Step1_SubTitle"
        pros={[
          'SiteApp_Blog_PromotionalFTUE_Step1_Bullet1',
          'SiteApp_Blog_PromotionalFTUE_Step1_Bullet2',
          'SiteApp_Blog_PromotionalFTUE_Step1_Bullet3',
        ]}
        teaser="SiteApp_Blog_PromotionalFTUE_Step1_BeforeButtonText"
        nextButtonLabel="SiteApp_Blog_PromotionalFTUE_Step1_NextButton_Caption"
        openAppMarketLabel="SiteApp_Blog_PromotionalFTUE_Step1_SeeInAppMarket_LinkCaption"
        openAppMarket={this.openAppMarket}
        loadingLabel="SiteApp_Blog_PromotionalFTUE_Step2_Loader_Caption"
        sliderImages={this.getSliderImages()}
        installImage="blogSpecialBtnPromotional"
        {...this.filteredProps()}
      />
    );
  },
});
