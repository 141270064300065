:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_6va6m_7 {
  position: relative;
  display: inline-flex; }
  ._container_6va6m_7._withDot_6va6m_10:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #e62214;
    border-radius: 50%;
    border: 1px solid #fff;
    display: block;
    top: -5px;
    right: -5px;
    position: absolute;
    box-sizing: border-box; }

._icon_6va6m_23 {
  border-radius: 4px;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px); }
  ._icon_6va6m_23._withBorder_6va6m_27 {
    border: 2px solid #fff; }
