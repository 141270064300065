import type { MigrationFlow } from '../types';

export const createMigrationService = () => {
  let flow: MigrationFlow = null;

  return {
    setFlow: (newFlow: MigrationFlow) => {
      flow = newFlow;
    },
    getFlow: () => {
      return flow;
    },
  };
};
