import React from 'react';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import { Text } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import * as util from '@/util';

interface WarnForDisconnectedComponentsPanelProps {
  undo: () => void;
  connectedComps: boolean;
}

const WarnForDisconnectedComponentsPanel: React.FC<
  WarnForDisconnectedComponentsPanelProps
> = ({ undo, connectedComps }) => {
  const getTitle = () => {
    const key = connectedComps
      ? 'PLATFORM_messages_move_connected_element_title'
      : 'PLATFORM_messages_move_connected_dataset_title';
    return translate(key);
  };

  const getContent = () => {
    if (connectedComps) {
      return (
        <div className="content">
          <Text
            skin="secondary"
            size="medium"
            enableEllipsis={false}
            shouldTranslate={false}
          >
            {translate('PLATFORM_messages_move_connected_element_text_line1')}
          </Text>
          <br />
          <Text
            skin="secondary"
            size="medium"
            enableEllipsis={false}
            shouldTranslate={false}
          >
            {translate('PLATFORM_messages_move_connected_element_text_line2')}
          </Text>
        </div>
      );
    }
    return (
      <div className="content">
        <Text
          skin="secondary"
          size="medium"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {translate('PLATFORM_messages_move_connected_dataset_text_line1')}
        </Text>
        <br />
        <Text
          skin="secondary"
          size="medium"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {translate('PLATFORM_messages_move_connected_dataset_text_line2')}
        </Text>
      </div>
    );
  };

  const isNewWorkspaceEnabled = util.workspace.isNewWorkspaceEnabled();
  return isNewWorkspaceEnabled ? (
    <panels.frames.MessagePanelFrame
      panelName="platform.panels.warnForDisconnectedComponentsPanel"
      onCancel={undo}
      confirmLabel={translate('PLATFORM_messages_continue')}
      title={getTitle()}
      cancelLabel={translate('PLATFORM_messages_cancel')}
      theme="destructive"
      illustration={<baseUI.symbol name="platformWarning" />}
    >
      {getContent()}
    </panels.frames.MessagePanelFrame>
  ) : (
    <panels.frames.ConfirmationPanelFrame
      type="important"
      panelName="platform.panels.warnForDisconnectedComponentsPanel"
      onCancel={undo}
      confirmLabel="PLATFORM_messages_continue"
      cancelLabel="PLATFORM_messages_cancel"
      shouldCloseByDefaultOnConfirm={true}
      panelTitle={getTitle()}
    >
      <div className="icon">
        <baseUI.symbol name="platformWarning" />
      </div>
      {connectedComps ? (
        <div key="connectedCompsContent" className="content">
          <p>
            {translate('PLATFORM_messages_move_connected_element_text_line1')}
          </p>
          <p>
            {translate('PLATFORM_messages_move_connected_element_text_line2')}
          </p>
        </div>
      ) : null}

      {!connectedComps ? (
        <div key="connectedDatasetContent" className="content">
          <p>
            {translate('PLATFORM_messages_move_connected_dataset_text_line1')}
          </p>
          <p>
            {translate('PLATFORM_messages_move_connected_dataset_text_line2')}
          </p>
        </div>
      ) : null}
    </panels.frames.ConfirmationPanelFrame>
  );
};

export default WarnForDisconnectedComponentsPanel;
