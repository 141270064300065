import _ from 'lodash';
import { BasePublicApi } from '@/apilib';
import { isMeshLayoutEnabled } from '@/layoutOneDockMigration';
import { sections, selection, multilingual } from '@/stateManagement';
import constants from '@/constants';
import { sections as sectionsUtils } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { compWithLayoutMapper } from './utils';

import type { CompRef, CompLayout } from 'types/documentServices';
import type { Scope } from './scope';

export interface SectionWithLayout {
  ref: CompRef;
  layout: CompLayout;
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

type NonRemovableData =
  | { type: 'lastSection' }
  | { type: 'containsNonRemovableTPA'; nonRemovableTPARef: CompRef };

export const addBlankSection = (
  scope: Scope,
  containerRef: CompRef,
  structureOverrides: { height: number; y: number; name?: string },
  {
    origin,
    index = 0,
  }: {
    origin?: string;
    index?: number;
  } = {},
) =>
  scope.components.add(
    containerRef,
    sectionsUtils.getBlankSectionStructure(structureOverrides),
    undefined,
    undefined,
    origin,
    {
      optionalIndex: index,
    },
  );

export const isSection = (scope: Scope, compRef: CompRef) => {
  if (!compRef) return false;

  return isSectionByCompType(scope.components.getType(compRef));
};

export const isSectionByCompType = (compType: string) => {
  return compType === constants.COMP_TYPES.SECTION;
};

export const isHeader = ({ editorAPI }: Scope, compRef: CompRef) =>
  editorAPI.utils.isSameRef(compRef, editorAPI.siteSegments.getHeader());

export const isFooter = ({ editorAPI }: Scope, compRef: CompRef) =>
  editorAPI.utils.isSameRef(compRef, editorAPI.siteSegments.getFooter());

export const isHeaderOrFooter = (scope: Scope, compRef: CompRef) => {
  return isHeader(scope, compRef) || isFooter(scope, compRef);
};

export const isSectionLike = (scope: Scope, compRef: CompRef) =>
  [isHeader, isFooter, isSection].some((predicate) =>
    predicate(scope, compRef),
  );

export const hasTransformationsOnCurrentPage = (scope: Scope) => {
  const { editorAPI } = scope;
  const pageSectionLike = getPageSectionLike(
    scope,
    editorAPI.pages.getCurrentPage(),
  );
  return pageSectionLike.some(editorAPI.components.transformations.get);
};

export const clearSectionTransformationsOnCurrentPage = async (
  scope: Scope,
) => {
  const { editorAPI } = scope;
  getPageSectionLike(scope, editorAPI.pages.getCurrentPage()).forEach(
    editorAPI.components.transformations.remove,
  );
  await editorAPI.documentServices.waitForChangesAppliedAsync();
};

export const getSectionName = (scope: Scope, compRef: CompRef) =>
  scope.editorAPI.components.anchor.get(compRef)?.name;

export const getClosestSection = (scope: Scope, compRef: CompRef) =>
  scope.editorAPI.components.findAncestor(
    compRef,
    (compOrAncestorRef) => isSection(scope, compOrAncestorRef),
    { includeSelf: true, includeScopeOwner: true },
  );

export const getClosesHeaderFooter = (scope: Scope, compRef: CompRef) =>
  scope.editorAPI.components.findAncestor(
    compRef,
    (compOrAncestorRef) => isHeaderOrFooter(scope, compOrAncestorRef),
    { includeSelf: true, includeScopeOwner: true },
  );

export const getClosestSectionLike = (scope: Scope, compRef: CompRef) =>
  scope.editorAPI.components.findAncestor(
    compRef,
    (compOrAncestorRef) => isSectionLike(scope, compOrAncestorRef),
    { includeSelf: true, includeScopeOwner: true },
  );

export const getFocusedSection = (scope: Scope) => {
  const state = scope.editorAPI.store.getState();
  const focusedContainer =
    selection.selectors.getFocusedContainer(state) ||
    selection.selectors.getAppContainer(state);
  return getClosestSection(scope, focusedContainer);
};

export const getFocusedSectionLike = (scope: Scope) => {
  const state = scope.editorAPI.store.getState();
  const focusedContainer =
    selection.selectors.getFocusedContainer(state) ||
    selection.selectors.getAppContainer(state);
  return getClosestSectionLike(scope, focusedContainer);
};

export const getHoveredSection = (scope: Scope) => {
  const hoveredSectionLike = getHoveredSectionLike(scope);
  return isSection(scope, hoveredSectionLike) ? hoveredSectionLike : null;
};

export const getHoveredHeaderFooter = (scope: Scope) => {
  const hoveredSectionLike = getHoveredSectionLike(scope);

  return isHeaderOrFooter(scope, hoveredSectionLike)
    ? hoveredSectionLike
    : null;
};

export const getHoveredSectionLike = ({ editorAPI }: Scope) => {
  const ref = sections.selectors.getHoveredSectionLikeRef(
    editorAPI.store.getState(),
  );
  return editorAPI.components.is.exist(ref) ? ref : null;
};

export const getFocusedHeaderFooter = (scope: Scope) => {
  const state = scope.editorAPI.store.getState();
  const focusedContainer =
    selection.selectors.getFocusedContainer(state) ||
    selection.selectors.getAppContainer(state);
  return getClosesHeaderFooter(scope, focusedContainer);
};

export const getPageSections = ({ editorAPI }: Scope, pageRef: CompRef) =>
  editorAPI.components.get.byType(constants.COMP_TYPES.SECTION, pageRef);

export const getPageSectionsSortedByStageOrder = (
  scope: Scope,
  pageRef: CompRef,
): CompRef[] =>
  getPageSectionsWithLayoutSortedByStageOrder(scope, pageRef).map(
    (sectionWithLayout) => sectionWithLayout.ref,
  );

export const getPageSectionsWithLayoutSortedByStageOrder = (
  scope: Scope,
  pageRef: CompRef,
  useRelativeToStructureLayout = false,
): SectionWithLayout[] =>
  getPageSectionsWithLayout(scope, pageRef, useRelativeToStructureLayout).sort(
    isMeshLayoutEnabled()
      ? (sectionA, sectionB) =>
          scope.components.arrangement.getCompIndex(sectionA.ref) -
          scope.components.arrangement.getCompIndex(sectionB.ref)
      : (sectionA, sectionB) => sectionA.layout.y - sectionB.layout.y,
  );

export const getPageSectionsWithLayout = (
  scope: Scope,
  pageRef: CompRef,
  useRelativeToStructureLayout = false,
): SectionWithLayout[] =>
  getPageSections(scope, pageRef).map((sectionRef) =>
    compWithLayoutMapper(
      scope.editorAPI,
      sectionRef,
      useRelativeToStructureLayout,
    ),
  );

export const getPageSectionLike = (scope: Scope, pageRef: CompRef) => {
  const { editorAPI } = scope;
  const shouldIncludeSiteSegments = !editorAPI.pages.isLandingPage(pageRef.id);
  const sections = getPageSections(scope, pageRef);

  if (!shouldIncludeSiteSegments) return sections;

  return [
    editorAPI.siteSegments.getHeader(),
    editorAPI.siteSegments.getFooter(),
    ...sections,
  ];
};

export const getPageSectionLikeSortedByStageOrder = (
  scope: Scope,
  pageRef: CompRef,
): CompRef[] =>
  getPageSectionsWithLayout(scope, pageRef)
    .sort((a, b) => a.layout.y - b.layout.y)
    .map((sectionWithLayout) => sectionWithLayout.ref);

export const getPageSectionLikeWithLayoutSortedByStageOrder = (
  scope: Scope,
  pageRef: CompRef,
  useRelativeToStructureLayout = false,
): SectionWithLayout[] =>
  getPageSectionLikeWithLayout(
    scope,
    pageRef,
    useRelativeToStructureLayout,
  ).sort((sectionA, sectionB) => sectionA.layout.y - sectionB.layout.y);

export const getPageSectionLikeWithLayout = (
  scope: Scope,
  pageRef: CompRef,
  useRelativeToStructureLayout = false,
): SectionWithLayout[] =>
  getPageSectionLike(scope, pageRef).map((sectionRef) =>
    compWithLayoutMapper(
      scope.editorAPI,
      sectionRef,
      useRelativeToStructureLayout,
    ),
  );

export const getAllSections = ({ editorAPI }: Scope) =>
  editorAPI.components.get.byType(constants.COMP_TYPES.SECTION);

export const getSectionAtY = (
  scope: Scope,
  yRelativeToStructure: number,
): CompRef | undefined => {
  if (isMeshLayoutEnabled()) {
    const yRelatoveToViewport =
      yRelativeToStructure - scope.editorAPI.scroll.get().scrollTop;
    return scope.editorAPI.components.get
      .byXYFromDom(0, yRelatoveToViewport)
      .find((comp) => isSection(scope, comp));
  }

  const X_COORDINATE = 1; // 0 doesn't return any components

  return scope.editorAPI.components.get
    .byXYRelativeToStructure(X_COORDINATE, yRelativeToStructure)
    .find((comp) => isSection(scope, comp));
};

export const getSectionLikeAtY = (
  scope: Scope,
  yRelativeToStructure: number,
): CompRef | undefined => {
  if (isMeshLayoutEnabled()) {
    const yRelatoveToViewport =
      yRelativeToStructure - scope.editorAPI.scroll.get().scrollTop;
    return scope.editorAPI.components.get
      .byXYFromDom(0, yRelatoveToViewport)
      .find((comp) => isSectionLike(scope, comp));
  }

  const X_COORDINATE = 1; // 0 doesn't return any components

  return scope.editorAPI.components.get
    .byXYRelativeToStructure(X_COORDINATE, yRelativeToStructure)
    .find((comp) => isSectionLike(scope, comp));
};

export const scrollIntoView = async (
  { editorAPI, components }: Scope,
  currentRef: CompRef,
): Promise<void> => {
  const DANIEL_MAGIC_GAP = 120;
  await editorAPI.waitForChangesAppliedAsync();
  return new Promise((resolve) => {
    editorAPI.scroll.animateScrollTo(
      {
        y:
          (components.layout.getRelativeToScreen(currentRef)?.y -
            DANIEL_MAGIC_GAP) *
          editorAPI.getSiteScale(),
        x: 0,
      },
      1,
      resolve,
    );
  });
};

export const reorderSections = async (
  scope: Scope,
  currentRef: CompRef,
  direction: Direction,
  scrollToSection: boolean = true,
) => {
  const { components, history, editorAPI } = scope;

  if (isMeshLayoutEnabled()) {
    const currentSectionIndexTarget =
      editorAPI.components.arrangement.getCompIndex(currentRef) +
      (direction === Direction.Down ? 1 : -1);

    await editorAPI.components.arrangement.moveToIndex(
      currentRef,
      currentSectionIndexTarget,
    );
  } else {
    const currentLayout = components.layout.get_rect(currentRef);
    const sectionRefToSwap =
      direction === Direction.Down
        ? getSectionBelow(scope, currentRef)
        : getSectionAbove(scope, currentRef);

    const swapWithCompLayout = components.layout.get_rect(sectionRefToSwap);

    const [newCurrentSectionY, newSectionToSwapY] =
      direction === Direction.Down
        ? [currentLayout.y + swapWithCompLayout.height, currentLayout.y]
        : [swapWithCompLayout.y, swapWithCompLayout.y + currentLayout.height];

    components.layout.update(
      sectionRefToSwap,
      {
        y: newSectionToSwapY,
      },
      true,
    );

    components.layout.update(
      currentRef,
      {
        y: newCurrentSectionY,
      },
      true,
    );
  }

  history.add(`sections reorder: move ${direction}`);

  editorAPI.store.dispatch(multilingual.actions.componentChanged());

  if (!scrollToSection) return;

  return scrollIntoView(scope, currentRef);
};

export const moveDown = async (
  scope: Scope,
  currentRef: CompRef,
  scrollToSection?: boolean,
) => await reorderSections(scope, currentRef, Direction.Down, scrollToSection);

export const moveUp = async (
  scope: Scope,
  currentRef: CompRef,
  scrollToSection?: boolean,
) => await reorderSections(scope, currentRef, Direction.Up, scrollToSection);

export const getSectionAbove = ({ components }: Scope, currentRef: CompRef) => {
  const currentLayout = components.layout.get_rect(currentRef);
  const currentTop = currentLayout.y;
  const candidateSections = components.getSiblings(currentRef);

  const candidatesAbove = candidateSections
    .map((candidate) => ({
      ref: candidate,
      position: components.layout.get_position(candidate),
    }))
    .filter((candidate) => {
      const candidateTop = candidate.position.y;
      return currentTop >= candidateTop;
    });

  if (!candidatesAbove.length) return null;

  return _.minBy(candidatesAbove, (candidate) => {
    const candidateTop = candidate.position.y;
    return currentTop - candidateTop;
  }).ref;
};

export const getSectionBelow = ({ components }: Scope, currentRef: CompRef) => {
  const currentLayout = components.layout.get_rect(currentRef);
  const currentTop = currentLayout.y;
  const candidateSections = components.getSiblings(currentRef);

  const candidatesBelow = candidateSections
    .map((candidate) => ({
      ref: candidate,
      position: components.layout.get_position(candidate),
    }))
    .filter((candidate) => {
      const candidateTop = candidate.position.y;
      return currentTop <= candidateTop;
    });

  if (!candidatesBelow.length) return null;

  return _.minBy(candidatesBelow, (candidate) => {
    const candidateTop = candidate.position.y;
    return candidateTop - currentTop;
  }).ref;
};

export const canMoveUp = (scope: Scope, compRef: CompRef) =>
  Boolean(getSectionAbove(scope, compRef));

export const canMoveDown = (scope: Scope, compRef: CompRef) =>
  Boolean(getSectionBelow(scope, compRef));

export const isBlankSection = (scope: Scope, compRef: CompRef) => {
  if (!isSection(scope, compRef)) return false;

  const hasChildren = scope.components.getChildren(compRef).length > 0;
  const designData = scope.components.design.get(compRef);
  const isTransparent = designData.background.colorOpacity === 0;
  const withoutMediaBg = !designData.background.mediaRef;

  return !hasChildren && isTransparent && withoutMediaBg;
};

const TRANSPARENT_SITE_SEGMENT_SKIN =
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen';
export const isBlankSiteSegment = (scope: Scope, compRef: CompRef) => {
  if (!isHeaderOrFooter(scope, compRef)) return false;

  const compStyle = scope.components.style.get([compRef]);
  const skin = compStyle?.skin;
  const style = compStyle?.style;

  if (skin && skin !== TRANSPARENT_SITE_SEGMENT_SKIN) {
    const alphaBgValue = Number(style?.properties?.['alpha-bg']);
    const alphaBgCenterValue = Number(style?.properties?.['alpha-bgctr']);

    if (alphaBgValue !== 0 || alphaBgCenterValue !== 0) {
      return false;
    }
  }

  const children = scope.components.getChildren(compRef);
  const hasVisibleChildren =
    children.filter((child) => scope.editorAPI.components.is.visible(child))
      .length > 0;

  return !hasVisibleChildren;
};

export const getEmptyStateSection = (scope: Scope, page?: CompRef) => {
  const handledPage = page || scope.editorAPI.pages.getFocusedPage();
  const pageSections = getPageSections(scope, handledPage);
  return pageSections.length === 1 && isBlankSection(scope, pageSections[0])
    ? pageSections[0]
    : null;
};

export const isEmptyState = (scope: Scope) => {
  const emptyStateSection = getEmptyStateSection(scope);

  return Boolean(emptyStateSection);
};

export const getSelectedSection = (scope: Scope) => {
  const [selectedComp] = scope.editorAPI.selection.getSelectedComponents();
  return isSection(scope, selectedComp) ? selectedComp : null;
};

export const getSelectedHeaderFooter = (scope: Scope) => {
  const [selectedComp] = scope.editorAPI.selection.getSelectedComponents();
  return isHeaderOrFooter(scope, selectedComp) ? selectedComp : null;
};

export const getSelectedSectionLike = (scope: Scope) => {
  const [selectedComp] = scope.editorAPI.selection.getSelectedComponents();
  return isSectionLike(scope, selectedComp) ? selectedComp : null;
};

export const getName = ({ editorAPI }: Scope, sectionRef: CompRef) =>
  editorAPI.components.anchor.get(sectionRef).name;

export const rename = (
  { editorAPI }: Scope,
  sectionRef: CompRef,
  name: string,
  dontAddToUndoRedoStack: boolean = false,
) => {
  editorAPI.components.anchor.update(sectionRef, { name });
  if (!dontAddToUndoRedoStack) {
    editorAPI.history.add(`section renamed to ${name}`);
  }
  editorAPI.store.dispatch(multilingual.actions.componentChanged());
};

export const removeBottomGap = ({ editorAPI }: Scope, sectionRef: CompRef) => {
  // the height of a container component should not be smaller than the bottom of its lowest children.
  // updateAndAdjustLayout should consider children under the hood and not allow to make container height less than bottom child bottom
  editorAPI.components.layout.updateAndAdjustLayout(sectionRef, {
    height: 1,
  });
};

export const getNonRemovableData = (
  scope: Scope,
  compRef: CompRef,
): NonRemovableData | undefined => {
  const { editorAPI } = scope;
  const nonRemovableTPAChild =
    editorAPI.components.getNonRemovableTPAChild(compRef);

  if (nonRemovableTPAChild) {
    return {
      type: 'containsNonRemovableTPA',
      nonRemovableTPARef: nonRemovableTPAChild,
    };
  }

  const pageSections = getPageSections(scope, editorAPI.pages.getFocusedPage());
  const isLastSection = pageSections.length === 1;

  if (isLastSection) {
    if (editorAPI.isMobileEditor() || isBlankSection(scope, compRef)) {
      return { type: 'lastSection' };
    }
  }
};

interface NonDuplicatableData {
  type: 'containsNonDuplicatableTPA';
  nonDuplicatableTPARef: CompRef;
}

export const getNonDuplicatableData = (
  scope: Scope,
  compRef: CompRef,
): NonDuplicatableData | undefined => {
  const nonDuplicatableTPAChild =
    scope.editorAPI.components.getNonDuplicatableTPAChild(compRef);

  if (nonDuplicatableTPAChild) {
    return {
      type: 'containsNonDuplicatableTPA',
      nonDuplicatableTPARef: nonDuplicatableTPAChild,
    };
  }
};

export const shouldShowBoExposerOnCurrentMode = ({
  editorAPI,
}: Scope): boolean => {
  return !editorAPI.isMobileEditor() && !editorAPI.zoomMode.isStageZoomMode();
};

export const getExposeBOWidgets = (
  { editorAPI, selection }: Scope,
  sectionRef: CompRef,
): CompRef[] => {
  try {
    const widgets = editorAPI.tpa.getUniqueChildrenWidgets(sectionRef);
    const selectedComponentsIdsSet = new Set<string>(
      selection.getSelectedComponents().map(({ id }) => id),
    );
    if (
      widgets.some((widgetRef) => selectedComponentsIdsSet.has(widgetRef.id))
    ) {
      return [];
    }

    return widgets;
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        uniqueSectionWidgets: true,
      },
    });

    return [];
  }
};

export const isFirstTimeParentSectionLikeFocused = (
  scope: Scope,
  compRef: CompRef,
): boolean => {
  const { editorAPI } = scope;
  const componentParentSectionLike = getClosestSectionLike(scope, compRef);
  const prevSelectedComponents = selection.selectors.getPrevSelectedComponents(
    editorAPI.store.getState(),
  );

  return prevSelectedComponents
    .map((comp) => getClosestSectionLike(scope, comp))
    .every(
      (prevSelectedParentSectionLike) =>
        !editorAPI.utils.isSameRef(
          prevSelectedParentSectionLike,
          componentParentSectionLike,
        ),
    );
};

export class SectionsApi extends BasePublicApi<Scope> {
  hooks = this.scope.hooks;
  setEnforcementEnabled = this.scope.enforcement.setEnforcementEnabled;
  isEnforcementEnabled = this.scope.enforcement.isEnforcementEnabled;
  _enforceSectionContainer = this.scope.enforceSectionOnPageService.run;
  __forceEnforceContainerOnPage = this.scope.enforceSectionOnPageService._force;

  addBlankSection = this.bindScope(addBlankSection);
  getBlankSectionStructure = sectionsUtils.getBlankSectionStructure;
  isBlankSection = this.bindScope(isBlankSection);
  isBlankSiteSegment = this.bindScope(isBlankSiteSegment);
  getEmptyStateSection = this.bindScope(getEmptyStateSection);
  isEmptyState = this.bindScope(isEmptyState);
  getSectionName = this.bindScope(getSectionName);
  isSection = this.bindScope(isSection);
  isSectionByCompType = isSectionByCompType;
  isHeader = this.bindScope(isHeader);
  isFooter = this.bindScope(isFooter);
  isHeaderOrFooter = this.bindScope(isHeaderOrFooter);
  isSectionLike = this.bindScope(isSectionLike);
  hasTransformationsOnCurrentPage = this.bindScope(
    hasTransformationsOnCurrentPage,
  );
  clearSectionTransformationsOnCurrentPage = this.bindScope(
    clearSectionTransformationsOnCurrentPage,
  );
  getHoveredSection = this.bindScope(getHoveredSection);
  getHoveredHeaderFooter = this.bindScope(getHoveredHeaderFooter);
  getHoveredSectionLike = this.bindScope(getHoveredSectionLike);
  getSelectedSection = this.bindScope(getSelectedSection);
  getSelectedSectionLike = this.bindScope(getSelectedSectionLike);
  getSelectedHeaderFooter = this.bindScope(getSelectedHeaderFooter);
  getFocusedSection = this.bindScope(getFocusedSection);
  getFocusedSectionLike = this.bindScope(getFocusedSectionLike);
  getFocusedHeaderFooter = this.bindScope(getFocusedHeaderFooter);
  getPageSections = this.bindScope(getPageSections);
  getAllSections = this.bindScope(getAllSections);
  getPageSectionsWithLayout = this.bindScope(getPageSectionsWithLayout);
  getPageSectionsSortedByStageOrder = this.bindScope(
    getPageSectionsSortedByStageOrder,
  );
  getPageSectionsWithLayoutSortedByStageOrder = this.bindScope(
    getPageSectionsWithLayoutSortedByStageOrder,
  );
  getPageSectionLike = this.bindScope(getPageSectionLike);
  getPageSectionLikeWithLayout = this.bindScope(getPageSectionLikeWithLayout);
  getPageSectionLikeSortedByStageOrder = this.bindScope(
    getPageSectionLikeSortedByStageOrder,
  );
  getPageSectionLikeWithLayoutSortedByStageOrder = this.bindScope(
    getPageSectionLikeWithLayoutSortedByStageOrder,
  );
  getSectionAtY = this.bindScope(getSectionAtY);
  getSectionLikeAtY = this.bindScope(getSectionLikeAtY);
  getSectionAbove = this.bindScope(getSectionAbove);
  getSectionBelow = this.bindScope(getSectionBelow);
  canMoveUp = this.bindScope(canMoveUp);
  canMoveDown = this.bindScope(canMoveDown);
  getNonRemovableData = this.bindScope(getNonRemovableData);
  getNonDuplicatableData = this.bindScope(getNonDuplicatableData);
  getExposeBOWidgets = this.bindScope(getExposeBOWidgets);
  shouldShowBoExposerOnCurrentMode = this.bindScope(
    shouldShowBoExposerOnCurrentMode,
  );
  getClosestSection = this.bindScope(getClosestSection);
  getClosestSectionLike = this.bindScope(getClosestSectionLike);
  isFirstTimeParentSectionLikeFocused = this.bindScope(
    isFirstTimeParentSectionLikeFocused,
  );
  moveDown = this.bindScope(moveDown);
  moveUp = this.bindScope(moveUp);
  removeBottomGap = this.bindScope(removeBottomGap);
  getName = this.bindScope(getName);
  rename = this.bindScope(rename);
}
