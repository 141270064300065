import React, { type FC } from 'react';

import { frames } from '@/panels';

import { Checkbox, Text } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import styles from './PublishErrorPanel.scss';

interface PublishErrorPanelDevUIProps {
  showPanelAgain: boolean;
  setShowPanelAgain: any;
  errorResult: any;
  handleClose: (origin: string) => void;
  handleOpenBuildLog: () => void;
}

const PublishErrorPanelDevUI: FC<PublishErrorPanelDevUIProps> = ({
  showPanelAgain,
  setShowPanelAgain,
  errorResult,
  handleClose,
  handleOpenBuildLog,
}) => (
  <frames.CustomPanelFrame
    title="Publish halted due to errors"
    onOuterClick={() => handleClose('outer_click')}
    onCloseButtonClick={() => handleClose('x')}
    onPrimaryButtonClick={handleOpenBuildLog}
    panelName="publishErrorPanel"
    primaryButtonText="Open build.log"
    secondaryButtonText="Publish anyway"
    sideActions={
      <div className={styles.checkboxWrapper}>
        <Checkbox
          value={showPanelAgain}
          onChange={setShowPanelAgain}
          vAlign="CENTER"
        />
        <Text size="medium" skin="secondary" enableEllipsis={false}>
          Ignore code validation for the current session
        </Text>
      </div>
    }
  >
    {
      <div className={styles.wrapper_devMode}>
        <Text size="medium" skin="secondary" enableEllipsis={false}>
          Errors in your site’s code were found. Publishing a site with code
          errors may break parts of the site. Open build.log for more
          information.
        </Text>

        <div className={styles.list}>
          <div className={styles.list_name}>
            {errorResult.map((error: any) => (
              <div>
                <Symbol
                  name={
                    error.status === 'done' ? 'cyclePickerV' : 'cyclePickerX'
                  }
                />
                <Text size="medium" skin="secondary" enableEllipsis={false}>
                  {error.name}
                </Text>
              </div>
            ))}
          </div>
          <div className={styles.list_result}>
            {errorResult.map((error: any) => (
              <Text
                size="medium"
                skin={error.status === 'done' ? 'success' : 'alert'}
                enableEllipsis={false}
              >
                {error.status}
              </Text>
            ))}
          </div>
        </div>
      </div>
    }
  </frames.CustomPanelFrame>
);

export default PublishErrorPanelDevUI;
