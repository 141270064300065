import { createReduxStore } from '@/apilib';

interface WixStoreState {
  isNewStoreCategoryUser?: boolean;
}

const getInitialState = (): WixStoreState => ({
  isNewStoreCategoryUser: false,
});

export const WixStorePackageStore = createReduxStore({
  getInitialState,
  selectors: {
    getIsNewStoreCategoryUser: (state: WixStoreState) =>
      state.isNewStoreCategoryUser,
  },
  actions: {
    setIsNewStoreCategoryUser: (
      state: WixStoreState,
      isNewStoreCategoryUser: WixStoreState['isNewStoreCategoryUser'],
    ) => {
      return {
        ...state,
        isNewStoreCategoryUser,
      };
    },
  },
});
