// @ts-nocheck
import _ from 'lodash';

/**
 * general todo: token is a placeholder for a parameter that will be used distinguish between applications
 */

function readFile(editorAPI, appData, token, options) {
  const fs = editorAPI.wixCode.fileSystem;
  const fileDescriptor = fs.getVirtualDescriptor(options.path, false);

  return fs.readFile(fileDescriptor);
}

function listChildren(editorAPI, appData, token, options) {
  const fs = editorAPI.wixCode.fileSystem;
  const pathDescriptor = fs.getVirtualDescriptor(options.path, true);

  return fs.getChildren(pathDescriptor).then(function (children) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(children, mapVfsDescriptors);
  });
}

function mapVfsDescriptors(vfsItemDescriptor) {
  return {
    name: vfsItemDescriptor.name,
    fullPath: vfsItemDescriptor.location,
    isDirectory: vfsItemDescriptor.directory,
  };
}

function writeFile(editorAPI, appData, token, options) {
  const fs = editorAPI.wixCode.fileSystem;
  const fileDescriptor = fs.getVirtualDescriptor(options.path, false);

  return fs.writeFile(fileDescriptor, options.content);
}

function deleteFile(editorAPI, appData, token, options) {
  const fs = editorAPI.wixCode.fileSystem;
  const fileDescriptor = fs.getVirtualDescriptor(options.path, false);

  return fs.deleteItem(fileDescriptor);
}

function flush(editorAPI) {
  return editorAPI.wixCode.fileSystem.flush();
}

export { listChildren, readFile, writeFile, deleteFile, flush };
