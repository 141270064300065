import { useEffect } from 'react';
import { hoc } from '@/util';
import type { MapDispatchToProps, ThunkAction } from 'types/redux';

interface OwnProps {}
interface SpotlightStageScrollStateProps {}
interface SpotlightStageScrollDispatchProps {
  scrollContainerByDelta: (event: WheelEvent) => void;
}

interface SpotlightStageScrollProps
  extends OwnProps,
    SpotlightStageScrollStateProps,
    SpotlightStageScrollDispatchProps {}

const Component: React.FC<SpotlightStageScrollProps> = ({
  scrollContainerByDelta,
}) => {
  const handleMouseWheel = (event: WheelEvent) => {
    event.stopPropagation();
    scrollContainerByDelta(event);
  };

  useEffect(() => {
    window.addEventListener('wheel', handleMouseWheel);

    return () => {
      window.removeEventListener('wheel', handleMouseWheel);
    };
  });

  return null;
};

const scrollContainerByDelta = (event: WheelEvent): ThunkAction => {
  return (dispatch, getState, { editorAPI }) => {
    editorAPI.spotlightStage.scrollByDelta(event.deltaY);
  };
};

const mapDispatchToProps: MapDispatchToProps<
  SpotlightStageScrollDispatchProps,
  OwnProps
> = {
  scrollContainerByDelta,
};

const Connected = hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(Component);

export const SpotlightStageScroll = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ editorAPI }) => Boolean(editorAPI.spotlightStage.getContainer()),
)(Connected);
