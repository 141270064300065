// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { translate } from '@/i18n';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface LanguageUnpublishPanelProps {
  panelName?: string;
  closePanel: FunctionFixMe;
  sendClosedBi: FunctionFixMe;
  deleteCB: FunctionFixMe;
}

class LanguageUnpublishPanel extends React.Component<LanguageUnpublishPanelProps> {
  static displayName = 'languagePickerDeletePanel';

  static propTypes = {
    panelName: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
    sendClosedBi: PropTypes.func.isRequired,
    deleteCB: PropTypes.func.isRequired,
  };

  onCancel = () => {
    this.props.sendClosedBi('cancel');
    this.props.closePanel();
  };

  onClose = () => {
    this.props.sendClosedBi('close');
    this.props.closePanel();
  };

  onConfirm = () => {
    this.props.deleteCB();
    this.props.sendClosedBi('confirm');
    this.props.closePanel();
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader
        shouldNotCloseOnBlur
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
        className="language-picker-delete-panel"
      >
        <Composites.PopupSmallSize>
          <PanelHeader noHelpBtn onClose={this.onClose} className="dark">
            {translate('Notification_Box_Multilingual_Delete_Menu_Header')}
          </PanelHeader>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              <Illustration>
                <symbols.symbol name="deleteLanguagePicker" />
              </Illustration>

              <Composites.RichText>
                <RichText type="T01">
                  {translate('Notification_Box_Multilingual_Delete_Menu_Text1')}
                  <br />
                  {translate('Notification_Box_Multilingual_Delete_Menu_Text2')}
                </RichText>
              </Composites.RichText>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetHorizontal>
            <Button onClick={this.onCancel} className="secondary">
              <TextLabel value="Notification_Box_Multilingual_Delete_Menu_Cancel_Button" />
            </Button>
            <Button onClick={this.onConfirm} className="primary">
              <TextLabel value="Notification_Box_Multilingual_Delete_Menu_Delete_Button" />
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupSmallSize>
      </FocusPanelFrame>
    );
  }
}

const mapStateToProps = ({ editorAPI, dsRead }) => {
  const { language } = dsRead;
  const { delete_language_menu_panel_selection } = coreBi.events.multilingual;

  const page_id = editorAPI.pages.getCurrentPageId();

  const biParams = {
    primary_lang: language.original.get().languageCode,
    current_secondary_lng: language.current.get(),
    page_id,
    page_name: editorAPI.pages.getPageTitle(page_id),
  };

  return {
    sendClosedBi: (button_name) =>
      editorAPI.bi.event(
        delete_language_menu_panel_selection,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({ button_name }, biParams),
      ),
  };
};

const mapDispatchToProps = (dispatch, { panelName }) => ({
  closePanel: () =>
    dispatch(stateManagement.panels.actions.closePanelByNameAction(panelName)),
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LanguageUnpublishPanel);
