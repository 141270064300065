// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['mobilemenu_design_section_explore'],
      sections: [
        addPanel.sections.getSections().designPanelOnly.tinyMenuStyleSection,
      ],
    };
  },
};
