import _ from 'lodash';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import { editorWixRecorder } from '@/util';

import type { MapStateToProps } from 'types/redux';
import type {
  ToolsListItemsStateProps,
  ToolsListItemsOwnProps,
} from '../../menuBar/menuBarListItems/toolsMenu/toolsMenuStructure';

const { openDockedSections } = stateManagement.sectionedPanel.selectors;
const { getDeviceType } = stateManagement.leftBar.selectors;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const { getOverrideGetters } = stateManagement.topBar.selectors;

export const mapStateToProps: MapStateToProps<
  ToolsListItemsStateProps,
  ToolsListItemsOwnProps
> = ({ state, editorAPI }) => {
  const overrideGetters = getOverrideGetters(state);

  return {
    inInteractionMode: !!state.interactions.interactionName,
    isToolBarEnabled: Boolean(editorAPI.toolbar.isToolbarEnabled()),
    isSectionizerPanelDisplayed:
      stateManagement.sections.selectors.getIsSectionizedPanelOpened(state),
    isAiPersonalizeContentDisplayed:
      stateManagement.panels.selectors.isPanelOpenedByName(
        state,
        'ai.panels.toolPanel',
      ),
    isLayersPanelDisplayed:
      stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state),
    isRulersEnabled: Boolean(
      stateManagement.rulers.selectors.isRulersEnabled(state),
    ),
    isGuidelinesEnabled: Boolean(
      editorAPI.grid.guidelines.isGuidelinesEnabled(),
    ),
    isMobileEditor: editorAPI.isMobileEditor(),
    isSnapToEnabled: Boolean(editorAPI.grid.snapTo.isSnapToEnabled()),
    isDeveloperModeEnabled: Boolean(editorAPI.developerMode.isEnabled()),
    isDeveloperToolBarEnabled: Boolean(
      editorAPI.developerToolBar.isEnabled() ||
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/some
        _.some(
          openDockedSections(state),
          (sectionName) =>
            sectionName === constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
        ),
    ),
    isDeveloperToolbarInCodePanel: Boolean(
      editorAPI.developerToolBar.isInCodePanel(),
    ),
    isDeveloperHiddenComponentsEnabled: Boolean(
      editorAPI.developerHiddenComponents.isEnabled(),
    ),
    siteId: editorAPI.dsRead?.generalInfo.getSiteId(),
    deviceType: getDeviceType(state),
    developerModeFirstTime:
      getSiteUserPreferences<boolean>(
        constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME,
      )(state) || false,
    overriddenToolsMenu: _.invoke(
      overrideGetters,
      'getToolsMenuOverrides',
      state,
    ),
  };
};

export const mapDispatchToProps = {
  toggleToolBar:
    (...args: AnyFixMe[]) =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
      editorAPI.toolbar.toggleToolbar(...args);
      dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          'topBarCloseToolsUsed',
          true,
        ),
      );
    },

  toggleSectionizerPanel:
    () =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
      const state = editorAPI.store.getState();
      const isSectionizedPanelOpened =
        stateManagement.sections.selectors.getIsSectionizedPanelOpened(state);

      if (isSectionizedPanelOpened) {
        dispatch(stateManagement.sections.actions.hideSectionizedPanel());
      } else {
        dispatch(stateManagement.sections.actions.showSectionizedPanel());
      }
    },

  toggleLayersPanel:
    () =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
      const state = editorAPI.store.getState();
      const isLayersPanelDisplayed =
        stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state);

      if (isLayersPanelDisplayed) {
        dispatch(stateManagement.layersPanel.actions.hideLayersPanel());
      } else {
        const origin = 'tools menu';
        editorWixRecorder.addLabel(
          'panels.toolPanels.layersPanel panel opened',
        );
        editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
          panel_name: 'panels.toolPanels.layersPanel',
          origin,
        });
        dispatch(stateManagement.layersPanel.actions.showLayersPanel(origin));
      }
    },

  toggleRulers: stateManagement.rulers.actions.toggleRulers,

  toggleGuidelines:
    (...args: AnyFixMe[]) =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI.grid.guidelines.toggleGuidelines(...args),

  toggleSnapTo:
    (...args: AnyFixMe[]) =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI.grid.snapTo.toggleSnapTo(...args),

  toggleDeveloperToolBar:
    (...args: AnyFixMe[]) =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI.developerToolBar.toggle(...args),

  toggleDeveloperHiddenComponents:
    (...args: AnyFixMe[]) =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI.developerHiddenComponents.toggle(...args),
};
