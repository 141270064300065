export default {
  inlinePopup: true,
  fixedNonPinned: true,
  spotlightStageContainer: true,
  focusable: true,
  movable: false,
  verticallyMovable: false,
  horizontallyMovable: false,
  resizableWithAnchors: false,
  duplicatable: false,
  pinnable: false,
  rotatable: false,
  groupable: false,
  alignable: false,
  draggable: false,
  multiselectable: false,
  proportionallyResizable: false,
  selectWhenDeselectingChildren: true,
};
