import { hoc } from '@/util';
import React, { type FC } from 'react';
import { withRestrictions } from '@/editorRestrictions';
import GridLines from './gridLines';
import { mapStateToProps } from './gridLinesMapper';
import type { GridLinesContainerProps } from './types';

const GridLinesContainer: FC<GridLinesContainerProps> = ({
  isVisible,
  showContainerGridLines,
  isFade,
  containerGridLines,
  pageGridLines,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div key="gridLines" className="gridLines">
      <GridLines
        key="pageGridLines"
        dataHook="pageGridLines"
        gridLines={pageGridLines}
        fade={isFade}
      />

      {showContainerGridLines ? (
        <GridLines
          key="containerGridLines"
          dataHook="containerGridLines"
          gridLines={containerGridLines}
        />
      ) : null}
    </div>
  );
};

export default withRestrictions('rEditor_gridlines-container')(
  hoc.connect(hoc.STORES.MOUSE_OPS, mapStateToProps)(GridLinesContainer),
);
