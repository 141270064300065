export const TRANSLATIONS = {
  HEADER: 'ColorPicker2_MainPanel_Header',
  THEME_TITLE: 'ColorPicker2_MainPanel_ThemeColors_Text',
  EDIT_THEME_LINK: 'ColorPicker2_MainPanel_EditThemeColors_Link',
  EDIT_THEME_TOOLTIP:
    'ColorPicker2_MainPanel_EditThemeColors_site_design_Tooltip',
  CUSTOM_COLORS_TITLE: 'ColorPicker2_MainPanel_CustomColors_Text',
  CUSTOM_COLORS_TOOLTIP: 'ColorPicker2_MainPanel_CustomColors_Tooltip',
  ADD_CUSTOM_COLOR_LINK: 'ColorPicker2_MainPanel_AddColor',
};
