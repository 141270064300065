export default {
  APP_INTENT: {
    evid: 12,
    src: 75,
    endpoint: 'app-sdk',
    fields: {
      type: 'string',
      instance_id: 'guid',
      initiator: 'string',
      app_id: 'guid',
      origin: 'string',
    },
  },
  APP_ENGAGEMENT: {
    evid: 13,
    src: 75,
    endpoint: 'app-sdk',
    fields: {
      instance_id: 'guid',
      app_id: 'guid',
      premium_intent: 'string',
    },
  },
  TPA_ADDED: {
    evid: 165,
    src: 38,
    endpoint: 'editor',
    fields: {
      is_permissions_app: 'number',
      is_first: 'boolean',
      app_id: 'string',
      app_site_id: 'string',
      instance_id: 'string',
      category: 'string',
      origin: 'string',
      section: 'string',
      search_term: 'string',
      search_result_position: 'number',
      referral_info_category: 'string',
      referral_info: 'string',
      adding_method: 'string',
      template_guid: 'string',
      modal_tab_name: 'string',
      page_id: 'string',
      widget_id: 'string',
      market_version: 'string',
    },
  },
  TPA_ADD_CLICK: {
    evid: 167,
    src: 38,
    endpoint: 'editor',
    fields: {
      adding_method: 'string',
      section: 'string',
      category: 'string',
      origin: 'string',
      app_id: 'string',
      is_first: 'boolean',
      is_permissions_app: 'boolean',
    },
  },
  COMPONENT_ADDED_FROM_MARKET: {
    evid: 168,
    src: 38,
    endpoint: 'editor',
    fields: {
      referral_info_category: 'string',
      referral_info: 'string',
      app_id: 'guid',
      app_site_id: 'guid',
      instance_id: 'guid',
      search_result_position: 'number',
      search_term: 'string',
      modal_tab_name: 'string',
      adding_method: 'string',
      category: 'string',
      origin: 'string',
      component_id: 'string',
      is_first: 'boolean',
      component_type: 'string',
    },
  },
  CLOSE_MARKET_CLICK: {
    evid: 171,
    src: 38,
    endpoint: 'editor',
    fields: {
      open_origin: 'string',
      closing_method: 'string',
    },
  },
  MARKET_CLOSED: {
    evid: 172,
    src: 38,
    endpoint: 'editor',
    fields: {
      open_origin: 'string',
      seconds_opened: 'number',
    },
  },
  OPEN_MODAL_CLICK: {
    evid: 173,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      origin: 'string',
      category: 'string',
      section: 'string',
      component_type: 'string',
    },
  },
  MARKET_HELP_CLICK: {
    evid: 174,
    src: 38,
    endpoint: 'editor',
  },
  TPA_HELP_CLICK: {
    evid: 175,
    src: 38,
    endpoint: 'editor',
    fields: {
      widget_id: 'string',
      help_status: 'string',
      app_id: 'string',
      origin: 'string',
    },
  },
  EXTENSION_ADDED: {
    evid: 177,
    src: 38,
    endpoint: 'editor',
    fields: {
      page_id: 'string',
      app_site_id: 'string',
      instance_id: 'string',
      widget_id: 'string',
      app_id: 'string',
      preset_id: 'string',
      origin: 'string',
    },
  },
  EXTENSION_REMOVED: {
    evid: 178,
    src: 38,
    endpoint: 'editor',
    fields: {
      page_id: 'string',
      widget_id: 'string',
      comp_id: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  TPA_OPEN_SETTINGS: {
    evid: 179,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_site_id: 'string',
      instance_id: 'string',
    },
  },
  CUT_COMPONENT_OR_TPA: {
    evid: 180,
    src: 38,
    endpoint: 'editor',
    fields: {
      component_type: 'string',
      comp_id: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  COPY_COMPONENT_OR_TPA: {
    evid: 181,
    src: 38,
    endpoint: 'editor',
    fields: {
      component_type: 'string',
      comp_id: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  PASTE_COMPONENT_OR_TPA: {
    evid: 182,
    src: 38,
    endpoint: 'editor',
    fields: {
      component_type: 'string',
      comp_id: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  DUPLICATE_COMPONENT_OR_TPA: {
    evid: 183,
    src: 38,
    endpoint: 'editor',
    fields: {
      component_type: 'string',
      comp_id: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  CLICK_UPGRADE_APP: {
    evid: 184,
    src: 38,
    endpoint: 'editor',
    fields: {
      pp_origin: 'string',
      app_site_id: 'string',
      app_id: 'string',
    },
  },
  PP_OPENED: {
    evid: 185,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_site_id: 'string',
      pp_origin: 'string',
      pp_type: 'string',
    },
  },
  CLICK_UPGRADE_IN_PP: {
    evid: 186,
    src: 38,
    endpoint: 'editor',
    fields: {
      pp_type: 'string',
      pp_origin: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  CLICK_CYCLE: {
    evid: 187,
    src: 38,
    endpoint: 'editor',
    fields: {
      cycle_id: 'number',
      cycle_name: 'string',
      pp_type: 'string',
      pp_origin: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  EXPAND_OFFERING: {
    evid: 188,
    src: 38,
    endpoint: 'editor',
    fields: {
      cycle_id: 'number',
      cycle_name: 'string',
      pp_type: 'string',
      pp_origin: 'string',
      instance_id: 'string',
      app_id: 'string',
    },
  },
  PERMISSION_MODAL_CLOSED: {
    evid: 189,
    src: 38,
    endpoint: 'editor',
    fields: {
      closing_method: 'string',
      app_id: 'string',
    },
  },
  TPA_ERROR: {
    evid: 190,
    src: 38,
    endpoint: 'editor',
    fields: {
      error_desc: 'string',
      error_type: 'string',
      app_id: 'string',
    },
  },
  TPA_CLOSE_SETTINGS: {
    evid: 191,
    src: 38,
    endpoint: 'editor',
    fields: {
      seconds_opened: 'number',
      app_id: 'string',
      app_site_id: 'string',
      instance_id: 'string',
    },
  },
  TPA_MESSAGE_LOADED: {
    evid: 277,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      description: 'string',
      type: 'string',
    },
  },
  OPEN_DASHBOARD_APP_CLICK: {
    evid: 278,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_site_id: 'string',
      instance_id: 'string',
      origin: 'string',
    },
  },
  APP_MARKET_ICON_EXPANDED: {
    evid: 675,
    src: 38,
    endpoint: 'editor',
  },
  PLATFORM_APP_TRY_TO_INSTALL: {
    evid: 755,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_name: 'string',
      app_id: 'string',
      flowId: 'string',
      origin: 'string',
      allAppsToInstall: 'string',
    },
  },
  SILENT_INSTALL_APPS_START: {
    evid: 1336,
    src: 38,
    endpoint: 'editor',
    fields: {
      origin: 'string',
      appsToInstall: 'string',
    },
  },
  SILENT_INSTALL_APPS_REQUEST_BEGIN: {
    evid: 1371,
    src: 38,
    endpoint: 'editor',
    fields: {
      origin: 'string',
      appsToInstall: 'string',
      payload: 'string',
    },
  },
  PLATFORM_APP_ADDED: {
    evid: 746,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_name: 'string',
      app_id: 'string',
      duration: 'number',
      flowId: 'string',
      origin: 'string',
      allAppsToInstall: 'string',
      app_type: 'string',
    },
  },
  PLATFORM_APP_FAILED: {
    evid: 772,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_name: 'string',
      error_desc: 'string',
      app_id: 'string',
      origin: 'string',
      app_type: 'string',
      allAppsToInstall: 'string',
      flowId: 'string',
    },
  },
} as const;
