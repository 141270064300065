import * as stateManagement from '@/stateManagement';
import { events } from '@/coreBi';
import {
  SiteGlobalDataApiKey,
  ContentInjectionApiKey,
  WorkspaceRightPanelApiKey,
} from '@/apis';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '@/constants';
import constants from '@/constants';
import { fedopsLogger } from '@/util';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type {
  OwnProps,
  BusinessType,
  DispatchProps,
  StateProps,
} from './types';
import { translate } from '@/i18n';

const {
  changeBusinessType: { panelOpened, panelClosed },
} = events;

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const {
  panels: {
    actions: {
      updateOrOpenPanel,
      openLeftPanel,
      closePanelByNameAction,
      openHelpCenter,
    },
  },
} = stateManagement;
const {
  ROOT_COMPS: {
    LEFTBAR: { ADD_SECTION_PANEL_NAME },
  },
  PANEL_NAMES: { ADD_PAGES_PANEL },
} = constants;
const REOPEN_PANEL_ORIGIN = 'onCloseBusinessTypePanel';
const PANEL_PAGE_ORIGIN = 'Page panel';

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { panelName, origin },
) => {
  const editorAPI = dispatch(getEditorAPI);
  const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
  const contentInjectionAPI = editorAPI.host.getAPI(ContentInjectionApiKey);

  return {
    sendBI: (event: BiEventDefinition, params: BiEventFields = {}) => {
      return dispatch(
        stateManagement.bi.actions.event(event, {
          panel_name: panelName,
          origin,
          ...params,
        }),
      );
    },

    saveBusinessType: async (businessType: BusinessType) => {
      await siteGlobalDataAPI.setBusinessType(businessType);
      await contentInjectionAPI.initiateContentManager();
    },

    saveBusinessName: async (businessName: string) => {
      await siteGlobalDataAPI.setBusinessName(businessName);
    },

    close: () => {
      dispatch(closePanelByNameAction(panelName));

      if (origin === PANEL_PAGE_ORIGIN) {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_OPEN,
        );
        dispatch(
          updateOrOpenPanel(ADD_PAGES_PANEL, { origin: REOPEN_PANEL_ORIGIN }),
        );
      } else if (origin === 'ai_writer_panel') {
        dispatch(
          editorAPI.host.getAPI(WorkspaceRightPanelApiKey).open('aiWriter', {
            panelName: WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
            panelWidth: 288,
            title: translate('ai_text_to_page_panel_header'),
            helpId: '6ace9e33-3ebf-4ab7-a3ee-368d9a1d36c5',
          }),
        );
      } else if (origin === 'section_creator') {
        dispatch(
          openLeftPanel(ADD_SECTION_PANEL_NAME, {
            origin: REOPEN_PANEL_ORIGIN,
            emptyStateSectionReplacement: true,
            emptyStateBlankSectionIndex: 1,
            category: origin === 'section_creator' ? 'ai_creator' : null,
          }),
        );
      }
    },

    openHelpCenter: (helpId, props) => {
      dispatch(
        openHelpCenter(helpId, props, {
          panel_name: panelName,
          learn_more: true,
          origin,
        }),
      );
    },

    getContentManager: contentInjectionAPI.getContentManager,
    initiateContentManager: contentInjectionAPI.initiateContentManager,

    isBusinessTypeValid: siteGlobalDataAPI.isBusinessTypeValid,

    saveToneOfVoiceToSitePreferences: (toneOfVoice: string) => {
      dispatch(
        stateManagement.userPreferences.actions.setSitePreferences('site')(
          'toneOfVoice',
          toneOfVoice,
        ),
      );
    },
  };
};

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) => {
  const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
  const businessType = siteGlobalDataAPI.getBusinessType();
  const businessName = siteGlobalDataAPI.getBusinessName();
  const initToneOfVoice =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      'toneOfVoice',
    )(editorAPI.store.getState()) as string;

  return {
    businessType,
    businessName,
    initToneOfVoice,
  };
};

export const mergeProps = (
  { businessType, businessName, initToneOfVoice }: StateProps,
  { sendBI, ...restDispatchProps }: DispatchProps,
  { panelName, origin, onBusinessDataUpdated, ...resetOwnProps }: OwnProps,
) => {
  const { displayName, industryId, structureId } = businessType || {}; //TODO: businessType is undefined for the studio
  const businessTypeString = JSON.stringify({ industryId, structureId });

  return {
    sendCloseBI: (buttonName: string) => {
      const biOrigin = buttonName === 'done_button' ? 'cancel' : 'cross';

      sendBI(panelClosed, {
        origin: biOrigin,
        business_type: businessTypeString,
        panel_name: `${panelName}: ${origin}`,
      });
    },

    sendOpenBI: () =>
      sendBI(panelOpened, { business_type: businessTypeString }),

    sendSuggestionBi: (
      event: BiEventDefinition,
      fieldName: string,
      newBusinessType?: BusinessType,
      newValue?: string,
    ) => {
      const params = newBusinessType
        ? {
            business_type: JSON.stringify({
              industryId: newBusinessType.industryId,
              structureId: newBusinessType.structureId,
            }),
            is_changed:
              newBusinessType.industryId !== industryId &&
              newBusinessType.structureId !== structureId,
          }
        : { business_type: businessTypeString };
      sendBI(event, {
        ...params,
        fieldName,
        newValue,
      });
    },
    businessTypeName: displayName,
    businessName,
    panelName,
    onBusinessDataUpdated,
    ...restDispatchProps,
    ...resetOwnProps,
    initToneOfVoice,
    origin,
  };
};
