import constants from '@/constants';

import { createPanelIntents, IntentType } from './createPanelIntents';
import { searchModule } from '../searchModule';
import { createAddPanelAPI } from './addPanel/addPanelAPI';
import { Integrations } from '../constants';

import type { EditorAPI } from '@/editorAPI';

const { ROOT_COMPS } = constants;

interface AddPanelIntent {
  type: IntentType;
  props?: {
    category: string;
    sectionId: string;
  };
}

const getSection = (
  addPanelAPI: ReturnType<typeof createAddPanelAPI>,
  categoryID: string,
  sectionName: string,
) => {
  const section = addPanelAPI.getSectionData(categoryID, sectionName);
  if (!section) {
    addPanelAPI.resetSectionData();
    return addPanelAPI.getSectionData(categoryID, sectionName);
  }

  return section;
};

export const integrateAddPanelToSearch = (editorAPI: EditorAPI) => {
  const addPanelIntents = createPanelIntents<AddPanelIntent>(
    editorAPI,
    ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
  );

  const addPanelAPI = createAddPanelAPI(editorAPI);

  searchModule.addIntegration({
    type: Integrations.ADD_PANEL,
    checkVisibility: () => {
      return !editorAPI.isMobileEditor();
    },
    transform: (result) => {
      const { categoryID, sectionName } = result.extras;

      const section = getSection(addPanelAPI, categoryID, sectionName);

      if (!section) {
        return;
      }

      const transformedResult = {
        ...result,
        onEnter: () => {
          addPanelIntents.set({
            type: IntentType.OPEN,
            props: {
              category: section.categoryId,
              sectionId: section.id,
            },
          });
        },
        onLeave: () => {
          addPanelIntents.set({
            type: IntentType.CLOSE,
          });
        },
      };

      return transformedResult;
    },
  });
};
