import React from 'react';
import styles from './workspaceRightPanel.scss';
import { cx } from '@/util';
import { HorizontalTabs, PanelHeader } from '@wix/wix-base-ui';
import HeaderContextMenu from './headerContextMenu';
import type {
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
} from '@/constants';
import type { ContextMenuConfig } from '../workspaceRightPanelApi';

interface InnerRightPanelProps {
  helpId?: string;
  panelWidth: number;
  title: string;
  onHelp: () => void;
  isOpened: boolean;
  onClose?: () => void;
  noCloseBtn?: boolean;
  panelContent: any[];
  contextMenuConfig?: ContextMenuConfig;
  panelName: WORKSPACE_RIGHT_PANEL_NAMES | WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES;
  groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES;
  changeTab?: (newTab: WORKSPACE_RIGHT_PANEL_NAMES) => void;
  getTabs?: (
    panelName:
      | WORKSPACE_RIGHT_PANEL_NAMES
      | WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
    groupType: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  ) => any;
  onBack?: () => void;
  isSecondaryPanel: boolean;
  secondaryPanelTitle?: string;
  onBackTooltip?: string;
  zIndex?: number;
}

export const InnerRightPanel: React.FC<InnerRightPanelProps> = (props) => {
  const {
    helpId,
    panelWidth,
    title,
    noCloseBtn,
    onHelp,
    isOpened,
    onClose,
    panelContent,
    panelName,
    groupType,
    changeTab,
    getTabs,
    onBack,
    contextMenuConfig,
    isSecondaryPanel,
    secondaryPanelTitle,
    onBackTooltip,
    zIndex,
  } = props;

  const tabs = getTabs ? getTabs(panelName, groupType) : undefined;

  const dataHookPrimary = isOpened
    ? 'workspace-right-panel-opened'
    : 'workspace-right-panel-closed';
  const dataHookSecondary = isOpened
    ? 'secondary-panel-opened'
    : 'secondary-panel-closed';

  const onChangeTab = (newTab: string) => {
    changeTab(newTab as WORKSPACE_RIGHT_PANEL_NAMES);
  };

  const getTitle = () => {
    if (isSecondaryPanel && secondaryPanelTitle) {
      return secondaryPanelTitle;
    }
    return title;
  };

  return (
    <div
      data-hook={!isSecondaryPanel ? dataHookPrimary : dataHookSecondary}
      className={cx(styles.innerPanel, {
        [styles.secondary]: isSecondaryPanel,
      })}
      style={
        isSecondaryPanel
          ? {
              width: panelWidth,
              transform: `translateX(${isOpened ? 0 : panelWidth}px)`,
              opacity: isOpened ? 1 : 0,
              zIndex,
            }
          : {}
      }
    >
      <PanelHeader
        className={styles.panelHeader}
        onClose={onClose}
        onHelp={onHelp}
        noCloseBtn={noCloseBtn}
        removeStripe
        noHelpBtn={!helpId}
        actionsBar={
          contextMenuConfig?.items ? (
            <HeaderContextMenu contextMenuConfig={contextMenuConfig} />
          ) : null
        }
        {...(onBack ? { onBack } : {})}
        backTooltipContent={onBackTooltip}
        shouldTranslate={false}
      >
        {getTitle()}
      </PanelHeader>
      <div className={styles.panelBody}>
        {groupType && tabs.length > 1 ? (
          <HorizontalTabs
            value={panelName}
            onChange={onChangeTab}
            options={tabs}
            type="arrowed"
            shouldTranslate={false}
          />
        ) : null}
        {panelContent.map((slot) => (
          <div
            key={slot.uniqueId}
            className={cx(styles.content, {
              [styles.hasTabs]: groupType && tabs.length > 1,
            })}
          >
            <slot.contribution />
          </div>
        ))}
      </div>
    </div>
  );
};
