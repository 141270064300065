import React, { useState } from 'react';
import * as util from '@/util';
import {
  WidgetPluginsManager,
  TranslationContext,
  type EditorIntegrationAPI,
  type WidgetPointer,
} from '@wix/editor-platform-host-integration/ui';
import { TpaSettingsPanelFrame } from '../frames';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import { translate } from '@/i18n';
import experiment from 'experiment';

interface PluginsPanelStateProps {
  widgetRef: CompRef;
}

interface PluginsPanelDispatchProps {
  openHelpArticle: () => void;
  getEditorIntegrationAPI: () => EditorIntegrationAPI;
}

interface PluginsPanelOwnProps {
  panelName: string;
  style?: Record<string, string>;
  selectedComponents: CompRef[];
  onClose?: () => void;
  isBlocks?: boolean;
  onInstallationComplete?: (widgetPlugin: WidgetPointer) => void;
}

interface PluginsPanelProps
  extends PluginsPanelStateProps,
    PluginsPanelDispatchProps,
    PluginsPanelOwnProps {}

const PluginSettingsMaxHeight = 490; // https://jira.wixpress.com/browse/EP-2484
const PluginsPanel: React.FC<PluginsPanelProps> = (props) => {
  const [editorIntegrationAPI] = useState(() =>
    props.getEditorIntegrationAPI(),
  );

  return (
    <TpaSettingsPanelFrame
      panelName={props.panelName}
      title={translate('PLATFORM_Widget_Slots_Modal_Header')}
      width={790}
      height={PluginSettingsMaxHeight}
      shouldAddScroll
      style={props.style}
      onClose={props.onClose}
      onHelpClicked={props.openHelpArticle}
    >
      <TranslationContext.Provider value={translate}>
        <WidgetPluginsManager
          editorIntegrationAPI={editorIntegrationAPI}
          widgetRef={props.widgetRef}
        />
      </TranslationContext.Provider>
    </TpaSettingsPanelFrame>
  );
};

const getEditorAPI: ThunkAction = (_dispatch, _getState, { editorAPI }) =>
  editorAPI;

const getSelectedComponents = (editorAPI: EditorAPI) =>
  editorAPI.selection.getSelectedComponents();

const mapStateToProps: MapStateToProps<
  PluginsPanelStateProps,
  PluginsPanelOwnProps
> = ({ editorAPI }) => ({
  widgetRef: editorAPI.platform.widgetPlugins.getWidgetHostRefByWidgetRef(
    getSelectedComponents(editorAPI)[0],
  ),
});

const mapDispatchToProps: MapDispatchToProps<
  PluginsPanelDispatchProps,
  PluginsPanelOwnProps
> = (dispatch, { panelName, isBlocks, onInstallationComplete }) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openHelpArticle: () => {
      const widgetPluginsHelpArticleId = '19eb165a-b55d-4e7e-8bd9-067c9cbb9cb5';
      editorAPI.panelManager.openHelpCenter(widgetPluginsHelpArticleId);
    },
    getEditorIntegrationAPI: () =>
      getEditorIntegrationAPI(editorAPI, {
        panelName,
        isBlocks,
        onInstallationComplete,
        selectedComponents: getSelectedComponents(editorAPI),
      }),
  };
};

function getEditorIntegrationAPI(
  editorAPI: EditorAPI,
  options: {
    panelName: string;
    isBlocks?: boolean;
    onInstallationComplete?: (widgetPlugin: WidgetPointer) => void;
    selectedComponents: CompRef[];
  },
): EditorIntegrationAPI {
  return {
    isBlocks: () => options.isBlocks ?? false,
    getWidgetSlots: (widgetCompRef: CompRef) => {
      // when opened a default plugins modal setting in blocks
      if (options.isBlocks) {
        if (!window.appStudioModel.devSiteAppDefId) {
          // if blocks app was never saved yet it means that
          // no appDefId was created and we have no active placement
          // therefore no available slots to install plugins
          // TODO ask if we could improve this
          return [];
        }
        return [
          editorAPI.platform.widgetPlugins.getSlotInBlocks(
            options.selectedComponents[0],
          ),
        ];
      }
      // when selected a populated slot
      const scopedSlotPlaceholder =
        editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
          widgetCompRef,
        );
      if (scopedSlotPlaceholder) {
        return [
          editorAPI.platform.widgetPlugins.getWidgetSlot(scopedSlotPlaceholder),
        ];
      }
      // when selected a host widget
      return editorAPI.platform.widgetPlugins.getWidgetSlots(widgetCompRef);
    },
    getSlotsData: editorAPI.dsRead.components.slots.getSlotsData,
    getWidgetSlotData: editorAPI.components.data.get,
    installWidgetPlugin: async (
      slotPlaceholderCompRef: CompRef,
      widgetPointer: WidgetPointer,
    ) => {
      const compRef =
        await editorAPI.platform.widgetPlugins.installWidgetPlugin(
          slotPlaceholderCompRef,
          widgetPointer,
          {
            origin: 'pluginsPanel',
            action: options.isBlocks
              ? 'installDefaultPluginInBlocks'
              : 'installPluginInEditor',
          },
        );

      editorAPI.selection.selectComponentByCompRef(compRef);
      options.onInstallationComplete?.(widgetPointer);
    },
    uninstallWidgetPlugin: (slotComponentRef: CompRef) => {
      const origin = options.isBlocks ? undefined : options.panelName;
      return editorAPI.platform.widgetPlugins.uninstallWidgetPlugin(
        slotComponentRef,
        origin,
      );
    },
    openPanel: editorAPI.panelManager.openPanel,
    highlightSlot: editorAPI.platform.widgetPlugins.highlightSlot,
    unhighlightSlot: editorAPI.platform.widgetPlugins.unhighlightSlot,
    closeWidgetPluginsPanel: () => {
      editorAPI.panelManager.closePanelByName(options.panelName);
    },
    isWidgetPluginMoveActionEnabled: (slotPlaceholderCompRef: CompRef) =>
      experiment.isOpen('se_widgetPlugins_movePluginToAnotherSlot') &&
      experiment.isOpen('se_widgetPlugins') &&
      editorAPI.platform.widgetPlugins.isSlotContentPotentialMovable(
        slotPlaceholderCompRef,
      ),
    movePlugin: (compRef: CompRef) => {
      editorAPI.panelManager.openPanel(
        'panels.widgetPanels.slotSelectionExtendedPanel',
        {
          slotsPlaceholderCompRef: compRef,
        },
      );

      editorAPI.platform.widgetPlugins.scrollToSlot(compRef);
      editorAPI.selection.selectComponentByCompRef(compRef);
    },
  };
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PluginsPanel);
