import { collectFlattenChildrenSnapshots } from './collectFlattenChildrenSnapshots';
import { VIEW_MODES } from '../constants';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { PageSnapshots } from './types';

export function collectSnapshots(
  documentServices: DocumentServicesObject,
  pages: CompRef[],
  isFullSnapshot: boolean,
): PageSnapshots[] {
  return pages.map(({ id }) =>
    VIEW_MODES.map((viewMode) => {
      const pageRef = <CompRef>{
        id,
        type: viewMode,
      };

      const pageTitle = documentServices.pages.getPageTitle(id) || id;

      return collectFlattenChildrenSnapshots(
        documentServices,
        pageRef,
        pageTitle,
        isFullSnapshot,
      );
    }),
  );
}
