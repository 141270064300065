import { createReduxStore } from '@/apilib';
import type { CompRef } from 'types/documentServices';
import type { Position } from './types';

export interface State {
  highlightedComponents: CompRef[];
  parentCompRef: CompRef;
  childrensCompsPositions: Position[];
  isResize: boolean;
  shouldCalculatePositionsOnEveryMove: boolean;
}

interface StartResizePayload {
  parentCompRef: CompRef;
  childrensCompsPositions: Position[];
  shouldCalculatePositionsOnEveryMove: boolean;
}

const getInitialState = (): State => ({
  highlightedComponents: [],
  parentCompRef: null,
  childrensCompsPositions: [],
  isResize: false,
  shouldCalculatePositionsOnEveryMove: false,
});

export const HighlightResizeStore = createReduxStore({
  getInitialState,
  selectors: {
    getHighlightedComponents: (state) => state.highlightedComponents,
    getChildrensCompsPositions: (state) => state.childrensCompsPositions,
    getParentCompRef: (state) => state.parentCompRef,
    getShouldCalculatePositionsOnEveryMove: (state) =>
      state.shouldCalculatePositionsOnEveryMove,
  },
  actions: {
    addHighlightedComponents: (state, compsRefs: CompRef[]) => {
      return { ...state, highlightedComponents: compsRefs };
    },
    startResize: (state, payload: StartResizePayload) => {
      return { ...state, ...payload, isResize: true };
    },
    endResize: (state) => {
      return {
        ...state,
        highlightedComponents: [],
        parentCompRef: null,
        childrensCompsPositions: [],
        isResize: false,
        shouldCalculatePositionsOnEveryMove: false,
      };
    },
  },
});
