import React, { type ComponentType } from 'react';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';

export interface ComponentWithSelectedComponent {
  selectedCompId: string;
  scrollToCompAndSelect: (compId: string) => void;
}

interface WithSelectedComponentsStateProps {
  selectedCompId: string;
}

interface WithSelectedComponentsDispatchProps {
  scrollToCompAndSelect: (compId: string) => void;
}

export interface WithSelectedComponentsProps
  extends WithSelectedComponentsStateProps,
    WithSelectedComponentsDispatchProps {}

const mapStateToProps: MapStateToProps<WithSelectedComponentsStateProps> = ({
  state,
}) => ({
  selectedCompId:
    stateManagement.selection.selectors.getSelectedCompsRefs(state)?.[0]?.id,
});

const mapDispatchToProps: MapDispatchToProps<
  WithSelectedComponentsDispatchProps,
  unknown
> = {
  scrollToCompAndSelect:
    (compId: string): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      const compRef = editorAPI.components.get.byId(compId);
      const layout =
        editorAPI.components.layout.getRelativeToStructure(compRef);
      editorAPI.ui.scroll.animateScroll(
        { x: 0, y: Math.max(layout.y - 30, 0) },
        0.5,
      );
      editorAPI.selection.selectComponentByCompRef(compRef);
      editorAPI.selection.setIsHiddenComponentDraggedNext(true);
    },
};

const withSelectedComponent = <P extends WithSelectedComponentsProps>(
  WrappedComponent: ComponentType<P>,
) => {
  const Wrapper: React.FC<WithSelectedComponentsProps> = (props) => (
    <WrappedComponent {...(props as P)} />
  );

  return util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(Wrapper);
};

export default withSelectedComponent;
