import { BaseEntryScope, Hooks } from '@/apilib';
import { EditorAPIKey } from '@/apis';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);

  hooks = this.declareApi(() => ({
    dragStart: Hooks.createHook(),
    drag: Hooks.createHook<{ position: { x: number; y: number } }>(),
    beforeDragEnd: Hooks.createHook(),
    dragEnd: Hooks.createHook<{ position: { x: number; y: number } }>(),
  }));
}
