import React from 'react';
import { cx, keyboardShortcuts } from '@/util';
import { getImageUrl } from '../../addPagePanelUtil';
import { TextLabel, InfoIcon } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import type { AddPageApplicationImage } from '../../../types';
import type { ImageTransformTarget } from '@/util';

interface AddPageGalleryActions {
  moveLeft: () => void;
  moveRight: () => void;
}

const addPageCarouselImageDefaultTarget: ImageTransformTarget = {
  width: 442,
  height: 527,
  htmlTag: 'bg',
};

type ArrowAlignment = 'left' | 'right';

export interface AddPageCarouselItemsProps {
  images: string[];
  registerGalleryActions(galleryActions: AddPageGalleryActions): void;
}

class AddPageCarouselItems extends React.Component<AddPageCarouselItemsProps> {
  static displayName = 'AddPageCarouselItems';

  constructor(props: AddPageCarouselItemsProps) {
    super(props);
    props.registerGalleryActions({
      moveLeft: this.moveLeft,
      moveRight: this.moveRight,
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  private hiddenRefs: React.RefObject<HTMLDivElement>[] = [];
  private leftRef: React.RefObject<HTMLDivElement> = React.createRef();
  private centerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private rightRef: React.RefObject<HTMLDivElement> = React.createRef();

  getImagesIndexes = () => {
    const centerIndex = 0;
    const { images } = this.props;
    const leftIndex =
      images.length > 2
        ? (centerIndex + images.length - 1) % images.length
        : -1;
    const rightIndex = (centerIndex + 1) % images.length;

    return {
      leftIndex,
      rightIndex,
      centerIndex,
    };
  };

  moveLeft = () => {
    if (this.rightRef.current) {
      this.rightRef.current.classList.remove('hidden-to-right');
      this.rightRef.current.classList.add('right-to-hidden');
      this.rightRef.current.classList.add('hidden');
      this.rightRef.current.classList.remove('right');
    }

    this.centerRef.current.classList.remove('left-to-center');
    this.centerRef.current.classList.add('right');
    this.centerRef.current.classList.remove('center');

    this.leftRef.current.classList.add('left-to-center');
    this.leftRef.current.classList.remove('hidden-to-left');
    this.leftRef.current.classList.add('center');
    this.leftRef.current.classList.remove('left');

    if (this.hiddenRefs.length > 0) {
      this.hiddenRefs[0].current.classList.remove('right-to-hidden');
      this.hiddenRefs[0].current.classList.remove('left-to-hidden');
      this.hiddenRefs[0].current.classList.add('hidden-to-left');
      this.hiddenRefs[0].current.classList.add('left');
      this.hiddenRefs[0].current.classList.remove('hidden');
    } else if (this.rightRef.current) {
      this.rightRef.current.classList.remove('right-to-hidden');
      this.rightRef.current.classList.remove('left-to-hidden');
      this.rightRef.current.classList.add('hidden-to-left');
      this.rightRef.current.classList.add('left');
      this.rightRef.current.classList.remove('hidden');
    }

    const tempRightRef = this.rightRef;
    this.rightRef = this.centerRef;
    this.centerRef = this.leftRef;

    if (this.hiddenRefs.length > 0) {
      this.leftRef = this.hiddenRefs[0];

      for (let i = 0; i < this.hiddenRefs.length - 1; i++) {
        this.hiddenRefs[i] = this.hiddenRefs[i + 1];
      }
      this.hiddenRefs[this.hiddenRefs.length - 1] = tempRightRef;
    } else {
      this.leftRef = tempRightRef;
    }
  };

  moveRight = () => {
    if (this.leftRef.current) {
      this.leftRef.current.classList.remove('hidden-to-left');
      this.leftRef.current.classList.add('left-to-hidden');
      this.leftRef.current.classList.add('hidden');
      this.leftRef.current.classList.remove('left');
    }

    this.centerRef.current.classList.remove('left-to-center');
    this.centerRef.current.classList.add('left');
    this.centerRef.current.classList.remove('center');

    this.rightRef.current.classList.remove('hidden-to-right');
    this.rightRef.current.classList.add('center');
    this.rightRef.current.classList.remove('right');

    const lastHiddenIndex = this.hiddenRefs.length - 1;
    if (this.hiddenRefs.length > 0) {
      this.hiddenRefs[lastHiddenIndex].current.classList.remove(
        'left-to-hidden',
      );
      this.hiddenRefs[lastHiddenIndex].current.classList.remove(
        'right-to-hidden',
      );
      this.hiddenRefs[lastHiddenIndex].current.classList.add('hidden-to-right');
      this.hiddenRefs[lastHiddenIndex].current.classList.add('right');
      this.hiddenRefs[lastHiddenIndex].current.classList.remove('hidden');
    } else if (this.leftRef.current) {
      this.leftRef.current.classList.remove('left-to-hidden');
      this.leftRef.current.classList.remove('right-to-hidden');
      this.leftRef.current.classList.add('hidden-to-right');
      this.leftRef.current.classList.add('right');
      this.leftRef.current.classList.remove('hidden');
    }

    const tempLeftRef = this.leftRef;
    this.leftRef = this.centerRef;
    this.centerRef = this.rightRef;

    if (this.hiddenRefs.length > 0) {
      this.rightRef = this.hiddenRefs[lastHiddenIndex];

      for (let i = 0; i < this.hiddenRefs.length - 1; i++) {
        this.hiddenRefs[this.hiddenRefs.length - 1 - i] =
          this.hiddenRefs[this.hiddenRefs.length - 2 - i];
      }
      this.hiddenRefs[0] = tempLeftRef;
    } else {
      this.rightRef = tempLeftRef;
    }
  };

  getImageElementByIndex = (
    image: string,
    index: number,
    leftIndex: number,
    centerIndex: number,
    rightIndex: number,
  ) => {
    let imageRef, className;
    switch (index) {
      case leftIndex:
        imageRef = this.leftRef;
        className = 'left';
        break;
      case centerIndex:
        imageRef = this.centerRef;
        className = 'center';
        break;
      case rightIndex:
        imageRef = this.rightRef;
        className = 'right';
        break;
      default:
        imageRef = React.createRef<HTMLDivElement>();
        this.hiddenRefs.unshift(imageRef);
        className = 'hidden';
        break;
    }

    return (
      <div
        key={image}
        ref={imageRef}
        className={`carousel-gallery-image ${className}`}
        style={{ backgroundImage: `url(${image}` }}
      />
    );
  };

  render() {
    const { images } = this.props;
    const { leftIndex, centerIndex, rightIndex } = this.getImagesIndexes();
    return (
      <div className="carousel-gallery-images-wrapper">
        {images.map((image, index) =>
          this.getImageElementByIndex(
            image,
            index,
            leftIndex,
            centerIndex,
            rightIndex,
          ),
        )}
      </div>
    );
  }
}

export interface AddPageCarouselGalleryProps {
  onMoveLeft?(): void;
  onMoveRight?(): void;
  images: AddPageApplicationImage[];
  galleryUnique: string;
}

export interface AddPageCarouselGalleryState {
  centerIndex: number;
}

export default class AddPageCarouselGallery extends React.Component<
  AddPageCarouselGalleryProps,
  AddPageCarouselGalleryState
> {
  static displayName = 'AddPageCarouselGallery';
  constructor(props: AddPageCarouselGalleryProps) {
    super(props);
    this.state = { centerIndex: 0 };
  }

  componentDidMount() {
    const extendedShortcuts = keyboardShortcuts.extendContext(
      keyboardShortcuts.CONTEXTS.ADD_PAGE_PANEL,
      {
        [keyboardShortcuts.specialKeys.left]: this.onMoveLeft.bind(this),
        [keyboardShortcuts.specialKeys.right]: this.onMoveRight.bind(this),
      },
    );
    keyboardShortcuts.registerContext(
      keyboardShortcuts.CONTEXTS.ADD_PAGE_PANEL,
      extendedShortcuts,
    );
  }

  componentWillUnmount() {
    const extendedShortcuts = keyboardShortcuts.extendContext(
      keyboardShortcuts.CONTEXTS.ADD_PAGE_PANEL,
      {
        [keyboardShortcuts.specialKeys.left]: null,
        [keyboardShortcuts.specialKeys.right]: null,
      },
    );
    keyboardShortcuts.registerContext(
      keyboardShortcuts.CONTEXTS.ADD_PAGE_PANEL,
      extendedShortcuts,
    );
  }

  private moveLeft: () => void;
  private moveRight: () => void;

  onMoveLeft = () => {
    this.moveLeft();
    this.setState({
      centerIndex:
        (this.state.centerIndex - 1 + this.props.images.length) %
        this.props.images.length,
    });
    if (this.props.onMoveLeft) {
      this.props.onMoveLeft();
    }
  };

  onMoveRight = () => {
    this.moveRight();
    this.setState({
      centerIndex: (this.state.centerIndex + 1) % this.props.images.length,
    });
    if (this.props.onMoveRight) {
      this.props.onMoveRight();
    }
  };

  registerGalleryActions = ({ moveLeft, moveRight }: AddPageGalleryActions) => {
    this.moveLeft = moveLeft;
    this.moveRight = moveRight;
  };

  shouldShowArrow = (arrowAlignment: ArrowAlignment) => {
    switch (this.props.images.length) {
      case 0:
      case 1:
        return false;
      case 2:
        return arrowAlignment === 'left'
          ? this.state.centerIndex === 1
          : this.state.centerIndex === 0;
      default:
        return true;
    }
  };

  render() {
    const { images } = this.props;
    return (
      <div className="add-page-carousel-gallery">
        <div className="add-page-carousel-gallery-items-wrapper">
          <AddPageCarouselItems
            key={this.props.galleryUnique}
            images={images.map(({ image }) =>
              getImageUrl(image, addPageCarouselImageDefaultTarget),
            )}
            registerGalleryActions={this.registerGalleryActions}
          />
          {this.shouldShowArrow('right') ? (
            <div className="arrow-button right" onClick={this.onMoveRight}>
              <symbols.symbol name="sliderArrowRight" />
            </div>
          ) : null}
          {this.shouldShowArrow('left') ? (
            <div className="arrow-button left" onClick={this.onMoveLeft}>
              <symbols.symbol name="sliderArrowLeft" />
            </div>
          ) : null}
          <div className="carousel-item-description">
            <TextLabel
              type="T05"
              className="carousel-item-description-text"
              value={images[this.state.centerIndex].imageTitle}
            />
            <InfoIcon
              tooltipMarginTop={5}
              className="carousel-item-info-icon"
              text={images[this.state.centerIndex].imageDescription}
            />
          </div>
        </div>
        <div className="circle-indicators-wrapper">
          {images.map((image, index) => (
            <div
              key={image.imageTitle}
              className={cx('circle-indicator', {
                centered: index === this.state.centerIndex,
              })}
            />
          ))}
        </div>
      </div>
    );
  }
}
