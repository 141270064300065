'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require('experiment')
const i18n = require('@/i18n')
const _ = require('lodash')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_youtube',
    'subNavigationTitle': 'add_section_label_youtube',
    'presetTitle': 'add_section_label_youtube',
    'tooltipTitle': 'add_section_label_youtube',
    "automationId": "add-panel-section-youtubeSocialSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': experiment.isOpen('se_newSocialCategoryAddPanel') ? {
        "image": "addPanelData/sections/youtubeSocialSection_en/youtubeSocialSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Social_YoutubeSocial_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.youtubesubscribebutton.viewer.skins.YouTubeSubscribeButtonSkin",
                "layout": {
                    "width": 212,
                    "height": 55,
                    "x": 59,
                    "y": 61,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton",
                "data": {
                    "type": "YouTubeSubscribeButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "youtubeChannelId": _.last(i18n.translate("add_preset_social_bar_items_youtube").split("/"))
                },
                "props": {
                    "type": "YouTubeSubscribeButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "layout": "full",
                    "theme": "light"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.youtubesubscribebutton.viewer.skins.YouTubeSubscribeButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jp1eaukt"
            },
            "preset": {
                "rect": {"width": 161, "height": 88, "x": 0, "y": 0},
                "label": "add_preset_Social_Youtube_1",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_YoutubeSocial_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.youtubesubscribebutton.viewer.skins.YouTubeSubscribeButtonSkin",
                "layout": {
                    "width": 145,
                    "height": 33,
                    "x": 387,
                    "y": 76,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton",
                "data": {
                    "type": "YouTubeSubscribeButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "youtubeChannelId": _.last(i18n.translate("add_preset_social_bar_items_youtube").split("/"))
                },
                "props": {
                    "type": "YouTubeSubscribeButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "layout": "default",
                    "theme": "light"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.youtubesubscribebutton.viewer.skins.YouTubeSubscribeButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jp1eddq1"
            },
            "preset": {
                "rect": {"width": 139.5, "height": 88, "x": 161, "y": 0},
                "label": "add_preset_Social_Youtube_2",
                "tags": null,
                "labelClasses": "align-left"
            }
        }],
        "compTypes": ["wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton"]
    } : {
        'image': 'addPanelData/sections/youtubeSocialSection_en/youtubeSocialSection_en.v3.png',
        'imageHover': null,
        'items': [{
            'id': 'Social_YoutubeSocial_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.youtubesubscribebutton.viewer.skins.YouTubeSubscribeButtonSkin',
                'layout': {
                    'width': 212,
                    'height': 55,
                    'x': 0,
                    'y': 10,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton',
                'data': {
                    'type': 'YouTubeSubscribeButton',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'youtubeChannelId': _.last(i18n.translate('add_preset_social_bar_items_youtube').split('/'))
                },
                'props': {
                    'type': 'YouTubeSubscribeButtonProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'layout': 'full',
                    'theme': 'light'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'YouTubeSubscribeButton_1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.common.components.youtubesubscribebutton.viewer.skins.YouTubeSubscribeButtonSkin'
                },
                'id': 'ic20be9z'
            },
            'preset': {
                'rect': {'width': 324, 'height': 85, 'x': 0, 'y': 0},
                'label': 'add_preset_Social_Youtube_1',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }],
        'compTypes': ['wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton']
    }
}
