import type { MapDispatchToProps, DispatchMapperArgs } from 'types/redux';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

export interface SiteSettingsBranchWarningOwnProps {
  dashboardUrl: string;
}
export interface SiteSettingsBranchWarningDispatchProps {
  openHelpArticle: () => void;
  sendBi: (event: AnyFixMe, parameters: AnyFixMe) => void;
}

const getBranchNameAndId = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: DispatchMapperArgs,
) => ({
  branchName:
    stateManagement.branches.selectors.getCurrentBranchName(getState()),

  branchId: editorAPI.dsRead.generalInfo.getBranchId(),

  dontShowAgain:
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      constants.USER_PREFS.BRANCHES.SITE_SETTINGS_WARNING_DONT_SHOW_AGAIN,
    )(getState()) || false,
});

export const mapDispatchToProps: MapDispatchToProps<
  SiteSettingsBranchWarningDispatchProps,
  SiteSettingsBranchWarningOwnProps
> = (dispatch) => ({
  openHelpArticle: () => {
    const helpId = 'd041f0d3-dffc-4675-b12d-e50527124d69';
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId));
  },
  sendBi: (event, parameters = {}) => {
    const { branchName, branchId, dontShowAgain } =
      dispatch(getBranchNameAndId);

    dispatch(
      stateManagement.bi.actions.event(event, {
        site_branch_id: branchId,
        site_branch_name: branchName,
        dont_show_again: dontShowAgain,
        ...parameters,
      }),
    );
  },
});
