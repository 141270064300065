import experiment from 'experiment';
import { SiteGlobalDataApiKey, WorkspaceRightPanelApiKey } from '@/apis';
import { biLogger, guidUtils } from '@/util';
import * as stateManagement from '@/stateManagement';
import {
  aiPageWriterUndoRedoSuccessBackToPreviousContentVersion,
  aiTextGeneratorErrorEvent,
} from '@wix/bi-logger-editor/v2';
import {
  AI_PAGE_WRITER_CONTEXT,
  ERROR_STATE_PANEL_PATH,
  AI_PAGE_WRITER_ORIGIN,
  PANEL_TYPE,
} from './constants';
import { getBusinessData } from './utils/general';

import type { AiWriterPanelScope } from './aiWriterPanelScope';
import type {
  AiWriterPanelStateProps,
  AiWriterPanelDispatchProps,
  QueryOutput,
} from './aiWriterPanelTypes';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '@/constants';
import constants from '@/constants';

export const mapStateToProps = (
  scope: AiWriterPanelScope,
): AiWriterPanelStateProps => {
  const isEmptyState = scope.editorAPI.sections.isEmptyState();
  const pageId = scope.editorAPI.dsRead.pages.getFocusedPageId();
  const pageData = scope.editorAPI.pages.data.get(pageId);
  const isIrrelevantPage = !!(
    pageData.managingAppDefId ||
    pageData.tpaApplicationId ||
    isEmptyState
  );
  const origin = scope.store.getOrigin();
  const isPreInjection =
    scope.editorAPI.history.getUndoLastSnapshotLabel() !==
    AI_PAGE_WRITER_CONTEXT;

  return {
    defaultFormState: {
      ...getBusinessData(scope),
    },
    origin,
    isIrrelevantPage,
    isPreInjection,
    isEmptyState,
  };
};

export const mapDispatchToProps = (
  scope: AiWriterPanelScope,
): AiWriterPanelDispatchProps => {
  const pageId = scope.editorAPI.pages.getCurrentPageId();
  const isUpdateContentByPageStructureEnabled = experiment.isOpen(
    'se_updateContentByPageStructure',
  );
  const structure = scope.editorAPI.components.serialize(
    scope.editorAPI.pages.getCurrentPage(),
    undefined,
    undefined,
    true,
  );

  return {
    onClick: (
      businessDescription: string,
      toneOfVoiceValue?: string | null,
    ) => {
      const guid = guidUtils.getGUID();
      const { businessType, businessName } = getBusinessData(scope);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const toneOfVoice = toneOfVoiceValue ? toneOfVoiceValue : '';

      scope.editorAPI.store.dispatch(
        stateManagement.ai.actions.setIsAiTermsAndConditionsShown(true),
      );

      return new Promise((res, rej) => {
        return isUpdateContentByPageStructureEnabled
          ? //@ts-expect-error
            scope.editorAPI.ai.content.updateContentByPageStructure(
              businessType,
              businessName,
              businessDescription,
              structure,
              null,
              (...queryOutput: QueryOutput[]) => {
                if (queryOutput[0].originalError) {
                  const error: object = queryOutput[0].originalError;
                  rej({ error, queryOutput });
                }
                res({
                  queryOutput: queryOutput[0].suggestionResults,
                  guid,
                });
                scope.editorAPI.history.add(AI_PAGE_WRITER_CONTEXT, {
                  component_id: guid,
                });
              },
              (error: any, queryOutput: QueryOutput[]): void => {
                rej({ error, queryOutput });
              },
            )
          : //@ts-expect-error
            scope.editorAPI.ai.content.updatePageContent(
              pageId,
              businessType,
              businessName,
              businessDescription,
              (...queryOutput: QueryOutput[]) => {
                if (queryOutput[0].originalError) {
                  const error: object = queryOutput[0].originalError;
                  rej({ error, queryOutput });
                }
                res({
                  queryOutput: queryOutput[0].suggestionResults,
                  guid,
                });
                scope.editorAPI.history.add(AI_PAGE_WRITER_CONTEXT, {
                  component_id: guid,
                });
              },
              (error: any, queryOutput: QueryOutput[]): void => {
                rej({ error, queryOutput });
              },
              {},
              toneOfVoice,
            );
      });
    },
    onUndo: async () => {
      await scope.editorAPI.history.performUndoUntilLabel(
        AI_PAGE_WRITER_CONTEXT,
        true,
      );
    },
    sendUndoRedoBI: (originParams: any, targetParams: any) => {
      const isOriginAiWritten = originParams?.label === AI_PAGE_WRITER_CONTEXT;
      const isTargetAiWritten = targetParams?.label === AI_PAGE_WRITER_CONTEXT;

      if (!isOriginAiWritten && !isTargetAiWritten) return;

      const targetGuid = targetParams?.component_id;
      const originGuid = originParams?.component_id;

      if (originGuid === targetGuid || (!originGuid && !targetGuid)) return;

      biLogger.report(
        aiPageWriterUndoRedoSuccessBackToPreviousContentVersion({
          output_id_origin: isOriginAiWritten
            ? originGuid
            : 'pre-injection state',
          output_id_target: isTargetAiWritten
            ? targetGuid
            : 'pre-injection state',
        }),
      );
    },
    onLoading: (isLoading: boolean) => {
      if (isLoading) {
        scope.editorAPI.store.dispatch(
          stateManagement.stageLoader.actions.startStageLoading(),
        );
      } else {
        scope.editorAPI.store.dispatch(
          stateManagement.stageLoader.actions.endStageLoading(),
        );
      }
    },
    onWrongPageClick: () => {
      scope.editorAPI.setForceOpenPagesQuickNavigationEventCounter();
    },
    onAddSectionClick: () => {
      const { editorAPI } = scope;
      const shouldStretchOnClose = false;

      editorAPI.host
        .getAPI(WorkspaceRightPanelApiKey)
        .close(
          WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
          shouldStretchOnClose,
        );

      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
        { origin: AI_PAGE_WRITER_ORIGIN },
      );
    },
    showFailStateModal: (
      { tokens, completionText, sessionId, ...query }: AnyFixMe,
      errorMessage: string,
    ) => {
      const siteGlobalDataApi =
        scope.editorAPI.host.getAPI(SiteGlobalDataApiKey);
      const businessType =
        siteGlobalDataApi.getBusinessType()?.displayName ?? '';
      const businessName = siteGlobalDataApi.getBusinessName() ?? '';

      biLogger.report(
        aiTextGeneratorErrorEvent({
          query: JSON.stringify({
            ...query,
            businessType,
            businessName,
          }),
          query_output: JSON.stringify({
            completionText,
          }),
          panelType: PANEL_TYPE,
          errorMessage,
          tokens,
          sessionId,
        }),
      );

      scope.editorAPI.panelManager.openPanel(
        ERROR_STATE_PANEL_PATH,
        {
          closePanel: () =>
            scope.editorAPI.panelManager.closePanelByName(
              ERROR_STATE_PANEL_PATH,
            ),
        },
        true,
      );
    },
    businessData: getBusinessData(scope),
  };
};
