import * as util from '@/util';
import experiment from 'experiment';

import type { AppInstallOrigin } from '@wix/editor-platform-host-integration-apis';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
export const isAddSectionProductionMode =
  !experiment.isOpen('se_addDesignerSection') && !util.url.isQA();

export const isCreateSectionWithAIEnabled = (editorAPI: EditorAPI) => {
  const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
    editorAPI.store.getState(),
  );
  return experiment.isOpen('se_createSectionWithAI') && !isSchoolMode;
};

export const origin = 'addSectionPanel';

export function getPanelCorrelationId(count: number): string {
  return `panelOpenCount_${count}`;
}

export function getAddSectionPlatformOrigin(): AppInstallOrigin {
  return {
    type: EditorType.Classic,
    initiator: InstallInitiator.Editor,
    info: {
      type: InstallationOriginType.ADD_SECTION_PANEL,
    },
  };
}

export const getLanguageConsiderFRLocalization = () =>
  util.editorModel.languageCode === 'fr' ? 'fr' : 'en';

export const getAddSectionPanelLanguage = () => {
  if (experiment.isOpen('se_sectionsPanelFRLocalization')) {
    return getLanguageConsiderFRLocalization();
  }
  return experiment.isOpen('se_sectionsPanelLanguages')
    ? util.editorModel.languageCode
    : 'en';
};
