// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as addPanelAddCompService from '../addPanelAddCompService';
import triggerBiEvent from './triggerElementAddedBiEvent';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';
import constants from '../../constants/constants';
import superAppsConstants from '../../superApps/superAppsConstants';

const APP_DEF_ID = superAppsConstants.NEW_BLOG_APP_DEF_ID;
const WIDGET_ID = '1515a9e7-b579-fbbb-43fc-0e3051c14803';

const itemIds = {
  SQUARE: 'rssButtonSquare',
  ROUNDED_DARK: 'rssButtonRoundedDark',
  ROUNDED_LIGHT: 'rssButtonRoundedLight',
  TRANSPARENT_DARK: 'rssButtonTransparentDark',
  TRANSPARENT_LIGHT: 'rssButtonTransparentLight',
};

const borderTypes = {
  [itemIds.SQUARE]: 'square',
  [itemIds.ROUNDED_DARK]: 'rounded_dark',
  [itemIds.ROUNDED_LIGHT]: 'rounded_light',
  [itemIds.TRANSPARENT_DARK]: 'transparent_dark',
  [itemIds.TRANSPARENT_LIGHT]: 'transparent_light',
};

const getBiParams = function (itemId) {
  return {
    element_type: 'rss_button',
    border: borderTypes[itemId],
  };
};

const addApp = function (
  compStructure,
  sectionTitle,
  tags,
  itemId,
  editorAPI,
  addOptions,
) {
  triggerBiEvent(editorAPI, getBiParams(itemId));
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    compStructure,
    tags,
    itemId,
    {
      origin: constants.BI.type.ADD_WIDGET,
    },
    addOptions,
  );
};

const onDrop = function (layoutParams, compPreset, parentComponent, editorAPI) {
  triggerBiEvent(editorAPI, getBiParams(compPreset.itemId));
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
    { origin: constants.BI.type.ADD_WIDGET },
  );
};

function getSection() {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_newblog_rss_button',
    subNavigationTitle: 'add_section_label_newblog_rss_button',
    presetTitle: 'add_section_label_newblog_rss_button',
    tooltipTitle: 'add_section_label_newblog_rss_button',
    sectionName: 'rssButtonSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
      iconEnabledForComps: {},
    },
    props: {
      onClick: addApp,
      onDrop,
      image:
        'addPanelData/sections/blogRssButtonSection_en/blogRssButtonSection_en.png',
      imageHover: null,
      items: [
        {
          id: itemIds.SQUARE,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 39,
              height: 39,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              widgetId: WIDGET_ID,
            },
            style: {
              type: 'TopLevelStyle',
              styleType: 'custom',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.SQUARE,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 54, height: 76, x: 0, y: 0 },
            label: '',
            tags: null,
          },
        },
        {
          id: itemIds.ROUNDED_DARK,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 39,
              height: 39,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              widgetId: WIDGET_ID,
            },
            style: {
              type: 'TopLevelStyle',
              styleType: 'custom',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.ROUNDED_DARK,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 54, height: 76, x: 54, y: 0 },
            label: '',
            tags: null,
          },
        },
        {
          id: itemIds.ROUNDED_LIGHT,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 39,
              height: 39,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              widgetId: WIDGET_ID,
            },
            style: {
              type: 'TopLevelStyle',
              styleType: 'custom',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.ROUNDED_LIGHT,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 54, height: 76, x: 108, y: 0 },
            label: '',
            tags: null,
          },
        },
        {
          id: itemIds.TRANSPARENT_DARK,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 39,
              height: 39,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              widgetId: WIDGET_ID,
            },
            style: {
              type: 'TopLevelStyle',
              styleType: 'custom',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.TRANSPARENT_DARK,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 54, height: 76, x: 162, y: 0 },
            label: '',
            tags: null,
          },
        },
        {
          id: itemIds.TRANSPARENT_LIGHT,
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 39,
              height: 39,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              widgetId: WIDGET_ID,
            },
            style: {
              type: 'TopLevelStyle',
              styleType: 'custom',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                itemIds.TRANSPARENT_LIGHT,
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 54, height: 76, x: 210, y: 0 },
            label: '',
            tags: null,
          },
        },
      ],
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: { hide: false, text: 'add_section_info_text_rss_button' },
  };
}

export default {
  registerSection(editorAPI) {
    editorAPI.addPanel.registerSectionAtStart(
      addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
      _.partial(getSection, editorAPI),
    );
  },
};
