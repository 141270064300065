import React, { type FC } from 'react';

export const UpperDeck: FC<{ onClick?: React.MouseEventHandler }> = ({
  children,
  onClick,
}) => (
  <div
    className="top-bar-upper-deck"
    data-hook="top-bar-upper-deck"
    onClick={onClick}
  >
    {children}
  </div>
);

export const LowerDeck: FC = ({ children }) => (
  <div className="top-bar-lower-deck" data-hook="top-bar-lower-deck">
    {children}
  </div>
);
