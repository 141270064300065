@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._urlBar_serzj_8 {
  width: 100%;
  height: 28px;
  line-height: 28px;
  border-radius: 30px;
  background-color: #f7f8f8;
  padding: 0;
  margin: 0 12px;
  overflow: hidden;
  position: relative; }

._urlBarTextWrapping_serzj_19 {
  width: 100%;
  height: 28px;
  line-height: 28px;
  border-radius: 30px;
  padding: 0 18px; }

._withCta_serzj_26 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

._urlBarSuggestion_serzj_29 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: calc(28px - 2px);
  line-height: calc(28px - 2px);
  background-color: #fff;
  border: 1px solid #dfe5eb;
  border-radius: 100px;
  padding-right: 18px; }
  ._urlBarSuggestion_serzj_29 .symbol-languagesEarth {
    vertical-align: middle;
    margin: -2px 6px 0 16px; }
    ._urlBarSuggestion_serzj_29 .symbol-languagesEarth path {
      fill: #9a27d5; }
    ._urlBarSuggestion_serzj_29 .symbol-languagesEarth.premium-site path {
      fill: #116dff; }
  ._urlBarSuggestion_serzj_29 .symbol-closeSmall {
    cursor: pointer;
    vertical-align: middle; }

._urlBarSiteUrl_serzj_48 {
  color: #000624;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased; }
  ._urlBarSiteUrl_serzj_48 .symbol-languagesEarth {
    margin-top: -2px;
    vertical-align: middle;
    margin-right: 6px; }
    ._urlBarSiteUrl_serzj_48 .symbol-languagesEarth path {
      fill: #000624; }
  ._urlBarSiteUrl_serzj_48:hover {
    text-decoration: underline; }

._standaloneUrl_serzj_70 {
  width: calc(100% - 18px * 2); }

._nonInteractivePublicUrl_serzj_73 {
  cursor: default;
  text-decoration: none; }
  ._nonInteractivePublicUrl_serzj_73:hover {
    text-decoration: none; }

._domainSuggestion_serzj_79 {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  min-width: 65px; }
  ._domainSuggestion_serzj_79 ._connectDomainSymbolWrap_serzj_87 {
    width: 30px;
    margin-right: 2px;
    display: inline-block;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px; }
    ._domainSuggestion_serzj_79 ._connectDomainSymbolWrap_serzj_87._nonPremium_serzj_93 {
      margin-right: 12px;
      background-color: #eedbf4; }

._domainSuggestionText_serzj_97 {
  text-decoration: underline;
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-medium);
  font-size: 14px;
  -webkit-font-smoothing: antialiased; }
  ._domainSuggestionText_serzj_97:hover {
    text-decoration: underline; }

._connectDomainExposer_serzj_107 {
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  position: absolute; }
  ._connectDomainExposer_serzj_107 .symbol-closeSmall {
    vertical-align: middle;
    margin-left: 12px; }

._connectDomainExposerPlaceholder_serzj_116 {
  height: 28px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top; }

._connectDomainTeaser_serzj_122 {
  white-space: nowrap; }

._connectDomainHandler_serzj_125 {
  cursor: default;
  color: #9a27d5;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  margin-left: 8px; }
  ._connectDomainHandler_serzj_125.interactive {
    cursor: pointer; }
    ._connectDomainHandler_serzj_125.interactive:hover {
      color: #c161f0; }

._premiumHandler_serzj_139 {
  color: #116dff; }
  ._premiumHandler_serzj_139.interactive:hover {
    color: #5999ff; }
