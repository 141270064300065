import mobileElementsPanel from './mobileElementsPanel/mobileElementsPanel';
import {
  EditorAPIKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
} from '@/apis';
import hiddenItemsPanel from './hiddenItemsPanel/hiddenItemsPanel';
import * as hiddenItemsData from './hiddenItemsPanel/hiddenItemsData';
import * as hiddenItemsUtils from './hiddenItemsUtils/hiddenItemsUtils';
import layoutOptimizerPanel from './layoutOptimizerPanel/layoutOptimizerPanel';
import mobileWizard from './mobileWizard/mobileWizard';
import cantMakeChangeMobile from './panels/cantMakeChangeMobile';
import qrCodeExposer from './qrCodeExposer/qrCodeExposer';
import { getMobileElementsAPI } from './mobileElementsPanel/mobileElementsData';
import type { EntryPoint } from '@/apilib';
import { createMobileEditorApi } from './mobileEditorWrapper';
import { MobileElements } from './mobileElementsPanel/mobileElements';

export type MobileEditorApi = ReturnType<typeof createMobileEditorApi>;

import { MobileEditorApiKey } from './publicApiKey';

export const MobileEditorEntrypoint: EntryPoint = {
  name: 'MobileEditorApi',
  declareAPIs: () => [MobileEditorApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    EditorPermissionsApiKey,
    EditorRestrictionsApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(MobileEditorApiKey, () => createMobileEditorApi(shell));
  },
  extend(shell) {
    const editorAPI = shell.getAPI(EditorAPIKey);
    const editorPermissionsApi = shell.getAPI(EditorPermissionsApiKey);
    const editorRestrictionsApi = shell.getAPI(EditorRestrictionsApiKey);

    editorRestrictionsApi.set(
      ['mobile-editor_mobile-discoverability.visible'],
      () => editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
    );

    const mobile = shell.getAPI(MobileEditorApiKey);

    editorAPI.userPreferencesInitCallbacks.push(mobile.initMobileEditorFlag);
    editorAPI.registerPageNavigationCallback(
      mobile.updateLeftBarNotificationsCount,
    );
  },
};

const panels = {
  cantMakeChangeMobile,
};

export {
  mobileElementsPanel,
  MobileElements as MobileElementsPanelContent,
  hiddenItemsPanel,
  hiddenItemsData,
  hiddenItemsUtils,
  layoutOptimizerPanel,
  mobileWizard,
  panels,
  getMobileElementsAPI,
  qrCodeExposer,
};

export type { HiddenItemData } from './hiddenItemsPanel/hiddenItemsData';
